import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextFieldWrapper = (props) => {
  const { id, type, label, placeholder, field: { onBlur, onChange, onFocus, value, ...fieldAttrs }, focused, hasError, children, handleBlur, handleFocus, handleChange, inputRef, fieldClass, labelClass, prepend, prependClass, append, appendClass, readOnly, plaintext, is, disabled, ...attrs } = props;
  const labelClasses = classNames(
    (placeholder || value || readOnly || plaintext || focused && !readOnly || hasError || type === 'date') && 'shrink', 
    labelClass && labelClass
  );
  const fieldClasses = classNames(fieldClass && fieldClass);
  const prependClasses = classNames(
    'd-flex align-items-center', 
    prependClass && prependClass
  );
  const appendClasses = classNames(
    'd-flex align-items-center', 
    appendClass && appendClass
  );

  const onHandleChange = (e) => {
    onChange(e);
    if (handleChange) handleChange(e);
  }

  const onHandleBlur = (e) => {
    onBlur(e);
    if (handleBlur) handleBlur(e);
  }

  const onHandleFocus = (e) => {
    if (onFocus) onFocus(e);
    if (handleFocus) handleFocus(e);
  }

  return (
    <>
      {label && <Form.Label className={labelClasses}>{label}</Form.Label>}
      <InputGroup className="h-100 flex-nowrap">
        {prepend && <InputGroup.Prepend className={prependClasses}>{prepend}</InputGroup.Prepend>}
        <Form.Control as={is || undefined} className={fieldClasses} readOnly={readOnly || undefined} type={type || undefined} onBlur={onHandleBlur} onFocus={onHandleFocus} onChange={onHandleChange} placeholder={placeholder} isInvalid={hasError} ref={inputRef} plaintext={plaintext || undefined} value={value === null || value === undefined ? '' : value} {...fieldAttrs} disabled={disabled} {...attrs} />
        {append && <InputGroup.Append className={appendClasses}>{append}</InputGroup.Append>}
      </InputGroup>
      {children}
    </>
  );
}

TextFieldWrapper.propTypes = {
  /**
   * field is required and must be an object
   */
  field: PropTypes.object.isRequired
};

export default TextFieldWrapper;
