import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { webhooksApi } from '../../api';
import { isValidJson, handleResponseError } from '../../utils';

import FieldWrapper from '../form/wrappers/Field';

import { useAlerts } from '../context';

const EndpointTestModal = ({ show, data, onHide }) => {
  const [status, setStatus] = useState(false);
  const formRef = useRef(null);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const initialValues = {
    trigger: data.triggers[0],
    payload: ''
  };

  const schema = yup.object().shape({
    trigger: yup.string()
      .required(t('webhooks.endpoints.form.fields.trigger.error')),
    payload: yup.string()
      .required(t('common:form.errors.required', { field: t('webhooks.endpoints.form.fields.payload.label') }))
      .test('validatePayload', t('webhooks.endpoints.form.fields.payload.error'), (value) => isValidJson(value))
  });

  const handleHide = () => {
    setStatus(false);
    onHide();
  };

  const getSamplePayload = async (trigger) => {
    try {
      const response = await webhooksApi.getWebhookEventSample(trigger);
      formRef.current.setFieldValue('payload', JSON.stringify(response.payload, null, '\t'));
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const handleTest = async (values) => {
    if (values.payload && values.trigger) {
      try {
        const response = await webhooksApi.sendPayload(data.app_id, data.uuid, values.payload, values.trigger);
        setStatus({
          style: response.success ? 'success' : 'danger',
          message: response.validation_details ? handleResponseError(response) : t('webhooks.endpoints.test.modal.messages.success', { code: response.status_code }),
          response: response
        });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  useEffect(() => {
    if (show) getSamplePayload(initialValues.trigger);
  }, [show]);

  return (
    <Modal centered id="endpoint-test-modal"
      backdrop="static"
      show={show}
      onHide={handleHide}
      size="xl"
      aria-labelledby="endpoint-test-modal-title">
      <Modal.Header closeButton>
        <Modal.Title as="h3" id="endpoint-test-modal-title">{t('webhooks.endpoints.test.modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleTest(values);
          }}>
          {({ errors, handleSubmit, isSubmitting, values }) => (
            <Form noValidate autoComplete="new-password" onSubmit={handleSubmit} className="mb-4">
              <h4 className="text-reg font-weight-bold mb-2">{t('webhooks.endpoints.form.fields.trigger.label')}</h4>
              <p className="text-info">{t('webhooks.endpoints.form.fields.trigger.description')}</p>
              <Row>
                <Col md="8">
                  <Field required className="trigger"
                    id="endpointTestForm.trigger"
                    name="trigger"
                    type="select"
                    options={data.triggers.map(trigger => ({ value: trigger, label: `/${trigger}` }))}
                    handleChange={(e) => getSamplePayload(e.target.value)}
                    component={FieldWrapper} />
                </Col>
                <Col md="4">
                  <Button block type="submit" className="text-nowrap" disabled={!values.trigger || Object.keys(errors).length || isSubmitting}>{t('webhooks.endpoints.form.buttons.test')}</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="text-reg font-weight-bold mt-5 mb-3">{t('webhooks.endpoints.test.modal.headers.payload')}</h4>
                  <Field required className="payload mb-0"
                    id="endpointTestForm.payload"
                    name="payload"
                    is="textarea"
                    rows="10"
                    component={FieldWrapper} />
                </Col>
                {status && status.response && <Col><div className="d-flex flex-column h-100"><h4 className="text-reg font-weight-bold mt-5 mb-3">{t('webhooks.endpoints.test.modal.headers.response')}</h4>
                  <div className="border rounded text-sm bg-light p-3 mb-0 overflow-auto" style={{ height: '242px' }}><pre className="mb-0">{JSON.stringify(status.response, null, '\t')}</pre></div></div></Col>}
              </Row>
            </Form>
          )}
        </Formik>
        <h4 className="text-reg font-weight-bold mt-5 mb-3">{t('webhooks.endpoints.test.modal.headers.status')}</h4>
        <p className={status ? `text-${status.style}` : 'font-italic text-info'}>{status ? status.message : t('webhooks.endpoints.test.modal.messages.status')}</p>
      </Modal.Body>
      <Modal.Footer className="text-right">
        <Button variant="outline-light" onClick={handleHide}>{t('common:buttons.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

EndpointTestModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The endpoint data object
   */
  data: PropTypes.object.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default EndpointTestModal;