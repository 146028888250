import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Field, FieldArray } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { adminApi } from '../../api';
import { useAlerts, useApp } from '../context';
import { SUPERUSER_LEVEL } from '../../constants';

import { removeEmpty, getUniqueArray, hasSuperuserAccess } from '../../utils';
import { useCurrentDimensions } from '../../utils/hooks';

import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';
import ConfirmModal from '../common/ConfirmModal';
import LoaderButton from '../common/LoaderButton';
import ChecklistTask from './ChecklistTask';

const UNIQUE_KEYS = ['name'];
const EXPANDED_MAX_ROW_COUNT = 8;

const ChecklistTasks = ({ user, initialValues, setName, match }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const { app, setAppData } = useApp();
  const location = useLocation();
  const [expanded, setExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(initialValues.tasks.length === 0);
  const [deleted, setDeleted] = useState([]);
  const [confirm, setConfirm] = useState({ update: false, deleteTask: false, data: undefined });
  const [redirect, setRedirect] = useState(false);
  const [expandedHeight, setExpandedHeight] = useState();
  const formRef = useRef(null);
  const expandedRef = useRef([]);
  const addTaskLinkRef = useRef(null);
  const history = useHistory();
  const currentWidth = useCurrentDimensions().width;
  const isMobile = currentWidth <= 992;
  const hasWriteAccess = hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]);

  const onAdd = (arrayHelpers) => {
    arrayHelpers.push({ uuid: uuid(), unique: true, name: '' });
    if (match.params.id === 'new' && arrayHelpers.form.values && arrayHelpers.form.values.tasks.length) {
      setAppData({
        checklist: {
          name: arrayHelpers.form.values.name,
          tasks: arrayHelpers.form.values.tasks
        }
      });
    }
  };

  const saveData = async (values) => {
    if (hasWriteAccess && values) {
      try {
        const response = await adminApi.updateCustomerChecklistTemplate(values, match.params.id !== 'new' ? match.params.id : undefined);
        if (response.success) {
          setAlert({ message: t(`admin.customers.checklist.details.messages.${match.params.id !== 'new' ? 'update' : 'create'}`), variant: 'success' });
          setAppData({ checklist: {} });
          setRedirect(true);
        } else {
          setAlert({ message: t('admin.customers.checklist.details.messages.error'), variant: 'warning' });
        }
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setConfirm({ ...confirm, update: false, data: undefined });
        formRef.current.setSubmitting(false);
      }
    } else {
      formRef.current.setSubmitting(false);
      setConfirm({ ...confirm, update: false, data: undefined });
    }
  };

  const deleteTask = (values) => {
    if (hasWriteAccess && values) {
      values.arrayHelpers.remove(values.index);
      if (values.task.id) setDeleted([...deleted, values.task.id]);

      if (match.params.id === 'new' && app.checklist && app.checklist.tasks.length) {
        setAppData({
          checklist: {
            ...app.checklist,
            tasks: app.checklist.tasks.filter(item => item.name !== values.task.name)
          }
        });
      }
    }

    setConfirm({ ...confirm, deleteTask: false, data: undefined });
  };

  const deleteTemplate = async (values) => {
    if (hasWriteAccess && values) {
      try {
        let deletedTasks = [...deleted, ...values.tasks];
        deletedTasks = deletedTasks.filter(task => task !== undefined);
        await adminApi.deleteCustomerChecklistTemplate(values.id, deletedTasks);
        setAlert({ message: t('admin.customers.checklist.details.messages.delete.template'), variant: 'success' });
        setRedirect(true);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setConfirm({ ...confirm, delete: false });
      }
    } else {
      setConfirm({ ...confirm, delete: false });
    }
  };

  const handleCancel = () => {
    setAppData({
      checklist: {}
    }, () => {
      history.push({ pathname: '/admin/customers/checklists', state: { from: location.pathname } });
    });
  }

  useEffect(() => {
    if (redirect) history.push({ pathname: '/admin/customers/checklists', state: { from: location.pathname } });
  }, [redirect]);

  useEffect(() => {
    if (!initialValues.tasks.length && match.params.id === 'new') addTaskLinkRef.current.click();
  }, [addTaskLinkRef, deleted]);

  return (
    <>
      <p className="mb-4 text-med text-info">{t(`admin.customers.checklist.details.${match.params.id !== 'new' ? 'editDescription' : 'description'}`)}</p>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          name: yup.string().trim()
            .max(254, t('common:form.errors.max', { count: 254 }))
            .required(t('common:form.errors.required', { field: t('admin.customers.checklist.details.form.fields.name.label') })),
          tasks: yup.array().of(yup.object().shape({
            name: yup.string()
              .max(254, t('common:form.errors.max', { count: 254 }))
              .required(t('common:form.errors.required', { field: t('admin.customers.checklist.details.form.fields.taskName.label') }))
          }))
        })}
        onSubmit={(values) => setConfirm({ ...confirm, update: true, data: deleted.length ? { ...values, deleted } : values })}>
        {({ values, errors, handleSubmit, isSubmitting, dirty, setFieldValue }) => {

          const moveTask = useCallback((dragIndex, hoverIndex) => {
            const dragItem = values.tasks[dragIndex]
            const hoverItem = values.tasks[hoverIndex]
            const updatedTasks = [...values.tasks];
            updatedTasks[dragIndex] = hoverItem;
            updatedTasks[hoverIndex] = dragItem;
            setFieldValue('tasks', updatedTasks);
          }, [values.tasks]);

          useEffect(() => {
            const arrayList = [];
            const lists = expandedRef.current;
            if (values.tasks && values.tasks.length > EXPANDED_MAX_ROW_COUNT && !isMobile && lists && lists.length) {
              for (let i = 0; i < EXPANDED_MAX_ROW_COUNT; i++) {
                if (lists[i]) {
                  var eachList = lists[i].clientHeight;
                  arrayList.push(eachList);
                  setExpandedHeight(arrayList.reduce((a, b) => a + b, 0));
                }
              }
            } else if (isMobile) {
              setExpandedHeight(false);
            }
          }, [isMobile, values.tasks]);

          useEffect(() => {
            values.tasks.forEach(task => {
              task.unique = getUniqueArray(values.tasks.map(task => { const { id, unique, ...rest } = task; return removeEmpty(rest); }), UNIQUE_KEYS).some(unique => task.uuid === unique.uuid);
            });
          }, [values.tasks]);

          useEffect(() => {
            if (match.params.id === 'new' && app.checklist && app.checklist.tasks && app.checklist.tasks.length) {
              values.name = app.checklist.name;
              values.tasks = app.checklist.tasks;
            }
          }, []);

          return (<Form noValidate className="loaded d-flex flex-grow-1 flex-column justify-content-between align-content-stretch" autoComplete="new-password" onSubmit={handleSubmit}>
            <FieldArray
              name="tasks"
              render={arrayHelpers => <Card className="border loaded flex-grow-1" style={{ zIndex: 2 }}>
                <Card.Header as={Row} className={`p-4${isMobile && !isEditing ? ' flex-nowrap text-nowrap' : ''}`} noGutters>
                  <Col lg={isMobile && !isEditing ? 'auto' : '8'} className="d-flex align-items-center">
                    {values.tasks.length > EXPANDED_MAX_ROW_COUNT && <TooltipButton placement="top" variant="link" text={t(`common:buttons.${expanded ? 'collapse' : 'expand'}`)} className="p-0 mr-3 cursor-pointer no-underline loaded" onClick={() => setExpanded(!expanded)} popoverProps={{ style: { textAlign: 'center' } }}><i className={`sila-icon text-lg text-${expanded ? 'primary collapsed' : 'meta expanded'}`}></i></TooltipButton>}
                    {isEditing && <Col md="8" lg="6" className="p-0"><Field required className="mt-0 mb-3 my-lg-n3"
                      id="editChecklist.name"
                      name="name"
                      size="sm"
                      fieldClass="text-reg"
                      handleChange={(e) => setName(e.target.value)}
                      disabled={!isEditing}
                      label={isMobile ? t('admin.customers.checklist.details.form.fields.name.label') : undefined}
                      placeholder={t('admin.customers.checklist.details.form.fields.name.label')}
                      component={FieldWrapper} /></Col>}
                  </Col>
                  <Col lg={isMobile && !isEditing ? 'auto' : '4'} className="d-flex align-items-center justify-content-end">
                    {hasWriteAccess && match.params.id !== 'new' && <><Form.Check
                      type="switch"
                      id="edit-mode-switch"
                      onChange={(e) => setIsEditing(e.target.checked)}
                      checked={isEditing} />
                      <Form.Label htmlFor="edit-mode-switch" className="m-0 mr-3 mr-md-2 text-reg">{t('admin.customers.checklist.details.buttons.edit')}</Form.Label></>}
                    {hasWriteAccess && <Button disabled={match.params.id !== 'new' && !isEditing} ref={addTaskLinkRef} variant="link" className="p-0 text-lg ml-3 loaded" onClick={() => onAdd(arrayHelpers)}>{t('admin.customers.checklist.details.buttons.create')}</Button>}
                  </Col>
                </Card.Header>
                <Card.Body className="p-0 d-flex flex-grow-1 flex-column">
                  <Form.Row className="d-none d-lg-flex bg-meta m-0 border-bottom border-light px-3 py-2">
                    <Col><strong className="text-meta">{t('admin.customers.checklist.details.labels.taskName')}</strong></Col>
                    {isEditing && values.tasks.some(task => !task.unique) && <div className="d-none d-lg-block loaded" style={{ width: 30 }}>&nbsp;</div>}
                    {isEditing && values.tasks.length !== 0 && <div className="d-none d-lg-block loaded" style={{ width: 30 }}>&nbsp;</div>}
                  </Form.Row>
                  <DndProvider backend={HTML5Backend}>
                    <div className="d-flex flex-grow-1 flex-column">
                      {values.tasks.length !== 0 && <ul className={`list-unstyled mb-0${!expanded ? ' overflow-auto custom-scrollbar' : ''}`} style={!expanded ? { maxHeight: expandedHeight } : undefined}>
                        {values.tasks.map((task, index) => <ChecklistTask key={task.id} arrayHelpers={arrayHelpers} isMobile={isMobile} expandedRef={expandedRef} length={values.tasks.length} data={task} isEditing={isEditing} index={index} match={match} setConfirm={setConfirm} isUnique={values.tasks.some(task => task.unique)} moveTask={moveTask} />)}
                      </ul>}
                    </div>
                  </DndProvider>
                </Card.Body>
              </Card>}
            />
            <Card.Footer className="border-0 p-0 mt-4 text-right">
              {hasWriteAccess && isEditing && match.params.id !== 'new' && <TooltipButton placement="top" text={t('admin.customers.checklist.details.tips.deleteTemplate')} className="px-4 mr-3" variant="outline-warning" onClick={() => setConfirm({ ...confirm, delete: true, data: { name: values.name, id: match.params.id, tasks: values.tasks.map(task => task.id) } })}>{t('common:buttons.delete')}</TooltipButton>}
              <Button variant="outline-light" className="px-4" onClick={handleCancel}>{t('common:buttons.cancel')}</Button>
              {hasWriteAccess && <LoaderButton variant="primary"
                className="ml-3 px-4"
                type="submit"
                loading={isSubmitting}
                disabled={values.tasks.some(task => !task.unique) ? true : match.params.id === 'new' && app.checklist && app.checklist.tasks && app.checklist.tasks.length === values.tasks.length && values.tasks.some(task => task.unique) ? false : Object.keys(errors).length || !isEditing || !dirty}>{t('common:buttons.save')}</LoaderButton>}
            </Card.Footer>
          </Form>);
        }}
      </Formik>

      {confirm.update && <ConfirmModal
        show={confirm.update}
        data={confirm.data}
        title={t(`admin.customers.checklist.details.${match.params.id !== 'new' ? 'confirmSaveChanges' : 'confirmCreate'}.title`)}
        renderMessageComponent={({ data }) => <>
          <p className="text-lg mb-4">{t(`admin.customers.checklist.details.${match.params.id !== 'new' ? 'confirmSaveChanges' : 'confirmCreate'}.subtitle`, { name: data.name })}</p>
          <p className="text-info mb-4">{t(`admin.customers.checklist.details.${match.params.id !== 'new' ? 'confirmSaveChanges' : 'confirmCreate'}.description`)}</p>
        </>}
        buttonLabel={t(match.params.id !== 'new' ? 'common:buttons.save' : 'admin.customers.checklist.details.confirmCreate.button')}
        onHide={saveData} />}

      {confirm.deleteTask && <ConfirmModal
        show={confirm.deleteTask}
        data={confirm.data}
        title={t('admin.customers.checklist.details.confirmDeleteTask.title')}
        renderMessageComponent={({ data }) => <>
          <p className="text-lg mb-4">{t('admin.customers.checklist.details.confirmDeleteTask.description', { name: data.task.name })}</p>
        </>}
        buttonLabel={t('common:buttons.delete')}
        buttonVariant="warning"
        onHide={deleteTask} />}

      {confirm.delete && <ConfirmModal
        show={confirm.delete}
        data={confirm.data}
        title={t('admin.customers.checklist.details.confirmDelete.title')}
        renderMessageComponent={({ data }) => <>
          <p className="text-lg mb-4">{t('admin.customers.checklist.details.confirmDelete.subtitle', { name: data.name })}</p>
          <p className="text-info mb-4">{t('admin.customers.checklist.details.confirmDelete.description')}</p>
        </>}
        buttonLabel={t('common:buttons.delete')}
        buttonVariant="warning"
        onHide={deleteTemplate} />}

    </>
  );
};

ChecklistTasks.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to set the name of the checklist template
   */
  setName: PropTypes.func,
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired
};

export default ChecklistTasks;