import React, { useState } from 'react';
import { Table, Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';

import { formatDateAndTime } from '../../../utils';

const RequestReturnHistory = ({ initialValues }) => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState([]);
  const last_update = sortBy.find(sort => sort.id === 'last_update');

  return (
    <Card className="border p-0 overflow-hidden">
      <Table bordered responsive className="border-0 p-0=">
        <thead>
          <tr>
            <th className="border-right pl-4 py-3"><Button variant="link" className="p-0 text-reset no-underline font-weight-bold" onClick={() => setSortBy(last_update && last_update.desc ? sortBy.filter(sort => sort.id !== 'last_update') : last_update ? sortBy.map(sort => sort.id === 'last_update' ? { ...sort, desc: !last_update.desc } : sort) : [...sortBy, { id: 'last_update', desc: false }])}>{t('common:labels.lastUpdated')}<i className={`${!last_update ? 'text-ultra-light' : 'text-primary'} fas ml-2 ${!last_update ? 'fa-sort' : last_update && last_update.desc ? 'fa-sort-down' : 'fa-sort-up'}`}></i></Button></th>
            <th className="px-4 py-3">{t('common:labels.status')}</th>
          </tr>
        </thead>
        <tbody>
          {initialValues && initialValues.length !== 0 ? initialValues.sort((a, b) => {
            if (!last_update || last_update && !last_update.desc) {
              return new Date(b.last_update) - new Date(a.last_update);
            } else if (last_update && last_update.desc) {
              return new Date(a.last_update) - new Date(b.last_update);
            }
          }).map((history, index) => <tr key={index}>
            <td className="pl-4 py-3">{formatDateAndTime(history.last_update)}</td>
            <td className="px-4 py-3">{t(`common:transactions.RequestsForReturn.status.${history.status}`)}</td>
          </tr>) :
            <tr className="disabled">
              <td className="p-0 cursor-default" colSpan="2"><p className="text-center text-info font-italic mx-4 my-5">{t('common:transactions.RequestsForReturn.statusHistory.empty')}</p></td>
            </tr>}
        </tbody>
      </Table>
    </Card>);
};

RequestReturnHistory.propTypes = {
  /**
   * The values of the status history
   */
  initialValues: PropTypes.array.isRequired
};

export default RequestReturnHistory;