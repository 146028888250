import React, { useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';

import { teamApi } from '../../../api';
import { useAlerts } from '../../context';
import { formatDateAndTime } from '../../../utils';
import { REQUEST_RETURN_PENDING_REVIEW, REQUEST_RETURN_OPEN } from '../../../constants';

import TooltipButton from '../TooltipButton';
import DocumentPreviewModal from '../DocumentPreviewModal';
import RequestForReturnModal from './RequestForReturnModal';

const SupportingDocuments = ({ match, initialValues, user, customer, fetchRequestReturn }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const [preview, setPreview] = useState({ show: false, data: undefined });
  const [requestReturn, setRequestReturn] = useState({ show: false, percentage: {}, return_code: initialValues.return_code || undefined, uuid: initialValues.uuid || undefined, customer_contact_date: initialValues.date_of_customer_contact || undefined });
  const [isDownloading, setIsDownloading] = useState(false);
  const disabledUploadDocument = initialValues.request_status_name === REQUEST_RETURN_OPEN ? false : true;

  const getDocument = async () => {
    if (initialValues.document_name) {
      setPreview({ show: true, date: undefined });
      const fileType = initialValues.document_name.split('.').pop();
      try {
        const response = await teamApi.getRequestReturn(match.params.id, user.currentTeam.id, true);
        let blob = new Blob([response], { type: fileType === 'pdf' ? 'application/pdf' : `image/${fileType}` });
        setPreview({ show: true, data: { file: URL.createObjectURL(blob), file_name: initialValues.document_name, file_type: fileType } });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        setPreview({ show: false, date: undefined });
      }
    }
  };

  const downloadDocument = async () => {
    if (initialValues.document_name) {
      const fileType = initialValues.document_name.split('.').pop();
      try {
        setIsDownloading(true);
        const response = await teamApi.getRequestReturn(match.params.id, user.currentTeam.id, true);
        let blob = new Blob([response], { type: fileType === 'pdf' ? 'application/pdf' : `image/${fileType}` });
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = initialValues.document_name;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setIsDownloading(false);
      }
    }
  };

  const submitRequestReturn = (values) => {
    if (values) {
      const name = values.files[0].name;
      const formData = new FormData();
      formData.append('file', values.files[0]);
      formData.append('data', JSON.stringify({
        name,
        filename: name,
        mime_type: values.files[0].type,
        support_document: {
          filename: name,
          mime_type: values.files[0].type
        },
        return_code: values.return_code,
        customer_contact_date: values.customer_contact_date,
        team_id: user && user.currentTeam.id ? user.currentTeam.id : undefined,
        transaction_uuid: initialValues && initialValues.transaction_uuid ? initialValues.transaction_uuid : undefined,
        return_request_status: REQUEST_RETURN_PENDING_REVIEW
      }));
      const xhr = new XMLHttpRequest();
      xhr.upload.onprogress = (event => {
        if (event.lengthComputable) {
          const percentage = Math.floor((event.loaded / event.total) * 100);
          setRequestReturn({ ...requestReturn, percentage: { ...requestReturn.percentage, [name]: percentage } });
        }
      });
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;
        const json = JSON.parse(xhr.responseText);
        setAlert({ message: xhr.status === 200 ? t('common:transactions.detail.requestReturn.messages.success') : handleResponseError(json), style: xhr.status === 200 ? 'success' : 'danger' });
        delete requestReturn.percentage[name];
        setRequestReturn({
          ...requestReturn,
          show: false,
          percentage: requestReturn.percentage,
          return_code: xhr.status === 200 ? REQUEST_RETURN_PENDING_REVIEW : requestReturn.return_code,
          uuid: xhr.status === 200 ? json.transaction_return_request_uuid : requestReturn.uuid
        });
        if (xhr.status === 200 && fetchRequestReturn) fetchRequestReturn();
      };
      xhr.open('PUT', `/api/v2/reverse_request_virtual_account_ach_transaction/${initialValues.uuid}`, true);
      xhr.send(formData);
    } else {
      setRequestReturn({ ...requestReturn, show: false, percentage: {} });
    }
  };

  return (<>
    <h4 className="mb-2">{t('common:transactions.RequestsForReturn.supportingDocument.title')}</h4>
    <p className="text-info mb-4">{t('common:transactions.RequestsForReturn.supportingDocument.description')}</p>

    <Card className="mb-2 border loaded overflow-hidden">
      <Table bordered responsive className="border-0">
        <thead>
          <tr>
            <th className="border-right pl-4 py-3">{t('common:transactions.RequestsForReturn.supportingDocument.labels.dateAdded')}</th>
            <th className="border-right px-4 py-3">{t('common:transactions.RequestsForReturn.supportingDocument.labels.documentName')}</th>
            <th className="pr-4 py-3" width="15%">{t('common:labels.action')}</th>
          </tr>
        </thead>
        <tbody>
          {initialValues && initialValues.document_name && <tr>
            <td className="pl-4 py-3">{formatDateAndTime(initialValues.date_submitted)}</td>
            <td className="px-4 py-3"><span className="w-100 d-block text-break pr-3 text-left" style={{ maxWidth: '300px' }}>{initialValues.document_name}</span></td>
            <td className="pr-4 py-3 text-center" width="15%">
              <TooltipButton placement="top" variant="link" popoverProps={{ style: { textAlign: 'center' } }} onClick={getDocument} text={t('common:labels.preview')} className="p-0 cursor-pointer text-info-link mr-4"><i className="text-lg fas fa-eye"></i></TooltipButton>
              <TooltipButton disabled={isDownloading} placement="top" variant="link" popoverProps={{ style: { textAlign: 'center' } }} onClick={downloadDocument} text={t('common:labels.download')} className="p-0 text-info-link cursor-pointer"><i className="text-lg fas fa-download"></i></TooltipButton>
            </td>
          </tr>}
          {!initialValues.document_name && <tr className="disabled">
            <td className="p-0 cursor-default" colSpan="4"><p className="text-center text-info font-italic mx-4 my-5">{t('common:transactions.RequestsForReturn.supportingDocument.empty')}</p></td>
          </tr>}
        </tbody>
      </Table>
    </Card>

    {customer && <p className="loaded text-right mb-5"><Button disabled={disabledUploadDocument} onClick={() => setRequestReturn({ ...requestReturn, show: true })} target="_blank" variant="link" className="p-0 text-underline">{t('common:transactions.RequestsForReturn.supportingDocument.buttons.upload')}</Button></p>}
    {preview.show && <DocumentPreviewModal show={preview.show} data={preview.data} onHide={() => setPreview({ show: false, data: undefined })} />}
    {requestReturn.show && <RequestForReturnModal data={requestReturn} show={requestReturn.show} percentage={requestReturn.percentage} onHide={submitRequestReturn} />}

  </>
  );
};

SupportingDocuments.propTypes = {
  /**
   * The match object
   */
  match: PropTypes.object.isRequired,
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The user object
   */
  user: PropTypes.object.isRequired
};

export default SupportingDocuments;
