/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Accordion, Table, Dropdown, Form, InputGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { adminApi } from '../../api';

import { formatDateAndTime, capitalize } from '../../utils';
import { useAuth, useAlerts } from '../context';

import AccordionItem from '../common/AccordionItem';
import ConfirmModal from '../common/ConfirmModal';
import ProdAccessModal from './ProdAccessModal';

function ApplicationForm({ initialValues, match, onSave }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState([1]);
  const [tier, setTier] = useState({ id: null, name: initialValues.sales_tier });
  const [tiers, setTiers] = useState([]);
  const [filters, setFilters] = useState({ name: '' });
  const [currentTier, setCurrentTier] = useState({ update: false, data: undefined });
  const [prodAccess, setProdAccess] = useState({
    show: false,
    access_granted: false,
    name: undefined,
    handle: undefined,
  });

  const { env } = useAuth();
  const { setAlert } = useAlerts();

  const handleExpanded = (key) =>
    setExpanded(expanded.includes(key) ? expanded.filter((e) => e !== key) : [...expanded, key]);
  const accordionItemProps = { expanded, onSetExpanded: handleExpanded };
  const accordionActiveKey = (key) => expanded.includes(key) && key;

  const filteredTiers = tiers.filter(
    (t) =>
      t.name &&
      t.name.indexOf(tier.name) === -1 &&
      (!filters.name || (filters.name && t.name.includes(filters.name)))
  );

  const updateAppSalesTier = async (values) => {
    if (values) {
      try {
        await adminApi.updateAppSalesTier(match.params.id, values);
        setAlert({
          message: t('admin.applications.detail.tier.form.messages.success'),
          variant: 'success',
        });
        onSave();
      } catch (error) {
        setAlert({ message: error, variant: 'danger' });
      } finally {
        setCurrentTier({ ...currentTier, update: false, data: undefined });
      }
    } else {
      setCurrentTier({ ...currentTier, update: false, data: undefined });
    }
  };

  const updateProdAccess = async (values) => {
    if (values && prodAccess.handle) {
      try {
        await adminApi.updateConsoleUser(prodAccess.handle, {
          access_granted: values.access_granted,
        });
        setAlert({
          message: t(
            `admin.consoleUsers.profile.prodAccess.${
              values.access_granted ? 'grant' : 'revoke'
            }.message`,
            { handle: prodAccess.handle }
          ),
          variant: 'success',
        });
        onSave();
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setProdAccess({ ...prodAccess, show: false });
      }
    } else {
      setProdAccess({ ...prodAccess, show: false });
    }
  };

  useEffect(() => {
    const fetchSalesTiers = async () => {
      try {
        const response = await adminApi.fetchSalesTiers({ limit: 100 });
        setTiers(response.sales_tiers);
      } catch (error) {
        setAlert({ message: error, variant: 'danger' });
      }
    };
    fetchSalesTiers();
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-md-row mb-4">
        <Dropdown
          className="sales-tier-select w-100"
          onToggle={(isVisible) => {
            if (!isVisible) setFilters({ ...filters, name: '' });
          }}
        >
          <Form.Group className="autofocus mb-0">
            <Form.Label className="shrink">{t('applications.labels.sales_tier')}</Form.Label>
            <Dropdown.Toggle className="w-100 text-left" childBsPrefix="form-control">
              <span
                className={`text-nowrap overflow-hidden${!tier.name ? ' text-info' : ''}`}
                style={{ textOverflow: 'ellipsis' }}
              >
                {tier.name ? tier.name : t('common:form.fields.select.placeholder')}
              </span>
            </Dropdown.Toggle>
          </Form.Group>
          <Dropdown.Menu className="w-100">
            <InputGroup className="input-group-seamless border-bottom border-light">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="fas fa-search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                className="w-100"
                size="sm"
                placeholder={t('common:form.fields.search.placeholder')}
                aria-label={t('common:labels.search')}
                value={filters.name}
                onChange={(e) => setFilters({ ...filters, name: e.target.value.trim() })}
              />
              {filters.name && (
                <InputGroup.Append>
                  <InputGroup.Text>
                    <Button
                      variant="link"
                      className="p-0 text-info-link"
                      onClick={() => setFilters({ ...filters, name: '' })}
                    >
                      <i className="fas fa-times-circle" />
                    </Button>
                  </InputGroup.Text>
                </InputGroup.Append>
              )}
            </InputGroup>
            <div
              className="w-100 overflow-auto custom-scrollbar position-relative"
              style={{ maxHeight: 200 }}
            >
              {filteredTiers.length ? (
                filteredTiers.map((tier, index) => (
                  <Dropdown.Item
                    disabled={initialValues.deleted}
                    key={tier.name}
                    className={index !== 0 ? 'border-top border-light' : undefined}
                    onClick={() => setTier({ id: tier.id, name: tier.name })}
                  >
                    {tier.name}
                  </Dropdown.Item>
                ))
              ) : (
                <Dropdown.Item className="bg-transparent cursor-default border-top border-light text-center text-info font-italic text-sm py-3">
                  {t('common:form.messages.empty')}
                </Dropdown.Item>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Button
          className="text-nowrap ml-auto mt-2 ml-md-4 mt-md-0"
          disabled={
            !initialValues.sales_tier ||
            (initialValues.sales_tier && initialValues.sales_tier.indexOf(tier.name) === 0)
          }
          onClick={() => setCurrentTier({ ...currentTier, update: true, data: tier })}
        >
          {t('admin.applications.detail.tier.form.buttons.save')}
        </Button>
      </div>

      <Accordion id="app" className="mb-4" activeKey={accordionActiveKey(1)}>
        <AccordionItem
          className="border rounded"
          eventKey={1}
          label={t('admin.applications.detail.headers.app')}
          {...accordionItemProps}
        >
          <div className="m-n4">
            <Table responsive>
              <thead>
                <tr>
                  <th className="py-2 pl-4 border-top border-light" width="50%">
                    {t('common:labels.detail')}
                  </th>
                  <th className="py-2 pr-4 border-top border-light" width="50%">
                    {t('common:labels.data')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:applications.nickname')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.nickname}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:applications.handle')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.handle}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:applications.app_id')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.id}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:applications.environment')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {t(`env.${env}.label`)}
                  </td>
                </tr>
                {/* <tr>
                <td className="py-2 pl-4" width="50%">{t('common:applications.description')}</td>
                <td className="py-2 pr-4" width="50%">{initialValues.description}</td>
              </tr> */}
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('admin.applications.detail.labels.brand_name')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.brand_name ? (
                      initialValues.brand_name
                    ) : (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:labels.created')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {formatDateAndTime(initialValues.created)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('admin.applications.detail.labels.deleted')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.deleted ? (
                      formatDateAndTime(initialValues.deleted)
                    ) : (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr className="py-2">
                  <td className="pl-4" width="50%">
                    {t('admin.applications.detail.labels.frozen')}
                  </td>
                  <td className="pr-4 " width="50%">
                    {initialValues.frozen ? t('common:status.true') : t('common:status.false')}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('applications.labels.sales_tier')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.sales_tier ? (
                      capitalize(initialValues.sales_tier)
                    ) : (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                {initialValues.app_product_settings.instant_ach && (
                  <>
                    <tr>
                      <td className="py-2 pl-4" width="50%">
                        {t('admin.applications.detail.labels.twilio_phone_number')}
                      </td>
                      <td className="py-2 pr-4" width="50%">
                        {initialValues.app_product_settings.instant_ach.twilio_phone_number ? (
                          initialValues.app_product_settings.instant_ach.twilio_phone_number
                        ) : (
                          <span className="text-info">{t('common:labels.na')}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">
                        {t('admin.applications.detail.labels.client_id')}
                      </td>
                      <td className="py-2 pr-4" width="50%">
                        {initialValues.credentials.client_id || (
                          <span className="text-info">{t('common:labels.na')}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">
                        {t('admin.applications.detail.labels.vAccountsPrefix')}
                      </td>
                      <td className="py-2 pr-4" width="50%">
                        {initialValues.vaccount_prefix || (
                          <span className="text-info">{t('common:labels.na')}</span>
                        )}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('admin.applications.detail.labels.defaultSecCode')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.app_sec_code || (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('admin.applications.detail.labels.acparName')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.default_ach_company.ach_name || (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('admin.applications.detail.labels.approvedKYCLevels')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.kyc_levels ? (
                      initialValues.kyc_levels.map((kyc_level) => kyc_level.name).join(', ')
                    ) : (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion id="business" className="mb-4" activeKey={accordionActiveKey(2)}>
        <AccordionItem
          className="border rounded"
          eventKey={2}
          label={t('admin.applications.detail.headers.customer')}
          {...accordionItemProps}
        >
          <div className="m-n4">
            <Table responsive>
              <thead>
                <tr>
                  <th className="py-2 pl-4 border-top border-light" width="50%">
                    {t('common:labels.detail')}
                  </th>
                  <th className="py-2 pr-4 border-top border-light" width="50%">
                    {t('common:labels.data')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:teams.name')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.team_name}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:teams.team_id')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.team_id}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </AccordionItem>
      </Accordion>

      {initialValues.team_members.filter((member) => member.role_name !== 'owner').length !== 0 && (
        <Accordion id="team-members" className="mb-4" activeKey={accordionActiveKey(3)}>
          <AccordionItem
            className="border rounded"
            eventKey={3}
            label={t('admin.applications.detail.headers.teamMembers')}
            {...accordionItemProps}
          >
            <div className="m-n4 overflow-auto" style={{ maxHeight: 300 }}>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="py-2 pl-4 border-top border-light">{t('common:labels.name')}</th>
                    <th className="py-2 border-top border-light">{t('common:labels.email')}</th>
                    <th className="py-2 border-top border-light">
                      {t('common:labels.phoneNumber')}
                    </th>
                    <th className="py-2 border-top border-light">{t('common:labels.role')}</th>
                    <th className="py-2 pr-4 border-top border-light">
                      {t('admin.consoleUsers.profile.labels.productionAccess')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initialValues.team_members.map((member, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td className="py-2 pl-4">
                        <Button
                          href={`/admin/console_user/${member.login_handle}/profile`}
                          variant="link"
                          className="p-0 text-reset text-underline"
                        >
                          {member.login_name}
                        </Button>
                      </td>
                      <td className="py-2">
                        {member.login_emails.length ? (
                          member.login_emails.map(
                            ({ email }, index) =>
                              `${email}${member.login_emails.length - 1 !== index ? ', ' : ''}`
                          )
                        ) : (
                          <span className="text-info">{t('common:labels.na')}</span>
                        )}
                      </td>
                      <td className="py-2">
                        {member.login_phones.length ? (
                          member.login_phones.map(
                            ({ phone }, index) =>
                              `${phone}${member.login_phones.length - 1 !== index ? ', ' : ''}`
                          )
                        ) : (
                          <span className="text-info">{t('common:labels.na')}</span>
                        )}
                      </td>
                      <td className="py-2">{capitalize(member.role_name)}</td>
                      <td className="py-2 pr-4">
                        <Button
                          variant="link text-underline text-nowrap"
                          onClick={() =>
                            setProdAccess({
                              ...prodAccess,
                              name: member.login_name,
                              handle: member.login_handle,
                              access_granted: member.access_granted,
                              show: true,
                            })
                          }
                          className="p-0"
                        >
                          {t(
                            `admin.consoleUsers.profile.prodAccess.${
                              member.access_granted ? 'revoke' : 'grant'
                            }.title`
                          )}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </AccordionItem>
        </Accordion>
      )}

      {currentTier.update && (
        <ConfirmModal
          show={currentTier.update}
          data={currentTier.data}
          title={t('admin.applications.detail.tier.confirm.title')}
          message={t('admin.applications.detail.tier.confirm.description', {
            name: currentTier.data.name,
          })}
          buttonLabel={t('common:buttons.save')}
          onHide={updateAppSalesTier}
        />
      )}

      {prodAccess.show && (
        <ProdAccessModal
          show={prodAccess.show}
          name={prodAccess.name}
          handle={prodAccess.handle}
          onHide={updateProdAccess}
          access_granted={prodAccess.access_granted}
        />
      )}
    </>
  );
}

ApplicationForm.propTypes = {
  /**
   * The values of the form
   */
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  /**
   * The react dom match object
   */
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  /**
   * The onSave function to fetch data
   */
  onSave: PropTypes.func.isRequired,
};

export default ApplicationForm;
