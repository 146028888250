import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CheckBoxWrapper = (props) => {
  const { id, children, label, inputRef, fieldClass, hasError, field, focused, handleBlur, handleFocus, handleChange, error, disabled, field: { onChange, ...fieldAttrs }, prependClass, appendClass, ...attrs } = props;

  const onHandleChange = (e) => {
    onChange(e);
    if (handleChange) handleChange(e);
  };

  return (
    <>
      {children ?
        <Form.Check custom id={id} className={fieldClass || undefined} {...attrs}>
          <Form.Check.Input ref={inputRef} isInvalid={hasError && error} onChange={onHandleChange} disabled={disabled} {...field} />
          <Form.Check.Label>
            {children || label}
          </Form.Check.Label>
        </Form.Check>
        :
        <Form.Check custom id={id} className={fieldClass || undefined} onChange={onHandleChange} ref={inputRef} label={label} disabled={disabled} isInvalid={hasError && error} {...fieldAttrs} {...attrs} />
      }
      {hasError && error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </>
  );
}

CheckBoxWrapper.propTypes = {
  /**
   * field is required and must be an object
   */
  field: PropTypes.object.isRequired
};

export default CheckBoxWrapper;