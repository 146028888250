/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { adminApi } from '../../../api';

import { useAlerts } from '../../context';

import {
  formatDateAndTime,
  formatNumberColumns,
  capitalize,
  convertToUSD,
  handleResponseError,
} from '../../../utils';

import { REQUEST_RETURN_PENDING_REVIEW, TRANS_QUEUED, TRANS_FAILED } from '../../../constants';

import AccordionItem from '../AccordionItem';
import RequestForReturnModal from './RequestForReturnModal';

function BalanceButton({ type, value, balance, getBalance, error }) {
  const [convert, setConvert] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center">
      {balance && !error ? (
        <>
          {convert ? (
            <>
              <i className="sila-icon sila text-primary" /> {formatNumberColumns(balance)}{' '}
            </>
          ) : (
            `$${convertToUSD(balance)}`
          )}
        </>
      ) : error ? (
        <span className="loaded">
          <i className="fas fa-exclamation-triangle text-warning mr-2" />
          {balance}
        </span>
      ) : (
        t('common:status.loading')
      )}
      {type && getBalance && value && (
        <Button
          variant="link"
          onClick={() => getBalance(type, value)}
          className={`text-nowrap p-0 mt-n1 ml-2 ${
            !balance ? 'spin' : balance && error ? 'd-none' : 'loaded'
          }`}
        >
          <i className="fas fa-sync-alt" />
        </Button>
      )}
      {balance && !error && (
        <Button
          variant="link"
          className="p-0 text-reset text-underline ml-auto"
          onClick={() => setConvert(!convert)}
        >
          {t(`common:buttons.${convert ? 'convertToUsd' : 'convertToSila'}`)}
        </Button>
      )}
    </div>
  );
}

const renderAccountInfo = (type, value, balance, getBalance, customer, admin, error) => {
  const { t } = useTranslation();
  return (
    <>
      <tr>
        <td className="py-2 pl-4" width="50%">
          {t('common:transactions.labels.entityName')}
        </td>
        <td className="py-2 pr-4" width="50%">
          {value.entity_name}
        </td>
      </tr>
      <tr>
        <td className="py-2 pl-4" width="50%">
          {t('common:labels.userHandle')}
        </td>
        <td className="py-2 pr-4" width="50%">
          <Button
            href={`/${customer ? 'settings' : 'admin'}/end_user/${value.user_handle}/profile`}
            variant="link"
            className="p-0 text-reset text-underline"
          >
            {value.user_handle}
          </Button>
        </td>
      </tr>
      <tr>
        <td className="py-2 pl-4" width="50%">
          {t('common:transactions.labels.nickname')}
        </td>
        <td className="py-2 pr-4" width="50%">
          {value.nickname || t('common:transactions.labels.default')}
        </td>
      </tr>
      {!customer && (
        <tr>
          <td className="py-2 pl-4" width="50%">
            {t('common:transactions.labels.ledger')}
          </td>
          <td className="py-2 pr-4" width="50%">
            {value.gla_uuid}
          </td>
        </tr>
      )}
      {value.payment_instrument_type === 'bank_account' && (
        <>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.routing')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {value.routing_number}
            </td>
          </tr>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.account')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {value.last_4 ? (
                `${value.last_4}*`
              ) : (
                <span className="text-info">{t('common:labels.na')}</span>
              )}
            </td>
          </tr>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.linkStatus')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {value.link_status || <span className="text-info">{t('common:labels.na')}</span>}
            </td>
          </tr>
        </>
      )}
      {value.payment_instrument_type === 'blockchain_address' && (
        <>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('applications.detail.form.fields.address.label')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {value.address}
            </td>
          </tr>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.wallets.type.label')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {t(
                `common:transactions.labels.wallets.type.${value.blockchain_network.toLowerCase()}`
              )}
            </td>
          </tr>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.wallets.migration.label')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {value.migrated_at || <span className="text-info">{t('common:labels.na')}</span>}
            </td>
          </tr>
        </>
      )}
      {value.payment_instrument_type === 'card' && (
        <>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.cards.network')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {value.card_network}
            </td>
          </tr>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.cards.type')}
            </td>
            <td className="py-2 pr-4" width="50%">
              {value.card_type}
            </td>
          </tr>
          <tr>
            <td className="py-2 pl-4" width="50%">
              {t('common:transactions.labels.cards.number')}
            </td>
            <td className="py-2 pr-4" width="50%">
              *{value.last_4}
            </td>
          </tr>
        </>
      )}
      {(value.payment_instrument_type === 'blockchain_address' ||
        value.payment_instrument_type === 'sila_ledger') && (
        <tr>
          <td className="py-2 pl-4" width="50%">
            {t(`common:transactions.labels.${customer ? 'walletBalance' : 'glaBalance'}`)}
          </td>
          <td className="py-2 pr-4" width="50%">
            <BalanceButton
              error={error}
              type={type}
              value={value}
              balance={balance}
              getBalance={getBalance}
            />
          </td>
        </tr>
      )}
      <tr>
        <td className="py-2 pl-4" width="50%">
          {t('common:transactions.labels.frozen')}
        </td>
        <td className="py-2 pr-4" width="50%">
          {capitalize(value.frozen)}
        </td>
      </tr>
    </>
  );
};

function TransactionForm({ customer, admin, initialValues, user }) {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [expanded, setExpanded] = useState([1]);
  const [balance, setBalance] = useState({ source: false, destination: false, error: false });
  const [requestReturn, setRequestReturn] = useState({
    show: false,
    percentage: {},
    return_code: initialValues.return_code || undefined,
    uuid: initialValues.transaction_return_request_uuid || undefined,
  });

  const handleExpanded = (key) =>
    setExpanded(expanded.includes(key) ? expanded.filter((e) => e !== key) : [...expanded, key]);
  const accordionItemProps = { expanded, onSetExpanded: handleExpanded };
  const accordionActiveKey = (key) => expanded.includes(key) && key;

  const getBalance = async (type, value) => {
    if (type && value.gla_uuid) {
      setBalance((prevState) => ({ ...prevState, [type]: false }));
      try {
        const response = await adminApi.getWalletBalance(value.gla_uuid);
        if (response.success) {
          setBalance((prevState) => ({ ...prevState, [type]: response.sila_balance.toString() }));
        } else {
          setBalance((prevState) => ({ ...prevState, [type]: response.message, error: true }));
        }
      } catch (error) {
        throw error;
      }
    }
  };

  const getBalances = async () => {
    if (initialValues.source && initialValues.source.gla_uuid) {
      getBalance('source', initialValues.source);
    }
    if (initialValues.destination && initialValues.destination.gla_uuid) {
      getBalance('destination', initialValues.destination);
    }
  };

  const submitRequestReturn = (values) => {
    if (values) {
      const { name } = values.files[0];
      const formData = new FormData();
      formData.append('file', values.files[0]);
      formData.append(
        'data',
        JSON.stringify({
          name,
          filename: name,
          mime_type: values.files[0].type,
          support_document: {
            filename: name,
            mime_type: values.files[0].type,
          },
          return_code: values.return_code,
          customer_contact_date: values.customer_contact_date,
          team_id: user && user.currentTeam.id ? user.currentTeam.id : undefined,
          transaction_uuid:
            initialValues && initialValues.transaction_uuid
              ? initialValues.transaction_uuid
              : undefined,
          return_request_status: REQUEST_RETURN_PENDING_REVIEW,
        })
      );
      const xhr = new XMLHttpRequest();
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentage = Math.floor((event.loaded / event.total) * 100);
          setRequestReturn({
            ...requestReturn,
            percentage: { ...requestReturn.percentage, [name]: percentage },
          });
        }
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;
        const json = JSON.parse(xhr.responseText);
        setAlert({
          message:
            xhr.status === 200
              ? t('common:transactions.detail.requestReturn.messages.success')
              : handleResponseError(json),
          style: xhr.status === 200 ? 'success' : 'danger',
        });
        delete requestReturn.percentage[name];
        setRequestReturn({
          show: false,
          percentage: requestReturn.percentage,
          return_code:
            xhr.status === 200 ? REQUEST_RETURN_PENDING_REVIEW : requestReturn.return_code,
          uuid: xhr.status === 200 ? json.transaction_return_request_uuid : requestReturn.uuid,
        });
      };
      xhr.open('POST', '/api/v2/request_reverse_virtual_account_ach_transaction', true);
      xhr.send(formData);
    } else {
      setRequestReturn({ show: false, percentage: {} });
    }
  };

  useEffect(() => {
    getBalances();
  }, []);

  return (
    <>
      <Accordion id="critical" className="mb-4" activeKey={accordionActiveKey(1)}>
        <AccordionItem
          className="border rounded"
          eventKey={1}
          label={t('common:transactions.detail.headers.critical')}
          {...accordionItemProps}
        >
          <div className="m-n4">
            <Table responsive>
              <thead>
                <tr>
                  <th className="py-2 pl-4 border-top" width="50%">
                    {t('common:labels.detail')}
                  </th>
                  <th className="py-2 pr-4 border-top" width="50%">
                    {t('common:labels.data')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:labels.created')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {formatDateAndTime(initialValues.created)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:labels.userHandle')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    <Link
                      to={{
                        pathname: `/${customer ? 'settings' : 'admin'}/end_user/${
                          initialValues.source.user_handle
                        }/profile`,
                      }}
                      className="p-0 text-reset text-underline"
                    >
                      {initialValues.source.user_handle}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:applications.handle')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {customer ? (
                      initialValues.app.app_handle
                    ) : (
                      <Link
                        to={{
                          pathname: `/admin/app/${initialValues.app.app_id}/detail`,
                        }}
                        className="p-0 text-reset text-underline"
                      >
                        {initialValues.app.app_handle}
                      </Link>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:applications.nickname')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {customer ? (
                      initialValues.app.app_handle
                    ) : (
                      <Link
                        to={{
                          pathname: `/admin/app/${initialValues.app.app_id}/detail`,
                        }}
                        className="p-0 text-reset text-underline"
                      >
                        {initialValues.app.app_nickname}
                      </Link>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.amount')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    <BalanceButton error={balance.error} balance={initialValues.sila_amount} />
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.source')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.source.payment_instrument_type === 'bank_account'
                      ? t('common:transactions.labels.bank_account_number', {
                          number: initialValues.source.last_4,
                        })
                      : initialValues.source.payment_instrument_type === 'card'
                      ? t('common:transactions.labels.bank_account_number', {
                          number: initialValues.source.last_4,
                        })
                      : t('common:transactions.labels.blockchain_address_nickname', {
                          nickname:
                            initialValues.source.nickname ||
                            t('common:transactions.labels.default'),
                          app: initialValues.app.app_nickname,
                        })}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.destination')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.destination.payment_instrument_type === 'bank_account'
                      ? t('common:transactions.labels.bank_account_number', {
                          number: initialValues.destination.last_4,
                        })
                      : initialValues.destination.payment_instrument_type === 'card'
                      ? t('common:transactions.labels.bank_account_number', {
                          number: initialValues.destination.last_4,
                        })
                      : t('common:transactions.labels.blockchain_address_nickname', {
                          nickname:
                            initialValues.destination.nickname ||
                            t('common:transactions.labels.default'),
                          app: initialValues.app.app_nickname,
                        })}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.transactionType')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {t(`common:transactions.${initialValues.transaction_type}`)}
                  </td>
                </tr>
                {initialValues.ach_details.is_debit &&
                  initialValues.transaction_type !== 'transfer' && (
                    <tr>
                      <td className="py-2 pl-4" width="50%">
                        {t('common:transactions.labels.debit')}
                      </td>
                      <td className="py-2 pr-4" width="50%">
                        {capitalize(initialValues.ach_details.is_debit)}
                      </td>
                    </tr>
                  )}
                {initialValues.transaction_type === 'issue' &&
                initialValues.destination.gla_uuid ? (
                  <tr>
                    <td className="py-2 pl-4" width="50%">
                      {t('common:transactions.labels.balance')}
                    </td>
                    <td className="py-2 pr-4" width="50%">
                      <BalanceButton
                        error={balance.error}
                        type="destination"
                        balance={balance.destination}
                        value={initialValues.destination}
                        getBalance={getBalance}
                      />
                    </td>
                  </tr>
                ) : initialValues.transaction_type === 'redeem' && initialValues.source.gla_uuid ? (
                  <tr>
                    <td className="py-2 pl-4" width="50%">
                      {t('common:transactions.labels.balance')}
                    </td>
                    <td className="py-2 pr-4" width="50%">
                      <BalanceButton
                        error={balance.error}
                        type="source"
                        value={initialValues.source}
                        balance={balance.source}
                        getBalance={getBalance}
                      />
                    </td>
                  </tr>
                ) : initialValues.destination.gla_uuid && initialValues.source.gla_uuid ? (
                  <>
                    <tr>
                      <td className="py-2 pl-4" width="50%">
                        {t('common:transactions.labels.balanceWithLabel', {
                          label: t('common:transactions.labels.source'),
                        })}
                      </td>
                      <td className="py-2 pr-4" width="50%">
                        <BalanceButton
                          error={balance.error}
                          type="source"
                          value={initialValues.source}
                          balance={balance.source}
                          getBalance={getBalance}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">
                        {t('common:transactions.labels.balanceWithLabel', {
                          label: t('common:transactions.labels.destination'),
                        })}
                      </td>
                      <td className="py-2 pr-4" width="50%">
                        <BalanceButton
                          error={balance.error}
                          type="destination"
                          value={initialValues.destination}
                          balance={balance.destination}
                          getBalance={getBalance}
                        />
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </Table>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion id="transaction" className="mb-4" activeKey={accordionActiveKey(2)}>
        <AccordionItem
          className="border rounded"
          eventKey={2}
          label={t('common:transactions.detail.headers.transaction')}
          {...accordionItemProps}
        >
          <div className="m-n4">
            <Table responsive>
              <thead>
                <tr>
                  <th className="py-2 pl-4 border-top" width="50%">
                    {t('common:labels.detail')}
                  </th>
                  <th className="py-2 pr-4 border-top" width="50%">
                    {t('common:labels.data')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {!customer && (
                  <tr>
                    <td className="py-2 pl-4" width="50%">
                      {t('common:transactions.labels.id')}
                    </td>
                    <td className="py-2 pr-4" width="50%">
                      {initialValues.transaction_db_id}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.uuid')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.transaction_uuid}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.addenda')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.ach_details.addenda_uuid || (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.status')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    <span
                      className={`text-${
                        initialValues.transaction_status === 'failed'
                          ? 'warning'
                          : initialValues.transaction_status === 'success'
                          ? 'success'
                          : 'primary'
                      }`}
                    >
                      {capitalize(initialValues.transaction_status)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.status')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    <span
                      className={`text-${
                        initialValues.transaction_status === 'failed'
                          ? 'warning'
                          : initialValues.transaction_status === 'success'
                          ? 'success'
                          : 'primary'
                      }`}
                    >
                      {capitalize(initialValues.transaction_status)}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.usdStatus')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    <span
                      className={`text-${
                        initialValues.transaction_status_details.usd_status === 'failed'
                          ? 'warning'
                          : initialValues.transaction_status_details.usd_status === 'success'
                          ? 'success'
                          : 'primary'
                      }`}
                    >
                      {capitalize(initialValues.transaction_status_details.usd_status)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion id="ach" className="mb-4" activeKey={accordionActiveKey(3)}>
        <AccordionItem
          className="border rounded"
          eventKey={3}
          label={t('common:transactions.detail.headers.ach')}
          {...accordionItemProps}
        >
          <div className="m-n4">
            <Table responsive>
              <thead>
                <tr>
                  <th className="py-2 pl-4 border-top" width="50%">
                    {t('common:labels.detail')}
                  </th>
                  <th className="py-2 pr-4 border-top" width="50%">
                    {t('common:labels.data')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:labels.submitted')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.ach_details.submitted ? (
                      formatDateAndTime(initialValues.ach_details.submitted)
                    ) : (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.ach')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.ach_details.ach_filename || (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.returnCode')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.ach_details.return_code || (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.returnDescription')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.ach_details.return_description || (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.processingType')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.processing_type}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.review')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {capitalize(initialValues.review_needed)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pl-4" width="50%">
                    {t('common:transactions.labels.traceNumber')}
                  </td>
                  <td className="py-2 pr-4" width="50%">
                    {initialValues.ach_details.trace_number || (
                      <span className="text-info">{t('common:labels.na')}</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion id="source" className="mb-4" activeKey={accordionActiveKey(4)}>
        <AccordionItem
          className="border rounded"
          eventKey={4}
          label={`${t('common:transactions.detail.headers.source')} (${t(
            `common:transactions.labels.${initialValues.source.payment_instrument_type}`
          )})`}
          {...accordionItemProps}
        >
          <div className="m-n4">
            <Table responsive>
              <thead>
                <tr>
                  <th className="py-2 pl-4 border-top" width="50%">
                    {t('common:labels.detail')}
                  </th>
                  <th className="py-2 pr-4 border-top" width="50%">
                    {t('common:labels.data')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderAccountInfo(
                  'source',
                  initialValues.source,
                  balance.source,
                  getBalance,
                  customer,
                  admin,
                  balance.error
                )}
              </tbody>
            </Table>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion id="destination" activeKey={accordionActiveKey(5)}>
        <AccordionItem
          className="border rounded"
          eventKey={5}
          label={`${t('common:transactions.detail.headers.destination')} (${t(
            `common:transactions.labels.${initialValues.destination.payment_instrument_type}`
          )})`}
          {...accordionItemProps}
        >
          <div className="m-n4">
            <Table responsive>
              <thead>
                <tr>
                  <th className="py-2 pl-4 border-top" width="50%">
                    {t('common:labels.detail')}
                  </th>
                  <th className="py-2 pr-4 border-top" width="50%">
                    {t('common:labels.data')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderAccountInfo(
                  'destination',
                  initialValues.destination,
                  balance.destination,
                  getBalance,
                  customer,
                  admin,
                  balance.error
                )}
              </tbody>
            </Table>
          </div>
        </AccordionItem>
      </Accordion>

      {customer &&
        user &&
        (user.role === 1 || user.role === 2) &&
        ![TRANS_QUEUED, TRANS_FAILED].includes(initialValues.transaction_status) &&
        (initialValues.destination.payment_instrument_type === 'virtual_account' ||
          initialValues.source.payment_instrument_type === 'virtual_account') &&
        (initialValues.destination.user_handle === 'virtual_accounts_external_originator' ||
          initialValues.source.user_handle === 'virtual_accounts_external_originator') && (
          <p className="text-right mt-5">
            {!requestReturn.uuid && (
              <Button
                variant="primary"
                type="button"
                onClick={() => setRequestReturn({ ...requestReturn, show: true })}
              >
                {t('common:transactions.detail.buttons.requestReturn')}
              </Button>
            )}
            {requestReturn.uuid && (
              <Button
                href={`/settings/request-return/${requestReturn.uuid}/detail`}
                variant="primary"
                type="button"
              >
                {t('common:transactions.detail.buttons.viewRequestReturn')}
              </Button>
            )}
          </p>
        )}

      {requestReturn.show && (
        <RequestForReturnModal
          show={requestReturn.show}
          percentage={requestReturn.percentage}
          onHide={submitRequestReturn}
        />
      )}
    </>
  );
}

TransactionForm.propTypes = {
  /**
   * The customer toggle to determine if the form is used for customer transactions
   */
  customer: PropTypes.bool,
  /**
   * The admin toggle to determine if the form is used for the admin console
   */
  admin: PropTypes.bool,
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
};

export default TransactionForm;
