import React, { useMemo } from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { adminApi } from '../../api';
import { formatDateAndTime, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import DataList from '../../components/common/DataList';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'owners';

const formatSortBy = (sortBy) => {
  return { url: sortBy };
};

const ChecklistOwners = ({ t, history, user }) => {
  const columns = useMemo(() => [
    {
      id: 'created',
      Header: t('common:labels.created'),
      accessor: 'created',
      disableFilters: true,
      Cell: ({ value }) => <span className="text-nowrap">{formatDateAndTime(value)}</span>
    },
    {
      id: 'name',
      Header: t('common:labels.name'),
      accessor: 'name',
      disableFilters: true,
      Cell: ({ value }) => value
    },
    {
      id: 'email',
      Header: t('common:labels.email'),
      accessor: 'email',
      disableFilters: true,
      Cell: ({ value }) => value
    },
    {
      id: 'department',
      Header: t('admin.customers.owners.labels.department'),
      accessor: 'department',
      disableFilters: true,
      Cell: ({ value }) => value
    }
  ], []);

  return (
    <Container className="owners main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.customers.owners.title')}>
          <Button variant="outline-light" className="back text-info" as={NavLink} to="/admin">{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]) ?
        <DataList 
          enableGlobalFilter
          enableFilterMenu={false}
          enableManualFilters={false}
          slug={TABLE_SLUG}
          limit={TABLE_MAX_COUNT}
          fetchData={adminApi.fetchCustomerChecklistOwners}
          globalFilterPlaceholder={t('admin.customers.owners.list.search.placeholder')}
          columns={columns}
          onRowClick={(row) => history.push(`/admin/customers/owners/${row.original.id}`)}
          onSortBy={formatSortBy}
          hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE])}
          headerRightComponent={(rows) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]) ? <Button as={NavLink} to={{ pathname: `/admin/customers/owners/new`, state: { owners: rows, from: location.pathname } }} className="mt-2 mt-sm-0" variant="outline-light">{t('admin.customers.owners.list.buttons.create')} <i className="fas fa-plus-circle ml-2"></i></Button> : null}
          headerClasses={({ headerGroup, index }) => classNames(
            'py-3 col-4',
            index === 0 && 'pl-4',
            index === headerGroup.headers.length - 1 && 'pr-4',
            index !== 0 && index !== headerGroup.headers.length && 'px-3'
          )}
          cellClasses={({ row, index }) => classNames(
            'py-3',
            index === 0 && 'pl-4',
            index === row.cells.length - 1 && 'pr-4',
            index !== 0 && index !== row.cells.length && 'px-3'
          )}
          renderEmptyComponent={({ globalFilter }) => <p className="text-center text-info font-italic mx-4 my-5">{t(`admin.customers.owners.list.empty.${globalFilter && globalFilter.length ? 'search' : 'entries'}`)}</p>}
          loadingMessage={t('admin.customers.owners.list.loading')} />
        :
        <div className="position-relative"><DisabledOverlay /></div>}

    </Container>
  );
};

export default ChecklistOwners;