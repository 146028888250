export default [
  {
    name: 'privacy',
    title: 'footer.navigation.privacy',
    href: 'https://www.silamoney.com/legal/privacy-policy',
  },
  {
    name: 'terms',
    title: 'footer.navigation.terms',
    href: 'https://www.silamoney.com/legal/terms-of-service',
  },
  {
    name: 'github',
    title: 'footer.navigation.github',
    href: 'https://github.com/sila-Money',
    htmlBefore: '<i class="fab fa-github"></i>',
    classes: 'icon-only'
  }
]
