import React, { useState, useRef, useEffect } from 'react';
import { Card, Col, Form, Button } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { adminApi } from '../../api';

import { LETTERS_UNDERSCORES_REG_EXP } from '../../constants';

import FieldWrapper from '../form/wrappers/Field';
import LoaderButton from '../common/LoaderButton';
import ConfirmModal from '../common/ConfirmModal';
import TooltipButton from '../common/TooltipButton';

import { useAlerts } from '../context';

const SalesTierFieldLabel = ({ label, fieldName }) => {
  const { t } = useTranslation();
  return <>{label}<TooltipButton placement="top" variant="link" text={t(`admin.salesTiers.detail.tips.${fieldName}`)} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton></>;
};

const SalesTierForm = ({ initialValues, setInitialValues, setName, getSalesTier, match, limits }) => {
  const [currentTier, setCurrentTier] = useState({ update: false, delete: false, duplicate: false, data: undefined });
  const [exists, setExists] = useState({ name: '', long_name: '' });
  const [redirect, setRedirect] = useState(false);
  const formRef = useRef(null);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const history = useHistory();
  const location = useLocation();
  const isDefault = initialValues.name === 'default';

  const updateSalesTier = async (values, refresh) => {
    if (values) {
      try {
        await adminApi.updateSalesTier(match.params.id, {
          name: values.name,
          long_name: values.long_name,
          description: values.description
        });
        setAlert({ message: t('admin.salesTiers.detail.messages.update'), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentTier({ ...currentTier, update: false, data: undefined });
        formRef.current.resetForm({ values: initialValues });
        if (refresh) setRedirect(true);
      }
    } else {
      formRef.current.setSubmitting(false);
      setCurrentTier({ ...currentTier, update: false, data: undefined });
    }
  };

  const deleteSalesTier = async (values) => {
    if (values) {
      try {
        await adminApi.deleteSalesTier(values);
        setAlert({ message: t('admin.salesTiers.detail.messages.delete', { name: values.name }), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentTier({ ...currentTier, delete: false, data: undefined });
        setRedirect(true);
      }
    } else {
      setCurrentTier({ ...currentTier, delete: false, data: undefined });
    }
  };

  const createSalesTier = async (values) => {
    if (values) {
      try {
        const response = await adminApi.createSalesTier(values);
        setAlert({ message: t('admin.salesTiers.detail.messages.create', { name: values.name }), variant: 'success' });
        history.push({ pathname: `/admin/sales_tier/${response.sales_tier.id}/limits`, state: { from: location.pathname } });
      } catch (error) {
        if (error.includes('(name)')) formRef.current.setFieldError('name', );
        if (error.includes('(long_name)')) formRef.current.setFieldError('long_name', t('common:form.errors.exists', { field: values.name }));
      } finally {
        formRef.current.setSubmitting(false);
      }
    }
  };

  const duplicateSalesTier = async (values, setSubmitting) => {
    if (values) {
      if (match.params.action === 'duplicate') {
        try {
          const response = await adminApi.createSalesTier(values);
          setAlert({ message: t('admin.salesTiers.detail.messages.duplicate', { name: initialValues.name }), variant: 'success' });
          history.push({ pathname: `/admin/sales_tier/${response.sales_tier.id}/limits`, state: { from: location.pathname, limits } });
          getSalesTier(response.sales_tier.id);
        } catch (error) {
          setAlert({ message: error, variant: 'warning' });
        } finally {
          setCurrentTier({ ...currentTier, duplicate: false, data: undefined });
          if (setSubmitting) setSubmitting(false);
        }
      } else {
        setInitialValues({
          ...initialValues,
          name: `${initialValues.name}_copy`,
          long_name: `${initialValues.long_name} Copy`,
          description: initialValues.description ? `${initialValues.description} Copy` : initialValues.description
        });
        history.push({ pathname: `/admin/sales_tier/${match.params.id}/duplicate`, state: { from: location.pathname } });
        setCurrentTier({ ...currentTier, duplicate: false, data: undefined });
      }
    } else {
      setCurrentTier({ ...currentTier, duplicate: false, data: undefined });
    }
  };

  useEffect(() => {
    if (redirect) history.push({ pathname: '/admin/sales_tiers', state: { from: location.pathname } });
  }, [redirect]);

  return (<>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={yup.object().shape({
        name: initialValues.name !== 'default' ? yup.string().trim()
          .required(t('common:form.errors.required', { field: t('common:labels.name') }))
          .max(48, t('common:form.errors.max', { count: 48 }))
          .matches(LETTERS_UNDERSCORES_REG_EXP, { message: t('admin.salesTiers.detail.errors.name') })
          .test('checkName', t('common:form.errors.exists', { field: exists.name }), (value) => value && value != initialValues.name && location.state && location.state.tiers && location.state.tiers.some(tier => tier.name === value) ? false : true)
          .test('checkDefault', t('admin.salesTiers.detail.errors.default'), (value) => value && value !== 'default') : null,
        long_name: initialValues.name !== 'default' ? yup.string().trim()
          .required(t('common:form.errors.required', { field: t('admin.salesTiers.labels.long_name') }))
          .max(100, t('common:form.errors.max', { count: 100 }))
          .test('checkName', t('common:form.errors.exists', { field: exists.long_name }), (value) => value && value != initialValues.long_name && location.state && location.state.tiers && location.state.tiers.some(tier => tier.long_name === value) ? false : true) : null
      })}
      onSubmit={(values, { setSubmitting }) => {
        match.params.action === 'detail' ? setCurrentTier({ ...currentTier, update: true, data: values }) : !match.params.action ? createSalesTier(values) : duplicateSalesTier(values, setSubmitting)
      }}>
      {({ errors, handleSubmit, isSubmitting, dirty, setFieldValue }) => (
        <Form noValidate className="loaded flex-grow-1" autoComplete="new-password" onSubmit={handleSubmit}>
          <Form.Row>
            <Col sm="12" lg="4">
              <Field required className="name"
                disabled={isDefault}
                readOnly={isDefault}
                tabIndex="1"
                id="editSalesTierForm.name"
                name="name"
                handleChange={e => {
                  const value = e.target.value.toLowerCase().trim();
                  setName(value)
                  setFieldValue(e.target.name, value);
                  setExists({ ...exists, name: value });
                }}
                label={<SalesTierFieldLabel label={t('admin.salesTiers.labels.name')} fieldName="name" />}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="4">
              <Field required className="label"
                disabled={isDefault}
                readOnly={isDefault}
                tabIndex="2"
                id="editSalesTierForm.label"
                name="long_name"
                handleChange={e => setExists({ ...exists, long_name: e.target.value })}
                label={<SalesTierFieldLabel label={t('admin.salesTiers.labels.long_name')} fieldName="label" />}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="4">
              <Field required className="description"
                disabled={isDefault}
                readOnly={isDefault}
                tabIndex="3"
                id="editSalesTierForm.description"
                name="description"
                label={<SalesTierFieldLabel label={t('common:labels.description')} fieldName="description" />}
                component={FieldWrapper} />
            </Col>
          </Form.Row>
          <Card.Footer className="actions p-0 rounded mt-4 d-flex justify-content-end border-top-0">
            {match.params.action === 'detail' && !isDefault && <Button className="mr-0 mr-auto px-4" variant="outline-warning" onClick={() => setCurrentTier({ ...currentTier, delete: true, data: initialValues })}>{t('common:buttons.delete')}</Button>}
            {match.params.action === 'detail' && <Button className="mr-0 mx-md-3 px-4" variant="outline-light" disabled={dirty} onClick={() => setCurrentTier({ ...currentTier, duplicate: true, data: initialValues })} >{t('admin.salesTiers.detail.buttons.duplicate')}</Button>}
            {match.params.action === 'detail' && <Button className="mr-0 mr-md-3 px-4" variant="outline-light" onClick={() => history.push({ pathname: `/admin/sales_tier/${initialValues.id}/limits`, state: { from: location.pathname } })}>{t('admin.salesTiers.detail.buttons.manage')}</Button>}
            {(!match.params.action || match.params.action === 'duplicate') && <Button className="mr-0 mr-md-3 px-4" variant="outline-light" onClick={() => history.goBack()}>{t('common:buttons.cancel')}</Button>}
            <LoaderButton variant="primary" type="submit" loading={isSubmitting} disabled={((!match.params.action || match.params.action === 'detail') && !dirty) || Object.keys(errors).length}>{t(`common:buttons.${match.params.action === 'detail' ? 'save' : 'continue'}`)}</LoaderButton>
          </Card.Footer>
        </Form>
      )}
    </Formik>

    {currentTier.update && <ConfirmModal
      show={currentTier.update}
      data={currentTier.data}
      title={t('admin.salesTiers.detail.confirm.title')}
      message={t('admin.salesTiers.detail.confirm.description', { name: currentTier.data.name })}
      buttonLabel={t('common:buttons.confirmSave')}
      onHide={(values) => updateSalesTier(values, true)} />}

    {currentTier.delete &&
      <ConfirmModal
        show={currentTier.delete}
        data={currentTier.data}
        title={t('admin.salesTiers.detail.delete.title')}
        message={t('admin.salesTiers.detail.delete.description', { name: currentTier.data.name })}
        buttonLabel={t('common:buttons.delete')}
        buttonVariant="warning"
        onHide={deleteSalesTier} />}

    {currentTier.duplicate &&
      <ConfirmModal
        show={currentTier.duplicate}
        data={currentTier.data}
        title={t('admin.salesTiers.duplicate.title')}
        message={t('admin.salesTiers.duplicate.description', { name: currentTier.data.name })}
        buttonLabel={t('common:buttons.duplicate')}
        buttonVariant="warning"
        onHide={duplicateSalesTier} />}
  </>
  );
};

SalesTierForm.propTypes = {
  /**
   * The initialValues object
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to set the value of the form
   */
  setInitialValues: PropTypes.func.isRequired,
  /**
   * The name set function
   */
  setName: PropTypes.func.isRequired,
  /**
   * The save function when the form is submitted
   */
  getSalesTier: PropTypes.func.isRequired,
  /**
   * The limits array
   */
  limits: PropTypes.array.isRequired,
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired
};

export default SalesTierForm;