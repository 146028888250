import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import LoaderButton from '../common/LoaderButton';

const ProdAccessModal = ({ show, name, handle, onHide, access_granted }) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const status = access_granted ? 'revoke' : 'grant';

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    onHide({ access_granted: !access_granted });
  };

  return (
    <Modal centered id="prod-access-modal"
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="prod-access-modal-title">
      <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title as="h3" id="prod-access-modal-title">{t(`admin.consoleUsers.profile.prodAccess.${status}.title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-lg mb-4">{t(`admin.consoleUsers.profile.prodAccess.${status}.confirm`, { name })}</p>
          <p className="text-info">{t(`admin.consoleUsers.profile.prodAccess.${status}.description`, { name, handle })}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" disabled={isSubmitting} onClick={() => onHide()}>{t('common:buttons.cancel')}</Button>
          <LoaderButton loading={isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit">{t(`admin.consoleUsers.profile.prodAccess.${status}.action`)}</LoaderButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

ProdAccessModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The consule user name
   */
  name: PropTypes.string.isRequired,
  /**
   * The console user handle
   */
  handle: PropTypes.string.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired,
  /**
   * The console user production access boolean
   */
  access_granted: PropTypes.bool.isRequired
};

export default ProdAccessModal;