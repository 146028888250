import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { teamApi, consoleApi } from '../../../api';
import { formatMd5Name } from '../../../utils';
import { useCurrentDimensions } from '../../../utils/hooks';
import { COOKIES_OPTIONS } from '../../../constants';

import TeamDataModal from '../../team/TeamDataModal';
import Collapse from '../../common/Collapse';

import { useAuth, useAlerts, useApp } from '../../context';

const NavbarTeams = () => {
  const { user, onUpdateUser } = useAuth();
  const { t } = useTranslation();
  const { updateApp } = useApp();
  const { setAlert } = useAlerts();
  const currentWidth = useCurrentDimensions().width;

  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [team, setTeam] = useState(user.currentTeam);
  
  const isMobile = currentWidth <= 768;
  const [isLoading, setIsLoading] = useState(false);

  const getTeams = async () => {
    setIsLoading(true);
    if (isLoading) return false;
    if (user.currentTeam.page > user.currentTeam.total_pages) return false;
    const page = user.currentTeam.page + 1;
    try {
      const response = await teamApi.fetchTeams({
        limit: 20,
        page: page
      });
      onUpdateUser({ teams: [...user.teams, ...response.teams], currentTeam: { ...user.currentTeam, page } }, () => {
        setIsLoading(false);
      });
    } catch (error) {
      throw error;
    }
  } 

  const createTeam = async (value) => {
    if (value) {
      try {
        const response = await teamApi.createTeamsForBothEnvs(value, user.entity.handle);
        setAlert({ message: t('teams.modal.form.messages.success', { team: formatMd5Name(value, user.company.name) }), variant: 'success' });
        handleClick(response.team);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleClick = async (team) => {
    updateApp({ isLoading: true });
    try {
      const teamRes = await consoleApi.get({ url: `/teams/${team.id}` });
      const currentTeam = { ...user.currentTeam, ...teamRes.team };
      const teams = (user.teams.some(tm => tm.team.id === team.id)) ? [...user.teams] : [...user.teams, { team }];
      onUpdateUser({ currentTeam, teams }, () => {
        setTeam(team);
        Cookies.set('sila_console_team', team.id, COOKIES_OPTIONS);
        Cookies.set('sila_console_team_name', team.name, COOKIES_OPTIONS);
        updateApp({ isLoading: false });
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setTeam(user.currentTeam);
  }, [user.currentTeam]);

  return (
    <>
      <Dropdown id="teams-menu" as={Nav.Item} onToggle={() => setVisible(!visible)}>
        <Dropdown.Toggle id="teams-toggle" size={!isMobile ? 'sm' : undefined} variant="outline-light" as={Button} className={`w-100 text-nowrap${isMobile ? ' shadow border-0' : ''}`}>
          <span className="text-primary mr-2">{t('common:labels.team')}:</span> {formatMd5Name(team.name, user.company.name)} {' '}
        </Dropdown.Toggle>
        <Collapse as={Dropdown.Menu} className={`justify-content-end dropdown-menu-small scrollable-menu${isMobile ? ' rounded-0' : ''}`} show={visible}>
          <div className="overflow-auto border-bottom pb-2 mb-2 custom-scrollbar" style={{ maxHeight: 300 }}>
            {user.teams.filter(data => data.team.id !== team.id).map(data =>
              <Dropdown.Item key={data.team.id} eventKey={data.team.id} onClick={() => handleClick(data.team)}>{data.team.name}</Dropdown.Item>
            )}
            {user.currentTeam.page != user.currentTeam.total_pages && <Button variant="link" disabled={isLoading} className="w-100 text-center pt-2 pb-2" style={{justifyContent: 'center', textDecoration: 'none', boxShadow: 'none' }} onClick={(e) => { getTeams(); e.preventDefault(); }}>{t(`common:${isLoading ? 'status.loading' : 'labels.loadMore'}`)}</Button>}
          </div>
          <Dropdown.Item onClick={handleShow}><i className="fas fa-plus-circle mr-2"></i> {t('header.teams.create')}</Dropdown.Item>
        </Collapse>
      </Dropdown>

      <TeamDataModal show={show} onHide={createTeam} />
    </>
  );
};

export default NavbarTeams;