import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import ConfirmPasswordForm from '../components/auth/ConfirmPasswordForm';

import { useAuth } from '../components/context';

import { authApi } from '../api';

const ResetPassword = ({ t, history, location, match, user, setAlert }) => {
  const { onLogin } = useAuth();
  const [confirmedHandle, setConfirmedHandle] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [showUsername, setShowUsername] = useState(false);
  const [usernameOptions, setUsernameOptions] = useState([]);
  const from = location.state && location.state.from ? location.state.from : '/';

  const authenticate = async (values, { setSubmitting }) => {
    try {
      const response = await authApi.authenticate({ ...values, handle: user.entity.handle });
      onLogin(response);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setSubmitting(false);
    }
  };

  const changePassword = async (values, { setSubmitting, resetForm, initialValues }) => {
    try {
      await authApi.changePassword(values.password);
      authenticate(values, { setSubmitting });
      setAlert({ message: t('resetPassword.form.messages.success'), variant: 'success' });
      history.push({ pathname: from });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      resetForm({ values: initialValues });
    }
  };

  const resetPassword = async (values, { setSubmitting, resetForm, initialValues }) => {
    try {
      let response;
      const handleConfirmed = values.handle ? values.handle : confirmedHandle;
      if (process.env.RECAPTCHA_SKIP !== 'True') {
        response = await authApi.resetPassword(match.params.token, handleConfirmed, values.password, values.ctoken);
      } else {
        response = await authApi.resetPassword(match.params.token, handleConfirmed, values.password);
      }
      setAlert({ message: response.message, variant: 'success' });
      history.push({ pathname: user ? from : '/login' });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      resetForm({ values: initialValues });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!match.params.token && !user) history.push({ pathname: '/login' });
    async function fetchAccountsData() {
      const response = await authApi.getAccounts({ token: match.params.token, handle: 'placeholder_handle' });
      if (!response.success) {
        setAlert({ message: response.message, variant: 'warning' });
        history.push({ pathname: '/login' });
      } else {
        if (response.total_count && response.total_count > 1) {
          if (response.accounts.length > 0) {
            const accounts = [];
            response.accounts.forEach(loginHandle => {
              accounts.push({ label: loginHandle, value: loginHandle });
            });
            setUsernameOptions(accounts);
          }
          setShowUsername(true);
        } else {
          setConfirmedHandle(response.accounts[0]);
        }
      }
    }
    async function decryptEmail() {
      const response = await authApi.decryptEmail({ email: decodeURIComponent(match.params.email) });
      if (response.success && response.email) {
        setUserEmail(response.email);
      }
    }
    if (match.params.token && !user) {
      fetchAccountsData();
      if (match.params.email) {
        decryptEmail();
      }
    }
  }, []);

  return (
    <Container className="login set-password main-content-container p-3 py-md-5">
      {user ? <Row noGutters>
        <Col className="mx-auto"
          xl={5}
          lg={6}
          md={8}
          sm={10}
          xs={12}>
          <Card>
            <Card.Body>
              <ConfirmPasswordForm onSubmit={user ? changePassword : resetPassword} />
            </Card.Body>
          </Card>
        </Col>
      </Row> :
        <ConfirmPasswordForm onSubmit={user ? changePassword : resetPassword} usernameOptions={usernameOptions} showUsername={showUsername} userEmail={userEmail} />}
    </Container>
  );
}

export default ResetPassword;