/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { applyFilter, applyQuery } from '../../utils';
import { useInfiniteScroll } from '../../utils/hooks';

import Loader from './Loader';
import TableContainer from './table/TableContainer';

import { useAuth, useApp } from '../context';

function DataList({
  slug,
  filterName,
  innerRef,
  limit,
  fetchData,
  fetchOptions = {},
  columns,
  onRefresh,
  onFilter,
  onSortBy,
  onUpdate,
  onFiltered,
  hasPermissions,
  loadingMessage,
  enableManualFilters,
  columnSticky,
  ...rest
}) {
  const { t } = useTranslation();
  const { user, env } = useAuth();
  const { app } = useApp();
  const history = useHistory();

  const [activeFilter, setActiveFilter] = useState(
    filterName ? app.settings.filters.find((filter) => filter.active && filter[filterName]) : false
  );
  const [tableFilters, setTableFilters] = useState({
    filters: activeFilter
      ? activeFilter.filters
      : location.search.length
      ? applyQuery(location.search.substring(1)).filters
      : [],
    sortBy: activeFilter
      ? activeFilter.sortBy
      : location.search.length
      ? applyQuery(location.search.substring(1)).sortBy
      : [],
  });

  const { query, url } = applyFilter({
    filters: onFilter ? onFilter(tableFilters.filters) : [],
    sortBy: onSortBy ? onSortBy(tableFilters.sortBy) : [],
  });

  const {
    data,
    setData,
    lastItem,
    hasMore,
    refresh,
    isLoading,
    isFetching,
    setIsLoading,
    setRefresh,
    loaded,
    setLoaded,
  } = useInfiniteScroll(
    fetchData,
    {
      slug,
      limit,
      query,
      ...fetchOptions,
    },
    // eslint-disable-next-line no-use-before-define
    hasPermissions({ user, loaded, tableFilters })
  );

  useEffect(() => {
    if (loaded && !onFiltered && !onRefresh) setRefresh(true);
    if (onRefresh)
      onRefresh({
        refresh,
        tableFilters,
        setRefresh,
        data,
        setData,
        isLoading,
        setIsLoading,
        loaded,
        setLoaded,
      });
  }, [env, user.currentTeam]);

  useEffect(() => {
    if (loaded && !onFiltered && enableManualFilters) setRefresh(true);
    if (onFiltered)
      onFiltered({
        refresh,
        tableFilters,
        setRefresh,
        data,
        setData,
        isLoading,
        setIsLoading,
        loaded,
        setLoaded,
      });
  }, [tableFilters.filters, tableFilters.sortBy]);

  useEffect(() => {
    let decodeURIstr = location.search.substring(1);
    try {
      decodeURIstr = decodeURI(decodeURIstr);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('DataList: ERROR DECODING URI');
    }
    if (decodeURIstr !== url)
      history.push({
        pathname: location.pathname,
        search: url,
        state: { from: location.pathname },
      });
  }, [url]);

  useEffect(() => {
    if (loaded && onUpdate)
      onUpdate({
        refresh,
        tableFilters,
        setRefresh,
        data,
        setData,
        isLoading,
        setIsLoading,
        loaded,
        setLoaded,
      });
  }, [loaded, data]);

  useEffect(() => {
    if (innerRef && innerRef.current)
      // eslint-disable-next-line no-param-reassign
      innerRef.current = {
        setRefresh,
        setData,
        setIsLoading,
        setLoaded,
      };
  }, [innerRef]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <TableContainer
        columnSticky={columnSticky}
        enableManualFilters={enableManualFilters}
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        filterName={filterName}
        lastItem={!isFetching ? lastItem : undefined}
        columns={columns}
        data={data}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />

      {loaded && isFetching && hasMore && (
        <div className="fetching">
          <Loader label={loadingMessage || t('common:status.loadingMore')} />
        </div>
      )}
    </>
  );
}

DataList.propTypes = {
  /**
   * The inner ref
   */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  /**
   * The unique slug for the data list
   */
  slug: PropTypes.string.isRequired,
  /**
   * The unique name for the table filter stored in local storage
   */
  filterName: PropTypes.string,
  /**
   * The maximum number of items to be displayed before autoscroll
   */
  limit: PropTypes.number.isRequired,
  /**
   * The fetch data function
   */
  fetchData: PropTypes.func.isRequired,
  /**
   * The fetch options object
   */
  fetchOptions: PropTypes.object,
  /**
   * The columns array (Requires useMemo)
   */
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  /**
   * The function on env / team change
   */
  onRefresh: PropTypes.func,
  /**
   * The function on filter change
   */
  onFilter: PropTypes.func,
  /**
   * The function on sort change
   */
  onSortBy: PropTypes.func,
  /**
   * The function when the table data is updated
   */
  onUpdate: PropTypes.func,
  /**
   * The function when the table filters have changed
   */
  onFiltered: PropTypes.func,
  /**
   * The function to validate user permissions
   */
  hasPermissions: PropTypes.func.isRequired,
  /**
   * The message when more items are loading
   */
  loadingMessage: PropTypes.string,
  /**
   * The boolean to enable / disable manual filters
   */
  enableManualFilters: PropTypes.bool,
  /**
   * The boolean to enable table scroll
   */
  columnSticky: PropTypes.bool,
};

DataList.defaultProps = {
  enableManualFilters: true,
};

export default DataList;
