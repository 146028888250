import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import classNames from 'classnames';

import { authApi } from './api';
import { IDLE_TIMEOUT } from './constants';

import Loader from './components/common/Loader';
import InviteModal from './components/common/InviteModal';
import RouteConfig from './components/routes/RouteConfig';
import Alerts from './components/alerts/Alerts';

import { useAuth, useApp, useAlerts } from './components/context';

import routes from './routes';

export default function App() {
  const { user, onLogout, authLoaded, session } = useAuth();
  const { app } = useApp();
  const { setAlert } = useAlerts();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const idleTimerRef = useRef(null);
  const [sessionExpiryCache, setSessionExpiryCache] = useState(0);

  const getTimestamp = (dateObj) => {
    if (!dateObj) {
      return new Date().getTime();
    }
    return new Date(dateObj).getTime();
  };

  // set the value of the sessionExpiryCache if session changes, this happens on login or refresh
  useEffect(() => {
    if (session && session.expiry_date) {
      setSessionExpiryCache(getTimestamp(session.expiry_date));
    }
  }, [session]);

  /*
    Watches the session and sets a timer to handle session expiry.
    Clears the previous timer and starts a new one on sessionExpiryCache updates,
    using the updated session expiry time.
  */
  useEffect(() => {
    let timeout;
    if (sessionExpiryCache && sessionExpiryCache !== 0) {
      let remainingSessionTime = sessionExpiryCache - getTimestamp();

      if (remainingSessionTime > 0) {
        timeout = setTimeout(() => {
          const checkSession = async () => {
            try {
              const response = await authApi.checkSessionExpiry();
              const incomingTime = getTimestamp(response.session.expiry_date);
              remainingSessionTime = incomingTime - sessionExpiryCache;
              if (remainingSessionTime <= 5000) {
                // eslint-disable-next-line no-use-before-define
                onIdle();
              } else setSessionExpiryCache(incomingTime);
            } catch (error) {
              setAlert({ message: error, variant: 'warning' });
            }
          };

          checkSession();
        }, remainingSessionTime - 5000);
      }
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [sessionExpiryCache]);

  const onIdle = async () => {
    setSessionExpiryCache(0);
    if (user) {
      try {
        await authApi.logout();
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        history.push({
          pathname: '/login',
          state: { from: location.pathname, handle: user.entity.emails[0].email },
        });
        onLogout();
        setAlert({ message: t('user.status.inactive'), variant: 'warning', dismissible: true });
      }
    }
  };

  useEffect(() => {
    if (user && window.Intercom) {
      window.Intercom('update', {
        user_id: user.entity.handle,
        email: user.entity.emails[0].email,
        name: user.entity.full_name,
      });
    }
  }, [user]);

  return !authLoaded ? (
    <Loader />
  ) : (
    <IdleTimer
      ref={idleTimerRef}
      element={document}
      onIdle={onIdle}
      debounce={250}
      timeout={IDLE_TIMEOUT}
    >
      <Alerts />
      <div
        className={classNames(
          'app',
          (!user ||
            location.pathname.includes('invite_team_members') ||
            location.pathname.includes('questionnaire')) &&
            'guest'
        )}
      >
        <RouteConfig routes={routes} />
      </div>
      {user && app.invitation && <InviteModal show={!!app.invitation} data={app.invitation} />}
    </IdleTimer>
  );
}
