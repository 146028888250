import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import RouteConfig from './RouteConfig';

import { stripTrailingSlash } from '../../utils';
import { useCurrentDimensions } from '../../utils/hooks';
import { COOKIES_OPTIONS } from '../../constants';

import { useAuth, useApp, useAlerts } from '../context';

const TrackedRoute = ({ route, ...props }) => {
  const { user, env, authFrozen } = useAuth();
  const { app, updateApp } = useApp();
  const { setAlert } = useAlerts();
  const { t } = useTranslation();
  const currentWidth = useCurrentDimensions().width;
  const routeProps = { ...props, t, user, env, user, app, updateApp, setAlert, frozen: authFrozen, isMobile: currentWidth <= 576 };

  useEffect(() => {
    if (!route.disableTracking && !route.routes || route.routes && route.routes.some(route => !route.disableTracking)) {
      Cookies.set('sila_console_referrer', props.location.pathname, COOKIES_OPTIONS);
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (app.isLoading) updateApp({ isLoading: false });
  }, []);

  return stripTrailingSlash(props.location.pathname) !== route.path && route.routes ? <RouteConfig routes={route.routes} /> : (<>
    <Helmet>
      <title>{route.seo && route.seo.title ? route.seo.title : `${t('seo.title')} - ${route.name}`}</title>
      <meta name="description" content={route.seo && route.seo.description ? route.seo.description : route.seo && route.seo.title ? `${t('seo.title')} - ${route.name}` : t('seo.description')} />
    </Helmet>
    <route.layout {...routeProps}>
      <route.component {...routeProps} />
    </route.layout></>
  );
};

TrackedRoute.propTypes = {
  /**
   * The current route
   */
  route: PropTypes.object.isRequired
};

export default TrackedRoute;