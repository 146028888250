import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

const DropdownPersist = ({ show, handleToggle, ...rest }) => {
  const [open, setOpen] = useState(show);
  const onToggle = (isOpen, ev, metadata) => {
    if (metadata.source === "select") {
      setOpen(true);
      return;
    }
    setOpen(isOpen);
    if (handleToggle) handleToggle(isOpen);
  };
  return <Dropdown show={open} onToggle={onToggle} {...rest}></Dropdown>;
};

DropdownPersist.propTypes = {
  /**
   * The visbility boolean
   */
   show: PropTypes.bool,
  /**
   * The function called when toggling the dropdown
   */
   handleToggle: PropTypes.func
};

export default DropdownPersist;