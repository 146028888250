import React, { useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';

import { useHistory } from 'react-router-dom/';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import ConfirmationModal from '../common/ConfirmationModal';

export default function ViewToEditForm({
  initialValues,
  formValidationSchema,
  formContents,
  t,
  handleUpdate,
  handleDelete,
  canUpdateItem,
  isEditing,
  setIsEditing,
  uneditedValues,
  deleteMessage,
  disableEdit = false,
  validate = () => ({}),
  isCreate = false,
  formRef = useRef(null),
}) {
  const [deleting, setDeleting] = useState(false);
  const history = useHistory();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      innerRef={formRef}
      onSubmit={handleUpdate}
      validate={validate}
      enableReinitialize
    >
      {({ errors, dirty, handleSubmit, isSubmitting, resetForm }) => {
        const isDisabled = isCreate
          ? initialValues.frozen || deleting || isSubmitting || Object.keys(errors).length > 0
          : initialValues.frozen ||
            deleting ||
            isSubmitting ||
            Object.keys(errors).length > 0 ||
            !dirty;

        return (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            {isSubmitting && <Loader />}

            {formContents}

            <div className="border-top actions d-flex flex-wrap justify-content-end mx-0 mb-0 mt-4 p-0 mx-md-n4 mb-md-n4 p-md-4">
              {isEditing && canUpdateItem && (
                <>
                  {!isCreate && (
                    <Button
                      variant={deleting ? 'warning' : 'outline-warning'}
                      onClick={() => setDeleting(true)}
                      className="px-4 mr-0 mr-auto"
                    >
                      {t(deleting ? 'common:status.deleting' : 'common:buttons.delete')}
                    </Button>
                  )}

                  <Button
                    variant="outline-light"
                    onClick={() => {
                      setIsEditing(false);
                      resetForm(uneditedValues);
                      if (isCreate) history.goBack();
                    }}
                    className={`px-4 ${isCreate ? 'mr-auto' : 'ml-0'}`}
                  >
                    {t('common:buttons.cancel')}
                  </Button>
                  <LoaderButton
                    variant="primary"
                    loading={isSubmitting ? true : undefined}
                    disabled={isDisabled}
                    type="submit"
                    className="ml-0 ml-md-3 px-4"
                  >
                    {t('common:buttons.save')}
                  </LoaderButton>
                </>
              )}
              {!isEditing && (
                <Button
                  variant="outline-light"
                  onClick={() => setIsEditing(true)}
                  className="px-4 ml-0"
                  disabled={disableEdit}
                >
                  {t('common:buttons.edit')}
                </Button>
              )}
            </div>

            {deleting && (
              <ConfirmationModal
                show={deleting}
                data={initialValues}
                title={t('common:buttons.confirm')}
                message={deleteMessage}
                buttonLabel={t('common:buttons.delete')}
                buttonLoadingLabel={t('common:status.deleting')}
                buttonVariant="warning"
                onHide={() => setDeleting(false)}
                handleSubmit={() => {
                  handleDelete();
                  setDeleting(false);
                }}
                setShow={setDeleting}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
