import { SUPERUSER_LEVEL, SUPPORT_URL } from './';
import { hasPermissions, requestProdAccess, hasSuperuserAccess, hasSalesAccess } from '../utils';

export const primaryNav = [{
    name: 'home',
    title: 'sidebar.navigation.home',
    to: '/welcome',
    htmlBefore: '<i class="sila-icon home"></i>',
    htmlAfter: ''
  },
  {
    name: 'apps',
    title: 'sidebar.navigation.applications',
    htmlBefore: '<i class="sila-icon apps"></i>',
    to: '/apps'
  },
  {
    name: 'settings',
    title: 'sidebar.navigation.settings',
    htmlBefore: '<i class="sila-icon settings"></i>',
    to: '/settings'
  },
  {
    name: 'developers',
    title: 'sidebar.navigation.developer',
    htmlBefore: '<i class="sila-icon developers"></i>',
    to: '/dev',
    permissions: (user) => hasPermissions(user, 'webhooks', 'Read')
  },
  {
    name: 'admin',
    title: 'sidebar.navigation.admin',
    htmlBefore: '<i class="sila-icon individual"></i>',
    to: '/admin',
    permissions: (user) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])
  },
  {
    name: 'sales',
    title: 'sidebar.navigation.sales',
    htmlBefore: '<i class="sila-icon business"></i>',
    to: '/sales',
    permissions: (user) => hasSalesAccess(user),
  },
];

export const secondaryNav = [
  {
    name: 'onboarding',
    title: 'sidebar.navigation.onboarding',
    htmlBefore: '<i class="sila-icon check"></i>',
    href: '#',
    permissions: (user) => !user.approved,
    onClick: requestProdAccess
  },
  {
    name: 'support',
    title: 'sidebar.navigation.support',
    htmlBefore: '<i class="sila-icon support"></i>',
    href: SUPPORT_URL,
    newWindow: true
  },
  {
    name: 'docs',
    title: 'sidebar.navigation.documentation',
    htmlBefore: '<i class="sila-icon docs"></i>',
    href: 'https://docs.silamoney.com',
    newWindow: true
  }
];