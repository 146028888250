import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import FieldWrapper from '../form/wrappers/Field';

const AddFilterModal = ({ show, data, onHide, onDelete, filters }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(data.name);

  return (
    <Modal centered id="save-filter-modal"
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="save-filter-modal-title">
      <Formik
        initialValues={data}
        validationSchema={yup.object().shape({
          name: yup.string().trim()
            .required(t('common:form.errors.required', {
              field: t('common:filters.form.fields.filter.label')
            }))
            .test('unique', t('common:filters.form.fields.filter.exists', { name }), (value) => data.name === value || value && !filters.includes(value.toLowerCase()))
            .max(30, t('common:form.errors.max', { count: 30 })),
        })}
        onSubmit={onHide}>
        {({ errors, handleSubmit, dirty }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title as="h3" id="save-filter-modal-title">{t('common:filters.buttons.save')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-lg">{t('common:filters.form.description')}</p>
              <Field required className="filter-name"
                id="filterForm.name"
                name="name"
                placeholder={t('common:filters.form.fields.filter.label')}
                handleChange={(e) => setName(e.target.value)}
                component={FieldWrapper} />
              <Field className="filter-active"
                id="filterForm.active"
                name="active"
                type="checkbox"
                component={FieldWrapper}>
                {t('common:filters.form.fields.default.label')}
              </Field>
            </Modal.Body>
            <Modal.Footer>
              <Button variant={data.id ? 'warning' : 'outline-light'} onClick={() => data.id ? onDelete(data.id) : onHide()}>{t(`common:buttons.${data.id ? 'delete' : 'cancel'}`)}</Button>
              <Button disabled={!dirty || Object.keys(errors).length} type="submit">{t(`common:filters.buttons.${data.id ? 'update' : 'save'}`)}</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
};

AddFilterModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The filter object
   */
  filter: PropTypes.object,
  /**
   * The filters array
   */
  filters: PropTypes.array.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default AddFilterModal;