import React, { useState, useRef } from 'react';
import { Accordion, Table, Form, Col, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { useAlerts } from '../../context';

import AccordionItem from '../AccordionItem';
import ConfirmModal from '../ConfirmModal';
import FieldWrapper from '../../form/wrappers/Field';
import LoaderButton from '../LoaderButton';
import Loader from '../Loader';

import { formatDateAndTime, hasSuperuserAccess } from '../../../utils';
import {
  RETURN_CODES, REQUEST_RETURN_STATUS, REQUEST_RETURN_PENDING_REVIEW, REQUEST_RETURN_OPEN, REQUEST_RETURN_WITHDRAWN,
  REQUEST_RETURN_SUBMITTED, REQUEST_RETURN_COMPLETED, REQUEST_RETURN_REQUEST_FOR_WITHDRAW, REQUEST_RETURN_DENIED,
  SUPERUSER_LEVEL
} from '../../../constants';

import { teamApi, adminApi } from '../../../api';

const RequestReturnForm = ({ user, customer, initialValues, getRequestReturn }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const formRef = useRef(null);
  const [expanded, setExpanded] = useState([1]);
  const [status, setStatus] = useState(undefined);
  const [returnData, setReturnData] = useState({ show: false, data: undefined });

  const handleExpanded = (key) => setExpanded(expanded.includes(key) ? expanded.filter(e => e !== key) : [...expanded, key]);
  const accordionItemProps = { expanded, onSetExpanded: handleExpanded };
  const accordionActiveKey = (key) => expanded.includes(key) && key;

  const disabledRequestStatus = (!customer && [REQUEST_RETURN_PENDING_REVIEW, REQUEST_RETURN_REQUEST_FOR_WITHDRAW, REQUEST_RETURN_DENIED].includes(initialValues.request_status_name)) ? false : true;
  const disabledRequestWithdrawBtn = (customer && [REQUEST_RETURN_PENDING_REVIEW, REQUEST_RETURN_OPEN].includes(initialValues.request_status_name)) ? false : true;
  const hasSuperUserAccess = hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]);

  let disabledRCode = (customer && initialValues.request_status_name === REQUEST_RETURN_OPEN) ? false : true;
  if (!customer && initialValues.request_status_name === REQUEST_RETURN_PENDING_REVIEW) {
    disabledRCode = false;
  }

  const updateRequestReturn = async (values) => {
    if (values) {
      try {
        values = { ...values, team_id: user.currentTeam.id };
        if (hasSuperUserAccess && !customer) {
          const response = await adminApi.updateAdminRequestReturn(initialValues.uuid, values);
          if (response.success && status && status === REQUEST_RETURN_SUBMITTED) {
            await adminApi.updateAdminRequestReturnSubmitted({
              ...values,
              transaction_uuid: initialValues.transaction_uuid,
              return_code: initialValues.return_code
            });
          }
        } else {
          await teamApi.updateRequestReturn(initialValues.uuid, values);
        }
        getRequestReturn();
        setAlert({ message: t('common:transactions.RequestsForReturn.detail.messages.update'), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        formRef.current.resetForm({ values: initialValues });
      } finally {
        setReturnData({ ...returnData, show: false, data: undefined });
      }
    } else {
      formRef.current.setSubmitting(false);
      setReturnData({ ...returnData, show: false, data: undefined });
    }
  }

  const handleStatus = (status) => {
    if (status) {
      setReturnData({ ...returnData, show: true, data: { return_request_status: status } });
    }
  }

  return (<>
    <Accordion id="returnDetail" className="mb-4" activeKey={accordionActiveKey(1)}>
      <AccordionItem className="border rounded"
        eventKey={1}
        label={t('common:transactions.RequestsForReturn.detail.headers.returnDetail')}
        {...accordionItemProps}>
        <div className="m-n4">
          <Table responsive>
            <thead>
              <tr>
                <th className="py-2 pl-4 border-top" width="50%">{t('common:labels.detail')}</th>
                <th className="py-2 pr-4 border-top" width="50%">{t('common:labels.data')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.created')}</td>
                <td className="py-2 pr-4" width="50%">{formatDateAndTime(initialValues.date_submitted)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.lastUpdated')}</td>
                <td className="py-2 pr-4" width="50%">{formatDateAndTime(initialValues.last_update)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.RequestsForReturn.detail.labels.transactionUUID')}</td>
                <td className="py-2 pr-4 text-underline" width="50%"><Button href={`/${!customer ? 'admin' : 'settings'}/transaction/${initialValues.transaction_uuid}/detail`} variant="link" className="text-reset text-underline p-0">{initialValues.transaction_uuid}</Button></td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.amount')}</td>
                <td className="py-2 pr-4" width="50%"><i className="sila-icon sila text-sm text-primary mr-1"></i>{initialValues.amount}</td>
              </tr>
              {!customer && initialValues && initialValues.app && <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.appHandle')}</td>
                <td className="py-2 pr-4 text-underline" width="50%"><Button href={`/admin/app/${initialValues.app.app_id}/detail`} variant="link" className="text-reset text-underline p-0">{initialValues.app.app_handle}</Button></td>
              </tr>}
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.userHandle')}</td>
                <td className="py-2 pr-4" width="50%">
                  {!customer && initialValues.user_handle !== 'virtual_accounts_external_originator' ? <Button href={`/admin/end_user/${initialValues.user_handle}/profile`} variant="link" className="text-reset text-underline p-0">{initialValues.user_handle}</Button> : initialValues.user_handle}
                </td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.RequestsForReturn.labels.dateOfTransaction')}</td>
                <td className="py-2 pr-4" width="50%">{initialValues.date_of_transaction}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.RequestsForReturn.labels.daysSinceTransaction')}</td>
                <td className="py-2 pr-4" width="50%">{initialValues.days_since_transaction}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.RequestsForReturn.labels.dateInitialContact')}</td>
                <td className="py-2 pr-4" width="50%">{initialValues.date_of_customer_contact}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </AccordionItem>
    </Accordion>

    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        return_code: initialValues && initialValues.return_code ? initialValues.return_code : undefined,
        return_request_status: initialValues && initialValues.request_status_name ? initialValues.request_status_name : undefined
      }}
      validationSchema={yup.object().shape({
        return_code: yup.string().required(t('common:form.errors.required', { field: t('common:transactions.labels.returnCode') })),
        return_request_status: hasSuperUserAccess && !customer ? yup.string().required(t('common:form.errors.required', { field: t('common:transactions.detail.requestReturn.form.fields.returnStatus.label') })) : null
      })}
      onSubmit={(values) => {
        if (customer && initialValues.request_status_name === REQUEST_RETURN_OPEN) {
          values = { ...values, return_request_status: REQUEST_RETURN_PENDING_REVIEW };
        }
        setReturnData({ ...returnData, show: true, data: values });
      }}>
      {({ values, errors, handleSubmit, isSubmitting, dirty, setFieldValue, ...rest }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
          {isSubmitting && <Loader />}
          <Form.Row>
            <Col xs="12" sm={customer ? '12' : '6'}>
              <Field required disabled={disabledRCode} className="returnCode"
                type="select"
                id="RequestReturnForm.return_code"
                name="return_code"
                labelClass="text-hdr mb-3"
                label={`${t(`common:transactions.labels.${!customer ? 'returnReason' : 'returnCode'}`)}:`}
                placeholder={t('common:transactions.detail.requestReturn.form.fields.returnCode.placeholder')}
                options={RETURN_CODES.map(code => ({ value: code, label: code }))}
                component={FieldWrapper} />
            </Col>
            {hasSuperUserAccess && !customer && <Col xs="12" sm="6">
              <Field required disabled={disabledRequestStatus} className="returnRequestStatus"
                type="select"
                id="RequestReturnForm.return_request_status"
                name="return_request_status"
                labelClass="text-hdr mb-3"
                label={`${t('common:transactions.detail.requestReturn.form.fields.returnStatus.label')}:`}
                options={REQUEST_RETURN_STATUS[initialValues.request_status_name].map(status => ({ value: status.value, label: status.label }))}
                handleChange={(e) => setStatus(e.target.value)}
                component={FieldWrapper} />
            </Col>}
          </Form.Row>

          <footer className="d-flex">

            {customer && <p className="text-muted"><Trans i18nKey="common:transactions.RequestsForReturn.detail.description">View <a href="https://docs.silamoney.com/docs/ach-return-codes#ach-return-codes" target="_blank">our docs</a> to see all the ACH return codes and their meanings.</Trans></p>}

            <div className="ml-auto">

              {customer && initialValues.request_status_name !== REQUEST_RETURN_WITHDRAWN && <Button disabled={disabledRequestWithdrawBtn} onClick={() => handleStatus(initialValues.request_status_name === REQUEST_RETURN_OPEN ? REQUEST_RETURN_WITHDRAWN : REQUEST_RETURN_REQUEST_FOR_WITHDRAW)} variant="outline-warning" className="mr-4">{t(`common:transactions.RequestsForReturn.detail.buttons.${initialValues.request_status_name === REQUEST_RETURN_OPEN ? REQUEST_RETURN_WITHDRAWN : [REQUEST_RETURN_SUBMITTED, REQUEST_RETURN_COMPLETED].includes(initialValues.request_status_name) ? 'withdrawRequest' : 'requestWithdraw'}`)}</Button>}

              {customer && initialValues.request_status_name === REQUEST_RETURN_WITHDRAWN && <Button onClick={() => handleStatus(REQUEST_RETURN_OPEN)} variant="outline-light" className="mr-4">{t('common:transactions.RequestsForReturn.detail.buttons.reopenRequest')}</Button>}

              <LoaderButton loading={isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={!dirty || Object.keys(errors).length}>{t('common:buttons.update')}</LoaderButton>
            </div>

          </footer>

        </Form>
      )}
    </Formik>

    {returnData.data && <ConfirmModal
      show={returnData.show}
      data={returnData.data}
      title={t(`common:transactions.RequestsForReturn.detail.${returnData.data.return_request_status === REQUEST_RETURN_REQUEST_FOR_WITHDRAW ? 'requestWithdrawConfirm' : customer && returnData.data.return_request_status === REQUEST_RETURN_WITHDRAWN ? 'withdrawConfirm' : returnData.data.return_request_status === REQUEST_RETURN_OPEN && initialValues.request_status_name === REQUEST_RETURN_WITHDRAWN ? 'reopenConfirm' : 'updateConfirm'}.title`)}
      renderMessageComponent={() => <>
        {customer && <p className="text-lg mb-4">{t(`common:transactions.RequestsForReturn.detail.${returnData.data.return_request_status === REQUEST_RETURN_REQUEST_FOR_WITHDRAW ? 'requestWithdrawConfirm' : returnData.data.return_request_status === REQUEST_RETURN_WITHDRAWN ? 'withdrawConfirm' : returnData.data.return_request_status === REQUEST_RETURN_OPEN && initialValues.request_status_name === REQUEST_RETURN_WITHDRAWN ? 'reopenConfirm' : 'updateConfirm'}.description`)}</p>}
        {!customer && <p className="text-lg mb-4">
          {t(`common:transactions.RequestsForReturn.detail.${status ? 'statusConfirm' : 'updateConfirm'}.description`, { status: t(`common:transactions.RequestsForReturn.status.${status}`) })}
          {status && status === REQUEST_RETURN_SUBMITTED && t('common:transactions.RequestsForReturn.detail.statusConfirm.submitted')}
          {status && status === REQUEST_RETURN_WITHDRAWN && t('common:transactions.RequestsForReturn.detail.statusConfirm.withdrawn')}
        </p>}
      </>}
      buttonLabel={t(!customer ? 'common:buttons.update' : returnData.data.return_request_status === REQUEST_RETURN_REQUEST_FOR_WITHDRAW ? 'common:transactions.RequestsForReturn.detail.buttons.requestWithdraw' : returnData.data.return_request_status === REQUEST_RETURN_WITHDRAWN ? 'common:transactions.RequestsForReturn.detail.buttons.withdraw' : returnData.data.return_request_status === REQUEST_RETURN_OPEN && initialValues.request_status_name === REQUEST_RETURN_WITHDRAWN ? 'common:buttons.reOpen' : 'common:buttons.save')}
      onHide={updateRequestReturn} />}
  </>
  );
};

RequestReturnForm.propTypes = {
  /**
   * The customer boolean to determine the view for customer or admin
   */
  customer: PropTypes.bool,
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to get the request return data
   */
  getRequestReturn: PropTypes.func.isRequired,
};

export default RequestReturnForm;