import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nav } from 'react-bootstrap';

import { useAuth, useApp } from '../../../components/context';

const SidebarNavItem = ({ item, menuVisible }) => {
  const { user } = useAuth();
  const { updateApp } = useApp();
  const { t } = useTranslation();
  const location = useLocation();

  const handleClick = (e) => {
    if (item.onClick) {
      e.preventDefault();
      item.onClick(t, user);
    }
    updateApp({ menuVisible: !menuVisible });
  };

  return (
    <Nav.Item className={item.classes || undefined}>
      <Nav.Link as={item.to ? NavLink : undefined} to={item.to ? { pathname: item.to, state: { from: location.pathname } } : undefined} onClick={handleClick} href={item.href ? item.href : undefined} target={item.newWindow ? '_blank' : undefined}>
        {item.htmlBefore && (
          <span
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
          />
        )}
        {item.title && <span className="sidebar-hover">{t(item.title)}</span>}
        {item.htmlAfter && (
          <span
            className="d-inline-block item-icon-wrapper"
            dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
          />
        )}
      </Nav.Link>
    </Nav.Item>
  )
};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
