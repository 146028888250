import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SilaLogo from '../../../components/common/SilaLogo';

import { useApp } from '../../context';

const SidebarMainNavbar = ({ hideLogoText }) => {
  const { t } = useTranslation();
  const { app, updateApp } = useApp();

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    updateApp({ menuVisible: !app.menuVisible });
  };

  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-black flex-md-nowrap p-0"
        type="light"
      >
        <NavbarBrand
          className="d-flex w-100 py-0 m-0"
          href="/"
        >
          <div className="d-flex align-items-center my-auto">
            <span className="main-logo-container overflow-hidden d-inline-block">
              <SilaLogo id="main-logo" fill="#FFF" width="80" height="42" className="logo" />
            </span>
            {!hideLogoText && <span className="sidebar-hover d-inline-block d-md-block d-lg-inline-block align-middle ml-3 name">{t('console.title')}</span>}
          </div>
        </NavbarBrand>
        {/* eslint-disable-next-line */}
        <a
          className="toggle-sidebar d-sm-flex d-md-none d-lg-none"
          onClick={handleToggleSidebar}
        >
          <i className="fas fa-arrow-left align-self-center"></i>
        </a>
      </Navbar>
    </div>
  );
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
