import Cookies from 'js-cookie';

import consoleApi from './console';
import teamApi from './team';

import { removeEmpty, simpleHandle, handleResponseError } from '../utils';
import { COOKIES_OPTIONS } from '../constants';

const currentTeam = parseInt(Cookies.get('sila_console_team'), 10);

const dataLayer = window.dataLayer || [];

const combinerUserData = async (response, team = currentTeam) => {
  try {
    const [teamsResponse, prodApproved] = await Promise.all([
      consoleApi.get({ url: '/teams' }),
      consoleApi.get({ url: '/check_approved' }),
    ]);
    if (!teamsResponse.success) {
      throw handleResponseError(teamsResponse);
    }
    if (teamsResponse.success && teamsResponse.teams.length) {
      response.user.teams = teamsResponse.teams;
      if (team && teamsResponse.teams.some((teamData) => teamData.team.id === team)) {
        response.user.currentTeam = teamsResponse.teams.find(
          (teamData) => teamData.team.id === team
        ).team;
      } else {
        Cookies.set('sila_console_team', teamsResponse.teams[0].team.id, COOKIES_OPTIONS);
        Cookies.set('sila_console_team_name', teamsResponse.teams[0].team.name, COOKIES_OPTIONS);
        response.user.currentTeam = teamsResponse.teams.find(
          (teamData) => teamData.team.id === teamsResponse.teams[0].team.id
        ).team;
      }
      try {
        const [appsResponse, membersResponse, teamDataResponse, permissionsResponse] =
          await Promise.all([
            consoleApi.get({
              url: '/apps',
              data: {
                team_id: response.user.currentTeam.id,
                limit: 1,
                page: 1,
              },
            }),
            teamApi.fetchMembers({ team: response.user.currentTeam.id, limit: 2 }),
            consoleApi.get({ url: `/teams/${response.user.currentTeam.id}` }),
            consoleApi.get({
              url: `/members/${response.user.entity.id}`,
              data: {
                team_id: response.user.currentTeam.id,
              },
            }),
          ]);
        if (!appsResponse.success) throw handleResponseError(appsResponse);
        if (!membersResponse.success) throw handleResponseError(membersResponse);
        if (!teamDataResponse.success) throw handleResponseError(teamDataResponse);
        if (!permissionsResponse.success) throw handleResponseError(permissionsResponse);
        response.user.hasApps = appsResponse.apps.length !== 0;
        response.user.hasMembers = membersResponse.members.length > 1;
        response.user.approved = prodApproved.success;
        response.user.currentTeam = {
          ...response.user.currentTeam,
          ...teamDataResponse.team,
          page: 1,
          total_pages: teamsResponse.pagination.total_pages,
        };
        response.user.permissions = permissionsResponse.role.permissions;
        response.user.role = permissionsResponse.role.id;
        response.user.currentTeamPage = 1;
        response.user.totalPage = teamsResponse.pagination.total_pages;
      } catch (error) {
        throw error;
      }
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const handleUserData = async (data) => {
  let response = data;
  if (!Cookies.get('sila_console_registered')) {
    Cookies.set('sila_console_registered', true, COOKIES_OPTIONS);
  }
  Cookies.set('sila_console_handle', response.user.entity.handle, COOKIES_OPTIONS);
  Cookies.set('sila_console_email', response.user.entity.emails[0].email, COOKIES_OPTIONS);
  try {
    response = await combinerUserData(response);
  } catch (error) {
    throw error;
  }
  return response;
};

// eslint-disable-next-line consistent-return
const refreshSession = async (team = currentTeam) => {
  try {
    let response = await consoleApi.get({ url: '/session_validation' });
    if (!response.success) {
      throw handleResponseError(response);
    } else if (!response.user.frozen) {
      try {
        response = await combinerUserData(response, team);
        if (response.success) return response;
      } catch (error) {
        throw error;
      }
    }
  } catch (error) {
    throw error;
  }
};

// eslint-disable-next-line consistent-return
const checkSessionExpiry = async (_team = currentTeam) => {
  try {
    const response = await consoleApi.get({ url: '/check_expiry_date' });
    if (!response.success) {
      throw handleResponseError(response);
    }
    if (response.success) return response;
  } catch (error) {
    throw error;
  }
};

const confirmRegistration = async (token) => {
  try {
    const response = await consoleApi.get({
      url: `/account/confirm/${token}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
  } catch (error) {
    throw error;
  }
};

const confirmAccountExists = async (email) => {
  try {
    const response = await consoleApi.post({
      url: '/has_login',
      data: {
        email,
      },
    });
    if (!response.success) {
      return false;
    }
    return response.has_login;
  } catch (error) {
    throw error;
  }
};

const authenticate = async (values) => {
  const data = {
    user_handle: simpleHandle(values.handle),
    passphrase: values.password,
    sila_device_id: Cookies.get('sila_console_device_id'),
    user_agent: navigator.userAgent,
    via: values.via ? values.via : 'login',
  };
  if (values.ctoken) {
    data.ctoken = values.ctoken;
  }
  try {
    const response = await consoleApi.post({ url: '/login', data });
    if (!response.success) {
      return response;
    }
    if (response.total_count && response.total_count > 1) {
      return response;
    }
    if (response.user && response.user.mfa_required) {
      return response;
    }
    return handleUserData(response);
  } catch (error) {
    throw error;
  }
};

const logout = async () => {
  try {
    const response = await consoleApi.post({
      url: '/logout',
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    Cookies.remove('sila_console_handle');
    Cookies.remove('sila_console_email');
    Cookies.remove('sila_console_team');
    Cookies.remove('sila_console_team_name');
    Cookies.remove('sila_console_referrer');
    return response.message;
  } catch (error) {
    throw error;
  }
};

const register = async (values) => {
  const data = {
    passphrase: values.password,
    company_name: values.company_name,
    entity: {
      first_name: values.first_name,
      surname: values.surname,
      full_name: `${values.first_name} ${values.surname}`,
      emails: [
        {
          email: values.email,
          primary: true,
        },
      ],
      phones: [
        {
          phone: values.phone,
          primary: true,
        },
      ],
    },
  };
  if (values.ctoken) {
    data.ctoken = values.ctoken;
  }
  if (values.invitation_token) {
    data.invitation_token = values.invitation_token;
    data.invitation_env = values.invitation_env;
  }
  try {
    const response = await consoleApi.post({ url: '/profile', data });
    if (!response.success) {
      throw handleResponseError(response);
    }
    if (!Cookies.get('sila_console_registered')) {
      Cookies.set('sila_console_registered', true, COOKIES_OPTIONS);
    }
    dataLayer.push({ event: 'console_registration_completed' });
    window.lintrk('track', { conversion_id: 15614649 });
    return response;
  } catch (error) {
    throw error;
  }
};

const resendVerificationEmail = async (handle, email) => {
  try {
    const response = await consoleApi.post({
      url: '/resend_verification_email',
      data: {
        handle,
        email,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const resendVerificationEmailResendDate = async (handle) => {
  try {
    const response = await consoleApi.post({
      url: `/verification_last_sent/${handle}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    if (response && response.message && response.message.resend_validation_email_at) {
      return response.message.resend_validation_email_at;
    }
    return null;
  } catch (error) {
    throw error;
  }
};

const updateProfile = async (values) => {
  try {
    const response = await consoleApi.put({
      url: '/profile',
      data: {
        login: {
          entity: removeEmpty({
            ...values,
            full_name:
              values.first_name && values.surname
                ? `${values.first_name} ${values.surname}`
                : values.full_name,
          }),
        },
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (token, handle, passphrase, ctoken) => {
  const data = {
    token,
    handle: simpleHandle(handle),
    passphrase,
  };
  if (ctoken) {
    data.ctoken = ctoken;
  }
  try {
    const response = await consoleApi.put({ url: '/passphrase', data });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const requestNewPassword = async (values) => {
  try {
    const response = await consoleApi.delete({
      url: '/passphrase',
      data: values,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const checkHandle = async (value) => {
  try {
    const response = await consoleApi.post({
      url: '/check_handle',
      data: {
        entity: {
          handle: simpleHandle(value),
        },
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const checkEmail = async (value) => {
  try {
    const response = await consoleApi.post({
      url: '/check_email',
      data: {
        user_handle: value,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const checkTeamName = async (value) => {
  try {
    const response = await consoleApi.post({
      url: '/check_team_name',
      data: {
        name: value,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const confirmPassword = async (handle, passphrase, ctoken) => {
  const data = {
    user_handle: handle,
    passphrase,
  };
  if (ctoken) {
    data.ctoken = ctoken;
  }
  try {
    const response = await consoleApi.post({ url: '/confirm_passphrase', data });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const changePassword = async (passphrase) => {
  try {
    const response = await consoleApi.put({
      url: '/profile',
      data: {
        login: { passphrase },
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
  } catch (error) {
    throw error;
  }
};

const fetchRole = async (id) => {
  try {
    const response = await consoleApi.get({
      url: '/roles',
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response.roles.find((role) => role.id === id);
  } catch (error) {
    throw error;
  }
};

const updateSignUpStep = async (values) => {
  try {
    const response = await consoleApi.put({
      url: '/profile',
      data: {
        login: removeEmpty(values),
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response.user.entity;
  } catch (error) {
    throw error;
  }
};

const getAccounts = async (values) => {
  try {
    const response = await consoleApi.get({
      url: '/accounts',
      data: values,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const decryptEmail = async (values) => {
  try {
    const response = await consoleApi.get({
      url: '/decrypt_email',
      data: values,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const mfaVerify = async (handle, otp) => {
  try {
    const response = await consoleApi.post({
      url: '/mfa_verify',
      data: {
        mfa_code: otp,
        user_handle: simpleHandle(handle),
        sila_device_id: Cookies.get('sila_console_device_id'),
        user_agent: navigator.userAgent,
      },
    });
    if (response.verified) {
      return handleUserData(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const mfaCodeResend = async (handle) => {
  try {
    const response = await consoleApi.post({
      url: '/mfa_code_resend',
      data: {
        user_handle: simpleHandle(handle),
        sila_device_id: Cookies.get('sila_console_device_id'),
        user_agent: navigator.userAgent,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const mfaDeviceRemember = async (handle, values) => {
  try {
    const response = await consoleApi.post({
      url: '/mfa_device_remember',
      data: {
        user_handle: simpleHandle(handle),
        remember_me: values.mfa_device_remember === 'yes',
        sila_device_id: Cookies.get('sila_console_device_id'),
        user_agent: navigator.userAgent,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

// const fetchActivity = (prevCount, offset) => {
//   // API CALL HERE
//   // DUMMY DATA
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve(dummyData.slice(prevCount, offset).reduce((acc, entry) => {
//         return [
//           ...acc, {
//             ...entry
//           },
//         ];
//       }, []));
//     }, 1500)
//   })
// }

const authApi = {
  combinerUserData,
  refreshSession,
  confirmRegistration,
  authenticate,
  confirmAccountExists,
  logout,
  register,
  resendVerificationEmail,
  resendVerificationEmailResendDate,
  updateProfile,
  resetPassword,
  requestNewPassword,
  checkTeamName,
  checkHandle,
  checkEmail,
  changePassword,
  confirmPassword,
  fetchRole,
  updateSignUpStep,
  getAccounts,
  decryptEmail,
  mfaVerify,
  mfaCodeResend,
  mfaDeviceRemember,
  checkSessionExpiry,
  // fetchActivity
};

export default authApi;
