import React, { useEffect, useState, useRef } from 'react';
import { Card, Accordion, Form, Button, Collapse, Fade } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { BLOCKCHAIN_ADDRESS_REG_EXP, HANDLE_REG_EXP, AUTH_TYPE_JWT, AUTH_TYPE_WALLET, SLACK_EVENT_TRACKING_CHANNEL } from '../../constants';

import { appsApi, consoleApi } from '../../api';
import { capitalize } from '../../utils';
import { useDebounce } from '../../utils/hooks';

import FieldWrapper from '../form/wrappers/Field';
import AccordionItem from '../common/AccordionItem';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import KeyGenForm from './KeyGenForm';
import ApplicationCreateModal from './ApplicationCreateModal';

import { useAuth, useAlerts } from '../context';

const ApplicationCreateForm = ({ isMobile }) => {
  const { setAlert } = useAlerts();
  const { user, onUpdateUser, env } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const appNameRef = useRef(null);
  const appBrandNameRef = useRef(null);
  const handleRef = useRef(null);
  const formRef = useRef(null);
  
  const [expanded, setExpanded] = useState(1);
  const [panels, setPanels] = useState([expanded])
  const [confirm, setconfirm] = useState({ show: false, data: undefined });
  const [authType, setAuthType] = useState(AUTH_TYPE_JWT);
  const [name, setName] = useState('');
  const [handle, setHandle] = useState('');
  const [isValid, setIsValid] = useState({
    handle: true,
    name: true,
    pending: false,
    complete: false
  });
  
  const accordionItemProps = { expanded, onSetExpanded: setExpanded };
  const steps = { current: expanded, total: authType === AUTH_TYPE_JWT ? 3 : 4 }

  const initialValues = authType === AUTH_TYPE_JWT ? {
    name: '',
    brand_name: '',
    handle: '',
    authentication: AUTH_TYPE_JWT
  } : {
    name: '',
    brand_name: '',
    handle: '',
    blockchain_address: '',
    privateKey: false,
    authentication: AUTH_TYPE_WALLET
  };

  const debouncedName = useDebounce(name, 750);
  const debouncedHandle = useDebounce(handle, 750);

  const schema = [
    yup.object().shape({
      name: yup.string().trim()
        .required(t('common:form.errors.required', { field: t('applications.detail.form.fields.name.label') }))
        .max(32, t('common:form.errors.max', { count: 32 }))
        .test('checkAppName', t('common:form.errors.exists', { field: t('applications.detail.form.fields.name.label') }), (value) => isValid.name),
      brand_name: yup.string().trim()
        .required(t('common:form.errors.required', { field: t('applications.detail.form.fields.brand_name.label') }))
        .max(32, t('common:form.errors.max', { count: 32 }))
    }),
    yup.object().shape({
      handle: yup.string()
        .matches(HANDLE_REG_EXP, {
          message: t('common:form.fields.handle.error')
        })
        .required(t('common:form.errors.required', { field: t('applications.detail.form.fields.handle.label') }))
        .min(3, t('common:form.errors.min', { count: 3 }))
        .max(100, t('common:form.errors.max', { count: 100 }))
        .test('checkAppHandle', t('common:form.errors.exists', { field: t('applications.detail.form.fields.handle.label') }), (value) => isValid.handle),
    }),
    null,
    yup.object().shape({
      blockchain_address: authType !== AUTH_TYPE_JWT ? yup.string()
        .matches(BLOCKCHAIN_ADDRESS_REG_EXP, {
          message: t('applications.detail.form.fields.address.error')
        })
        .required(t('common:form.errors.required', { field: t('applications.detail.form.fields.address.label') })) : null,
    })
  ];

  const sendSlackMessage = async (data) => {
    try {
      await consoleApi.sendSlackMessage({
        channel: SLACK_EVENT_TRACKING_CHANNEL,
        message: t('applications.create.event', data)
      });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const checkAppHandle = async (value) => {
    if (value) {
      setIsValid({ ...isValid, handle: false, pending: true });
      try {
        const response = await appsApi.checkAppHandle(value);
        const isValidStatus = response && response.success === true ? true : false;
        setIsValid({ ...isValid, handle: isValidStatus, pending: false, complete: true })
      } catch (error) {
        setIsValid({ ...isValid, handle: false, pending: false, complete: true });
        formRef.current.setSubmitting(false);
        setExpanded(2);
      } finally {
        formRef.current.setFieldTouched('handle', true);
      }
    } else {
      setIsValid({ ...isValid, handle: true, pending: false, complete: false });
    }
  };

  const checkAppName = async (value) => {
    if (value) {
      setIsValid({ ...isValid, name: false, pending: true });
      try {
        const response = await appsApi.checkAppName(value);
        const isValidStatus = response && response.success === true ? true : false;
        setIsValid({ ...isValid, name: isValidStatus, pending: false, complete: true })
      } catch (error) {
        setIsValid({ ...isValid, name: false, pending: false, complete: true });
        formRef.current.setSubmitting(false);
        setExpanded(1);
      } finally {
        formRef.current.setFieldTouched('name', true);
      }
    } else {
      setIsValid({ ...isValid, name: true, pending: false, complete: false });
    }
  };

  const createApplication = async (values, { setSubmitting }) => {
    if (values) {
      try {
        const response = await appsApi.createApp(values, user.currentTeam.id);
        setAlert({ message: response.message, variant: 'success' });
        sendSlackMessage({
          app_name: values.name,
          app_handle: values.handle,
          team: user.currentTeam.name,
          env: capitalize(env),
          name: `${user.entity.first_name} ${user.entity.surname}`,
          handle: user.entity.handle,
          email: user.entity.emails[0].email,
          type: values.authentication.toUpperCase(),
          source: t(`common:applications.${response.app_credentials ? 'client_id' : 'address'}`),
          address: response.app_credentials
          ? response.app_credentials.client_id : values.blockchain_address
        });
        if (!user.hasApps) onUpdateUser({ hasApps: true });
        if (response.app_credentials) {
          setconfirm({ show: true, data: { ...values, ...response.app_credentials } });
        } else {
          setconfirm({ show: true, data: values });
        }
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setSubmitting(false);
      }
    } else {
      setRedirect(true);
    }
  };

  const handleDisabled = (expanded, values, errors) => !(1 === expanded && values.name && !errors.name && isValid.name && values.brand_name && !errors.brand_name) && (!(2 === expanded && handle && !errors.handle && isValid.handle) && !(3 === expanded)) && !(4 === expanded && values.authentication === AUTH_TYPE_WALLET && values.blockchain_address && !errors.blockchain_address);

  const handleAppNameChange = (e) => setName(e.target.value);
  const handleAppHandleChange = (e) => setHandle(e.target.value.toLowerCase().trim());

  useEffect(() => {
    if (expanded === 1 && appNameRef.current) {
      setTimeout(() => { appNameRef.current.focus(); }, 100);
    } else if (expanded === 2 && handleRef.current) {
      setTimeout(() => { handleRef.current.focus(); }, 100);
    }
  }, [expanded]);

  useEffect(() => {
    if (debouncedHandle) checkAppHandle(debouncedHandle);
  }, [debouncedHandle]);

  useEffect(() => {
    if (debouncedName) checkAppName(debouncedName);
  }, [debouncedName]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema[expanded - 1]}
        innerRef={formRef}
        onSubmit={(values, { setSubmitting }) => {
          if (expanded === 3 && values.authentication === AUTH_TYPE_JWT || expanded === 4 && values.authentication === AUTH_TYPE_WALLET) {
            createApplication(values, { setSubmitting });
          } else {
            !panels.includes(expanded + 1) && setPanels([...panels, expanded + 1]);
            setExpanded(expanded + 1);
            setSubmitting(false);
          }
        }}>
        {({ values, errors, handleSubmit, isSubmitting, setFieldValue, setFieldTouched }) =>
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit} className="mb-4">

            <Card className="mb-5 position-relative">

              {isSubmitting && <Loader />}

              <Card.Header as="header" className="p-4 border-0">
                <Button variant="link" className="close" onClick={() => history.push({ pathname: '/apps', state: { from: location.pathname } })}><i className="text-lg sila-icon close"></i><span className="sr-only">{t('common:buttons.close')}</span></Button>
                <h4 className="m-0 text-primary">{t('applications.labels.pending')}</h4>
              </Card.Header>

              <Accordion activeKey={expanded} defaultActiveKey={expanded}>

                <AccordionItem className="app-name no-shadow rounded-0 border-top" eventKey={1}
                  label={expanded === 1 ? t('applications.detail.form.fields.name.longLabel') : t('applications.create.name.title')}
                  append={expanded === 1 && <span className="steps text-info text-nowrap loaded mr-2 ml-5">{t('common:pagination.steps', steps)}</span>}
                  {...accordionItemProps}>
                  <p className="text-info w-75">{t('applications.create.name.description')}</p>
                  <Field required className="name"
                    tabIndex={1}
                    id="createAppForm.name"
                    placeholder={t('applications.detail.form.fields.name.label')}
                    value={name}
                    handleChange={handleAppNameChange}
                    isValid={name && isValid.complete && isValid.name && !isValid.pending}
                    append={isValid.pending ? <Loader size="sm" className="mr-3" /> : undefined}
                    name="name"
                    inputRef={appNameRef}
                    component={FieldWrapper} />
                  <Field required className="brand-name"
                    tabIndex={2}
                    id="createAppForm.brand_name"
                    placeholder={t('applications.detail.form.fields.brand_name.label')}
                    name="brand_name"
                    inputRef={appBrandNameRef}
                    component={FieldWrapper} />
                </AccordionItem>

                {(values.name && panels.includes(2)) && <AccordionItem className="app-handle loaded no-shadow rounded-0 border-top" eventKey={values.name ? 2 : undefined}
                  label={expanded === 2 ? t('applications.detail.form.fields.handle.longLabel') : t('applications.create.handle.title')}
                  append={expanded === 2 && <span className="steps text-info text-nowrap loaded mr-2 ml-5">{t('common:pagination.steps', steps)}</span>}
                  {...accordionItemProps}>
                  <Field required className="handle"
                    tabIndex={3}
                    id="createAppForm.handle"
                    placeholder={t('applications.detail.form.fields.handle.placeholder')}
                    value={handle}
                    handleChange={handleAppHandleChange}
                    note={<Trans i18nKey="applications.detail.form.fields.handle.description">This will be the <span className="text-primary">app_handle</span> used to identify all API requests made by this app.</Trans>}
                    name="handle"
                    inputRef={handleRef}
                    component={FieldWrapper}
                    isValid={handle && isValid.complete && isValid.handle && !isValid.pending}
                    append={isValid.pending ? <Loader size="sm" className="mr-3" /> : undefined} />
                </AccordionItem>}

                {(values.name && panels.includes(3)) && <AccordionItem className="app-auth loaded no-shadow rounded-0 border-top" eventKey={handle ? 3 : undefined}
                  label={t('applications.create.auth.title')}
                  append={expanded === 3 && <span className="steps text-info text-nowrap loaded mr-2 ml-5">{t('common:pagination.steps', steps)}</span>}
                  {...accordionItemProps}>
                  <Field required className="mb-4 flex-row-reverse d-flex justify-content-end align-items-center"
                    id="createAppForm.authentication.jwt"
                    name="authentication"
                    type="radio"
                    label={t('applications.create.auth.jwt')}
                    handleChange={() => {
                      setFieldValue('authentication', AUTH_TYPE_JWT);
                      setFieldValue('blockchain_address', undefined);
                      setFieldValue('privateKey', false);
                    }}
                    labelClass="text-info"
                    value={AUTH_TYPE_JWT}
                    component={FieldWrapper} />
                  <Field required className="flex-row-reverse d-flex justify-content-end align-items-center"
                    id="createAppForm.authentication.wallet"
                    name="authentication"
                    type="radio"
                    label={t('applications.create.auth.ecdsa')}
                    handleChange={() => {
                      setAuthType(AUTH_TYPE_WALLET);
                      setFieldValue('authentication', AUTH_TYPE_WALLET);
                      setExpanded(4);
                    }}
                    labelClass="text-info"
                    value={AUTH_TYPE_WALLET}
                    component={FieldWrapper} />
                </AccordionItem>}

                {values.authentication === AUTH_TYPE_WALLET && <AccordionItem className="app-keys loaded no-shadow rounded-0 border-top" eventKey={4}
                  label={t('applications.create.auth.generate.title')}
                  append={expanded === 4 && <span className="steps text-info text-nowrap loaded mr-2 ml-5">{t('common:pagination.steps', steps)}</span>}
                  {...accordionItemProps}>
                  <p className="text-info">{t('applications.create.auth.generate.description')}</p>
                  <KeyGenForm wallet={{ blockchain_address: values.blockchain_address, privateKey: values.privateKey }} onAddressChange={address => {
                    setFieldValue('blockchain_address', address);
                  }} onGenerate={async (wallet) => {
                    setFieldValue('blockchain_address', wallet.blockchain_address);
                    setFieldValue('privateKey', wallet.privateKey);
                    setTimeout(() => setFieldTouched('blockchain_address', true));
                  }} />
                </AccordionItem>}

              </Accordion>

              <Card.Footer as="footer" className="text-center border-top-0 pt-0 pl-4 pr-4 pb-4">
                <LoaderButton variant="primary"
                  className="w-50 text-center"
                  loading={isSubmitting}
                  type="submit"
                  disabled={handleDisabled(expanded, values, errors)}>{t(`applications.buttons.${expanded === 3 && values.authentication === AUTH_TYPE_JWT || expanded === 4 && values.authentication === AUTH_TYPE_WALLET ? 'download' : (expanded === 4 && values.blockchain_address || expanded === 3 && values.authentication === AUTH_TYPE_JWT) ? 'save' : 'continue'}`)}</LoaderButton>
              </Card.Footer>

            </Card>
          </Form>
        }
      </Formik>

      {confirm.show &&
        <ApplicationCreateModal
          data={confirm.data}
          show={confirm.show}
          isMobile={isMobile}
          authType={authType}
          onHide={() => history.push({ pathname: '/apps', state: { from: location.pathname } })} />}
    </>
  );
};

ApplicationCreateForm.propTypes = {
  /**
   * If the current screen width is similar to a mobile device
   */
  isMobile: PropTypes.bool.isRequired
};

export default ApplicationCreateForm;