import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { teamApi } from '../../api';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import RequestReturnForm from '../../components/common/transactions/RequestReturnForm';
import SupportingDocuments from '../../components/common/transactions/SupportingDocuments';

const RequestReturn = ({ t, match, history, user, setAlert }) => {
  const [key, setKey] = useState(match.params.action);
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const notSubmitted = (initialValues.request_status_name === 'pending_review' || initialValues.request_status_name === 'request_for_withdraw' || initialValues.request_status_name === 'open' || initialValues.request_status_name === 'withdrawn');

  const getRequestReturn = async () => {
    try {
      const response = await teamApi.getRequestReturn(match.params.id, user.currentTeam.id);
      if (response.success) {
        setInitialValues(response.request_reverse_vaccount_transaction);
      } else {
        setRedirect(true);
      }
    } catch (error) {
      setRedirect(true);
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setKey(match.params.action);
  }, [match]);

  useEffect(() => {
    if (redirect) history.push('/settings/requests-return');
  }, [redirect]);

  useEffect(() => {
    getRequestReturn();
  }, []);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('settings.requestsForReturn.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {isLoading ? <Loader /> : <Card className="loaded">
        <Card.Header className="d-flex justify-content-between p-4">
          <h3 className="m-0">{initialValues.transaction_uuid}</h3>
          <p className={`text-lg mb-0 text-${notSubmitted ? 'primary' : (initialValues.request_status_name === 'submitted' || initialValues.request_status_name === 'completed') ? 'success' : 'warning'}`}>{t(`common:transactions.RequestsForReturn.status.${initialValues.request_status_name}`)}</p>
        </Card.Header>
        <Card.Body as={Row} className="p-0" noGutters>
          <Tab.Container defaultActiveKey={key} onSelect={k => setKey(k)}>
            <Col md="4" lg="3" xl="2" className="border-right">
              <Nav className="d-flex flex-column align-content-start h-100 py-4">
                <Nav.Item className="w-100">
                  <Nav.Link className={`d-block px-4 py-3${key === 'detail' ? ' bg-primary-accent text-primary' : ''}`} eventKey="detail" as={NavLink} to={{ pathname: `/settings/request-return/${match.params.id}/detail`, state: { from: location.pathname } }}>{t('common:transactions.RequestsForReturn.detail.title')}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="w-100">
                  <Nav.Link className={`d-block px-4 py-3${key === 'docs' ? ' bg-primary-accent text-primary' : ''}`} eventKey="docs" as={NavLink} to={{ pathname: `/settings/request-return/${match.params.id}/docs`, state: { from: location.pathname } }}>{t('common:transactions.RequestsForReturn.supportingDocument.title')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="8" lg="9" xl="10" className="p-4">
              <Tab.Content>
                <Tab.Pane eventKey="detail">
                  <RequestReturnForm
                    user={user}
                    customer={true}
                    initialValues={initialValues}
                    getRequestReturn={getRequestReturn} />
                </Tab.Pane>
                <Tab.Pane eventKey="docs">
                  <SupportingDocuments
                    match={match}
                    user={user}
                    customer={true}
                    initialValues={initialValues}
                    fetchRequestReturn={getRequestReturn} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Card.Body>
      </Card>}
    </Container>
  );
};

export default RequestReturn;