import React from 'react';
import { Container, Row, Button } from 'react-bootstrap';

import PageTitle from '../../components/common/PageTitle';
import TeamForm from '../../components/team/TeamForm';

const Team = ({ t, history }) => (
  <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

    <Row noGutters className="page-header mb-3 mb-md-5">
      <PageTitle title={t('settings.account.title')}>
        <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
      </PageTitle>
    </Row>

    <TeamForm />

  </Container>
);

export default Team;
