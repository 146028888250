import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Field } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';

import { BLOCKCHAIN_ADDRESS_REG_EXP } from '../../constants';

import { appsApi } from '../../api';

import { useDebounce } from '../../utils/hooks';

import { hasPermissions } from '../../utils';

import { useAuth, useAlerts } from '../context';

import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';
import Loader from '../common/Loader';
import ApplicationCreateModal from './ApplicationCreateModal';
import ViewToEditForm from '../form/ViewToEditForm';

import { ClientIdField, ClientSecretField } from './ClientSecretGenForm';
import { AddressField, PrivateKeyField } from './KeyGenForm';

function ApplicationForm({ initialValues, onSave, onDelete }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { setAlert } = useAlerts();
  const formRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  const [save, setSave] = useState(false);
  const [data, setData] = useState(undefined);
  const [name, setName] = useState(initialValues.name);

  const emptyValues = {
    nameHasChanged: isEditing && name !== initialValues.name,
    name: initialValues.name,
    brand_name: initialValues.brand_name,
    handle: initialValues.handle,
    client_id: initialValues.client_id,
    blockchain_address: initialValues.blockchain_address,
    frozen: initialValues.frozen,
  };
  const [currentValues, setCurrentValues] = useState(emptyValues);

  const emptyCredentials = {
    client_id: initialValues.client_id,
    client_secret: false,
  };
  const [credentials, setCredentials] = useState(emptyCredentials);

  const emptyWallet = {
    show: false,
    blockchain_address: initialValues.blockchain_address,
    privateKey: false,
  };
  const [wallet, setWallet] = useState(emptyWallet);

  const emptyValidState = { handle: false, name: false, pending: false, complete: false };
  const [isValid, setIsValid] = useState(emptyValidState);

  const appNameRef = useRef(null);
  const appBrandNameRef = useRef(null);

  const handleAppNameChange = (e) => setName(e.target.value);
  const handleAppAddressChange = (e) => {
    const address = e.target.value;
    setWallet({ ...wallet, blockchain_address: address, privateKey: false });
    formRef.current.setFieldValue('blockchain_address', address);
  };

  const debouncedName = useDebounce(name, 750);

  const generateWallet = (newWallet) => {
    setWallet({ ...newWallet, show: true });
    formRef.current.setFieldValue('blockchain_address', newWallet.blockchain_address);
    formRef.current.setFieldValue('privateKey', newWallet.privateKey);
  };

  const generateCredentials = (newCredentials) => {
    setCredentials(newCredentials);
    formRef.current.setFieldValue('client_id', newCredentials.client_id);
    formRef.current.setFieldValue('client_secret', newCredentials.client_secret);
    if (!isEditing) {
      setData({ ...formRef.current.values, ...newCredentials });
      setSave(true);
    }
  };

  const checkAppName = async (value) => {
    if (value) {
      setIsValid({ ...isValid, name: false, pending: true });
      try {
        const response = await appsApi.checkAppName(value);
        const isValidStatus = !!(response && response.success === true);
        setIsValid({ ...isValid, name: isValidStatus, pending: false, complete: true });
      } catch (error) {
        setIsValid({ ...isValid, name: false, pending: false, complete: true });
        formRef.current.setSubmitting(false);
      } finally {
        formRef.current.setFieldTouched('name', true);
      }
    } else {
      setIsValid({ ...isValid, name: true, pending: false, complete: false });
    }
  };

  const updateApp = async (values) => {
    setData(values);
    try {
      const response = await appsApi.updateAppDetails(initialValues.id, values);
      setAlert({ message: response.message, variant: 'success' });
      formRef.current.setSubmitting(false);
      if (save) {
        setWallet(emptyWallet);
        setCredentials(emptyCredentials);
        setSave(false);
        setData(undefined);
      }
      if (onSave) onSave(initialValues.id, values);
      setIsEditing(false);
      setCurrentValues({ ...currentValues, ...values, nameHasChanged: false });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      formRef.current.setSubmitting(false);
    }
  };

  const onUpdate = (values) => {
    if (credentials.client_secret || wallet.privateKey) {
      setData(values);
      setSave(true);
    } else {
      updateApp(values);
    }
  };

  const deleteApp = async () => {
    try {
      const response = await appsApi.deleteApp(initialValues.id);
      if (response.success) setAlert({ message: response.message, variant: 'success' });
      onDelete(initialValues.id);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const handleNotEditing = (setIsEditingTrue) => {
    if (setIsEditingTrue) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setIsValid(emptyValidState);
      setWallet(emptyWallet);
    }
  };

  useEffect(() => {
    if (isEditing && debouncedName !== initialValues.name) checkAppName(debouncedName);
  }, [debouncedName]);

  const nameChangeField = (
    <Field
      required
      disabled={!isEditing}
      className="name m-0"
      id={`editAppForm.${initialValues.id}.name`}
      placeholder={t('applications.detail.form.fields.name.label')}
      name="name"
      isValid={name && isValid.complete && isValid.name && !isValid.pending && isEditing}
      append={
        name !== initialValues.name && isValid.pending ? (
          <Loader size="sm" className="mr-3" />
        ) : undefined
      }
      handleChange={handleAppNameChange}
      inputRef={appNameRef}
      label={
        <>
          {t('applications.detail.form.fields.name.label')}
          <TooltipButton
            placement="top"
            variant="link"
            text={t('applications.detail.form.fields.name.description')}
            className="ml-2 no-underline p-0"
          >
            <i className="sila-icon info text-lg" />
          </TooltipButton>
        </>
      }
      component={FieldWrapper}
    />
  );

  const brandChangeField = (
    <Field
      required
      disabled={!isEditing}
      className="brand-name m-0"
      id={`editAppForm.${initialValues.id}.brand_name`}
      placeholder={t('applications.detail.form.fields.brand_name.label')}
      name="brand_name"
      inputRef={appBrandNameRef}
      label={
        <>
          {t('applications.detail.form.fields.brand_name.label')}
          <TooltipButton
            placement="top"
            variant="link"
            text={t('applications.detail.form.fields.brand_name.description')}
            className="ml-2 no-underline p-0"
          >
            <i className="sila-icon info text-lg" />
          </TooltipButton>
        </>
      }
      component={FieldWrapper}
    />
  );

  const appHandleField = (
    <Field
      required
      disabled
      className="handle m-0"
      id={`editAppForm.${initialValues.id}.handle`}
      name="handle"
      label={
        <>
          {t('applications.detail.form.fields.handle.label')}
          <TooltipButton
            placement="top"
            variant="link"
            text={
              <Trans i18nKey="applications.detail.form.fields.handle.description">
                This will be the <span className="text-primary">app_handle</span> used to identify
                all requests made by this app. It is a unique value that cannot be changed.
              </Trans>
            }
            className="ml-2 no-underline p-0"
          >
            <i className="sila-icon info text-lg" />
          </TooltipButton>
        </>
      }
      placeholder={t('applications.detail.form.fields.handle.placeholder')}
      component={FieldWrapper}
    />
  );

  const addressField = (
    <AddressField
      label={
        <>
          {t('applications.detail.form.fields.address.label')}
          <TooltipButton
            placement="top"
            variant="link"
            text={t('applications.detail.form.fields.address.description')}
            className="ml-2 no-underline p-0"
          >
            <i className="sila-icon info text-lg" />
          </TooltipButton>
        </>
      }
      wallet={wallet}
      onGenerate={generateWallet}
      onAddressChange={handleAppAddressChange}
      plaintext={false}
      readOnly={!isEditing}
    />
  );

  const clientIdField = (
    <ClientIdField plaintext={false} credentials={credentials} readOnly={!isEditing} />
  );

  const privateKeyField = (
    <PrivateKeyField
      show={wallet.show}
      label={
        <>
          {t('applications.detail.form.fields.key.label')}
          <TooltipButton
            placement="top"
            variant="link"
            text={
              <Trans i18nKey="applications.detail.form.fields.key.description">
                The private key associated with this account will be used to sign the request body
                to create the <span className="text-primary">authsignature</span> header and
                authenticate the request.{' '}
                <span className="text-warning">
                  IMPORTANT! Never give your private key to us or anyone else!
                </span>
              </Trans>
            }
            className="ml-2 no-underline p-0"
          >
            <i className="sila-icon info text-lg" />
          </TooltipButton>
        </>
      }
      readOnly
      wallet={wallet}
    />
  );

  const clientSecretField = (
    <ClientSecretField
      plaintext={false}
      app={initialValues.id}
      credentials={credentials}
      onGenerate={generateCredentials}
      readOnly={!isEditing}
      disableGenerate={initialValues.frozen}
    />
  );

  const formValidationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        t('common:form.errors.required', {
          field: t('applications.detail.form.fields.name.label'),
        })
      )
      .max(32, t('common:form.errors.max', { count: 32 }))
      .when('nameHasChanged', {
        is: true,
        then: yup.string().test(
          'checkAppName',
          t('common:form.errors.exists', {
            field: t('applications.detail.form.fields.name.label'),
          }),
          () => isValid.name
        ),
      }),
    brand_name: yup
      .string()
      .trim()
      .required(
        t('common:form.errors.required', {
          field: t('applications.detail.form.fields.brand_name.label'),
        })
      )
      .max(32, t('common:form.errors.max', { count: 32 })),
    blockchain_address: !initialValues.client_id
      ? yup
          .string()
          .matches(BLOCKCHAIN_ADDRESS_REG_EXP, {
            message: t('applications.detail.form.fields.address.error'),
          })
          .required(
            t('common:form.errors.required', {
              field: t('applications.detail.form.fields.address.label'),
            })
          )
      : null,
  });

  const formContents = (
    <Container>
      <Row className="align-items-end mb-5">
        <Col md={4}>{appHandleField}</Col>
        <Col md={4}>{nameChangeField}</Col>
        <Col md={4}>{brandChangeField}</Col>
      </Row>
      <Row className="align-items-end">
        {initialValues.client_id && (
          <>
            <Col md={5}>{clientIdField}</Col>
            <Col md={7}>{clientSecretField}</Col>
          </>
        )}
        {initialValues.blockchain_address && <Col md={12}>{addressField}</Col>}
      </Row>
      {wallet.privateKey && isEditing && (
        <Row>
          <Col md={12}>{privateKeyField}</Col>
        </Row>
      )}
    </Container>
  );

  return (
    <>
      <ViewToEditForm
        initialValues={emptyValues}
        uneditedValues={currentValues}
        formValidationSchema={formValidationSchema}
        formContents={formContents}
        t={t}
        handleUpdate={onUpdate}
        handleDelete={deleteApp}
        canUpdateItem={hasPermissions(user, 'apps', 'Delete')}
        isEditing={isEditing}
        setIsEditing={handleNotEditing}
        deleteMessage={t('common:form.messages.confirm.delete', {
          name: initialValues.name,
        })}
        formRef={formRef}
        disableEdit={initialValues.frozen}
      />

      {save && <ApplicationCreateModal data={data} show={save} onHide={updateApp} />}
    </>
  );
}
export default ApplicationForm;
