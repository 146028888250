import React, { useMemo } from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { adminApi } from '../../api';
import { formatDateAndTime, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import DataList from '../../components/common/DataList';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'sales_tiers';

const formatSortBy = (sortBy) => {
  if (!sortBy.length) [...sortBy, { created: '', desc: true }];
  return { url: sortBy };
};

const SalesTiers = ({ t, history, user, location }) => {
  const columns = useMemo(() => [
    {
      id: 'created',
      Header: t('common:labels.created'),
      accessor: 'created',
      disableFilters: true,
      Cell: ({ value }) => <span className="text-nowrap">{formatDateAndTime(value)}</span>
    },
    {
      id: 'name',
      Header: t('admin.salesTiers.labels.name'),
      accessor: 'name',
      disableFilters: true,
      Cell: ({ value }) => <>{value}{value === 'default' && <i className="fas fa-star text-primary ml-2"></i>}</>
    },
    {
      id: 'long_name',
      Header: t('admin.salesTiers.labels.long_name'),
      accessor: 'long_name',
      disableFilters: true
    },
    {
      id: 'modified',
      Header: t('common:labels.lastUpdated'),
      accessor: 'modified',
      disableFilters: true,
      Cell: ({ value }) => <span className="text-nowrap">{formatDateAndTime(value)}</span>
    }
  ], []);

  return (
    <Container className="owners main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.salesTiers.title')}>
          <Button variant="outline-light" className="back text-info" as={NavLink} to="/admin">{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]) ?
        <DataList enableGlobalFilter
          enableManualFilters={false}
          enableFilterMenu={false}
          globalFilterPlaceholder={t('admin.salesTiers.list.search.placeholder')}
          slug={TABLE_SLUG}
          limit={TABLE_MAX_COUNT}
          fetchData={adminApi.fetchSalesTiers}
          columns={columns}
          onRowClick={(row) => history.push(`/admin/sales_tier/${row.original.id}/detail`)}
          onSortBy={formatSortBy}
          hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])}
          headerRightComponent={(rows) => <Button as={NavLink} to={{ pathname: `/admin/sales_tier/new`, state: { tiers: rows, from: location.pathname } }} className="mt-2 mt-sm-0" variant="outline-light">{t('admin.salesTiers.list.buttons.create')} <i className="fas fa-plus-circle ml-2"></i></Button>}
          headerClasses={({ headerGroup, index }) => classNames(
            'py-2 col-4',
            index === 0 && 'pl-4',
            index === headerGroup.headers.length - 1 && 'pr-4',
            index !== 0 && index !== headerGroup.headers.length && 'px-3'
          )}
          cellClasses={({ row, index }) => classNames(
            'py-3',
            index === 0 && 'pl-4',
            index === row.cells.length - 1 && 'pr-4',
            index !== 0 && index !== row.cells.length && 'px-3'
          )}
          renderEmptyComponent={({ globalFilter }) => <p className="text-center text-info font-italic mx-4 my-5">{t(`admin.salesTiers.list.empty.${globalFilter && globalFilter.length ? 'search' : 'entries'}`)}</p>}
          loadingMessage={t('admin.salesTiers.loading')} />
        :
        <div className="position-relative"><DisabledOverlay /></div>}

    </Container>
  );
};

export default SalesTiers;
