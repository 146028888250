import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CountdownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return null;
  } else {
    return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
  }
};

const LockoutModal = ({ locked, recaptchaStatus, show, onHide }) => {
  const { t } = useTranslation();
  const handleHide = (isClose) => isClose ? onHide(isClose) : onHide();
  const countdownCompleted = () => {
    setAlert({ message: t('login.lockout.try_again'), variant: 'info' });
    onHide();
  };

  // Parent component state recaptchaToken is expired after one minute. 
  // In that case, the timer will not be starting from the begin.
  let lockedUntil = 1000*60*parseInt(locked.until);
  if(show && Object.is(recaptchaStatus, null)) {
    lockedUntil = lockedUntil - 1000*60;
  }

  return (
    <Modal centered id="lockout-modal"
      backdrop="static"
      show={show}
      onHide={handleHide}
      aria-labelledby="lockout-modal-title">
      <Modal.Header closeButton>
        <Modal.Title as="h3" id="lockout-modal-title">
          {t('login.lockout.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-lg text-info mb-0">
          {locked.message.replace(locked.until+" minutes.", "")} 
          <Countdown date={Date.now() + lockedUntil} renderer={CountdownRenderer} onComplete={() => countdownCompleted()} />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-light" onClick={() => handleHide(true)}>{t('common:buttons.close')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LockoutModal;
