import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import LoaderButton from './LoaderButton';
import { FreezeReasonsField, generateFreezeReasonValidationSchema } from './FreezeReasonsField';
import { useAlerts } from '../context';

// TO-TO:  REMOVE HARD-CODED LIST

// import { appsApi } from '../../api';

// import { useAlerts } from '../context';

function FreezeModal({
  show,
  handleFreeze,
  data,
  setShow,
  loaderButtonText,
  isAdmin,
  adminDescription,
  customerDescription,
  title,
  reasons = {},
}) {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const { setAlert } = useAlerts();
  const onSubmit = async (freezeReason) => {
    setSubmitting(true);
    try {
      await handleFreeze(freezeReason);
    } catch (e) {
      setAlert({ message: t('common:messages.error.critical'), variant: 'danger' });
    } finally {
      setSubmitting(false);
      setShow(false);
    }
  };

  // format reasons and dates to display with linebreaks if PI is frozen
  const formattedReasons = Object.entries(reasons).map(([reason, timestamp]) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });
    return (
      <span key={timestamp}>
        {reason} on {formattedDate}
        <br />
      </span>
    );
  });

  // TO-TO:  REMOVE HARD-CODED LIST
  // const listFreezeReasons = async () => {
  //   try {
  //     const response = await appsApi.listFreezeReasons();
  //     setReasons(response.freeze_reasons);
  //   } catch (error) {
  //     setAlert({ message: error, variant: 'warning' });
  //   }
  // };
  //
  // useEffect(() => {
  //   listFreezeReasons();
  // }, []);

  const validationSchema = isAdmin ? generateFreezeReasonValidationSchema(t) : null;

  return (
    <Modal
      centered
      id="freeze-reasons-modal"
      backdrop="static"
      show={show}
      size={data.transaction_list ? 'lg' : ''}
      onHide={() => setShow(false)}
      aria-labelledby="freeze-app-modal-title"
    >
      <Formik
        initialValues={{
          freeze_reason: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Modal.Header closeButton={!isSubmitting}>
              <Modal.Title as="h3" id="freeze-reasons-modal-title">
                {title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!isAdmin && <p className="text-lg mb-4">{customerDescription}</p>}
              {isAdmin && <p className="text-lg mb-4">{adminDescription}</p>}
              {isAdmin && Object.values(reasons).length !== 0 && (
                <div className="text-md mb-4">
                  {Object.values(reasons).length === 1
                    ? t('common:freezeUnfreeze.model.frozenListDescriptorSingular')
                    : t('common:freezeUnfreeze.model.frozenListDescriptorPlural')}
                  <br />
                  {formattedReasons}
                </div>
              )}
              {isAdmin && <FreezeReasonsField isSubmitting={isSubmitting} t={t} />}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-light" onClick={() => setShow(false)}>
                {t('common:buttons.cancel')}
              </Button>
              <LoaderButton
                loading={isSubmitting}
                onClick={isSubmitting ? (e) => e.preventDefault() : undefined}
                type="submit"
                disabled={isAdmin && (!dirty || Object.keys(errors).length)}
              >
                {loaderButtonText}
              </LoaderButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

FreezeModal.propTypes = {
  /**
   * Determines if the modal is visible on the screen.
   */
  show: PropTypes.bool.isRequired,

  /**
   * Callback function that executes the freezing action.
   */
  handleFreeze: PropTypes.func.isRequired,

  /**
   * Holds data related to the freezing action, defaults to an empty object.
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,

  /**
   * Function to update the visibility state of the modal.
   */
  setShow: PropTypes.func.isRequired,

  /**
   * Text displayed on a loader or button within the modal.
   */
  loaderButtonText: PropTypes.string.isRequired,

  /**
   * Flag indicating whether the current user has admin privileges.
   */
  isAdmin: PropTypes.bool.isRequired,

  /**
   * Optional text specific to admin users, explaining the freeze action.
   */
  adminDescription: PropTypes.string,

  /**
   * Optional text for customers, detailing the freeze action.
   */
  customerDescription: PropTypes.string,

  /**
   * Text for the modal's title, essential for identifying the modal's purpose.
   */
  title: PropTypes.string.isRequired,
};

FreezeModal.defaultProps = {
  data: {},
  adminDescription: '',
  customerDescription: '',
};

export default FreezeModal;
