import React, { useState, useRef, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useCurrentDimensions } from '../../utils/hooks'

const BadgeMenu = ({ className, label, items, mobileWidth = 992, variant = 'primary' }) => {
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(false);
  const dropdownRef = useRef(null);
  const itemsRef = useRef([]);
  const currentWidth = useCurrentDimensions().width;
  const isMobile = currentWidth <= mobileWidth;
  const hasItems = items && items.length > 1;
  const itemsLabel = items && `${label ? label : items ? items[0] : ''}${hasItems && !show ? '...' : ''}`;

  const badgeMenuClasses = classNames(
    className,
    items && 'badge-menu d-block position-relative',
    show && hasItems && 'shadow'
  );

  const badgeLabelClasses = classNames(
    'badge-outline py-2 px-3',
    items && 'badge-menu-label d-flex flex-nowrap rounded text-left align-items-center position-relative',
    show && hasItems && 'rounded-bottom-0'
  );

  const badgeTextClasses = classNames(
    'text-ellipsis text-nowrap',
    hasItems && 'pr-3'
  );

  const badgeItemsClasses = classNames(
    'badge-menu-items badge-outline p-0 rounded rounded-top-0 text-left bg-primary-light position-absolute overflow-hidden border-top-0',
    isMobile && 'w-100',
    show && 'show position-absolute',
    !show && 'border-0 position-relative'
  );

  useEffect(() => {
    const arrayList = [];
    const lists = itemsRef.current;
    if (!isMobile && hasItems && lists.length) {
      for (let i = 0; i < lists.length; i++) {
        var eachList = lists[i].clientWidth;
        arrayList.push(eachList);
        setWidth(Math.max.apply(null, arrayList) + 50);
      }
    } else if (mobileWidth) {
      setWidth(false);
    }
  }, [isMobile, items]);

  return (
    <div
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className={badgeMenuClasses}>
      <Badge as="div" variant={variant} style={width ? { width } : undefined} className={badgeLabelClasses}>
        <span className={badgeTextClasses}>{label || itemsLabel}</span>
        {hasItems && <i className={`badge-toggle-icon sila-icon arrow-down text-${variant} ml-auto`}></i>}
      </Badge>
      {hasItems && <Badge as="div" variant={variant} className={badgeItemsClasses} style={{
        width: width || null,
        height: show ? dropdownRef.current.clientHeight : 0
      }}>
        <ul ref={dropdownRef} className="list-unstyled mb-0 w-100">
          {items.slice(1).map((item, index) => <li key={index} ref={(el) => itemsRef.current.splice(index, 1, el)} className="badge-menu-item py-2 px-3 position-relative">
            <span className={`badge-menu-item-border w-100 d-block opacity-25 bg-${variant} position-absolute`} />
            <span className="badge-menu-item-label d-block position-relative">{item}</span>
          </li>)}
        </ul>
      </Badge>}
    </div>);
};

BadgeMenu.propTypes = {
  /**
   * The label.
   */
  label: PropTypes.string,
  /**
   * The optional classes.
   */
  className: PropTypes.string,
  /**
   * The items array.
   */
  items: PropTypes.array,
  /**
   * The badge variant.
   */
  variant: PropTypes.string,
  /**
   * The mobile breakpoint width.
   */
  mobileWidth: PropTypes.number
};

export default BadgeMenu;