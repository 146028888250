import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Accordion, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCurrentDimensions } from '../../../utils/hooks';

import { Filter } from './TableFilters';
import AccordionItem from '../AccordionItem';

const TableGlobalFilter = ({ headerGroups, tableFilters, setAllFilters, clearFilters }) => {
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [filters, setFilters] = useState(tableFilters.filters);
  const formRef = useRef();
  const { t } = useTranslation();
  const currentWidth = useCurrentDimensions().width;
  const isMobile = currentWidth <= 576;

  const handleExpanded = (key) => setExpanded(expanded.includes(key) ? expanded.filter(e => e !== key) : [key]);
  const accordionItemProps = { expanded, onSetExpanded: handleExpanded };
  const accordionActiveKey = (key) => expanded.includes(key) && key;

  const isSame = filters.length == tableFilters.filters.length && filters.every((filter, index) => filter === tableFilters.filters[index]);

  const handleHide = () => {
    clearFilters();
    setFilters([]);
    setExpanded([]);
    setShow(false);
  };

  useEffect(() => {
    setFilters(tableFilters.filters);
  }, [tableFilters.filters]);

  useEffect(() => {
    if (show && formRef.current) {
      formRef.current.reset();
    } else {
      setExpanded([]);
    }
  }, [show, formRef.current]);

  return (
    <Dropdown align="end" show={show} onToggle={(visible) => setShow(visible)}>
      <Dropdown.Toggle variant="link" bsPrefix={`ml-3 p-0 text-decoration-none ${(show || headerGroups.every(headerGroup => headerGroup.headers.some(column => tableFilters.filters.some(filter => filter.id === column.id) || tableFilters.sortBy.some(filter => filter.id === column.id)))) ? 'text-primary' : 'text-info'}`}>
        <span className="text-lg mr-2">{t('common:filters.headers.global')}</span>
        <i className="sila-icon filter"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 overflow-hidden" style={{ minWidth: isMobile ? currentWidth - 80 : 300 }}>
        <Form ref={formRef} onSubmit={(e) => { e.preventDefault(); setAllFilters(filters); setExpanded([]); setShow(false); }}>
          {headerGroups.map((headerGroup, index) => (
            <section key={index} className="border-bottom" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.filter(column => column.canFilter).map((column, index) => (<Accordion key={column.id} id={column.id} className="table-global-filter" activeKey={accordionActiveKey(index + 1)}>
                <AccordionItem small
                  className={`shadow-none rounded-0${index !== 0 ? ' border-top' : ''}`}
                  eventKey={index + 1}
                  label={<span className={`text-${filters.some(filter => filter.id === column.id) ? 'primary' : 'info'}`}>{column.render('Header')}</span>}
                  {...accordionItemProps}>
                  <Filter show={show} isGlobal column={column} handleChange={value => setFilters(filters.length && filters.some(filter => filter.id === column.id) ? filters.map(filter => filter.id === column.id ? { ...filter, value } : filter).filter(filter => filter.value && filter.value.length !== 0) : [...filters, { id: column.id, value }])} />
                </AccordionItem>
              </Accordion>))}
            </section>
          ))}
          <footer className="d-flex text-nowrap align-items-center justify-content-end p-3 text-right">
            {tableFilters.filters.length !== 0 && <Button variant="link" size="sm" className="ml-auto text-info text-underline mr-4 p-0" onClick={handleHide}>{t('common:filters.buttons.clear')}</Button>}
            <Button type="submit" size="sm" disabled={isSame}>{t('common:filters.buttons.apply')}</Button>
          </footer>
        </Form>
      </Dropdown.Menu>
    </Dropdown>);
};

TableGlobalFilter.propTypes = {
  /**
   * The react table headerGroups array
   */
  headerGroups: PropTypes.array.isRequired,
  /**
   * The filter object of the table
   */
  tableFilters: PropTypes.object.isRequired,
  /**
   * The function to apply all global filters
   */
  setAllFilters: PropTypes.func.isRequired,
  /**
   * The function to clearz all global filters and remove saved view
   */
  clearFilters: PropTypes.func.isRequired
};

export default TableGlobalFilter;