import React, { useState, useEffect } from 'react';
import { Container, Tab } from 'react-bootstrap';

import { adminApi } from '../../api';

import Loader from '../../components/common/Loader';
import ApiAccessKeysForm from '../../components/applications/ApiAccessKeysForm';
import ApplicationForm from '../../components/admin/ApplicationForm';
import VAccountsForm from '../../components/admin/VAccountsForm';
import StatementInfoForm from '../../components/applications/StatementInfoForm';
import InstantSettlementForm from '../../components/admin/InstantSettlementForm';
import CompanyWalletForm from '../../components/admin/CompanyWalletForm';
import ProviderWebhooksForm from '../../components/applications/ProviderWebhooksForm';
import FreezeModal from '../../components/common/FreezeModal';
import { hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';
import {
  AppProfileNavItem,
  AppProfileTabColumn,
  AppProfileDetailColumn,
  AdminAppDetailView,
  AppCardHeader,
  AppCardBody,
  AppDetailHeader,
} from '../../components/applications/AppProfileViewComponents';

function Application({ t, env, match, location, history, isMobile, setAlert, user }) {
  /**
   * Renders the detailed view of an application for superusers. Example url  where this
   * component can be viewed is localadmin.silamoney.com:8000/admin/app/6/detail
   */

  const [header, setHeader] = useState(match.params.action);
  const [isLoading, setIsLoading] = useState(true);
  const [app, setApp] = useState({});
  const [showFreezeModal, setShowFreezeModal] = useState(false);

  const redirect = () => {
    history.push({ pathname: '/admin/apps', state: { from: location.pathname } });
  };

  const getApplication = async () => {
    setIsLoading(true);
    try {
      const response = await adminApi.getApplication(match.params.id);
      if (response.success) {
        setApp(response.app);
        setIsLoading(false);
      } else {
        redirect();
      }
    } catch (error) {
      redirect();
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    setHeader(match.params.action);
  }, [match]);

  useEffect(() => {
    getApplication();
  }, [env]);

  const appFreezeUnfreeze = async (freezeReasonObj) => {
    const desiredFreezeState = !app.frozen;
    const response = await adminApi.appFreezeUnfreeze(
      app.id,
      desiredFreezeState,
      freezeReasonObj.freeze_reason
    );

    if (response.status_code === 200) {
      setAlert({
        message: response.message,
        variant: 'success',
      });
    } else if (!response.success) {
      if (response.status_code === 400) {
        setAlert({
          message: response.message,
          variant: 'danger',
        });
      } else if (response.status_code === 409) {
        setAlert({
          message: response.message,
          variant: 'warning',
        });
      }
    }

    const responseFreezeReasons = response.freeze_reasons ? response.freeze_reasons : {};
    setApp({
      ...app,
      frozen: Object.values(responseFreezeReasons).length !== 0,
      freeze_reasons: responseFreezeReasons,
    });

    setShowFreezeModal(false);
  };

  // variable used in FreezeModal
  const name = app.nickname;
  const type = t('common:labels.app');

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <AppDetailHeader t={t} />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AppCardHeader setShowFreezeModal={setShowFreezeModal} app={app} t={t} user={user} />

          <AppCardBody header={header} setHeader={setHeader}>
            <AppProfileTabColumn>
              <AppProfileNavItem type={header} appId={match.params.id} t={t} route="detail" />
              <AppProfileNavItem
                type={header}
                appId={match.params.id}
                t={t}
                disabled={app.deleted}
                route="virtual-accounts"
              />
              <AppProfileNavItem
                type={header}
                appId={match.params.id}
                t={t}
                route="statement-info"
              />
              <AppProfileNavItem
                type={header}
                appId={match.params.id}
                t={t}
                disabled={app.deleted}
                route="entity-info"
              />
              <AppProfileNavItem
                type={header}
                appId={match.params.id}
                t={t}
                disabled={app.deleted}
                route="instant-settlement"
              />
              <AppProfileNavItem
                type={header}
                appId={match.params.id}
                t={t}
                disabled={app.deleted}
                route="access-keys"
              />
              <AppProfileNavItem
                type={header}
                appId={match.params.id}
                t={t}
                disabled={app.deleted}
                route="provider-webhooks"
              />
            </AppProfileTabColumn>
            <AppProfileDetailColumn>
              <Tab.Content className="w-100">
                <Tab.Pane eventKey="detail">
                  {app.team_members.filter((member) => member.role_name === 'owner').length !==
                    0 && <AdminAppDetailView app={app} t={t} />}
                  <ApplicationForm match={match} initialValues={app} onSave={getApplication} />
                </Tab.Pane>
                <Tab.Pane eventKey="virtual-accounts">
                  <VAccountsForm initialValues={app} onSave={getApplication} />
                </Tab.Pane>
                <Tab.Pane eventKey="statement-info">
                  <StatementInfoForm
                    admin
                    isMobile={isMobile}
                    initialValues={app}
                    user={user}
                    onSave={getApplication}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="entity-info">
                  <CompanyWalletForm match={match} initialValues={app} onSave={getApplication} />
                </Tab.Pane>
                <Tab.Pane eventKey="instant-settlement">
                  <InstantSettlementForm initialValues={app} onSave={getApplication} />
                </Tab.Pane>
                <Tab.Pane eventKey="access-keys">
                  <ApiAccessKeysForm
                    admin
                    isMobile={isMobile}
                    appId={app.id}
                    onSave={getApplication}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="provider-webhooks">
                  <ProviderWebhooksForm admin isMobile={isMobile} appId={app.id} />
                </Tab.Pane>
              </Tab.Content>
            </AppProfileDetailColumn>
          </AppCardBody>
        </>
      )}
      {showFreezeModal && (
        <FreezeModal
          show={showFreezeModal}
          data={{}}
          handleFreeze={appFreezeUnfreeze}
          reasons={app.freeze_reasons}
          setShow={setShowFreezeModal}
          loaderButtonText={app.frozen ? t('common:buttons.unfreeze') : t('common:buttons.freeze')}
          isAdmin={hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])}
          adminDescription={
            app.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.reasonDescription', { name })
              : t('common:freezeUnfreeze.model.freeze.reasonDescription', { name })
          }
          customerDescription={
            app.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.confirm', { name })
              : t('common:freezeUnfreeze.model.freeze.confirm', { name })
          }
          title={
            app.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.title', { type })
              : t('common:freezeUnfreeze.model.freeze.title', { type })
          }
        />
      )}
    </Container>
  );
}

export default Application;
