import React from 'react';
import PropTypes from 'prop-types';

const CenteredOverlay = ({ children }) => {
  return (
    <div className="centered-overlay position-absolute w-100 h-100 d-flex flex-column justify-content-center loaded">
      <div className="align-self-center text-center">
        {children}
      </div>
    </div>
  )
};

CenteredOverlay.propTypes = {
  /**
   * The children components.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default CenteredOverlay;