import Cookies from 'js-cookie';

import consoleApi from './console';

import { removeEmpty, handleResponseError } from '../utils';

const currentTeam = parseInt(Cookies.get('sila_console_team'), 10);

const createTeamsForBothEnvs = async (value, handle) => {
  try {
    const response = await consoleApi.post({
      url: '/create_teams_for_both_envs',
      data: {
        name: value,
        handle,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateTeam = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/teams/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getTeamDetails = async (id) => {
  try {
    const response = await consoleApi.get({ url: `/teams/${id}` });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return removeEmpty(response.team);
  } catch (error) {
    throw error;
  }
};

// TODO: correct this lint error
// eslint-disable-next-line default-param-last
const inviteMember = async (values, team = currentTeam, env) => {
  let roleName = false;
  try {
    const response = await consoleApi.post({
      url: '/members',
      data: {
        member_email: values.member.email,
        team_id: team.id || team,
        role_id: parseInt(values.role.id, 10),
        env: env,
        signup_step_completed: values.signup_step_completed ? values.signup_step_completed : '',
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    try {
      const rolesResponse = await consoleApi.get({ url: '/roles' });
      if (!rolesResponse.success) {
        throw handleResponseError(rolesResponse);
      }
      if (
        rolesResponse.roles.length &&
        rolesResponse.roles.find((role) => role.id === parseInt(values.role.id, 10))
      ) {
        // eslint-disable-next-line no-unused-vars
        roleName = rolesResponse.roles.find(
          (role) => role.id === parseInt(values.role.id, 10)
        ).name;
      }
    } catch (error) {
      throw error;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getInvitation = async (token, email, env) => {
  try {
    const response = await consoleApi.get({
      url: '/invitation',
      data: {
        token,
        email,
        env,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteInvitation = async (id) => {
  try {
    const response = await consoleApi.delete({ url: `/invitation/${id}` });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const confirmMember = async (values) => {
  try {
    const response = await consoleApi.put({
      url: '/invitation',
      data: {
        token: values.token,
        email: values.email,
        env: values.env,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
  } catch (error) {
    throw error;
  }
};

const getMember = async (id, team = currentTeam) => {
  try {
    const response = await consoleApi.get({
      url: `/members/${id}`,
      data: {
        team_id: team.id || team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateMember = async (values, team = currentTeam) => {
  try {
    const response = await consoleApi.post({
      url: `/members/${values.member.id}`,
      data: {
        team_id: team.id || team,
        role_id: values.role.id,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteMember = async (values, team = currentTeam) => {
  try {
    const response = await consoleApi.delete({
      url: `/members/${values.member.id}`,
      data: {
        team_id: team.id || team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchTeams = async (options) => {
  const limit = options.limit || 20;
  const page = options.page || 1;
  try {
    const response = await consoleApi.get({ url: `/teams?limit=${limit}&page=${page}` });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchMembers = async (values) => {
  try {
    const [membersResponse, invitationsResponse] = await Promise.all([
      consoleApi.get({
        url: '/members',
        data: {
          team_id: values.team,
          limit: values.limit,
          page: values.page || 1,
        },
      }),
      consoleApi.get({
        url: '/invitations',
        data: {
          team_id: values.team,
          limit: values.limit,
          page: values.page || 1,
        },
      }),
    ]);
    if (!membersResponse.success) {
      throw handleResponseError(membersResponse);
    } else if (!invitationsResponse.success) {
      throw handleResponseError(invitationsResponse);
    }
    const { members, ...membersResults } = membersResponse;
    const { invitations, ...invitationsResults } = invitationsResponse;
    const req =
      membersResults.pagination.total_pages >= invitationsResults.pagination.total_pages
        ? membersResults
        : invitationsResults;
    return { ...req, members: [...members, ...invitations.map((invitation) => ({ invitation }))] };
  } catch (error) {
    throw error;
  }
};

const updateTeamName = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/teams/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchTransactions = async (options) => {
  const defaultSort = '&sort_keys=created_desc';
  const team = options.team || currentTeam;
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('created')
    ? options.query
    : `${options.query}${defaultSort}`;
  try {
    const response = await consoleApi.get({
      url: `/transactions?team_id=${team}&limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getTransaction = async (id, team = currentTeam) => {
  try {
    const response = await consoleApi.get({
      url: `/transactions/${id}`,
      data: {
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

// TODO: correct this lint error
// eslint-disable-next-line default-param-last
const getReturnRates = async (team = currentTeam, apps, target_handle = null) => {
  let query = '';
  if (apps && apps.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const app of apps) {
      query += `&app_handle=${app}`;
    }
  }
  try {
    const response = await consoleApi.get({
      url: `/return_rates?team_id=${team}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    response.target_handle = target_handle;
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchRequestsReturn = async (options) => {
  const defaultSort = '&sort_keys=created_desc';
  const team = options.team || currentTeam;
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('created')
    ? options.query
    : `${options.query}${defaultSort}`;
  try {
    const response = await consoleApi.get({
      url: `/request_reverse_virtual_account_ach_transaction?team_id=${team}&limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getRequestReturn = async (uuid, team = currentTeam, downloadDocument = false) => {
  try {
    const response = await consoleApi.get({
      binary: downloadDocument,
      notracking: downloadDocument,
      url: `/reverse_request_virtual_account_ach_transaction/${uuid}`,
      data: {
        team_id: team,
        download_document: downloadDocument,
      },
    });
    if (!downloadDocument && !response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateRequestReturn = async (uuid, values) => {
  try {
    const response = await consoleApi.put({
      url: `/reverse_request_virtual_account_ach_transaction/${uuid}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

const fetchStatements = async (options) => {
  const team = options.team || currentTeam;
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('created') ? options.query : `${options.query}`;
  try {
    const response = await consoleApi.get({
      url: `/statements?team_id=${team}&limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getStatement = async (id, team = currentTeam, isDownload = true) => {
  try {
    const response = await consoleApi.get({
      binary: isDownload,
      notracking: isDownload,
      url: `/statement/${id}`,
      data: {
        team_id: team,
        get_file: isDownload,
      },
    });
    if ((isDownload && !response) || (!isDownload && !response.success)) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const reSendStatement = async (id, team = currentTeam) => {
  try {
    const response = await consoleApi.put({
      url: `/statement/${id}`,
      data: {
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getEndUsers = async (options) => {
  const defaultSort = '&sort_keys=created_desc';
  const team = options.team || currentTeam;
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('created')
    ? options.query
    : `${options.query}${defaultSort}`;
  try {
    const response = await consoleApi.get({
      url: `/end_users?team_id=${team}&limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getEndUser = async (handle, team = currentTeam) => {
  try {
    const response = await consoleApi.get({
      url: `/end_users/${handle}`,
      data: {
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateEndUser = async (handle, values, team = currentTeam) => {
  try {
    const response = await consoleApi.put({
      url: `/end_users/${handle}`,
      data: {
        team_id: team,
        email: values.email,
        phone: values.phone,
        address: values.address,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const smsOptIn = async (handle, phoneId, team = currentTeam) => {
  try {
    const response = await consoleApi.post({
      url: `/end_users/${handle}/sms_opt_in`,
      data: {
        team_id: team,
        phone_id: phoneId,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getEndUserPaymentInstruments = async (options) => {
  const { handle } = options;
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query || '';
  const team = options.team || currentTeam;
  try {
    const response = await consoleApi.get({
      url: `/payment_instruments?handle=${handle}&team_id=${team}&limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const paymentInstrumentFreezeUnfreeze = async (
  freeze,
  payment_instrument_id,
  team_id,
  force_freeze
) => {
  try {
    const response = await consoleApi.put({
      url: '/payment_instruments',
      data: removeEmpty({
        freeze: freeze,
        payment_instrument_id: payment_instrument_id,
        team_id: team_id,
        force_freeze: force_freeze,
        freeze_reason: 'by_customer',
      }),
    });
    if (!response.success && !(response.status_code === 400 || response.status_code === 409)) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getPaymentInstrument = async (id, team = currentTeam) => {
  try {
    const response = await consoleApi.get({
      url: `/payment_instrument/${id}`,
      data: {
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const teamApi = {
  createTeamsForBothEnvs,
  updateTeam,
  getTeamDetails,
  inviteMember,
  getInvitation,
  deleteInvitation,
  confirmMember,
  getMember,
  updateMember,
  deleteMember,
  fetchTeams,
  fetchMembers,
  updateTeamName,
  fetchTransactions,
  getTransaction,
  getReturnRates,
  fetchRequestsReturn,
  getRequestReturn,
  updateRequestReturn,
  fetchStatements,
  getStatement,
  reSendStatement,
  getEndUsers,
  getEndUser,
  updateEndUser,
  smsOptIn,
  getEndUserPaymentInstruments,
  paymentInstrumentFreezeUnfreeze,
  getPaymentInstrument,
};

export default teamApi;
