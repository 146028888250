import React, { useState, useEffect, useRef } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Formik, Field } from 'formik';

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';
import ConfirmModal from '../common/ConfirmModal';

import { NUMBER_REG_EXP, VACCOUNTS_PREFIX_WARNING_COUNT } from '../../constants';
import { useDebounce } from '../../utils/hooks';
import { adminApi } from '../../api';
import { useAlerts, useAuth } from '../context';

const VAccountsForm = ({ initialValues, onSave }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const { env } = useAuth();
  const [prefix, setPrefix] = useState('');
  const [status, setStatus] = useState({ pending: false, generate: false, isValid: false, warning: false });
  const [confirm, setConfirm] = useState({ show: false, data: undefined, status: undefined });

  const formRef = useRef(null);
  const prefixRef = useRef(null);
  const debouncedPrefix = useDebounce(prefix, 750);

  const handlePrefixChange = (e) => {
    e.preventDefault();
    const value = e.target.value.toLowerCase().trim();
    setPrefix(value);
    setStatus({ ...status, pending: false, isValid: false });
  }

  const generatePrefix = async () => {
    if (env === 'prod') return;
    setStatus({ ...status, pending: true, generate: true });
    try {
      const response = await adminApi.getVaccountPrefix();
      formRef.current.setFieldValue('vaccount_prefix', response.prefix);
      setPrefix(response.prefix);
      setStatus({ ...status, isValid: response.success ? true : false, warning: response.available_prefix_count < VACCOUNTS_PREFIX_WARNING_COUNT ? true : false, pending: false, generate: false });
      setAlert({ message: response.message, variant: 'success' });
      formRef.current.setFieldTouched('vaccount_prefix', true);
    } catch (error) {
      setAlert({ message: error, variant: 'danger' });
      setStatus({ ...status, isValid: false, pending: false, generate: false });
      formRef.current.setSubmitting(false);
    } finally {
    }
  };

  const checkPrefix = async (value) => {
    if (value && value.length === 3) {
      setStatus({ ...status, pending: true });
      try {
        await adminApi.setVaccountPrefix({
          app_id: initialValues.id,
          team_id: initialValues.team_id,
          prefix: value,
          pre_checking_prefix: true
        }, true);
        setStatus({ ...status, isValid: true, pending: false });
      } catch (error) {
        setStatus({ ...status, isValid: false, pending: false });
        setAlert({ message: error, variant: 'warning' });
      } finally {
        formRef.current.setFieldTouched('vaccount_prefix', true);
      }
    }
  };

  const setVaccountPrefix = async (values) => {
    if (values) {
      try {
        const response = await adminApi.setVaccountPrefix({
          app_id: initialValues.id,
          team_id: initialValues.team_id,
          prefix: values.vaccount_prefix
        });
        if (!response.success) {
          setAlert({ message: response.message, variant: 'warning' });
          setStatus({ ...status, isValid: true, pending: false });
        } else {
          onSave();
          setAlert({ message: initialValues.vaccount_prefix ? response.message : t('admin.applications.vAccounts.form.messages.success'), variant: 'success' });
          setStatus({ ...status, isValid: false, pending: false });
        }
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setConfirm({ ...confirm, show: false, data: undefined });
        formRef.current.setSubmitting(false);
      }
    } else {
      setConfirm({ ...confirm, show: false, data: undefined });
      formRef.current.setSubmitting(false);
    }
  };

  const setVaccountLimit = async (values) => {
    if (values) {
      try {
        const response = await adminApi.setVaccountLimit({
            app_id: initialValues.id,
            team_id: initialValues.team_id,
            vaccount_limit: values.vaccount_limit,
        });
        setAlert({ message: response.message, variant: 'success' });
        formRef.current.setFieldTouched('vaccount_limit', response.app_settings.virtual_account_limit);
        onSave();
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  useEffect(() => {
    if (debouncedPrefix) checkPrefix(debouncedPrefix);
  }, [debouncedPrefix]);

  useEffect(() => {
    if (prefixRef.current && !prefix) prefixRef.current.focus();
  }, []);

  return (<>
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={yup.object().shape({
        vaccount_prefix: initialValues.vaccount_limits ? yup.string().nullable().trim()
          .required(t('common:form.errors.required', { field: t('admin.applications.vAccounts.form.fields.prefix.label') }))
          .min(3, t('admin.applications.vAccounts.form.errors.min', { count: 3 }))
          .max(3, t('admin.applications.vAccounts.form.errors.max', { count: 3 }))
          .matches(NUMBER_REG_EXP, { message: t('admin.applications.vAccounts.form.errors.number') })
          .test('checkPrefix', t('common:form.errors.exists', { field: t('admin.applications.vAccounts.form.fields.prefix.label') }) || t('common:status.validating'), (value) => status.isValid && value) : null,
        vaccount_limit: yup.number()
          .required(t('common:form.errors.required', { field: t('admin.applications.vAccounts.form.fields.limit.label') }))
      })}
      onSubmit={(values) => values.vaccount_prefix && initialValues.vaccount_prefix !== values.vaccount_prefix ? setConfirm({ ...confirm, show: true, data: values }) : setVaccountLimit(values)}>
      {({ values, errors, handleSubmit, isSubmitting, dirty }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>

          <h4 className="mb-4">{t('admin.applications.vAccounts.title')}</h4>

          {initialValues.vaccount_limit === 0 && <p>{t('admin.applications.vAccounts.description')}</p>}

          {isSubmitting && <Loader />}

          {initialValues.vaccount_limit !== 0 && <Form.Row className="align-items-end">
            <Col md="12" lg={!initialValues.vaccount_prefix ? 8 : 12}>
              <Field required
                id="vAccountsForm.prefix"
                name="vaccount_prefix"
                className="prefix mb-0"
                placeholder={t('admin.applications.vAccounts.form.fields.prefix.placeholder')}
                inputRef={prefixRef}
                maxLength={3}
                label={<>
                  {t('admin.applications.vAccounts.form.fields.prefix.label')}
                  <TooltipButton placement="top" variant="link" text={t('admin.applications.vAccounts.form.fields.prefix.tooltip')} className="ml-2 no-underline p-0"><i className="sila-icon info ml-2"></i></TooltipButton>
                </>}
                handleChange={handlePrefixChange}
                disabled={env === 'prod' || initialValues.vaccount_prefix}
                isValid={!errors.prefix && prefix && status.isValid && !status.pending}
                append={!errors.prefix && status.pending ? <Loader size="sm" className="mr-3" /> : undefined}
                component={FieldWrapper} />
            </Col>
            {!initialValues.vaccount_prefix && <><Col md="12" lg="2">
              <LoaderButton block disabled={env === 'prod' || initialValues.vaccount_prefix || status.isValid} className="mt-4 mt-lg-0 mr-0 mr-md-4 text-center" loading={status.generate} loadingLabel={t('common:status.generating')} onClick={(e) => generatePrefix()}>{t('common:buttons.generate')}</LoaderButton>
            </Col>
            <Col md="12" lg="2">
              <LoaderButton block className="mt-4 mt-lg-0" loading={values.vaccount_prefix && isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={env === 'prod' || !status.isValid || Object.keys(errors).length}>{t(`common:buttons.${initialValues.vaccount_prefix ? 'update' : 'set'}`)}</LoaderButton>
            </Col></>}
          </Form.Row>}

          {prefix && prefix.length === 3 && status.isValid && <p className={`mt-3 mb-0 loaded ${status.warning ? 'text-warning' : ''}`}>
            <i className={`text-lg pr-2 ${status.warning ? 'fas fa-exclamation-triangle text-warning' : 'fas fa-check text-success'}`}></i>{t(`admin.applications.vAccounts.form.messages.${status.warning ? 'warning' : 'valid'}`)}
          </p>}

          <Form.Label className="mt-4" htmlFor="vAccountsForm.vaccount_limit">{t('admin.applications.vAccounts.form.fields.limit.label')}
          </Form.Label>

          <Form.Row className="align-content-start">
            <Col md="12" lg="10">
              <Field required
                id="vAccountsForm.vaccount_limit"
                name="vaccount_limit"
                type="number"
                className="limit mb-0"
                note={t('admin.applications.vAccounts.form.fields.limit.description')}
                disabled={!initialValues.vaccount_prefix && env === 'prod'}
                placeholder={t('admin.applications.vAccounts.form.fields.limit.placeholder')}
                component={FieldWrapper} />
            </Col>
            <Col md="12" lg="2">
              <LoaderButton block className="mt-4 mt-lg-0" loading={values.vaccount_limit !== initialValues.vaccount_limit && isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={values.vaccount_limit === initialValues.vaccount_limit || errors.vaccount_limit}>{t(`common:buttons.${initialValues.vaccount_limit !== 0 ? 'update' : 'set'}`)}</LoaderButton>
            </Col>
          </Form.Row>

        </Form>
      )}
    </Formik>

    {confirm.show && <ConfirmModal
      show={confirm.show}
      data={confirm.data}
      title={t('admin.applications.vAccounts.modal.title')}
      message={t('admin.applications.vAccounts.modal.description')}
      buttonLabel={t('common:buttons.enable')}
      onHide={setVaccountPrefix} />}

  </>
  );
};

VAccountsForm.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to be called when the application is saved
   */
  onSave: PropTypes.func.isRequired
};

export default VAccountsForm;
