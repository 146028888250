import Cookies from 'js-cookie';
import consoleApi from './console';

import { handleResponseError, removeEmpty } from '../utils';

const currentTeam = parseInt(Cookies.get('sila_console_team'), 10);

const createApp = async (values, team = currentTeam) => {
  const { blockchain_address, ...data } = values;
  if (blockchain_address) {
    data.address = blockchain_address;
  }
  try {
    const response = await consoleApi.post({
      url: '/apps',
      data: {
        ...data,
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateAppDetails = async (id, values) => {
  // limit data to that which can be changed by the update app view
  const data = removeEmpty({
    address: values.blockchain_address,
    brand_name: values.brand_name,
    client_id: values.client_id,
    handle: values.handle,
    name: values.name,
    privateKey: values.privateKey,
  });

  try {
    const response = await consoleApi.put({
      url: `/apps/${id}`,
      data: data,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateApp = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/apps/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteApp = async (id) => {
  try {
    const response = await consoleApi.delete({
      url: `/apps/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const freezeApp = async (id, freeze) => {
  try {
    const response = await consoleApi.put({
      url: `/apps/${id}`,
      data: {
        freeze: freeze,
      },
    });
    if (!response.success && !(response.status_code === 400 || response.status_code === 409)) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchMetrics = async (apps) => {
  try {
    const response = await Promise.all(
      apps.map((app) =>
        consoleApi.get({
          url: `/apps/${app}`,
        })
      )
    );
    if (!response) {
      throw handleResponseError(response);
    }
    return response.map((data) => ({
      ...data.metrics,
      id: data.app.id,
    }));
  } catch (error) {
    throw error;
  }
};

const getApp = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/apps/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchApps = async (options) => {
  try {
    const response = await consoleApi.get({
      url: '/apps',
      data: {
        team_id: options.team || currentTeam,
        limit: options.limit || 3,
        page: options.page || 1,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const checkAppHandle = async (value) => {
  try {
    const response = await consoleApi.post({
      url: '/check_app_handle',
      data: {
        handle: value,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const checkAppName = async (value) => {
  try {
    const response = await consoleApi.post({
      url: '/check_app_name',
      data: {
        app_name: value,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const saveStatement = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/business_statement_information',
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateStatement = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/business_statement_information/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const regenerateClientSecret = async (id) => {
  try {
    const response = await consoleApi.delete({
      url: `/apps/secret/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteStatementLogo = async (id, team = currentTeam) => {
  try {
    const response = await consoleApi.delete({
      url: `/${id}/logo_delete`,
      data: {
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchAppMetrics = async (app_id) => {
  try {
    const response = await consoleApi.get({
      url: '/fetch_app_metrics',
      data: {
        app_id,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCkoCredentials = async (id) => {
  try {
    const response = await consoleApi.post({
      url: '/cko_credentials',
      data: {
        app_id: id,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCkoCredentials = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: '/cko_credentials',
      data: {
        app_id: id,
        ...values,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProviderWebhooks = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/provider_webhooks/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateProviderWebhooks = async (id, values, method) => {
  try {
    const response = await consoleApi[method]({
      url: '/provider_webhook',
      data: {
        app_id: id,
        ...values,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const listFreezeReasons = async () => {
  try {
    const response = await consoleApi.get({
      url: '/list_freeze_reasons',
      data: {
        per_page: 100,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const appsApi = {
  createApp,
  updateAppDetails,
  updateApp,
  deleteApp,
  freezeApp,
  getApp,
  fetchApps,
  fetchMetrics,
  checkAppHandle,
  checkAppName,
  saveStatement,
  updateStatement,
  regenerateClientSecret,
  deleteStatementLogo,
  getCkoCredentials,
  updateCkoCredentials,
  getProviderWebhooks,
  updateProviderWebhooks,
  fetchAppMetrics,
  listFreezeReasons,
};

export default appsApi;
