import React from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TrackedRoute from './TrackedRoute';
import RedirectRoute from './RedirectRoute';
import CustomSwitch from './CustomSwitch';

import { useAuth } from '../context';
import { SUPERUSER_LEVEL } from '../../constants';
import { hasSalesAccess, hasSuperuserAccess } from '../../utils';

const RouteConfig = ({ routes }) => {
  const { user, authFrozen } = useAuth();
  const { t } = useTranslation();
  const hasSuperUserAccess = user && hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]);

  const filterRoutes = (route) =>
    (user && hasSuperUserAccess && route.admin) ||
    (user && hasSalesAccess(user) && route.sales) ||
    (user && route.restricted) ||
    (!user && !authFrozen && !route.restricted && !route.admin) ||
    (authFrozen && route.frozen) ||
    route.all;

  return (
    <CustomSwitch>
      {routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={(props) => {
            if (route.routes ? route.routes.some(route => filterRoutes(route)) : filterRoutes(route)) {
              return <TrackedRoute t={t} route={route} {...props} />
            } else {
              return <RedirectRoute from={props.location.state && props.location.state.from ? props.location.state.from : undefined} routes={routes} />
            }
          }}
        />
      ))}
    </CustomSwitch>
  );
};

RouteConfig.propTypes = {
  /**
   * The routes array
   */
  routes: PropTypes.array.isRequired
};

export default RouteConfig;