import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { truncateFileName } from '../../../utils';
import { RETURN_CODES, RETURN_CODES_DOCS_URL, FILE_NAME_MAX_LENGTH } from '../../../constants';

import LoaderButton from '../LoaderButton';
import FieldWrapper from '../../form/wrappers/Field';

const RequestForReturnModal = ({ show, onHide, percentage, data }) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = (values) => {
    setSubmitting(true);
    onHide(values);
  };

  return (
    <Formik
      initialValues={{
        return_code: data && data.return_code ? data.return_code : undefined,
        customer_contact_date: data && data.customer_contact_date ? data.customer_contact_date : undefined,
        files: []
      }}
      validationSchema={yup.object().shape({
        return_code: yup.string()
          .required(t('common:form.errors.required', { field: t('common:transactions.detail.requestReturn.form.fields.returnCode.placeholder') })),
        customer_contact_date: yup.string()
          .required(t('common:form.errors.required', { field: t('common:transactions.detail.requestReturn.form.fields.customerContactDate.placeholder') }))
      })}
      onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue, values, errors, dirty }) => (
        <Modal id="request-return-modal" centered
          backdrop="static"
          show={show}
          size="xl"
          onHide={() => onHide()}
          aria-labelledby="request-return-modal-title">
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Modal.Header closeButton={Object.keys(percentage).length === 0}>
              <Modal.Title id="request-return-modal-title">{t('common:transactions.detail.requestReturn.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-lg text-info"><Trans i18nKey="common:transactions.detail.requestReturn.description">Please provide a reason for your request to return this transaction by selecting a return code below. Refer to <a href={RETURN_CODES_DOCS_URL} target="_blank">our docs</a> to learn more about each return code and their meaning.  Additionally, we ask that you upload a supporting document to aid in the review process. <span className="font-weight-bold">Once submitted, you may not make any changes unless the request is reopened by a Sila administrator.</span> Please make sure all information on the document is visible and clear. We accept files in these formats: PNG, JPG, and PDF, no larger than 20MB.</Trans></p>
              <Row>
                <Col md="6">
                  <Field required readOnly={Object.keys(percentage).length} className="returnCode"
                    type="select"
                    id="requestReturnForm.return_code"
                    name="return_code"
                    labelClass="text-lg text-dark"
                    label={t('common:transactions.detail.requestReturn.form.fields.returnCode.label')}
                    placeholder={t('common:transactions.detail.requestReturn.form.fields.returnCode.placeholder')}
                    options={RETURN_CODES.map(code => ({ value: code, label: code }))}
                    component={FieldWrapper} />
                </Col>
                <Col md="6">
                  <Field required readOnly={Object.keys(percentage).length} className="customerContactDate"
                    id="requestReturnForm.customer_contact_date"
                    name="customer_contact_date"
                    type="date"
                    labelClass="text-lg text-dark"
                    label={t('common:transactions.detail.requestReturn.form.fields.customerContactDate.label')}
                    component={FieldWrapper} />
                </Col>
              </Row>
              <p className="text-lg">{t('common:transactions.detail.requestReturn.form.fields.supportDocument.label')}</p>
              <Row>
                <Col>
                  <Field required className="files mb-0"
                    type="file"
                    id="requestReturnForm.files"
                    name="files"
                    label={t('common:upload.form.fields.files.placeholder')}
                    percentage={percentage}
                    options={{
                      onDrop: (acceptedFiles) => {
                        const files = acceptedFiles.map((file) => new File([file], truncateFileName(file.name, FILE_NAME_MAX_LENGTH), { type: file.type }));
                        setFieldValue('files', files);
                        return files;
                      },
                      multiple: false,
                      maxSize: 20971520,
                      accept: 'image/jpeg, image/png, application/pdf'
                    }}
                    note={t('common:upload.form.fields.files.description')}
                    component={FieldWrapper} />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button disabled={isSubmitting || Object.keys(percentage).length} variant="outline-light" className="mr-4" onClick={() => onHide()}>{t('common:buttons.cancel')}</Button>
              <LoaderButton loadingLabel={t('common:status.uploading')} disabled={!dirty || Object.keys(errors).length || !values.files.length || Object.keys(percentage).length} loading={isSubmitting} type="submit">{t('common:buttons.submit')}</LoaderButton>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
};

RequestForReturnModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The percentage object to store progress integer for each file
   */
  percentage: PropTypes.object.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default RequestForReturnModal;