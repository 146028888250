import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik, Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { DEFAULT_PROVIDER } from '../../constants';

import { appsApi, adminApi } from '../../api'

import { useAlerts } from '../context';

import FieldWrapper from '../form/wrappers/Field';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import CopyButton from '../common/CopyButton';

const DEFAULT_VALUES = {
  name: 'Sila-DebitCard-Refund-Workflow',
  provider: DEFAULT_PROVIDER
};

const ProviderWebhooksForm = ({ appId, admin }) => {
  const [initialValues, setInitialValues] = useState({
    webhooks: [DEFAULT_VALUES]
  });
  const [currentWebhook, setCurrentWebhook] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [generated, setGenerated] = useState(false);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const formRef = useRef(null);
  const authKeyRef = useRef(null);
  const signKeyRef = useRef(null);
  const history = useHistory();

  const getProviderWebhooks = async () => {
    setIsLoading(true);
    try {
      let [credentialsResponse, webhooksResponse] = await Promise.all(admin ? [
        adminApi.getCkoCredentials(appId),
        adminApi.getProviderWebhooks(appId),
      ] : [
        appsApi.getCkoCredentials(appId),
        appsApi.getProviderWebhooks(appId),
      ]);
      if (!credentialsResponse.success) {
        setError(credentialsResponse.message);
        return;
      } else if (!webhooksResponse.success) {
        setError(webhooksResponse.message);
        return;
      };
      setInitialValues({ webhooks: webhooksResponse.webhooks.length ? webhooksResponse.webhooks.filter(webhook => webhook.provider === DEFAULT_PROVIDER) : [DEFAULT_VALUES] });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteProviderWebhooks = async (values) => {
    if (values.id) {
      setIsDeleting(true);
      try {
        const response = await adminApi.deleteProviderWebhooks(appId, values);
        setAlert({ message: response.message, variant: 'success' });
        getProviderWebhooks();
      } catch (error) {
        setError(error);
      } finally {
        setIsDeleting(false);
        setGenerated(false);
      }
    }
  };

  useEffect(() => {
    getProviderWebhooks();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={yup.object().shape({
        webhooks: yup.array().of(yup.object().shape({
          name: yup.string()
            .required(t('common:form.errors.required', { field: t('applications.providerWebhooks.form.fields.name.label') }))
        }))
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const index = values.webhooks.findIndex(webhook => webhook.id === currentWebhook.id);
        const method = !initialValues.webhooks[index].id ? 'post' : 'put';
        let response;
        if (initialValues.webhooks[index].id) {
          setGenerated(true);
          values.webhooks[index].authentication_key = uuid();
          values.webhooks[index].signing_key = uuid();
        }
        try {
          if (admin) {
            response = await adminApi.updateProviderWebhooks(appId, values.webhooks[index], method);
          } else {
            response = await appsApi.updateProviderWebhooks(appId, values.webhooks[index], method);
          }
          setAlert({ message: t('applications.providerWebhooks.form.messages.success'), variant: 'success' });
          if (error) setError(false);
          getProviderWebhooks();
        } catch (error) {
          setAlert({ message: error, variant: 'warning' });
          resetForm({ values: initialValues });
        } finally {
          setSubmitting(false);
        }
      }}>
      {({ values, handleSubmit, isSubmitting }) => (
        <Form noValidate className="loaded" autoComplete="new-password" onSubmit={handleSubmit}>

          {admin && <h4 className="mb-4">{t('applications.providerWebhooks.title')}</h4>}

          {error && <Alert variant="warning" className="mb-4 loaded">
            <i className="fas fa-exclamation-triangle text-warning mr-2"></i> {error}
          </Alert>}

          {(isSubmitting || isLoading) && <Loader />}

          <h4 className="text-hdr mb-4">{t('applications.providerWebhooks.headers.refund')}</h4>

          <FieldArray
            name="webhooks"
            render={arrayHelpers => <>

              {values.webhooks.map((webhook, index) => <Fragment key={webhook.id || index}>
                <Field required readOnly
                  id={`appProviderWebhooksForm.${appId}.webhook.${index}.name`}
                  name={`webhooks.${index}.name`}
                  label={t('applications.providerWebhooks.form.fields.name.label')}
                  component={FieldWrapper} />

                <Field required readOnly
                  id={`appProviderWebhooksForm.${appId}.webhook.${index}.authentication_key`}
                  name={`webhooks.${index}.authentication_key`}
                  label={t('applications.providerWebhooks.form.fields.authKey.label')}
                  placeholder={!isLoading ? t('applications.providerWebhooks.form.fields.authKey.placeholder') : t('common:status.loading')}
                  inputRef={authKeyRef}
                  append={<>
                    {generated && <strong className="text-success mr-2 loaded">{t('applications.labels.generated')}</strong>}
                    {webhook.id && document.queryCommandSupported('copy') && <CopyButton inputRef={authKeyRef} variant="link" placement="top" className="p-1" />}
                  </>}
                  component={FieldWrapper} />

                <Field required readOnly
                  id={`appProviderWebhooksForm.${appId}.webhook.${index}.signing_key`}
                  name={`webhooks.${index}.signing_key`}
                  label={t('applications.providerWebhooks.form.fields.signKey.label')}
                  placeholder={!isLoading ? t('applications.providerWebhooks.form.fields.signKey.placeholder') : t('common:status.loading')}
                  inputRef={signKeyRef}
                  append={<>
                    {generated && <strong className="text-success mr-2 loaded">{t('applications.labels.generated')}</strong>}
                    {webhook.id && document.queryCommandSupported('copy') && <CopyButton inputRef={signKeyRef} variant="link" placement="top" className="p-1" />}
                  </>}
                  component={FieldWrapper} />

                <div className={`actions d-flex flex-wrap justify-content-end ${!admin ? 'mx-0 mb-0 mt-4 p-0 mx-md-n4 mb-md-n4 p-md-4 border-top border-light' : 'mt-4'}`}>

                  <div className="mr-0 mr-md-auto">

                    {admin && webhook.id && <LoaderButton variant="outline-warning" disabled={isDeleting} loading={isDeleting} loadingLabel={t('common:status.deleting')} onClick={() => deleteProviderWebhooks(webhook)} className="px-4 loaded">{t('common:buttons.delete')}</LoaderButton>}

                    {/* TODO: Remove the conditional render from the cancel button, and make it reset the form. See ENGRG-3950*/}
                    {/* {!admin && <Button variant="outline-light" onClick={() => history.push('/apps')} className="px-4">{t('common:buttons.cancel')}</Button>} */}

                  </div>

                  <LoaderButton variant="primary" disabled={error} loading={(isLoading || isSubmitting) ? true : undefined} loadingLabel={isLoading ? t('common:status.loading') : undefined} type="submit" onClick={() => setCurrentWebhook(webhook)} className={!admin ? 'ml-0 ml-md-3 px-4' : undefined}>{webhook.id ? t('applications.providerWebhooks.form.buttons.regenerate') : t('applications.providerWebhooks.form.buttons.create')}</LoaderButton>

                </div>

              </Fragment>)}

            </>}
          />

        </Form>
      )}
    </Formik>
  );
};

ProviderWebhooksForm.propTypes = {
  /**
   * The id of the application
   */
  appId: PropTypes.number.isRequired,
  /**
   * The admin toggle to determine if the form is used for the admin console
   */
  admin: PropTypes.bool
};

export default ProviderWebhooksForm;