import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

import SandboxToggle from './SandboxToggle';
// import Messages from './Messages';
// import Notifications from './Notifications';
import UserActions from './UserActions';

const NavbarNav = () => (
  <Nav navbar className="flex-row">
    <SandboxToggle />
    {/* <Messages />
    <Notifications /> */}
    <UserActions />
  </Nav>
);

export default NavbarNav;
