import React from 'react';
import classNames from 'classnames';

import { Container, Row, Col } from 'react-bootstrap';

import Loader from '../components/common/Loader';
import MainNavbar from '../components/layout/MainNavbar/MainNavbar';
import MainSidebar from '../components/layout/MainSidebar/MainSidebar';
import MainFooter from '../components/layout/MainFooter';

const DefaultLayout = ({ children, app, user, full }) => {
  const mainClasses = classNames('main flex-column p-0', user && 'white-bg');
  const isVisible = (user && !full) || (full && app.menuVisible);
  return (
    <>
      {app.isLoading && <Loader fixed />}
      <Container fluid>
        <Row>
          {user && <MainSidebar full={full} />}
          <Col
            className={mainClasses}
            sm={isVisible ? 12 : undefined}
            as="main"
          >
            <MainNavbar full={full} />
            <section className="main-content d-flex flex-column">
              {children}
            </section>
            <MainFooter />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DefaultLayout;