import React, { useState } from 'react';
import { Card, Table, Button, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';

import { adminApi } from '../../api';

import Loader from '../common/Loader';
import TooltipButton from '../common/TooltipButton';
import VerficationRecordModal from './VerficationRecordModal';

import { formatDateAndTime } from '../../utils';

import { useAlerts } from '../context';

const VerificationRecords = ({ initialValues, setInitialValues, getEndUser }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [record, setRecord] = useState({ show: false, data: undefined, handle: initialValues.handle, name: initialValues.full_name });
  const [loaded, setLoaded] = useState({
    verification_history: initialValues.verification_history.length !== 0,
    member_verifications: initialValues.member_verifications ? true : false
  });

  const getVerficationHistory = async (type) => {
    setLoaded({ ...loaded, [type]: false });
    getEndUser((response) => {
      setInitialValues(response);
      setLoaded({ ...loaded, [type]: response[type] && response[type].length !== 0 });
    });
  };

  const submitRecord = async (handle, values, type, id) => {
    if (values) {
      try {
        await adminApi.updateEndUser(handle, {
          verification_records: [id ? {
            action: 'update',
            uuid: id,
            verification_status: values.verification_status,
            comment: values.comment || null
          } : {
            action: 'add',
            kyc_level: values.kyc_level,
            comment: values.comment || null
          }]
        });
        setAlert({ message: t(`admin.endUsers.records.new_evaluation.form.messages.${id ? 'update' : 'create'}`), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        getVerficationHistory(type);
        setRecord({ ...record, show: false, data: undefined, handle: initialValues.handle, name: initialValues.full_name });
      }
    } else {
      setRecord({ ...record, show: false, data: undefined, handle: initialValues.handle, name: initialValues.full_name });
    }
  };

  return (<>

    {initialValues.verification_history.length !== 0 && <><h4 className="mb-2">{t(`admin.endUsers.records.headers.${initialValues.entity_type}.verification`)}</h4>
      <p className="text-info mb-2">{t(`admin.endUsers.records.verification_history.${initialValues.entity_type}.description`)}</p>
      <p className="text-right mb-3"><Button variant="link" onClick={() => getVerficationHistory('verification_history')} className="text-reset text-lg p-0"><i className="fas fa-sync-alt mr-3 text-primary"></i>{t('common:buttons.refresh')}</Button></p>
      <Card className="mb-2 border overflow-hidden">
        {!loaded.verification_history && <Loader />}
        <Table bordered responsive className="border-0">
          <thead>
            <tr>
              <th className="border-right pl-4 py-3">{t('admin.endUsers.records.verification_history.labels.date', { type: initialValues.entity_type === 'business' ? t('common:kyb.label') : t('common:kyc.label') })}</th>
              <th className="border-right px-4 py-3">{t('admin.endUsers.records.verification_history.labels.level', { type: initialValues.entity_type === 'business' ? t('common:kyb.label') : t('common:kyc.label') })}</th>
              <th className="border-right px-4 py-3">{t('admin.endUsers.records.verification_history.labels.alloy')}</th>
              <th className="border-right px-4 py-3">{t('admin.endUsers.records.verification_history.labels.verification')}</th>
              {initialValues.entity_type === 'business' && <th className="px-4 py-3">{t('common:kyb.certification_status')}</th>}
              <th className="pr-4 py-3 text-center" width="1%">{t('common:labels.action')}</th>
            </tr>
          </thead>
          <tbody>
            {initialValues.verification_history.map((record, index) =>
              <tr key={index}>
                <td className="pl-4 py-3">{formatDateAndTime(record.requested_at * 1000)}</td>
                <td className="px-4 py-3">{record.kyc_level}</td>
                <td className="px-4 py-3">{record.entity_token ? <Button href={`https://app.alloy.co/entities/${record.entity_token}/evaluations/${record.evaluation_token}`} target="_blank" variant="link" className="p-0 text-reset text-underline">{t('admin.endUsers.records.verification_history.buttons.viewEval')}</Button> : <span className="text-info">{t('common:labels.na')}</span>}</td>
                <td className="px-4 py-3"><Badge pill className="badge-outline py-2 px-3 w-100" variant={record.verification_status.includes('passed') ? 'success' : record.verification_status.includes('failed') ? 'warning' : 'primary'}>{initialValues.verification_statuses.find(status => status.name == record.verification_status).label}</Badge></td>
                {initialValues.entity_type === 'business' && <td className="px-4 py-3">{t(`common:kyb.${initialValues.certification_status}`)}</td>}
                <td className="pr-4 py-3 text-center" width="1%">
                  {record.verification_id ? <Button className="p-0 text-lg text-info hover" onClick={() => setRecord({ ...record, show: true, data: record, name: initialValues.full_name, handle: initialValues.handle })} variant="link"><i className="fas fa-pen"></i></Button> : <TooltipButton placement="auto" variant="link" text={t('admin.endUsers.records.verification_history.messages.noId')} className="p-0 cursor-pointer"><i className="fas fa-exclamation-triangle text-warning text-lg"></i></TooltipButton>}</td>
              </tr>)}
          </tbody>
        </Table>
      </Card>
      <p className="text-right mb-5"><Button target="_blank" variant="link" className="p-0 text-underline" onClick={() => setRecord({ ...record, show: true, handle: initialValues.handle, name: initialValues.full_name })}>{t('admin.endUsers.records.verification_history.buttons.addEval')}</Button></p>
    </>}

    {initialValues.member_verifications && initialValues.member_verifications.length !== 0 && <><h4 className="mb-2">{t(`admin.endUsers.records.headers.${initialValues.entity_type}.member_verifications`)}</h4>
      <p className="text-info mb-2">{t(`admin.endUsers.records.member_verifications.description`)}</p>
      <p className="text-right mb-3"><Button variant="link" className="text-reset text-lg p-0" onClick={() => getVerficationHistory('member_verifications')}><i className="fas fa-sync-alt mr-3 text-primary"></i>{t('common:buttons.refresh')}</Button></p>
      <Card className="mb-2 border position-relative overflow-hidden">
        {!loaded.member_verifications && <Loader />}
        <Table bordered responsive className="border-0">
          <thead>
            <tr>
              <th className="border-right pl-4 py-3">{t('common:form.fields.name.label')}</th>
              <th className="border-right px-4 py-3" width="1%">{t('common:kyb.roles.controlling_officer')}</th>
              <th className="border-right px-4 py-3" width="1%">{t('common:kyb.roles.beneficial_owner')}</th>
              <th className="border-right px-4 py-3" width="1%">{t('common:kyb.roles.admin')}</th>
              {/* <th className="border-right px-4 py-3">{t('admin.endUsers.records.verification_history.allowEvalLink')}</th> */}
              <th className="border-right px-4 py-3" width="1%">{t('admin.endUsers.records.verification_history.labels.verification')}</th>
              <th className="border-right px-4 py-3" width="1%">{t('common:kyb.certification_status')}</th>
              <th className="pr-4 py-3 text-center" width="1%">{t('common:labels.action')}</th>
            </tr>
          </thead>
          <tbody>
            {initialValues.member_verifications.map((record, index) =>
              <tr key={index}>
                <td className="pl-4 py-3"><Button href={`/admin/end_users/${record.user_handle}/profile`} variant="link" className="text-reset text-underline p-0">{`${record.first_name} ${record.last_name}`}</Button></td>
                <td className="px-4 py-3 text-center">{record.role === 'controlling_officer' && <i className="fas fa-circle text-primary"></i>}</td>
                <td className="px-4 py-3 text-center">{record.role === 'beneficial_owner' && <i className="fas fa-circle text-primary"></i>}</td>
                <td className="px-4 py-3 text-center">{record.role === 'administrator' && <i className="fas fa-circle text-primary"></i>}</td>
                {record.entity_token && <td className="px-4 py-3">{record.entity_token ? <Button href={`https://app.alloy.co/entities/${record.entity_token}/evaluations/${record.evaluation_token}`} target="_blank" variant="link" className="p-0 text-reset text-underline">{t('admin.endUsers.records.verification_history.addNewEvalLink')}</Button> : <span className="text-info">{t('common:labels.na')}</span>}</td>}
                <td className="px-4 py-3"><Badge pill className="badge-outline py-2 px-3 w-100" variant={record.verification_status.includes('passed') ? 'success' : record.verification_status.includes('failed') ? 'warning' : 'primary'}>{initialValues.verification_statuses.find(status => status.name == record.verification_status).label}</Badge></td>
                <td className="px-4 py-3">{t(`common:kyb.${record.beneficial_owner_certification_status}`)}</td>
                <td className="pr-4 py-3 text-center">
                  {record.verification_id ? <Button className="p-0 text-lg text-info hover" onClick={() => setRecord({ ...record, show: true, data: record, handle: record.user_handle, name: `${record.first_name} ${record.last_name}` })} variant="link"><i className="fas fa-pen"></i></Button> : <TooltipButton placement="auto" variant="link" text={t('admin.endUsers.records.verification_history.messages.noId')} className="p-0 cursor-pointer"><i className="fas fa-exclamation-triangle text-warning text-lg"></i></TooltipButton>}</td>
              </tr>)}
          </tbody>
        </Table>
      </Card>
    </>}

    {initialValues.kyc_levels && initialValues.verification_statuses && <VerficationRecordModal show={record.show} onHide={submitRecord} data={record.data} name={record.name} kyc_levels={initialValues.kyc_levels} verification_statuses={initialValues.verification_statuses} flow={initialValues.entity_type === 'business' ? 'kyb' : 'kyc'} handle={initialValues.handle} />}

  </>
  );
};

VerificationRecords.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to set the value of the form
   */
  setInitialValues: PropTypes.func.isRequired
};

export default VerificationRecords;