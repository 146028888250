import React from 'react';
import { Formik, Field } from 'formik';
import { Form, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { STATES_ARRAY, PHONE_REG_EXP, POSTAL_REG_EXP, EIN_REG_EXP, SSN_REG_EXP } from '../../../constants';

import FieldWrapper from '../../form/wrappers/Field';
import Loader from '../Loader';
import LoaderButton from '../LoaderButton';

const EndUserForm = ({ initialValues, onSubmit, formRef, customer, formFooterComponent, setName }) => {
  const { t } = useTranslation();

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        first_name: initialValues.entity_type !== 'business' ? yup.string()
          .required(t('common:form.errors.required', { field: t('common:form.fields.firstName.label') })) : null,
        last_name: initialValues.entity_type !== 'business' ? yup.string()
          .required(t('common:form.errors.required', { field: t('common:form.fields.lastName.label') })) : null,
        handle: yup.string()
          .required(t('common:form.errors.required', { field: t('common:form.fields.handle.label') })),
        birthdate: initialValues.entity_type !== 'business' ? yup.string()
          .max(new Date(), t('common:form.fields.birthdate.errors.max'))
          .required(t('common:form.errors.required', { field: t('common:form.fields.birthdate.label') })) : null,
        phones: yup.array().of(yup.object().shape({
          phone: yup.string()
            .matches(PHONE_REG_EXP, t('common:form.fields.phone.error'))
            .required(t('common:form.errors.required', { field: t('common:form.fields.phone.label') }))
        })),
        emails: yup.array().of(yup.object().shape({
          email: yup
            .string()
            .email(t('common:form.fields.email.error'))
            .max(254, t('common:form.errors.max', { count: 320 }))
            .required(t('common:form.errors.required', { field: t('common:form.fields.email.label') })),
        })),
        identities: yup.array().of(yup.object().shape({
          identity: !customer ? yup.string()
            .test('checkMask', t(`common:form.fields.${initialValues.entity_type === 'business' ? 'ein' : 'ssn'}.error`), (value) => {
              const regex = initialValues.entity_type === 'business' ? EIN_REG_EXP : SSN_REG_EXP; return !value || (!!value.includes('*') || !!regex.test(value))
            })
            .required(t('common:form.errors.required', { field: t(`common:form.fields.${initialValues.entity_type === 'business' ? 'ein' : 'ssn'}.label`) })) : null
        })),
        addresses: yup.array().of(yup.object().shape({
          street_address_1: yup.string()
            .max(254, t('common:form.errors.max', { count: 254 })),
          street_address_2: yup.string()
            .nullable()
            .max(254, t('common:form.errors.max', { count: 254 })),
          city: yup.string()
            .ensure().when('street_address_1', {
              is: (value) => value && value.length,
              then: yup.string().required(t('common:form.errors.required', { field: t('common:form.fields.address.city.label') }))
            })
            .max(96, t('common:form.errors.max', { count: 96 })),
          state: yup.string()
            .ensure().when('street_address_1', {
              is: (value) => value && value.length,
              then: yup.string().required(t('common:form.errors.required', { field: t('common:form.fields.address.state.label') }))
            }),
          postal_code: yup.string()
            .ensure().when('street_address_1', {
              is: (value) => value && value.length,
              then: yup.string()
                .required(t('common:form.errors.required', { field: t('common:form.fields.address.zip.label') }))
                .matches(POSTAL_REG_EXP, {
                  message: t('common:form.fields.address.zip.error')
                })
            })
        }))
      })}
      onSubmit={(values) => onSubmit(values)}>
      {({ values, errors, handleSubmit, isSubmitting, dirty }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>

          {isSubmitting && <Loader />}

          {dirty && <Alert variant="warning" className="mb-4 loaded">{t('common:form.messages.unsavedChanges')}</Alert>}

          <Form.Row>
            <Col xs="12" sm="6">
              <Field autofocus readOnly={customer} className="first-name"
                id="endUserProfile.first_name"
                label={t('common:form.fields.firstName.label')}
                name="first_name"
                handleChange={setName ? e => setName(`${e.target.value} ${values.surname}`) : undefined}
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="6">
              <Field autofocus readOnly={customer} className="last-name"
                id="endUserForm.last_name"
                label={t('common:form.fields.lastName.label')}
                name="last_name"
                handleChange={setName ? e => setName(`${values.first_name} ${e.target.value}`) : undefined}
                component={FieldWrapper} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="12" sm="6">
              <Field autofocus readOnly className="handle"
                id="endUserForm.handle"
                label={t('common:form.fields.handle.label')}
                name="handle"
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="6">
              <Field autofocus readOnly={customer} className="ssn"
                id="endUserForm.ssn"
                label={t(`common:form.fields.${initialValues.entity_type === 'business' ? 'ein' : 'ssn'}.label`)}
                name="identities[0].identity"
                component={FieldWrapper} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="12" sm="4">
              <Field autofocus readOnly={customer} className="birthdate"
                id="endUserForm.birthdate"
                label={t('common:form.fields.birthdate.shortLabel')}
                name="birthdate"
                type="date"
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="4">
              <Field autofocus className="email"
                id="endUserForm.email"
                label={t('common:form.fields.email.label')}
                name="emails[0].email"
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="4">
              <Field autofocus className="phone"
                id="endUserForm.phone"
                label={t('common:form.fields.phone.longLabel')}
                name="phones[0].phone"
                component={FieldWrapper} />
            </Col>
          </Form.Row>
          <Field autofocus className="addresses"
            id={`endUserForm.addresses[0].street1`}
            label={t('common:form.fields.address.street1.label')}
            name={`addresses[0].street_address_1`}
            component={FieldWrapper} />
          <Field autofocus className="street-address-2"
            id={`endUserForm.addresses[0].street2`}
            label={t('common:form.fields.address.street2.label')}
            name={`addresses[0].street_address_2`}
            component={FieldWrapper} />
          <Form.Row>
            <Col xs="12" sm="4">
              <Field autofocus className="city"
                id={`endUserForm.addresses[0].city`}
                label={t('common:form.fields.address.city.label')}
                name={`addresses[0].city`}
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="4">
              <Field autofocus className="state"
                id={`endUserForm.addresses[0].state`}
                label={t('common:form.fields.address.state.label')}
                name={`addresses[0].state`}
                type="select"
                options={STATES_ARRAY.map(state => ({
                  label: t(`common:form.fields.address.state.options.${state}`),
                  value: state
                }))}
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="4">
              <Field autofocus className="postal-code"
                id={`endUserForm.addresses[0].postal_code`}
                label={t('common:form.fields.address.zip.label')}
                name={`addresses[0].postal_code`}
                component={FieldWrapper} />
            </Col>
          </Form.Row>

          <div className="actions d-flex flex-wrap align-items-center justify-content-end mt-4">
            {formFooterComponent && formFooterComponent({ errors, values, isSubmitting, dirty })}
            <LoaderButton loading={isSubmitting} className="ml-4" onClick={isSubmitting ? () => e.preventDefault() : undefined} type="submit" disabled={!dirty || Object.keys(errors).length}>{t('common:buttons.save')}</LoaderButton>
          </div>

        </Form>
      )}
    </Formik>
  );
};

EndUserForm.propTypes = {
  /**
  * The form ref
  */
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  /**
   * The set name function to show the full name outside of the form
   */
  setName: PropTypes.func,
  /**
   * The customer boolean to determine the view for customer or admin
   */
  customer: PropTypes.bool,
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The submit function
   */
  onSubmit: PropTypes.func.isRequired,
  /**
  * The form ref
  */
  formFooterComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default EndUserForm;