import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { capitalize } from '../../utils';
import { teamApi } from '../../api';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import TooltipButton from '../../components/common/TooltipButton';
import TransactionForm from '../../components/common/transactions/TransactionForm';

const Transaction = ({ t, match, location, history, user, setAlert }) => {
  const [key, setKey] = useState(match.params.action);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [redirect, setRedirect] = useState(false);
  const notSubmitted = initialValues.submitted === null && initialValues.transaction_status === 'pending' && initialValues.usd_status === 'pending' && (initialValues.transaction_type === 'issue' || initialValues.transaction_type === 'redeem');

  const getTransaction = async () => {
    try {
      const response = await teamApi.getTransaction(match.params.id, user.currentTeam.id);
      if (response.success) {
        setInitialValues(response.transaction);
      } else {
        setRedirect(true);
      }
    } catch (error) {
      setRedirect(true);
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setKey(match.params.action);
  }, [match]);

  useEffect(() => {
    if (redirect) history.push({ pathname: '/settings/transactions', state: { from: location.pathname } });
  }, [redirect]);

  useEffect(() => {
    getTransaction();
  }, []);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('common:transactions.detail.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {isLoading ? <Loader /> : <Card className="loaded">
        <Card.Header className="d-flex justify-content-between p-4">
          <h3 className="m-0">{`${t('common:transactions.detail.type')}: ${' '} ${t(`common:transactions.${initialValues.transaction_type}`)}`}</h3>
          <p className={`text-lg mb-0 text-${notSubmitted ? 'info' : initialValues.transaction_status === 'failed' ? 'warning' : initialValues.transaction_status === 'success' ? 'success' : 'primary'}`}>{notSubmitted ? <span className="d-flex align-items-center text-nowrap">{t('common:transactions.status.notSubmitted')}<TooltipButton placement="top" variant="link" text={t('common:transactions.tips.notSubmitted')} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton></span> : capitalize(initialValues.transaction_status)}</p>
        </Card.Header>
        <Card.Body as={Row} className="p-0" noGutters>
          <Tab.Container activeKey={key} defaultActiveKey={key} onSelect={k => setKey(k)}>
            <Col md="4" lg="3" xl="2" className="border-right">
              <Nav className="d-flex flex-column align-content-start h-100 py-4">
                <Nav.Item className="w-100">
                  <Nav.Link className={`d-block px-4 py-3${key === 'detail' ? ' bg-primary-accent text-primary' : ''}`} eventKey="detail" as={NavLink} to={{ pathname: `/settings/transaction/${match.params.id}/detail`, state: { from: location.pathname } }}>{t('common:transactions.detail.title')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="8" lg="9" xl="10" className="p-4">
              <Tab.Content>
                <Tab.Pane eventKey="detail">
                  <TransactionForm
                    customer={true}
                    user={user}
                    initialValues={initialValues}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Card.Body>
      </Card>}

    </Container>
  );
};

export default Transaction;