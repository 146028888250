import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Table, Dropdown, DropdownButton, Button } from 'react-bootstrap';

import { teamApi, consoleApi } from '../../api';
import { hasPermissions, hasExpired, formatRole, formatRoleID } from '../../utils';
import { capitalize } from '../../utils';
import { useInfiniteScroll } from '../../utils/hooks';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import ConfirmModal from '../../components/common/ConfirmModal';
import Member from '../../components/team/Member';
import MemberDataModal from '../../components/team/MemberDataModal';

import { useAuth } from '../../components/context';

import { SLACK_EVENT_TRACKING_CHANNEL } from '../../constants';

const Members = ({ t, history, user, env, match, setAlert }) => {
  const { onUpdateUser } = useAuth();
  const [roles, setRoles] = useState([]);
  const [currentMember, setCurrentMember] = useState({ show: false, delete: false, data: undefined, loading: false });
  const [filters, setFilters] = useState({ role: '', search: '' });
  const { data, setData, setRefresh, lastItem, hasMore, isLoading, isFetching, loaded, error } = useInfiniteScroll(teamApi.fetchMembers, {
    slug: 'members',
    team: user.currentTeam ? user.currentTeam.id : null,
    limit: 20
  }, hasPermissions(user, 'members', 'Read'));

  const members = data.filter(member => {
    return Object.keys(filters).every(filter => {
      if (!filters[filter]) { return true; }
      if (filter === 'role') {
        if (member.role) {
          return member.role.id === filters.role;
        } else {
          return member.invitation && formatRoleID(member.invitation.role) === filters.role;
        }
      } else if (filter === 'search') {
        return Object.keys(member).map(prop => prop).some(prop => {
          if (member.member) {
            return prop !== 'role' && member[prop] ? member[prop].name.toLowerCase().includes(filters[filter].toLowerCase()) || member[prop].handle.toLowerCase().includes(filters[filter].toLowerCase()) : false;
          } else {
            return member.invitation.email.toLowerCase().includes(filters[filter].toLowerCase()) || false;
          }
        });
      }
    });
  });

  const sendSlackMessage = async (data) => {
    try {
      await consoleApi.sendSlackMessage({
        channel: SLACK_EVENT_TRACKING_CHANNEL,
        message: t('invite.messages.events.sent', data)
      });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const handleCreate = async (values) => {
    if (values && (user.role !== 1 && parseInt(values.role.id) !== 1 || user.role === 1)) {
      setCurrentMember({ ...currentMember, loading: true });
      try {
        const response = await teamApi.inviteMember(values, user.currentTeam, env);
        sendSlackMessage({
          name: `${user.entity.first_name} ${user.entity.surname}`,
          handle: user.entity.handle,
          email: user.entity.emails[0].email,
          role: t(`settings.members.labels.roles.${parseInt(values.role.id)}`),
          invite: values.member.email,
          team: user.currentTeam.name,
          env: capitalize(env)
        });
        setAlert({ message: response.message, variant: 'success' });
        setRefresh(true);
        if (!user.hasMembers) onUpdateUser({ hasMembers: true });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentMember({ ...currentMember, data: undefined, show: false });
      }
    } else {
      setCurrentMember({ ...currentMember, data: undefined, show: false });
    }
  };

  const handleEdit = async (values) => {
    if (values.invitation) {
      values.role = { id: formatRoleID(values.invitation.role), name: values.invitation.role };
      setCurrentMember({ ...currentMember, data: values, show: true });
    } else {
      setCurrentMember({ ...currentMember, data: { member: {} }, show: true, loading: true });
      try {
        const response = await teamApi.getMember(values, user.currentTeam);
        setCurrentMember({ ...currentMember, data: response, show: true, loading: false });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  const handleSave = async (values) => {
    if (values && values.member && !values.member.token && (user.role !== 1 && parseInt(values.role.id) !== 1 || user.role === 1)) {
      try {
        const response = await teamApi.updateMember(values, user.currentTeam);
        setAlert({ message: response.message, variant: 'success' });
        setData(data.map(m => m.member && m.member.id && values.member && values.member.id && m.member.id === values.member.id ? { member: values.member, role: values.role } : m));
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentMember({ ...currentMember, data: undefined, show: false });
      }
    } else if (values && values.invitation) {
      handleRevoke(values);
      handleCreate(values);
      setCurrentMember({ ...currentMember, data: undefined, show: false });
    } else {
      setCurrentMember({ ...currentMember, data: undefined, show: false });
    }
  }

  const handleRevoke = async (values) => {
    if (values.invitation) {
      try {
        await teamApi.deleteInvitation(values.invitation.id);
        setData(data.filter(m => m.member || m.invitation && m.invitation.id !== values.invitation.id));
        if (!values.member) setAlert({ message: t('settings.members.messages.delete', { user: values.invitation.email }), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  }

  const handleDelete = async (values) => {
    if (values && values.member) {
      try {
        await teamApi.deleteMember(values, user.currentTeam, user.entity.handle);
        setData(data.filter(m => m.member && m.member.id !== values.member.id));
        setAlert({ message: t('settings.members.messages.delete', { user: values.member.name }), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentMember({ ...currentMember, data: undefined, delete: false });
      }
    } else if (values && values.invitation) {
      handleRevoke(values);
      setCurrentMember({ ...currentMember, data: undefined, delete: false });
    } else {
      setCurrentMember({ ...currentMember, data: undefined, delete: false });
    }
  }

  useEffect(() => {
    if (error) setAlert({ message: error, variant: 'warning' });
  }, [error]);

  useEffect(() => {
    if (loaded) setRefresh(true);
  }, [user.currentTeam]);

  useEffect(() => {
    members.forEach(member => {
      if (member.role && !roles.some(role => parseInt(role) === parseInt(member.role.id))) {
        setRoles([...roles, member.role.id]);
      }
      if (member.invitation && !roles.some(role => parseInt(role) === formatRoleID(member.invitation.role))) {
        setRoles([...roles, formatRoleID(member.invitation.role)]);
      }
    });
  }, [members]);

  useEffect(() => {
    if (match.params.action === 'new' && hasPermissions(user, 'members', 'Write')) {
      setCurrentMember({ ...currentMember, data: undefined, show: true });
    }
  }, []);

  return (
    <Container className="main-content-container members p-3 p-md-5">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('settings.members.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {isLoading ? <Loader /> : <Card className="mb-3 mb-md-5">
        <Card.Header as={Row} className="p-4" noGutters>
          <Col sm="8" className="roles d-block d-sm-flex">
            <Form.Control size="lg" className="w-100" placeholder={t('common:labels.search')} aria-label={t('common:labels.search')} onChange={(e) => setFilters({ ...filters, search: e.currentTarget.value.trimStart() })} />
            {roles.length > 0 && <DropdownButton className="text-nowrap ml-0 mt-2 ml-sm-2 mt-sm-0" variant="outline-light" title={filters.role ? formatRole(t, filters.role) : t('settings.members.labels.all')}>
              {roles.filter(role => filters.role !== role).map((role, key) =>
                <Dropdown.Item key={key} onClick={() => setFilters({ ...filters, role: role })}>{formatRole(t, role)}</Dropdown.Item>
              )}
              {filters.role && <Dropdown.Item onClick={() => setFilters({ ...filters, role: '' })}>{t('settings.members.labels.all')}</Dropdown.Item>}
            </DropdownButton>}
          </Col>
          <Col sm="4" className="text-right">
            {hasPermissions(user, 'members', 'Write') && <Button variant="outline-light" onClick={() => { setCurrentMember({ ...currentMember, data: undefined, show: true }); }}>{t('settings.members.buttons.create')} <i className="fas fa-plus-circle ml-2"></i></Button>}
          </Col>
        </Card.Header>
        <Card.Body className="py-0 px-4">
          <div className="mx-n4">
            <Table className="border-bottom" hover responsive>
              <thead>
                <tr>
                  <th className="py-3 pl-4">{t('settings.members.labels.name')}</th>
                  <th className="py-3 ">{t('settings.members.labels.handle')}</th>
                  <th className="py-3">{t('settings.members.labels.role')}</th>
                  <th className="py-3">{t('settings.members.labels.status')}</th>
                  {/* <th>{t('settings.members.labels.admin')}</th> */}
                  {hasPermissions(user, 'members', 'Delete') && <th className="py-3 pr-4" width='1%'></th>}
                </tr>
              </thead>
              <tbody>
                {!members.length > 0 && <tr className="disabled"><td colSpan="5" className="p-0 cursor-default"><p className="text-center text-info font-italic mx-4 my-5">{t(`settings.members.empty.${filters.search ? 'search' : 'entries'}`)}</p></td></tr>}
                {members.length > 0 && members.sort((x, y) =>
                  x.member && y.member ? x.member.name.localeCompare(y.member.name) :
                    x.invitation && y.invitation ? x.invitation.email.localeCompare(y.invitation.email) :
                      x.member ? -1 : y.member ? 1 :
                        x.invitation && !hasExpired(x.invitation.expires_at) ? -1 :
                          y.invitation && !hasExpired(y.invitation.expires_at) ? 1 : 0
                ).map((member, key) => {
                  return <Member key={key} data={member} onEdit={handleEdit} onDelete={() => { setCurrentMember({ ...currentMember, data: member, delete: true }) }} ref={members[members.length - 1].key === members.key ? lastItem : null} />
                })}
              </tbody>
            </Table>
          </div>
          {loaded && isFetching && hasMore && <div className="fetching"><Loader label={t('settings.members.loading')} /></div>}
        </Card.Body>
      </Card>}

      {currentMember.show &&
        <MemberDataModal
          show={currentMember.show}
          onHide={currentMember.show && currentMember.data ? handleSave : handleCreate}
          data={currentMember.data}
          loading={currentMember.loading} />}

      {currentMember.delete &&
        <ConfirmModal
          show={currentMember.delete}
          data={currentMember.data}
          title={t('common:buttons.confirm')}
          message={currentMember.data.invitation ? t('settings.members.messages.revoke', { email: currentMember.data.invitation.email }) : t('common:form.messages.confirm.delete', { name: currentMember.data.member ? currentMember.data.member.name : currentMember.data.invitation.email })}
          buttonLabel={currentMember.data.invitation ? t('settings.members.buttons.revoke') : t('common:buttons.delete')}
          buttonVariant="warning"
          onHide={handleDelete} />}

    </Container>
  );
}

export default Members;