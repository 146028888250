import React, { useEffect } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

const NotFoundPage = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    history.push({ pathname: '/not_found', state: { from: location.pathname } });
  }, []);

  return null;
};

const CustomSwitch = ({ children }) => (<Switch>
  {children}
  <NotFoundPage />
</Switch>
);

export default CustomSwitch;