import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Loader from '../common/Loader';
import FieldWrapper from '../form/wrappers/Field';
import CaptchaWrapper from '../form/wrappers/Captcha';

import { authApi } from '../../api';

import { useDebounce } from '../../utils/hooks';

import { useAuth } from '../context';

const ConfirmPasswordActionForm = ({ onPasswordConfirm }) => {
  const [status, setStatus] = useState({ pending: false, isValid: false });
  const [recaptchaToken, setRecaptchaToken] = useState(process.env.RECAPTCHA_SKIP === 'True');
  const [password, setPassword] = useState('');
  const debounce = useDebounce(password, 750);
  const { t } = useTranslation();
  const { user } = useAuth();
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  const initialValues = {
    password: '',
  };

  const schema = yup.object().shape({
    password: yup.string()
      .required(t('common:form.errors.required', { field: t('common:form.fields.currentPassword.label') }))
      .test('confirmPassword', t('common:form.fields.currentPassword.error'), (value) => value && status.isValid)
  });

  const confirmPassword = async (value) => {
    if (value) {
      setStatus({ ...status, pending: true });
      try {
        let response;
        if (process.env.RECAPTCHA_SKIP !== 'True') {
          response = await authApi.confirmPassword(user.entity.handle, value, recaptchaToken);
        } else {
          response = await authApi.confirmPassword(user.entity.handle, value);
        }
        const isValid = response && response.success === true ? true : false;
        setStatus({ ...status, isValid, pending: false });
        onPasswordConfirm(isValid);
      } catch (error) {
        setStatus({ ...status, isValid: false, pending: false });
        onPasswordConfirm(false);
        if (process.env.RECAPTCHA_SKIP !== 'True') {
          setRecaptchaToken(false);
          recaptchaRef.current.reset();
        }
      } finally {
        formRef.current.setFieldTouched('password', true);
      }
    }
  }

  useEffect(() => {
    if (recaptchaToken && debounce) confirmPassword(debounce);
  }, [debounce, recaptchaToken]);

  return user ? (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={schema}>
      {({ handleSubmit }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
          <Field required autofocus
            className="password mb-4 pb-4 border-bottom"
            id="confirmPasswordForm.password"
            label={t('common:form.fields.currentPassword.label')}
            name="password"
            type="password"
            handleChange={e => setPassword(e.target.value)}
            isValid={status && status.isValid && !status.pending}
            append={status && status.pending ? <Loader size="sm" className="mr-3" /> : undefined}
            component={FieldWrapper} />
          <CaptchaWrapper ref={recaptchaRef} className="form-group m-0" handleChange={(value) => setRecaptchaToken(value)} />
        </Form>
      )}
    </Formik>
  ) : null;
};

ConfirmPasswordActionForm.propTypes = {
  /**
  * The function called when confirming the current password
  */
  onPasswordConfirm: PropTypes.func.isRequired
};

export default ConfirmPasswordActionForm;
