import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Card, Col } from 'react-bootstrap';
import * as yup from 'yup';

import { Field, useField } from 'formik';

import Loader from '../../components/common/Loader';
import PageTitle from '../../components/common/PageTitle';
import TooltipButton from '../../components/common/TooltipButton';
import ViewToEditForm from '../../components/form/ViewToEditForm';
import FieldWrapper from '../../components/form/wrappers/Field';
import { adminApi } from '../../api';
import {
  MAX_LIMIT_AMOUNT,
  MAX_LIMIT_BEGINS_AT_DELTA_DAYS,
  MAX_LIMIT_DURATION,
  MAX_LIMIT_EXPIRES_AFTER_COUNT,
  MAX_LIMIT_EXPIRES_AT_DELTA_DAYS,
} from '../../constants';

function CustomSelectField({
  limit,
  value,
  options,
  isEditing,
  t,
  toolTipText = '',
  isCreate = false,
  required = false,
}) {
  return (
    <Field
      className={`${value}`}
      id={`editLimitForm.${value}`}
      name={`${value}`}
      type={isEditing ? 'select' : 'text'}
      placeholder={limit[value]}
      disabled={!isEditing}
      options={isEditing ? options.map((reason) => ({ label: reason, value: reason })) : []}
      label={
        <>
          {t(`admin.limits.table.${value}`)}
          {toolTipText !== '' && (
            <TooltipButton
              placement="top"
              variant="link"
              text={toolTipText}
              className="ml-2 no-underline p-0"
            >
              <i className="sila-icon info text-lg" />
            </TooltipButton>
          )}
          {required && (isCreate || isEditing) && <span className="text-primary">{' *'}</span>}
        </>
      }
      component={FieldWrapper}
    />
  );
}

function CustomMultipleOptionsField({
  limit,
  value,
  options,
  isEditing,
  t,
  toolTipText = '',
  isCreate = false,
  required = false,
}) {
  // TODO: rewrite component to work with lists when the API is configured to handle lists
  // TODO: remove "transaction_graph_label" when formik is able to see `transaction_graph_labels`
  const isEditable = !limit[value].includes(',');
  return (
    <Field
      className={`${value}`}
      id={`editLimitForm.${value}`}
      name={`${value}`}
      type={isEditing && isEditable ? 'select' : 'text'}
      readOnly={!isEditable}
      disabled={!isEditing || !isEditable}
      options={isEditing ? options.map((reason) => ({ label: reason, value: reason })) : []}
      label={
        <>
          {t(`admin.limits.table.${value}`)}
          {toolTipText !== '' && (
            <TooltipButton
              placement="top"
              variant="link"
              text={toolTipText}
              className="ml-2 no-underline p-0"
            >
              <i className="sila-icon info text-lg" />
            </TooltipButton>
          )}
          {required && (isCreate || isEditing) && <span className="text-primary">{' *'}</span>}
        </>
      }
      component={FieldWrapper}
    />
  );
}

function CustomTextField({
  limit,
  value,
  isEditing,
  t,
  toolTipText = '',
  units = false,
  isCreate = false,
  required = false,
  isOverride = false,
}) {
  return (
    <Field
      className={`${value}`}
      id={`editLimitForm.${limit.uuid}.${value}`}
      name={`${value}`}
      type="text"
      placeholder={limit[value]}
      disabled={!isEditing}
      label={
        <>
          {t(`admin.limits.table.${value}`)} {units && `(${t(`admin.limits.table.${value}Units`)})`}
          {toolTipText !== '' && (
            <TooltipButton
              placement="top"
              variant="link"
              text={toolTipText}
              className="ml-2 no-underline p-0"
            >
              <i className="sila-icon info text-lg" />
            </TooltipButton>
          )}
          {required && (isCreate || isEditing) && (
            <span className="text-primary">{isOverride ? ' **' : ' *'}</span>
          )}
        </>
      }
      component={FieldWrapper}
    />
  );
}

function CustomTimeField({
  limit,
  value,
  isEditing,
  t,
  isCreate = false,
  required = false,
  toolTipText = '',
}) {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(value);
  const [timeString, setTimeString] = useState('');
  const [timeValue, setTimeValue] = useState('');

  const convertToValue = (localDateTime) => {
    const date = new Date(localDateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (field.value) {
      setTimeString(field.value);
      setTimeValue(convertToValue(field.value));
    } else {
      setTimeValue('');
      setTimeString('');
    }
  }, [field.value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setTimeValue(newValue);
    helpers.setValue(newValue);
  };

  return (
    <div className="mb-3">
      <Field
        className={`${value}`}
        id={`editLimitForm.${limit.uuid}.${value}`}
        name={value}
        type={isEditing ? 'datetime-local' : 'text'}
        value={isEditing ? timeValue : timeString}
        onChange={handleChange}
        disabled={!isEditing}
        label={
          <>
            {t(`admin.limits.table.${value}`)}
            {toolTipText !== '' && (
              <TooltipButton
                placement="top"
                variant="link"
                text={toolTipText}
                className="ml-2 no-underline p-0"
              >
                <i className="sila-icon info text-lg" />
              </TooltipButton>
            )}
            {required && (isCreate || isEditing) && <span className="text-primary">{' **'}</span>}
          </>
        }
        component={FieldWrapper}
      />
    </div>
  );
}

export default function LimitForm({
  t,
  history,
  title,
  limit,
  handleDelete,
  onSubmit,
  loaded,
  initialValues,
  uneditedValues,
  isCreate = true,
  isLimitOverride = false,
}) {
  const [isEditing, setIsEditing] = useState(isCreate);
  const [enums, setEnums] = useState({});
  const [enumsLoaded, setEnumsLoaded] = useState(false);

  useEffect(() => {
    setEnumsLoaded(false);
    const fetchEnums = async () => {
      const response = await adminApi.fetchLimitEnums();
      if (response.success) {
        setEnums(response);
        setEnumsLoaded(true);
      }
    };

    fetchEnums();
  }, []);

  const handleUpdate = async (data) => {
    await onSubmit(data);
    if (!isCreate) setIsEditing(false);
  };

  const schema = {
    app_handle: yup
      .string()
      .required(t('common:form.errors.required', { field: t('admin.limits.table.app_handle') })),
    amount: yup
      .number(t('common:form.errors.invalidNumber'))
      .required(t('common:form.errors.required', { field: t('admin.limits.table.amount') }))
      .typeError(t('common:form.errors.invalidNumber'))
      .positive(t('common:form.errors.positiveField', { field: t('admin.limits.table.amount') }))
      .max(
        MAX_LIMIT_AMOUNT,
        t('common:form.errors.maxField', {
          field: t('admin.limits.table.amount'),
          max: MAX_LIMIT_AMOUNT,
          units: t('admin.limits.table.amountUnits'),
        })
      )
      .test(
        'is-decimal',
        t('common:form.errors.twoDecimal', { field: t('admin.limits.table.amount') }),
        (value) => {
          if (value != null) {
            return /^\d+(\.\d{1,2})?$/.test(value);
          }
          return true;
        }
      ),
    duration: yup
      .number(t('common:form.errors.invalidNumber'))
      .positive(t('common:form.errors.positiveField', { field: t('admin.limits.table.duration') }))
      .required(t('common:form.errors.required', { field: t('admin.limits.table.duration') }))
      .max(
        MAX_LIMIT_DURATION,
        t('common:form.errors.maxField', {
          field: t('admin.limits.table.duration'),
          max: MAX_LIMIT_DURATION,
          units: t('admin.limits.table.durationUnits'),
        })
      ),
    limit_type: yup
      .string()
      .required(t('common:form.errors.required', { field: t('admin.limits.table.limit_type') })),
    provider_name: yup.string().nullable(),
    transaction_graph_label: yup.string().nullable(),
    user_category: yup.string().nullable(),
    begins_at: yup
      .date()
      .nullable()
      .max(
        new Date(new Date().getTime() + MAX_LIMIT_BEGINS_AT_DELTA_DAYS * 24 * 60 * 60 * 1000),
        t('common:form.errors.withinNDays', { n: MAX_LIMIT_BEGINS_AT_DELTA_DAYS })
      )
      .test('is-future-date', t('common:form.errors.futureDate'), (value) => {
        const originalValue = initialValues.begins_at;
        if (new Date(value).getTime() === new Date(originalValue).getTime()) {
          return true;
        }
        const bufferTime = new Date(new Date().getTime() - 15000);
        if (!value || value >= bufferTime) {
          return true;
        }
        return false;
      }),
  };

  if (isCreate) {
    schema.name = yup
      .string()
      .required(t('common:form.errors.required', { field: t('admin.limits.table.name') }));
  }

  let validationSchema;
  if (isLimitOverride) {
    schema.expires_at = yup
      .date()
      .nullable()
      .max(
        new Date(new Date().getTime() + MAX_LIMIT_EXPIRES_AT_DELTA_DAYS * 24 * 60 * 60 * 1000),
        t('common:form.errors.withinNDays', { n: MAX_LIMIT_EXPIRES_AT_DELTA_DAYS })
      )
      .test('is-future-date', t('common:form.errors.futureDate'), (value) => {
        const originalValue = initialValues.expires_at;

        if (new Date(value).getTime() === new Date(originalValue).getTime()) {
          return true;
        }

        const bufferTime = new Date(new Date().getTime() - 15000);
        if (!value || value >= bufferTime) {
          return true;
        }
        return false;
      });

    schema.expires_after_count = yup
      .number()
      .nullable()
      .max(
        MAX_LIMIT_EXPIRES_AFTER_COUNT,
        t('common:form.errors.maxField', {
          field: t('admin.limits.table.expires_after_count'),
          max: MAX_LIMIT_EXPIRES_AFTER_COUNT,
          units: t('admin.limits.table.expires_after_count_units'),
        })
      );

    validationSchema = yup.object().shape(schema);
  } else {
    validationSchema = yup.object().shape(schema);
  }

  const validate = (values) => {
    const errors = {};
    if (isLimitOverride) {
      if (!values.expires_at && !values.expires_after_count) {
        errors.expires_at = t('admin.limits.createOverride.error');
        errors.expires_after_count = t('admin.limits.createOverride.error');
      }
    }

    if (
      values.begins_at &&
      values.expires_at &&
      new Date(values.expires_at).getTime() < new Date(values.begins_at).getTime()
    ) {
      errors.expires_at = t('common:form.errors.startOrder', {
        firstField: t('admin.limits.table.expires_at'),
        secondField: t('admin.limits.table.begins_at'),
        preposition: 'after',
      });
      errors.begins_at = t('common:form.errors.startOrder', {
        firstField: t('admin.limits.table.begins_at'),
        secondField: t('admin.limits.table.expires_at'),
        preposition: 'before',
      });
    }
    return errors;
  };

  const formContents = (
    <Container>
      <Row style={{ height: '2vh' }} className="justify-content-end">
        {(isCreate || isEditing) && (
          <p className="text-primary text-end">{t('applications.detail.form.messages.asterisk')}</p>
        )}
      </Row>
      {isCreate && (
        <Row>
          <Col md={12} className="mb-3">
            <CustomTextField
              limit={initialValues}
              value="name"
              isEditing={isEditing}
              t={t}
              required
            />
          </Col>
        </Row>
      )}
      <Row className="align-items-end">
        <Col md={5} className="mb-3">
          <CustomTextField
            limit={initialValues}
            value="app_handle"
            isEditing={isEditing}
            t={t}
            required
            isCreate={isCreate}
          />
        </Col>
        <Col md={3} className="mb-3">
          <CustomTextField
            limit={initialValues}
            value="amount"
            isEditing={isEditing}
            t={t}
            required
            isCreate={isCreate}
            units
            toolTipText={t('admin.limits.table.amountTooltip')}
          />
        </Col>
        <Col md={4} className="mb-3">
          <CustomTextField
            limit={initialValues}
            value="duration"
            isEditing={isEditing}
            t={t}
            required
            isCreate={isCreate}
            toolTipText={t('admin.limits.table.durationTooltip')}
            units
          />
        </Col>
        <Col md={4} className="mb-3">
          <CustomSelectField
            key="limit_type"
            limit={initialValues}
            value="limit_type"
            options={enums ? enums.limitType : []}
            isEditing={isEditing}
            t={t}
            required
            isCreate={isCreate}
          />
        </Col>
        <Col md={4} className="mb-3">
          <CustomSelectField
            key="provider_name"
            limit={initialValues}
            value="provider_name"
            options={enums && enums.providerNames ? [...enums.providerNames, null] : []}
            isEditing={isEditing}
            t={t}
          />
        </Col>
        <Col md={4} className="mb-3">
          <CustomSelectField
            key="user_category"
            limit={initialValues}
            value="user_category"
            options={enums && enums.userCategory ? [...enums.userCategory, null] : []}
            isEditing={isEditing}
            t={t}
          />
        </Col>
        <Col md={6}>
          <CustomMultipleOptionsField
            toolTipText={t('admin.limits.table.transaction_graph_labels_tooltip')}
            key="transaction_graph_label"
            limit={initialValues}
            value="transaction_graph_label"
            options={
              enums && enums.transactionGraphLabel ? [...enums.transactionGraphLabel, null] : []
            }
            isEditing={isEditing}
            t={t}
          />
        </Col>
        <Col md={6}>
          <CustomTimeField
            limit={limit}
            value="begins_at"
            isEditing={isEditing}
            t={t}
            isCreate={isCreate}
          />
        </Col>
      </Row>
      <Row style={{ height: '31px' }}>
        {isLimitOverride && isEditing && (
          <Col md={12} className="text-primary text-right">
            {t('applications.detail.form.messages.atLeastOneRequired')}
          </Col>
        )}
      </Row>
      <Row>
        {isLimitOverride && (
          <Col md={6}>
            <CustomTextField
              limit={initialValues}
              value="expires_after_count"
              isEditing={isEditing}
              t={t}
              isOverride
              required
              isCreate={isCreate}
              toolTipText={t('admin.limits.table.expires_after_count_tooltip')}
            />
          </Col>
        )}
        {isLimitOverride && (
          <Col md={6}>
            <CustomTimeField
              limit={limit}
              value="expires_at"
              isEditing={isEditing}
              t={t}
              isCreate={isCreate}
              toolTipText={t('admin.limits.table.expires_at_tooltip')}
              required
            />
          </Col>
        )}
      </Row>
    </Container>
  );

  return (
    <Container className="limit main-content-container p-3 p-md-5 d-inline-block">
      <Row noGutters className="page-header mb-3 mb-md-5 loaded">
        <PageTitle title={title}>
          <Button
            variant="outline-light"
            className="back text-info"
            onClick={() => history.goBack()}
          >
            {t('common:buttons.back')}
          </Button>
        </PageTitle>
      </Row>

      {!loaded && <Loader label={t('common:status.loading')} />}
      {loaded && enumsLoaded && initialValues && (
        <Card className="loaded flex-grow-1">
          <Card.Header className="d-block d-sm-flex justify-content-between align-content-stretch p-4">
            {isLimitOverride && (
              <h3 className="m-0">
                {isCreate ? t('admin.limits.createOverride.header') : limit.name}
              </h3>
            )}
            {!isLimitOverride && (
              <h3 className="m-0">
                {isCreate ? t('admin.limits.createLimit.header') : limit.name}
              </h3>
            )}
          </Card.Header>
          <Card.Body className="p-4 d-flex align-content-stretch flex-column">
            <ViewToEditForm
              isCreate={isCreate}
              initialValues={initialValues}
              formValidationSchema={validationSchema}
              confirm={{}}
              formContents={formContents}
              t={t}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              canUpdateItem
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              uneditedValues={uneditedValues}
              deleteMessage={t('common:form.messages.confirm.delete', {
                name: limit.name ? limit.name : '',
              })}
              validate={isLimitOverride ? validate : () => ({})}
            />
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
