import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Card } from 'react-bootstrap';

import ConfirmModal from '../../components/common/ConfirmModal';
import DocumentPreviewModal from '../../components/common/DocumentPreviewModal';
import Loader from '../../components/common/Loader';
import PageTitle from '../../components/common/PageTitle';

import { teamApi } from '../../api';

const Statement = ({ t, match, history, user, setAlert }) => {
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [confirm, setConfirm] = useState({ show: false, type: '', data: undefined });
  const [preview, setPreview] = useState({ show: false, data: undefined });

  const getStatement = async (isDownload = false) => {
    try {
      const [file, response] = await Promise.all([
        teamApi.getStatement(match.params.id, user.currentTeam.id, true),
        teamApi.getStatement(match.params.id, user.currentTeam.id, false)
      ]);
      let blob = new Blob([file], { type: 'application/pdf' });
      if (isDownload) {
        let a = window.document.createElement("a");
        window.document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = initialValues.file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
      } else {
        setPreview({ show: true, data: { file: URL.createObjectURL(blob), file_name: '', file_type: 'pdf' } });
        setInitialValues(response.statement_info);
      }
      setIsLoading(false);
    } catch (error) {
      setRedirect(true);
      setAlert({ message: error, variant: 'danger' });
    }
  }

  const handleDownload = () => {
    setIsDownloading(true);
    getStatement(true);
  }

  const handleResend = async (value) => {
    setConfirm(false);
    if (value) {
      if (value.type === 'resend') {
        try {
          setIsLoading(true);
          await teamApi.reSendStatement(match.params.id, user.currentTeam.id);
          setAlert({ message: t('settings.statements.resendMessage'), variant: 'success' });
        } catch (error) {
          setAlert({ message: error, variant: 'warning' });
        } finally {
          setIsLoading(false);
        }
      } else {
        history.push(`/settings/end_user/${value.data.user_handle}/profile`);
      }
    }
  };

  useEffect(() => {
    if (redirect) history.push('/settings/statements');
  }, [redirect]);

  useEffect(() => {
    getStatement();
  }, []);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('common:transactions.statements.detail.title')}>
          <Button variant="outline-light" className="back text-meta" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {isLoading ? <Loader /> : <Card className="loaded d-flex justify-content-center flex-grow-1">
        <Card.Header className="d-flex justify-content-between p-4 w-100">
          <h2 className="m-0">#{initialValues.identifier || ''}</h2>
          {!isLoading && <div className="d-flex align-items-center loaded">
            <Button disabled={isDownloading} variant="link" className="text-muted" size="sm" type="button" onClick={handleDownload}><i className="text-lg fas fa-download text-muted mr-2"></i>{isDownloading ? <>{t('common:status.downloading')}<i className="fas fa-sync-alt spin ml-2"></i></> : t('common:buttons.downloadPDF')}</Button>
            {!initialValues.delivery_status || initialValues.delivery_status && initialValues.delivery_status.toLowerCase() !== 'success' && <>
              <Button variant="primary" size="sm" type="button" className="ml-3" onClick={(e) => setConfirm({ ...confirm, show: true, type: !initialValues.email ? 'noEmail' : initialValues.is_email_update ? 'resend' : 'invalid', data: { ...confirm.data, name: initialValues.user_name, user_handle: initialValues.user_handle, email: initialValues.email } })}>{t(initialValues.delivery_status && initialValues.delivery_status.toLowerCase() === 'unsent' ? 'common:buttons.send' : 'common:transactions.statements.labels.resend')}</Button></>}
          </div>}
        </Card.Header>

        <Card.Body className="d-flex flex-column flex-grow-1 p-0">
          <DocumentPreviewModal modal={false} show={preview.show} data={preview.data} onHide={() => setPreview({ show: false, data: undefined })} />
        </Card.Body>
      </Card>}

      {confirm.show && <ConfirmModal
        show={confirm.show}
        isHide={true}
        title={t(`common:transactions.statements.modal.${confirm.type}.title`)}
        renderMessageComponent={() => <>
          <p className="text-lg mb-4 text-muted">{t(`common:transactions.statements.modal.${confirm.type}.description`, { name: confirm.data.name, email: confirm.data.email })}</p>
        </>}
        buttonLabel={t(`common:buttons.${confirm.type === 'resend' ? 'sendEmail' : 'updateEmail'}`)}
        data={confirm}
        onHide={handleResend} />}
    </Container>
  );
};

export default Statement;