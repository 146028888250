import { SUPERUSER_LEVEL } from '.';
import { hasSuperuserAccess } from '../utils';

export default [
  {
    name: 'admin.headers.applications',
    items: [
      {
        title: 'admin.applications.title',
        description: 'admin.applications.description',
        to: '/apps',
      },
    ],
    permissions: (user) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]),
  },
  {
    name: 'admin.headers.consoleUsers',
    items: [
      {
        title: 'admin.consoleUsers.title',
        description: 'admin.consoleUsers.description',
        to: '/console_users',
      },
    ],
    permissions: (user) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]),
  },
  {
    name: 'admin.headers.endUsers',
    items: [
      {
        title: 'admin.endUsers.title',
        description: 'admin.endUsers.description',
        to: '/end_users',
      },
    ],
    permissions: (user) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]),
  },
  {
    name: 'admin.headers.limits',
    items: [
      {
        title: 'admin.limits.titlePlural',
        description: 'admin.limits.description',
        to: '/limits',
      },
      {
        title: 'admin.limits.titleOverridePlural',
        description: 'admin.limits.descriptionOverrides',
        to: '/limit_overrides',
      },
    ],
    permissions: (user) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]),
  },
  {
    name: 'admin.headers.salesTiers',
    items: [
      {
        title: 'admin.salesTiers.title',
        description: 'admin.salesTiers.description',
        to: '/sales_tiers',
      },
    ],
    permissions: (user) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]),
  },
  {
    name: 'admin.headers.customers',
    items: [
      {
        title: 'admin.customers.onboarding.title',
        description: 'admin.customers.onboarding.description',
        to: '/customers/onboarding',
      },
      {
        title: 'admin.customers.checklist.title',
        description: 'admin.customers.checklist.description',
        to: '/customers/checklists',
      },
      {
        title: 'admin.customers.owners.title',
        description: 'admin.customers.owners.description',
        to: '/customers/owners',
      },
    ],
    permissions: (user) =>
      hasSuperuserAccess(user, [
        SUPERUSER_LEVEL.FULL_ACCESS,
        SUPERUSER_LEVEL.ONBOARDING_READ_ONLY,
        SUPERUSER_LEVEL.ONBOARDING_READ_WRITE,
      ]),
  },
  {
    name: 'admin.headers.transactions',
    items: [
      {
        title: 'admin.transactions.title',
        description: 'admin.transactions.description',
        to: '/transactions',
      },
      {
        title: 'admin.requestsReturn.title',
        description: 'admin.requestsReturn.description',
        to: '/requests-return',
      },
    ],
    permissions: (user) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]),
  },
];
