import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Button, Popover } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

const TooltipButton = ({ placement, children, trigger, show, defaultShow, text, popoverProps, rootClose, id, ...rest }) => (
  <OverlayTrigger
    defaultShow={defaultShow}
    show={show}
    placement={placement || 'auto'}
    trigger={trigger}
    rootClose={rootClose || false}
    overlay={<Popover {...popoverProps}><Popover.Content>{text}</Popover.Content></Popover>}
  >
    <Button id={id || uuid()} {...rest}>{children}</Button>
  </OverlayTrigger>
);

TooltipButton.propTypes = {
  /**
   * The text of the tooltip.
   */
  text: PropTypes.any.isRequired,
  /**
   * The placement of the tooltip.
   */
  placement: PropTypes.string
};

export default TooltipButton;