
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Transition from 'react-transition-group/Transition';

import { TRANSITION_KEYS, TRANSITION_CLASS_MAP } from '../../constants';
import { reflow, getNodeHeight } from '../../utils';

const Collapse = (props) => {
  const [height, setHeight] = useState(null);
  const {
    as: Element,
    show,
    className,
    navbar,
    children,
    innerRef,
    ...attrs
  } = props;

  const transitionProps = { ...TRANSITION_KEYS.reduce((r, key) => ({ ...r, [key]: attrs[key] }), {}) }
  const childProps = TRANSITION_KEYS.reduce((r, key) => (delete r[key], r), { ...attrs });

  const onEntering = (node, isAppearing) => {
    setHeight(getNodeHeight(node));
    props.onEntering(node, isAppearing);
  }

  const onEntered = (node, isAppearing) => {
    setHeight(null);
    props.onEntered(node, isAppearing);
  }

  const onExit = (node) => {
    setHeight(getNodeHeight(node));
    props.onExit(node);
  }

  const onExiting = (node) => {
    reflow(node);
    setHeight(0);
    props.onExiting(node);
  }

  const onExited = (node) => {
    setHeight(null);
    props.onExited(node);
  }

  return (
    <Transition
      {...transitionProps}
      in={show}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      {status => {
        const style = {
          height: height || null,
          display: status !== 'exited' && 'block'
        };

        const classes = classNames(
          className,
          TRANSITION_CLASS_MAP[status] || 'collapse',
          navbar && 'navbar-collapse'
        );

        return (
          <Element
            {...childProps}
            style={{ ...childProps.style, ...style }}
            className={classes}
            ref={innerRef}
          >
            {children}
          </Element>
        );
      }}
    </Transition>
  );
}

Collapse.propTypes = {
  ...Transition.propTypes,
  /**
   * Whether it is show, or not.
   */
  show: PropTypes.bool,
  /**
   * The children components.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /**
   * The element as type.
   */
  as: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The class name.
   */
  className: PropTypes.node,
  /**
   * Whether it is located inside a navbar, or not.
   */
  navbar: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ])
};

Collapse.defaultProps = {
  ...Transition.defaultProps,
  show: false,
  appear: false,
  enter: true,
  exit: true,
  as: 'div',
  timeout: 350
};

export default Collapse;