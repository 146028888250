import React, { useState } from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { teamApi } from '../../api';

import { hasPermissions } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import RequestsReturnList from '../../components/common/transactions/RequestsReturnList';
import ConfirmModal from '../../components/common/ConfirmModal';

const RequestsReturn = ({ t, history, location, user }) => {
  const [show, setShow] = useState(false);

  const handleAddNew = (value) => {
    if(value) {
      window.location.href = '/settings/transactions?source_payment_instrument_type=virtual_account&destination_payment_instrument_type=virtual_account';
    }
    setShow(false);
  };

  return (
    <Container className="main-content-container settings-requests-return p-3 p-md-5 d-flex flex-column flex-grow-1 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('settings.requestsForReturn.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasPermissions(user, 'transactions', 'Read') && user && (user.role === 1 || user.role === 2) ?
        <RequestsReturnList
          customer={true}
          filterName={`team_${user.currentTeam.id}_requests_return`}
          hasPermissions={({ user }) => hasPermissions(user, 'transactions', 'Read')}
          fetchData={teamApi.fetchRequestsReturn}
          fetchOptions={{
            team: user.currentTeam.id
          }}
          onRowClick={(row) => history.push(`/settings/request-return/${row.uuid || row.original.uuid}/detail`)}
          onAddNewClick={() => setShow(true)} /> :
        <div className="position-relative"><DisabledOverlay /></div>}

      <ConfirmModal
        show={show}
        isHide={true}
        title={t('common:transactions.RequestsForReturn.confirm.title')}
        renderMessageComponent={() => <>
          <p className="text-lg mb-4">{t('common:transactions.RequestsForReturn.confirm.description')}</p>
        </>}
        buttonLabel={t('common:buttons.go')}
        data={{}}
        onHide={handleAddNew} />

    </Container>
  )
};

export default RequestsReturn;