export default [
  {
    title: 'register.features.ach',
    // href: 'https://www.silamoney.com/ach-api'
  },
  {
    title: 'register.features.wallet'
    // href: 'https://www.silamoney.com/wallet-api'
  },
  {
    title: 'register.features.bank'
    // href: 'https://www.silamoney.com/bank-account-linking'
  },
  {
    title: 'register.features.kycKyb'
    // href: 'https://www.silamoney.com/kyc-api'
  }
];