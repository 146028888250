import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { adminApi } from '../../api';
import { useAlerts } from '../context';

import Loader from '../common/Loader';
import ConfirmModal from '../common/ConfirmModal';
import OnboardingChecklistTasks from './OnboardingChecklistTasks';

const OnboardingChecklistForm = ({ match, hasWriteAccess }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef(null);
  const addChecklistLinkRef = useRef(null);

  const [page, setPage] = useState(match.params.checklist);
  const [initialValues, setInitialValues] = useState({ checklists: [] });
  const [owners, setOwners] = useState([]);
  const [templates, setTemplates] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentChecklist, setCurrentChecklist] = useState({ delete: false, data: undefined });
  const [pagination, setPagination] = useState();

  const onAdd = (arrayHelpers) => {
    const id = uuid();
    history.push(`/admin/customers/onboarding/${match.params.id}/checklists/${id}`);
    arrayHelpers.push({
      new: true,
      id: id,
      name: '',
      tasks: []
    });
  };

  const onCreate = async (template) => {
    if (!hasWriteAccess) return;
    setLoaded(false);
    try {
      const response = await adminApi.createCustomerChecklistInstance(match.params.id, template);
      setAlert({ message: t('admin.customers.onboarding.checklist.form.messages.checklist.created', { name: template.name }), variant: 'success' });
      history.push(`/admin/customers/onboarding/${match.params.id}/checklists/${response.checklist.id}`);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      loadData();
    }
  };

  const onRemove = async (values) => {
    if (hasWriteAccess && values) {
      const { index, count, checklist: { id, name }, arrayHelpers } = values;
      try {
        await adminApi.deleteCustomerChecklistInstance(id);
        arrayHelpers.remove(index);
        setAlert({ message: t('admin.customers.onboarding.checklist.form.messages.checklist.deleted', { name }), variant: 'success' });
        if (id == page && count >= 2) history.push(`/admin/customers/onboarding/${match.params.id}/checklists/${initialValues.checklists[0].id}`);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentChecklist({ ...currentChecklist, delete: false, data: undefined });
      }
    } else {
      setCurrentChecklist({ ...currentChecklist, delete: false, data: undefined });
    }
  };

  const loadData = async () => {
    try {
      const [templatesResponse, ownersResponse, checklistsResponse] = await Promise.all([
        adminApi.fetchCustomerChecklistTemplates({ limit: 100 }),
        adminApi.fetchCustomerChecklistOwners({ limit: 100 }),
        adminApi.fetchCustomerChecklists(match.params.id, { limit: 100 })
      ]);;
      if (templatesResponse.success && ownersResponse.success && checklistsResponse.success) {
        setPagination(templatesResponse.pagination);
        setTemplates(templatesResponse.checklist_templates);
        setOwners(ownersResponse.owners);
        setInitialValues({
          checklists: checklistsResponse.checklist_instances.map(checklist => ({
            ...checklist,
            tasks: checklist.tasks.map(task => ({
              ...task,
              owner_login: task.assigned !== null ? task.owner_login : '',
              owner_login_handle: task.assigned !== null ? task.owner_login_handle : '',
            }))
          }))
        });
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    setPage(match.params.checklist);
  }, [match])

  useEffect(() => {
    loadData();
  }, []);

  return (<>
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={yup.object().shape({
        checklists: yup.array().of(yup.object().shape({
          tasks: yup.array().of(yup.object().shape({
            name: yup.string().nullable()
              .max(254, t('common:form.errors.max', { count: 254 }))
              .required(t('common:form.errors.required', {
                field: t('admin.customers.onboarding.checklist.form.fields.name.label')
              })),
            link: yup.string().nullable().trim()
              .url(t('common:form.fields.url.error')),
          }))
        }))
      })}>
      {({ values, isSubmitting }) => {

        useEffect(() => {
          if (!location.search)
            if (match.params.checklist && values.checklists.some(checklist => checklist.id == match.params.checklist)) {
              history.push(`/admin/customers/onboarding/${match.params.id}/checklists/${match.params.checklist}`);
            } else if (values.checklists.length) {
              history.push(`/admin/customers/onboarding/${match.params.id}/checklists/${values.checklists[0].id}`);
            } else if (addChecklistLinkRef.current) {
              addChecklistLinkRef.current.click();
            }
        }, [addChecklistLinkRef, values.checklists, loaded]);

        return (<>

          {(isSubmitting || !loaded) && <>
            <h4 className="mb-4">{t('admin.customers.onboarding.checklist.title')}</h4>
            <div className="position-relative"><Loader /></div>
          </>}

          {loaded && <FieldArray
            name="checklists"
            render={arrayHelpers =>
              <Tab.Container activeKey={page} defaultActiveKey={page}>
                <Card className="border loaded flex-grow-1" style={{ zIndex: 2 }}>
                  <Card.Header className="p-0 border-bottom-0">
                    <Nav className="nav-tabs loaded bg-info rounded-top">
                      {values.checklists.map((checklist, index) => <Nav.Item key={checklist.id}>
                        <Nav.Link className={classNames('px-4 py-3 rounded-tr-0', index === 0 && 'rounded-tl')} eventKey={checklist.id} as={NavLink} to={{ pathname: `/admin/customers/onboarding/${match.params.id}/checklists/${checklist.id}`, state: { from: location.pathname } }}>
                          {checklist.new && !checklist.name ? t('admin.customers.onboarding.checklist.labels.new') : checklist.name}
                          {hasWriteAccess && ((checklist.new && values.checklists.length >= 2) || values.checklists.filter(checklist => !checklist.new).length !== 0) && <Button variant="outline-secondary" className="btn-circle-tiny ml-2 border-0 text-md position-relative p-0" style={{ top: '-2px' }} onClick={() => checklist.new ? arrayHelpers.remove(index) : setCurrentChecklist({ ...currentChecklist, delete: true, data: { checklist, count: values.checklists.length, index, arrayHelpers } })}><i className="fas fa-times" style={{ top: 1 }}></i></Button>}
                        </Nav.Link>
                      </Nav.Item>)}
                      {hasWriteAccess && loaded && values.checklists.filter(checklist => checklist.new).length === 0 && <Nav.Item>
                        <Nav.Link className="px-4 py-3 text-info-link rounded-0" ref={addChecklistLinkRef} onClick={() => onAdd(arrayHelpers)}><i className="fas fa-plus"></i></Nav.Link>
                      </Nav.Item>}
                    </Nav>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Tab.Content>
                      {values.checklists.map((checklist, index) => <Tab.Pane key={checklist.id} eventKey={checklist.id}>
                        <FieldArray
                          name={`checklists[${index}].tasks`}
                          render={arrayHelpers => <OnboardingChecklistTasks
                            templates={templates}
                            setTemplates={setTemplates}
                            setPagination={setPagination}
                            pagination={pagination}
                            page={page}
                            name={`checklists[${index}].tasks`}
                            handleCreate={onCreate}
                            handleAdd={arrayHelpers.insert}
                            handleRemove={arrayHelpers.remove}
                            loadData={loadData}
                            match={match}
                            owners={owners}
                            hasWriteAccess={hasWriteAccess} />} />
                      </Tab.Pane>)}
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </Tab.Container>}
          />}

        </>);
      }}
    </Formik>

    {currentChecklist.delete && <ConfirmModal
      show={currentChecklist.delete}
      data={currentChecklist.data}
      title={t('admin.customers.onboarding.checklist.delete.checklist.title')}
      renderMessageComponent={() => <>
        <p className="text-lg mb-4">{t('admin.customers.onboarding.checklist.delete.checklist.description', { name: currentChecklist.data.checklist.new && !currentChecklist.data.checklist.name ? t('admin.customers.onboarding.checklist.labels.new') : currentChecklist.data.checklist.name })}</p>
        <p className="text-info">{t('admin.customers.onboarding.checklist.delete.checklist.note')}</p>
      </>}
      buttonLabel={t('common:buttons.delete')}
      buttonVariant="warning"
      onHide={onRemove} />}

  </>
  );
};

OnboardingChecklistForm.propTypes = {
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired,
  /**
   * The write access boolean
   */
   hasWriteAccess: PropTypes.bool.isRequired
};

export default OnboardingChecklistForm;