import React from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { adminApi } from '../../api';
import { hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import TransactionList from '../../components/common/transactions/TransactionList';

const Transactions = ({ t, history, user }) => (
  <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

    <Row noGutters className="page-header mb-3 mb-md-5">
      <PageTitle title={t('admin.transactions.title')}>
        <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
      </PageTitle>
    </Row>

    {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]) ?
      <TransactionList
        filterName="transactions"
        hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])}
        fetchData={adminApi.fetchTransactions}
        onRowClick={(row) => history.push(`/admin/transaction/${row.uuid || row.original.transaction_uuid}/detail`)} />
      :
      <div className="position-relative"><DisabledOverlay /></div>}

  </Container>
);

export default Transactions;
