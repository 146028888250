/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SelectWrapper(props) {
  const {
    label,
    hasError,
    children,
    inputRef,
    field: { onBlur, onChange, onFocus, value, ...fieldAttrs },
    fieldClass,
    labelClass,
    focused,
    handleBlur,
    handleFocus,
    handleChange,
    options,
    append,
    appendClass,
    prepend,
    prependClass,
    readOnly,
    autofocus,
    placeholder,
    disabled,
    defaultValue,
    ...attrs
  } = props;
  const { t } = useTranslation();
  const fieldClasses = classNames(!value && 'no-value', fieldClass && fieldClass);
  const prependClasses = classNames('d-flex align-items-center', prependClass && prependClass);
  const appendClasses = classNames('d-flex align-items-center', appendClass && appendClass);

  const onHandleChange = (e) => {
    onChange(e);
    if (handleChange) handleChange(e);
  };

  const onHandleBlur = (e) => {
    onBlur(e);
    if (handleBlur) handleBlur(e);
  };

  const onHandleFocus = (e) => {
    if (onFocus) onFocus(e);
    if (handleFocus) handleFocus(e);
  };

  return (
    <>
      {autofocus && label && value && (
        <Form.Label className={classNames('sr-only', labelClass && labelClass)} aria-hidden="true">
          {label}
        </Form.Label>
      )}
      {!autofocus && label && (
        <Form.Label className={classNames('mb-2', labelClass && labelClass)}>{label}</Form.Label>
      )}
      <div className="position-relative">
        {autofocus && (
          <Form.Label
            className={classNames((value || focused) && 'shrink', labelClass && labelClass)}
          >
            {placeholder || label || t('common:form.fields.select.placeholder')}
          </Form.Label>
        )}
        <InputGroup className="h-100 flex-nowrap">
          {prepend && <InputGroup.Prepend className={prependClasses}>{prepend}</InputGroup.Prepend>}
          <Form.Control
            as="select"
            className={fieldClasses}
            isInvalid={hasError}
            ref={inputRef}
            disabled={readOnly || disabled}
            onBlur={onHandleBlur}
            onFocus={onHandleFocus}
            onChange={onHandleChange}
            defaultValue={defaultValue}
            value={!defaultValue && (value === null || value === undefined ? '' : value)}
            {...fieldAttrs}
            {...attrs}
          >
            {!value && <option aria-label="Select from Dropdown" default value="" />}
            {options.length > 0 &&
              options.map((option, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <option key={index} value={option.value} disabled={option.disabled}>
                  {option.label}
                </option>
              ))}
          </Form.Control>
          {append && <InputGroup.Append className={appendClasses}>{append}</InputGroup.Append>}
        </InputGroup>
        {children}
      </div>
    </>
  );
}

SelectWrapper.propTypes = {
  /**
   * field is required and must be an object
   */
  field: PropTypes.object.isRequired,
};

export default SelectWrapper;
