import React from 'react';
import PropTypes from 'prop-types';

import { useApp } from '../../context';

const NavbarToggle = ({ full }) => {
  const { app, updateApp } = useApp();

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    updateApp({ menuVisible: !app.menuVisible });
  };

  return (
    <nav className="nav ml-auto">
      <a href="#" onClick={handleToggleSidebar} className={`nav-link nav-link-icon toggle-sidebar text-center${!full ? ' d-sm-inline d-md-none d-lg-none' : ''}`}>
        <i className="fas fa-bars"></i>
      </a>
    </nav>
  );
};

NavbarToggle.propTypes = {
  /**
   * The visibility of the sidebar, if full, it will be hidden
   */
  full: PropTypes.bool
};

export default NavbarToggle;
