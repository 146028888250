import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useAuth } from '../context';

import KeyGenForm from './KeyGenForm';
import ClientSecretGenForm from './ClientSecretGenForm';
import LoaderButton from '../common/LoaderButton';

const ApplicationCreateModal = ({ show, onHide, data, isMobile }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();

  const download = () => {
    const fileCopy = [`${t('applications.create.confirm.download.description', { date: new Date().toString() })}\n\n`,
    `${t('common:applications.name')}:  ${data.name}\n`,
    `${t('applications.create.confirm.download.appBrandName')}:  ${data.brand_name}\n`,
    `${t('common:applications.handle')}:  ${data.handle}\n`,
    ];
    if (data.client_id) {
      fileCopy.push(
        `${t('applications.create.confirm.download.appClientId')}: ${data.client_id}\n`,
        `${t('applications.create.confirm.download.appClientSecret')}: ${data.client_secret}\n`
      );
    } else if (data.blockchain_address) {
      fileCopy.push(
        `${t('applications.create.confirm.download.appAddress')}: ${data.blockchain_address}\n`,
        `${t('applications.create.confirm.download.appKey')}: ${data.privateKey}\n`
      );
    }
    const fileName = `${data.handle}_keys.txt`;
    const fileData = new Blob(fileCopy, { type: 'text/plain' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(fileData, fileName);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const fileUrl = URL.createObjectURL(fileData);
      a.href = fileUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(a.href)
      a.remove();
    }
    onHide(data);
  };

  return (
    <Modal id="app-confirm-modal" centered
      backdrop="static"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="app-confirm-modal-title">

      <Modal.Header className="d-block">
        <Modal.Title as="h3" id="app-confirm-modal-title">{t('applications.create.confirm.title')}</Modal.Title>
        <p className="text-primary mb-0 mt-2">{t('applications.create.confirm.description')}</p>
      </Modal.Header>

      <Modal.Body>

      <Formik initialValues={data}>
        <Form noValidate autoComplete="new-password">
            {data.blockchain_address &&
              <KeyGenForm show readOnly wallet={{ blockchain_address: data.blockchain_address, privateKey: data.privateKey }} />}
            {data.client_id &&
              <ClientSecretGenForm readOnly disableGenerate credentials={{ client_id: data.client_id, client_secret: data.client_secret }} />}
        </Form>
      </Formik>

        <div className="d-flex flex-row">
          <i className="sila-icon info text-primary text-lg mr-3"></i>
          <div className="text-sm">
            <p className="text-primary text-uppercase mb-2">{t(`applications.create.confirm.${data.client_id ? 'jwt' : 'ecdsa'}.disclaimer.title`)}</p>
            <p className="text-info mb-0">{t(`applications.create.confirm.${data.client_id ? 'jwt' : 'ecdsa'}.disclaimer.description`)}</p>
          </div>
        </div>

      </Modal.Body>

      <Modal.Footer className="text-right">
        <LoaderButton loading={isSubmitting} onClick={() => {
          setIsSubmitting(true);
          if (!isMobile) {
            download();
          } else {
            onHide(data);
          }
        }} type="submit">{t('applications.create.confirm.buttons.confirm')}</LoaderButton>
      </Modal.Footer>

    </Modal>
  );
};

ApplicationCreateModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired,
  /**
   * The app data object
   */
  data: PropTypes.object.isRequired,
  /**
   * If the current screen width is similar to a mobile device
   */
  isMobile: PropTypes.bool.isRequired
};

export default ApplicationCreateModal;