import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DisabledOverlay = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div className="disabled-overlay position-absolute w-100 h-100 d-flex justify-content-center"><p className="message py-2 px-3 m-0 rounded text-white align-self-center"><i className="fas fa-lock mr-2"></i>{message || t('settings.disabled')}</p></div>
  )
};

DisabledOverlay.propTypes = {
  /**
   * The optional message that is displayed in the overlay.
   */
  message: PropTypes.string
};

export default DisabledOverlay;