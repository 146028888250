import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { adminApi } from '../../api';

import LimitForm from './LimitForm';

export default function Limit({ t, match, history, setAlert, env }) {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [limit, setLimit] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const mapInitialValues = (incomingLimit = limit) => {
    // TODO: rewrite component to work with lists when the API is configured to handle lists
    // of transaction graph labels
    let transactionGraphLabel = incomingLimit.transaction_graph_labels;
    if (incomingLimit.transaction_graph_labels) {
      transactionGraphLabel = incomingLimit.transaction_graph_labels.join(', ');
    }

    return {
      provider_name: incomingLimit.provider_name,
      app_handle: incomingLimit.app_handle,
      transaction_graph_label: transactionGraphLabel,
      user_category: incomingLimit.user_category,
      duration: incomingLimit.duration,
      amount: incomingLimit.amount,
      begins_at: incomingLimit.begins_at,
      limit_type: incomingLimit.limit_type,
    };
  };

  const createLimitValues = {
    name: '',
    provider_name: '',
    app_handle: '',
    transaction_graph_label: '',
    user_category: '',
    duration: '',
    amount: '',
    begins_at: '',
    limit_type: '',
  };

  const getLimit = async (id = match.params.id) => {
    setLoaded(false);
    try {
      const response = await adminApi.fetchLimit(id);
      setLimit(response.limit);
      const newInitialValues = mapInitialValues(response.limit);
      setInitialValues(newInitialValues);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      history.push('/admin/limits');
    }
    setLoaded(true);
  };

  const handleUpdate = async (values) => {
    const changedValues = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === null && values[key] !== initialValues[key]) {
        changedValues[key] = values[key];
      } else if (values[key] === '') {
        changedValues[key] = null;
      } else if (values[key] !== initialValues[key]) {
        changedValues[key] = values[key];
      }
    });

    setLoaded(false);
    try {
      const response = await adminApi.updateLimit(limit.uuid, changedValues);
      setLimit(response.limit);
      const newInitialValues = mapInitialValues(response.limit);
      setAlert({ message: response.message, variant: 'success' });
      setInitialValues(newInitialValues);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
    setLoaded(true);
  };

  const handleCreate = async (values) => {
    setLoaded(false);
    try {
      const response = await adminApi.createLimit(values);
      setLimit(response.limit);
      setAlert({ message: response.message, variant: 'success' });
      const newInitialValues = mapInitialValues(response.limit);
      setInitialValues(newInitialValues);
      history.replace({
        pathname: `/admin/limit/${response.limit.uuid}/detail`,
        state: { from: location.pathname },
      });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setInitialValues(values);
    }
    setLoaded(true);
  };

  const handleDelete = async () => {
    try {
      const response = await adminApi.deleteLimit(limit.uuid);
      setAlert({ message: response.message, variant: 'success' });
      history.goBack();
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (match.params.id !== 'new') {
      getLimit();
    } else {
      setInitialValues(createLimitValues);
      setLoaded(true);
    }
  }, [env]);

  return (
    <>
      {match.params.id !== 'new' && (
        <LimitForm
          t={t}
          history={history}
          title={t('admin.limits.titleSingle')}
          limit={limit}
          handleDelete={handleDelete}
          onSubmit={handleUpdate}
          loaded={loaded}
          initialValues={initialValues}
          uneditedValues={mapInitialValues()}
          isCreate={false}
        />
      )}
      {match.params.id === 'new' && (
        <LimitForm
          t={t}
          history={history}
          title={t('admin.limits.createLimit.title')}
          limit={createLimitValues}
          handleDelete={handleDelete}
          onSubmit={handleCreate}
          loaded={loaded}
          initialValues={initialValues}
          uneditedValues={initialValues}
        />
      )}
    </>
  );
}
