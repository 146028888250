import React, { useState } from 'react';
import { Form, Button, Card, Container } from 'react-bootstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';
import salesApi from '../../api/sales';
import { useAlerts } from '../../components/context';

export default function RoutingNumberChecker() {
  const [results, setResults] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const checkIcon = <i className="fas fa-check" style={{ color: 'green' }} />;

  const timesIcon = <i className="fas fa-times" style={{ color: 'red' }} />;
  const { setAlert } = useAlerts();

  const validationSchema = Yup.object().shape({
    routingNumber: Yup.string()
      .matches(/^\d+$/, 'Routing number must contain only digits')
      .length(9, 'Routing number must be exactly 9 digits')
      .required('Routing number is required'),
  });

  const submitCheckRoutingNumber = async (routingNumber) => {
    setIsSubmitting(true);
    try {
      const response = await salesApi.checkRoutingNumber(routingNumber);
      if (response.status === 'SUCCESS') {
        setResults(response);
      }
    } catch (error) {
      let message =
        'Unable to verify the routing number at this time. Please check the routing number and try again.';
      if (error.message) message = error.message;
      setAlert({ message: message, variant: 'warning' });
    }
    setIsSubmitting(false);
  };

  return (
    <div className="sales-body mt-5">
      <Container className="sales-app-container container bg-white p-4 rounded shadow">
        <h1 className="sales-header text-center">ACHNow Routing Number Instant Payment Checker</h1>
        <Formik
          initialValues={{ routingNumber: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            submitCheckRoutingNumber(values.routingNumber);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="mt-4">
              <div className="form-group">
                <label htmlFor="routingNumber" className="sales-form-label">
                  {/* eslint-disable-line jsx-a11y/label-has-associated-control */}
                  Enter a nine-digit ABA Routing Number
                </label>
                <Form.Control
                  type="text"
                  name="routingNumber"
                  placeholder="Ex: 001234567"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.routingNumber}
                  isInvalid={touched.routingNumber && !!errors.routingNumber}
                />
                <div className="text-danger" style={{ height: '2vh' }}>
                  {touched.routingNumber && errors.routingNumber ? errors.routingNumber : ''}
                </div>
              </div>
              <Button type="submit" disabled={isSubmitting} className="button">
                GO!
              </Button>
            </Form>
          )}
        </Formik>

        {results && (
          <Card className="mt-4">
            <Card.Body>
              <Card.Title className="sales-card-title ">
                Capability Results for Routing Number {results.capabilities.aba_routing_number}
              </Card.Title>
              <Card.Text className="sales-card-text">{results.capabilities.bank_name}</Card.Text>
              <div className="my-4">
                <div className="sales-table-header ">RTP Capable</div>
                <div className="sales-table-row">
                  <span className="sales-icon">
                    {results.capabilities.rtp.credit_enabled === true ? checkIcon : timesIcon}
                  </span>
                  <span>Receive a payment (RTP)</span>
                </div>
                <div className="sales-table-row">
                  <span className="sales-icon">
                    {results.capabilities.rtp.debit_enabled === true ? checkIcon : timesIcon}
                  </span>
                  <span>Receive an RfP (RTP)</span>
                </div>
                <div>
                  <div className="sales-table-header ">FedNow Capable</div>
                  <div className="sales-table-row">
                    <span className="sales-icon">
                      {results.capabilities.fednow.credit_enabled === true ? checkIcon : timesIcon}
                    </span>
                    <span>Receive a payment</span>
                  </div>
                  <div className="sales-table-row">
                    <span className="sales-icon">
                      {results.capabilities.fednow.debit_enabled === true ? checkIcon : timesIcon}
                    </span>
                    <span>Receive an RfP</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
      </Container>
    </div>
  );
}
