import React, { useState } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { hasSuperuserAccess } from '../../../utils';

import { SUPERUSER_LEVEL } from '../../../constants';

import { teamApi, adminApi } from '../../../api';
import { useAlerts, useAuth } from '../../context';

import Loader from '../Loader';
import FreezePaymentMethodModal from './FreezePaymentMethodModal';
import PaymentInstrumentModal from './PaymentInstrumentModal';

function PaymentMethods({ initialValues, getEndUser }) {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const { user } = useAuth();
  const [loaded, setLoaded] = useState(initialValues.payment_instruments.length || false);
  const [showFreezePaymentMethodModal, setShowFreezePaymentMethodModal] = useState(false);
  const [paymentInstrumentDetailsLoaded, setPaymentInstrumentDetailsLoaded] = useState(false);
  const [paymentInstrumentData, setPaymentInstrumentData] = useState(undefined);
  const [name, setName] = useState(undefined);

  // this is required because this component is used for both superuser and non-superuser views
  const isAdmin = () => {
    return (
      hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]) &&
      window.location.href.includes('/admin/')
    );
  };

  const generateFreezePaymentMethodApiCall = (transaction_list, freezeReasonObj) => {
    if (isAdmin()) {
      return () =>
        adminApi.paymentInstrumentFreezeUnfreeze(
          !paymentInstrumentData.frozen,
          paymentInstrumentData.payment_method_id,
          user.currentTeam.id,
          !!transaction_list,
          freezeReasonObj.freeze_reason
        );
    }
    return () =>
      teamApi.paymentInstrumentFreezeUnfreeze(
        !paymentInstrumentData.frozen,
        paymentInstrumentData.payment_method_id,
        user.currentTeam.id,
        !!transaction_list
      );
  };

  const handleSubmitFreezePayment = async (freezeReason, transaction_list = []) => {
    const freezePaymentApiCall = generateFreezePaymentMethodApiCall(transaction_list, freezeReason);

    const response = await freezePaymentApiCall();
    if (response.success) {
      setAlert({ message: response.message, variant: 'success' });
    }

    if (response.status_code === 400) {
      setAlert({
        message: response.message,
        variant: 'danger',
      });
    } else if (response.status_code === 409) {
      setAlert({
        message: response.message,
        variant: 'warning',
      });
    }

    getEndUser();
    setShowFreezePaymentMethodModal(false);
    setPaymentInstrumentData(undefined);
    setLoaded(true);
  };

  const getPaymentInstrument = async (id) => {
    if (id) {
      setLoaded(false);
      try {
        const response = await teamApi.getPaymentInstrument(id, user.currentTeam.id);
        setPaymentInstrumentData(response.payment_method);
        setPaymentInstrumentDetailsLoaded(true);
      } catch (error) {
        setAlert({ message: error, variant: 'danger' }); // TODO:write custom error
        setPaymentInstrumentDetailsLoaded(false);
        setPaymentInstrumentData(undefined);
      } finally {
        setLoaded(true);
      }
    } else {
      setPaymentInstrumentDetailsLoaded(false);
      setPaymentInstrumentData(undefined);
    }
  };

  return (
    <>
      <h3 className="mb-2">{t('common:paymentMethods.title')}</h3>
      <p className="text-meta mb-5">{t('common:paymentMethods.description')}</p>
      <Card className="mb-2 border overflow-hidden">
        {!loaded && <Loader />}
        <Table bordered responsive className="border-0">
          <thead>
            <tr className="bg-secondary">
              <th className="border-right border-light pl-4 py-3">{t('common:labels.name')}</th>
              <th className="border-right border-light px-4 py-3">
                {t('common:paymentMethods.labels.method')}
              </th>
              <th className="border-right border-light px-4 py-3">
                {t('common:paymentMethods.labels.identifier')}
              </th>
              <th className="pr-2 py-3 text-center" width="20%">
                {t('common:labels.action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {initialValues.payment_instruments.map((payment_instrument, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={index}>
                <td className="px-4 py-3">
                  {payment_instrument.payment_name || t('common:labels.noName')}
                </td>
                <td className="px-4 py-3">{payment_instrument.payment_method_type}</td>
                <td className="px-4 py-3">
                  {payment_instrument.identifier || (
                    <span className="text-info">{t('common:labels.na')}</span>
                  )}
                </td>
                <td className="pr-3 py-3 text-center text-nowrap" width="25%">
                  <Button
                    variant="link text-underline"
                    onClick={() => {
                      setPaymentInstrumentData(payment_instrument);
                      setName(payment_instrument.payment_name);
                      setShowFreezePaymentMethodModal(true);
                    }}
                    className={`p-0 ${payment_instrument.frozen ? 'text-danger' : ''}`}
                  >
                    {t(`common:labels.${payment_instrument.frozen ? 'unfreeze' : 'freeze'}`)}
                  </Button>
                  <Button
                    className="p-0 text-lg text-info hover ml-4"
                    variant="link"
                    onClick={() => getPaymentInstrument(payment_instrument.payment_method_id)}
                  >
                    <i className="fas fa-eye" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      {showFreezePaymentMethodModal && (
        <FreezePaymentMethodModal
          show={showFreezePaymentMethodModal}
          data={paymentInstrumentData}
          name={initialValues.full_name || `${initialValues.first_name} ${initialValues.last_name}`}
          handleSubmitFreezePayment={handleSubmitFreezePayment}
          setShowFreezePaymentMethodModal={setShowFreezePaymentMethodModal}
          isAdmin={isAdmin()}
          customerDescription=""
          adminDescription={
            paymentInstrumentData.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.reasonDescription', { name })
              : t('common:freezeUnfreeze.model.freeze.reasonDescription', { name })
          }
        />
      )}
      {paymentInstrumentDetailsLoaded && paymentInstrumentData && (
        <PaymentInstrumentModal
          show={paymentInstrumentDetailsLoaded}
          data={paymentInstrumentData}
          onHide={getPaymentInstrument}
        />
      )}
    </>
  );
}

PaymentMethods.propTypes = {
  /**
   * The values of the form
   */
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
};

export default PaymentMethods;
