import React from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../components/context';

import SilaLogo from '../../../components/common/SilaLogo';

const NavbarGuest = () => {
  const { authFrozen } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const showLogin = !authFrozen && !location.pathname.includes('login') && !location.pathname.includes('register') && !location.pathname.includes('maintenance');
  return (
    <Navbar type="light" className="p-0">
      <Container className="my-auto justify-content-between">
        <Navbar.Brand href="/">
          <SilaLogo id="main-logo" className="d-inline-block align-middle mr-1 logo" />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            {showLogin && <Button as={NavLink} variant="link" size="sm" to="/login">{t('common:buttons.login')}</Button>}
            {authFrozen && <Button as={NavLink} variant="link" size="sm" to="/logout">{t('common:buttons.logout')}</Button>}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarGuest;