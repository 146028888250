import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Badge, Button, Alert } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

const convertSize = (size) => {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const baseStyle = {
  borderWidth: 2,
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  fontSize: '100%',
  whiteSpace: 'normal',
  lineHeight: 'inherit'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const FileUploadWrapper = (props) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');

  const { id, label, disabled, field: { value, ...fieldProps }, options: { onDrop, ...fileProps }, percentage = {} } = props;
  const { t } = useTranslation();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: useCallback((acceptedFiles, fileRejections) => {
      setFiles([...files, ...onDrop(acceptedFiles)]);
      fileRejections.forEach((file) => {
        file.errors.forEach((err, index) => setError(index !== 0 ? `${error}, ${err.message}` : err.message));
      });
    }, [files]),
    ...fileProps
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const removeFile = file => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    onDrop(newFiles);
  };

  useEffect(() => {
    let showTimer;
    if (error) showTimer = setTimeout(() => setError(''), 5000);
    return () => clearTimeout(showTimer);
  }, [error]);

  return (
    <>
      <Badge pill variant="primary" {...getRootProps({ className: 'badge-outline d-flex align-items-center flex-column p-5 rounded-0', style })}>
        <input id={id} {...fieldProps} {...getInputProps()} disabled={disabled || Object.keys(percentage).length} />
        {Object.keys(percentage).length ?
          <ul className="list-unstyled mb-0 text-center w-100 px-5">{Object.keys(percentage).map((file, i) => (
            <li className={`text-info${i !== 0 ? ' mt-5' : ''}`} key={file}>
              {percentage[file] === 100 ?
                <span className="d-flex align-items-center justify-content-between">
                  <span className="d-flex">
                    <i className="fas fa-file-alt mr-3 text-primary" style={{ fontSize: '3rem' }}></i>
                    <span className="d-flex flex-column justify-content-between text-left">
                      <span className="text-lg"><span className="w-100 d-block text-break pr-3 text-left" style={{ maxWidth: '100%' }}>{file}</span></span>
                      <span className="text-nowrap">{convertSize(files.find(acceptedFile => acceptedFile.name === file).size)}</span>
                    </span>
                  </span>
                  <span className="text-lg"><i className="fas fa-check mr-3 text-success"></i>{t('common:upload.form.messages.success')}</span>
                </span>
                : <>
                  <span className="d-flex align-items-center justify-content-between mb-2">
                    <em><span className="w-100 d-block text-break pr-3 text-left" style={{ maxWidth: '100%' }}>{file}</span></em>
                    <em>{percentage[file]}%</em>
                  </span>
                  <span className="d-block w-100 position-relative">
                    <Badge pill variant="outline-light" className="d-block w-100 position-absolute" style={{ height: 12 }}></Badge>
                    <Badge pill variant="primary" className="d-block position-absolute" style={{ height: 12, width: `${percentage[file]}%` }}></Badge>
                  </span>
                </>}
            </li>
          ))}
          </ul> : files.length ?
            <ul className="list-unstyled mb-0 text-center">{files.map((file, i) => (
              <li className={`d-flex align-items-center align-content-center text-info${i !== 0 ? ' mt-3' : ''}`} key={file.name}>
                <span className="text-lg"><span className="w-100 d-block text-break pr-3 text-left" style={{ maxWidth: '100%' }}>{file.name}</span></span>
                <span className="mx-2 text-nowrap">{convertSize(file.size)}</span>
                <Button variant="link" className="p-0 ml-auto" onClick={removeFile(file)}><i className="fas fa-times-circle"></i></Button>
              </li>
            ))}
            </ul> : <>
              <p><i className="fas fa-file-alt text-info" style={{ fontSize: '3rem' }}></i></p>
              <p className="text-lg text-info mb-0"><Trans i18nKey="common:upload.form.fields.files.placeholder">Drag your document here, or <Button variant="link" className="p-0 shadow-none text-underline text-lg mt-n1" onClick={open}> choose a file</Button>.</Trans></p>
            </>}
      </Badge>
      {error && <Alert variant="warning" className="mt-4 loaded">{error}.</Alert>}
    </>
  );
};

FileUploadWrapper.propTypes = {
  /**
   * field is required and must be an object
   */
  field: PropTypes.object.isRequired,
  /**
   * The object of react-dropzone properties
   */
  options: PropTypes.object.isRequired,
  /**
   * The percentage object to store progress integer for each file
   */
  percentage: PropTypes.object
};

export default FileUploadWrapper;