import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { consoleApi, teamApi } from '../../api';
import { formatMd5Name, capitalize } from '../../utils';

import { useAuth, useAlerts, useApp } from '../context';

import { SLACK_EVENT_TRACKING_CHANNEL } from '../../constants';

const InviteModal = ({ show, data }) => {
  const { user, onUpdateUser } = useAuth();
  const { setAlert } = useAlerts();
  const { updateApp } = useApp();
  const { t } = useTranslation();
  const teamName = formatMd5Name(data.team_name, user.company.name);

  const onHide = () => {
    updateApp({ invitation: false });
    if (Cookies.get('sila_console_invitation')) Cookies.remove('sila_console_invitation');
  }

  const getTeams = async (data) => {
    try {
      const response = await teamApi.fetchTeams({ limit: 20 });
      setAlert({ message: t('invite.messages.success', { team: teamName, role: t(`settings.members.labels.roles.${data.role_name}`), env: capitalize(data.env) }), variant: 'success' });
      onUpdateUser({ teams: response.teams });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const sendSlackMessage = async (data) => {
    try {
      await consoleApi.sendSlackMessage({
        channel: SLACK_EVENT_TRACKING_CHANNEL,
        message: t('invite.messages.events.accept', {
          name: `${user.entity.first_name} ${user.entity.surname}`,
          handle: user.entity.handle,
          email: user.entity.emails[0].email,
          role: data.role_name,
          team: teamName,
          env: capitalize(data.env)
        })
      });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const onInviteAccept = async (data) => {
    if (data) {
      try {
        await teamApi.confirmMember({
          ...data, 
          name: `${user.entity.first_name} ${user.entity.surname}`,
          handle: user.entity.handle,
          email: user.entity.emails[0].email
        });
        sendSlackMessage(data);
        getTeams(data);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        onHide();
      }
    }
  };

  return (
    <Modal centered id="invite-modal"
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="invite-modal-title">
      <Modal.Header closeButton>
        <Modal.Title as="h3" id="invite-modal-title">{t('invite.messages.title', { team: teamName })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-lg">{t('invite.messages.confirm', { team: teamName, role: t(`settings.members.labels.roles.${data.role_name}`), env: capitalize(data.env) })}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-light" onClick={() => onHide()}>{t('common:buttons.cancel')}</Button>     
        <Button className="text-uppercase" variant="primary" onClick={() => onInviteAccept(data)}>{t('invite.messages.button')}</Button> 
      </Modal.Footer>
    </Modal>
  )
};

InviteModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired
  ]),
  /**
   * The team data object to identify the team.
   */
  data: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object.isRequired
  ])
};

export default InviteModal;