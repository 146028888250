import React, { createContext, useState, useEffect, useContext } from 'react';

import { authApi } from '../../api';

export const AuthDataContext = createContext(null);

const initialAuthData = {};

const AuthDataProvider = props => {
  const [authData, setAuthData] = useState(initialAuthData);
  const [authFrozen, setAuthFrozen] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);

  const initAuth = (response) => {
    if (response.user.frozen) {
      setAuthFrozen(true);
    } else {
      setAuthData(response);
    }
  };

  const refreshSession = async (team, callback) => {
    try {
      const response = await authApi.refreshSession(team);
      if (response) {
        initAuth(response);
        if (callback) callback(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAuthLoaded(true);
    }
  };

  const onLogout = () => {
    setAuthData(initialAuthData);
    setAuthFrozen(false);
  };

  const onLogin = (response) => {
    if (response) {
      initAuth(response);
      setAuthLoaded(true);
    }
  };

  const onUpdateUser = (response, callback) => {
    setAuthData({...authData, user: { ...authData.user, ...response }});
    if (callback) callback();
  };

  const onUpdateEnv = (env) => {
    setAuthData({...authData, env });
  };


  /* The first time the component is rendered, it tries to
   * fetch the auth data from the sesssion.
   */
  useEffect(() => {
    refreshSession();
  }, []);

  return <AuthDataContext.Provider value={{ 
    ...authData, 
    authFrozen,
    authLoaded, 
    onLogin, 
    onLogout, 
    onUpdateUser, 
    onUpdateEnv, 
    refreshSession
  }} {...props} />;
};

export const useAuth = () => useContext(AuthDataContext);

export default AuthDataProvider;