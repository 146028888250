import React, { useEffect, useState } from 'react';
import { Accordion, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import CheveronLeft from '../../../../../images/cheveron-left.svg';
import CheveronDown from '../../../../../images/cheveron-down.svg';
import ApplicationForm from './ApplicationForm';
import AppCharts from './AppCharts';
import StatementInfoForm from './StatementInfoForm';
import InstantSettlementForm from './InstantSettlementForm';
import ApiAccessKeysForm from './ApiAccessKeysForm';
import ProviderWebhooksForm from './ProviderWebhooksForm';
import {
  AppProfileNavItem,
  AppCardHeader,
  AppCardBody,
  AppProfileDetailColumn,
  AppProfileTabColumn,
} from './AppProfileViewComponents';
import { useAlerts } from '../context';
import { appsApi } from '../../api';
import FreezeModal from '../common/FreezeModal';
import { hasPermissions } from '../../utils';

export default function AppPanels({
  app,
  t,
  onDelete,
  isMobile,
  onSave,
  user,
  history,
  match,
  onFreeze,
  shouldBeOpen = true,
  lastItem = null,
  isAppsView = false,
}) {
  const [header, setHeader] = useState(match.params.action);
  const [showFreezeModal, setShowFreezeModal] = useState(false);
  const { setAlert } = useAlerts();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(shouldBeOpen);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!location.pathname.includes('/apps')) setHeader(match.params.action);
  }, [match]);

  useEffect(() => {
    if (location.pathname.includes('/apps')) setHeader('detail');
  }, []);

  const handleSubmitFreezeApp = async () => {
    const desiredFreezeState = !app.frozen;
    const response = await appsApi.freezeApp(app.id, desiredFreezeState);
    if (response.success) {
      setAlert({
        message: t('applications.detail.form.messages.status', {
          name: app.name,
          status: (desiredFreezeState
            ? t('common:labels.frozen')
            : t('common:labels.unfrozen')
          ).toLowerCase(),
        }),
        variant: 'success',
      });
      onFreeze(app.id, { frozen: desiredFreezeState });
    } else if (!response.success) {
      if (response.status_code === 400) {
        setAlert({
          message: response.message,
          variant: 'danger',
        });
      } else if (response.status_code === 409) {
        setAlert({
          message: response.message,
          variant: 'warning',
        });
        onFreeze(app.id, { frozen: desiredFreezeState });
      }
    }
    setShowFreezeModal(false);
  };

  // values to be displayed in the FeezeModal
  const type = t('common:applications.singular');
  const { name } = app;

  const body = (
    <AppCardBody header={header} setHeader={setHeader}>
      <AppProfileTabColumn>
        <AppProfileNavItem
          type={header}
          appId={app.id}
          t={t}
          setHeader={setHeader}
          route="detail"
        />
        {user.role &&
          (user.role === 1 || user.role === 2) &&
          hasPermissions(user, 'apps', 'Write') && (
            <AppProfileNavItem
              type={header}
              appId={app.id}
              t={t}
              setHeader={setHeader}
              route="statement-info"
            />
          )}
        {hasPermissions(user, 'apps', 'Write') && (
          <AppProfileNavItem
            type={header}
            appId={app.id}
            t={t}
            setHeader={setHeader}
            route="access-keys"
          />
        )}
        {hasPermissions(user, 'apps', 'Write') && (
          <AppProfileNavItem
            type={header}
            appId={app.id}
            t={t}
            setHeader={setHeader}
            route="provider-webhooks"
          />
        )}
        <AppProfileNavItem
          type={header}
          appId={app.id}
          t={t}
          setHeader={setHeader}
          route="metrics"
        />
        {user.role &&
          (user.role === 1 || user.role === 2) &&
          hasPermissions(user, 'apps', 'Write') && (
            <AppProfileNavItem
              type={header}
              appId={app.id}
              t={t}
              route="instant-settlement"
              setHeader={setHeader}
            />
          )}
      </AppProfileTabColumn>
      <AppProfileDetailColumn>
        <Tab.Content className="w-100">
          <Tab.Pane eventKey="detail">
            {isOpen && (
              <ApplicationForm
                isEditing={false}
                onDelete={onDelete}
                initialValues={app}
                isMobile={isMobile}
                onSave={onSave}
                disabled
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="statement-info">
            <p
              className={`text-primary ml-auto mb-0 text-nowrap text-right ${
                isMobile ? 'text-sm' : ''
              }`}
            >
              {t('applications.detail.form.messages.asterisk')}
            </p>
            {isOpen &&
              user.role &&
              (user.role === 1 || user.role === 2) &&
              hasPermissions(user, 'apps', 'Write') && (
                <StatementInfoForm
                  initialValues={app}
                  user={user}
                  isMobile={isMobile}
                  onSave={onSave}
                />
              )}
          </Tab.Pane>
          <Tab.Pane eventKey="access-keys">
            {isOpen && hasPermissions(user, 'apps', 'Write') && (
              <ApiAccessKeysForm
                isMobile={isMobile}
                onSave={() => history.push('/apps')}
                appId={app.id}
              />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="instant-settlement">
            {isOpen && hasPermissions(user, 'apps', 'Write') && (
              <InstantSettlementForm initialValues={app} onSave={onSave} />
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="metrics">{isOpen && <AppCharts appId={app.id} />}</Tab.Pane>
          <Tab.Pane eventKey="provider-webhooks">
            {isOpen && hasPermissions(user, 'apps', 'Write') && (
              <ProviderWebhooksForm appId={app.id} />
            )}
          </Tab.Pane>
        </Tab.Content>
      </AppProfileDetailColumn>
    </AppCardBody>
  );
  const toggle = (
    <Accordion.Toggle as="span" variant="link" eventKey="0" onClick={toggleAccordion}>
      <AppCardHeader setShowFreezeModal={setShowFreezeModal} app={app} t={t} user={user}>
        {isOpen ? (
          <span className="d-block text-center pl-3">
            <img src={CheveronDown} height="24" alt="cheveron down" />
          </span>
        ) : (
          <span className="d-block text-center pl-3">
            <img src={CheveronLeft} height="24" alt="cheveron left" />
          </span>
        )}
      </AppCardHeader>
    </Accordion.Toggle>
  );

  return (
    <>
      {/* If it is a multiple view, render accorian, otherwise do not */}
      {isAppsView ? (
        <Accordion activeKey={isOpen ? '0' : 'null'} ref={lastItem}>
          {toggle}
          <Accordion.Collapse eventKey="0">{body}</Accordion.Collapse>
        </Accordion>
      ) : (
        <>
          <AppCardHeader setShowFreezeModal={setShowFreezeModal} app={app} t={t} user={user} />
          {body}
        </>
      )}

      {showFreezeModal && (
        <FreezeModal
          show={showFreezeModal}
          data={{}}
          handleFreeze={handleSubmitFreezeApp}
          setShow={setShowFreezeModal}
          loaderButtonText={app.frozen ? t('common:buttons.unfreeze') : t('common:buttons.freeze')}
          isAdmin={false}
          adminDescription={
            app.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.reasonDescription', { name })
              : t('common:freezeUnfreeze.model.freeze.reasonDescription', { name })
          }
          customerDescription={
            app.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.confirm', { name })
              : t('common:freezeUnfreeze.model.freeze.confirm', { name })
          }
          title={
            app.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.title', { type })
              : t('common:freezeUnfreeze.model.freeze.title', { type })
          }
        />
      )}
    </>
  );
}
