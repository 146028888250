import React, { useMemo } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDateAndTime, formatNumberColumns } from '../../../utils';
import { RETURN_CODES } from '../../../constants';

import DataList from '../DataList';
import UUIDSearchForm from '../UUIDSearchForm';
import { DateRangeColumnFilter, CheckboxColumnFilter, MultipleFilter, DateBetweenFilter, RangeColumnFilter } from '../table/TableFilters';

const TABLE_SLUG = 'request_reverse_vaccount_transactions';
const TABLE_MAX_COUNT = 20;

const formatFilters = (filters) => {
  let query = filters;
  const date_submitted = filters.find(filter => filter.id === 'date_submitted');
  const last_update = filters.find(filter => filter.id === 'last_update');
  const amount = filters.find(filter => filter.id === 'amount');
  const date_of_transaction = filters.find(filter => filter.id === 'date_of_transaction');
  const return_code = filters.find(filter => filter.id === 'return_code');
  const date_of_customer_contact = filters.find(filter => filter.id === 'date_of_customer_contact');

  if (date_submitted) {
    if (date_submitted.value) {
      query = [...query.filter(filter => filter.id !== 'date_submitted'), { id: 'date_submitted_start', value: date_submitted.value[0]?.slice(0, 10) }, { id: 'date_submitted_end', value: date_submitted.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'date_submitted')];
    }
  }
  if (last_update) {
    if (last_update.value) {
      query = [...query.filter(filter => filter.id !== 'last_update'), { id: 'last_updated_start_date', value: last_update.value[0]?.slice(0, 10) }, { id: 'last_updated_end_date', value: last_update.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'last_update')];
    }
  }
  if (amount) {
    query = [...query.filter(filter => filter.id !== 'amount'), { id: 'min_amount', value: amount.value[0] }, { id: 'max_amount', value: amount.value[1] }]
  }
  if (date_of_transaction) {
    if (date_of_transaction.value) {
      query = [...query.filter(filter => filter.id !== 'date_of_transaction'), { id: 'date_of_transaction_start', value: date_of_transaction.value[0]?.slice(0, 10) }, { id: 'date_of_transaction_end', value: date_of_transaction.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'date_of_transaction')];
    }
  }
  if (return_code) {
    query = query.map(filter => filter.id === 'return_code' && filter.value[0] === 'all' ? { ...filter, value: 'R' } : filter);
  }
  if (date_of_customer_contact) {
    if (date_of_customer_contact.value) {
      query = [...query.filter(filter => filter.id !== 'date_of_customer_contact'), { id: 'date_of_customer_contact_start', value: date_of_customer_contact.value[0]?.slice(0, 10) }, { id: 'date_of_customer_contact_end', value: date_of_customer_contact.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'date_of_customer_contact')];
    }
  }
  return { url: filters, query };
};

const formatSortBy = (sortBy) => {
  let query = sortBy;
  return { url: sortBy, query };
};

const RequestsReturnList = ({ customer, filterName, fetchData, fetchOptions, onRowClick, hasPermissions, onAddNewClick }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      id: 'date_submitted',
      Header: t('common:labels.dateSubmitted'),
      accessor: 'date_submitted',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      columnClasses: "align-middle",
      Cell: ({ value }) => formatDateAndTime(value)
    },
    {
      id: 'last_update',
      Header: t('common:labels.lastUpdate'),
      accessor: 'last_update',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      columnClasses: "align-middle",
      Cell: ({ value }) => formatDateAndTime(value)
    },
    {
      id: 'amount',
      Header: t('common:transactions.labels.amount'),
      accessor: data => data.amount,
      Filter: RangeColumnFilter,
      columnStyles: () => ({ width: '1%' }),
      columnClasses: "align-middle",
      Cell: ({ value }) => <><i className="sila-icon sila text-sm text-primary"></i>{formatNumberColumns(value)}</>
    },
    {
      id: 'app_handle',
      Header: t('common:labels.appHandle'),
      accessor: data => data.app.app_handle,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      columnClasses: "align-middle",
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}>{value}</span>
    },
    {
      id: 'user_handle',
      Header: t('common:labels.userHandle'),
      accessor: data => data.user_handle,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      columnClasses: "align-middle",
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}>{value}</span>
    },
    {
      id: 'date_of_transaction',
      Header: t('common:transactions.RequestsForReturn.labels.dateOfTransaction'),
      accessor: 'date_of_transaction',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      columnClasses: "align-middle",
      isHeaderTextWrap: true,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 95 }}>{formatDateAndTime(value)}</span>
    },
    {
      id: 'days_since_transaction',
      Header: t('common:transactions.RequestsForReturn.labels.daysSinceTransaction'),
      accessor: 'days_since_transaction',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      columnClasses: "align-middle",
      isHeaderTextWrap: true,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 91 }}>{value}</span>
    },
    {
      id: 'return_code',
      Header: t('common:transactions.labels.returnCode'),
      accessor: data => data.return_code || t('common:labels.null'),
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      searchOptions: {
        disabled: true
      },
      columnClasses: "align-middle",
      Cell: ({ value }) => <span className="text-nowrap" style={{ maxWidth: 160, minWidth: 90 }}>{value}</span>,
      filterOptions: () => [
        {
          label: t('common:transactions.labels.allCodes'),
          value: 'all'
        },
        ...[...RETURN_CODES].map(rCode => ({
          label: rCode,
          value: rCode
        }))
      ]
    },
    {
      id: 'date_of_customer_contact',
      Header: t('common:transactions.RequestsForReturn.labels.dateInitialContact'),
      accessor: 'date_of_customer_contact',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      columnClasses: "align-middle",
      isHeaderTextWrap: true,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 90 }}>{formatDateAndTime(value)}</span>
    },
    {
      id: 'request_status_name',
      Header: t('common:transactions.RequestsForReturn.labels.requestStatus'),
      accessor: 'request_status_name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      columnClasses: "align-middle",
      Cell: ({ value }) => {
        const notSubmitted = (value === 'pending_review' || value === 'request_for_withdraw' || value === 'open' || value === 'withdrawn');
        return <Badge pill className="badge-outline py-2 px-3 w-100 d-block mx-auto" style={{ maxWidth: 140 }} variant={notSubmitted ? 'primary' : (value === 'submitted' || value === 'completed') ? 'success' : 'warning'}>{t(`common:transactions.RequestsForReturn.status.${value}`)}</Badge>;
      },
      columnStyles: () => ({ width: '1%' }),
      filterOptions: () => [{
        value: 'pending_review',
        label: t('common:transactions.RequestsForReturn.status.pending_review')
      }, {
        value: 'request_for_withdraw',
        label: t('common:transactions.RequestsForReturn.status.request_for_withdraw')
      }, {
        value: 'submitted',
        label: t('common:transactions.RequestsForReturn.status.submitted')
      }, {
        value: 'open',
        label: t('common:transactions.RequestsForReturn.status.open')
      }, {
        value: 'withdrawn',
        label: t('common:transactions.RequestsForReturn.status.withdrawn')
      }, {
        value: 'denied',
        label: t('common:transactions.RequestsForReturn.status.denied')
      }, {
        value: 'completed',
        label: t('common:transactions.RequestsForReturn.status.completed')
      }]
    },
  ], []);

  if (customer) {
    delete columns[3];
    delete columns[6];
    delete columns[8];
  }

  return (
    <DataList
      filterName={filterName}
      slug={TABLE_SLUG}
      limit={TABLE_MAX_COUNT}
      fetchData={fetchData}
      fetchOptions={fetchOptions}
      columns={columns}
      headerLeftComponent={() => <UUIDSearchForm onSubmit={onRowClick} />}
      headerRightComponent={customer ? () => <Button onClick={onAddNewClick} variant="outline-light" type="button" className="mr-5">{t('common:buttons.addNew')}<i className="fa fa-plus-circle ml-2" aria-hidden="true"></i></Button> : undefined}
      onRowClick={onRowClick}
      onFilter={formatFilters}
      onSortBy={formatSortBy}
      hasPermissions={hasPermissions}
      loadingMessage={t('common:transactions.RequestsForReturn.loading')} />
  )
};

RequestsReturnList.propTypes = {
  /**
   * The unique item slug for the data list
   */
  filterName: PropTypes.string,
  /**
   * The fetch data function
   */
  fetchData: PropTypes.func.isRequired,
  /**
   * The fetch options object
   */
  fetchOptions: PropTypes.object.isRequired,
  /**
   * The click handler function trigger on a table row
   */
  onRowClick: PropTypes.func.isRequired,
  /**
   * The function to validate user permissions
   */
  hasPermissions: PropTypes.func.isRequired
};

export default RequestsReturnList;