import React from 'react';
import { Formik, Field } from 'formik';
import { Form, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import * as yup from 'yup';

import { STATES_ARRAY, PHONE_REG_EXP, POSTAL_REG_EXP, DEFAULT_EMAIL, DEFAULT_PHONE, DEFAULT_ADDRESS } from '../../constants';
import { hasPermissions, formatMd5Name, formatAddress, uniqueFieldValue, formatFieldArray } from '../../utils';
import { teamApi } from '../../api';

import FieldWrapper from '../form/wrappers/Field';
import FieldArrayWrapper from '../form/wrappers/FieldArray';
import LoaderButton from '../common/LoaderButton';
import Loader from '../common/Loader';

import { useAuth, useAlerts } from '../context';

yup.addMethod(yup.array, 'unique', uniqueFieldValue);

const TeamForm = () => {
  const { user, onUpdateUser } = useAuth();
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const initialValues = {
    name: formatMd5Name(user.currentTeam.name, user.company.name),
    entity: {
      emails: user.currentTeam.emails && user.currentTeam.emails.length ? user.currentTeam.emails : DEFAULT_EMAIL,
      phones: user.currentTeam.phones && user.currentTeam.phones.length ? user.currentTeam.phones : DEFAULT_PHONE,
      addresses: user.currentTeam.addresses && user.currentTeam.addresses.length ? user.currentTeam.addresses : DEFAULT_ADDRESS
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        name: yup.string()
          .max(56, t('common:form.errors.max', { count: 56 }))
          .required(t('common:form.errors.required', { field: t('common:form.fields.name.label') })),
        entity: yup.object().shape({
          phones: yup.array().of(yup.object().shape({
            phone: yup.string()
              .matches(PHONE_REG_EXP, t('common:form.fields.phone.error'))
          })).unique('phone', t('common:form.errors.exists', { field: t('common:form.fields.phone.label') }), value => value.phone),
          emails: yup.array().of(yup.object().shape({
            email: yup.string()
              .email(t('common:form.fields.email.error'))
              .max(254, t('common:form.errors.max', { count: 320 }))
              .required(t('common:form.errors.required', { field: t('common:form.fields.email.label') })),
          })).unique('email', t('common:form.errors.exists', { field: t('common:form.fields.email.label') }), value => value.email),
          addresses: yup.array().of(yup.object().shape({
            street_address_1: yup.string()
              .max(254, t('common:form.errors.max', { count: 254 })),
            street_address_2: yup.string()
              .nullable()
              .max(254, t('common:form.errors.max', { count: 254 })),
            city: yup.string()
              .ensure().when('street_address_1', {
                is: (value) => value && value.length,
                then: yup.string().required(t('common:form.errors.required', { field: t('common:form.fields.address.city.label') }))
              })
              .max(96, t('common:form.errors.max', { count: 96 })),
            state: yup.string()
              .ensure().when('street_address_1', {
                is: (value) => value && value.length,
                then: yup.string().required(t('common:form.errors.required', { field: t('common:form.fields.address.state.label') }))
              }),
            postal_code: yup.string()
              .ensure().when('street_address_1', {
                is: (value) => value && value.length,
                then: yup.string()
                  .required(t('common:form.errors.required', { field: t('common:form.fields.address.zip.label') }))
                  .matches(POSTAL_REG_EXP, {
                    message: t('common:form.fields.address.zip.error')
                  })
              })
          }))
        })
      })}
      onSubmit={(values, props) => {
        const { setSubmitting, resetForm } = props;
        formatFieldArray(values, props, async (newValues) => {
          if (newValues.name === user.currentTeam.name) delete newValues.name;
          try {
            const response = await teamApi.updateTeam(user.currentTeam.id, newValues);
            setAlert({ message: t('settings.account.success'), variant: 'success' });
            onUpdateUser({ currentTeam: { ...user.currentTeam, ...values.entity, name: values.name, ...response.team } });
            resetForm({ values: { name: values.name, entity: { ...values.entity, ...response.team } } });
          } catch (error) {
            setAlert({ message: error, variant: 'warning' })
            resetForm({ values: initialValues });
          } finally {
            setSubmitting(false);
          }
        });
      }}>
      {({ values, errors, handleSubmit, isSubmitting, dirty, setFieldValue, ...rest }) => (
        <Form noValidate className="position-relative" autoComplete="new-password" onSubmit={handleSubmit}>

          {isSubmitting && <Loader />}

          <Card>
            <Card.Header className="p-4">
              <h3 className="m-0">{values.name}</h3>
            </Card.Header>
            <Card.Body>
              <Field required autofocus readOnly={!hasPermissions(user, 'teams', 'Write')} className="name"
                id="teamForm.name"
                label={t('common:form.fields.teamName.label')}
                name="name"
                component={FieldWrapper} />
              <Form.Row>
                <Col xs="12" sm>
                  <FieldArrayWrapper
                    form={{ id: 'teamForm', ...rest }}
                    field={{
                      name: "entity.emails",
                      slug: "email",
                      handleChange: (e) => setFieldValue(e.target.name, e.target.value.toLowerCase().trim()),
                      initialValues: {
                        email: ''
                      }
                    }}
                    values={values}
                    errors={errors} />
                </Col>
                <Col xs="12" sm>
                  <FieldArrayWrapper
                    form={{ id: 'teamForm', ...rest }}
                    field={{
                      name: "entity.phones",
                      slug: "phone",
                      initialValues: {
                        phone: ''
                      }
                    }}
                    values={values}
                    errors={errors} />
                </Col>
              </Form.Row>
              <FieldArrayWrapper
                form={{ id: 'teamForm', ...rest }}
                field={{
                  name: "entity.addresses",
                  slug: "address"
                }}
                values={values}
                errors={errors}
                formatValue={formatAddress}
                fields={index => <><Field autofocus className="street-address-1"
                  id={`teamForm.addresses.${index}.street1`}
                  label={t('common:form.fields.address.street1.label')}
                  name={`entity.addresses.${index}.street_address_1`}
                  component={FieldWrapper} />
                  <Field autofocus className="street-address-2"
                    id={`teamForm.addresses.${index}.street2`}
                    label={t('common:form.fields.address.street2.label')}
                    name={`entity.addresses.${index}.street_address_2`}
                    component={FieldWrapper} />
                  <Form.Row>
                    <Col xs="12" sm="4">
                      <Field autofocus className="city"
                        id={`teamForm.addresses.${index}.city`}
                        label={t('common:form.fields.address.city.label')}
                        name={`entity.addresses.${index}.city`}
                        component={FieldWrapper} />
                    </Col>
                    <Col xs="12" sm="4">
                      <Field autofocus className="state"
                        id={`teamForm.addresses.${index}.state`}
                        label={t('common:form.fields.address.state.label')}
                        name={`entity.addresses.${index}.state`}
                        type="select"
                        options={STATES_ARRAY.map(state => ({
                          label: t(`common:form.fields.address.state.options.${state}`),
                          value: state
                        }))}
                        component={FieldWrapper} />
                    </Col>
                    <Col xs="12" sm="4">
                      <Field autofocus className="postal-code"
                        id={`teamForm.addresses.${index}.postal_code`}
                        label={t('common:form.fields.address.zip.label')}
                        name={`entity.addresses.${index}.postal_code`}
                        component={FieldWrapper} />
                    </Col>
                  </Form.Row></>} />
              {hasPermissions(user, 'teams', 'Write') && <div className="d-flex">
                <LoaderButton className="ml-auto" loading={isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={!dirty || Object.keys(errors).length}>{t(isSubmitting && !Object.keys(errors).length ? 'common:status.submitting' : 'common:buttons.save')}</LoaderButton>
              </div>}
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default TeamForm;
