import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const calcDays = (duration) => Math.floor(duration / 86400);
const calcHours = (duration) => Math.floor(duration / 3600) - (Math.floor(duration / 86400) * 24);
const calcMinutes = (duration) => Math.floor(duration / 60) - (Math.floor(duration / 3600) * 60);
const calcDuration = (days, hours, minutes) => (parseInt(days) * 86400) + (parseInt(hours) * 3600) + (parseInt(minutes) * 60);

const TimePicker = ({ inline, disabled, duration, onHandleChange }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    days: calcDays(duration),
    hours: calcHours(duration),
    minutes: calcMinutes(duration)
  });

  const handleChange = (type, value) => {
    setValues({ ...values, [type]: !isNaN(parseInt(value, 10)) ? value < 0 ? 0 : value : 0 });
  };

  useEffect(() => {
    setValues({
      days: calcDays(duration),
      hours: calcHours(duration),
      minutes: calcMinutes(duration)
    });
  }, [duration]);

  useEffect(() => {
    if ((values.days || values.hours || values.minutes) && onHandleChange) onHandleChange(calcDuration(values.days, values.hours, values.minutes));
  }, [values]);

  return (
    <Form as={Row} noGutters className={`mx-n2${inline ? ' d-flex flex-nowrap' : ''}`}>
      <Col className="px-2">
        <Form.Group className={`mb-0${inline ? ' d-flex flex-nowrap align-items-center' : ''}`}>
          <Form.Label htmlFor="timepicker.days" className={inline ? 'mr-2 mb-0' : undefined}>{inline ? 'D' : t('common:labels.days')}</Form.Label>
          <Form.Control className="mb-0"
            id="timepicker.days"
            name="days"
            type="number"
            size="sm"
            value={values.days}
            onChange={(e) => handleChange('days', e.target.value)}
            disabled={disabled}
            htmlSize={2} />
        </Form.Group>
      </Col>
      <Col className="px-2">
        <Form.Group className={`mb-0${inline ? ' d-flex flex-nowrap align-items-center' : ''}`}>
          <Form.Label htmlFor="timepicker.hours" className={inline ? 'mr-2 mb-0' : undefined}>{inline ? 'H' : t('common:labels.hours')}</Form.Label>
          <Form.Control className="mb-0"
            id="timepicker.hours"
            name="hours"
            type="number"
            size="sm"
            value={values.hours}
            onChange={(e) => handleChange('hours', e.target.value)}
            disabled={disabled}
            htmlSize={2} />
        </Form.Group>
      </Col>
      <Col className="px-2">
        <Form.Group className={`mb-0${inline ? ' d-flex flex-nowrap align-items-center' : ''}`}>
          <Form.Label htmlFor="timepicker.minutes" className={inline ? 'mr-2 mb-0' : undefined}>{inline ? 'M' : t('common:labels.minutes')}</Form.Label>
          <Form.Control className="mb-0"
            id="timepicker.minutes"
            name="minutes"
            type="number"
            size="sm"
            value={values.minutes}
            disabled={disabled}
            onChange={(e) => handleChange('minutes', e.target.value)}
            htmlSize={2} />
        </Form.Group>
      </Col>
    </Form>
  );
};

TimePicker.propTypes = {
  /**
   * The inline boolean to display inline and shortened labels or stacked
   */
  inline: PropTypes.bool,
  /**
   * The disabled boolean to disable the fields
   */
  disabled: PropTypes.bool,
  /**
   * The react dom match object
   */
  duration: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  /**
  * The on change function
  */
  onHandleChange: PropTypes.func
};

export default TimePicker;
