/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { appsApi } from '../api';

import ApplicationCreateForm from '../components/applications/ApplicationCreateForm';

import { useAuth, useAlerts } from '../components/context';

import Loader from '../components/common/Loader';
import { AppDetailHeader } from '../components/applications/AppProfileViewComponents';
import AppPanels from '../components/applications/AppPanels';

function Application({ isMobile, match, location }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { setAlert } = useAlerts();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [app, setApp] = useState({});
  const redirect = () => {
    history.push({ pathname: '/apps', state: { from: location.pathname } });
  };

  const getApplication = async () => {
    try {
      const response = await appsApi.getApp(match.params.id);
      setApp({
        ...response.app,
        metrics: response.app.metrics,
      });
      setIsLoading(false);
    } catch (error) {
      redirect();
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (match.params.id !== 'new') {
      getApplication();
    }
  }, []);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <AppDetailHeader t={t} />

      {match.params.id === 'new' ? (
        <ApplicationCreateForm onHide={redirect} isMobile={isMobile} />
      ) : isLoading ? (
        <Loader />
      ) : (
        <AppPanels
          app={app}
          t={t}
          setApp={setApp}
          onDelete={redirect}
          isMobile={isMobile}
          onSave={getApplication}
          user={user}
          history={history}
          match={match}
          onFreeze={getApplication}
        />
      )}
    </Container>
  );
}

export default Application;
