import React, { useState, useEffect } from 'react';
import {
  Button, Card, Row, Col, TabContainer, TabContent, TabPane, Nav,
} from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { setUniqueCookie, hasPermissions } from '../../utils';
import { authApi } from '../../api';

import LoaderButton from '../common/LoaderButton';

import {
  SILA_MARKET_PLACE_URL, SILA_SECURITY_REQUIREMENTS_URL, START_SILA_JOURNEY_URL, SUPPORT_URL,
} from '../../constants';

import { useAuth, useAlerts } from '../context';

function OnboardingBanner() {
  const { user } = useAuth();
  const { setAlert } = useAlerts();
  const [key, setKey] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const [completedAllSteps, setCompletedAllSteps] = useState(false);
  const [disabledResendButton, setDisableResendButton] = useState(true);
  const [startSilaJourney, setStartSilaJourney] = useState(
    !!(Cookies.get(`start_sila_journey_${user.entity.handle}`)),
  );
  const [exploreMarketPlace, setExploreMarketPlace] = useState(
    !!Cookies.get(`sila_console_marketplace_${user.entity.handle}`),
  );
  const [newWelcomePanel, setNewWelcomePanel] = useState(
    (!Cookies.get(`sila_console_newwelcome_closed_${user.entity.handle}`)),
  );
  const [securityRequirements, setSecurityRequirements] = useState(
    !!(Cookies.get(`sila_console_security_requirements_${user.entity.handle}`)),
  );
  const { t } = useTranslation();
  const history = useHistory();
  const canInvite = hasPermissions(user, 'members', 'Write');

  // load data for disabling resend email buttin
  useEffect(() => {
    let timeoutId = null;
    const fetchData = async () => {
      if (!user.confirmed_at) {
        const response = await authApi.resendVerificationEmailResendDate(user.entity.handle);
        if (response) {
          const resendAt = new Date(response);
          const currentTime = new Date();

          // Check if the current time is before the resend time
          if (currentTime < resendAt) {
            const msUntilEnable = resendAt.getTime() - currentTime.getTime();
            timeoutId = setTimeout(() => {
              setDisableResendButton(false);
            }, msUntilEnable);
          } else {
            setDisableResendButton(false);
          }
        } else {
          setDisableResendButton(false);
        }
      }
    };

    fetchData();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [disabledResendButton]);

  const handleAction = (url, cName) => {
    if (cName !== 'create_app') setUniqueCookie(cName, user.entity.handle);
    if (cName === 'sila_console_marketplace' || cName === 'sila_console_community') {
      if (cName === 'sila_console_marketplace') setExploreMarketPlace(true);
      window.open(url);
    } else if (cName === 'sila_console_invited_team' || cName === 'create_app') {
      // eslint-disable-next-line no-restricted-globals
      history.push({ pathname: url, state: { from: location.pathname } });
    } else if (cName === 'start_sila_journey') {
      setStartSilaJourney(true);
      window.open(url);
    } else if (cName === 'sila_console_newwelcome_closed') {
      setNewWelcomePanel(false);
    } else if (cName === 'sila_console_security_requirements') {
      setSecurityRequirements(true);
      window.open(url);
    }
  };

  const checkCompletes = () => {
    let nextTabKey;
    if (user.confirmed_at && !completedAllSteps) {
      nextTabKey = 2;
    } else {
      setKey(1);
      return;
    }
    if (user.hasMembers && !completedAllSteps) {
      nextTabKey = canInvite ? 3 : 2;
    } else {
      setKey(canInvite ? 2 : 1);
      return;
    }
    if (startSilaJourney && !completedAllSteps) {
      nextTabKey = canInvite ? 4 : 3;
    } else {
      setKey(canInvite ? 3 : 2);
      return;
    }
    if (securityRequirements && !completedAllSteps) {
      nextTabKey = canInvite ? 5 : 4;
    } else {
      setKey(canInvite ? 4 : 3);
      return;
    }
    if (user.hasApps && !completedAllSteps) {
      nextTabKey = canInvite ? 6 : 5;
    } else {
      setKey(canInvite ? 5 : 4);
      return;
    }
    if (nextTabKey) setKey(nextTabKey);
    if (
      nextTabKey === (canInvite ? 6 : 5)
      && startSilaJourney
      && exploreMarketPlace
      && securityRequirements
    ) setCompletedAllSteps(true);
  };

  const resendVerificationEmail = async () => {
    try {
      setIsSending(true);
      await authApi.resendVerificationEmail(user.entity.handle, user.entity.emails['0'].email);
      setAlert({
        message: t(
          'resendVerfication.message',
          { email: user.entity.emails['0'].email },
        ),
        variant: 'success',
      });
      checkCompletes();
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
    setDisableResendButton(true);
    setIsSending(false);
  };

  useEffect(() => {
    if (newWelcomePanel) checkCompletes();
  }, [startSilaJourney, exploreMarketPlace, securityRequirements]);

  const handleClick = (e) => {
    e.preventDefault(); handleAction('close', 'sila_console_newwelcome_closed');
  };

  const selectNumber = (base) => (canInvite ? base : base - 1);

  return newWelcomePanel ? (
    <Card className="welcome-banner flex-fill mb-5">
      <Card.Body as={Row} className="p-4">
        <Col md="12" lg="12" xl="12" className="pr-0 pr-lg-4 pb-4 pb-lg-0 position-relative">
          {completedAllSteps && (
          <button
            type="submit"
            className="text-info position-absolute position-right close mr-4"
            onClick={handleClick}
          >
            <i className="text-lg sila-icon close" />
            <span className="sr-only">{t('common:buttons.close')}</span>
          </button>
          )}
          <h2 className="mb-3">{t('welcome.getStarted.title')}</h2>
          <p className="text-lg text-info mb-5">{t('welcome.getStarted.description')}</p>
        </Col>
        <TabContainer
          activeKey={!completedAllSteps ? key : selectNumber(7)}
          onSelect={(k) => setKey(k)}
        >
          <Col md="12" lg="5">
            <Nav className="step-list flex-column">
              <Nav.Link
                disabled={completedAllSteps}
                eventKey="1"
                className={`rounded d-flex${user.confirmed_at ? ' complete' : ''}`}
              >
                {t('welcome.getStarted.actionKey1')}
              </Nav.Link>
              {canInvite && (
              <Nav.Link
                disabled={completedAllSteps}
                eventKey="2"
                className={`rounded d-flex${user.hasMembers ? ' complete' : ''}`}
              >
                {t('welcome.getStarted.actionKey2')}
              </Nav.Link>
              )}
              <Nav.Link
                disabled={completedAllSteps}
                eventKey={canInvite ? 3 : 2}
                className={`rounded d-flex${startSilaJourney ? ' complete' : ''}`}
              >
                {t('welcome.getStarted.actionKey3')}
              </Nav.Link>
              <Nav.Link
                disabled={completedAllSteps}
                eventKey={canInvite ? 4 : 3}
                className={`rounded d-flex${securityRequirements ? ' complete' : ''}`}
              >
                {t('welcome.getStarted.actionKey4')}
              </Nav.Link>
              <Nav.Link
                disabled={completedAllSteps}
                eventKey={canInvite ? 5 : 4}
                className={`rounded d-flex${user.hasApps ? ' complete' : ''}`}
              >
                {t('welcome.getStarted.actionKey5')}
              </Nav.Link>
              <Nav.Link
                disabled={completedAllSteps}
                eventKey={canInvite ? 6 : 5}
                className={`rounded d-flex${exploreMarketPlace ? ' complete' : ''}`}
              >
                {t('welcome.getStarted.actionKey6')}
              </Nav.Link>
            </Nav>
          </Col>
          <Col className="mt-4 mt-mb-0 mt-lg-0" md="12" lg="7">
            <TabContent className="border rounded p-4 h-100">
              <TabPane eventKey="1">
                <h4 className="mb-3 text-xl">{t('welcome.getStarted.actionKey1')}</h4>
                <p className="text-lg text-info">
                  {user.confirmed_at
                    ? t('account.confirm.user')
                    : t('welcome.getStarted.actionKey1Content')}
                </p>
                <LoaderButton
                  className="text-sm text-uppercase mt-3"
                  variant="primary"
                  type="button"
                  onClick={resendVerificationEmail}
                  loading={isSending}
                  disabled={disabledResendButton}
                  loadingLabel={t('common:status.sending')}
                >
                  {t('resendVerfication.button')}
                </LoaderButton>
              </TabPane>
              {canInvite && (
              <TabPane eventKey="2">
                <h4 className="mb-3 text-xl">{t('welcome.getStarted.actionKey2')}</h4>
                <p className="text-lg text-info">{t('welcome.getStarted.actionKey2Content')}</p>
                <Button
                  variant="primary"
                  className="text-sm text-uppercase mt-3"
                  onClick={() => handleAction('/settings/members/new', 'sila_console_invited_team')}
                >
                  {t('welcome.getStarted.actionKey2Button')}
                </Button>
              </TabPane>
              )}
              <TabPane eventKey={canInvite ? 3 : 2}>
                <h4 className="mb-3 text-xl">{t('welcome.getStarted.actionKey3')}</h4>
                <p className="text-lg text-info">{t('welcome.getStarted.actionKey3Content')}</p>
                <Button
                  variant="primary"
                  className="text-sm text-uppercase mt-3"
                  onClick={() => handleAction(START_SILA_JOURNEY_URL, 'start_sila_journey')}
                >
                  {t('welcome.getStarted.actionKey3Button')}
                </Button>
              </TabPane>
              <TabPane eventKey={canInvite ? 4 : 3}>
                <h4 className="mb-3 text-xl">{t('welcome.getStarted.actionKey4')}</h4>
                <p className="text-lg text-info">{t('welcome.getStarted.actionKey4Content')}</p>
                <Button
                  variant="primary"
                  className="text-sm text-uppercase mt-3"
                  onClick={() => handleAction(SILA_SECURITY_REQUIREMENTS_URL, 'sila_console_security_requirements')}
                >
                  {t('welcome.getStarted.actionKey4Button')}
                </Button>
              </TabPane>
              <TabPane eventKey={canInvite ? 5 : 4}>
                <h4 className="mb-3 text-xl">{t('welcome.getStarted.actionKey5')}</h4>
                <p className="text-lg text-info">
                  <Trans i18nKey="welcome.getStarted.actionKey5Content">
                    Once you&apos;ve created your app,
                    <a
                      href="https://docs.silamoney.com/docs/installing-the-sdk-demo"
                      target="_blank"
                      rel="noreferrer"
                    >
                      run a local instance
                    </a>
                    , or install our
                    <a
                      href="https://github.com/Sila-Money/sila-postman-signer"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Postman Collection
                    </a>
                    .
                  </Trans>
                </p>
                <Button
                  variant="primary"
                  className="text-sm text-uppercase mt-3"
                  disabled={!user.confirmed_at}
                  onClick={() => handleAction('/app/new', 'create_app')}
                >
                  {t('welcome.getStarted.actionKey5Button')}
                </Button>
              </TabPane>
              <TabPane eventKey={canInvite ? 6 : 5}>
                <h4 className="mb-3 text-xl">{t('welcome.getStarted.actionKey6Heading')}</h4>
                <p className="text-lg text-info">{t('welcome.getStarted.actionKey6Content')}</p>
                <Button
                  variant="primary"
                  className="text-sm text-uppercase mt-3"
                  onClick={() => handleAction(SILA_MARKET_PLACE_URL, 'sila_console_marketplace')}
                >
                  {t('welcome.getStarted.actionKey6Button')}
                </Button>
              </TabPane>
              {completedAllSteps && (
              <TabPane eventKey={canInvite ? 7 : 6}>
                <h4 className="mb-3 text-xl">{t('welcome.getStarted.congratsHeading')}</h4>
                <p className="text-lg text-info">{t('welcome.getStarted.congratsContent')}</p>
                <Button
                  variant="primary"
                  className="text-sm text-uppercase mt-3"
                  href={SUPPORT_URL}
                  target="_blank"
                >
                  {t('welcome.getStarted.congratsButton')}
                </Button>
              </TabPane>
              )}
            </TabContent>
          </Col>
        </TabContainer>
      </Card.Body>
    </Card>
  ) : null;
}

export default OnboardingBanner;
