import React, { useMemo } from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { adminApi } from '../../api';
import { formatDateAndTime, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import { useApp } from '../../components/context/AppDataProvider';

import PageTitle from '../../components/common/PageTitle';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import DataList from '../../components/common/DataList';
import { MultipleFilter, CheckboxColumnFilter, DateBetweenFilter, DateRangeColumnFilter } from '../../components/common/table/TableFilters';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'checklist_templates';

const formatSortBy = (sortBy) => {
  let query = sortBy;
  return { url: sortBy, query };
};

const formatFilters = (filters) => {
  let query = filters;
  const created = filters.find(filter => filter.id === 'created');
  const modified = filters.find(filter => filter.id === 'modified');
  if (created) {
    if (created.value) {
      query = [...query.filter(filter => filter.id !== 'created'), { id: 'created_start', value: created.value[0]?.slice(0, 10) }, { id: 'created_end', value: created.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'created')];
    }
  }
  if (modified) {
    if (modified.value) {
      query = [...query.filter(filter => filter.id !== 'modified'), { id: 'modified_start', value: modified.value[0]?.slice(0, 10) }, { id: 'modified_end', value: modified.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'modified')];
    }
  }
  return { url: filters, query };
};

const ChecklistTemplates = ({ t, history, user, env }) => {
  const { app, setAppData } = useApp();
  const hasWriteAccess = hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]);

  const columns = useMemo(() => [
    {
      id: 'created',
      Header: t('common:labels.created'),
      accessor: 'created',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => value ? formatDateAndTime(value) : <span className="text-info">{t('common:labels.na')}</span>
    },
    {
      id: 'name',
      Header: t('common:labels.name'),
      accessor: 'name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => value
    },
    {
      id: 'modified',
      Header: t('common:labels.lastUpdated'),
      accessor: 'modified',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => value ? formatDateAndTime(value) : <span className="text-info">{t('common:labels.na')}</span>
    }
  ], [env]);

  const createTemplate = (rows) => {
    setAppData({
      checklist: {}
    }, () => {
      history.push({ pathname: '/admin/customers/checklists/new', state: { owners: rows, from: location.pathname } });
    });
  }

  return (
    <Container className="owners main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.customers.checklist.title')}>
        <Button variant="outline-light" className="back text-info" as={NavLink} exact={true} to="/admin">{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]) ?
        <DataList
          filterName={TABLE_SLUG}
          slug={TABLE_SLUG}
          limit={TABLE_MAX_COUNT}
          fetchData={adminApi.fetchCustomerChecklistTemplates}
          columns={columns}
          onRowClick={(row) => history.push(`/admin/customers/checklists/${row.original.id}`)}
          onFilter={formatFilters}
          onSortBy={formatSortBy}
          hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE])}
          headerRightComponent={(rows) => hasWriteAccess ? <Button  className="mt-2 mt-sm-0" onClick={() => createTemplate(rows)} variant="outline-light">{t('admin.customers.checklist.list.buttons.create')} <i className="fas fa-plus-circle ml-2"></i></Button> : null}
          headerClasses={({ headerGroup, index }) => classNames(
            'py-2 col-4',
            index === 0 && 'pl-4',
            index === headerGroup.headers.length - 1 && 'pr-4',
            index !== 0 && index !== headerGroup.headers.length && 'px-3'
          )}
          cellClasses={({ row, index }) => classNames(
            'py-3',
            index === 0 && 'pl-4',
            index === row.cells.length - 1 && 'pr-4',
            index !== 0 && index !== row.cells.length && 'px-3'
          )}
          loadingMessage={t('admin.customers.checklist.list.loading')} />
        :
        <div className="position-relative"><DisabledOverlay /></div>}

    </Container>
  );
};

export default ChecklistTemplates;