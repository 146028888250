import React, { useState, useRef } from 'react';
import { Container, Row, Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import PageTitle from '../components/common/PageTitle';
import UserForm from '../components/common/UserForm';
import ConfirmModal from '../components/common/ConfirmModal';

import { DEFAULT_PHONE, DEFAULT_ADDRESS, DEFAULT_EMAIL } from '../constants';

import { authApi } from '../api';

import { useAuth } from '../components/context';

const Account = ({ t, user, setAlert }) => {
  const [name, setName] = useState(user && user.entity ? `${user.entity.first_name} ${user.entity.surname}` : '');
  const [currentUser, setCurrentUser] = useState({ show: false, data: undefined, handle: user && user.entity ? user.entity.handle : '', name: user && user.entity ? `${user.entity.first_name} ${user.entity.surname}` : ''});
  const { onUpdateUser } = useAuth();
  const formRef = useRef(null);

  const initialValues = {
    first_name: user.entity.first_name,
    surname: user.entity.surname,
    handle: user.entity.handle,
    emails: user.entity.emails.length ? [user.entity.emails[0]] : DEFAULT_EMAIL,
    phones: user.entity.phones.length ? user.entity.phones : DEFAULT_PHONE,
    birthdate: user.entity.birthdate,
    addresses: user.entity.addresses.length ? user.entity.addresses : DEFAULT_ADDRESS,
  };

  const updateUser = async (values) => {
    if (values) {
      if (initialValues.emails[0].email === values.emails[0].email) {
        delete values.emails;
      }
      try {
        const response = await authApi.updateProfile(values);
        setAlert({ message: t('account.profile.form.messages.success'), variant: 'success' });
        onUpdateUser({
          ...response.user,
          confirmed_at: values.emails ? null : user.confirmed_at
        });
        formRef.current?.resetForm({ values: response.user.entity });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        formRef.current?.resetForm({ values: initialValues });
      } finally {
        setCurrentUser({ ...currentUser, show: false, data: undefined });
        formRef.current?.setSubmitting(false);
      }
    } else {
      setCurrentUser({ ...currentUser, show: false, data: undefined });
      formRef.current?.setSubmitting(false);
    }
  };

  return !user ? <Redirect to="/login" /> : (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
  
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('account.title')} />
      </Row>
  
      <Card className="mb-3 mb-md-5">
        <Card.Header className="p-4">
          <h3 className="m-0">{name}</h3>
        </Card.Header>
        <Card.Body>
          <UserForm confirm
            formRef={formRef}
            initialValues={initialValues}
            setName={setName}
            onSubmit={(values) => setCurrentUser({ ...currentUser, show: true, data: values })} />
        </Card.Body>
      </Card>

      {currentUser.data && <ConfirmModal
        show={currentUser.show}
        data={currentUser.data}
        title={t('admin.user.update.title')}
        renderMessageComponent={() => <>
          <p className="text-lg mb-4">{t(`admin.user.update.confirm`, { name: currentUser.name })}</p>
          <p className="text-info">{t(`admin.user.update.description`, { name: currentUser.name, handle: currentUser.handle })}</p>
        </>}
        buttonLabel={t('admin.user.update.action')}
        onHide={updateUser} />}
  
    </Container>
  );
};

export default Account;
