import React, { useState, useEffect, useRef } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { formatMd5Name } from '../utils/';
import { useDebounce } from '../utils/hooks';
import { authApi, teamApi } from '../api';

import Loader from '../components/common/Loader';
import LoaderButton from '../components/common/LoaderButton';
import FieldWrapper from '../components/form/wrappers/Field';

import { useAuth } from '../components/context';

import { SIGNUP_STEP_COMPLETED, EMAIL_REG_EXP } from '../constants';

const InviteTeamMembers = ({ location, history, app, updateApp, setAlert }) => {
  const [teamNameStatus, setTeamNameStatus] = useState({ pending: false, isValid: false });
  const [teamName, setTeamName] = useState('');
  const [emailsStatus, setEmailsStatus] = useState({ status: '', emailsIsValid: true, isSending: false, email_exists: '' });
  const [emailAddresses, setEmailAddresses] = useState('');
  const { user, onUpdateUser, refreshSession } = useAuth();
  const debouncedTeamName = useDebounce(teamName, 750);
  const debouncedemailAddresses = useDebounce(emailAddresses, 750);
  const formRef = useRef(null);
  const { t } = useTranslation();
  const hasTeamName = (user && user.currentTeam.name) ? user.currentTeam.name : app.registration.team_name ? app.registration.team_name : '';
  const hasTeamId = (user && user.currentTeam.id) ? user.currentTeam.id : app.registration.team_id ? app.registration.team_id : '';

  const initialValues = {
    team_name: app.registration.company_name,
    emails: ''
  };

  const schema = yup.object().shape({
    team_name: !app.invitation ? yup.string()
      .required(t('common:form.errors.required', { field: t('common:form.fields.teamName.label') }))
      .test('checkTeamName', t('common:form.fields.teamName.exists') || t('common:status.validating'), (value) => app.validation.checkTeamName.isValid && value) : null,
    emails: yup.string()
      .notRequired()
      .test('checkValidEmails', t('inviteTeamMembers.form.fields.emails.error') || t('common:status.validating'), (value) => emailsStatus.emailsIsValid)
  });

  const checkTeamName = async (value) => {
    if (value) {
      setTeamNameStatus({ ...teamNameStatus, pending: true });
      updateApp({ validation: { ...app.validation, checkTeamName: { ...app.validation.checkTeamName, teamName: value } } });
      try {
        const response = await authApi.checkTeamName(value);
        const isValidStatus = response && response.success === true ? true : false;
        setTeamNameStatus({ ...teamNameStatus, isValid: isValidStatus, pending: false });
        updateApp({ validation: { ...app.validation, checkTeamName: { ...app.validation.checkTeamName, isValid: isValidStatus } } });
      } catch (error) {
        setTeamNameStatus({ ...teamNameStatus, isValid: false, pending: false });
        updateApp({ validation: { ...app.validation, checkTeamName: { ...app.validation.checkTeamName, isValid: false, error: error } } });
      } finally {
        formRef.current.setFieldTouched('team_name', true);
      }
    }
  };

  const checkEmail = async (value) => {
    if (value) {
      setEmailsStatus({ ...emailsStatus, isSending: true });
      try {
        const exists = await authApi.confirmAccountExists(value);
        if (!exists == false) {
          setEmailsStatus({ ...emailsStatus, status: 'exists', email_exists: value, emailsIsValid: false, isSending: false });
          return false;
        }
      } catch (error) {
        setEmailsStatus({ ...emailsStatus, status: '', emailsIsValid: false, isSending: false });
      }
    }
  };

  const checkValidEmails = async (emailValue) => {
    if (emailValue) {
      let emailRegex = EMAIL_REG_EXP;
      let invitesEmails = emailValue.split(',').map(value => value.trim());
      for (let i = 0; i < invitesEmails.length; i++) {
        if (invitesEmails[i] != "" && !emailRegex.test(invitesEmails[i])) {
          setEmailsStatus({ ...emailsStatus, status: 'error', emailsIsValid: false });
          return false;
        } else {
          checkEmail(invitesEmails[i]);
        }
      }
      setEmailsStatus({ ...emailsStatus, status: 'pending', emailsIsValid: true });
      return true;
    } else {
      setEmailsStatus({ ...emailsStatus, status: '', emailsIsValid: true });
      return true;
    }
  };

  const sendInvites = async (emails, setFieldValue) => {
    if (emails) {
      try {
        setEmailsStatus({ ...emailsStatus, isSending: true });
        const invitesEmails = [];
        emails.split(',').map(function (value) { if (value.trim() !== "") { invitesEmails.push(value.trim()); } });
        const response = await Promise.all(invitesEmails.map(email => teamApi.inviteMember({ member: { email: email }, role: { id: 3 }, signup_step_completed: SIGNUP_STEP_COMPLETED.STEP2 }, { id: hasTeamId }, 'sandbox')));
        setFieldValue('emails', initialValues.emails, false);
        if (response.some(res => !res.success)) {
          setAlert({ message: response.message, variant: 'warning' });
        }
        setEmailsStatus({ ...emailsStatus, status: 'sent', emailsIsValid: true, isSending: false });
        setFieldValue('emails', initialValues.emails, false);
        refreshSession();
      } catch (error) {
        setEmailsStatus({ ...emailsStatus, isSending: false });
        setAlert({ message: error, variant: 'warning' });
        throw error;
      };
    }
  }

  useEffect(() => {
    if (debouncedTeamName) checkTeamName(debouncedTeamName);
  }, [debouncedTeamName]);

  useEffect(() => {
    if (debouncedemailAddresses) checkValidEmails(debouncedemailAddresses);
  }, [debouncedemailAddresses]);

  useEffect(() => {
    if (!app.registration.inProgress) {
      history.push({ pathname: '/welcome', state: { from: location.pathname } });
    } else if (hasTeamName == app.registration.company_name) {
      setTeamNameStatus({ ...teamNameStatus, isValid: true, pending: false });
      updateApp({ validation: { ...app.validation, checkTeamName: { ...app.validation.checkTeamName, isValid: true } } });
    } else if (formatMd5Name(hasTeamName, app.registration.company_name) == app.registration.company_name) {
      checkTeamName(hasTeamName);
    }
  }, []);

  return (
    <Container className="register main-content-container p-3 py-md-5">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        innerRef={formRef}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          try {
            if (!app.invitation && hasTeamName != values.team_name) {
              const response = await teamApi.updateTeamName(hasTeamId, {
                name: values.team_name,
                signup_step_completed: SIGNUP_STEP_COMPLETED.STEP2
              });
              if (!response.success) {
                setAlert({ message: response.message, variant: 'warning' });
                return;
              }
              const teamNewValues = { ...user.currentTeam, name: values.team_name };
              onUpdateUser({ currentTeam: teamNewValues });
              updateApp({ registration:  { ...app.registration, team_name: values.team_name, inProgress: true } });
            } else {
              await authApi.updateSignUpStep({ signup_step_completed: SIGNUP_STEP_COMPLETED.STEP2 });
            }
            setEmailsStatus({ ...emailsStatus, status: '', emailsIsValid: true });
            setFieldValue('emails', initialValues.emails, false);
            setSubmitting(false);
            history.push({ pathname: '/register/questionnaire', state: { from: location.pathname } });
          } catch (error) {
            setAlert({ message: error, variant: 'warning' });
          }
        }}>
        {({ values, errors, handleSubmit, isSubmitting, setFieldValue, touched, setErrors }) => {
          if (touched.emails && !values.emails && errors.emails) {
            delete errors.emails;
            setErrors(errors);
          }
          return (<Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <h2 className="mb-4">{t('inviteTeamMembers.title.team_name')}</h2>
            <Field required autofocus material data-hj-whitelist plaintext={app.invitation ? true : false} readOnly={app.invitation ? true : false}
              tabIndex={1}
              id="InviteTeamMembers.team_name"
              label={t('inviteTeamMembers.form.fields.team_name.label')}
              name="team_name"
              fieldClass="data-hj-whitelist"
              handleChange={e => setTeamName(e.target.value)}
              isValid={teamNameStatus && teamNameStatus.isValid && !teamNameStatus.pending}
              component={FieldWrapper}
              append={teamNameStatus && teamNameStatus.pending ? <Loader size="sm" className="mr-3" /> : undefined} />
            <h4 className="mt-5 mb-3 text-lg">{t('inviteTeamMembers.title.emails')}</h4>
            <ul className="invite-list p-0 mb-5 text-info">
              <li className="text-info">{t('inviteTeamMembers.description.emails.item1')}</li>
              <li className="text-info">{t('inviteTeamMembers.description.emails.item2')}</li>
              <li className="text-info">{t('inviteTeamMembers.description.emails.item3')}</li>
            </ul>
            <Field autofocus data-hj-whitelist
              tabIndex={2}
              id="InviteTeamMembers.emails"
              label={t('inviteTeamMembers.form.fields.emails.label')}
              name="emails"
              handleChange={e => {
                const value = e.target.value.toLowerCase().trim();
                setFieldValue(e.target.name, value);
                setEmailAddresses(value);
              }}
              fieldClass="data-hj-whitelist"
              is="textarea"
              rows="5"
              component={FieldWrapper} />
            <div className="d-flex justify-content-between align-items-center">
              <LoaderButton className="text-nowrap" variant="primary" type="button" onClick={() => sendInvites(values.emails, setFieldValue)} loading={emailsStatus.isSending} loadingLabel={t('common:status.sending')} disabled={(!values.emails || (emailsStatus.status == 'error' || emailsStatus.status == 'exists')) ? 1 : 0}>
                {t('common:buttons.sendInvites')}
              </LoaderButton>
              <p className="text-info d-flex mb-0 px-4">
                {values.emails && emailsStatus.status == 'exists' && <><i className="fas fa-ban text-lg text-warning mr-2"></i> {t('inviteTeamMembers.form.fields.emails.exists', { exists: emailsStatus.email_exists })}</>}
                {values.emails && emailsStatus.status == 'error' && <><i className="fas fa-ban text-lg text-warning mr-2"></i> {t('inviteTeamMembers.form.fields.emails.error')}</>}
                {values.emails && emailsStatus.status == 'pending' && <><i className="fas fa-exclamation-circle text-lg text-primary mr-2"></i> {t('inviteTeamMembers.form.fields.emails.info')}</>}
                {emailsStatus.status == 'sent' && <><i className="fas fa-check text-lg text-success mr-2"></i> {t('inviteTeamMembers.form.fields.emails.sent')}</>}
              </p>
              <LoaderButton className="p-0 text-decoration-none" variant="link" type="submit" loading={isSubmitting} disabled={Object.keys(errors).length || (values.emails && (emailsStatus.status == 'error' || emailsStatus.status == 'pending')) ? 1 : 0}>
                {t('common:buttons.continue')}
                <i className="sila-icon arrow-right ml-2"></i>
              </LoaderButton>
            </div>
          </Form>);
        }}
      </Formik>
    </Container>
  );
};

export default InviteTeamMembers;