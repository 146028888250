import React, { useState, useEffect, useRef } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import FieldWrapper from '../form/wrappers/Field';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';

import { useDebounce } from '../../utils/hooks';

import { authApi } from '../../api';

import { useApp } from '../context';

const TeamDataModal = ({ show, onHide }) => {
  const [teamName, setTeamName] = useState('');
  const debouncedTeamName = useDebounce(teamName, 750);
  const { app, updateApp } = useApp();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const initialValues = {
    team_name: ''
  };

  const handleHide = (data) => {
    formRef.current.resetForm();
    data ? onHide(data) : onHide();
  };

  const handleChange = (e) => {
    setTeamName(e.target.value);
    if (formRef.current.touched[e.target.name]) formRef.current.setFieldTouched(e.target.name, false);
  };

  const checkTeamName = async (value) => {
    if (value && formRef.current) {
      formRef.current.setStatus({ pending: true });
      formRef.current.setFieldTouched('team_name', false);
      updateApp({ validation: { ...app.validation, checkTeamName: { isValid: false } } });
      try {
        const response = await authApi.checkTeamName(value);
        const isValidStatus = response && response.success === true ? true : false;
        formRef.current.setStatus({ isValid: isValidStatus, pending: false });
        updateApp({ validation: { ...app.validation, checkTeamName: { isValid: isValidStatus } } });
      } catch (error) {
        formRef.current.setStatus({ isValid: false, pending: false });
        updateApp({ validation: { ...app.validation, checkTeamName: { isValid: false, error: error } } });
      } finally {
        formRef.current.setFieldTouched('team_name', true);
      }
    };
  };

  useEffect(() => {
    if (debouncedTeamName) checkTeamName(debouncedTeamName);
  }, [debouncedTeamName]);

  return (
    <Modal centered id="team-modal"
      backdrop="static"
      show={show}
      onHide={handleHide}
      aria-labelledby="team-modal-title">
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          team_name: yup.string().trim()
            .required(t('common:form.errors.required', { field: t('common:form.fields.teamName.label') }))
            .max(56, t('common:form.errors.max', { count: 56 }))
            .test('checkTeamName', t('common:form.fields.teamName.exists') || t('common:status.validating'), (value) => app.validation.checkTeamName.isValid && value)
        })}
        onSubmit={(values) => {
          onHide(values.team_name);
        }}>
        {({ errors, handleSubmit, dirty, isSubmitting, status, touched }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title as="h3" id="team-modal-title">{t('teams.modal.headers.create')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Field required autofocus className="teamName"
                id="createTeamForm.team_name"
                label={t('common:form.fields.teamName.label')}
                name="team_name"
                maxLength={90}
                handleChange={handleChange}
                isValid={status && status.isValid && !status.pending && touched.team_name}
                component={FieldWrapper}
                append={status && status.pending ? <Loader size="sm" className="mr-3" /> : undefined} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-light" onClick={(e) => { e.preventDefault(); handleHide(); }} disabled={isSubmitting}>{t('common:buttons.cancel')}</Button>
              <LoaderButton type="submit" loading={isSubmitting} disabled={(status && status.pending) || !status || !dirty || Object.keys(errors).length}>{t('teams.modal.form.buttons.create')}</LoaderButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

TeamDataModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default TeamDataModal;