import React, { useState, useRef, useEffect } from 'react';
import { Form, Overlay, Tooltip, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const FormGroupWrapper = (props) => {
  const { id, note, name, className, type, form: { touched, errors, values, validateField }, children, autofocus, material, append, inputRef, readOnly, plaintext, error, handleBlur, handleFocus, hideError, disabled, required, showRequired, multiple, ...attrs } = props;
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [show, setShow] = useState(false);

  const hasError = !hideError && getIn(touched, name) && getIn(errors, name) || !hideError && getIn(touched, name) && error;

  const inputEl = useRef(null);
  const errorRef = useRef(null);
  const classes = classNames(
    className,
    type,
    children.props.size && children.props.size,
    disabled && 'disabled',
    readOnly && 'readonly',
    plaintext && 'plaintext',
    autofocus && 'autofocus',
    material && 'material',
    required && 'required',
    multiple && type === 'select' && 'select-multiple',
    (focused || hasError) && 'focused',
    hasError && 'err'
  );

  useEffect(() => {
    if (hasError) setShow(true);
  }, [hasError]);

  useEffect(() => {
    let showTimer;
    if (show) showTimer = setTimeout(() => setShow(false), 3000);
    return () => clearTimeout(showTimer);
  }, [show]);

  return (
    <Form.Group controlId={id} className={classes} {...attrs}>
      {React.cloneElement(children, {
          inputRef: inputRef || inputEl,
          focused,
          hasError,
          error: getIn(errors, name) || error,
          append: append || hasError ?
            <>
              {append || null}
              {!readOnly && hasError &&
                <>
                  <Button ref={errorRef} onClick={() => setShow(!show)} variant="danger" className="ml-2 btn-err btn-circle-tiny">
                    <i className="fas fa-exclamation"></i>
                  </Button>
                  <Overlay target={errorRef.current} show={show} placement="top">
                    <Tooltip className="err-msg mb-1">{getIn(errors, name) || error}</Tooltip>
                  </Overlay></>}
            </> : null,
          handleBlur: (e) => { setFocused(false); if (children.props.validate) validateField(e.target.name); if (handleBlur) handleBlur(e); },
          handleFocus: (e) => { setFocused(true); if (handleFocus) handleFocus(e); }
        })}
        {required && showRequired && !getIn(values, name) && !hasError && <span className="text-primary text-lg font-italic position-absolute position-top position-right mt-1 mr-2">*</span>}
      {note && <Form.Text className="text-info">{note}</Form.Text>}
    </Form.Group>
  );
}

FormGroupWrapper.propTypes = {
  /**
   * The name of the field (used to identify errors)
   */
  name: PropTypes.string.isRequired,
  /**
   * The form object passed down from FormIk
   */
  form: PropTypes.object.isRequired
};

export default FormGroupWrapper;