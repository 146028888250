import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Nav } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import userMenuNavItems from '../../../../constants/userMenuNavItems';
import { useAuth } from '../../../context';
import { getInitials } from '../../../../utils';

import Collapse from '../../../common/Collapse';
import LogoutModal from '../../../auth/LogoutModal';

const UserActions = () => {
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const fullName = user.entity.full_name || `${user.entity.first_name} ${user.entity.surname}`;

  const handleHide = (logout) => {
    if (logout) history.push('/logout');
    setShow(false);
  };

  return (
    <>
      <Dropdown id="user-menu" className="user" as={Nav.Item} onToggle={() => setVisible(!visible)}>
        <Dropdown.Toggle id="user-menu-toggle" as={Nav.Link} className="text-nowrap text-center">
          <span className="border-left p-0 pl-4 user">
            {user.avatar ?
              <img
                className="user-avatar rounded-circle mr-0 mr-md-2"
                src={user.avatar}
                alt={fullName}
                height="27" />
              : <span className="bg-primary-accent rounded-sm p-2 text-xs mr-0 mr-md-2">{getInitials(fullName)}</span>}
            <span className="d-none d-md-inline-block">{fullName}</span>
          </span>
        </Dropdown.Toggle>
        <Collapse as={Dropdown.Menu} className="justify-content-end dropdown-menu-small" show={visible}>
          {userMenuNavItems.map((item, index) =>
            <Dropdown.Item key={index} className={item.classes || undefined} as={item.to ? Link : undefined} to={item.to ? { pathname: item.to, state: { from: location.pathname } } : undefined} href={item.href || undefined} target={item.href ? '_blank' : undefined}>
              {item.htmlBefore && (
                <span
                  className="d-inline-block item-icon-wrapper mr-2"
                  dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                />
              )}
              {item.title && <span>{t(item.title)}</span>}
              {item.htmlAfter && (
                <span
                className="d-inline-block item-icon-wrapper ml-2"
                  dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                />
              )}
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => setShow(true)} className="text-danger">
            <i className="fas fa-sign-out-alt text-danger mr-2"></i> {t('user.menu.logout')}
          </Dropdown.Item>
        </Collapse>
      </Dropdown>
      <LogoutModal show={show} onHide={handleHide} />
    </>
  );
};

export default UserActions;
