/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Cookies from 'js-cookie';
import consoleApi from './console';

import {
  handleResponseError,
  removeEmpty,
  convertToSila,
  formatLimitsResponse,
  formatLimitResponse,
  formatLimitQuery,
} from '../utils';

const currentTeam = parseInt(Cookies.get('sila_console_team'), 10);

const fetchConsoleUsers = async (options) => {
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query || '';
  try {
    const response = await consoleApi.get({
      url: `/superuser/console_users?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getConsoleUser = async (handle) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/console_users/${handle}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateConsoleUser = async (handle, values) => {
  if (values.first_name && values.last_name) {
    values.full_name = `${values.first_name} ${values.last_name}`;
  }
  try {
    const response = await consoleApi.put({
      url: `/superuser/console_users/${handle}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchEndUsers = async (options) => {
  const defaultSort = '&sort_keys=last_verification_time_desc';
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('last_verification_time')
    ? options.query
    : `${options.query}${defaultSort}`;
  try {
    const response = await consoleApi.get({
      url: `/superuser/end_users?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getEndUser = async (handle) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/end_users/${handle}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateEndUser = async (handle, values) => {
  if (values.first_name && values.last_name) {
    values.full_name = `${values.first_name} ${values.last_name}`;
  }
  if (values.identities && values.identities.length) {
    values.identities = values.identities
      .map((identity) =>
        identity.action === 'add'
          ? { ...identity, identity_type: values.entity_type === 'business' ? 'EIN' : 'SSN' }
          : identity
      )
      .filter((value) => !value.identity.includes('*'));
  }
  try {
    const response = await consoleApi.put({
      url: `/superuser/end_users/${handle}`,
      data: values,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getEndUserDocuments = async (handle) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/end_users/${handle}/documents`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getDocumentTypes = async () => {
  try {
    const response = await consoleApi.get({
      url: '/document_types',
      data: {
        per_page: 100,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getEndUserDocument = async (handle, id) => {
  try {
    const response = await consoleApi.get({
      binary: true,
      notracking: true,
      url: `/superuser/end_users/${handle}/documents/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getVerificationStatuses = async () => {
  try {
    const response = await consoleApi.get({
      url: '/verification_statuses',
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getKycLevels = async () => {
  try {
    const response = await consoleApi.get({
      url: '/kyc_levels',
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchTransactions = async (options) => {
  const defaultSort = '&sort_keys=created_desc';
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('created')
    ? options.query
    : `${options.query}${defaultSort}`;
  try {
    const response = await consoleApi.get({
      url: `/superuser/transactions?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getTransaction = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/transactions/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getWalletBalance = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/get_wallet_balance/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchApps = async (options) => {
  const defaultSort = '&sort_keys=created_desc';
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('created')
    ? options.query
    : `${options.query}${defaultSort}`;
  try {
    const response = await consoleApi.get({
      url: `/superuser/apps?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateAppSalesTier = async (appHandle, values) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/apps/${appHandle}`,
      data: {
        sales_tier_id: values.id,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getApplication = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/apps/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchSalesTiers = async (options) => {
  try {
    const response = await consoleApi.get({
      url: '/sales_tiers',
      data: {
        limit: options.limit || 20,
        page: options.page || 1,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getSalesTier = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/sales_tier/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createSalesTier = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/sales_tier',
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateSalesTier = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/sales_tier/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteSalesTier = async (values) => {
  try {
    const response = await consoleApi.delete({
      url: `/sales_tier/${values.id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateSalesTierLimits = async (values, types, sales_tier_id) => {
  try {
    const response = await Promise.all(
      values.map((value) => {
        const { type, id, status, kyc_level, amount, ...data } = value;
        if (amount) data.amount = convertToSila(amount);
        data.kyc_level_id = kyc_level;
        return consoleApi[status]({
          url: `/${types.find((t) => t.key === type).endpoint}${
            status === 'put' || status === 'delete' ? `/${id}` : ''
          }`,
          data: status === 'post' ? { ...data, sales_tier_id } : data,
        });
      })
    );
    if (response.some((res) => !res.success)) {
      response.forEach((res) => {
        if (!res.success) throw handleResponseError(res);
      });
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getEndUserPaymentInstruments = async (options) => {
  const { handle } = options;
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query || '';
  const team = options.team || currentTeam;
  try {
    const response = await consoleApi.get({
      url: `/superuser/payment_instruments?handle=${handle}&team_id=${team}&limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const paymentInstrumentFreezeUnfreeze = async (
  freeze,
  payment_instrument_id,
  team_id,
  force_freeze,
  freeze_reason
) => {
  try {
    const response = await consoleApi.put({
      url: '/superuser/payment_instruments',
      data: removeEmpty({
        freeze: freeze,
        payment_instrument_id: payment_instrument_id,
        team_id: team_id,
        force_freeze: force_freeze,
        freeze_reason: freeze_reason,
      }),
    });
    if (!response.success && !(response.status_code === 400 || response.status_code === 409)) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getPaymentInstrument = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/payment_instrument/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchCustomerOnboard = async (options) => {
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query || '';
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomerInfo = async (id) => {
  try {
    const detailsResponse = await Promise.all([
      consoleApi.get({
        url: `/superuser/customer_onboard_details/${id}`,
      }),
      consoleApi.get({
        url: `/superuser/customer_onboard_info/${id}`,
      }),
      consoleApi.get({
        url: '/superuser/customer_onboard_owner?limit=100',
      }),
    ]);
    if (detailsResponse.some((response) => !response.success)) {
      detailsResponse.forEach((response) => {
        if (!response.success) throw handleResponseError(response);
      });
    }
    return {
      details: detailsResponse[0].customer_onboard_details,
      info: {
        ...detailsResponse[1].customer_onboard_info,
        assignee: detailsResponse[1].customer_onboard_info.assignee.handle,
        kyc_levels_list: detailsResponse[1].available_kyc_levels || [],
        payment_status_list: detailsResponse[1].available_payment_status || [],
        products_list: detailsResponse[1].available_products || [],
      },
      owners: detailsResponse[2].owners,
    };
  } catch (error) {
    throw error;
  }
};

const updateCustomerInfo = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/customer_onboard_info/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomerInfoDocuments = async (customer) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard_info_documents/${customer}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomerInfoDocument = async (customer, id) => {
  try {
    const response = await consoleApi.get({
      binary: true,
      notracking: true,
      url: `/superuser/customer_onboard_info_documents/${customer}/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchCustomerChecklistTemplates = async (options) => {
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query || '';
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard_checklist?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomerChecklistInstance = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard_checklist_instance/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createCustomerChecklistInstance = async (id, template) => {
  try {
    const response = await consoleApi.post({
      url: `/superuser/customer_onboard_checklist_link/${id}/${template.id}`,
      data: {
        name: template.name,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCustomerChecklistInstance = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/customer_onboard_checklist_instance/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteCustomerChecklistInstance = async (id) => {
  try {
    const response = await consoleApi.delete({
      url: `/superuser/customer_onboard_checklist_instance/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchCustomerChecklists = async (id, options) => {
  const limit = options.limit || 20;
  const page = options.page || 1;
  try {
    const response = await consoleApi.get({
      url: `/superuser/checklist_instances_by_customer/${id}?limit=${limit}&page=${page}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchCustomerChecklistOwners = async (options) => {
  const limit = options.limit || 20;
  const page = options.page || 1;
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard_owner?limit=${limit}&page=${page}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomerChecklistOwner = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard_owner/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createCustomerChecklistOwner = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/customer_onboard_owner',
      data: values,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCustomerChecklistOwner = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/customer_onboard_owner/${id}`,
      data: values,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteCustomerChecklistOwner = async (id) => {
  try {
    const response = await consoleApi.delete({
      url: `/superuser/customer_onboard_owner/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomerChecklistTemplate = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard_checklist/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteCustomerChecklistTemplate = async (id, _tasks) => {
  try {
    const response = await consoleApi.delete({
      url: `/superuser/customer_onboard_checklist/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createCustomerChecklistTask = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/customer_onboard_task',
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCustomerChecklistTask = async (id, values) => {
  if (values.completed_status) values.completed_status = parseInt(values.completed_status, 10);
  try {
    const response = await consoleApi.put({
      url: `/superuser/customer_onboard_task/${id}`,
      data: values,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCustomerChecklistTemplate = async (values, id) => {
  const requests = [];
  const request = {};
  let response = {};
  if (values.tasks && values.tasks.filter((task) => !task.id).length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const task of values.tasks.filter((task) => !task.id)) {
      requests.push({
        uuid: task.uuid,
        request: consoleApi.post({
          url: '/superuser/customer_onboard_task',
          data: {
            name: task.name,
          },
        }),
      });
    }
  }
  if (requests.length) {
    try {
      const tasksResponse = await Promise.all(requests.map((result) => result.request));
      tasksResponse.forEach((response, index) => {
        if (!response.success) {
          throw handleResponseError(response);
        } else {
          values.tasks = values.tasks.map((task) =>
            task.uuid && task.uuid === requests[index].uuid
              ? { ...task, id: response.task.id }
              : task
          );
        }
      });
      if (id) {
        request.add = tasksResponse.map((response) => response.task.id);
      } else {
        request.tasks = tasksResponse.map((response) => response.task.id);
      }
    } catch (error) {
      throw error;
    }
  }
  if (values.name) request.name = values.name;
  if (values.deleted) request.delete = values.deleted;
  if (id) {
    try {
      const updateResponse = await consoleApi.put({
        url: `/superuser/customer_onboard_checklist/${id}`,
        data: {
          order: values.tasks.map((task, index) => ({ id: task.id, position: index + 1 })),
          ...request,
        },
      });
      if (!updateResponse.success) {
        throw handleResponseError(updateResponse);
      }
      response = updateResponse;
    } catch (error) {
      throw error;
    }
  } else {
    try {
      const postResponse = await consoleApi.post({
        url: '/superuser/customer_onboard_checklist',
        data: request,
      });
      if (!postResponse.success) {
        throw handleResponseError(postResponse);
      }
      response = postResponse;
    } catch (error) {
      throw error;
    }
  }
  return response;
};

const createCustomerChecklist = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/customer_onboard_checklist',
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchDepartments = async () => {
  try {
    const response = await consoleApi.get({
      url: '/superuser/department',
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCustomerOnboardComments = async (id, sectionId, taskId) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/customer_onboard_comments/${id}/${sectionId}${
        taskId ? `?task_id=${taskId}` : ''
      }`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createCustomerOnboardComment = async (id, sectionId, values) => {
  try {
    const response = await consoleApi.post({
      url: `/superuser/customer_onboard_comments/${id}/${sectionId}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateAdminRequestReturn = async (uuid, values) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/action_reverse_virtual_account_ach_transaction/${uuid}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateAdminRequestReturnSubmitted = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/reverse_virtual_account_ach_transaction',
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const setVaccountPrefix = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/provision_vaccount_prefix',
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const setVaccountLimit = async (values) => {
  try {
    const response = await consoleApi.post({
      url: `/superuser/apps/${values.app_id}/app_settings`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getVaccountPrefix = async () => {
  try {
    const response = await consoleApi.get({
      url: '/superuser/get_available_vaccount_prefix',
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createInstantSettlement = async (id, values) => {
  try {
    const response = await consoleApi.post({
      url: `/superuser/instant_settlement/${id}`,
      data: removeEmpty(values),
    });

    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateInstantSettlement = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/instant_settlement/${id}`,
      data: removeEmpty(values),
    });

    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createCompanyWallet = async (id, values) => {
  try {
    const response = await consoleApi.post({
      url: `/superuser/apps/${id}/setup_company_wallet`,
      data: removeEmpty(values),
    });

    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const saveStatement = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/business_statement_information',
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateStatement = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/business_statement_information/${id}`,
      data: removeEmpty(values),
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteStatementLogo = async (id, team = currentTeam) => {
  try {
    const response = await consoleApi.delete({
      url: `/superuser/${id}/logo_delete`,
      data: {
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchCompanyWallet = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/apps/${id}/get_company_wallets`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getCkoCredentials = async (id) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/cko_credentials',
      data: {
        app_id: id,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCkoCredentials = async (id, values, method) => {
  if (method === 'post') values = removeEmpty(values);
  try {
    const response = await consoleApi[method]({
      url: '/superuser/cko_credentials',
      data: {
        app_id: id,
        ...values,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProviderWebhooks = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/provider_webhooks/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateProviderWebhooks = async (id, values, method) => {
  values = removeEmpty(values);
  try {
    const response = await consoleApi[method]({
      url: '/superuser/provider_webhook',
      data: {
        app_id: id,
        ...values,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteProviderWebhooks = async (id, values) => {
  try {
    const response = await consoleApi.delete({
      url: '/superuser/provider_webhook',
      data: {
        app_id: id,
        ...values,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const userFreezeUnfreeze = async (freeze, freeze_reason, handle) => {
  try {
    const response = await consoleApi.put({
      url: '/superuser/freeze_user',
      data: {
        freeze: freeze,
        freeze_reason: freeze_reason,
        handle: handle,
      },
    });
    if (!response.success && !(response.status_code === 400 || response.status_code === 409)) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const cancelACHTransactions = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/superuser/cancel_transaction',
      data: values,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const appFreezeUnfreeze = async (appId, freeze, freeze_reason) => {
  try {
    const response = await consoleApi.put({
      url: `/superuser/apps/${appId}`,
      data: {
        freeze: freeze,
        freeze_reason: freeze_reason,
      },
    });
    // 400 indicates an app cant be unfrozen for the given reason
    // 409 indicates an app is not frozen, so cant be unfroze
    if (!response.success && !(response.status_code === 400 || response.status_code === 409)) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchLimits = async (options) => {
  const defaultSort = '&sort_keys=begins_at_desc';
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('sort_keys')
    ? options.query
    : `${options.query}${defaultSort}`;
  try {
    const response = await consoleApi.get({
      url: `/superuser/limits?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    formatLimitsResponse(response);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchLimit = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/limits/${id}`,
    });
    if (!response.success || response.status_code !== 200) {
      throw handleResponseError(response);
    }

    formatLimitResponse(response.limit);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateLimit = async (id, data) => {
  formatLimitQuery(data);
  try {
    const response = await consoleApi.put({
      url: `/superuser/limits/${id}`,
      data: data,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }

    formatLimitResponse(response.limit);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteLimit = async (id) => {
  try {
    const response = await consoleApi.delete({
      url: `/superuser/limits/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

const createLimit = async (data) => {
  const requestData = {};
  Object.entries(data).forEach(([key, val]) => {
    if (val !== '') requestData[key] = val;
  });
  formatLimitQuery(requestData);

  try {
    const response = await consoleApi.post({
      url: '/superuser/limits',
      data: requestData,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }

    formatLimitResponse(response.limit);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchLimitEnums = async () => {
  try {
    const urls = {
      transactionGraphLabel: '/superuser/enums/transaction_graph_labels?selector=limit',
      limitType: '/superuser/enums/limit_type',
      userCategory: '/superuser/enums/user_category',
      providerNames: '/superuser/enums/provider_names?selector=limit',
    };

    const requests = Object.entries(urls).map(([key, url]) =>
      consoleApi.get({ url }).then((response) => {
        if (!response.success) {
          throw handleResponseError(response);
        }
        return { [key]: response.enum };
      })
    );

    const detailsResponse = await Promise.all(requests);

    const result = detailsResponse.reduce((acc, obj) => ({ ...acc, ...obj }), { success: true });

    return result;
  } catch (error) {
    throw error;
  }
};

const fetchLimitOverrides = async (options) => {
  const defaultSort = '&sort_keys=begins_at_desc';
  const limit = options.limit || 20;
  const page = options.page || 1;
  const query = options.query.includes('sort_keys')
    ? options.query
    : `${options.query}${defaultSort}`;

  try {
    const response = await consoleApi.get({
      url: `/superuser/limit_overrides?limit=${limit}&page=${page}${query}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    formatLimitsResponse(response);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchLimitOverride = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/superuser/limit_overrides/${id}`,
    });
    if (!response.success || response.status_code !== 200) {
      throw handleResponseError(response);
    }

    formatLimitResponse(response.limit);
    return response;
  } catch (error) {
    throw error;
  }
};

const updateLimitOverride = async (id, data) => {
  formatLimitQuery(data);
  try {
    const response = await consoleApi.put({
      url: `/superuser/limit_overrides/${id}`,
      data: data,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }

    formatLimitResponse(response.limit);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteLimitOverride = async (id) => {
  try {
    const response = await consoleApi.delete({
      url: `/superuser/limit_overrides/${id}`,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

const createLimitOverride = async (data) => {
  const responseData = {};
  Object.entries(data).forEach(([key, val]) => {
    if (val !== '') responseData[key] = val;
  });
  formatLimitQuery(responseData);

  try {
    const response = await consoleApi.post({
      url: `/superuser/limit_overrides`,
      data: responseData,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }

    formatLimitResponse(response.limit);
    return response;
  } catch (error) {
    throw error;
  }
};

const adminApi = {
  fetchConsoleUsers,
  getConsoleUser,
  updateConsoleUser,
  fetchEndUsers,
  getEndUser,
  updateEndUser,
  getEndUserDocuments,
  getDocumentTypes,
  getEndUserDocument,
  getVerificationStatuses,
  getKycLevels,
  fetchTransactions,
  getTransaction,
  getWalletBalance,
  fetchApps,
  updateAppSalesTier,
  getApplication,
  fetchSalesTiers,
  getSalesTier,
  createSalesTier,
  updateSalesTier,
  updateSalesTierLimits,
  deleteSalesTier,
  getEndUserPaymentInstruments,
  paymentInstrumentFreezeUnfreeze,
  fetchCustomerOnboard,
  fetchCustomerChecklistTemplates,
  getCustomerChecklistTemplate,
  deleteCustomerChecklistTemplate,
  updateCustomerChecklistTemplate,
  getCustomerChecklistInstance,
  createCustomerChecklistInstance,
  updateCustomerChecklistInstance,
  deleteCustomerChecklistInstance,
  fetchCustomerChecklists,
  fetchCustomerChecklistOwners,
  getCustomerChecklistOwner,
  createCustomerChecklistOwner,
  updateCustomerChecklistOwner,
  deleteCustomerChecklistOwner,
  createCustomerChecklistTask,
  updateCustomerChecklistTask,
  createCustomerChecklist,
  getCustomerInfo,
  updateCustomerInfo,
  getCustomerInfoDocuments,
  getCustomerInfoDocument,
  fetchDepartments,
  getCustomerOnboardComments,
  createCustomerOnboardComment,
  updateAdminRequestReturn,
  updateAdminRequestReturnSubmitted,
  setVaccountPrefix,
  setVaccountLimit,
  getVaccountPrefix,
  createInstantSettlement,
  updateInstantSettlement,
  createCompanyWallet,
  getPaymentInstrument,
  saveStatement,
  updateStatement,
  deleteStatementLogo,
  getCkoCredentials,
  updateCkoCredentials,
  getProviderWebhooks,
  updateProviderWebhooks,
  deleteProviderWebhooks,
  fetchCompanyWallet,
  userFreezeUnfreeze,
  cancelACHTransactions,
  appFreezeUnfreeze,
  fetchLimit,
  updateLimit,
  deleteLimit,
  createLimit,
  fetchLimits,
  fetchLimitEnums,
  fetchLimitOverrides,
  fetchLimitOverride,
  updateLimitOverride,
  deleteLimitOverride,
  createLimitOverride,
};

export default adminApi;
