const subDomain = window.location.href.split(".")[0];

export const TRANSITION_KEYS = ['in', 'mountOnEnter', 'unmountOnExit', 'appear', 'enter', 'exit', 'timeout', 'onEnter', 'onEntering', 'onEntered', 'onExit', 'onExiting', 'onExited'];

export const TRANSITION_STATUS = {
  ENTERING: 'entering',
  ENTERED: 'entered',
  EXITING: 'exiting',
  EXITED: 'exited'
};

export const TRANSITION_CLASS_MAP = {
  [TRANSITION_STATUS.ENTERING]: 'collapsing',
  [TRANSITION_STATUS.ENTERED]: 'collapse show',
  [TRANSITION_STATUS.EXITING]: 'collapsing',
  [TRANSITION_STATUS.EXITED]: 'collapse'
};

export const TYPEFORM_REGISTER_QUESTIONNAIRE_FORM = 'https://form.typeform.com/to/t4UhZ2';

export const STATES_ARRAY = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export const FREEZE_REASONS_ARRAY = ['return', 'by_customer', 'instant_settlement_return', 'by_sila', 'bank_account_fuzzy_match_failure', 'fraud', 'shutdown']

export const IDLE_TIMEOUT = 900000;

export const REDIRECT_ROUTES = {
  USER_HOME: '/welcome',
  USER_FROZEN: '/error/frozen',
  USER_PENDING: '/error/pending',
  GUEST_HOME: '/register'
};

export const PRIVACY_POLICY_URL = 'https://www.silamoney.com/legal/privacy-policy';

export const TERMS_OF_SERVICE_URL = 'https://www.silamoney.com/legal/terms-of-service';

export const SDK_AGREEMENT_URL = 'https://www.silamoney.com/legal/sdk-license-agreement';

export const SIGNUP_STEP_COMPLETED = {
  STEP1: 'account-created',
  STEP2: 'team-created',
  STEP3: 'questionnaire'
};

export const START_SILA_JOURNEY_URL = 'https://docs.silamoney.com/docs/getting-started';

export const SILA_MARKET_PLACE_URL = 'https://www.silamoney.com/ecosystem';

export const SILA_SECURITY_REQUIREMENTS_URL = 'https://docs.silamoney.com/docs/info-management-security-requirements';

export const RETURN_RATES_DOCS_URL = 'https://docs.silamoney.com/docs/ach-return-codes#ach-return-rate-limits';

export const RETURN_CODES_DOCS_URL = 'https://docs.silamoney.com/docs/ach-return-codes#ach-return-codes'

export const NACHA_DOCS_URL = 'https://www.nacha.org/rules/ach-network-risk-and-enforcement-topics';

export const API_DEMO_URL = 'https://www.silamoney.com/live-demo?utm_source=console_promo&utm_medium=banner_graphic';

export const SUPPORT_URL = 'https://sila.atlassian.net/servicedesk/customer/portal/2/group/1';

export const SUPPORT_REQUEST_URL = 'https://sila.atlassian.net/servicedesk/customer/portal/2/group/3/create/9';

export const REQUEST_PROD_URL = 'https://sila.atlassian.net/servicedesk/customer/portal/2/group/3/create/11';

export const PHONE_REG_EXP = /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/;

export const SSN_REG_EXP = /^(?!000|666)[0-9]{3}([ -]?)(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/;

export const EIN_REG_EXP = /^(0[1-6]||1[0-6]|2[0-7]|[345]\d|[68][0-8]|7[1-7]|9[0-58-9])-?\d{7}$/;

export const UUID_REG_EXP = /^[0-9a-f]{8}-?[0-9a-f]{4}-?[0-5][0-9a-f]{3}-?[089ab][0-9a-f]{3}-?[0-9a-f]{12}$/i

export const POSTAL_REG_EXP = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const HANDLE_REG_EXP = /^([a-zA-Z0-9._-]+)$/;

export const LETTERS_UNDERSCORES_REG_EXP = /^([a-zA-Z_]+)$/;

export const HANDLE_EMAIL_REG_EXP = /^[a-zA-Z0-9@+._-]{3,}$/;

export const EMAIL_REG_EXP = /^\S+@\S+\.\S+$/;

export const BLOCKCHAIN_ADDRESS_REG_EXP = /^0x[a-fA-F0-9]{40}$/;

export const URL_REG_EXP = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const NUMBER_REG_EXP = /^[0-9]*$/;

export const SPACES_REG_EXP =  /^\s*\S[\s\S]*$/;

export const CLEARBIT_SEARCH_URI = 'https://autocomplete.clearbit.com/v1/companies/suggest?query=:';

export const MFA_NUMBER_OF_INPUTS = 6;

export const MFA_MAX_FAILED_ATTEMPTS = 5

export const MFA_RESEND_CODE_THRESHOLD = 3 // in number of minutes.

export const VACCOUNTS_PREFIX_WARNING_COUNT = 100;

export const VACCOUNTS_COMPLIANCE_URL = 'https://www.silamoney.com/customer-compliance';

export const WEBHOOK_TROUBLESHOOTING_URL = 'https://docs.silamoney.com/docs/webhook-troubleshooting';

export const DEFAULT_EMAIL = [{
  email: '',
  action: 'add'
}];

export const DEFAULT_IDENTITY = [{
  identity: '',
  action: 'add'
}];

export const DEFAULT_PHONE = [{
  phone: '',
  action: 'add'
}];

export const DEFAULT_ADDRESS = [{
  action: 'add',
  street_address_1: '',
  street_address_2: null,
  city: '',
  state: '',
  postal_code: ''
}];

export const TRANSACTION_PROCESSING_TYPES = ['SAME_DAY_ACH', 'STANDARD_ACH', 'INSTANT_ACH', 'NON_ACH', 'CARD', 'INSTANT_SETTLEMENT'];

export const FILE_NAME_MAX_LENGTH = '96';

export const MAX_FILE_SIZE = 20971520;

export const NACHA_LIMITS = {
  unauth: '0.5',
  admin: '3.0',
  total: '15'
};

export const SILA_LIMITS = {
  unauth: '0.4',
  admin: '2.5',
  total: '14'
};

export const RETURN_CODES = ['R05', 'R08', 'R10', 'R11', 'R17', 'R22', 'R23', 'R29', 'R31', 'R37'];

export const REQUEST_RETURN_PENDING_REVIEW = 'pending_review';
export const REQUEST_RETURN_OPEN = 'open';
export const REQUEST_RETURN_REQUEST_FOR_WITHDRAW = 'request_for_withdraw';
export const REQUEST_RETURN_DENIED = 'denied';
export const REQUEST_RETURN_WITHDRAWN = 'withdrawn';
export const REQUEST_RETURN_SUBMITTED = 'submitted';
export const REQUEST_RETURN_COMPLETED = 'completed';

export const REQUEST_RETURN_STATUS = {
  'pending_review': [{
      value: 'pending_review',
      label: 'Pending Review'
    },
    {
      value: 'submitted',
      label: 'Submitted'
    },
    {
      value: 'open',
      label: 'Open'
    },
    {
      value: 'denied',
      label: 'Denied'
    }
  ],
  'request_for_withdraw': [{
      value: 'request_for_withdraw',
      label: 'Request For Withdraw'
    },
    {
      value: 'withdrawn',
      label: 'Withdrawn'
    }
  ],
  'denied': [{
      value: 'denied',
      label: 'Denied'
    },
    {
      value: 'open',
      label: 'Open'
    }
  ],
  'open': [{
    value: 'open',
    label: 'Open'
  }],
  'submitted': [{
    value: 'submitted',
    label: 'Submitted'
  }],
  'withdrawn': [{
    value: 'withdrawn',
    label: 'Withdrawn'
  }],
  'completed': [{
    value: 'completed',
    label: 'Completed'
  }]
};

export const SUPERUSER_LEVEL = {
  NO_ACCESS: 0,
  ONBOARDING_READ_ONLY: 1,
  ONBOARDING_READ_WRITE: 2,
  SALES: 2,
  FULL_ACCESS: 3
};

export const COOKIES_OPTIONS = {
  secure: true,
  samesite: 'lax'
};

export const AUTH_TYPE_JWT = 'jwt';

export const AUTH_TYPE_WALLET = 'ecdsa';

export const DEFAULT_PROVIDER = 'CKO';

export const PROVIDERS = [
  'PROCESS_STREET',
  'ALLOY',
  'MIDDESK',
  'ACUANT',
  'PLAID',
  'PLAID_LEGACY',
  'EVOLVE',
  'SARDINE',
  'MX',
  'CKO',
  'SYNAPSE',
  'ROSETTE',
  'MANUAL'
];

export const SLACK_EVENT_TRACKING_CHANNEL = subDomain.includes('local') || subDomain.includes('stage') || subDomain.includes('test') ? '#eng-arcgate' : '#fin-the-console-bot';

export const TRANS_QUEUED = 'queued';
export const TRANS_FAILED = 'failed';

export const MAX_LIMIT_AMOUNT = 5_000_000;
export const MAX_LIMIT_DURATION = 90;
export const MAX_LIMIT_EXPIRES_AFTER_COUNT = 100;
export const MAX_LIMIT_BEGINS_AT_DELTA_DAYS = 30;
export const MAX_LIMIT_EXPIRES_AT_DELTA_DAYS = 60;
