import React, { useState } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { Alert } from 'react-bootstrap';
import classNames from 'classnames';

import { useAlerts } from '../context';

const Alerts = () => {
  const { alerts, clearAlert } = useAlerts();
  const [refMap] = useState(() => new Map());

  const transitions = useTransition(alerts, {
    enter: (item) => async (next) => {
      await next({
        opacity: 1,
        height: refMap.get(item.id).clientHeight + 16,
      });
    },
    leave: (item) => async (next) => {
      refMap.delete(item.id);
      await next({ opacity: 0, height: 0 });
    },
    from: { opacity: 0, height: 0 },
  });

  const alert = transitions((style, item) => <animated.li key={item.id} style={style}>
    <div ref={(ref) => ref && refMap.set(item.id, ref)}>
      <Alert
        className="shadow d-flex align-items-center"
        key={item.id}
        variant={item.variant}
        onClick={() => clearAlert(item.id)}>
        <i className={classNames('mr-3 fas', (item.variant === 'danger' || item.variant === 'warning') ? 'fa-exclamation-circle' : item.variant === 'success' ? 'fa-check-circle' : 'fa-info-circle')}></i>
        {item.message}
        <i className="sila-icon close text-reg ml-3 cursor-pointer text-reset"></i>
      </Alert>
    </div>
  </animated.li>);

  return (
    <ul className="alerts list-unstyled m-4">
      {alert}
    </ul>
  );
};



export default Alerts;