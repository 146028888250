import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Button, Form, Card } from 'react-bootstrap';
import stickybits from 'stickybits';
import Cookies from 'js-cookie';

import { appsApi, webhooksApi } from '../../api';
import { hasPermissions } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import EndpointForm from '../../components/webhooks/EndpointForm';

const defaultApp = parseInt(Cookies.get('sila_endpoints_app'));

const WebhookEndpoint = ({ t, match, location, history, user, setAlert }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [apps, setApps] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [initialValues, setInitialValues] = useState({
    active: false,
    name: '',
    url: '',
    max_attempts: 5,
    retry_interval: 60,
    app_id: match.params.app !== 'new' ? match.params.app : defaultApp || '',
    app_nickname: '',
    triggers: [],
    alerts_email: ''
  });
  const [active, setActive] = useState(initialValues.active);
  const [name, setName] = useState(initialValues.name);
  const formRef = useRef(null);

  const getEndpoint = async () => {
    try {
      const [endpointResponse, alertsResponse] = await Promise.all([
        webhooksApi.getEndpoint(match.params.id, initialValues.app_id),
        webhooksApi.getWebhookAlerts(match.params.id)
      ]);
      if (endpointResponse.success && alertsResponse.success) {
        setInitialValues({ ...endpointResponse.endpoint, alerts_email: alertsResponse.webhook_endpoint_alerts.length ? alertsResponse.webhook_endpoint_alerts[0].email : '' });
        setName(endpointResponse.endpoint.name);
        setAlerts(alertsResponse.webhook_endpoint_alerts);
      } else {
        setRedirect(true);
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setRedirect(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setActive(initialValues.active);
    setName(initialValues.name);
  }, [initialValues]);

  useEffect(() => {
    const fetchPayloads = async () => {
      try {
        const response = await webhooksApi.fetchPayloads(triggers.filter(trigger => !trigger.payload));
        setTriggers(triggers.map(trigger => ({ ...trigger, payload: JSON.stringify(response.find(res => res.name === trigger.name).payload, null, '\t') })));
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        setRedirect(true);
      }
    };
    if (triggers.length && triggers.some(trigger => !trigger.payload)) fetchPayloads();
  }, [triggers]);

  useEffect(() => {
    const fetchAppsAndTriggers = async () => {
      try {
        const [appsResponse, triggersResponse] = await Promise.all([
          appsApi.fetchApps({ team: user.currentTeam.id, limit: 20 }),
          webhooksApi.getTriggers()
        ]);
        if (appsResponse.success && appsResponse.apps.length && triggersResponse.success) {
          setApps(appsResponse.apps.map(app => ({ name: app.name, id: app.id })));
          setTriggers(triggersResponse.triggers);
        } else {
          setRedirect(true);
        }
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        setRedirect(true);
      } finally {
        if (match.params.action === 'detail') {
          getEndpoint();
        } else {
          setIsLoading(false);
        }
      }
    };
    fetchAppsAndTriggers();
  }, [user.currentTeam]);

  useEffect(() => {
    if (redirect) history.push({ pathname: '/dev/webhooks', state: { from: location.pathname } });
  }, [redirect]);

  useEffect(() => {
    stickybits('.triggers .trigger-search', { useStickyClasses: true });
  }, []);

  return (
    <Container className="webhooks main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5 loaded">
        <PageTitle title={t(`webhooks.endpoints.headers.${match.url.includes('new') ? 'create' : 'detail'}`)}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {isLoading ? <Loader /> : <Card className="loaded">
        {match.params.action === 'detail' && <Card.Header className="d-flex justify-content-between p-4">
          <h3 className="m-0">{name}</h3>
          {hasPermissions(user, 'webhooks', 'Write') ?
            <Form>
              <Form.Check
                type="switch"
                id="endpoint-active"
                checked={active}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setActive(checked); 
                  formRef.current.setFieldValue('active', checked);
                }}
                label={t('common:status.active')}
              />
            </Form>
            : <p className={`text-lg text-${initialValues.active ? 'success' : 'warning'}`}>{t(`common:status.${initialValues.active ? 'active' : 'inactive'}`)}</p>}
        </Card.Header>}
        <Card.Body className="p-4">
          <EndpointForm
            formRef={formRef}
            initialValues={initialValues}
            setName={setName}
            triggers={triggers}
            apps={apps}
            alerts={alerts}
            setIsLoading={setIsLoading}
            getEndpoint={getEndpoint} />
        </Card.Body>
      </Card>}

    </Container>
  );
};

export default WebhookEndpoint;