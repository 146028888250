import React, { useRef, useState, useEffect } from 'react';
import { Form, Row, Col, Card, CardGroup, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { appsApi } from '../../api';
import { useAlerts } from '../context';

import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';
import LoaderButton from '../common/LoaderButton';

const TooltipAppend = ({ field }) => {
  const { t } = useTranslation();
  return <TooltipButton placement="top" variant="link" text={t(`applications.instantSettlement.form.fields.${field}.tooltipLabel`)} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>;
};

const InstantSettlementForm = ({ initialValues, onSave }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const formRef = useRef(null);
  const history = useHistory();

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (redirect) history.push({ pathname: '/apps', state: { from: location.pathname } });
  }, [redirect]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        crw_wallet_uuid: initialValues.crw_wallet_uuid,
        warning_balance: initialValues.warning_balance,
        minimum_balance: initialValues.minimum_balance,
        initial_reserve_balance: initialValues.initial_reserve_balance,
        crw_wallet_balance: initialValues.crw_wallet_balance
      }}
      innerRef={formRef}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const response = await appsApi.updateApp(initialValues.id, values);
          setAlert({ message: response.message, variant: 'warning' });
          if (onSave) onSave(initialValues.id, values);
        } catch (error) {
          setAlert({ message: error, variant: 'warning' });
        } finally {
          setSubmitting(false);
          setRedirect(true);
        }
      }}>
      {({ values, errors, dirty, handleSubmit, isSubmitting }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>

          <p className="text-muted">{t(`applications.instantSettlement.description.${initialValues.instant_settlement_enabled == 'enabled' ? 'enabled1' : 'disabled1'}`)}</p>
          <p className="text-muted">{t(`applications.instantSettlement.description.${initialValues.instant_settlement_enabled == 'enabled' ? 'enabled2' : 'disabled2'}`)}</p>

          <CardGroup className="my-4">
            <Card className="form-control border border-lightest rounded-sm rounded-right-0">
              <Card.Body className="p-0 d-flex align-items-center">
                <Form.Label className="mb-0">{t('applications.instantSettlement.form.fields.customerReserveWallet.label')}</Form.Label>
                <TooltipAppend field={'customerReserveWallet'} />
              </Card.Body>
            </Card>
            <Card className="form-control border border-lightest rounded-sm rounded-left-0">
              <Card.Body className="p-0 d-flex justify-content-between align-items-center">
                <span>{values.crw_wallet_balance || '---'}</span>
                <i className="fas fa-dollar-sign"></i>
              </Card.Body>
            </Card>
          </CardGroup>
          <Row className="mb-4">
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field required className="m-0"
                disabled
                tabIndex={1}
                id={`appEditInstantSettlementForm.${initialValues.id}.crw_wallet_uuid`}
                placeholder={t('applications.instantSettlement.form.fields.walletUuid.placeholder')}
                name="crw_wallet_uuid"
                label={<>{t('applications.instantSettlement.form.fields.walletUuid.label')} <TooltipAppend field={'walletUuid'} /></>}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field required className="m-0"
                disabled
                tabIndex={2}
                id={`appEditInstantSettlementForm.${initialValues.id}.initial_reserve_balance`}
                type="number"
                placeholder={t('applications.instantSettlement.form.fields.crwInitialFundingAmount.placeholder')}
                name="initial_reserve_balance"
                label={<>{t('applications.instantSettlement.form.fields.crwInitialFundingAmount.label')} <TooltipAppend field={'crwInitialFundingAmount'} /></>}
                prepend={<i className="fas fa-dollar-sign"></i>}
                component={FieldWrapper} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field required className="m-0"
                disabled
                tabIndex={3}
                id={`appEditInstantSettlementForm.${initialValues.id}.warning_balance`}
                type="number"
                placeholder={t('applications.instantSettlement.form.fields.warningBalance.placeholder')}
                name="warning_balance"
                label={<>{t('applications.instantSettlement.form.fields.warningBalance.label')} <TooltipAppend field={'warningBalance'} /></>}
                prepend={<i className="fas fa-dollar-sign"></i>}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field required className="m-0"
                disabled
                tabIndex={4}
                id={`appEditInstantSettlementForm.${initialValues.id}.minimum_balance`}
                type="number"
                placeholder={t('applications.instantSettlement.form.fields.minimumBalance.placeholder')}
                name="minimum_balance"
                label={<>{t('applications.instantSettlement.form.fields.minimumBalance.label')} <TooltipAppend field={'minimumBalance'} /></>}
                prepend={<i className="fas fa-dollar-sign"></i>}
                component={FieldWrapper} />
            </Col>
          </Row>

          <div className="actions d-flex flex-wrap justify-content-end">

            {/* TODO: Remove the conditional render from the cancel button, and make it reset the form. See ENGRG-3950*/}
            {/* <Button variant="outline-light" onClick={() => history.push('/apps')} className="px-4">{t('common:buttons.cancel')}</Button> */}

            <LoaderButton variant="primary" loading={isSubmitting ? true : undefined} disabled={!dirty || Object.keys(errors).length} type="submit" className="px-4">{t('common:buttons.save')}</LoaderButton>

          </div>

        </Form>
      )}
    </Formik>
  );
};

InstantSettlementForm.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to be called when the application is saved
   */
  onSave: PropTypes.func
};

export default InstantSettlementForm;