import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { appsApi, adminApi } from '../../api'

import { useAuth, useAlerts } from '../context';

import FieldWrapper from '../form/wrappers/Field';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';

const ApiAccessKeysForm = ({ appId, admin, onSave }) => {
  const [initialValues, setInitialValues] = useState({
    processing_channel_id: '',
    secret_key: '',
    client_id: '',
    client_secret: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const { env } = useAuth();
  const { setAlert } = useAlerts();
  const formRef = useRef(null);
  const history = useHistory();

  const getCkoCredentials = async () => {
    try {
      let response;
      if (admin) {
        response = await adminApi.getCkoCredentials(appId);
      } else {
        response = await appsApi.getCkoCredentials(appId);
      }
      for (let key in response.credentials) {
        if (response.credentials[key] === null) {
          response.credentials[key] = '';
        }
      }
      setInitialValues(response.credentials);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCkoCredentials();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        processing_channel_id: yup.string()
          .required(t('common:form.errors.required', { field: t('applications.accessKeys.form.fields.processing_channel_id.label') })),
        client_id: yup.string()
          .when('client_secret', {
            is: (value) => value && value.length,
            then: yup.string().required(t('common:form.errors.required', { field: t('applications.accessKeys.form.fields.client_id.label') }))
          }),
        client_secret: yup.string()
          .when('client_id', {
            is: (value) => value && value.length,
            then: yup.string().required(t('common:form.errors.required', { field: t('applications.accessKeys.form.fields.client_secret.label') }))
          })
      }, [['client_id', 'client_secret'], ['client_secret', 'client_id']])}
      innerRef={formRef}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const method = !initialValues.processing_channel_id ? 'post' : 'put';
        try {
          let response;
          if (admin) {
            response = await adminApi.updateCkoCredentials(appId, values, method);
          } else {
            response = await appsApi.updateCkoCredentials(appId, values);
          }
          if (response.credentials.secret_key == null) response.credentials.secret_key = '';
          if (response.credentials.client_id == null) response.credentials.client_id = '';
          if (response.credentials.client_secret == null) response.credentials.client_secret = '';
          setAlert({ message: response.message, variant: 'success' });
          resetForm({ values: response.credentials });
          if (onSave) onSave(appId, values);
          if (admin) setInitialValues(response.credentials);
          if (error) setError(false);
        } catch (error) {
          setAlert({ message: error, variant: 'warning' });
          resetForm({ values: initialValues });
        } finally {
          setSubmitting(false);
        }
      }}>
      {({ errors, dirty, handleSubmit, isSubmitting }) => (
        <Form noValidate className="loaded" autoComplete="new-password" onSubmit={handleSubmit}>

          {admin && <h4 className="mb-4">{t('applications.accessKeys.title')}</h4>}

          {error && <Alert variant="warning" className="mb-4 loaded">
            <i className="fas fa-exclamation-triangle text-warning mr-2"></i> {error}
          </Alert>}

          {(isSubmitting || isLoading) && <Loader />}

          <Row className="mb-4">
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <h4 className="text-hdr mb-4">{t('applications.accessKeys.headers.processing')}</h4>
              <Field required disabled={!admin || isLoading} className="processing_channel_id required m-0"
                tabIndex={1}
                id={`appAccessKeysForm.${appId}.processing_channel_id`}
                name="processing_channel_id"
                label={t('applications.accessKeys.form.fields.processing_channel_id.label')}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <h4 className="text-hdr mb-4">{t('applications.accessKeys.headers.apiKey')}</h4>
              <Field disabled={isLoading || !admin && !initialValues.processing_channel_id} className="secret_key required m-0"
                tabIndex={2}
                id={`appAccessKeysForm.${appId}.secret_key`}
                name="secret_key"
                label={`${t('applications.accessKeys.form.fields.secret_key.label')} (${t(`env.${env}.label`)})`}
                component={FieldWrapper} />
            </Col>
          </Row>

          <h4 className="text-hdr mb-4">{t('applications.accessKeys.headers.accessKey')}</h4>

          <Row className="mb-4">
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field disabled={isLoading || !admin && !initialValues.processing_channel_id} className="client_id required m-0"
                tabIndex={3}
                id={`appAccessKeysForm.${appId}.client_id`}
                name="client_id"
                label={t('applications.accessKeys.form.fields.client_id.label')}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field disabled={isLoading || !admin && !initialValues.processing_channel_id} className="client_secret required m-0"
                tabIndex={4}
                id={`appAccessKeysForm.${appId}.client_secret`}
                name="client_secret"
                label={t('applications.accessKeys.form.fields.client_secret.label')}
                component={FieldWrapper} />
            </Col>
          </Row>

          <div className="actions d-flex flex-wrap justify-content-end">

            {/* TODO: Remove the conditional render from the cancel button, and make it reset the form. See ENGRG-3950*/}
            {/* {!admin && <Button variant="outline-light" onClick={() => history.push('/apps')} className="px-4">{t('common:buttons.cancel')}</Button>} */}

            <LoaderButton variant="primary" loading={(isSubmitting || isLoading) ? true : undefined} disabled={!admin && !initialValues.processing_channel_id || !dirty || Object.keys(errors).length} type="submit" className={!admin ? 'px-4' : undefined}>{t('common:buttons.save')}</LoaderButton>

          </div>

        </Form>
      )}
    </Formik>
  );
};

ApiAccessKeysForm.propTypes = {
  /**
   * The id of the application
   */
  appId: PropTypes.number.isRequired,
  /**
   * The admin toggle to determine if the form is used for the admin console
   */
  admin: PropTypes.bool,
  /**
   * The function to be called when the application is saved
   */
  onSave: PropTypes.func
};

export default ApiAccessKeysForm;