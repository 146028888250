import { hasPermissions, requestProdAccess } from '../utils';

import { API_DEMO_URL, SUPPORT_URL } from './';

import IconSupport from '../../../../images/icon-support.svg';

export const ctas = [
  {
    name: 'invite',
    title: 'welcome.actions.invite.title',
    description: 'welcome.actions.invite.description',
    button: 'welcome.actions.invite.button',
    to: '/settings/members/new',
    permissions: (user) => hasPermissions(user, 'members', 'Write')
  },
  {
    name: 'sila-journey',
    title: 'welcome.actions.silaJourney.title',
    description: 'welcome.actions.silaJourney.description',
    button: 'welcome.actions.silaJourney.button',
    onClick: requestProdAccess
  },
  {
    name: 'onboarding',
    title: 'welcome.actions.onboarding.title',
    description: 'welcome.actions.onboarding.description',
    button: 'welcome.actions.onboarding.button',
    permissions: (user) => !user.approved,
    onClick: requestProdAccess
  },
  {
    name: 'get-started',
    title: 'welcome.actions.getStarted.title',
    description: 'welcome.actions.getStarted.description',
    button: 'welcome.actions.getStarted.button',
    to: '/app/new',
    permissions: (user) => user.confirmed_at && !user.hasApps && hasPermissions(user, 'apps', 'Write')
  },
  {
    name: 'install',
    title: 'welcome.actions.install.title',
    description: 'welcome.actions.install.description',
    button: 'welcome.actions.install.button',
    href: 'https://docs.silamoney.com/docs/native-sdks'
  },
  {
    name: 'learn',
    title: 'welcome.actions.learn.title',
    description: 'welcome.actions.learn.description',
    button: 'welcome.actions.learn.button',
    href: 'https://docs.silamoney.com'
  }
];

export const blocks = [
  {
    name: 'demo',
    classes: 'decal-left pt-0 pt-lg-4 pt-xl-5',
    title: 'welcome.blocks.demo.title',
    button: 'welcome.blocks.demo.button',
    buttonClass: 'mt-5',
    href: API_DEMO_URL
  },
  {
    name: 'support',
    classes: 'decal-middle',
    icon: IconSupport,
    title: 'welcome.blocks.support.title',
    description: 'welcome.blocks.support.description',
    button: 'welcome.blocks.support.button',
    href: SUPPORT_URL
  },
  { 
    name: 'onboarding',
    classes: 'decal-right',
    title: 'welcome.blocks.onboarding.title',
    description: 'welcome.blocks.onboarding.description',
    button: 'welcome.blocks.onboarding.button',
    onClick: requestProdAccess,
    permissions: (user) => !user.approved,
  },
];