/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState, useRef, useEffect } from 'react';
import { Table, Form, Col, Card } from 'react-bootstrap';
import { Formik, Field } from 'formik';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import FieldWrapper from '../form/wrappers/Field';
import ConfirmModal from '../common/ConfirmModal';

import { UUID_REG_EXP } from '../../constants';
import { adminApi } from '../../api';
import { useAlerts } from '../context';

function CompanyWalletForm({ initialValues, match, onSave }) {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [confirm, setConfirm] = useState({ show: false, data: undefined });
  const formRef = useRef(null);
  const [walletType, setWalletType] = useState('customer_reserve_wallet');
  const [walletList, setWalletList] = useState([]);

  const handleWalletChange = (e) => {
    setWalletType(e.target.value);
  };
  const fetchCompanyWallet = async () => {
    try {
      const response = await adminApi.fetchCompanyWallet(match.params.id);
      setWalletList(response.company_wallets);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const createCompanyWallet = async (values) => {
    if (values) {
      try {
        // eslint-disable-next-line no-param-reassign
        values = { ...values, business_type: 'public_corporation', naics_code: '5221' };
        await adminApi.createCompanyWallet(initialValues.id, values);
        setAlert({
          message: t('admin.applications.entityInformation.form.messages.success'),
          variant: 'success',
        });
        onSave();
        fetchCompanyWallet();
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setConfirm({ ...confirm, show: false, data: undefined });
        formRef.current.setSubmitting(false);
      }
    } else {
      setConfirm({ ...confirm, show: false, data: undefined });
      formRef.current.setSubmitting(false);
    }
  };

  useEffect(() => {
    // Only execute if app is unfrozen, fetchCompanyWallet returns a 400 if the app is frozen.
    if (initialValues && !initialValues.frozen) {
      fetchCompanyWallet();
      if (walletType === 'business_operating_wallet') {
        // Execute subsequent setFieldValue only if the previous one returns truthy,
        // ensuring sequential dependency for setting form fields.
        // eslint-disable-next-line no-unused-expressions
        formRef.current.setFieldValue('initial_reserve_balance', '') &&
          formRef.current.setFieldValue('warning_threshold', '') &&
          formRef.current.setFieldValue('minimum_balance', '');
      }
    }
    // fetch the wallet if the wallet changes or if the app becomes unfrozen
  }, [walletType, initialValues]);

  return (
    <>
      <Formik
        // eslint-disable-next-line react/jsx-boolean-value
        enableReinitialize={true}
        initialValues={{
          company_wallet_type: walletType,
          business_name: initialValues.business_name || '',
          business_type: initialValues.business_type || 'Default: Public Corporation',
          naics_code: initialValues.naics_code || 'Default: 5221',
          wallet_uuid: initialValues.wallet_uuid || '',
        }}
        innerRef={formRef}
        validationSchema={yup.object().shape({
          initial_reserve_balance:
            walletType === 'customer_reserve_wallet'
              ? yup
                  .string()
                  .trim()
                  .required(
                    t('common:form.errors.required', {
                      field: t(
                        'applications.instantSettlement.form.fields.crwInitialFundingAmount.label'
                      ),
                    })
                  )
              : null,
          warning_threshold:
            walletType === 'customer_reserve_wallet'
              ? yup
                  .string()
                  .trim()
                  .required(
                    t('common:form.errors.required', {
                      field: t('applications.instantSettlement.form.fields.warningBalance.label'),
                    })
                  )
              : null,
          minimum_balance:
            walletType === 'customer_reserve_wallet'
              ? yup
                  .string()
                  .trim()
                  .required(
                    t('common:form.errors.required', {
                      field: t('applications.instantSettlement.form.fields.minimumBalance.label'),
                    })
                  )
              : null,
          business_name: yup.string().required(
            t('common:form.errors.required', {
              field: t('admin.applications.entityInformation.form.fields.companyLegalName.label'),
            })
          ),
          business_type: yup.string().required(
            t('common:form.errors.required', {
              field: t('admin.applications.entityInformation.form.fields.businessType.label'),
            })
          ),
          naics_code: yup.string().required(
            t('common:form.errors.required', {
              field: t('admin.applications.entityInformation.form.fields.naicsCode.label'),
            })
          ),
          wallet_uuid: yup
            .string()
            .required(
              t('common:form.errors.required', {
                field: t('admin.applications.entityInformation.form.fields.walletId.label'),
              })
            )
            .matches(UUID_REG_EXP, { message: t('common:form.fields.uuid.error') }),
        })}
        onSubmit={(values) => setConfirm({ ...confirm, show: true, data: values })}
      >
        {({ errors, handleSubmit, isSubmitting, dirty }) => (
          <Form
            className="px-4 pb-4"
            noValidate
            autoComplete="new-password"
            onSubmit={handleSubmit}
          >
            <div className="d-flex align-items-center mb-2">
              <h4>{t('admin.applications.entityInformation.title')}</h4>
            </div>
            <p className="text-info mb-4">
              {t('admin.applications.entityInformation.description')}
            </p>

            <div className="position-relative">
              {isSubmitting && <Loader />}

              <Form.Row className="mb-5 mt-5">
                <Col sm="12" lg="3" className="mb-3 mb-lg-0">
                  <Field
                    className="mb-4 flex-row-reverse d-flex justify-content-end align-items-center"
                    id="customer_reserve_wallet"
                    name="company_wallet_type"
                    type="radio"
                    label={
                      <strong>
                        {t(
                          'applications.instantSettlement.form.fields.customerReserveWallet.label'
                        )}
                      </strong>
                    }
                    value="customer_reserve_wallet"
                    onChange={handleWalletChange}
                    checked={walletType === 'customer_reserve_wallet'}
                    fieldClass="data-hj-whitelist"
                    component={FieldWrapper}
                  />
                </Col>
                <Col sm="12" lg="3" className="mb-3 mb-lg-0">
                  <Field
                    className="mb-4 flex-row-reverse d-flex justify-content-end align-items-center"
                    id="business_operating_wallet"
                    name="company_wallet_type"
                    type="radio"
                    label={
                      <strong>
                        {t(
                          'admin.applications.entityInformation.form.fields.businessOperatingWallet.label'
                        )}
                      </strong>
                    }
                    value="business_operating_wallet"
                    onChange={handleWalletChange}
                    checked={walletType === 'business_operating_wallet'}
                    fieldClass="data-hj-whitelist"
                    component={FieldWrapper}
                  />
                </Col>
              </Form.Row>

              {walletType == 'customer_reserve_wallet' && (
                <Form.Row className="mb-4">
                  <Col sm="12" lg="4" className="mb-3 mb-lg-0">
                    <Field
                      autofocus
                      required
                      className="m-0"
                      tabIndex={1}
                      id="initial_reserve_balance"
                      type="number"
                      name="initial_reserve_balance"
                      label={
                        <p className="ml-4">
                          {t(
                            'applications.instantSettlement.form.fields.crwInitialFundingAmount.label'
                          )}
                        </p>
                      }
                      prepend={<i className="fas fa-dollar-sign" />}
                      component={FieldWrapper}
                    />
                  </Col>
                  <Col sm="12" lg="4" className="mb-3 mb-lg-0">
                    <Field
                      autofocus
                      required
                      className="m-0"
                      tabIndex={2}
                      id="warning_threshold"
                      type="number"
                      name="warning_threshold"
                      label={
                        <p className="ml-4">
                          {t('applications.instantSettlement.form.fields.warningBalance.label')}
                        </p>
                      }
                      prepend={<i className="fas fa-dollar-sign" />}
                      component={FieldWrapper}
                    />
                  </Col>
                  <Col sm="12" lg="4" className="mb-3 mb-lg-0">
                    <Field
                      autofocus
                      required
                      className="m-0"
                      tabIndex={3}
                      id="minimum_balance"
                      type="number"
                      name="minimum_balance"
                      label={
                        <p className="ml-4">
                          {t('applications.instantSettlement.form.fields.minimumBalance.label')}
                        </p>
                      }
                      prepend={<i className="fas fa-dollar-sign" />}
                      component={FieldWrapper}
                    />
                  </Col>
                </Form.Row>
              )}

              <Form.Row className="mb-4">
                <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                  <Field
                    autofocus
                    required
                    className="m-0"
                    tabIndex={walletType == 'customer_reserve_wallet' ? 4 : 1}
                    id="business_name"
                    name="business_name"
                    label={t(
                      'admin.applications.entityInformation.form.fields.companyLegalName.label'
                    )}
                    component={FieldWrapper}
                  />
                </Col>
                <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                  <Field
                    autofocus
                    required
                    readOnly
                    className="m-0"
                    tabIndex={walletType == 'customer_reserve_wallet' ? 5 : 2}
                    id="business_type"
                    name="business_type"
                    label={t('admin.applications.entityInformation.form.fields.businessType.label')}
                    component={FieldWrapper}
                  />
                </Col>
              </Form.Row>

              <Form.Row className="mb-4">
                <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                  <Field
                    autofocus
                    required
                    readOnly
                    className="m-0"
                    tabIndex={walletType == 'customer_reserve_wallet' ? 6 : 3}
                    id="naics_code"
                    name="naics_code"
                    label={t('admin.applications.entityInformation.form.fields.naicsCode.label')}
                    component={FieldWrapper}
                  />
                </Col>
                <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                  <Field
                    autofocus
                    required
                    className="m-0"
                    tabIndex={walletType == 'customer_reserve_wallet' ? 7 : 4}
                    id="wallet_uuid"
                    name="wallet_uuid"
                    label={t('admin.applications.entityInformation.form.fields.walletId.label')}
                    component={FieldWrapper}
                  />
                </Col>
              </Form.Row>

              <div className="d-flex justify-content-end mt-4">
                <LoaderButton
                  loading={isSubmitting}
                  onClick={isSubmitting ? (e) => e.preventDefault() : undefined}
                  type="submit"
                  disabled={!dirty || Object.keys(errors).length}
                >
                  {t('common:buttons.save')}
                </LoaderButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {walletList.length !== 0 && (
        <Card className="mt-5">
          <Card.Body className="py-0 px-4">
            <div className="mx-n4">
              <Table className="border-bottom" responsive>
                <thead>
                  <tr>
                    <th className="py-3 pl-4">
                      {t('admin.applications.entityInformation.form.fields.legalCompanyName.label')}
                    </th>
                    <th className="py-3 ">{t('common:labels.userHandle')}</th>
                    <th className="py-3">
                      {t('common:transactions.labels.wallets.type.walletId')}
                    </th>
                    <th className="py-3">{t('common:transactions.labels.type')}</th>
                  </tr>
                </thead>
                <tbody>
                  {walletList.map((item, key) => {
                    return (
                      <tr key={key} className="loaded">
                        <td className="py-3 pl-4 text-break">{item.legal_company_name}</td>
                        <td className="py-3">{item.user_handle}</td>
                        <td className="py-3">{item.wallet_id}</td>
                        <td className="py-3">{item.type}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      )}

      {confirm.show && (
        <ConfirmModal
          show={confirm.show}
          data={confirm.data}
          title={t('admin.applications.entityInformation.modal.title')}
          message={t('admin.applications.entityInformation.modal.description', {
            name: initialValues.nickname,
          })}
          buttonLabel={t('common:buttons.update')}
          onHide={createCompanyWallet}
        />
      )}
    </>
  );
}

CompanyWalletForm.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to be called when the application is saved
   */
  onSave: PropTypes.func.isRequired,
};

export default CompanyWalletForm;
