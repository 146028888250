import React, { useState, useEffect, useRef } from 'react';
import { ListGroup, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { Formik, Field } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import * as yup from 'yup';

import { SPACES_REG_EXP } from '../../constants';
import { formatDateAndTime, linkify } from '../../utils';
import { useCurrentDimensions } from '../../utils/hooks'

import LoaderButton from '../common/LoaderButton';
import Loader from '../common/Loader';
import TooltipButton from '../common/TooltipButton';
import FieldWrapper from '../form/wrappers/Field';

const MAX_COMMMENT_COUNT = 3;

const CommentForm = ({ className, onSubmit, comments, hasWriteAccess }) => {
  const [show, setShow] = useState(false);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const { t } = useTranslation();
  const commentsRef = useRef([]);
  const authorsRef = useRef([]);
  const currentWidth = useCurrentDimensions().width;
  const isMobile = currentWidth <= 768;

  const initialValues = {
    comment: ''
  };

  useEffect(() => {
    let timeout = null;
    const resizeListener = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const list = [];
        const lists = commentsRef.current;
        if (!isMobile && lists.length) {
          for (let i = 0; i < MAX_COMMMENT_COUNT; i++) {
            if (lists[i]) {
              list.push(lists[i].clientHeight);
              setHeight(list.reduce((a, b) => a + b, 0));
            }
          }
        } else {
          setHeight(false);
        }
      }, 150);
    };
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [isMobile, comments]);

  useEffect(() => {
    let timeout = null;
    const resizeListener = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const list = [];
        const lists = authorsRef.current;
        let width = 0;
        if (!isMobile && lists.length) {
          for (let i = 0; i < lists.length; i++) {
            if (lists[i]) {
              list.push(lists[i].clientWidth);
              width = Math.max.apply(null, list);
            }
          }
          setWidth(width);
        } else {
          setWidth(false);
        }
      }, 150);
    };
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [isMobile, comments]);

  return (<aside className={classNames(className, 'comments')}>

    <header className="comment-header d-flex justify-content-between align-items-center">
      <h4 className="mb-3">{t('common:comments.title')}</h4>
      {hasWriteAccess && <TooltipButton className={`float-right p-0 cursor-pointer no-underline ${show ? 'text-primary' : 'text-info-link'}`} placement="top" variant="link" popoverProps={{ style: { textAlign: 'center' } }} onClick={() => setShow(true)} text={t('common:comments.tip')}><i className="sila-icon comment text-lg"></i></TooltipButton>}
    </header>

    <section className="p-4 border rounded overflow-hidden position-relative">

      {show &&
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            comment: yup.string()
              .min(10, t('common:form.errors.min', { count: 10 }))
              .max(500, t('common:form.errors.max', { count: 500 }))
              .matches(SPACES_REG_EXP, t('common:form.errors.spaces'))
              .required(t('common:comments.required')),
          })}
          onSubmit={onSubmit}>
          {({ handleSubmit, errors, dirty, isSubmitting, resetForm, setFieldValue }) => (<>
            <Form noValidate id="commentForm" className={`comment-form loaded bg-info rounded p-3 d-flex border ${errors.comment ? 'border-danger mb-2' : 'mb-4'}`} autoComplete="new-password" onSubmit={(values) => {
              setShow(false);
              handleSubmit(values);
            }}>
              <Field required material hideError style={{ minHeight: '100%' }} className="comment no-border mb-0 w-100"
                id="commentForm.comment"
                fieldClass="border-0 p-0 no-resize text-reg"
                type="elastic"
                rows="3"
                placeholder={t('common:comments.placeholder')}
                name="comment"
                handleChange={(e) => setFieldValue(e.target.name, string.replace(/\s\s+/g, ' '))}
                component={FieldWrapper} />
              <div className="buttons d-flex flex-column ml-2">
                <Button variant="link" className="text-reset text-lg no-underline close ml-auto mb-auto mt-n2 mr-n2" onClick={() => { resetForm({ values: initialValues }); setShow(false); }}><span aria-hidden="true">×</span><span className="sr-only">{t('common:buttons.close')}</span></Button>
                <LoaderButton className="mt-auto ml-auto" size="sm" loading={isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={!dirty || Object.keys(errors).length}>{t('common:comments.submit')}</LoaderButton>
              </div>
            </Form>
            {errors.comment && <p className="text-sm text-danger">{errors.comment}</p>}
          </>
          )}
        </Formik>}

      {comments ? <>
        {comments.length ? <ListGroup as="ul" className={classNames('loaded list-unstyled', height && 'overflow-auto custom-scrollbar', !show && 'm-n4')} style={height ? { maxHeight: height } : undefined} variant="flush">
          {comments.length && comments.map((comment, index) => <ListGroup.Item key={index} as="li" ref={(el) => commentsRef.current.splice(index, 1, el)} className={classNames('loaded', show && 'px-0', show && index === (comments.length - 1) && 'pb-0', show && index === 0 && 'pt-0 borer-top-0')}>
            <p className="m-0 d-block d-md-flex flex-nowrap">
              <span className="author d-block"><span className={classNames('text-nowrap d-block mr-0 mr-md-2', !isMobile && 'text-right')} style={width ? { width } : undefined} ref={(el) => authorsRef.current.splice(index, 1, el)}><span className="text-primary">{`${comment.author_first_name} ${comment.author_surname ? comment.author_surname : ''}`}</span> {t('common:comments.added')}:</span></span>
              <span className="message text-info font-italic d-block my-2 my-md-0 mr-0 mr-md-2 text-break" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linkify(comment.comment))}}></span>
              <span className={classNames('text-info ml-auto text-nowrap d-block', isMobile && 'text-right')}>{formatDateAndTime(comment.created)}</span>
            </p>
          </ListGroup.Item>)}
        </ListGroup> : <p className="loaded text-center text-info font-italic mb-0">{t('common:comments.empty')}</p>}
      </> : <div className="position-relative"><Loader /></div>}

    </section>
  </aside>
  );
};

CommentForm.propTypes = {
  /**
   * The optional classes.
   */
  className: PropTypes.string,
  /**
   * The submit function
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * The comments array
   */
  comments: PropTypes.array,
  /**
   * The write access boolean
   */
   hasWriteAccess: PropTypes.bool.isRequired
};

export default CommentForm;