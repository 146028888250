import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Dropdown, DropdownButton, Table, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';

import { appsApi, webhooksApi } from '../../api';
import { hasPermissions } from '../../utils';
import { useInfiniteScroll } from '../../utils/hooks';
import { COOKIES_OPTIONS } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import CenteredOverlay from '../../components/common/CenteredOverlay';
import DisabledOverlay from '../../components/common/DisabledOverlay';

const defaultApp = parseInt(Cookies.get('sila_endpoints_app'));

const Webhooks = ({ t, user, history, location, setAlert }) => {
  const [apps, setApps] = useState([]);
  const [filters, setFilters] = useState({ app: false, search: '' });
  const { data, setRefresh, lastItem, isLoading, hasMore, isFetching, setIsLoading, error, loaded } = useInfiniteScroll(webhooksApi.fetchEndpoints, {
    apps: apps,
    app_id: filters.app || false,
    slug: 'endpoints',
    limit: 8
  }, hasPermissions(user, 'webhooks', 'Read') && apps.length !== 0);

  const endpoints = data.filter(endpoint => {
    return Object.keys(filters).every(filter => {
      if (!filters[filter]) { return true; }
      if (filter === 'app') {
        return endpoint.app_id === filters[filter];
      } else if (filter === 'search') {
        return endpoint.name.toLowerCase().includes(filters[filter].toLowerCase());
      }
    });
  });

  const handleNewApp = () => {
    history.push({ pathname: '/app/new', state: { from: location.pathname } })
  }

  useEffect(() => {
    if (error) setAlert({ message: error, variant: 'warning' });
  }, [error]);

  useEffect(() => {
    const currentApp = apps.find(app => app.id === defaultApp);
    setFilters({ ...filters, app: defaultApp && currentApp ? currentApp.id : false });
    if (apps.length) {
      if (loaded) setRefresh(true);
    } else {
      setIsLoading(false);
    }
  }, [apps]);

  useEffect(() => {
    const fetchApps = async () => {
      setIsLoading(true);
      try {
        const response = await appsApi.fetchApps({ team: user.currentTeam.id, limit: 20 });
        setApps(response.apps);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        setIsLoading(false);
      }
    };
    fetchApps();
  }, [user.currentTeam]);

  useEffect(() => {
    Cookies.set('sila_endpoints_app', filters.app, COOKIES_OPTIONS);
  }, [filters.app]);

  return (
    <Container className="webhooks main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('webhooks.endpoints.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      <div className="position-relative d-flex flex-column flex-grow-1">

        {!hasPermissions(user, 'webhooks', 'Read') && <DisabledOverlay />}

        {!isLoading && apps.length === 0 &&
          <CenteredOverlay>
            <p className="text-center text-info font-italic">{t('webhooks.endpoints.empty.apps')}</p>
            {hasPermissions(user, 'apps', 'Write') && <Button disabled={!user.confirmed_at} onClick={handleNewApp}>{t('applications.empty.action')}</Button>}
          </CenteredOverlay>}

        {isLoading ? <Loader /> : apps.length !== 0 ? <><Card className="mb-3 mb-md-5 loaded">
          <Card.Header as={Row} className="p-4 dropdown-z-index" noGutters>
            <Col sm="8" className="apps d-block d-sm-flex align-items-center">
              {data.length !== 0 && <Form.Control size="lg" className="w-100 mr-3 mb-2 mb-md-0 loaded" placeholder={t('webhooks.endpoints.form.fields.search.placeholder')} aria-label={t('common:labels.search')} onChange={(e) => setFilters({ ...filters, search: e.currentTarget.value })} />}
              <DropdownButton className="text-nowrap text-nowrap mt-2 mt-sm-0 loaded" style={{ zIndex: 9 }} variant="outline-light" title={apps.find(a => a.id === filters.app) ? apps.find(a => a.id === filters.app).name : t('webhooks.endpoints.labels.apps')}>
                <div className="overflow-auto mb-n2 custom-scrollbar" style={{ maxHeight: 300 }}>
                  {filters.app && <Dropdown.Item onClick={() => setFilters({ ...filters, app: false })}>{t('webhooks.endpoints.labels.apps')}</Dropdown.Item>}
                  {apps.filter(a => a.id !== filters.app).map((app, key) => <Dropdown.Item key={key} onClick={() => setFilters({ ...filters, app: parseInt(app.id) })}>{app.name}</Dropdown.Item>)}
                </div>
              </DropdownButton>
            </Col>
            <Col sm="4" className="text-right">
              {data.length !== 0 && hasPermissions(user, 'webhooks', 'Write') && <Button as={NavLink} variant="outline-light" to="/dev/webhooks/new" className="ml-0 ml-sm-3 mt-3 mt-sm-0 d-block d-md-inline-block text-nowrap loaded">{t('webhooks.endpoints.buttons.create')} <i className="fas fa-plus-circle ml-2"></i></Button>}
            </Col>
          </Card.Header>
          <Card.Body className="p-0">
            <Table hover responsive>
              {data.length !== 0 && <thead>
                <tr>
                  <th className="py-3 pl-4" width="20%">{t('webhooks.endpoints.labels.name')}</th>
                  <th className="py-3" width="20%">{t('webhooks.endpoints.labels.url')}</th>
                  <th className="py-3" width="20%">{t('webhooks.endpoints.labels.app')}</th>
                  <th className="py-3 text-center" width="10%">{t('webhooks.endpoints.labels.triggers')}</th>
                  <th className="py-3 pr-4 text-center" width="20%">{t('webhooks.endpoints.labels.status')}</th>
                </tr>
              </thead>}
              <tbody>
                {data.length && (filters.app || filters.search) && endpoints.length === 0 ? <tr className="disabled"><td colSpan="5" className="p-0 cursor-default"><p className="text-center text-info font-italic mx-4 my-5">{t('webhooks.endpoints.empty.search')}</p></td></tr> : null}
                {endpoints.length !== 0 && endpoints.map((endpoint, key) => <tr key={key} ref={data[data.length - 1].key === data.key ? lastItem : null} onClick={() => history.push({ pathname: `/dev/webhooks/${endpoint.app_id}/${endpoint.id}/detail`, state: { from: location.pathname } })}>
                  <td className="py-3 pl-4" width="20%">{endpoint.name}</td>
                  <td className="py-3" width="20%">{endpoint.url}</td>
                  <td className="py-3" width="20%">{apps.find(app => app.id === endpoint.app_id).name}</td>
                  <td className="py-3 text-center" width="10%">{endpoint.triggers.length}</td>
                  <td className={`py-3 pr-4 text-center ${endpoint.active ? 'text-success' : 'text-warning'}`} width="20%" >{endpoint.active ? t('common:status.active') : t('common:status.inactive')}</td>
                </tr>)}
                {data.length === 0 && <tr className="disabled">
                  <td colSpan="5" className="px-4 py-5 text-center cursor-default border-top-0">
                    <p className="text-info font-italic">{t('webhooks.endpoints.empty.message')}</p>
                    {hasPermissions(user, 'webhooks', 'Write') && <p><Button as={NavLink} to="/dev/webhooks/new">{t('webhooks.endpoints.empty.action')}</Button></p>}
                  </td>
                </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        {isFetching && hasMore && <div className="fetching"><Loader label={t('webhooks.loading')} /></div>}

        </> : null}

      </div>

    </Container>
  );
};

export default Webhooks;