import React, { useMemo, useState } from 'react';
import { Badge, Popover, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDateAndTime, formatNumberColumns, convertToUSD } from '../../../utils';
import { TRANSACTION_PROCESSING_TYPES } from '../../../constants';

import { useAuth } from '../../../components/context';

import TooltipButton from '../TooltipButton';
import DataList from '../DataList';
import UUIDSearchForm from '../UUIDSearchForm';
import { DateRangeColumnFilter, CheckboxColumnFilter, MultipleFilter, DateBetweenFilter, RangeColumnFilter } from '../table/TableFilters';

import BankIcon from '../../../../../../images/icon-bank.svg';
import CardIcon from '../../../../../../images/icon-card.svg';
import WalletIcon from '../../../../../../images/icon-wallet.svg';

const TABLE_SLUG = 'transactions';
const TABLE_MAX_COUNT = 20;

const formatFilters = (filters) => {
  let query = filters;
  const created = filters.find(filter => filter.id === 'created');
  const amount = filters.find(filter => filter.id === 'amount');
  const return_code = filters.find(filter => filter.id === 'return_code');
  if (created) {
    if (created.value) {
      query = [...query.filter(filter => filter.id !== 'created'), { id: 'start_date', value: created.value[0] }, { id: 'end_date', value: created.value[1] }];
    } else {
      query = [...query.filter(filter => filter.id !== 'created')];
    }
  }
  if (amount) {
    query = [...query.filter(filter => filter.id !== 'amount'), { id: 'min_amount', value: amount.value[0] }, { id: 'max_amount', value: amount.value[1] }];
  }
  if (return_code) {
    query = query.map(filter => filter.id === 'return_code' && filter.value[0] === 'all' ? { ...filter, value: 'R' } : filter);
  }
  return { url: filters, query };
};

const formatSortBy = (sortBy) => {
  let query = sortBy;
  return { url: sortBy, query: query.map(sort => sort.id === 'user_handle' ? { ...sort, id: 'source_user_handle' } : sort) };
};

const TransactionList = ({ filterName, fetchData, fetchOptions, onRowClick, hasPermissions, subHeaderComponent, onUpdate }) => {
  const [convert, setConvert] = useState(false);
  const { env } = useAuth();
  const { t } = useTranslation();

  const renderAccountPopover = (id, value) => <Popover id={`row-${id}-source`}>
    <Popover.Title as="h3" className="text-primary bg-primary-accent-light border-bottom-0">{t(`common:transactions.labels.${value.payment_instrument_type}`)}</Popover.Title>
    <Popover.Content>
      <p className="mb-2"><span className="text-info d-block">{t('common:labels.userHandle')}:</span>{value.user_handle}</p>
      {value.nickname && <p className="mb-2"><span className="text-info d-block">{t('common:transactions.labels.nickname')}:</span>{value.nickname}</p>}
      {value.payment_instrument_type === 'bank_account' && <>
        <p className="mb-2"><span className="text-info d-block">{t('common:transactions.labels.account')}: </span>{value.last_4 ? `${value.last_4}*` : <span className="text-info">{t('common:labels.na')}</span>}</p>
      </>}
      {value.payment_instrument_type === 'blockchain_address' && <>
        <p className="mb-2"><span className="text-info d-block">{t('applications.detail.form.fields.address.label')}:</span>{value.address}</p>
        <p className="mb-2"><span className="text-info d-block">{t('common:transactions.labels.wallets.type.label')}:</span>{t(`common:transactions.labels.wallets.type.${value.blockchain_network.toLowerCase()}`)}</p>
        <p className="mb-0"><span className="text-info d-block">{t('common:transactions.labels.wallets.migration.label')}:</span>
          {value.migrated_at || <span className="text-info">{t('common:labels.na')}</span>}</p>
      </>}
      {value.payment_instrument_type === 'card' && <>
        <p className="mb-2"><span className="text-info d-block">{t('common:transactions.labels.cards.network')}:</span>{value.card_network}</p>
        <p className="mb-2"><span className="text-info d-block">{t('common:transactions.labels.cards.type')}:</span>{value.card_type}</p>
        <p className="mb-0"><span className="text-info d-block">{t('common:transactions.labels.cards.number')}:</span>*{value.last_4}</p>
      </>}
    </Popover.Content>
  </Popover>;

  const renderAccountIcon = (type) => <span className="d-block text-center"><img src={type.payment_instrument_type === 'card' ? CardIcon : type.payment_instrument_type === 'bank_account' ? BankIcon : WalletIcon} height="24" /></span>;

  const columns = useMemo(() => [
    {
      id: 'created',
      Header: t('common:labels.created'),
      accessor: 'created',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => value ? formatDateAndTime(value) : <span className="text-info">{t('common:labels.na')}</span>
    },
    {
      id: 'user_handle',
      Header: t('common:labels.userHandle'),
      accessor: data => data.source.user_handle,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}>{value}</span>
    },
    {
      id: 'app_handle',
      Header: t('common:labels.app'),
      accessor: data => data.app.app_handle,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}>{value}</span>
    },
    {
      id: 'transaction_type',
      Header: t('common:transactions.labels.type'),
      accessor: data => data.transaction_type,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      columnStyles: () => ({ width: '1%' }),
      Cell: ({ value }) => value ? <span className="text-nowrap">{t(`common:transactions.${value}`)}</span> : <span className="text-info">{t('common:labels.na')}</span>,
      filterOptions: () => [{
        value: 'issue',
        label: t('common:transactions.issue')
      }, {
        value: 'redeem',
        label: t('common:transactions.redeem')
      }, {
        value: 'transfer',
        label: t('common:transactions.transfer')
      }]
    },
    {
      id: 'amount',
      Header: t('common:transactions.labels.amount'),
      accessor: data => data.sila_amount,
      Filter: RangeColumnFilter,
      disableSortBy: true,
      currency: !convert,
      renderHeader: () => <TooltipButton placement="top" variant="link" popoverProps={{ style: { textAlign: 'center' } }} onClick={() => setConvert(!convert)} className="p-0 no-underline ml-2" text={t(`common:buttons.${convert ? 'convertToUsd' : 'convertToSila'}`)}>({convert ? 'SILA' : 'USD'})</TooltipButton>,
      columnStyles: () => ({ width: '1%' }),
      Cell: ({ value }) => !value ? <span className="text-info">{t('common:labels.na')}</span> : convert ? <><i className="sila-icon sila mr-1 text-sm text-primary"></i>{formatNumberColumns(value)}</> : `$${convertToUSD(value)}`
    },
    {
      id: 'source_payment_instrument_type',
      Header: t('common:transactions.labels.source'),
      accessor: data => data.source,
      Cell: ({ value, row: { id } }) => <OverlayTrigger
        placement="top"
        overlay={renderAccountPopover(id, value)}>
        {renderAccountIcon(value)}
      </OverlayTrigger>,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      filterOptions: () => [{
        label: t('common:transactions.labels.bank_account'),
        value: 'bank_account'
      }, {
        label: t('common:transactions.labels.card'),
        value: 'card'
      }, {
        label: t('common:transactions.labels.virtual_account'),
        value: 'virtual_account'
      }, {
        label: t('common:transactions.labels.blockchain_address'),
        value: 'blockchain_address'
      },
      ],
      columnStyles: () => ({ width: '1%' })
    },
    {
      id: 'destination_payment_instrument_type',
      Header: t('common:transactions.labels.destination'),
      accessor: data => data.destination,
      Cell: ({ value, row: { id } }) => <OverlayTrigger
        placement="top"
        overlay={renderAccountPopover(id, value)}>
        {renderAccountIcon(value)}
      </OverlayTrigger>,
      disableSortBy: true,
      Filter: CheckboxColumnFilter,
      filterOptions: () => [{
        label: t('common:transactions.labels.bank_account'),
        value: 'bank_account'
      }, {
        label: t('common:transactions.labels.card'),
        value: 'card'
      }, {
        label: t('common:transactions.labels.virtual_account'),
        value: 'virtual_account'
      }, {
        label: t('common:transactions.labels.blockchain_address'),
        value: 'blockchain_address'
      },
      ],
      columnStyles: () => ({ width: '1%' })
    },
    {
      id: 'processing_type',
      Header: t('common:transactions.labels.processingType'),
      accessor: 'processing_type',
      filter: MultipleFilter,
      disableSortBy: true,
      Filter: CheckboxColumnFilter,
      columnStyles: () => ({ width: '1%' }),
      Cell: ({ value }) => <span className="text-nowrap text-sm">{value}</span>,
      filterOptions: () => TRANSACTION_PROCESSING_TYPES.map(type => ({ value: type, label: type }))
    },
    {
      id: 'return_code',
      Header: t('common:transactions.labels.returnCode'),
      accessor: data => data.return_code || t('common:labels.null'),
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      columnStyles: () => ({ width: '1%' }),
      Cell: ({ value }) => <span className="text-nowrap">{value}</span>,
      searchOptions: {
        disabled: true
      },
      disableSortBy: true,
      filterOptions: () => [
        {
          label: t('common:transactions.labels.allCodes'),
          value: 'all'
        },
        ...[...Array(85).keys()].map(r => ({
          label: `R${String(r + 1).padStart(2, '0')}`,
          value: `R${String(r + 1).padStart(2, '0')}`
        }))
      ]
    },
    {
      id: 'transaction_status',
      Header: t('common:transactions.labels.status'),
      accessor: 'transaction_status',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      Cell: ({ value, row }) => {
        const notSubmiitted = env === 'prod' && row.original.submitted === null && row.original.transaction_status === 'pending' && row.original.usd_status === 'pending' && (row.original.transaction_type === 'issue' || row.original.transaction_type === 'redeem');
        return <Badge pill className="badge-outline py-2 px-3 w-100 d-block mx-auto" style={{ maxWidth: 140 }} variant={notSubmiitted ? 'secondary' : value.includes('success') ? 'success' : value.includes('failed') ? 'warning' : 'primary'}>{notSubmiitted ? <span className="d-flex align-items-center text-nowrap">{t('common:transactions.status.notSubmitted')}<TooltipButton placement="top" variant="link" text={t('common:transactions.tips.notSubmitted')} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton></span> : t(`common:transactions.status.${value}`)}</Badge>;
      },
      columnStyles: () => ({ width: '1%' }),
      filterOptions: () => [{
        value: 'queued',
        label: t('common:transactions.status.queued')
      }, {
        value: 'pending',
        label: t('common:transactions.status.pending')
      }, {
        value: 'reversed',
        label: t('common:transactions.status.reversed')
      }, {
        value: 'failed',
        label: t('common:transactions.status.failed')
      }, {
        value: 'success',
        label: t('common:transactions.status.success')
      }, {
        value: 'rollback',
        label: t('common:transactions.status.rollback')
      }, {
        value: 'review',
        label: t('common:transactions.status.review')
      }, {
        value: 'pending_confirmation',
        label: t('common:transactions.status.pending_confirmation')
      }]
    },
  ], [convert, env]);

  return (
    <DataList
      filterName={filterName}
      slug={TABLE_SLUG}
      limit={TABLE_MAX_COUNT}
      fetchData={fetchData}
      fetchOptions={fetchOptions}
      columns={columns}
      subHeaderComponent={subHeaderComponent}
      headerLeftComponent={() => <UUIDSearchForm onSubmit={onRowClick} />}
      onRowClick={onRowClick}
      onFilter={formatFilters}
      onSortBy={formatSortBy}
      hasPermissions={hasPermissions}
      onUpdate={onUpdate}
      loadingMessage={t('common:transactions.loading')} />
  )
};

TransactionList.propTypes = {
  /**
   * The unique item slug for the data list
   */
  filterName: PropTypes.string,
  /**
   * The fetch data function
   */
  fetchData: PropTypes.func.isRequired,
  /**
   * The fetch options object
   */
  fetchOptions: PropTypes.object,
  /**
   * The click handler function trigger on a table row
   */
  onRowClick: PropTypes.func.isRequired,
  /**
   * The function to validate user permissions
   */
  hasPermissions: PropTypes.func.isRequired,
  /**
  * The optional component to render in under the table header
  */
  subHeaderComponent: PropTypes.func,
  /**
   * The function when the table data is updated
   */
  onUpdate: PropTypes.func
};

export default TransactionList;