import React from 'react';

import { generateColumns, LimitsTable } from './LimitsTable';
import { adminApi } from '../../api';

function AdminLimitOverrides({ t, history, user, env }) {
  const columns = generateColumns(t, env, true);
  const pageTitle = t('admin.limits.titleOverridePlural');
  const makeNew = () => history.push('/admin/limit_override/new');
  const onRowClick = (row) => history.push(`/admin/limit_override/${row.original.uuid}/detail`);

  return (
    <LimitsTable
      t={t}
      history={history}
      user={user}
      makeNew={makeNew}
      onRowClick={onRowClick}
      pageTitle={pageTitle}
      columns={columns}
      fetchData={adminApi.fetchLimitOverrides}
      isLimitOverride
    />
  );
}

export default AdminLimitOverrides;
