import React from 'react';
import { Button, Card, Col, Nav, Tab, Table, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { hasPermissions, isAdminView } from '../../utils';
import PageTitle from '../common/PageTitle';

export function AppCardHeader({ children, setShowFreezeModal, app, t, user }) {
  const canFreeze = () => {
    if (isAdminView()) return true;
    return hasPermissions(user, 'apps', 'Write');
  };
  const history = useHistory();

  const handleNavigation = (path) => {
    history.push(path);
  };

  return (
    <Card className="loaded">
      <Card.Header className="d-flex justify-content-between align-items-center space p-4">
        <h4
          className="d-md-inline d-md-none text-truncate-on-mobile mb-0"
          onClick={() => handleNavigation(`/app/${app.id}/detail`)}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === ' ') {
              handleNavigation(`/app/${app.id}/detail`);
            }
          }}
        >
          {isAdminView() ? (
            <span className="font-normal">{app.nickname}</span>
          ) : (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-name" className="custom-tooltip">
                    Name
                  </Tooltip>
                }
              >
                <span className="font-normal hover-underline">{app.brand_name}</span>
              </OverlayTrigger>
              <span className="mx-2">&#47; </span>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-handle" className="custom-tooltip">
                    Handle
                  </Tooltip>
                }
              >
                <span className="font-normal hover-underline">{app.handle}</span>
              </OverlayTrigger>
            </>
          )}
        </h4>
        {/* TODO: Add this information to a 'Capabilties' section for app detail view */}
        {/* {!isAdminView() && (
          <ul className="d-none d-md-flex ml-md-auto list-unstyled mb-0">
            {app.vaccount_enabled && (
              <li>
                <Button
                  variant="link"
                  className="text-underline"
                  href={VACCOUNTS_COMPLIANCE_URL}
                  target="_blank"
                >
                  {t('applications.buttons.compliance')}
                </Button>
              </li>
            )}
            <li className="d-flex align-items-center justify-content-end">
              {app.vaccount_enabled && (
                <span className="badge badge-pill no-background border px-3 py-2 text-muted mr-4">
                  <i className="fas fa-circle text-success ml-0 mr-1" />
                  {t('applications.labels.vaccountTag')}
                </span>
              )}
              <span className="badge badge-pill no-background border px-3 py-2 text-muted mr-4">
                <i
                  className={`fas fa-circle ${
                    app.instant_settlement_enabled === 'disabled'
                      ? 'text-danger'
                      : app.instant_settlement_enabled === 'enabled'
                      ? 'text-success'
                      : app.instant_settlement_enabled === 'insufficient funds'
                      ? 'text-caution'
                      : ''
                  } ml-0 mr-1`}
                />
                {t('applications.instantSettlement.title')}
              </span>
            </li>
          </ul>
        )} */}
        {/* END TODO */}
        <div className="d-flex">
          <p
            className={`text-lg ${
              !app.frozen ? 'text-success' : 'text-danger'
            } ml-0 mr-4 mb-0 d-flex text-left`}
          >
            {!app.frozen ? t('common:status.enabled') : t('common:status.disabled')}
          </p>
          {canFreeze() && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setShowFreezeModal(true);
              }}
              className="generate mr-0 rounded-sm py-1"
              variant="primary"
              size="sm"
            >
              {t(`common:buttons.${app.frozen === true ? 'unfreeze' : 'freeze'}`)}
            </Button>
          )}
          {children}
        </div>
      </Card.Header>
    </Card>
  );
}

export function AppCardBody({ children, header, setHeader }) {
  return (
    <Card.Body as={Row} className="p-0 bg-light" noGutters>
      <Tab.Container activeKey={header} defaultActiveKey={header} onSelect={(h) => setHeader(h)}>
        {children}
      </Tab.Container>
    </Card.Body>
  );
}

export function AppProfileNavItem({ type, appId, t, route, setHeader = null, disabled = false }) {
  const history = useHistory();
  const location = useLocation();

  const handleSelect = () => {
    if (isAdminView() && !setHeader) {
      history.push(`/admin/app/${appId}/${route}`);
    } else if (!location.pathname.includes('/apps')) {
      history.push(`/app/${appId}/${route}`);
    } else {
      setHeader(route);
    }
  };

  return (
    <Nav.Item className="w-100">
      <Nav.Link
        className={`d-block px-4 py-3 ${type === route ? ' bg-primary-accent text-primary' : ''}${
          disabled ? ' opacity-25' : ''
        }`}
        as="div"
        onClick={!disabled ? handleSelect : undefined}
      >
        {isAdminView()
          ? t(`admin.applications.navigation.${route}`)
          : t(`applications.buttons.${route}`)}
      </Nav.Link>
    </Nav.Item>
  );
}

export default AppProfileNavItem;

export function AppProfileTabColumn({ children }) {
  return (
    <Col md="4" lg="3" xl="2" className="border-right">
      <Nav className="d-flex flex-column align-content-start h-100 pd-4">{children}</Nav>
    </Col>
  );
}

export function AppProfileDetailColumn({ children }) {
  return (
    <Col md="8" lg="9" xl="10" className="p-4">
      <Nav className="d-flex flex-column align-content-start h-100 pd-4">{children}</Nav>
    </Col>
  );
}

export function AdminAppDetailView({ app, t }) {
  return (
    <div
      className="bg-info px-4 py-3 mt-n4 ml-n4 mr-n4 mb-4 overflow-auto"
      style={{ maxHeight: '130px' }}
    >
      <Table borderless style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th className="text-primary pt-0 pb-1">
              {t('admin.applications.detail.labels.ownerApp')}
            </th>
            <th className="text-primary pt-0 pb-1">
              {t('admin.applications.detail.labels.ownerEmail')}
            </th>
            <th className="text-primary pt-0 pb-1">
              {t('admin.applications.detail.labels.ownerPhone')}
            </th>
          </tr>
        </thead>
        <tbody>
          {app.team_members
            .filter((member) => member.role_name === 'owner')
            .map((member) => (
              <tr key={member.login_handle}>
                <td className="text-info py-1">{member.login_name}</td>
                <td className="text-info py-1">
                  {member.login_emails.length ? (
                    member.login_emails.map(
                      ({ email }, index) =>
                        `${email}${member.login_emails.length - 1 !== index ? ', ' : ''}`
                    )
                  ) : (
                    <span className="text-info">{t('common:labels.na')}</span>
                  )}
                </td>
                <td className="text-info py-1">
                  {member.login_phones.length ? (
                    member.login_phones.map(
                      ({ phone }, index) =>
                        `${phone}${member.login_phones.length - 1 !== index ? ', ' : ''}`
                    )
                  ) : (
                    <span className="text-info">{t('common:labels.na')}</span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export function AppDetailHeader({ t }) {
  const url = isAdminView() ? '/admin/apps' : '/apps';
  return (
    <Row noGutters className="page-header mb-3 mb-md-5">
      <PageTitle title={t('admin.applications.detail.title')}>
        <Button variant="outline-light" className="back text-info" to={url} as={NavLink}>
          {t('common:buttons.back')}
        </Button>
      </PageTitle>
    </Row>
  );
}
