import React, { useState, useRef } from 'react';
import { Accordion, Table, Button, Form } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next'
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { adminApi } from '../../api';
import { useAlerts } from '../context';

import { UUID_REG_EXP } from '../../constants';
import { formatDateAndTime } from '../../utils';

import AccordionItem from '../common/AccordionItem';
import LoaderButton from '../common/LoaderButton';
import ConfirmModal from '../common/ConfirmModal';
import FieldWrapper from '../form/wrappers/Field';

const OnboardingProfileForm = ({ match, details, initialValues, owners, hasWriteAccess }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const [currentCustomer, setCurrentCustomer] = useState({ show: false, data: undefined });
  const [expanded, setExpanded] = useState([1, 2, 3]);
  const formRef = useRef(null);

  const handleExpanded = (key) => setExpanded(expanded.includes(key) ? expanded.filter(e => e !== key) : [...expanded, key]);
  const accordionItemProps = { expanded, onSetExpanded: handleExpanded };
  const accordionActiveKey = (key) => expanded.includes(key) && key;

  const updateCustomer = async (values) => {
    if (hasWriteAccess && values) {
      try {
        await adminApi.updateCustomerInfo(match.params.id, values);
        setAlert({ message: t('admin.customers.onboarding.profile.messages.success'), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentCustomer({ ...currentCustomer, show: false, data: undefined });
        formRef.current.setSubmitting(false);
      }
    } else {
      formRef.current.setSubmitting(false);
      setCurrentCustomer({ ...currentCustomer, show: false, data: undefined });
    }
  };

  return <>
    <Accordion id="profile" className="mb-3" activeKey={accordionActiveKey(1)}>
      <AccordionItem className="border rounded"
        eventKey={1}
        label={t('admin.customers.onboarding.profile.headers.info')}
        {...accordionItemProps}>
        <div className="m-n4">
          <Table responsive>
            <thead>
              <tr>
                <th className="py-2 pl-4 border-top" width="50%">{t('common:labels.detail')}</th>
                <th className="py-2 pr-4 border-top" width="50%">{t('common:labels.data')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.customer')}:</td>
                <td className="py-2 pr-4" width="50%">{details.name}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.labels.name')}:</td>
                <td className="py-2 pr-4" width="50%">{details.contact_name}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:form.fields.email.label')}:</td>
                <td className="py-2 pr-4" width="50%">{details.email}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:form.fields.phone.label')}:</td>
                <td className="py-2 pr-4" width="50%">{details.phone}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.labels.created')}:</td>
                <td className="py-2 pr-4" width="50%">{formatDateAndTime(details.pipedrive_date)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </AccordionItem>
    </Accordion>

    <p className="text-info mb-4"><Trans i18nKey="admin.customers.onboarding.profile.description">Customer information is read-only, any edits to this data should be done in <Button href="https://app.pipedrive.com/auth/login" target="_blank" variant="link" className="p-0">Pipedrive</Button>.</Trans></p>

    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        payment_status: yup.string().nullable(),
        requested_products: yup.array().nullable(),
        kyc_levels_approved: yup.array().nullable(),
        products_post_msa: yup.string().nullable(),
        funds_flow_final: yup.string().nullable().trim()
          .url(t('common:form.fields.url.error')),
        third_party_partners: yup.string().nullable(),
        limited_production_access_date: yup.string().nullable(),
        std_production_access_date: yup.string().nullable(),
        start_date: yup.string().nullable(),
        due_date: yup.string().nullable(),
        sales_rep: yup.string().nullable(),
        business_description: yup.string().nullable()
          .max(500, t('common:form.errors.max', { count: 500 })),
        website: yup.string().nullable().trim()
          .url(t('common:form.fields.url.error')),
        terms: yup.string().nullable(),
        privacy_policy: yup.string().nullable(),
        ach_company_name: yup.string().nullable(),
        ach_company_id: yup.string().nullable(),
        deal_uuid: yup.string().nullable()
          .matches(UUID_REG_EXP, {
            message: t('common:form.fields.uuid.error')
          }),
        kyc_levels_used: yup.string().nullable(),
        products_approved: yup.array().nullable(),
        funds_flow_wip: yup.string().nullable().trim()
          .url(t('common:form.fields.url.error')),
        product_summary: yup.string().nullable()
          .max(500, t('common:form.errors.max', { count: 500 })),
        mobile_apps: yup.string().nullable(),
        external_payment_providers: yup.string().nullable(),
        international_payment_providers: yup.string().nullable(),
        business_license_number: yup.string().nullable(),
        ownership: yup.string().nullable(),
        platform_type: yup.string().nullable()
          .max(500, t('common:form.errors.max', { count: 500 })),
      })}
      onSubmit={(values) => setCurrentCustomer({ ...currentCustomer, show: true, data: values })}>
      {({ handleSubmit, errors, dirty, isSubmitting }) => (

        <Form noValidate id="onboardingProfile" className="overflow-hidden" autoComplete="new-password" onSubmit={handleSubmit}>

          <Accordion id="onboarding" className="mb-4" activeKey={accordionActiveKey(2)}>
            <AccordionItem className="border rounded"
              eventKey={2}
              label={t('admin.customers.onboarding.profile.headers.progress')}
              {...accordionItemProps}>
              <div className="m-n4">
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th className="py-2 pl-4 border-top" width="50%">{t('common:labels.detail')}</th>
                      <th className="py-2 pr-4 border-top" width="50%">{t('common:labels.data')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.assignee')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="assignee m-0"
                          size="sm"
                          id="onboardingProfile.assignee"
                          name="assignee"
                          type="select"
                          disabled={!hasWriteAccess}
                          options={owners.map(owner => ({ value: owner.handle, label: owner.name }))}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.payment')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="payment_status m-0"
                          size="sm"
                          id="onboardingProfile.payment_status"
                          name="payment_status"
                          type="select"
                          options={initialValues.payment_status_list.map(ps => ({ value: ps.value, label: ps.label }))}
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.productsRequested')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="requested_products m-0"
                          size="sm"
                          id="onboardingProfile.requested_products"
                          name="requested_products"
                          type="select"
                          multiple={initialValues.products_list.length !== 0}
                          options={initialValues.products_list.map(pl => ({ value: pl.value, label: pl.label }))}
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.kycApproved')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="kyc_levels_approved m-0"
                          size="sm"
                          id="onboardingProfile.kyc_levels_approved"
                          name="kyc_levels_approved"
                          type="select"
                          multiple={initialValues.kyc_levels_list.length !== 0}
                          options={initialValues.kyc_levels_list.map(pl => ({ value: pl, label: pl }))}
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.productsAdded')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="products_added m-0"
                          size="sm"
                          id="onboardingProfile.products_added"
                          name="products_added"
                          type="select"
                          multiple={initialValues.products_list.length !== 0}
                          options={initialValues.products_list.map(pl => ({ value: pl.value, label: pl.label }))}
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.fundsFlowFinal')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="funds_flow_final m-0"
                          size="sm"
                          id="onboardingProfile.funds_flow_final"
                          name="funds_flow_final"
                          disabled={!hasWriteAccess}
                          placeholder={t('common:form.fields.url.placeholder')}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.partners')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="third_party_partners m-0"
                          size="sm"
                          id="onboardingProfile.third_party_partners"
                          name="third_party_partners"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.limitedProdAccess')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="limited_production_access_date m-0"
                          size="sm"
                          type="date"
                          id="onboardingProfile.limited_production_access_date"
                          name="limited_production_access_date"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.stdProdAccess')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="std_production_access_date m-0"
                          size="sm"
                          type="date"
                          id="onboardingProfile.std_production_access_date"
                          name="std_production_access_date"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.start')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="start_date m-0"
                          size="sm"
                          type="date"
                          id="onboardingProfile.start_date"
                          name="start_date"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.due')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="due_date m-0"
                          size="sm"
                          id="onboardingProfile.due_date"
                          type="date"
                          name="due_date"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.rep')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="sales_rep m-0"
                          size="sm"
                          id="onboardingProfile.sales_rep"
                          name="sales_rep"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </AccordionItem>
          </Accordion>

          <Accordion id="business" className="mb-4" activeKey={accordionActiveKey(3)}>
            <AccordionItem className="border rounded"
              eventKey={3}
              label={t('admin.customers.onboarding.profile.headers.business')}
              {...accordionItemProps}>
              <div className="m-n4">
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th className="py-2 pl-4 border-top" width="50%">{t('common:labels.detail')}</th>
                      <th className="py-2 pr-4 border-top" width="50%">{t('common:labels.data')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.businessDesc')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required hideError className="business_description m-0"
                          id="onboardingProfile.business_description"
                          fieldClass={`no-resize${errors.business_description ? ' border-danger' : ''}`}
                          size="sm"
                          type="elastic"
                          rows="3"
                          name="business_description"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.platformType')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required hideError className="platform_type m-0"
                          id="onboardingProfile.platform_type"
                          fieldClass={`no-resize${errors.platform_type ? ' border-danger' : ''}`}
                          size="sm"
                          type="elastic"
                          rows="3"
                          name="platform_type"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.website')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="website m-0"
                          id="onboardingProfile.website"
                          size="sm"
                          name="website"
                          disabled={!hasWriteAccess}
                          placeholder={t('common:form.fields.url.placeholder')}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.terms')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="terms m-0"
                          id="onboardingProfile.terms"
                          size="sm"
                          name="terms"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.privacy')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="privacy_policy m-0"
                          id="onboardingProfile.privacy_policy"
                          size="sm"
                          name="privacy_policy"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.achName')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="ach_company_name m-0"
                          size="sm"
                          id="onboardingProfile.ach_company_name"
                          name="ach_company_name"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.achId')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="ach_company_id m-0"
                          id="onboardingProfile.ach_company_id"
                          size="sm"
                          name="ach_company_id"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.dealUuid')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="deal_uuid m-0"
                          id="onboardingProfile.deal_uuid"
                          size="sm"
                          name="deal_uuid"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.kycRequested')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="kyc_levels_requested m-0"
                          id="onboardingProfile.kyc_levels_requested"
                          size="sm"
                          name="kyc_levels_requested"
                          type="select"
                          multiple={initialValues.kyc_levels_list.length !== 0}
                          options={initialValues.kyc_levels_list.map(pl => ({ value: pl, label: pl }))}
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.productsApproved')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="products_approved m-0"
                          id="onboardingProfile.products_approved"
                          size="sm"
                          name="products_approved"
                          type="select"
                          multiple={initialValues.products_list.length !== 0}
                          options={initialValues.products_list.map(pl => ({ value: pl.value, label: pl.label }))}
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.fundsFlowWip')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="funds_flow_wip m-0"
                          id="onboardingProfile.funds_flow_wip"
                          size="sm"
                          name="funds_flow_wip"
                          disabled={!hasWriteAccess}
                          placeholder={t('common:form.fields.url.placeholder')}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.productSummary')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required hideError className="product_summary m-0"
                          id="onboardingProfile.product_summary"
                          fieldClass={`no-resize${errors.product_summary ? ' border-danger' : ''}`}
                          size="sm"
                          type="elastic"
                          rows="3"
                          name="product_summary"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                        {errors.product_summary && <p className="text-sm text-danger mt-2 mb-0">{errors.product_summary}</p>}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.mobile')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="mobile_apps m-0"
                          id="onboardingProfile.mobile_apps"
                          size="sm"
                          name="mobile_apps"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.extPayments')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="external_payment_providers m-0"
                          id="onboardingProfile.external_payment_providers"
                          size="sm"
                          name="external_payment_providers"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.intPayments')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="international_payment_providers m-0"
                          id="onboardingProfile.international_payment_providers"
                          size="sm"
                          name="international_payment_providers"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.businessLicense')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="business_license_number m-0"
                          id="onboardingProfile.business_license_number"
                          size="sm"
                          name="business_license_number"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pl-4" width="50%">{t('admin.customers.onboarding.profile.labels.ownership')}:</td>
                      <td className="py-2 pr-4" width="50%">
                        <Field required className="ownership m-0"
                          size="sm"
                          type="select"
                          options={[{
                            value: 'domestic',
                            label: t('admin.customers.onboarding.profile.options.domestic')
                          }, {
                            value: 'international',
                            label: t('admin.customers.onboarding.profile.options.international')
                          }]}
                          id="onboardingProfile.ownership"
                          name="ownership"
                          disabled={!hasWriteAccess}
                          component={FieldWrapper} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </AccordionItem>
          </Accordion>

          {hasWriteAccess && <LoaderButton className="float-right" loading={isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={!dirty || Object.keys(errors).length}>{t('common:buttons.save')}</LoaderButton>}

        </Form>
      )}
    </Formik>

    {currentCustomer.data && <ConfirmModal
      show={currentCustomer.show}
      data={currentCustomer.data}
      title={t('admin.customers.onboarding.profile.confirm.title')}
      renderMessageComponent={() => <>
        <p className="text-lg mb-4">{t('admin.customers.onboarding.profile.confirm.description', { name: details.name })}</p>
        <p className="text-info">{t(`admin.customers.onboarding.profile.confirm.note`, { name: currentCustomer.name, handle: currentCustomer.handle })}</p>
      </>}
      buttonLabel={t('common:buttons.save')}
      onHide={updateCustomer} />}

  </>;
};

OnboardingProfileForm.propTypes = {
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired,
  /**
   * The details of the customer
   */
  details: PropTypes.object.isRequired,
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The owners array
   */
  owners: PropTypes.array.isRequired,
  /**
   * The write access boolean
   */
   hasWriteAccess: PropTypes.bool.isRequired
};

export default OnboardingProfileForm;