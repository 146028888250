import React/*, { useState } */ from 'react';
// import { Dropdown } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// import { Filter } from './TableFilters';

const TableFilter = ({ column, filters, index }) => {
  // const [show, setShow] = useState(false);
  // const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center">
      <div {...column.getSortByToggleProps([
        {
          className: classNames(
            'd-flex flex-nowrap align-items-center',
            column.labelClasses && column.labelClasses({ column, index })
          ),
          style: column.labelStyles ? column.labelStyles({ column, index }) : null,
        }
      ])}>
        {column.render('Header')}
        {column.canSort && <i className={`${!column.isSorted ? 'text-ultra-light' : 'text-primary'} fas ml-2 ${!column.isSorted ? 'fa-sort' : column.isSortedDesc ? 'fa-sort-down' : 'fa-sort-up'}`}></i>}
      </div>
      {column.renderHeader && <>{column.renderHeader(column)}</>}
      {/* {column.canFilter && <Dropdown onToggle={(visible) => setShow(visible)}>
        <Dropdown.Toggle variant="link" title={t('common:filters.labels.toggle')} bsPrefix="p-0 ml-2 text-decoration-none">
          <i className={`sila-icon filter text-sm ${(show || filters.some(filter => filter.id === column.id)) ? 'text-primary' : 'text-info'}`}></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 w-auto" style={{ minWidth: 200 }}>
          <h4 className="bg-primary text-hdr-sml text-white px-3 py-2 rounded-top mb-0">{t('common:filters.headers.filter')}</h4>
          <div className="p-3"><Filter column={column} show={show} /></div>
        </Dropdown.Menu>
      </Dropdown>} */}
    </div>);
};

TableFilter.propTypes = {
  /**
   * The table colum object
   */
  column: PropTypes.object.isRequired,
  /**
   * The table filters array
   */
  filters: PropTypes.array
};

export default TableFilter;