import React from 'react';
import PropTypes from 'prop-types';

import FormGroupWrapper from './FormGroup';

import FileUploadWrapper from '../types/FileUpload';
import SelectWrapper from '../types/Select';
import CheckBoxWrapper from '../types/CheckBox';
import PasswordFieldWrapper from '../types/PasswordField';
import ElasticFieldWrapper from '../types/ElasticField';
import TextFieldWrapper from '../types/TextField';

const FieldWrapper = (props) => {
  const { id, label, type, note, form, field, className, autofocus, material, prepend, prependClass, append, appendClass, inputRef, readOnly, plaintext, error, handleBlur, handleFocus, is, required, showRequired, hideError, disabled, multiple, ...attrs } = props;
  const groupProps = { id, type, note, name: field.name, className, form, autofocus, material, append, inputRef, readOnly, plaintext, error, handleBlur, handleFocus, hideError, disabled, required, showRequired }
  const fieldProps = { autoComplete: 'off', type, label, field, prepend, prependClass, append, appendClass, inputRef, readOnly, plaintext, is, required, disabled }
  switch (type) {
    case 'file':
      return (
        <FormGroupWrapper {...groupProps}>
          <FileUploadWrapper
            {...fieldProps}
            {...attrs}
          />
        </FormGroupWrapper>
      );
    case 'select':
      return (
        <FormGroupWrapper multiple={multiple} {...groupProps}>
          <SelectWrapper
            {...fieldProps}
            {...attrs}
            multiple={multiple}
            autofocus={autofocus}
          />
        </FormGroupWrapper>
      );
    case 'checkbox':
      return (
        <FormGroupWrapper {...groupProps}>
          <CheckBoxWrapper
            {...fieldProps}
            {...attrs}
          />
        </FormGroupWrapper>
      );
    case 'password':
      return (
        <FormGroupWrapper {...groupProps}>
          <PasswordFieldWrapper
            form={form}
            {...fieldProps}
            {...attrs}
          />
        </FormGroupWrapper>
      );
    case 'elastic':
      return (
        <FormGroupWrapper {...groupProps}>
          <ElasticFieldWrapper
            {...fieldProps}
            {...attrs}
          />
        </FormGroupWrapper>
      );
    default:
      return (
        <FormGroupWrapper {...groupProps}>
          <TextFieldWrapper
            {...fieldProps}
            {...attrs}
          />
        </FormGroupWrapper>
      );
  }
}

FieldWrapper.propTypes = {
  /**
   * form is required and must be an object
   */
  form: PropTypes.object.isRequired,
  /**
   * field is required and must be an object
   */
  field: PropTypes.object.isRequired
};

export default FieldWrapper;