import React from 'react';
import classNames from 'classnames';

import { Container, Row, Col, Card } from 'react-bootstrap';

import Loader from '../components/common/Loader';
import MainFooter from '../components/layout/MainFooter';
import NavbarGuest from '../components/layout/MainNavbar/NavbarGuest';

import featureListItems from '../constants/featureListItems';

const SimpleLayout = ({ t, children, app, reduced }) => {
  const classes = classNames('main-navbar border-bottom bg-white px-3');
  const mainClasses = classNames('main flex-column p-0');
  const isVisible = false;
  return (
    <>
      {app.isLoading && <Loader fixed />}
      <Container fluid>
        <Row>
          <Col
            className={mainClasses}
            lg={isVisible ? { span: 9, offset: 3 } : undefined}
            md={isVisible ? { span: 10, offset: 2 } : undefined}
            sm={isVisible ? 12 : undefined}
            as="main"
          >
            <header className={classes}>
              <NavbarGuest />
            </header>
            <section className="main-content d-flex flex-grow-1">
              <Container className={`simple${reduced ? ' reduced' : ''} align-self-start align-self-sm-center p-0 my-0 p-sm-3`}>
                <Row noGutters>
                  <Col className="mx-auto"
                    lg={10}
                    md={12}
                    sm={10}
                    xs={12}>
                    <Card className="overflow-hidden">
                      <Row noGutters>
                        <Col className="decal p-5"
                          lg={{ span: reduced ? 2 : 4 }}
                          md={{ span: reduced ? 2 : 4 }}
                          sm={{ span: 0 }}
                          xs={{ span: 0 }}>
                          {!reduced && <div className="content">
                            <h1 className="text-white mb-0 mb-md-5">{t('register.title')}</h1>
                            <ul className="list-unstyled p-0 d-none d-md-block">
                              {featureListItems.map((feature, index) => <li key={index}><a href={feature.href ? feature.href : undefined} className={`text-white no-underline text-lg mt-3 d-flex${!feature.href ? ' cursor-default' : ''}`} target={feature.href ? '_blank' : undefined}><i className="sila-icon check mr-2 mt-2"></i>{t(feature.title)}</a></li>)}
                            </ul>
                          </div>}
                        </Col>
                        <Col
                          className="d-flex"
                          lg={{ span: reduced ? 10 : 8 }}
                          md={{ span: reduced ? 10 : 8 }}
                          sm={{ span: 0 }}
                          xs={{ span: 0 }}>
                          <Card.Body className={`d-flex flex-grow-1 ${reduced ? `p-0` : 'p-4 p-md-5'}`}>
                            <div className="align-self-center w-100">
                              {children}
                            </div>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
            <MainFooter />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SimpleLayout;