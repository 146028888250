import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TooltipButton from './TooltipButton';

const CopyButton = ({ inputRef, ...rest }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const { t } = useTranslation();

  const copyToClipboard = () => {
    if (inputRef.current) inputRef.current.select();
    document.execCommand('copy');
    setCopySuccess(t('common:labels.copied'))
  };

  useEffect(() => {
    let copyTimer;
    if (copySuccess) copyTimer = setTimeout(() => setCopySuccess(false), 1000);
    return () => clearTimeout(copyTimer);
  }, [copySuccess]);

  return (
    <TooltipButton {...rest}
      onClick={copyToClipboard}
      text={copySuccess ? copySuccess : t('common:labels.copy')}>
      <i className="fas fa-copy text-lg"></i>
    </TooltipButton>
  )
};

CopyButton.propTypes = {
  /**
   * DOM element for input field to copy from
   */
  inputRef: PropTypes.any.isRequired
};

export default CopyButton;