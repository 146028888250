import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Form, Alert } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { adminApi } from '../../api';

import { formatDateAndTime, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import FieldWrapper from '../../components/form/wrappers/Field';
import LoaderButton from '../../components/common/LoaderButton';
import ConfirmModal from '../../components/common/ConfirmModal';

const ChecklistOwner = ({ t, match, history, user, env, setAlert }) => {
  const [initialValues, setInitialValues] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [confirm, setConfirm] = useState({ delete: false, update: false, data: undefined });
  const [redirect, setRedirect] = useState(false);
  const hasWriteAccess = hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]);

  const getCustomerChecklistOwner = async (id) => {
    try {
      const response = await adminApi.getCustomerChecklistOwner(id);
      setInitialValues(response.owner);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setRedirect(true);
    } finally {
      setLoaded(true);
    }
  };

  const createCustomerChecklistOwner = async (values) => {
    try {
      if (!hasWriteAccess) return;
      await adminApi.createCustomerChecklistOwner(values);
      setAlert({ message: t('admin.customers.owners.profile.messages.create'), variant: 'success' });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setLoaded(true);
      setRedirect(true);
    }
  };

  const updateCustomerChecklistOwner = async (values) => {
    if (hasWriteAccess && values) {
      try {
        await adminApi.updateCustomerChecklistOwner(match.params.id, values);
        setAlert({ message: t('admin.customers.owners.profile.messages.update'), variant: 'success' });
        setConfirm({ ...confirm, update: false });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setLoaded(true);
        setRedirect(true);
      }
    } else {
      setConfirm({ ...confirm, update: false });
    }
  };

  const deleteCustomerChecklistOwner = async (values) => {
    if (hasWriteAccess && values) {
      try {
        await adminApi.deleteCustomerChecklistOwner(match.params.id);
        setAlert({ message: t('admin.customers.owners.profile.messages.delete'), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setLoaded(true);
        setRedirect(true);
      }
    } else {
      setConfirm({ ...confirm, delete: false });
    }
  };

  useEffect(() => {
    if (match.params.id == 'new' && !hasWriteAccess) history.push('/admin/customers/owners');
  }, []);

  useEffect(() => {
    if (redirect) history.push('/admin/customers/owners');
  }, [redirect]);

  useEffect(() => {
    if (match.params.id !== 'new') getCustomerChecklistOwner(match.params.id);
  }, [env, user.currentTeam]);

  useEffect(() => {
    const getDepartments = async () => {
      try {
        const response = await adminApi.fetchDepartments();
        setDepartments(response.departments);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        setRedirect(true);
      } finally {
        setLoaded(true);
      }
    };
    getDepartments();
  }, []);

  return (
    <Container className="sales-tier main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5 loaded">
        <PageTitle title={t('admin.customers.owners.profile.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {!loaded ? <Loader /> :
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            first_name: yup.string()
              .required(t('common:form.errors.required', { field: t('common:form.fields.firstName.label') })),
            last_name: yup.string()
              .required(t('common:form.errors.required', { field: t('common:form.fields.lastName.label') })),
            email: yup.string()
              .email(t('common:form.fields.email.error'))
              .max(254, t('common:form.errors.max', { count: 254 }))
              .required(t('common:form.errors.required', { field: t('common:form.fields.email.label') })),
            department: yup.string()
              .required(t('common:form.errors.required', { field: t('admin.customers.owners.labels.department') })),
          })}
          onSubmit={(values) => match.params.id === 'new' ? createCustomerChecklistOwner(values) : updateCustomerChecklistOwner(values)}>
          {({ values, errors, handleSubmit, isSubmitting, dirty }) => (
            <Form noValidate autoComplete="new-password" className="position-relative" onSubmit={handleSubmit}>
              <Card className="loaded flex-grow-1">
                <Card.Header className="d-block d-sm-flex justify-content-between align-content-stretch align-items-center p-4">
                  <h3 className="m-0">{match.params.id === 'new' ? t('admin.customers.owners.list.buttons.create') : `${values.first_name} ${values.last_name}`}</h3>
                </Card.Header>
                <Card.Body className="p-4 d-flex align-content-stretch flex-column">
                  {match.params.id !== 'new' && <Row noGutters className="bg-info px-4 py-3 mt-n4 ml-n4 mr-n4 mb-4">
                    <Col className="d-lg-flex align-items-center">
                      <span className="d-block text-primary">{t('common:labels.created')}:</span>
                      <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">{formatDateAndTime(initialValues.created)}</span>
                    </Col>
                    <Col className="d-lg-flex align-items-center">
                      <span className="d-block text-primary">{t('common:labels.createdBy')}:</span>
                      <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">{initialValues.created_by}</span>
                    </Col>
                    <Col className="d-lg-flex align-items-center">
                      <span className="d-block text-primary">{t('common:labels.lastUpdated')}:</span>
                      <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">{formatDateAndTime(initialValues.modified)}</span>
                    </Col>
                    {initialValues.last_updated && <Col className="d-lg-flex align-items-center">
                      <span className="d-block text-primary">{t('common:labels.updatedBy')}:</span>
                      <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">{initialValues.last_updated}</span>
                    </Col>}
                  </Row>}
                  <p className="text-info mb-4">{t(`admin.customers.owners.${ match.params.id === 'new' ? 'create' : 'profile'}.description`)}</p>

                  {isSubmitting && <Loader />}

                  {dirty && <Alert variant="warning" className="mb-4 loaded">{t('common:form.messages.unsavedChanges')}</Alert>}

                  <Form.Row>
                    <Col xs="12" sm="6">
                      <Field autofocus className="first-name"
                        id="ownerForm.first_name"
                        label={t('common:form.fields.firstName.label')}
                        name="first_name"
                        disabled={match.params.id !== 'new'}
                        readOnly={match.params.id !== 'new'}
                        component={FieldWrapper} />
                    </Col>
                    <Col xs="12" sm="6">
                      <Field autofocus className="last-name"
                        id="ownerForm.last_name"
                        label={t('common:form.fields.lastName.label')}
                        name="last_name"
                        disabled={match.params.id !== 'new'}
                        readOnly={match.params.id !== 'new'}
                        component={FieldWrapper} />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col xs="12" sm="6">
                      <Field autofocus className="email"
                        id="ownerForm.emails"
                        label={t('common:form.fields.email.label')}
                        name="email"
                        disabled={match.params.id !== 'new'}
                        readOnly={match.params.id !== 'new'}
                        component={FieldWrapper} />
                    </Col>
                    <Col xs="12" sm="6">
                      <Field autofocus required
                        type="select"
                        id="ownerForm.department"
                        name="department"
                        disabled={!hasWriteAccess}
                        readOnly={!hasWriteAccess}
                        label={t('admin.customers.owners.labels.department')}
                        options={departments.map(department => ({ value: department, label: department }))}
                        component={FieldWrapper} />
                    </Col>
                  </Form.Row>
                </Card.Body>
                <Card.Footer className="border-top-0 text-right pt-0 pl-4 pb-4 pr-4">
                  {hasWriteAccess && match.params.id !== 'new' && <Button className="mr-3" onClick={() => setConfirm({ ...confirm, delete: true, data: values })} variant="outline-warning">{t('common:buttons.delete')}</Button>}
                  <Button variant="outline-light" className="mr-3" as={NavLink} exact={true} to="/admin/customers/owners">{t('common:buttons.cancel')}</Button>
                  {hasWriteAccess && <LoaderButton loading={isSubmitting} type={match.params.id === 'new' ? 'submit' : undefined} onClick={match.params.id !== 'new' ? () => setConfirm({ ...confirm, update: true, data: values }) : undefined} disabled={!dirty || Object.keys(errors).length}>{t('common:buttons.save')}</LoaderButton>}
                </Card.Footer>
              </Card>
            </Form>
          )}
        </Formik>}

      {confirm.update &&
        <ConfirmModal
          show={confirm.update}
          data={confirm.data}
          title={t('admin.customers.owners.profile.confirm.update.title')}
          message={t('admin.customers.owners.profile.confirm.update.description', { name: `${confirm.data.first_name} ${confirm.data.last_name}` })}
          buttonLabel={t('common:buttons.save')}
          onHide={updateCustomerChecklistOwner} />}

      {confirm.delete &&
        <ConfirmModal
          show={confirm.delete}
          data={confirm.data}
          title={t('admin.customers.owners.profile.confirm.delete.title')}
          renderMessageComponent={({ data }) => <>
            <p className="text-lg mb-4">{t('admin.customers.owners.profile.confirm.delete.description', { name: `${confirm.data.first_name} ${confirm.data.last_name}` })}</p>
            <p className="text-info mb-4">{t('admin.customers.owners.profile.confirm.delete.note')}</p>
          </>}
          buttonLabel={t('common:buttons.delete')}
          buttonVariant="warning"
          onHide={deleteCustomerChecklistOwner} />}

    </Container>
  );
};

export default ChecklistOwner;