import React from 'react';
import { Container, Row } from 'react-bootstrap';

import PageTitle from '../../components/common/PageTitle';
import PageSections from '../../components/common/PageSections';

import { hasSalesAccess } from '../../utils';

const salesNavItems = [
  {
    name: 'sales.CheckRoutingNumberDescriptionName',
    items: [
      {
        title: 'sales.CheckRoutingNumberTitle',
        description: 'sales.CheckRoutingNumberDescription',
        to: '/check_routing_number',
      },
    ],
    permissions: (user) => hasSalesAccess(user),
  },
];

function Sales({ t }) {
  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('sales.title')} />
      </Row>

      <PageSections items={salesNavItems} />
    </Container>
  );
}

export default Sales;
