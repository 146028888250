import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

const Maintenance = () => {
  const { t } = useTranslation();
  return (
    <Container className="maintenance splash main-content-container d-flex flex-grow-1 p-5">
      <div className="splash__content">
        <i className="fas fa-wrench"></i>
        <h3>{t('maintenance.title')}</h3>
        <p className="text-lg">{t('maintenance.description')}</p>
        <p><Trans i18nKey="maintenance.action">For emergency issues, please <a href="https://sila.atlassian.net/servicedesk/customer/portal/2/group/3/create/9" target="_blank">contact support</a>.</Trans></p>
      </div>
    </Container>
  )
};

export default Maintenance;