import React, { Fragment } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useAuth } from '../context';

import DisabledOverlay from './DisabledOverlay';

const PageSections = ({ items }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const location = useLocation();
  const filteredItems = items.filter(item => item.permissions ? item.permissions(user) : !item.permissions);
  return filteredItems.length === 0 ? <DisabledOverlay /> : <> {filteredItems.map((section, sectionIndex) => <Fragment key={sectionIndex}>
    <h3 className="text-info mb-3">{t(section.name)}</h3>
    <Card className="mb-5">
      <Card.Body>
        <Row as="ul" className="list-unstyled mb-0 mt-n4">
          {section.items.filter(item => item.permissions ? item.permissions(user) : !item.permissions).map((item, itemIndex) => <Col key={itemIndex} as="li" className="mt-4" sm="12" md="4">
            <Button as={NavLink} variant="link" className="p-0 no-underline text-left" to={{ pathname: `${match.path}${item.to}`, state: { from: location.pathname } }}>
              <span className="lnk text-primary d-block mb-2 font-weight-medium">{t(item.title)}</span>
              <span className="text-info text-sm">{t(item.description)}</span>
            </Button>
          </Col>)}
        </Row>
      </Card.Body>
    </Card>
  </Fragment>)}
  </>;
}

PageSections.propTypes = {
  /**
   * The items array
   */
  items: PropTypes.array.isRequired
};

export default PageSections;