import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import routes from '../routes';

const Errors = ({ t, history, location, status }) => {
  return (
    <Container className="error splash main-content-container d-flex flex-grow-1 p-5">
      <div className="splash__content align-self-center">
        <i className="sila-icon sad-cloud"></i>
        {(status === 404 || status === 500) && <h2>{status}</h2>}
        {status === 404 && <>
          <h3>{t('errors.404.title')}</h3>
          <p className="text-lg">{t('errors.404.description')}</p>
        </>}
        {status === 500 &&
          <>
            <h3>{t('errors.500.title')}</h3>
            <p className="text-lg">{t('errors.500.description')}</p>
          </>}
        {status === 'frozen' &&
          <>
            <h3>{t('errors.frozen.title')}</h3>
            <p className="text-lg">{t('errors.frozen.description1')}</p>
            <p><Trans i18nKey="errors.frozen.description2">Please contact our <a href="mailto:customer-success@silamoney.com">Customer Success Team</a> to request access. We will review your request within 2 business days.</Trans></p>
            <p className="text-info text-center">{t('errors.frozen.note')}</p>
          </>}
        <Button className="back" onClick={() =>  history.push({ pathname: '/welcome', state: { from: location.pathname } }) }>{t('common:buttons.goBack')}</Button>
      </div>
    </Container>
  )
};

export default Errors;
