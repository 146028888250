import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

const PageTitle = ({ title, subtitle, className, prepend, append, children, ...attrs }) => {
  const classes = classNames(
    className,
    'text-center',
    'text-md-left'
  );
  return (
    <Col className={classes} {...attrs}>
      {subtitle && <span className="text-uppercase page-subtitle">{subtitle}</span>}
      <div className="d-block d-md-flex">
        <h1 className="page-title mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start align-items-center">{prepend && <span>{prepend}</span>}<span>{title}</span>{append && <span>{append}</span>}</h1>
        {children && <div className="my-3 my-md-0 ml-sm-auto d-block d-md-flex align-items-center">{children}</div>}
      </div>
    </Col>
  );
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * The children components.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default PageTitle;
