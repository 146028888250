import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { ChromePicker } from 'react-color';

import { appsApi, adminApi } from '../../api';
import { useAuth, useAlerts } from '../context';

import FieldWrapper from '../form/wrappers/Field';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import ConfirmModal from '../common/ConfirmModal';
import DocumentUploadModal from '../common/DocumentUploadModal';

import { PHONE_REG_EXP, POSTAL_REG_EXP, STATES_ARRAY } from '../../constants';

import { uploadDocument } from '../../utils';

const DEFAULT_VALUES = {
  name: '',
  website_url: '',
  phone_number: '',
  email: '',
  street_address_1: '',
  city: '',
  state: '',
  postal_code: '',
  dba_name: '',
  brand_color: '',
  brand_logo_name: ''
};

const StatementInfoForm = ({ initialValues, user, isMobile, onSave, admin }) => {
  const frozenAndNotAdmin = !admin && initialValues.frozen
  const { t } = useTranslation();
  const { env } = useAuth();
  const { setAlert } = useAlerts();
  const history = useHistory();
  const location = useLocation();
  const formRef = useRef(null);

  const [confirm, setConfirm] = useState({ update: false, data: undefined });
  const [upload, setUpload] = useState({ show: false, percentage: {} });
  const [redirect, setRedirect] = useState(false);
  const [colorPicker, setColorPicker] = useState({
    show: false,
    brandColor: initialValues.statement_information && initialValues.statement_information.brand_color ? `#${initialValues.statement_information.brand_color}` : '#000000', color: { r: '0', g: '0', b: '0', a: '1' }
  });

  const handleColorPickerClick = () => {
    setColorPicker({ ...colorPicker, show: !colorPicker.show });
  };

  const handleChangeColor = (color) => {
    setColorPicker({ ...colorPicker, brandColor: color.hex, color: color.rgb });
    formRef.current.setFieldValue('brand_color', color.hex);
  };

  const handleUpload = () => {
    setUpload({ ...upload, show: true });
  }

  const updateStatementInfo = async (values) => {
    if (values) {
      try {
        let response;
        values = { 
          ...values,
          app_id: initialValues.id,
          team_id: user.currentTeam.id
        };
        if (admin) {
          if (initialValues.statement_information && initialValues.statement_information.id) {
            response = await adminApi.updateStatement(initialValues.statement_information.id, values);
          } else {
            response = await adminApi.saveStatement(values);
          }
        } else {
          response = await appsApi.updateStatement(initialValues.statement_information.id, values);
        }
        setAlert({ message: admin ? t(`admin.applications.statementInfo.messages.${initialValues.statement_information && initialValues.statement_information.id ? 'updated' : 'created'}`) : response.message, variant: 'success' });
        if (onSave) onSave(initialValues.statement_information.id, values);
        setRedirect(true);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        if (admin) {
          setConfirm({ ...confirm, update: false, data: undefined });
        }
      }
    } else if (admin) {
      setConfirm({ ...confirm, update: false, data: undefined });
    }
  };

  const uploadLogo = (values, setSubmitting) => {
    if (values) {
      const name = values.files[0].name;
      const data = {
        name,
        filename: name,
        mime_type: values.files[0].type,
        team_id: user.currentTeam.id
      };
      uploadDocument({
        url: `${admin ? '/superuser' : ''}/${initialValues.statement_information.id}/logo_upload`,
        file: values.files[0],
        data: data,
        onUpload: (percentage) => setUpload({ ...upload, percentage: { ...upload.percentage, [name]: percentage } }),
        onError: (status, error) => {
          if (setSubmitting) setSubmitting(false);
          setAlert({ message: status === 413 ? t('common:upload.form.messages.filesize') : error, style: 'danger' })
          setUpload({ ...upload, percentage: {} });
        },
        onSuccess: (message) => {
          formRef.current.setFieldValue('brand_logo_name', name);
          setUpload({ show: false, percentage: upload.percentage });
          delete upload.percentage[name];
          setAlert({ message: message, style: 'success' });
        }
      });
    } else {
      setUpload({ show: false, percentage: {} });
    }
  }

  const removeLogo = async () => {
    let response;
    try {
      if (admin) {
        response = await adminApi.deleteStatementLogo(initialValues.statement_information.id, user.currentTeam.id);
      } else {
        response = await appsApi.deleteStatementLogo(initialValues.statement_information.id, user.currentTeam.id);
      }
      setAlert({ message: response.message, variant: 'success' });
    } catch (error) {
      setAlert({ message: response.message, variant: 'warning' })
    } finally {
      formRef.current.setFieldValue('brand_logo_name', '');
    }
  };

  useEffect(() => {
    if (redirect) history.push({ pathname: admin ? '/admin/apps' : '/apps', state: { from: location.pathname } });
  }, [redirect]);

  return (
    <Formik
      initialValues={initialValues.statement_information || DEFAULT_VALUES}
      validationSchema={admin ? yup.object().shape({
        name: yup.string().trim()
          .required(t('common:form.errors.required', { field: t('common:form.fields.companyName.label') })),
        website_url: yup.string().trim()
          .url(t('common:form.fields.url.error'))
          .required(t('common:form.errors.required', { field: t('common:form.fields.url.label') })),
        phone_number: yup.string().trim()
          .matches(PHONE_REG_EXP, t('common:form.fields.phone.error'))
          .required(t('common:form.errors.required', { field: t('applications.statementInfo.form.fields.supportPhone.label') })),
        email: yup.string().trim()
          .email(t('common:form.fields.email.error'))
          .max(254, t('common:form.errors.max', { count: 254 }))
          .required(t('common:form.errors.required', { field: t('applications.statementInfo.form.fields.supportEmail.label') })),
        street_address_1: yup.string().trim()
          .max(254, t('common:form.errors.max', { count: 254 }))
          .required(t('common:form.errors.required', { field: t('applications.statementInfo.form.fields.supportAddress.label') })),
        city: yup.string().trim()
          .max(96, t('common:form.errors.max', { count: 96 }))
          .required(t('common:form.errors.required', { field: t('common:form.fields.address.city.label') })),
        state: yup.string().trim()
          .required(t('common:form.errors.required', { field: t('common:form.fields.address.state.label') })),
        postal_code: yup.string().trim()
          .matches(POSTAL_REG_EXP, { message: t('common:form.fields.address.zip.error') })
          .required(t('common:form.errors.required', { field: t('common:form.fields.address.zip.label') }))
      }) : undefined}
      innerRef={formRef}
      onSubmit={async (values) => {
        values = { ...values, brand_color: colorPicker.brandColor.replace('#', '') };
        if (admin) {
          setConfirm({ ...confirm, update: true, data: values });
        } else {
          updateStatementInfo(values);
        }
      }}>
      {({ values, errors, dirty, handleSubmit, isSubmitting }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>

          {isSubmitting && <Loader />}

          {admin ? <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h3>{t('admin.applications.statementInfo.title')}</h3>
              <p className="text-primary text-right">{t('admin.applications.statementInfo.messages.asterisk')}</p>
            </div>
            <p className="text-muted m-0 mb-4">{t('admin.applications.statementInfo.description')}</p>
          </> : <p className="text-muted mb-4">{t('applications.labels.statementDescription')}</p>}

          <Row className="mb-4">
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field required showRequired autofocus className="name m-0"
                tabIndex={1}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.name`}
                name="name"
                label={t('common:form.fields.companyName.label')}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field autofocus className="dba m-0"
                tabIndex={2}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.dba_name`}
                name="dba_name"
                label={t('applications.statementInfo.form.fields.dbaName.label')}
                component={FieldWrapper} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm="12" lg="4" className="mb-3 mb-lg-0">
              <Field required showRequired autofocus className="phone m-0"
                tabIndex={3}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.phone_number`}
                name="phone_number"
                label={t('applications.statementInfo.form.fields.supportPhone.label')}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="4" className="mb-3 mb-lg-0">
              <Field required showRequired autofocus className="email m-0"
                tabIndex={4}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.email`}
                label={t('applications.statementInfo.form.fields.supportEmail.label')}
                name="email"
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="4">
              <Field required showRequired autofocus className="website m-0"
                tabIndex={5}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.website_url`}
                label={t('common:form.fields.url.label')}
                name="website_url"
                component={FieldWrapper} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="mb-3 mb-lg-0">
              <Field required showRequired autofocus className="street1 m-0"
                tabIndex={6}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.street_address_1`}
                name="street_address_1"
                label={t('applications.statementInfo.form.fields.supportAddress.label')}
                component={FieldWrapper} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm="12" lg="4" className="mb-3 mb-lg-0">
              <Field required showRequired autofocus className="city m-0"
                tabIndex={7}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.city`}
                name="city"
                label={t('common:form.fields.address.city.label')}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="4" className="mb-3 mb-lg-0">
              <Field required showRequired autofocus className="state m-0"
                tabIndex={8}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.state`}
                name="state"
                type="select"
                label={t('common:form.fields.address.state.label')}
                options={STATES_ARRAY.map(state => ({
                  label: t(`common:form.fields.address.state.options.${state}`),
                  value: state
                }))}
                component={FieldWrapper} />
            </Col>
            <Col sm="12" lg="4">
              <Field required showRequired autofocus className="zip m-0"
                tabIndex={9}
                readOnly={!admin}
                id={`appEditStatementInfoForm.${initialValues.id}.postal_code`}
                name="postal_code"
                label={t('common:form.fields.address.zip.label')}
                component={FieldWrapper} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm="12" lg="6" className="mb-3 mb-lg-0">
              <Field required showRequired autofocus className="brand_color m-0"
                tabIndex={10}
                id={`appEditStatementInfoForm.${initialValues.id}.brand_color`}
                name="brand_color"
                label={t('applications.statementInfo.form.fields.brandColor.label')}
                labelClass="shrink"
                append={<div>
                  <div onClick={handleColorPickerClick} className="mr-4 border cursor-pointer rounded p-3 position-relative" style={{ background: colorPicker.brandColor }}></div>
                  {colorPicker.show ? <div className={`${isMobile ? 'm-' : ''}colorPicker-position`}>
                    <div onClick={handleColorPickerClick} className="color-click"></div>
                    <ChromePicker color={colorPicker.color} onChange={handleChangeColor} />
                  </div> : null}
                </div>}
                component={FieldWrapper} />
            </Col>
            <Col sm="10" md="8" lg="4" className="position-relative" style={{ zIndex: 1 }}>
              <Field autofocus readOnly className="m-0"
                tabIndex={11}
                note={t('admin.applications.statementInfo.note.description')}
                id={`appEditStatementInfoForm.${initialValues.id}.brand_logo_name`}
                name="brand_logo_name"
                label={t('common:form.fields.logo.label')}
                append={values && values.brand_logo_name && <i onClick={removeLogo} className="fas fa-times-circle text-danger cursor-pointer"></i>}
                component={FieldWrapper} />
            </Col>
            <Col sm="2" md="4" lg="2">
              <LoaderButton className="w-100" disabled={frozenAndNotAdmin || !initialValues.statement_information} onClick={() => handleUpload()}>{t('common:buttons.upload')}</LoaderButton>
            </Col>
          </Row>

          <div className="actions d-flex flex-wrap justify-content-end m-0 p-0 mx-md-n4 mb-md-n4 p-md-4 border-top">

            {/* TODO: Remove the conditional render from the cancel button, and make it reset the form. See ENGRG-3950*/}
            {/* {!admin && <Button variant="outline-light" onClick={() => setRedirect(true)} className="px-4 mr-auto">{t('common:buttons.cancel')}</Button>} */}

            <LoaderButton variant="primary" loading={isSubmitting ? true : undefined} disabled={frozenAndNotAdmin || !dirty || Object.keys(errors).length} type="submit" className="ml-md-3 px-4">{t('common:buttons.save')}</LoaderButton>

          </div>

          {confirm.update && <ConfirmModal
            show={confirm.update}
            data={confirm.data}
            title={t('admin.applications.statementInfo.confirm.title')}
            message={t('admin.applications.statementInfo.confirm.description', { name: initialValues.nickname })}
            onHide={updateStatementInfo} />}

          {upload.show && <DocumentUploadModal
            show={upload.show}
            percentage={upload.percentage}
            onHide={uploadLogo}
            isStatement={true}
            buttonLabel={t('common:upload.buttons.addLogo')}
            titleLabel={t('common:upload.modal.statement.title')}
            descriptionLabel={t('common:upload.modal.statement.description')} />}

        </Form>
      )}
    </Formik>
  );
};

StatementInfoForm.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The current user values
   */
  user: PropTypes.object.isRequired,
  /**
   * If the current screen width is similar to a mobile device
   */
  isMobile: PropTypes.bool.isRequired,
  /**
   * The function to be called when the application is saved
   */
  onSave: PropTypes.func,
  /**
   * The admin toggle to determine if the form is used for the admin console
   */
  admin: PropTypes.bool
};

export default StatementInfoForm;