import React, { useMemo } from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { adminApi } from '../../api';
import { capitalize, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import DataList from '../../components/common/DataList';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import TooltipButton from '../../components/common/TooltipButton';
import { CheckboxColumnFilter, MultipleFilter } from '../../components/common/table/TableFilters';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'console_users';

const formatSortBy = (sortBy) => {
  let query = sortBy;
  const name = sortBy.find(param => param.id === 'name');
  if (name) {
    query = [...query.filter(param => param.id !== 'name'), { id: 'full_name', desc: name.desc }, { id: 'first_name', desc: name.desc }];
  }
  return { url: sortBy, query };
};

const formatFilters = (filters) => {
  let query = filters;
  const access_granted = filters.find(param => param.id === 'access_granted');
  if (access_granted && access_granted.value.length === 2) {
    query = query.filter(param => param.id !== 'access_granted');
  } else if (access_granted) {
    query = query.map(param => param.id === 'access_granted' ? { ...param, value: param.value[0] === 'Production' } : param)
  }
  return { url: filters, query };
};

const ConsoleUsers = ({ t, history, user, env, location }) => {
  const columns = useMemo(() => [
    {
      id: 'name',
      Header: t('common:labels.name'),
      accessor: data => data.full_name || `${data.first_name} ${data.last_name}`,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => value ? <span className="d-block">{value}</span> : <span className="text-info">{t('common:labels.na')}</span>
    },
    {
      id: 'handle',
      Header: t('common:labels.handle'),
      accessor: 'handle',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>{value}</span>,
    },
    {
      id: 'email',
      Header: t('common:labels.email'),
      accessor: data =>
        data.emails.map((item => item.email)
        ),
      filter: MultipleFilter,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 200, minWidth: 160 }}>{value}</span>,
      Filter: CheckboxColumnFilter
    },
    {
      id: 'company',
      Header: t('common:labels.company'),
      accessor: data =>
        data.companies.map((item => item.name)
        ),
      filter: MultipleFilter,
      Cell: ({ value }) => value.length ? value.map((item, index) => <span key={index} className="text-break d-block" style={{ maxWidth: 200, minWidth: 160 }}>{item}</span>) : <span className="text-info">{t('common:labels.na')}</span>,
      Filter: CheckboxColumnFilter
    },
    {
      id: 'team',
      Header: t('common:labels.team'),
      accessor: data =>
        data.teams.map((item => item.name)
        ),
      filter: MultipleFilter,
      Cell: ({ value }) => value.length ? value.map((item, index) => <span key={index} className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>{item}</span>) : <span className="text-info">{t('common:labels.na')}</span>,
      Filter: CheckboxColumnFilter
    },
    {
      id: 'role',
      Header: t('common:labels.role'),
      accessor: data => data.teams,
      Cell: ({ value }) => value.map((item, index) => {
        const role = item.role_name;
        const team = item.name;
        return <span key={index} className="d-block">{capitalize(role)}{value.length > 1 && <TooltipButton placement="top" variant="link" text={<Trans i18nKey="admin.consoleUsers.list.labels.teams.multiple" role={role} team={team}>Role <span className="font-weight-bolder">{{role}}</span> of <span className="font-weight-bolder">{{team}}</span> team</Trans>} className="p-0 no-underline ml-2"><i className="sila-icon info text-lg"></i></TooltipButton>}</span>;
      }),
      Filter: CheckboxColumnFilter,
      filter: MultipleFilter,
      filterOptions: () => [{
        label: t('settings.members.labels.roles.owner'),
        value: 'owner'
      }, {
        label: t('settings.members.labels.roles.manager'),
        value: 'manager'
      }, {
        label: t('settings.members.labels.roles.developer'),
        value: 'developer'
      }]
    },
    {
      id: 'access_granted',
      Header: t('admin.labels.approved'),
      accessor: data => ({
        label: data.access_granted ? t('env.prod.label') : t('env.sandbox.label'),
        value: data.access_granted
      }),
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => value.label,
      filterOptions: () => [{
        label: t('env.prod.label'),
        value: t('env.prod.label')
      }, {
        label: t('env.sandbox.label'),
        value: t('env.sandbox.label')
      }]
    }
  ].filter(column => column.id === 'access_granted' && env === 'prod' || column.id !== 'access_granted'), [env]);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.consoleUsers.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]) ?
        <DataList
          slug={TABLE_SLUG}
          filterName={TABLE_SLUG}
          limit={TABLE_MAX_COUNT}
          fetchData={adminApi.fetchConsoleUsers}
          columns={columns}
          onRowClick={(row) => history.push(`/admin/console_user/${row.original.handle}/profile`)}
          onFilter={formatFilters}
          onSortBy={formatSortBy}
          hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])}
          loadingMessage={t('admin.consoleUsers.loading')} />
        :
        <div className="position-relative"><DisabledOverlay /></div>}

    </Container>
  );
};

export default ConsoleUsers;