import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import OtpInput from 'react-otp-input';
import Countdown, { zeroPad } from "react-countdown";
import * as yup from 'yup';

import { useAuth } from '../components/context';

import { authApi } from '../api';

import TooltipButton from '../components/common/TooltipButton';
import LoaderButton from '../components/common/LoaderButton';
import FieldWrapper from '../components/form/wrappers/Field';

import { SIGNUP_STEP_COMPLETED, MFA_NUMBER_OF_INPUTS, MFA_MAX_FAILED_ATTEMPTS, MFA_RESEND_CODE_THRESHOLD, IDLE_TIMEOUT } from '../constants';

const ReSendCountdownRenderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return null;
  } else {
    return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
  }
};

const Mfa = ({ user, location, history, app, updateApp, setAlert }) => {
  const [redirectToPreviousRoute, setRedirectToPreviousRoute] = useState(false);
  const [page, setPage] = useState('mfa');
  const [mfaResponse, setMfaResponse] = useState({
    otp: '',
    authRes: {},
    mfaCodeStatus: '',
    mfaLocked: false,
    reSendCodeStatus: { status: '', isSending: false },
    reSendCodeThreshold: 1000 * 60 * parseInt(MFA_RESEND_CODE_THRESHOLD)
  })
  const { onLogin } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };
  const { t } = useTranslation();
  let idleTimer = null;

  const initialValues = {
    otp: '',
    mfa_device_remember: ''
  };

  const schema = yup.object().shape({
    mfa_device_remember: (page == 'mfa-device') ? yup.string()
      .required(t('common:form.errors.required', { field: t('login.mfaDeviceRemember.form.fields.mfa_device_remember.label') })) : null
  });

  const reSendCode = async () => {
    try {
      setMfaResponse({ ...mfaResponse, reSendCodeStatus: { ...mfaResponse.reSendCodeStatus, isSending: true } });
      const response = await authApi.mfaCodeResend(app.registration.handle);
      setAlert({ message: (response.message) ? response.message : t('login.mfa.reSendCodeSuccess'), variant: 'success' });
      setMfaResponse({ ...mfaResponse, reSendCodeStatus: { ...mfaResponse.reSendCodeStatus, status: 'sent', isSending: false }, reSendCodeThreshold: 1000 * 60 * parseInt(MFA_RESEND_CODE_THRESHOLD), mfaLocked: false, mfaCodeStatus: '' });
    } catch (error) {
      setMfaResponse({ ...mfaResponse, reSendCodeStatus: { ...mfaResponse.reSendCodeStatus, status: '', isSending: false } });
      setAlert({ message: error, variant: 'warning' });
      throw error;
    };
  }

  const ReSendCountdownCompleted = () => {
    setMfaResponse({ ...mfaResponse, reSendCodeStatus: { ...mfaResponse.reSendCodeStatus, status: '' } });
  };

  const ReSendCountdownTick = () => {
    setMfaResponse({ ...mfaResponse, reSendCodeThreshold: mfaResponse.reSendCodeThreshold - parseInt(1000) });
  };

  if (!app.registration.email) {
    return <Redirect to="/login" />;
  }

  if (user || redirectToPreviousRoute) {
    return <Redirect to={from} />;
  }

  return (
    <Container className="login main-content-container p-3 py-md-5 mfa">
      <h2 className="mt-0 mt-md-5 mb-4">
        {t((page == 'mfa') ? 'login.mfa.title' : 'login.mfaDeviceRemember.title')}
        {page == 'mfa' && <TooltipButton placement="right" variant="link" text={t('login.mfa.titleTip')} className="p-0 no-underline ml-2"><i className="sila-icon info text-lg"></i></TooltipButton>}
      </h2>
      <p className="mb-5 text-info">{t((page == 'mfa') ? 'login.mfa.subTitle' : 'login.mfaDeviceRemember.subTitle', { email: (app.registration.email) ? app.registration.email : '' })}</p>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting, resetForm, setFieldValue, setTouched }) => {
          try {
            if (page == 'mfa') {
              const mfaApiResponse = await authApi.mfaVerify(app.registration.handle, mfaResponse.otp);
              if (mfaApiResponse.success && mfaApiResponse.verified) {
                setAlert({ message: mfaApiResponse.message, variant: 'success' });
                setMfaResponse({ ...mfaResponse, authRes: mfaApiResponse, mfaCodeStatus: 'success', reSendCodeStatus: { ...mfaResponse.reSendCodeStatus, status: '' } });
                setSubmitting(false);
                resetForm({ values: initialValues });
                setTimeout(() => { setPage('mfa-device'); }, 750);
              } else {
                let error_msg = (mfaApiResponse.validation_details) ? mfaApiResponse.validation_details.mfa_code : mfaApiResponse.message;
                setSubmitting(false);
                setTouched({}, false);
                setMfaResponse({ ...mfaResponse, mfaCodeStatus: 'error' });
                setAlert({ message: error_msg, variant: 'warning' });
                if (mfaApiResponse.locked) {
                  setMfaResponse({ ...mfaResponse, mfaLocked: true, reSendCodeStatus: { ...mfaResponse.reSendCodeStatus, status: '' } });
                  setTimeout(() => {
                    setMfaResponse({ ...mfaResponse, otp: '', authRes: {}, mfaCodeStatus: '', mfaLocked: false });
                    setSubmitting(false);
                    resetForm({ values: initialValues });
                    history.push({ pathname: '/login', state: { from: location.pathname } });
                  }, 3000);
                }
              }
            } else if (page == 'mfa-device') {
              const response = await authApi.mfaDeviceRemember(app.registration.handle, values);
              if (!response.success) {
                setSubmitting(false);
                setTouched({}, false);
                setAlert({ message: response.message, variant: 'warning' });
              } else {
                let user_signup_step_completed = mfaResponse.authRes.user.signup_step_completed;
                setAlert({ message: response.message, variant: 'success' });
                onLogin(mfaResponse.authRes);
                setMfaResponse({ ...mfaResponse, otp: '', authRes: {}, mfaCodeStatus: '', mfaLocked: false, reSendCodeStatus: { ...mfaResponse.reSendCodeStatus, status: '' } });
                setSubmitting(false);
                resetForm({ values: initialValues });
                if (user_signup_step_completed) {
                  if (user_signup_step_completed != SIGNUP_STEP_COMPLETED.STEP3) {
                    updateApp({ 
                      registration: { 
                        email: app.registration.email, 
                        company_name: 
                        app.registration.company_name, 
                        inProgress: true 
                      }
                    });
                  }
                  if (user_signup_step_completed == SIGNUP_STEP_COMPLETED.STEP1) {
                    history.push({ pathname: '/register/invite_team_members', state: { from: location.pathname } });
                  } else if (user_signup_step_completed == SIGNUP_STEP_COMPLETED.STEP2) {
                    history.push({ pathname: '/register/questionnaire', state: { from: location.pathname } });
                  } else {
                    setRedirectToPreviousRoute(true);
                  }
                } else {
                  setRedirectToPreviousRoute(true);
                }
              }
            }
          } catch (error) {
            setAlert({ message: error, variant: 'warning' });
          }
        }}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            {page == 'mfa' && <><Field required autofocus data-hj-whitelist className="mb-5"
              is={OtpInput}
              value={mfaResponse.otp}
              onChange={(value) => {
                setMfaResponse({ ...mfaResponse, otp: value, mfaCodeStatus: '' });
              }}
              numInputs={MFA_NUMBER_OF_INPUTS}
              isInputNum={true}
              shouldAutoFocus={true}
              hasErrored={(mfaResponse.mfaCodeStatus == 'error') ? true : false}
              containerStyle={'d-flex flex-row flex-nowrap mx-n1 mx-md-n2'}
              inputStyle={`w-100 ${mfaResponse.mfaCodeStatus == 'success' ? 'green' : mfaResponse.otp.length == MFA_NUMBER_OF_INPUTS ? 'blue' : 'default'}`}
              focusStyle={'blue'}
              errorStyle={mfaResponse.mfaCodeStatus == 'error' ? 'red' : ''}
              id="loginForm.otp"
              name="otp"
              fieldClass="data-hj-whitelist border-0 p-0 col otpField mx-1 mx-md-2"
              component={FieldWrapper} />
              {(mfaResponse.reSendCodeStatus.status != 'sent' && !mfaResponse.mfaLocked) && <p className="text-info text-center">{t('login.mfa.getNewCode')}</p>}
              {mfaResponse.reSendCodeStatus.status == 'sent' && <p className="text-info text-center">
                {t('login.mfa.getNewCodeThreshold')} <Countdown date={Date.now() + mfaResponse.reSendCodeThreshold} renderer={ReSendCountdownRenderer} onTick={() => ReSendCountdownTick()} onComplete={() => ReSendCountdownCompleted()} /> minutes.
              </p>}
              {(mfaResponse.mfaLocked) && <p className="text-info text-center">{t('login.mfa.mfaMaxFailedAttemptsReached', { mfa_max_failed_attempts: MFA_MAX_FAILED_ATTEMPTS })}</p>}
              <LoaderButton className="d-block m-auto p-0 text-decoration-none mb-5" variant="link" type="button" onClick={() => reSendCode()} loading={mfaResponse.reSendCodeStatus.isSending} loadingLabel={t('common:status.sending')} disabled={(mfaResponse.reSendCodeStatus.status == 'sent') ? 1 : 0}>{t('login.mfa.reSendCode')}</LoaderButton>
            </>}
            {page == 'mfa-device' && <><Field required data-hj-whitelist className="mb-4 flex-row-reverse d-flex justify-content-end align-items-center"
              id="loginForm.mfa_device_remember_yes"
              name="mfa_device_remember"
              type="radio"
              label={t('login.mfaDeviceRemember.OptionYesRememberMe')}
              value="yes"
              fieldClass="data-hj-whitelist"
              component={FieldWrapper} />
              <Field required data-hj-whitelist className="mb-4 flex-row-reverse d-flex justify-content-end align-items-center"
                id="loginForm.mfa_device_remember_ask"
                name="mfa_device_remember"
                type="radio"
                label={t('login.mfaDeviceRemember.OptionNoAskMe')}
                value="askMe"
                fieldClass="data-hj-whitelist"
                component={FieldWrapper} />
              <Field required data-hj-whitelist className="mb-4 flex-row-reverse d-flex justify-content-end align-items-center"
                id="loginForm.mfa_device_remember_always"
                name="mfa_device_remember"
                type="radio"
                label={t('login.mfaDeviceRemember.OptionAlwaysMfa')}
                value="no"
                fieldClass="data-hj-whitelist"
                component={FieldWrapper} />
            </>}
            <div className="d-block d-md-flex align-items-center pt-5">
              {page == 'mfa' && <><LoaderButton className="mr-5 d-sm-block" variant="primary" type="submit" loading={isSubmitting} loadingLabel={t('common:status.submitting')} disabled={mfaResponse.otp.length < MFA_NUMBER_OF_INPUTS}>{t('common:buttons.continue')}</LoaderButton>
                <p className="text-info d-flex align-items-center mb-0">
                  {mfaResponse.mfaCodeStatus == 'error' && <><i className="fas fa-ban text-lg text-warning mr-2"></i> {t('login.mfa.error')}</>}
                  {mfaResponse.mfaCodeStatus == 'success' && <><i className="fas fa-check text-lg text-success mr-2"></i> {t('login.mfa.success')}</>}
                  {!mfaResponse.mfaCodeStatus && mfaResponse.reSendCodeStatus.status == 'sent' && <><i className="fas fa-exclamation-circle text-lg text-primary mr-2"></i> {t('login.mfa.reSendCodeSuccess')}</>}
                </p>
              </>}
              {page == 'mfa-device' && <LoaderButton className="mr-5 d-sm-block" variant="primary" type="submit" loading={isSubmitting} loadingLabel={t('common:status.submitting')} disabled={!values.mfa_device_remember}>{t('common:buttons.continue')}</LoaderButton>}
            </div>
          </Form>
        )}
      </Formik>
      <IdleTimer
        ref={ref => { idleTimer = ref }}
        element={document}
        onIdle={() => {
          history.push({ pathname: '/login', state: { from: location.pathname } });
          setAlert({ message: t('user.status.inactive'), variant: 'warning', dismissible: true });
        }}
        debounce={250}
        timeout={IDLE_TIMEOUT} />
    </Container>
  );
};

export default Mfa;