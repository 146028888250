import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { useFormikContext, getIn, Field } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { adminApi } from '../../api';
import { useAlerts } from '../context';

import TableContainer from '../common/table/TableContainer';
import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';
import Loader from '../common/Loader';
import OnboardingChecklistTaskModal from './OnboardingChecklistTaskModal';

import { formatDateAndTime, applyFilter, applyQuery } from '../../utils';
import { DateRangeColumnFilter, CheckboxColumnFilter, MultipleFilter, DateBetweenFilter } from '../common/table/TableFilters';

const formatFilters = (filters) => {
  let query = filters;
  return { url: filters, query };
};

const formatSortBy = (sortBy) => {
  let query = sortBy;
  return { url: sortBy, query };
};

const OnboardingChecklistTasks = ({ name, owners, templates, setTemplates, setPagination, pagination, handleAdd, handleRemove, handleCreate, page, match, loadData, hasWriteAccess }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  
  const [currentTask, setCurrentTask] = useState({ show: false, data: undefined });
  const { values, errors, setFieldValue } = useFormikContext();
  const history = useHistory();
  const data = getIn(values, name) || [];
  const [isLoading, setIsLoading] = useState(false);

  const [edit, setEdit] = useState(null);
  const [tableFilters, setTableFilters] = useState({
    filters: location.search.length ? applyQuery(location.search.substring(1)).filters : [],
    sortBy: location.search.length ? applyQuery(location.search.substring(1)).sortBy : [],
  });

  const {
    url
  } = applyFilter({
    filters: formatFilters(tableFilters.filters),
    sortBy: formatSortBy(tableFilters.sortBy)
  });

  const onAdd = useCallback(
    index => {
      const item = {
        new: true,
        id: uuid(),
        owner_login_handle: '',
        assigned: '',
        completed_date: '',
        link: '',
        completed_status: '',
        name: ''
      };
      handleAdd(index + 1, item);
    }, [handleAdd]);

  const onRemove = useCallback(index => {
    handleRemove(index);
  }, [handleRemove]);

  const createTask = async ({ values, index }) => {
    try {
      if (!hasWriteAccess) return;
      const response = await adminApi.updateCustomerChecklistInstance(match.params.checklist, {
        new_task_name: values.name,
        new_task_link: values.link
      });
      setFieldValue(`${name}.${index}`, response.new_task);
      setAlert({ message: t('admin.customers.onboarding.checklist.form.messages.task.created', { name: getIn(values, name.replace(/\.[^/.]+$/, '.name')) }), variant: 'success' });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
    }
  };

  const updateTask = async (id, values, index) => {
    if (!hasWriteAccess) return;
    let message = false;
    if (values.completed_status && values.completed_status === '2') {
      message = t('admin.customers.onboarding.checklist.form.messages.task.disabled');
    } else if (values.completed_status && values.completed_status === '1') {
      message = t('admin.customers.onboarding.checklist.form.messages.task.completed');
    } else if (values.completed_status && values.completed_status === '0') {
      message = t('admin.customers.onboarding.checklist.form.messages.task.enabled');
    } else if (values.owner) {
      message = t('admin.customers.onboarding.checklist.form.messages.owner.assigned', { name: values.owner_name });
    } else if (values.link && values.new) {
      message = t('admin.customers.onboarding.checklist.form.messages.link.created');
      delete values.new;
    } else {
      message = t('admin.customers.onboarding.checklist.form.messages.link.updated');
    }
    try {
      const response = await adminApi.updateCustomerChecklistTask(id, values);
      if (message) setAlert({ message, variant: 'success' });
      setFieldValue(`${name}.${index}`, response.task);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const updateTaskInfo = async (values) => {
    if (hasWriteAccess && values) {
      try {
        const response = await adminApi.updateCustomerChecklistTask(values.id, { description: values.description });
        setAlert({ message: t('admin.customers.onboarding.checklist.task.form.messages.success'), variant: 'success' });
        setFieldValue(`${name}.${values.index}`, response.task);
        loadData();
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentTask({ ...currentTask, show: false, data: undefined });
      }
    } else {
      setCurrentTask({ ...currentTask, show: false, data: undefined });
    }
  };

  const getTemplates = async () => {
    setIsLoading(true);
    const page = pagination.current_page + 1;
    try {
      const response = await adminApi.fetchCustomerChecklistTemplates({
        limit: 100,
        page: page
      });
      const newTemplates = [...templates, ...response.checklist_templates];
      setPagination(response.pagination);
      setTemplates(newTemplates);
      setIsLoading(true);
    } catch (error) {
      throw error;
    }
  }

  const columns = useMemo(() => [
    {
      id: 'completed_status',
      Header: t('admin.customers.onboarding.checklist.labels.status'),
      accessor: (data) => data.completed_status == 1 ? true : false,
      columnStyles: () => ({ width: '5%' }),
      disableFilters: true,
      disableSortBy: true,
      hideCell: ({ row }) => row.original.new,
      Cell: ({ value, row }) => <Field required className="m-0 big-checkbox"
        disabled={hasWriteAccess ? edit === row.index || row.original.completed_status == 2 || !row.original.assigned : true}
        id={`${name}.${row.index}.completed_status`}
        name={`${name}.${row.index}.completed_status`}
        checked={value}
        handleChange={(e) => updateTask(row.original.id, { completed_status: (e.target.checked ? 1 : 0).toString() }, row.index)}
        type="checkbox"
        component={FieldWrapper} />
    },
    {
      id: 'name',
      Header: t('admin.customers.onboarding.checklist.labels.task'),
      columnStyles: () => ({ width: '25%' }),
      labelStyles: () => ({ width: '25%' }),
      accessor: 'name',
      disableFilters: true,
      disableSortBy: true,
      colSpan: ({ row }) => row.original.new ? 3 : undefined,
      cellClasses: ({ row }) => row.original.completed_status ? 'text-strikethrough ' : null,
      Cell: ({ value, row }) => row.original.new ?
        <Field required showRequired className="m-0"
          size="sm"
          id={`${name}.${row.index}.name`}
          name={`${name}.${row.index}.name`}
          placeholder={t('admin.customers.onboarding.checklist.form.fields.name.label')}
          component={FieldWrapper} /> :
        <Button variant="link" className="p-0 text-left text-reset d-flex align-items-center no-underline" style={{ minWidth: 200 }} onClick={() => setCurrentTask({ show: true, data: { ...row.original, index: row.index } })}>
          <span className="lnk text-break d-block" style={{ maxWidth: 200 }}>{value}</span>
          {row.original.description && <i className={`fas fa-info-circle text-lg ml-2 d-block text-${row.original.completed_status ? 'success-dark' : 'meta'}`}></i>}
          {row.original.comment_count > 0 && <span className="comment-count d-flex ml-2 position-relative">
            <i className={`fas fa-comment text-xl text-${row.original.completed_status ? 'success-dark' : 'meta'}`}></i>
            <span className="position-absolute position-top d-inline-block w-100 text-center text-white text-xs font-weight-bold" style={{ top: 2 }}>{row.original.comment_count}</span>
          </span>}
        </Button>
    },
    {
      id: 'owner_login',
      Header: t('admin.customers.onboarding.checklist.labels.owner'),
      columnStyles: () => ({ width: '25%' }),
      labelStyles: () => ({ width: '25%' }),
      cellClasses: ({ row }) => row.original.completed_status ? 'text-strikethrough' : null,
      accessor: 'owner_login',
      searchOptions: {
        disabled: true
      },
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      hideCell: ({ row }) => row.original.new,
      Cell: ({ row }) => <Field required className="m-0"
        type="select"
        size="sm"
        disabled={hasWriteAccess ? edit === row.index : true}
        fieldClass={row.original.completed_status ? 'text-reset font-italic py-2' : undefined}
        plaintext={row.original.completed_status}
        readOnly={row.original.completed_status}
        id={`${name}.${row.index}.owner_login_handle`}
        name={`${name}.${row.index}.owner_login_handle`}
        value={row.original.assigned !== null && row.original.owner_login_handle ? row.original.owner_login_handle : ''}
        options={owners.map(owner => ({ value: owner.handle, label: owner.name }))}
        handleChange={(e) => updateTask(row.original.id, { owner: e.target.value, owner_name: owners.find(owner => owner.handle === e.target.value).name }, row.index)}
        placeholder={t('admin.customers.onboarding.checklist.form.fields.owner.placeholder')}
        component={FieldWrapper} />
    },
    {
      id: 'assigned',
      Header: t('admin.customers.onboarding.checklist.labels.assigned'),
      columnStyles: () => ({ width: '10%' }),
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      cellClasses: ({ row }) => row.original.completed_status ? 'text-strikethrough' : null,
      accessor: 'assigned',
      hideCell: ({ row }) => row.original.new || edit === row.index,
      Cell: ({ value }) => value ? formatDateAndTime(value, false) : t('common:form.fields.date.placeholder')
    },
    {
      id: 'completed_date',
      Header: t('admin.customers.onboarding.checklist.labels.completed'),
      columnStyles: () => ({ width: '10%' }),
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      cellClasses: ({ row }) => row.original.completed_status ? 'text-strikethrough' : null,
      accessor: 'completed_date',
      hideCell: ({ row }) => row.original.new || edit === row.index,
      Cell: ({ value }) => value ? formatDateAndTime(value, false) : t('common:form.fields.date.placeholder')
    },
    {
      id: 'link',
      Header: t('admin.customers.onboarding.checklist.labels.link'),
      columnStyles: () => ({ width: '15%' }),
      accessor: 'link',
      disableFilters: true,
      disableSortBy: true,
      colSpan: ({ row }) => (row.original.new || edit === row.index) ? 5 : null,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value, row }) => {
        const taskName = `${name}.${row.index}.name`;
        const fieldName = `${name}.${row.index}.link`;
        return (edit === row.index || row.original.new) ? <div className="d-flex align-items-center w-100">
          <Field className="m-0 w-100"
            size="sm"
            fieldClass="text-sm"
            id={`${name}.${row.index}.link`}
            name={`${name}.${row.index}.link`}
            handleChange={(e) => setFieldValue(e.target.name, e.target.value.toLowerCase().trim())}
            placeholder={t('common:form.fields.url.placeholder')}
            component={FieldWrapper} />
          <Button className="mx-2 loaded text-sm" size="sm" disabled={(!row.original.name || getIn(errors, fieldName) || getIn(errors, taskName))} onClick={row.original.new ? () => { createTask(row); } : value ? () => { updateTask(row.original.id, { link: value, new: !row.original.link ? true : false }, row.index); setEdit(null) } : () => setEdit(null)}>{t(`common:buttons.${row.original.new ? 'add' : 'save'}`)}</Button>
          <Button variant="link" className="p-0 text-warning loaded" onClick={row.original.new ? () => onRemove(row.index) : value ? () => { setEdit(null); setFieldValue(fieldName, ''); updateTask(row.original.id, { link: '' }, row.index); } : () => { setEdit(null); setFieldValue(fieldName, getIn(values, fieldName)) }}><i className="fas fa-times-circle"></i></Button>
        </div>
          : hasWriteAccess ? <Button variant="link" className="p-0 loaded text-sm text-ellipsis" style={value ? { maxWidth: 160 } : undefined} href={value || undefined} target={value ? '_blank' : undefined} title={value || undefined} onClick={!value ? () => setEdit(row.index) : undefined}>{value || t('admin.customers.onboarding.checklist.form.buttons.link')}</Button> : null;
      }
    },
    {
      id: 'actions',
      Header: t('common:labels.actions'),
      columnStyles: () => ({ width: '10%' }),
      cellClasses: () => 'text-center text-nowrap',
      disableFilters: true,
      disableSortBy: true,
      hideCell: ({ row }) => (row.original.new || edit == row.index),
      Cell: ({ row }) => {
        const disabled = row.original.completed_status == 2;
        return (hasWriteAccess ? <div style={{ width: 50 }}>
          {row.original.link && edit !== row.index &&
            <Button variant="link" onClick={() => setEdit(row.index)} className="p-0 text-info-link no-underline"><i className="fas fa-pen text-lg"></i></Button>}
          {row.original.completed_status !== 1 &&
            <TooltipButton placement="top" variant="link" className="p-0 no-underline text-info-link" text={t(`admin.customers.onboarding.checklist.tips.${disabled ? 'enable' : 'disable'}`)} onClick={() => updateTask(row.original.id, { completed_status: (disabled ? 0 : 2).toString() }, row.index)}><i className={classNames('sila-icon text-lg', `${disabled ? 'enable' : 'disable'}-task`, row.original.link && 'ml-3')}></i></TooltipButton>}</div> : null);
      }
    }
  ], [edit, onAdd, onRemove, errors]);

  useEffect(() => {
    if (decodeURI(location.search.substring(1)) !== url) history.push({ pathname: location.pathname, search: url });
  }, [url]);

  return (
    <>
      <TableContainer
        enableExpanded={true}
        enableManualFilters={false}
        enableGlobalFilter={data.length ? true : false}
        enableGlobalFilterSmall={true}
        containerClasses="border-0 no-shadow"
        headerClasses={({ headerGroup, index }) => classNames(
          'py-2',
          index === 0 && 'pl-4',
          index !== 0 && index !== (headerGroup.headers.length - 1) && 'px-3',
          index === (headerGroup.headers.length - 1) && 'p-0'
        )}
        cellClasses={({ row, index, length }) => classNames(
          'py-2',
          index === 0 && 'pl-4',
          row.index === (length - 1) && 'border-bottom-0',
          index !== 0 && index !== (row.cells.length - 1) && 'px-3',
          index === (row.cells.length - 1) && 'p-0',
          row.original.completed_status == 2 && 'bg-info text-info',
          row.original.completed_status == 1 && 'bg-success-accent text-success-dark'
        )}
        headerRightComponent={() => data.length === 0 ? <div className="d-flex align-items-center">
          <Form.Label
            className="mr-3 mb-0 text-uppercase text-sm text-nowrap"
            htmlFor="checklist-templates">{t('admin.customers.onboarding.checklist.form.fields.template.label')}</Form.Label>
          <DropdownButton id="checklist-templates"
            className="text-nowrap mt-2 mt-md-0 w-auto"
            size="sm"
            variant="outline-light"
            disabled={!templates || templates.filter(template => page && template.id !== page || !page).length < 1}
            title={templates && page && templates.some(template => template.id == page) ? templates.find(template => template.id == page).name : t('admin.customers.onboarding.checklist.form.fields.template.placeholder')}>
            {!templates ? <Loader /> : <div className="overflow-auto custom-scrollbar" style={{ maxHeight: 160 }}>
              {templates.map(template => <Dropdown.Item key={template.id} style={{ minWidth: 200 }} className="text-sm" onClick={(e) => handleCreate(template)}>{template.name}</Dropdown.Item>)}
              {pagination.current_page !== pagination.total_pages && <Button variant="link" disabled={isLoading} className="w-100 text-center pt-2 pb-2" style={{ justifyContent: 'center', textDecoration: 'none', boxShadow: 'none' }} onClick={(e) => { getTemplates(); e.preventDefault(); }}>{t(`common:${isLoading ? 'status.loading' : 'labels.loadMore'}`)}</Button>}
            </div>}
          </DropdownButton>
        </div> : undefined}
        rowClasses={() => 'task loaded cursor-default'}
        tableFilters={tableFilters}
        setTableFilters={setTableFilters}
        renderEmptyComponent={() => !values.length && !tableFilters.filters.length && !tableFilters.sortBy.length && (!tableFilters.globalFilter || tableFilters.globalFilter && !tableFilters.globalFilter.length) ? <p className="text-center text-info font-italic mx-auto my-5 w-75"><Trans i18nKey="admin.customers.onboarding.checklist.empty">Choose a checklist template from the dropdown to begin. Once work on a checklist has begun, the template may not be changed. To edit and create new checklists, go to the<Button variant="link" className="p-0" as={NavLink} to={{ pathname: '/admin/customers/checklists', state: { from: location.pathname } }}>Checklist Onboarding Dashboard</Button>.</Trans></p> : <p className="text-center text-info font-italic mx-4 my-5">{t('common:form.messages.empty')}</p>}
        renderHoverComponent={({ row }) => hasWriteAccess && !row.original.new && edit !== row.index && <div className="position-absolute w-75 position-bottom position-left">
          <div className="bg-primary" style={{ height: '2px' }}></div>
          <Button onClick={() => onAdd(row.index)} className="add-task btn-circle-sm position-absolute position-bottom position-right p-0" style={{ marginBottom: '-12.5px', marginRight: '-12.5px' }}><i className="fas fa-plus"></i></Button>
        </div>}
        columns={columns}
        data={data} />

      {currentTask.show && <OnboardingChecklistTaskModal
        match={match}
        show={currentTask.show}
        data={currentTask.data}
        hasWriteAccess={hasWriteAccess}
        onComment={({ index, count }) => setFieldValue(`${name}.${index}.comment_count`, count)}
        onHide={updateTaskInfo} />}

    </>
  );
}

OnboardingChecklistTasks.propTypes = {
  /**
   * The name of the field array to be managed
   */
  name: PropTypes.string.isRequired,
  /**
   * The owners array
   */
  owners: PropTypes.array.isRequired,
  /**
   * The load data function
   */
  loadData: PropTypes.func.isRequired,
  /**
   * The add function
   */
  handleAdd: PropTypes.func.isRequired,
  /**
   * The remove function
   */
  handleRemove: PropTypes.func.isRequired,
  /**
   * The create function
   */
  handleCreate: PropTypes.func.isRequired,
  /**
   * The current page
   */
  page: PropTypes.string,
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired,
  /**
   * The write access boolean
   */
   hasWriteAccess: PropTypes.bool.isRequired
};

export default React.memo(OnboardingChecklistTasks);
