import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';

import TextFieldWrapper from './TextField';

import { useDebounce } from '../../../utils/hooks';

const PasswordFieldWrapper = (props) => {
  const { showstrength, children, handleChange, inputRef, hasError, form: { status, setStatus, setFieldTouched }, disabled, ...attrs } = props;
  const [password, setPassword] = useState('');
  const [strength, setStrength] = useState(0);
  const [error, setError] = useState(false);
  const debouncedpassword = useDebounce(password, 750);
  const minStrength = 3, thresholdLength = 8;

  const passwordLength = password.length;
  const passwordStrong = strength >= minStrength;
  const passwordLong = passwordLength > thresholdLength;

  const counterClass = classNames(
    'badge badge-pill',
    passwordLong ? passwordStrong ? 'badge-success' : 'badge-warning' : 'badge-danger'
  );

  const strengthClass = classNames(
    'strength-meter mt-2',
    passwordLength > 0 ? 'visible' : 'invisible'
  );

  const checkValidPassword = (value) => {
    const { feedback } = zxcvbn(value);
    setError(feedback.warning);
    setFieldTouched(attrs.field.name, true);
    if (feedback.warning) {
      setStatus({ errors: { [attrs.field.name]: feedback.warning } });
    } else if (status && status.errors && status.errors[attrs.field.name]) {
      delete status.errors[attrs.field.name];
      setStatus(status);
    }
  }

  const onHandleChange = e => {
    const value = e.currentTarget.value;
    const { score } = zxcvbn(value);
    if (handleChange) handleChange(e);
    if (showstrength) {
      setPassword(value);
      setStrength(score);
      if (error) checkValidPassword(value);
    }
  };

  useEffect(() => {
    if (debouncedpassword && showstrength && !error) {
      checkValidPassword(debouncedpassword)
    }
  }, [debouncedpassword]);

  return (
    <div className="password-strength">
      <TextFieldWrapper handleChange={onHandleChange} inputRef={inputRef} hasError={error || hasError} disabled={disabled} {...attrs}>
        {children}
        {showstrength && <>
          <div className={strengthClass}>
            <div className="strength-meter-fill" data-strength={strength}></div>
          </div><div className="position-absolute password-count mx-3">
            <span className={counterClass}>{passwordLength ? passwordLong ? `${thresholdLength}+` : passwordLength : ''}</span>
          </div></>}
      </TextFieldWrapper>
      {showstrength && error && <p className="text-danger text-sm m-0 loaded">{error}</p>}
    </div>
  );
};

PasswordFieldWrapper.propTypes = {
  /**
   * The visibility toggle to show the strength meter or not
   */
  showstrength: PropTypes.bool,
  /**
   * The children components.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /**
   * The function that is called when the password value changes
   */
  handleChange: PropTypes.func
};

export default PasswordFieldWrapper;