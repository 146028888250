import React from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { hasExpired } from '../../utils';

const StatusBadge = ({ data }) => {
  const { t } = useTranslation();
  let label, variant;

  if (data.member) {
    label = t('common:status.active');
    variant = 'success';
  } else if (data.invitation && hasExpired(data.invitation.expires_at)) {
    label = t('common:status.expired');
    variant = 'danger';
  } else {
    label = t('common:status.pending');
    variant = 'warning';
  }
  
  return (
    <Badge className="badge py-2 px-3" pill variant={variant}>
      {label}
    </Badge>
  );
};

StatusBadge.propTypes = {
  /**
   * The member data object
   */
  data: PropTypes.object.isRequired
};

export default StatusBadge;