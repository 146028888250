import React from 'react';
import { Container, Button, Card, Row, Col, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import WelcomeBanner from '../components/welcome/WelcomeBanner';
import OnboardingBanner from '../components/welcome/OnboardingBanner';
import ReturnRatesBanner from '../components/welcome/ReturnRatesBanner';

import { ctas, blocks } from '../constants/welcomeNavItems';

const Welcome = ({ location, user, env }) => {
  const { t } = useTranslation();
  const filteredCtas = ctas.filter(item => item.permissions ? item.permissions(user) : !item.permissions);
  const filteredBlocks = blocks.filter(item => item.permissions ? item.permissions(user) : !item.permissions);
  return (
    <Container className="welcome main-content-container p-3 p-md-5 d-flex flex-column">

      <WelcomeBanner />

      <OnboardingBanner />

      {user.role !== 3 && env === 'prod' && <ReturnRatesBanner className="mb-5" />}

      {filteredCtas.length !== 0 && <Row className={`actions mb-5 ${filteredCtas.length > 5 ? ' wrap' : ''}`}>
        {filteredCtas.map((item, index) => <Col key={index} xs="12" sm="6" md="6" lg={filteredCtas.length > 3 ? 3 : 4} className={`${item.name} action d-flex flex-column mb-4`}>
          <div className="flex-fill">
            <h3>{t(item.title)}</h3>
            <p className="text-info">{t(item.description)}</p>
          </div>
          <Button block as={item.to ? NavLink : undefined} href={item.href || undefined} to={item.to ? { pathname: item.to, state: { from: location.pathname } } : undefined} onClick={item.onClick ? () => item.onClick(t, user) : undefined} target={item.href ? '_blank' : undefined} variant="outline-light">{t(item.button)}</Button>
        </Col>)}

      </Row>}

      {filteredBlocks.length !== 0 && <Row className="blocks mt-n3 mb-n4">
        {filteredBlocks.map((item, index) => <Col key={index} lg={item.fullWidth ? 12 : filteredBlocks.length > 2 ? 4 : 6} className={`${item.name} ${item.class ? `${item.class} ` : ''}mb-4 d-flex`}>
          <Card as="a" href={item.href || '#'} target={item.href ? '_blank' : undefined} onClick={item.onClick ? (e) => { e.preventDefault(); item.onClick(t, user) } : undefined} className={`card rounded no-underline text-info flex-fill${item.classes ? ` ${item.classes}` : ''}`}>
            <Card.Header className="rounded-top border-bottom-0 px-4 pt-4 pb-0 bg-transparent"><h2 className="m-0 d-flex align-items-center flex-nowrap">{item.icon && <img src={item.icon} height={item.iconHeight || '54'} className="mr-2" />}<span>{t(item.title)}</span></h2></Card.Header>
            <Card.Body className={`${item.bodyClass ? `${item.bodyClass} ` : ''}px-4 pb-4 d-flex flex-column`}>
              {item.description && <p className="mt-2 mb-4">{t(item.description)}</p>}
              {item.button && <Button className={`${item.buttonClass ? `${item.buttonClass} ` : ''}text-uppercase mt-auto mr-auto`}>{item.buttonIcon && <i className={`${item.buttonIcon} mr-2`}></i>}{t(item.button)}</Button>}
            </Card.Body>
          </Card>
        </Col>)}

      </Row>}

    </Container>
  );
};

export default Welcome;
