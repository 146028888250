import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'react-bootstrap';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarSearch from './SidebarSearch';
import SidebarNavItems from './SidebarNavItems';

import { useApp } from '../../context';

const MainSidebar = ({ hideLogoText, full }) => {
  const { app } = useApp();
  const classes = classNames(
    'main-sidebar px-0 col-12 d-flex flex-column',
    full && 'full',
    app.menuVisible && 'open'
  );

  return (
    <Col
      as="aside"
      className={classes}
    >
      <SidebarMainNavbar hideLogoText={hideLogoText} />
      {/* <SidebarSearch /> */}
      <SidebarNavItems />
    </Col>
  );
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
  /**
   * The visibility of the sidebar, if full, it will be hidden
   */
  full: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
