import React, { useState, useEffect } from 'react';
import { Form, Button, Accordion, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCurrentDimensions } from '../../utils/hooks';

import AccordionItem from './AccordionItem';

const GlobalFilterMenu = ({ data, tableFilters, setTableFilters }) => {
  const defaultTerm = data.reduce((accumulator, value) => ({ ...accumulator, [value.id]: '' }), {});
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [filters, setFilters] = useState(tableFilters);
  const [term, setTerm] = useState(defaultTerm);
  const { t } = useTranslation();
  const currentWidth = useCurrentDimensions().width;
  const isMobile = currentWidth <= 576;

  const isSame = filters.length == tableFilters.length && filters.every((filter, index) => filter === tableFilters[index]);

  const handleExpanded = (key) => setExpanded(expanded.includes(key) ? expanded.filter(e => e !== key) : [key]);
  const accordionItemProps = { expanded, onSetExpanded: handleExpanded };
  const accordionActiveKey = (key) => expanded.includes(key) && key;

  const handleFilter = (condition, name, value) => {
    if (condition) {
      if (filters.some(filter => filter.id === name && !filter.value.includes(value))) {
        return filters.map(filter => filter.id === name ? { ...filter, value: [...filter.value, value] } : filter);
      } else {
        return [...filters, { id: name, value: [value] }];
      }
    } else {
      return filters.map(filter => filter.id === name ? { ...filter, value: filter.value.filter(filterValue => filterValue !== value) } : filter);
    }
  };

  const handleHide = () => {
    setExpanded([]);
    setTerm(defaultTerm);
    setShow(false);
  };

  useEffect(() => {
    setFilters(tableFilters);
  }, [tableFilters]);

  useEffect(() => {
    handleHide();
  }, []);

  return (
    <Dropdown align="end" show={show} onToggle={(visible) => setShow(visible)}>
      <Dropdown.Toggle variant="link" bsPrefix={`p-0 text-decoration-none loaded ${(show || tableFilters.length) ? 'text-primary' : 'text-info'}`}>
        <span className="text-lg mr-2">{t('common:filters.headers.global')}</span>
        <i className="sila-icon filter"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 overflow-hidden" style={{ minWidth: isMobile ? currentWidth - 130 : 300 }}>
        <>
          {data.map((item, index) => {
            item.options = item.options.filter((s => o => (k => !s.has(k) && s.add(k))(['label', 'value'].map(k => o[k]).join('|')))(new Set));
            const filteredOptions = item.search ? item.options.filter(option => option.value.toLowerCase().includes(term[item.id].toLowerCase())) : item.options;
            return (<section key={item.id} className="border-bottom">
              <Accordion id={item.id} className="table-global-filter" activeKey={accordionActiveKey(index + 1)}>
                <AccordionItem {...accordionItemProps} small
                  className="shadow-none rounded-0"
                  eventKey={index + 1}
                  label={<span className={`text-${filters.some(filter => filter.id === item.id) ? 'primary' : 'info'}`}>{item.label}</span>}
                  renderToggleIcon={(isExpanded) => `text-primary fas fa-${isExpanded ? 'minus' : 'plus'}`}>
                  <div className="m-n3">
                    {item.search && <header className="p-3 border-bottom">
                      <Form.Control className="w-100" size="sm" value={term[item.id]} placeholder={t('common:labels.search')} aria-label={t('common:labels.search')} onChange={(e) => setTerm({ ...term, [item.id]: e.target.value.trim() })} />
                    </header>}
                    <div className="options overflow-auto custom-scrollbar" style={{ maxHeight: 200 }}>
                      <ul className="list-unstyled mb-0 p-2">
                        {filteredOptions.map((option, index) => (
                          <li key={index} className={`px-3 py-2 text-sm${index !== 0 ? ' border-top' : ''}`}>
                            <Form.Check>
                              <Form.Check.Label className="w-100">
                                <Form.Check.Input
                                  checked={filters.some(filter => filter.id && filter.id === item.id && filter.value.includes(option.value))}
                                  type="checkbox"
                                  name={option.value}
                                  onChange={(e) => setFilters(handleFilter(e.target.checked, item.id, option.value))}
                                />
                                <span className="d-block w-100 text-break">{option.label}</span>
                              </Form.Check.Label>
                            </Form.Check>
                          </li>
                        ))}
                        {item.search && filteredOptions.length === 0 && <li className="text-center text-info font-italic text-sm">{t('common:form.messages.empty')}</li>}
                      </ul>
                    </div>
                  </div>
                </AccordionItem>
              </Accordion>
            </section>);
          })}
          <footer className="d-flex text-nowrap align-items-center justify-content-end p-3 text-right">
            {filters.length !== 0 && <Button variant="link" size="sm" className="ml-auto text-info text-underline mr-4 p-0" onClick={() => { setTableFilters([]); setFilters([]); handleHide(); }}>{t('common:filters.buttons.clear')}</Button>}
            <Button disabled={isSame} size="sm" onClick={() => { setTableFilters(filters); handleHide(); }}>{t('common:filters.buttons.apply')}</Button>
          </footer>
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
};

GlobalFilterMenu.propTypes = {
  /**
   * The data array
   */
  data: PropTypes.array.isRequired,
  /**
   * The filters array
   */
  tableFilters: PropTypes.array.isRequired,
  /**
  * The set filters function
  */
  setTableFilters: PropTypes.func.isRequired
};

export default GlobalFilterMenu;