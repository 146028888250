import React from 'react';
import { Formik, Field } from 'formik';
import { Form, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { formatAddress, uniqueFieldValue, formatFieldArray } from '../../utils';

import { STATES_ARRAY, PHONE_REG_EXP, POSTAL_REG_EXP } from '../../constants';

import { useAuth } from '../context';
import FieldWrapper from '../form/wrappers/Field';
import FieldArrayWrapper from '../form/wrappers/FieldArray';
import Loader from './Loader';
import LoaderButton from './LoaderButton';
import TooltipButton from '../common/TooltipButton';

yup.addMethod(yup.array, 'unique', uniqueFieldValue);

const UserForm = ({ initialValues, setName, onSubmit, formRef, confirm }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        first_name: yup.string()
          .required(t('common:form.errors.required', { field: t('common:form.fields.firstName.label') })),
        surname: yup.string()
          .required(t('common:form.errors.required', { field: t('common:form.fields.lastName.label') })),
        phones: yup.array().of(yup.object().shape({
          phone: yup.string()
            .matches(PHONE_REG_EXP, t('common:form.fields.phone.error'))
            .required(t('common:form.errors.required', { field: t('common:form.fields.phone.label') }))
        })).unique('phone', t('common:form.errors.exists', { field: t('common:form.fields.phone.label') }), value => value.phone),
        emails: yup.array().of(yup.object().shape({
          email: yup
            .string()
            .email(t('common:form.fields.email.error'))
            .max(254, t('common:form.errors.max', { count: 320 }))
            .required(t('common:form.errors.required', { field: t('common:form.fields.email.label') })),
        })),
        addresses: yup.array().of(yup.object().shape({
          street_address_1: yup.string()
            .max(254, t('common:form.errors.max', { count: 254 })),
          street_address_2: yup.string()
            .nullable()
            .max(254, t('common:form.errors.max', { count: 254 })),
          city: yup.string()
            .ensure().when('street_address_1', {
              is: (value) => value && value.length,
              then: yup.string().required(t('common:form.errors.required', { field: t('common:form.fields.address.city.label') }))
            })
            .max(96, t('common:form.errors.max', { count: 96 })),
          state: yup.string()
            .ensure().when('street_address_1', {
              is: (value) => value && value.length,
              then: yup.string().required(t('common:form.errors.required', { field: t('common:form.fields.address.state.label') }))
            }),
          postal_code: yup.string()
            .ensure().when('street_address_1', {
              is: (value) => value && value.length,
              then: yup.string()
                .required(t('common:form.errors.required', { field: t('common:form.fields.address.zip.label') }))
                .matches(POSTAL_REG_EXP, {
                  message: t('common:form.fields.address.zip.error')
                })
            })
        })),
        birthdate: yup.date()
          .test('age', t('common:form.fields.birthdate.errors.age'), function (birthdate) {
            if (birthdate) {
              const today = new Date();
              return birthdate <= today.setFullYear(today.getFullYear() - 18);
            }
            return true;
          }).nullable()
      })}
      onSubmit={(values, props) => formatFieldArray(values, props, onSubmit)}>
      {({ values, errors, handleSubmit, isSubmitting, dirty, setFieldValue, ...rest }) => (
        <Form noValidate autoComplete="new-password" className="position-relative" onSubmit={handleSubmit}>

          {isSubmitting && <Loader />}

          {dirty && <Alert variant="warning" className="mb-4 loaded">{t('common:form.messages.unsavedChanges')}</Alert>}

          <Form.Row>
            <Col xs="12" sm="6">
              <Field autofocus className="first-name"
                id="userForm.first_name"
                label={t('common:form.fields.firstName.label')}
                name="first_name"
                handleChange={setName ? e => setName(`${e.target.value} ${values.surname}`) : undefined}
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="6">
              <Field autofocus className="last-name"
                id="userForm.surname"
                label={t('common:form.fields.lastName.label')}
                name="surname"
                handleChange={setName ? e => setName(`${values.first_name} ${e.target.value}`) : undefined}
                component={FieldWrapper} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="12" sm="6">
              <Field autofocus readOnly className="handle"
                id="userForm.handle"
                label={t('common:form.fields.handle.label')}
                name="handle"
                component={FieldWrapper} />
            </Col>
            <Col xs="12" sm="6">
              <Field autofocus className="email"
                id={`userForm.emails.[0].email`}
                label={t('common:form.fields.email.label')}
                name="emails[0].email"
                append={confirm && user.confirmed_at && initialValues.emails.length && values.emails && values.emails.length && values.emails[0].email === initialValues.emails[0].email.toLowerCase().trim() ? <TooltipButton placement="top" variant="link" text={t('common:form.fields.email.emailConfirmed')} className="p-0 cursor-pointer"><i className="fas fa-check text-lg text-success mr-2"></i></TooltipButton> : confirm && initialValues.emails.length ? <em className="text-info">{t('common:form.fields.email.emailConfirmPending')}</em> : undefined}
                component={FieldWrapper} />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs="12" sm="6">
              <FieldArrayWrapper
                form={{ id: 'userForm', ...rest }}
                field={{
                  name: "phones",
                  slug: "phone",
                  initialValues: {
                    phone: ''
                  }
                }}
                values={values}
                errors={errors} />
            </Col>
            <Col xs="12" sm="6">
              <Field autofocus className="birthdate"
                id="userForm.birthdate"
                label={t('common:form.fields.birthdate.shortLabel')}
                name="birthdate"
                type="date"
                component={FieldWrapper} />
            </Col>
          </Form.Row>
          <FieldArrayWrapper
            form={{ id: 'userForm', ...rest }}
            field={{
              name: "addresses",
              slug: "address"
            }}
            values={values}
            errors={errors}
            formatValue={formatAddress}
            fields={index => <><Field autofocus className="street-address-1"
              id={`userForm.addresses.${index}.street1`}
              label={t('common:form.fields.address.street1.label')}
              name={`addresses.${index}.street_address_1`}
              component={FieldWrapper} />
              <Field autofocus className="street-address-2"
                id={`userForm.addresses.${index}.street2`}
                label={t('common:form.fields.address.street2.label')}
                name={`addresses.${index}.street_address_2`}
                component={FieldWrapper} />
              <Form.Row>
                <Col xs="12" sm="4">
                  <Field autofocus className="city"
                    id={`userForm.addresses.${index}.city`}
                    label={t('common:form.fields.address.city.label')}
                    name={`addresses.${index}.city`}
                    component={FieldWrapper} />
                </Col>
                <Col xs="12" sm="4">
                  <Field autofocus className="state"
                    id={`userForm.addresses.${index}.state`}
                    label={t('common:form.fields.address.state.label')}
                    name={`addresses.${index}.state`}
                    type="select"
                    options={STATES_ARRAY.map(state => ({
                      label: t(`common:form.fields.address.state.options.${state}`),
                      value: state
                    }))}
                    component={FieldWrapper} />
                </Col>
                <Col xs="12" sm="4">
                  <Field autofocus className="postal-code"
                    id={`userForm.addresses.${index}.postal_code`}
                    label={t('common:form.fields.address.zip.label')}
                    name={`addresses.${index}.postal_code`}
                    component={FieldWrapper} />
                </Col>
              </Form.Row></>} />
          <div className="d-flex">
            <LoaderButton className="ml-auto" loading={isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={!dirty || Object.keys(errors).length}>{t('common:buttons.save')}</LoaderButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The confirm boolean to enforce email confirmation
   */
  confirm: PropTypes.bool,
  /**
   * The set name function to show the full name outside of the form
   */
  setName: PropTypes.func,
  /**
   * The submit function
   */
  onSubmit: PropTypes.func.isRequired,
  /**
  * The form ref
  */
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
};

export default UserForm;