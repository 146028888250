import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const LoaderButton = ({ loading, loadingLabel, children, disabled, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button disabled={loading || disabled} {...rest}>
      {loading ?
        <span className="d-flex align-items-center">
          {loading && <Spinner animation="border" role="status" size="sm" variant="light" className="mr-3"><span className="sr-only">{t('common:status.loading')}</span></Spinner>}
          {loading && loadingLabel ? loadingLabel : t('common:status.submitting')}
        </span>
        : children}
    </Button>
  )
};

LoaderButton.propTypes = {
  /**
   * The loading boolean.
   */
  loading: PropTypes.bool,
  /**
   * The optional label that is displayed underneath the spinner.
   */
  loadingLabel: PropTypes.string,
  /**
   * The children components.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /**
   * The optional disabled boolean.
   */
  disabled: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])
};

export default LoaderButton;