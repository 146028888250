/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab, Table } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import ProdAccessModal from '../../components/admin/ProdAccessModal';
import ConsoleUserForm from '../../components/admin/ConsoleUserForm';

import { adminApi } from '../../api';
import { formatDateAndTime } from '../../utils';
import { DEFAULT_EMAIL, DEFAULT_PHONE, DEFAULT_ADDRESS } from '../../constants';

function ConsoleUser({ t, match, location, history, setAlert }) {
  const [key, setKey] = useState(match.params.action);
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    surname: '',
    full_name: '',
    handle: '',
    emails: DEFAULT_EMAIL,
    phones: DEFAULT_PHONE,
    birthdate: '',
    addresses: DEFAULT_ADDRESS,
    lastActive: '',
    created: '',
    access_granted: false,
    approved_at: null,
    teams: [],
  });
  const [prodAccess, setProdAccess] = useState({
    show: false,
    access_granted: initialValues.access_granted,
    handle: match.params.handle,
  });
  const formRef = useRef(null);

  const getConsoleUser = async () => {
    try {
      const response = await adminApi.getConsoleUser(match.params.handle);
      if (response.success) {
        setInitialValues({
          first_name: response.console_user.first_name,
          surname: response.console_user.last_name,
          full_name: response.console_user.full_name,
          handle: response.console_user.handle,
          emails: response.console_user.emails.length
            ? [response.console_user.emails[0]]
            : initialValues.emails,
          phones: response.console_user.phones.length
            ? response.console_user.phones
            : initialValues.phones,
          birthdate: response.console_user.birthdate,
          addresses: response.console_user.addresses.length
            ? response.console_user.addresses
            : initialValues.addresses,
          lastActive: response.console_user.last_login_attempt,
          created: response.console_user.created,
          access_granted: response.console_user.access_granted,
          approved_at: response.console_user.approved_at,
          teams: response.console_user.teams,
        });
      } else {
        setRedirect(true);
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setRedirect(true);
    } finally {
      setIsLoading(false);
      formRef.current.setSubmitting(false);
    }
  };

  const updateProdAccess = async (values) => {
    if (values) {
      formRef.current.setSubmitting(true);
      try {
        await adminApi.updateConsoleUser(match.params.handle, {
          access_granted: values.access_granted,
        });
        getConsoleUser();
        setAlert({
          message: t(
            `admin.consoleUsers.profile.prodAccess.${
              values.access_granted ? 'grant' : 'revoke'
            }.message`,
            { handle: match.params.handle }
          ),
          variant: 'success',
        });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        formRef.current.setSubmitting(false);
      } finally {
        setProdAccess({ ...prodAccess, show: false });
      }
    } else {
      setProdAccess({ ...prodAccess, show: false });
      formRef.current.setSubmitting(false);
    }
  };

  useEffect(() => {
    setKey(match.params.action);
  }, [match]);

  useEffect(() => {
    if (redirect)
      history.push({ pathname: '/admin/console_users', state: { from: location.pathname } });
  }, [redirect]);

  useEffect(() => {
    if (match.params.action === 'profile') {
      getConsoleUser();
    } else {
      setRedirect(true);
    }
  }, []);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.consoleUsers.profile.title')}>
          <Button
            variant="outline-light"
            className="back text-info"
            onClick={() => history.goBack()}
          >
            {t('common:buttons.back')}
          </Button>
        </PageTitle>
      </Row>

      {isLoading ? (
        <Loader />
      ) : (
        <Card className="loaded">
          <Card.Header className="d-flex justify-content-between p-4">
            <h3 className="m-0">{initialValues.full_name}</h3>
            <Button
              variant="link text-underline"
              onClick={() => setProdAccess({ ...prodAccess, show: true })}
              className="p-0"
            >
              {t(
                `admin.consoleUsers.profile.prodAccess.${
                  initialValues.access_granted ? 'revoke' : 'grant'
                }.title`
              )}
            </Button>
          </Card.Header>
          <Card.Body as={Row} className="p-0" noGutters>
            <Tab.Container activeKey={key} defaultActiveKey={key} onSelect={(k) => setKey(k)}>
              <Col md="4" lg="3" xl="2" className="border-right">
                <Nav className="d-flex flex-column align-content-start h-100 py-4">
                  <Nav.Item className="w-100">
                    <Nav.Link
                      className={`d-block px-4 py-3${
                        key === 'profile' ? ' bg-primary-accent text-primary' : ''
                      }`}
                      eventKey="profile"
                      as={NavLink}
                      to={{
                        pathname: `/admin/console_user/${match.params.handle}/profile`,
                        state: { from: location.pathname },
                      }}
                    >
                      {t('admin.consoleUsers.navigation.profile')}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md="8" lg="9" xl="10" className="p-4">
                <Tab.Content>
                  <Tab.Pane eventKey="profile">
                    <ul className="list-unstyled d-block d-lg-flex align-items-center bg-info px-4 py-3 mt-n4 ml-n4 mr-n4 mb-4">
                      {initialValues.lastActive && (
                        <li className="last-active d-block d-lg-flex align-items-center mr-0 mr-lg-4">
                          <span className="d-block text-primary">
                            {t('common:labels.lastActive')}:
                          </span>
                          <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">
                            {formatDateAndTime(initialValues.lastActive)}
                          </span>
                        </li>
                      )}
                      <li className="created d-block d-lg-flex align-items-center">
                        <span className="d-block text-primary">
                          {t('admin.consoleUsers.profile.labels.created')}:
                        </span>
                        <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">
                          {formatDateAndTime(initialValues.created)}
                        </span>
                      </li>
                      {initialValues.access_granted && initialValues.approved_at && (
                        <li className="granted-access d-block d-lg-flex align-items-center ml-0 ml-lg-4">
                          <span className="d-block text-primary">
                            {t('admin.consoleUsers.profile.labels.approved_at')}:
                          </span>
                          <span className="d-block ml-0 ml-lg-3">
                            {formatDateAndTime(initialValues.approved_at * 1000)}
                          </span>
                        </li>
                      )}
                    </ul>
                    <ConsoleUserForm
                      formRef={formRef}
                      setIsLoading={setIsLoading}
                      getConsoleUser={getConsoleUser}
                      initialValues={initialValues}
                    />

                    <h4 className="mb-3">{t('admin.consoleUsers.profile.labels.teamsAndApps')}</h4>
                    {initialValues.teams.length !== 0 && (
                      <Card className="mb-5 border overflow-auto" style={{ maxHeight: 300 }}>
                        <Table bordered responsive className="border-0">
                          <thead>
                            <tr>
                              <th className="border-right pl-4 py-3">
                                {t('admin.consoleUsers.profile.labels.teamName')}
                              </th>
                              <th className="border-right px-4 py-3">{t('common:labels.apps')}</th>
                              <th className="pr-4 py-3">{t('common:labels.role')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {initialValues.teams.map((team, teamIndex) => (
                              <tr className="loaded" key={`${team.name + teamIndex}`}>
                                <td className="pl-4 py-3">{team.name}</td>
                                <td className="px-4 py-3">
                                  {!team.apps ? (
                                    <span className="text-info">{t('common:labels.na')}</span>
                                  ) : !team.apps.length ? (
                                    <span className="text-info">
                                      {t('applications.empty.general')}
                                    </span>
                                  ) : (
                                    team.apps.map((app) => (
                                      <>
                                        <Link
                                          key={`${app.id}app`}
                                          to={{
                                            pathname: `/admin/app/${app.id}/detail`,
                                            state: { from: location.pathname },
                                          }}
                                          className="text-reset text-underline p-0"
                                        >
                                          {app.name}
                                        </Link>
                                        {teamIndex !== team.apps.length - 1 && <br />}
                                      </>
                                    ))
                                  )}
                                </td>
                                <td className="pr-4 py-3">
                                  {t(`settings.members.labels.roles.${team.role_id}`)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Card.Body>
        </Card>
      )}

      {prodAccess.show && (
        <ProdAccessModal
          show={prodAccess.show}
          name={initialValues.full_name}
          handle={initialValues.handle}
          onHide={updateProdAccess}
          access_granted={initialValues.access_granted}
        />
      )}
    </Container>
  );
}

export default ConsoleUser;
