import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';

import { truncateFileName } from '../../utils';
import { FILE_NAME_MAX_LENGTH, MAX_FILE_SIZE } from '../../constants';

import LoaderButton from '../common/LoaderButton';
import FieldWrapper from '../form/wrappers/Field';

const DocumentUploadModal = ({ show, types, onHide, percentage, isStatement=false, buttonLabel, titleLabel, descriptionLabel }) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const initialValues = !isStatement ? {
    document_type: types[0].name,
    document_type_name: '',
    identity_type: types[0].identity_type,
    files: []
  } : {
    files: []
  }

  const onSubmit = (values) => {
    setSubmitting(true);
    onHide(values, setSubmitting);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue, values }) => (
        <Modal id="doc-upload-modal" centered
          backdrop="static"
          show={show}
          size="lg"
          onHide={() => onHide()}
          aria-labelledby="doc-upload-modal-title">
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Modal.Header closeButton={Object.keys(percentage).length === 0}>
              <Modal.Title id="doc-upload-modal-title" as="h3">{titleLabel || t('common:upload.modal.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-lg text-info">{descriptionLabel || t('common:upload.modal.description')}</p>
              {!isStatement && <Field required readOnly={Object.keys(percentage).length} className="type"
                type="select"
                id="docUploadForm.type"
                name="document_type"
                label={t('common:upload.form.fields.type.label')}
                options={types.map(type => ({ value: type.name, label: type.label }))}
                handleChange={(e) => {
                  const newValue = types.find(type => type.name === e.target.value);
                  if (e.target.value !== 'other') {
                    setFieldValue('document_type', newValue.name);
                    setFieldValue('identity_type', newValue.identity_type);
                  }
                }}
                component={FieldWrapper} />}
              {/* {values.document_type === 'other' && <Field required className="type_name"
                id="docUploadForm.type_name"
                name="document_type_name"
                label={`${t('common:upload.form.fields.type_name.label')}:`}
                component={FieldWrapper} />} */}
              <Field required className="files mb-0"
                type="file"
                id="docUploadForm.files"
                name="files"
                label={t('common:upload.form.fields.files.placeholder')}
                percentage={percentage}
                options={{
                  onDrop: (acceptedFiles) => {
                    const files = acceptedFiles.map((file) => new File([file], truncateFileName(file.name, FILE_NAME_MAX_LENGTH), { type: file.type }));
                    setFieldValue('files', files);
                    return files;
                  },
                  multiple: false,
                  maxSize: MAX_FILE_SIZE,
                  accept: !isStatement ? 'image/jpeg, image/png, application/pdf' : 'image/png, image/svg+xml'
                }}
                note={!isStatement && t('common:upload.form.fields.files.description')}
                component={FieldWrapper} />
            </Modal.Body>
            <Modal.Footer>
              <Button disabled={isSubmitting || Object.keys(percentage).length} variant="outline-light" onClick={() => onHide()}>{t('common:buttons.cancel')}</Button>
              <LoaderButton loadingLabel={t('common:status.uploading')} disabled={!values.files.length || Object.keys(percentage).length} loading={isSubmitting} type="submit">{buttonLabel || t('common:upload.buttons.add')}</LoaderButton>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
};

DocumentUploadModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The document types array
   */
  types: PropTypes.array,
  /**
   * The percentage object to store progress integer for each file
   */
  percentage: PropTypes.object.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default DocumentUploadModal;