import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { webhooksApi } from '../../api';

import { formatDateAndTime, hasPermissions } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import TooltipButton from '../../components/common/TooltipButton';

const WebhookEvent = ({ t, match, location, history, user, setAlert }) => {
  const [event, setEvent] = useState(false);
  const [exists, setExists] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const startsWith = event && event.last_response_status_code ? parseInt(event.last_response_status_code.toString().charAt(0)) : 0;

  const getEndpoint = async (id, app_id) => {
    try {
      const response = await webhooksApi.getEndpoint(id, app_id);
      setExists(response.success);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const getWebhookEvent = async () => {
    try {
      const response = await webhooksApi.getWebhookEvent(match.params.id, user.currentTeam.id);
      if (response.success) {
        setEvent(response.event);
        getEndpoint(response.event.webhook_endpoint_id, response.event.app.app_id);
      } else {
        setRedirect(true);
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (match.params.action === 'detail') {
      getWebhookEvent();
    } else {
      setRedirect(true);
    }
  }, [match]);

  useEffect(() => {
    if (redirect) history.push({ pathname: '/dev/webhook_events', state: { from: location.pathname } });
  }, [redirect]);

  return (
    <Container className="webhooks main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5 loaded">
        <PageTitle title={t('webhooks.events.detail.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {!event ? <Loader /> :
        <Card className="loaded">
          <Card.Header className="d-block d-sm-flex justify-content-between align-content-stretch p-4">

            <h3 className="mb-3 mb-sm-0 flex-grow-1"><span className="text-break d-block w-75">{event.webhook_endpoint_name} <span className="mx-2 text-info">|</span> {event.webhook_endpoint_url}</span></h3>
            <div className="d-flex align-items-center">
              {exists && <Button as={NavLink} size="sm" className="loaded mr-4" to={{ pathname: `/dev/webhooks/${event.app.app_id}/${event.webhook_endpoint_id}/detail`, state: { from: location.pathname } }}>{t(`webhooks.events.detail.buttons.${hasPermissions(user, 'webhooks', 'Write') ? 'manage' : 'view'}`)}</Button>}
              <p className={`text-lg mb-0 text-${event.webhook_endpoint_deleted_at ? 'info' : !event.is_webhook_endpoint_disabled ? 'success' : 'warning'}`}>{t(`common:status.${event.webhook_endpoint_deleted_at ? 'deleted' : !event.is_webhook_endpoint_disabled ? 'active' : 'inactive'}`)}</p>
            </div>

          </Card.Header>
          <Card.Body className="p-4 pt-5">

            <Row as="ul" className="list-unstyled m-0">
              <Col as="li" md="4" className="border-left pl-4 mb-5">
                <h4 className="text-info text-sm">{t('common:labels.created')}</h4>
                <span className="text-lg">{formatDateAndTime(event.created)}</span>
              </Col>
              <Col as="li" md="4" className="border-left pl-4 mb-5">
                <h4 className="text-info text-sm">{t('webhooks.endpoints.labels.events')}</h4>
                <span className="text-lg">{`/${event.event_type}`}</span>
              </Col>
              <Col as="li" md="4" className="border-left pl-4 mb-5">
                <h4 className="text-info text-sm">{t('webhooks.events.detail.labels.status_code')}</h4>
                {event.archived ? <>
                  <span className="text-primary text-lg">{t('common:status.unsent')}</span>
                  <TooltipButton placement="top" variant="link" text={t('webhooks.events.send.description')} className="p-0 no-underline ml-3 mt-n1"><i className="sila-icon info text-lg"></i></TooltipButton>
                </> :
                  <span className={`text-lg text-${startsWith == 2 ? 'success' : (startsWith == 4 || startsWith == 5) ? 'warning' : 'primary'}`}>{event.last_response_status_code}</span>}
              </Col>
              <Col as="li" md="4" className="border-left pl-4 mb-5">
                <h4 className="text-info text-sm">{t('webhooks.endpoints.labels.attempts')}</h4>
                <span className="text-lg">{event.attempts}</span>
              </Col>
              <Col as="li" md="4" className="border-left pl-4 mb-5">
                <h4 className="text-info text-sm">{t('webhooks.events.detail.labels.uuid')}</h4>
                <span className="text-lg text-break w-100">{event.uuid}</span>
              </Col>
              <Col as="li" md="4" className="border-left pl-4 mb-5">
                <h4 className="text-info text-sm">{t('webhooks.endpoints.labels.app')}</h4>
                <span className="text-lg">{event.app.app_nickname.length ? event.app.app_nickname : event.app.app_handle}</span>
              </Col>
            </Row>

            {event.json_details && <Form.Group>
              <Form.Label className="text-lg mb-4">{t('webhooks.events.detail.labels.request')}</Form.Label>
              <Form.Control readOnly disabled className="no-resize overflow-auto mb-0 custom-scrollbar" as="textarea" rows={Object.keys(JSON.parse(event.json_details)).length + 2} value={JSON.stringify(JSON.parse(event.json_details), null, '\t')} />
            </Form.Group>}

          </Card.Body>
        </Card>}

    </Container>
  );
};

export default WebhookEvent;