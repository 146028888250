import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SILA_LIMITS } from '../../../constants';

import { calcPercent } from '../../../utils';

import Loader from '../Loader';
import TooltipButton from '../TooltipButton';
import ReturnRatesModal from '../../common/transactions/ReturnRatesModal';

const rateColor = (percent, limit) => {
  const p = calcPercent(percent);
  const l = calcPercent(limit);
  return classNames(
    'text-lg',
    p >= l && 'text-warning',
    p >= (l * .75) && percent <= (l - 0.01) && 'text-caution',
    p < (l * .75) && 'text-primary'
  )
};

const rateTip = (t, type, percent, limit) => {
  const p = calcPercent(percent);
  const l = calcPercent(limit);
  return t(`common:transactions.return_rates.${p >= l ? 'exceeds' : p >= (l * .75) && p <= (l - 0.01) ? 'caution' : 'meets'}`, {
    type: type.toLowerCase()
  });
};

const ReturnRates = ({ rates, headerLeftComponent, headerRightComponent }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (<>
    <Row noGutters as="ul" className="return-rates list-unstyled mb-0 loaded">
      {headerLeftComponent && <Col as="li" lg="auto" className="d-block d-lg-flex align-items-center mb-2 mb-lg-0">
        {headerLeftComponent()}
      </Col>}
      <Col as="li" className="rates d-flex align-items-center">
        <Row noGutters as="ul" className="w-100 list-unstyled mb-0">
          <Col as="li" xl="4" className="total d-flex flex-wrap align-items-center justify-content-sm-center">
            <span className="position-relative">
              {!rates ? <Loader size="sm" /> : <span className={rates ? rateColor(rates.total_percent, SILA_LIMITS.total) : ''}>{rates.total_percent}%</span>}
            </span>
            <span className="text-nowrap">
              <Form.Label
                className="mx-3 mb-0 text-nowrap"
                htmlFor="views-filter">{t('common:transactions.return_rates.total')}</Form.Label>
              {rates && <TooltipButton placement="top" variant="link" text={rateTip(t, t('common:transactions.return_rates.total'), rates.total_percent, SILA_LIMITS.total)} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>}
            </span>
          </Col>
          <Col as="li" xl="4" className="unauth d-flex flex-wrap align-items-center justify-content-sm-center">
            <span className="position-relative">
              {!rates ? <Loader size="sm" /> : <span className={rates ? rateColor(rates.unauth_percent, SILA_LIMITS.unauth) : ''}>{rates.unauth_percent}%</span>}
            </span>
            <span className="text-nowrap">
              <Form.Label
                className="mx-3 mb-0 text-nowrap"
                htmlFor="views-filter">{t('common:transactions.return_rates.unauth')}</Form.Label>
              {rates && <TooltipButton placement="top" variant="link" text={rateTip(t, t('common:transactions.return_rates.unauth'), rates.unauth_percent, SILA_LIMITS.unauth)} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>}
            </span>
          </Col>
          <Col as="li" xl="4" className="admin d-flex flex-wrap align-items-center justify-content-sm-center">
            <span className="position-relative">
              {!rates ? <Loader size="sm" /> : <span className={rates ? rateColor(rates.admin_percent, SILA_LIMITS.admin) : ''}>{rates.admin_percent}%</span>}
            </span>
            <span className="text-nowrap">
              <Form.Label
                className="mx-3 mb-0 text-nowrap"
                htmlFor="views-filter">{t('common:transactions.return_rates.admin')}</Form.Label>
              {rates && <TooltipButton placement="top" variant="link" text={rateTip(t, t('common:transactions.return_rates.admin'), rates.admin_percent, SILA_LIMITS.admin)} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>}
            </span>
          </Col>
        </Row>
      </Col>
      <Col as="li" lg="auto" className="d-flex align-items-center mt-2 mt-lg-0 justify-content-end">
        <Button variant="link" onClick={() => setShow(true)} className="p-0 text-underline text-nowrap">{t('common:buttons.learn')}</Button>
        {headerRightComponent && headerRightComponent()}
      </Col>
    </Row>

    <ReturnRatesModal show={show} onHide={() => setShow(false)} />

  </>);
};

ReturnRates.propTypes = {
  /**
  * The rates object
  */
  rates: PropTypes.object,
  /**
  * The optional component to render to the left of the returns table
  */
  headerLeftComponent: PropTypes.func,
  /**
  * The optional component to render to the right of the returns table
  */
  headerRightComponent: PropTypes.func
};

export default ReturnRates;