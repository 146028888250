import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatRole, hasExpired, hasPermissions } from '../../utils';
import { useAuth } from '../context'

import StatusBadge from './StatusBadge';
import TooltipButton from '../common/TooltipButton';

const Member = forwardRef(({ data, onEdit, onDelete }, ref) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const handleClick = (e) => {
    if (e.target.closest && !e.target.closest('.delete') || !e.target.closest && !e.target.className.includes('close')) {
      onEdit(data.member && data.member.id ? data.member.id : data);
    } else {
      e.preventDefault();
    }
  };

  return (
    <tr className={`loaded${hasPermissions(user, 'members', 'Read-Only') || (data.invitation && hasExpired(data.invitation.expires_at)) || data.member && data.role.id === 1 && user.role !== 1 ? ' disabled' : ''}`} onClick={hasPermissions(user, 'members', 'Write') && (data.member && (data.role.id !== 1 || data.role.id === 1 && user.role === 1) || data.invitation && !hasExpired(data.invitation.expires_at)) ? handleClick : undefined} ref={ref}>
      <td className="name py-3 pl-4 text-break" colSpan={data.invitation ? 2 : undefined}>{data.member ? data.member.name : data.invitation.email}{data.member && data.member.handle === user.entity.handle && <TooltipButton placement="top" variant="link" text={t('settings.members.you')} className="p-0"><i className="fas fa-star text-primary ml-2"></i></TooltipButton>}</td>
      {data.member && <td className="handle py-3">{data.member.handle}</td>}
      <td className="role py-3">{formatRole(t, data.member ? data.role.id : data.invitation.role)}</td>
      <td className="status py-3" width='1%'><StatusBadge data={data} /></td>
      {hasPermissions(user, 'members', 'Delete') && <td className="pr-4 py-3">
        {hasPermissions(user, 'members', 'Delete') && (data.invitation || data.member && (data.role.id !== 1 || data.role.id === 1 && user.role === 1)) && <button className="delete close float-none align-middle" onClick={onDelete}><i className="text-lg sila-icon close"></i><span className="sr-only">{t('common:buttons.delete')}</span></button>}
      </td>}
    </tr>);
});

Member.propTypes = {
  /**
   * The member data object
   */
  data: PropTypes.object.isRequired,
  /**
   * The function after the memnber is updated
   */
  onEdit: PropTypes.func.isRequired,
  /**
   * The function after the member is deleted
   */
  onDelete: PropTypes.func.isRequired
};

export default Member;