import React, { useState, useRef } from 'react';
import { Container, Col, Form, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { authApi } from '../api';

import LoaderButton from '../components/common/LoaderButton';
import FieldWrapper from '../components/form/wrappers/Field';
import CaptchaWrapper from '../components/form/wrappers/Captcha';

const ForgotPassword = ({ t, setAlert }) => {
  const [recaptchaToken, setRecaptchaToken] = useState(process.env.RECAPTCHA_SKIP === 'True');
  const [page, setPage] = useState('email');
  const recaptchaRef = useRef(null);

  const initialValues = {
    email: ''
  };

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('common:form.fields.email.error'))
      .required(t('common:form.errors.required', { field: t('common:form.fields.email.label') }))
  });

  let titleKey = (page == 'email') ? 'title' : 'emailSentTitle';

  return (
    <Container className="login forgot-password main-content-container p-0">
      <h2 className="mb-3 mb-md-5">{t(`forgotPassword.${titleKey}`)}</h2>
      {page == 'sent' && <p className="mb-4 text-lg text-info">{t('forgotPassword.emailSentSubTitle')}</p>}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (process.env.RECAPTCHA_SKIP !== 'True') {
            values = { ...values, ctoken: recaptchaToken }
          }
          try {
            const response = await authApi.requestNewPassword(values);
            setAlert({ message: response.message, variant: 'success' });
            setPage('sent');
          } catch (error) {
            setAlert({ message: error, variant: 'warning' });
            resetForm({ values: initialValues });
            if (process.env.RECAPTCHA_SKIP !== 'True') {
              setRecaptchaToken(false);
              recaptchaRef.current.reset();
            }
          } finally {
            setSubmitting(false);
          }
        }}>
        {({ errors, handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            {page == 'email' && <>
              <Field required autofocus material className="email mb-4"
                id="forgotPasswordForm.email"
                label={t('common:form.fields.email.label')}
                name="email"
                handleChange={(e) => setFieldValue(e.target.name, e.target.value.toLowerCase().trim())}
                component={FieldWrapper} />
              <CaptchaWrapper className="form-group my-5" ref={recaptchaRef} handleChange={(value) => setRecaptchaToken(value)} />
              <LoaderButton className="mr-4" variant="primary" type="submit" loading={isSubmitting} disabled={Object.keys(errors).length || !isValid || !recaptchaToken || !values.email}>{t('common:buttons.submit')}</LoaderButton></>}
            {page == 'sent' && <Col className="p-0 pb-5 mb-5"></Col>}
            <Button as={NavLink} to="/login" variant={(page == 'sent') ? "primary" : "outline-light"}>{t((page == 'sent') ? 'common:buttons.backToLogin' : 'common:buttons.cancel')}</Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

export default ForgotPassword;
