import React from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { adminApi } from '../../api';
import { hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import EndUsersList from '../../components/common/endusers/EndUsersList';
import DisabledOverlay from '../../components/common/DisabledOverlay';

const EndUsers = ({ t, history, user }) => {

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.endUsers.title')}>
          <Button variant="outline-light" className="back text-meta" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]) ?
        <EndUsersList
          customer={false}
          filterName="admin_end_users"
          fetchData={adminApi.fetchEndUsers}
          fetchOptions={{ team: user.currentTeam.id }}
          onRowClick={(row) => history.push(`/admin/end_user/${row.original.user_handle}/profile`)}
          onFiltered={({ tableFilters, setData, setLoaded, setRefresh }) => {
            if (tableFilters.filters.length || tableFilters.sortBy.length) {
              setRefresh(true);
            } else {
              setData([]);
              setLoaded(true);
            }
          }}
          hasPermissions={({ user, loaded }) => loaded && hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])} />
        :
        <div className="position-relative"><DisabledOverlay /></div>}

    </Container>
  );
};

export default EndUsers;
