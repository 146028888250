import React, { useState, useRef, useEffect } from 'react';
import { CardGroup, Card, Form, Col } from 'react-bootstrap';
import { Formik, Field } from 'formik';

import { useTranslation, Trans } from 'react-i18next'
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';
import ConfirmModal from '../common/ConfirmModal';

import { UUID_REG_EXP } from '../../constants';
import { adminApi } from '../../api';
import { useAlerts } from '../context';
import { isValidUUID } from '../../utils';

const TooltipAppend = ({ field, classes }) => {
  const { t } = useTranslation();
  return <TooltipButton placement="top" variant="link" text={t(`admin.applications.instantSettlement.form.fields.${field}.tooltip`)} className={classes ? classes : 'ml-2 no-underline p-0'}><i className="sila-icon info text-lg"></i></TooltipButton>;
};

const InstantSettlementForm = ({ initialValues, onSave }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [confirm, setConfirm] = useState({ show: false, data: undefined });
  const [enabled, setEnabled] = useState(initialValues.instant_settlement && initialValues.instant_settlement.enabled || false);
  const [isValidWalletUUID, setIsValidWalletUUID] = useState(false);
  const formRef = useRef(null);

  const enableInstantSettlement = async (values) => {
    if (values) {
      try {
        values = { ...values, enabled }
        if (initialValues.instant_settlement && initialValues.instant_settlement.crw_wallet_uuid) {
          await adminApi.updateInstantSettlement(initialValues.id, values);
        } else {
          await adminApi.createInstantSettlement(initialValues.id, values);
        }
        setAlert({ message: t('admin.applications.instantSettlement.form.messages.success'), variant: 'success' });
        onSave();
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setConfirm({ ...confirm, show: false, data: undefined });
        formRef.current.setSubmitting(false);
      }
    } else {
      setConfirm({ ...confirm, show: false, data: undefined });
      formRef.current.setSubmitting(false);
    }
  };


  useEffect(() => {
    if (formRef.current && initialValues.instant_settlement) {
      formRef.current.setValues(initialValues.instant_settlement);
    }
  }, [formRef.current]);

  return (<>
    <Formik
      enableReinitialize={true}
      initialValues={{
        crw_wallet_balance: '',
        crw_wallet_uuid: '',
        initial_reserve_balance: '',
        warning_threshold: '',
        minimum_balance: ''
      }}
      innerRef={formRef}
      validationSchema={yup.object().shape({
        crw_wallet_uuid: yup.string()
          .required(t('common:form.errors.required', { field: t('admin.applications.instantSettlement.form.fields.walletUUID.label') }))
          .matches(UUID_REG_EXP, { message: t('common:form.fields.uuid.error') }),
        initial_reserve_balance: yup.number()
          .required(t('common:form.errors.required', { field: t('admin.applications.instantSettlement.form.fields.initialAmount.label') })),
        warning_threshold: yup.number()
          .required(t('common:form.errors.required', { field: t('admin.applications.instantSettlement.form.fields.warningThreshold.label') })),
        minimum_balance: yup.number()
          .required(t('common:form.errors.required', { field: t('admin.applications.instantSettlement.form.fields.minimumThreshold.label') }))
      })}
      onSubmit={(values) => setConfirm({ ...confirm, show: true, data: values })}>
      {({ values, errors, handleSubmit, isSubmitting, dirty }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>

          <div className="d-flex align-items-center mb-2">
            <h4>{t('admin.applications.instantSettlement.title')}</h4>
            <Form.Check type="switch" className="ml-auto" id="instant-settlement-enabled"
              checked={enabled}
              onChange={(e) => {
                const checked = e.target.checked;
                setEnabled(checked);
                formRef.current.setFieldValue('enabled', checked);
              }}
              label={t(`common:status.${enabled ? 'active' : 'inactive'}`)}
            />
          </div>

          <p className="text-info mb-4"><Trans i18nKey="admin.applications.instantSettlement.description">In order for Instant Settlement to be turned on in Production, <span className="text-warning">you must first convert the individual entity to a business entity by using the Company Wallet tab.</span> This ensures the customer can link their business bank account to their CRW.</Trans></p>

          <div className="position-relative">

            {isSubmitting && <Loader />}

            <CardGroup className="loaded mb-4">
              <Card className="form-control border border-lightest rounded-sm rounded-right-0">
                <Card.Body className="p-0 d-flex align-items-center">
                  <Form.Label className="mb-0">{t('admin.applications.instantSettlement.form.fields.crw.label')}</Form.Label>
                  <TooltipAppend field="crw" classes="no-underline p-0 app-tooltip ml-3" />
                </Card.Body>
              </Card>
              <Card className="form-control border border-lightest rounded-sm rounded-left-0">
                <Card.Body className="p-0 d-flex justify-content-end align-items-center">
                  <i className="fas fa-dollar-sign"></i>
                  <span className="pl-2">{values.crw_wallet_balance || '---'}</span>
                </Card.Body>
              </Card>
            </CardGroup>

            <Form.Row className="loaded w-100 mb-4 mx-0">
              <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                <Field required className="loaded m-0"
                  fieldClass={`${isValidWalletUUID ? 'border-success' : ''}`}
                  tabIndex={1}
                  id="crw_wallet_uuid"
                  placeholder={t('admin.applications.instantSettlement.form.fields.walletUUID.placeholder')}
                  name="crw_wallet_uuid"
                  handleBlur={(e) => { setIsValidWalletUUID(isValidUUID(e.target.value)) }}
                  label={<>{t('admin.applications.instantSettlement.form.fields.walletUUID.label')} <TooltipAppend field={'walletUUID'} /></>}
                  component={FieldWrapper} />
                {isValidWalletUUID && <p className="mt-3 mb-0 loaded"><i className="text-lg pr-2 fas fa-check text-success"></i>{t(`admin.applications.instantSettlement.form.fields.walletUUID.message`)}</p>}
              </Col>
              <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                <Field required className="loaded m-0"
                  tabIndex={2}
                  type="number"
                  id="initial_reserve_balance"
                  placeholder={t('admin.applications.instantSettlement.form.fields.initialAmount.placeholder')}
                  name="initial_reserve_balance"
                  label={<>{t('admin.applications.instantSettlement.form.fields.initialAmount.label')} <TooltipAppend field={'initialAmount'} /></>}
                  prepend={<i className="fas fa-dollar-sign"></i>}
                  component={FieldWrapper} />
              </Col>
            </Form.Row>

            <Form.Row className="loaded w-100 mb-4 mx-0">
              <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                <Field required className="loaded m-0"
                  tabIndex={3}
                  type="number"
                  id="warning_threshold"
                  placeholder={t('admin.applications.instantSettlement.form.fields.warningThreshold.placeholder')}
                  name="warning_threshold"
                  label={<>{t('admin.applications.instantSettlement.form.fields.warningThreshold.label')} <TooltipAppend field={'warningThreshold'} /></>}
                  prepend={<i className="fas fa-dollar-sign"></i>}
                  component={FieldWrapper} />
              </Col>
              <Col sm="12" lg="6" className="mb-3 mb-lg-0">
                <Field required className="loaded m-0"
                  tabIndex={4}
                  type="number"
                  id="minimum_balance"
                  placeholder={t('admin.applications.instantSettlement.form.fields.minimumThreshold.placeholder')}
                  name="minimum_balance"
                  label={<>{t('admin.applications.instantSettlement.form.fields.minimumThreshold.label')} <TooltipAppend field={'minimumThreshold'} /></>}
                  prepend={<i className="fas fa-dollar-sign"></i>}
                  component={FieldWrapper} />
              </Col>
            </Form.Row>

            <div className="d-flex justify-content-end mt-4">
              <LoaderButton loading={isSubmitting} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit" disabled={!dirty || Object.keys(errors).length}>{t('common:buttons.save')}</LoaderButton>
            </div>

          </div>
        </Form>
      )}
    </Formik>

    {confirm.show && <ConfirmModal
      show={confirm.show}
      data={confirm.data}
      title={t('admin.applications.instantSettlement.modal.title')}
      message={t('admin.applications.instantSettlement.modal.description')}
      buttonLabel={t('common:buttons.apply')}
      onHide={enableInstantSettlement} />}

  </>);
};

InstantSettlementForm.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to be called when the application is saved
   */
  onSave: PropTypes.func.isRequired
};

export default InstantSettlementForm;