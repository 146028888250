import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SalesTierLimitTypesModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  return (
    <Modal centered id="limit-types-modal"
      backdrop="static"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="limit-types-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="limit-types-modal-title">{t('admin.salesTiers.limits.types.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-lg mb-4 text-info">{t('admin.salesTiers.limits.types.description')}</p>
        <p className="text-lg mb-4"><strong>{t('admin.salesTiers.limits.types.rate.title')}:</strong><span className="text-info ml-2">{t('admin.salesTiers.limits.types.rate.description')}</span></p>
        <p className="text-lg mb-4"><strong>{t('admin.salesTiers.limits.types.aggregate.title')}:</strong><span className="text-info ml-2">{t('admin.salesTiers.limits.types.aggregate.description')}</span></p>
        <p className="text-lg mb-4"><strong>{t('admin.salesTiers.limits.types.unscoped.title')}:</strong><span className="text-info ml-2">{t('admin.salesTiers.limits.types.unscoped.description')}</span></p>
      </Modal.Body>
    </Modal>
  );
};

SalesTierLimitTypesModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The function that is fired when the modal is closed
   */
  onHide: PropTypes.func.isRequired
};

export default SalesTierLimitTypesModal;