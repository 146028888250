import {
  Wallet
} from 'ethers';
import randomBytes from 'randombytes';
import Cookies from 'js-cookie';

import {
  UUID_REG_EXP,
  REQUEST_PROD_URL,
  COOKIES_OPTIONS,
  SUPERUSER_LEVEL,
} from '../constants';

export const reflow = node => {
  void node.offsetHeight;
};

export const getNodeHeight = node => {
  return node.scrollHeight;
};

export const parseParamData = (match) => {
  let params = window.location.search.substring(1);
  params = params.split('&');
  const data = [];
  for (var i = 0; i < params.length; i++) {
    if (params[i].startsWith(match)) {
      let param = params[i].split('=');
      data.push({
        key: param[0],
        val: (param.length > 1) ? param[1] : ''
      });
    }
  }
  return data;
};

export const getUrlParams = () => [...new URLSearchParams(document.location.search).entries()].reduce((q, [k, v]) => Object.assign(q, {
  [k]: v.replace(' ', '+')
}), {});

export const getInitials = (name) => {
  const regexChar = /\D\w+/
  return name
    .trim()
    .split(' ')
    .filter(word => word.length > 0)
    .filter(word => regexChar.test(word))
    .map(word => word.substring(0, 1).toUpperCase());
};

export const hasPermissions = (user, slug, rule) => {
  const currentRule = user && user.permissions && user.permissions.find(rule => rule.target === slug);
  return currentRule && currentRule.level.includes(rule) ? true : false;
};

export const hasSuperuserAccess = (user, levels) => {
  return (
    // comment out this line to mock admin.silamoney.com
    window.location.href.includes('admin.silamoney.com') &&
    user.is_superuser &&
    levels.includes(user.superuser_level)
  );
};

export const hasSalesAccess = (user) => {
  return (
    window.location.href.includes('admin.silamoney.com') &&
    user.superuser_level === SUPERUSER_LEVEL.SALES
  );
};

export const isAdminView = () => {
  return window.location.href.includes('/admin/');
};

export const hasExpired = (date) => Date.parse(date) < new Date().getTime();

export const filterEmpty = (obj, [key, val]) => {
  if (typeof val === 'boolean' || val) {
    obj[key] = removeEmpty(val)
  };
  if (obj instanceof Array) {
    return obj.some(value => Object.keys(value).length === 1 && value.action) ? [] : obj.filter(value => Object.keys(value).length !== 0);
  } else {
    return obj;
  }
};

export const removeEmpty = (entry) => {
  if (entry instanceof Object) {
    const type = entry instanceof Array ? [] : {};
    const entries = Object.entries(entry);
    return entries.reduce(filterEmpty, type);
  }
  return entry;
};

export const getQueryString = (params) => {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
};

export const stripTrailingSlash = (str) => str.substr(-1) === '/' ? str.substr(0, str.length - 1) : str;

export const generateWallet = (callback) => {
  const privateKey = randomBytes(32).toString('hex');
  const generated = new Wallet(privateKey);
  const wallet = {
    blockchain_address: generated.address,
    privateKey: privateKey
  };
  if (callback) callback(wallet);
  return wallet;
};

export const simpleHandle = (handle) => handle.toLowerCase().replace(/.silamoney.eth/g, '');

export const formatHandle = (handle) => `${handle}.silamoney.eth`;

export const formatRole = (t, role) => t(`settings.members.labels.roles.${role}`);

export const formatRoleID = (role) => role === 'owner' ? 1 : role === 'manager' ? 2 : role === 'developer' ? 3 : 4;

export const formatMd5Name = (original, updated) => original && original.replace('Team ', '').match(/^[a-f0-9]{32}$/gm) ? updated : original;

export const formatDateAndTime = (timestamp, showTime = true) => {
  const pad = (n) => n < 10 ? '0' + n : n;
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = pad(1 + date.getMonth());
  const day = pad(date.getDate());
  const time = date.toLocaleTimeString('en-US');
  return `${month}/${day}/${year}${showTime ? ` ${time}` : ''}`;
};

export const formatInternationalDateAndTime = (date, showTime, locale) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(showTime && { hour: '2-digit', minute: '2-digit' }),
  };
  return new Intl.DateTimeFormat(locale, options).format(new Date(date));
};

export const formatAddress = (data) => {
  const address = [];
  data.street_address_1 && address.push(data.street_address_1);
  data.street_address_2 && address.push(data.street_address_2);
  data.city && address.push(`${data.city},`);
  data.state && address.push(`${data.state},`);
  data.postal_code && address.push(data.postal_code);
  return address.join(' ');
};

export const formatNum = (num, digits) => {
  let units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
    decimal;
  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);
    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }
  return num;
};

export const formatNumberColumns = (num) => num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const capitalize = (str) => {
  try {
    return str.toString().charAt(0).toUpperCase() + str.toString().slice(1);
  } catch(e) {
    console.log('Error capitalize:', e.message);
  }
}

export const disAllowPassword = (passValue, emailValue, usernameValue) => {
  let validPassword = true;
  if (!passValue && !emailValue) return validPassword;

  let disallowPasswordFormats = [
    emailValue,
    `${emailValue}$`,
    `${emailValue}password`,
    `password${emailValue}`,
    `pass${emailValue}$$`
  ];

  if (passValue && usernameValue && usernameValue !== null) {
    disallowPasswordFormats.push(usernameValue);
    disallowPasswordFormats.push(`${usernameValue}$`);
    disallowPasswordFormats.push(`${usernameValue}password`);
    disallowPasswordFormats.push(`password${usernameValue}`);
    disallowPasswordFormats.push(`pass${usernameValue}$$`);
  }

  if (disallowPasswordFormats.length > 0) {
    for (let i = 0; i < disallowPasswordFormats.length; i++) {
      if (disallowPasswordFormats[i] && disallowPasswordFormats[i].includes(passValue)) {
        validPassword = false;
        break;
      }
    }
  }

  return validPassword;
};

export const setUniqueCookie = (cName, handle) => {
  const currentYear = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(currentYear.getFullYear() + 1);
  Cookies.set(`${cName}_${handle}`, new Date().toString(), {
    ...COOKIES_OPTIONS,
    'expires': nextYear
  });
};

export const requestProdAccess = (t, user) => {
  window.open(REQUEST_PROD_URL, '_blank');
  setUniqueCookie('sila_console_prequalification', user.entity.handle);
};

export const handleResponseError = (response) => {
  let error = '';
  const iterateErrors = (errors, parent) => {
    if (typeof errors === 'object') {
      Object.entries(errors).forEach(([key, value], index) => {
        if (typeof errors[key] === 'object') {
          iterateErrors(errors[key], key);
        } else {
          error += `(${parent ? `${parent}.${key}` : key}) ${value.split('.').join('')}`;
        }
        error += Object.keys(errors).length - 1 !== index ? ', ' : !parent ? '.' : '';
      });
    } else {
      error = errors;
    }
  };
  if (response.validation_details) {
    iterateErrors(response.validation_details);
  } else if (response.message) {
    error = response.message.replace(/\\/g, '');
  } else {
    error = JSON.stringify(response);
  }
  return error;
};

export const isValidJson = (json) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};

export const uniqueFieldValue = function (name, message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }
    throw this.createError({
      path: `${this.path}[${list.findIndex((l, i) => mapper(l) !== set[i])}].${name}`,
      message
    });
  });
};

export const formatFieldArray = (values, props, callback) => {
  Object.values(values).map(value => value instanceof Array ?
    value.map(value => {
      value.tempId && delete value.tempId
      if (!value.uuid && !value.id) value.action = 'add';
    }) : value);
  if (callback) callback(values, props);
};

export const injectPdfjsCdn = (version) => `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.mjs`;

export const truncateFileName = (name, max) => {
  const filename = name.substring(0, name.lastIndexOf('.'));
  const extension = name.split('.').pop();
  return name.length > max ? `${filename.substring(0, max)}.${extension}` : name;
};

export const isValidUUID = (str) => UUID_REG_EXP.test(str);

export const getObjectDiff = (base, object) => {
  const changes = {};

  function walkObject(base, object, path = '') {
    for (const [key, value] of Object.entries(object)) {
      const currentPath = Array.isArray(object) ? path + `[${key}]` : path === '' ? key : `${path}.${key}`;
      if (value !== base[key]) {
        if (typeof value === 'object' && typeof base[key] === 'object') {
          walkObject(base[key], value, currentPath)
        } else {
          changes[currentPath] = object[key];
        }
      }
    }
  }
  walkObject(base, object);
  return changes;
};

export const getUniqueArray = (arr = [], compareProps = []) => {
  let modifiedArray = [];
  if (compareProps.length === 0 && arr.length > 0)
    compareProps.push(...Object.keys(arr[0]));
  arr.forEach(item => {
    if (modifiedArray.length === 0) {
      modifiedArray.push(item);
    } else {
      if (!modifiedArray.some(item2 =>
          compareProps.every(eachProps => item2[eachProps] === item[eachProps])
        )) {
        modifiedArray.push(item);
      }
    }
  });
  return modifiedArray;
};

export const applyFilter = ({
  filters = [],
  sortBy = []
}) => {
  const formatFilter = ({
    filters,
    sortBy
  }) => {
    const activeFilters = {
      filters: [],
      sortBy: []
    };
    if (sortBy && sortBy.length) {
      sortBy.forEach(sort => {
        const term = `&sort_keys=${sort.id}${sort.desc ? '_desc' : ''}`;
        if (activeFilters.sortBy.some(q => q.includes(sort.id))) {
          activeFilters.sortBy.map(q => q.includes(sort.id) ? term : q);
        } else {
          activeFilters.sortBy.push(term);
        }
      });
    } else {
      activeFilters.sortBy = [];
    }
    if (filters && filters.length) {
      filters.forEach(filter => {
        let term;
        if (filter.value instanceof Array) {
          filter.value.forEach(value => {
            term = `&${value.term ? `${filter.id}SearchTerm` : filter.id}=${value.term || value}`;
            if (!activeFilters.filters.some(q => q.includes(term))) activeFilters.filters.push(term);
          });
        } else {
          term = `&${filter.id}=${filter.value}`;
          if (!activeFilters.filters.some(q => q.includes(term))) activeFilters.filters.push(term);
        }
      });
    } else {
      activeFilters.filters = [];
    }
    return [...activeFilters.sortBy, ...activeFilters.filters].join('');
  }
  return {
    query: formatFilter({
      filters: filters.query,
      sortBy: sortBy.query
    }).replace(/SearchTerm/g, ''),
    url: formatFilter({
      filters: filters.url,
      sortBy: sortBy.url
    }).substring(1)
  };
};

export const applyQuery = (query) => {
  const activeFilters = {
    filters: [],
    sortBy: []
  };
  if (query.split('&').length) {
    query.split('&').forEach(param => {
      let term = param.split('='),
        termId, termValue;
      if (term.length) {
        termId = term[0];
        termValue = term[1];
        try {
          termValue = decodeURI(termValue);
        } catch (e) {
          console.log("ERROR DECODING URI");
        }
        if (termId.includes('sort_keys')) {
          activeFilters.sortBy.push({
            id: termValue.replace(/_desc/g, ''),
            desc: termValue.includes('desc')
          });
        } else if (activeFilters.filters.some(filter => (!termId.includes('SearchTerm') && filter.id === termId && filter.value.some(value => value !== termValue)) || (termId.includes('SearchTerm') && filter.id === termId.replace(/SearchTerm/g, '') && filter.value.some(value => value.term && value.term !== termValue)))) {
          activeFilters.filters.forEach((filter, index) => {
            const found = activeFilters.filters.findIndex(filter => filter.id === termId.replace(/SearchTerm/g, ''));
            if (found === index) activeFilters.filters[index] = {
              ...filter,
              value: [...filter.value, filter.value.some(value => value.term) ? {
                term: termValue
              } : termValue]
            };
          });
        } else if (termId.includes('SearchTerm')) {
          activeFilters.filters.push({
            id: termId.replace(/SearchTerm/g, ''),
            value: [{
              term: termValue
            }]
          });
        } else if (termId && termValue) {
          activeFilters.filters.push({
            id: termId,
            value: [termValue]
          });
        }
      }
    });
  }
  return activeFilters;
};

export const convertToUSD = (value) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}).format(value * 0.01).replace(/\$/g, '');

export const convertToSila = (value) => {
  value = (value + '').replace(/[^\d.-]/g, '');
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3);
  }
  return value ? Math.round(parseFloat(value) * 100) : 0;
};

export const parseDate = (date) => {
  return new Date(`${date}.000Z`);
};

export const convertDateToUTC = (date) => {
  const newDate = new Date(date);
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000).toISOString();
};

export const converUTCDatetoLocal = (date) => {
  const newDate = parseDate(date);
  return newDate.getFullYear() + '-' +
  ('0'+ (newDate.getMonth()+1)).slice(-2) + '-' +
  ('0'+ newDate.getDate()).slice(-2);
};

export const calcPercent = (num) => num / 100 * 100;

export const truncate = (string, max) => string.length > max ? `${string.substring(0, max)}...` : string;

export const convertSecondsToTime = (epoch) => {
  const seconds = Math.floor(epoch % 60);
  const minutes = Math.floor((epoch % 3600) / 60);
  const hours = Math.floor((epoch % (3600 * 24)) / 3600);
  const days = Math.floor(epoch / (3600 * 24));
  const makeHumanReadable = (num, singular) => num > 0 ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `) : '';
  const secondsStr = makeHumanReadable(seconds, 'second');
  const minutesStr = makeHumanReadable(minutes, 'minute');
  const hoursStr = makeHumanReadable(hours, 'hour');
  const daysStr = makeHumanReadable(days, 'day');
  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
};

export const linkify = (value) => {
  let linktext = value;
  try {
    linktext = value.replace(/((http:|https:)[^\s]+[\w])/g, '<a href="$1" target="_blank">$1</a>');
  } catch (error) {
    console.log('linkify failed!', error);
  }
  return linktext;
};

export const uploadDocument = async ({
  url,
  file,
  data,
  onUpload,
  onError,
  onSuccess
}) => {
  const xhr = new XMLHttpRequest();
  const formData = new FormData();
  formData.append('file', file);
  formData.append('data', JSON.stringify(data));
  xhr.upload.onprogress = (event => {
    if (event.lengthComputable) {
      onUpload(Math.floor((event.loaded / event.total) * 100))
    }
  });
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== 4) return;
    const json = JSON.parse(xhr.responseText);
    const message = json.message;
    if (xhr.status === 200) {
      onSuccess(message);
    } else {
      onError(xhr.status, handleResponseError(json));
    }
  };
  xhr.open('POST', `/api/v2${url}`, true);
  //xhr.setRequestHeader('Authorization', Bearer ${auth.token});
  xhr.send(formData);
};

export const configureChart = (options) => {
  const {
    xlabel,
    ylabel,
    title
  } = options;
  return {
    responsive: true,
    layout: {
      padding: {
        left: 20,
        right: 20
      }
    },
    scales: {
      x: {
        display: true,
        reverse: true,
        title: {
          display: true,
          text: xlabel,
          font: {
            family: 'Inter, sans-serif'
          }
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        suggestedMax: 5,
        ticks: {
          stepSize: 1,
        },
        title: {
          display: true,
          text: ylabel,
          font: {
            family: 'Inter, sans-serif'
          }
        }
      }
    },
    plugins: {
      tooltip: {
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'Inter, sans-serif'
        },
        bodyFont: {
          family: 'Inter, sans-serif'
        },
        footerFont: {
          family: 'Inter, sans-serif'
        }
      },
      title: {
        display: true,
        color: '#00146B',
        font: {
          size: 16,
          family: 'Inter, sans-serif',
          weight: 'normal'
        },
        text: title
      }
    }
  }
};

/**
 * Formats a limit object by converting duration from seconds to days
 *  and amount to a from cents to dollars, and all time values to UTC.
 *
 * @param {Object} limit - The limit object to be formatted.
 */
export function formatLimitResponse(limit) {
  if (limit.expires_at) {
    // eslint-disable-next-line no-param-reassign
    limit.expires_at = new Date(limit.expires_at).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  if (limit.begins_at) {
    // eslint-disable-next-line no-param-reassign
    limit.begins_at = new Date(limit.begins_at).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  if (limit.duration !== null) {
    // eslint-disable-next-line no-param-reassign
    limit.duration /= 24 * 60 * 60;
  }

  if (limit.amount !== null) {
    // eslint-disable-next-line no-param-reassign
    limit.amount /= 100;
  }

  return limit;
}

/**
 * Formats the limits array within the response object by
 *  formatting each limit's duration from seconds to days
 *  and amount to a from cents to dollars.
 *
 * @param {Object} response - The response object containing the limits array.
 */
export function formatLimitsResponse(response) {
  if (response.limits && Array.isArray(response.limits)) {
    response.limits = response.limits.map((limit) => {
      return formatLimitResponse(limit);
    });
  }
}

/**
 * Formats a limit query object by converting duration from
 *  days to seconds and amount from dollars to cents and time values
 * to UTC.
 *
 * @param {Object} limit - The limit query object to be formatted.
 * @returns {Object} The formatted limit query object.
 */
export function formatLimitQuery(limit) {
  if (limit.duration && limit.duration !== null) {
    // eslint-disable-next-line no-param-reassign
    limit.duration *= 24 * 60 * 60;
  }

  // TODO: remove this extra limit parameter when updating LimitForm to send multiple tgls
  if (limit.transaction_graph_label) {
    // eslint-disable-next-line no-param-reassign
    limit.transaction_graph_labels = [limit.transaction_graph_label];
  }

  if (limit.amount && limit.amount !== null) {
    // eslint-disable-next-line no-param-reassign
    limit.amount *= 100;
  }

  if (limit.begins_at) {
    // eslint-disable-next-line no-param-reassign
    limit.begins_at = new Date(limit.begins_at).toISOString();
  }

  if (limit.expires_at) {
    // eslint-disable-next-line no-param-reassign
    limit.expires_at = new Date(limit.expires_at).toISOString();
  }

  return limit;
}
