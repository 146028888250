/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import LoaderButton from './LoaderButton';

function ConfirmModal({
  size,
  data,
  title,
  message,
  renderMessageComponent,
  itemLabel,
  buttonLabel,
  buttonLoadingLabel,
  buttonVariant,
  buttonDisabled,
  show,
  onHide,
  isHide,
  cancelButtonLabel,
}) {
  const [disabled, setDisabled] = useState(buttonDisabled);
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = (data) => {
    setSubmitting(true);
    onHide(data);
  };

  useEffect(() => {
    setSubmitting(false);
  }, [show]);

  return (
    <Modal
      centered
      id="confirm-modal"
      backdrop="static"
      show={show}
      onHide={isHide ? onHide : handleSubmit}
      size={size}
      aria-labelledby="confirm-modal-title"
    >
      <Modal.Header closeButton={!isSubmitting}>
        <Modal.Title as="h3" id="confirm-modal-title">
          {title || t('common:buttons.saveChanges')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderMessageComponent ? (
          renderMessageComponent({ data, setDisabled })
        ) : (
          <p className="text-lg text-info">
            {data && itemLabel
              ? t('common:form.messages.confirm.save', { name: itemLabel })
              : message || t('common:form.messages.confirm.default')}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isSubmitting} variant="outline-light" onClick={() => onHide()}>
          {cancelButtonLabel || t('common:buttons.cancel')}
        </Button>
        <LoaderButton
          disabled={disabled}
          loadingLabel={buttonLoadingLabel || undefined}
          loading={isSubmitting}
          variant={buttonVariant || undefined}
          onClick={() => handleSubmit(data)}
        >
          {buttonLabel || t('common:buttons.save')}
        </LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  /**
   * The size of the modal
   */
  size: PropTypes.string,
  /**
   * The data object
   */
  data: PropTypes.any,
  /**
   * The confirm modal title
   */
  title: PropTypes.string,
  /**
   * The confirm modal description
   */
  message: PropTypes.string,
  /**
   * The confirm modal message component
   */
  renderMessageComponent: PropTypes.func,
  /**
   * The name of the item to be confirmed
   */
  itemLabel: PropTypes.string,
  /**
   * The button label
   */
  buttonLabel: PropTypes.string,
  /**
   * The button loading label
   */
  buttonLoadingLabel: PropTypes.string,
  /**
   * The button style
   */
  buttonVariant: PropTypes.string,
  /**
   * The button disabled boolean
   */
  buttonDisabled: PropTypes.bool,
  /**
   * The function that is fired when the modal is closed
   */
  onHide: PropTypes.func.isRequired,
};

export default ConfirmModal;
