import { useEffect } from 'react';

import { useAuth } from '../components/context';
import { authApi } from '../api';

const Logout = ({ user, frozen, location, history, setAlert }) => {
  const { onLogout } = useAuth();

  useEffect(() => {
    const logout = async () => {
      if (user || frozen) {
        try {
          const message = await authApi.logout();
          if (!location.state || location.state && !location.state.noMessage) setAlert({ message, variant: 'success' });
          onLogout();
          history.push({ pathname: '/login' });
        } catch (error) {
          setAlert({ message: error, variant: 'warning' });
        }
      } else {
        history.push({ pathname: '/' });
      }
    }
    logout();
  }, []);

  return null;
}

export default Logout;
