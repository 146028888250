import React from 'react';
import { Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { hasPermissions } from '../../utils';

import { useAuth } from '../context';

import PageTitle from '../common/PageTitle';

const AppsHeader = ({ newApp, hasApps, children, title }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={title || t('applications.title')}>
          {user.confirmed_at && <><p className="text-info font-italic mb-md-0 mr-md-4" style={{ zIndex: 2 }}>
            <Trans i18nKey="common:tips.apiExplorer">Read up on <a href="https://docs.silamoney.com/docs/info-management-security-requirements" target="_blank">security requirements</a>!</Trans></p>
            {hasPermissions(user, 'apps', 'Write') && !newApp && hasApps && <Button variant="primary" onClick={() => history.push('/app/new')}>{t('applications.buttons.create')} <i className="fas fa-plus-circle ml-2"></i></Button>}</>}
            {children}
        </PageTitle>
      </Row>
  );
};

AppsHeader.propTypes = {
  /**
   * The new app boolean
   */
  newApp: PropTypes.bool,
  /**
   * The has apps boolean
   */
  hasApps: PropTypes.bool,
  /**
   * The children components.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /**
   * The page title.
   */
  title: PropTypes.string
};

export default AppsHeader;
