import { handleResponseError } from '../utils';
import consoleApi from './console';

const checkRoutingNumber = async (routingNumber) => {
  try {
    const response = await consoleApi.get({
      url: '/check_routing_number_capabilities',
      data: { routing_number: routingNumber },
    });

    if (!response.success || response.status_code !== 200) {
      throw handleResponseError(response);
    }

    return response;
  } catch (error) {
    throw error;
  }
};

const salesApi = {
  checkRoutingNumber,
};

export default salesApi;
