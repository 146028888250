import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Card, Row, Col, Carousel, Badge } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

import { useCurrentDimensions } from '../../utils/hooks'
import { useAuth } from '../context';

const WelcomeBanner = () => {
  const [height, setHeight] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const itemsRef = useRef([]);
  const currentWidth = useCurrentDimensions().width;

  const handleClick = () => {
    history.push({ pathname: user.hasApps ? '/apps' : '/app/new', state: { from: location.pathname } })
  }

  useEffect(() => {
    const arrayList = [];
    const lists = itemsRef.current;
    if (lists.length) {
      for (let i = 0; i < lists.length; i++) {
        if (lists[i]) {
          var eachList = lists[i].clientHeight;
          arrayList.push(eachList);
          setHeight(Math.max.apply(null, arrayList) + 50);
        }
      }
    }
  }, [currentWidth, itemsRef]);

  return (
    <Card className="banner flex-fill mb-5">
      <Card.Body as={Row} className="p-4">
        <Col md="12" lg="6" className="message d-flex flex-column pr-0 pr-lg-4 pb-4 pb-lg-0">
          <h1 className="mb-3">{t('welcome.title')}</h1>
          <p className="text-lg text-info mb-3">{t('welcome.description')}</p>
          <Button disabled={!user.confirmed_at} onClick={handleClick} className="text-sm text-uppercase mt-auto mr-auto">{t(`welcome.banner.button.${user.hasApps ? 'hasApps' : 'noApps'}`)}</Button>
        </Col>
        <Col md="12" lg="6" className="features pt-4 pt-lg-0 px-0 mx-3 mx-lg-0 px-lg-4">
          <h3 className="mb-3">{t('welcome.features.title')}</h3>
          <Carousel as="aside" style={height ? { height } : undefined}>
            <Carousel.Item ref={(el) => itemsRef.current.splice(0, 1, el)}>
              <div className="d-flex align-items-center mb-3"> 
                <h5 className="mb-0">{t('welcome.features.item1.title')}</h5>
                <Badge variant="primary" className="opaque px-3 py-2 ml-4 d-flex justify-content-center flex-column">API 0.2.40</Badge>
              </div>
              <p className="text-info mb-0 pb-2"><Trans i18nKey="welcome.features.item1.description">Virtual Accounts enable creation of an account and routing number for an end-user via an API call. Allows funds to move on and off the Sila platform with only an external ACH transaction and without an API call.<a href="https://docs.silamoney.com/docs/virtual-accounts" className="ml-2 text-underline" target="_blank">Learn more on our docs!</a></Trans></p>
            </Carousel.Item>
            <Carousel.Item ref={(el) => itemsRef.current.splice(1, 1, el)}>
              <div className="d-flex align-items-center mb-3">
                <h5 className="mb-0">{t('welcome.features.item2.title')}</h5>
                <Badge variant="primary" className="opaque px-3 py-2 ml-4 d-flex justify-content-center flex-column">API 0.2.39</Badge>
              </div>
              <p className="text-info mb-0 pb-2"><Trans i18nKey="welcome.features.item2.description">Instant Settlement allows immediate funds availability via the /issue_sila endpoint. <a href="https://docs.silamoney.com/docs/instant-settlement-beta" className="ml-2 text-underline" target="_blank">Learn more on our docs!</a></Trans></p>
            </Carousel.Item>
            <Carousel.Item ref={(el) => itemsRef.current.splice(2, 1, el)}>
              <div className="d-flex align-items-center mb-3">
                <h5 className="mb-0">{t('welcome.features.item3.title')}</h5>
                <Badge variant="primary" className="opaque px-3 py-2 ml-4 d-flex justify-content-center flex-column">Various</Badge>
              </div>
              <p className="text-info mb-0 pb-2">{t('welcome.features.item3.description')}</p>
            </Carousel.Item>
            <Carousel.Item ref={(el) => itemsRef.current.splice(3, 1, el)}>
              <div className="d-flex align-items-center mb-3">
                <h5 className="mb-0">{t('welcome.features.item5.title')}</h5>
                <Badge variant="primary" className="opaque px-3 py-2 ml-4 d-flex justify-content-center flex-column">API 0.2.38</Badge>
              </div>
              <p className="text-info mb-0 pb-2"><Trans i18nKey="welcome.features.item5.description">We now can provoke conditions that will result in a `documents_required` status. <a href="https://docs.silamoney.com/docs/register" className="ml-2 text-underline" target="_blank">Learn more on our docs!</a></Trans></p>
            </Carousel.Item>
            <Carousel.Item ref={(el) => itemsRef.current.splice(4, 1, el)}>
              <div className="d-flex align-items-center mb-3">
                <h5 className="mb-0"><span className="d-block text-break w-100">{t('welcome.features.item6.title')}</span></h5>
                <Badge variant="primary" className="opaque px-3 py-2 ml-4 d-flex justify-content-center flex-column">Various</Badge>
              </div>
              <p className="text-info mb-0 pb-2"><Trans i18nKey="welcome.features.item6.description">We are adding more payment methods all the time. We’ve now added an endpoint that returns all payment or redemption methods available for a user. <a href="https://docs.silamoney.com/docs/get_payment_methods" className="ml-2 text-underline" target="_blank">Learn more on our docs!</a></Trans></p>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Card.Body>
    </Card>
  );
};

export default WelcomeBanner;
