/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';

import { appsApi } from '../api';
import { hasPermissions } from '../utils';
import { useInfiniteScroll } from '../utils/hooks';

import Loader from '../components/common/Loader';
import AppsHeader from '../components/applications/AppsHeader';
import DisabledOverlay from '../components/common/DisabledOverlay';
import CenteredOverlay from '../components/common/CenteredOverlay';
import AppPanels from '../components/applications/AppPanels';

function Applications({ t, match, user, isMobile, history, setAlert }) {
  /**
   * Handles the logic for fetching and managing the data of apps shown at <base-url>/apps.
   * Displays the header and maps over fetched apps, displayed in <Applicaiton/> components
   */
  const newApp = hasPermissions(user, 'apps', 'Write') && match.params.action === 'new';

  const { data, setData, setRefresh, lastItem, hasMore, isLoading, isFetching, loaded, error } =
    useInfiniteScroll(
      appsApi.fetchApps,
      {
        slug: 'apps',
        team: user.currentTeam ? user.currentTeam.id : null,
        limit: 8,
      },
      hasPermissions(user, 'apps', 'Read')
    );

  const handleDelete = async (id) => {
    setData((prevData) => prevData.filter((app) => app.id !== id));
  };

  const handleSave = async (id, values) => {
    setData((prevData) => prevData.map((app) => (app.id === id ? { ...app, ...values } : app)));
  };

  const handleFreeze = async (id, values) => {
    setData((prevData) =>
      prevData.map((app) => (app.id === id ? { ...app, frozen: values.frozen } : app))
    );
  };

  const fetchMetrics = async () => {
    try {
      const response = await appsApi.fetchMetrics(
        data.filter((app) => !app.metrics && !app.pending).map((app) => app.id)
      );
      setData((prevData) =>
        prevData.map((app) => {
          const metrics = response.find((metric) => metric.id === app.id);
          return metrics ? { ...app, metrics } : app;
        })
      );
      // eslint-disable-next-line no-shadow
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (error) setAlert({ message: error, variant: 'warning' });
  }, [error]);

  useEffect(() => {
    if (loaded) setRefresh(true);
  }, [user.currentTeam]);

  useEffect(() => {
    if (!isFetching) fetchMetrics();
  }, [isFetching]);

  const shouldBeOpen = (apps, index) => {
    if (apps.length <= 3) return true;
    if (index === 0) return true;
    return false;
  };

  return (
    <Container className="applications main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <AppsHeader hasApps={data.length !== 0} />

      {!hasPermissions(user, 'apps', 'Read') && <DisabledOverlay />}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {data.length !== 0 ? (
            data.map((app, i) => (
              <div className="pb-4" key={app.handle}>
                <AppPanels
                  app={app}
                  t={t}
                  onDelete={handleDelete}
                  isMobile={isMobile}
                  onSave={handleSave}
                  user={user}
                  history={history}
                  match={match}
                  shouldBeOpen={shouldBeOpen(data, i)}
                  onFreeze={handleFreeze}
                  lastItem={
                    !isFetching && data.length > 1 && i + 1 === data.length ? lastItem : null
                  }
                  isAppsView
                />
              </div>
            ))
          ) : !newApp ? (
            <CenteredOverlay>
              <p className="text-center text-info font-italic">
                {user.confirmed_at
                  ? t('applications.empty.messages.confirmed')
                  : t('applications.empty.messages.notConfirmed')}
              </p>
              {hasPermissions(user, 'apps', 'Write') && user.confirmed_at && (
                <Button onClick={() => history.push('/app/new')}>
                  {t('applications.empty.action')}
                </Button>
              )}
            </CenteredOverlay>
          ) : null}

          {loaded && isFetching && hasMore && (
            <div className="fetching">
              <Loader label={t('applications.loading')} />
            </div>
          )}
        </>
      )}
    </Container>
  );
}

export default Applications;
