import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { UUID_REG_EXP } from '../../constants';

import FieldWrapper from '../form/wrappers/Field';

const UUIDSearchForm = ({ onSubmit, placeholder, htmlSize }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        uuid: '',
      }}
      validationSchema={yup.object().shape({
        uuid: yup.string()
          .required(t('common:form.errors.required', { field: t('common:form.fields.uuid.label') }))
          .matches(UUID_REG_EXP, { message: t('common:form.fields.uuid.error') })
      })}
      onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
          <Field required className="uuid mb-0"
            size="lg"
            htmlSize={htmlSize || 40}
            fieldClass="pr-0"
            id="uuidSearchForm.uuid"
            placeholder={placeholder || t('common:form.fields.uuid.placeholder')}
            name="uuid"
            component={FieldWrapper} />
        </Form>
      )}
    </Formik>
  );
};

UUIDSearchForm.propTypes = {
  /**
   * The submit function
   */
  onSubmit: PropTypes.func.isRequired,
};

export default UUIDSearchForm;