/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
import React from 'react';

// Components
import RedirectRoute from '../components/routes/RedirectRoute';

// Layout Types
import { DefaultLayout, SimpleLayout } from '../layouts';

// Route Views
import Login from '../views/Login';
import Mfa from '../views/Mfa';
import Logout from '../views/Logout';
import Register from '../views/Register';
import InviteTeamMembers from '../views/InviteTeamMembers';
import Questionnaire from '../views/Questionnaire';
// import ConfirmEmail from '../views/ConfirmEmail';
import ConfirmTeam from '../views/ConfirmTeam';
import ConfirmAccount from '../views/ConfirmAccount';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import Welcome from '../views/Welcome';
import Account from '../views/Account';
import Settings from '../views/settings/Settings';
import Team from '../views/settings/Team';
import Transactions from '../views/settings/Transactions';
import Transaction from '../views/settings/Transaction';
import RequestsReturn from '../views/settings/RequestsReturn';
import RequestReturn from '../views/settings/RequestReturn';
import Statements from '../views/settings/Statements';
import Statement from '../views/settings/Statement';
import EndUsers from '../views/settings/EndUsers';
import Members from '../views/settings/Members';
import EndUserProfile from '../views/settings/EndUserProfile';
import Developers from '../views/developers/Developers';
import Webhooks from '../views/developers/Webhooks';
import WebhookEndpoint from '../views/developers/WebhookEndpoint';
import WebhookEvents from '../views/developers/WebhookEvents';
import WebhookEvent from '../views/developers/WebhookEvent';
import ConfirmWebhookEmailAlerts from '../views/ConfirmWebhookEmailAlerts';
import Admin from '../views/admin/Admin';
import AdminApplications from '../views/admin/Applications';
import AdminApplication from '../views/admin/Application';
import AdminCustomersChecklistTemplates from '../views/admin/ChecklistTemplates';
import AdminCustomersChecklistTemplate from '../views/admin/ChecklistTemplate';
import AdminCustomersOwners from '../views/admin/ChecklistOwners';
import AdminCustomersOwner from '../views/admin/ChecklistOwner';
import AdminCustomersOnboarding from '../views/admin/Onboarding';
import AdminCustomersOnboardingProfile from '../views/admin/OnboardingProfile';
import AdminConsoleUsers from '../views/admin/ConsoleUsers';
import AdminConsoleUser from '../views/admin/ConsoleUser';
import AdminEndUsers from '../views/admin/EndUsers';
import AdminEndUser from '../views/admin/EndUser';
import AdminLimits from '../views/admin/Limits';
import AdminLimit from '../views/admin/Limit';
import AdminLimitOverride from '../views/admin/LimitOverride';
import AdminLimitOverrides from '../views/admin/LimitOverrides';
import AdminTransactions from '../views/admin/Transactions';
import AdminTransaction from '../views/admin/Transaction';
import AdminRequestsReturn from '../views/admin/RequestsReturn';
import AdminRequestReturn from '../views/admin/RequestReturn';
import AdminSalesTiers from '../views/admin/SalesTiers';
import AdminSalesTier from '../views/admin/SalesTier';
import Sales from '../views/sales/Sales';
import Application from '../views/Application';
import Applications from '../views/Applications';
import Errors from '../views/Errors';
import Maintenance from '../views/Maintenance';
import RoutingNumberChecker from '../views/sales/RoutingNumberChecker';

export default [
  {
    restricted: false,
    name: 'Login',
    path: '/login',
    layout: SimpleLayout,
    component: Login,
    seo: {
      title: 'Sign In | Sila API'
    },
    routes: [
      {
        all: true,
        name: 'Sign In::Two-factor authentication.',
        path: '/login/mfa',
        layout: SimpleLayout,
        component: Mfa
      }
    ]
  },
  {
    all: true,
    name: 'Logout',
    path: '/logout',
    layout: DefaultLayout,
    component: Logout
  },
  {
    restricted: false,
    name: 'Register',
    path: '/register',
    layout: SimpleLayout,
    component: Register,
    seo: {
      title: 'Sign Up | Sila API'
    },
    routes: [
      {
        all: true,
        name: 'Register::Invite Team Members',
        path: '/register/invite_team_members',
        layout: SimpleLayout,
        component: InviteTeamMembers
      },
      {
        all: true,
        name: 'Register::Questions',
        path: '/register/questionnaire',
        layout: (props) => <SimpleLayout reduced={true} {...props} />,
        component: Questionnaire
      }
    ]
  },
  // {
  //   restricted: false,
  //   name: 'Email Confirmation',
  //   path: '/confirm_email',
  //   layout: DefaultLayout,
  //   component: ConfirmEmail
  // },
  {
    restricted: false,
    name: 'Forgot Password',
    path: '/forgot_password',
    layout: SimpleLayout,
    component: ForgotPassword
  },
  {
    restricted: true,
    name: 'Welcome Series',
    path: '/welcome/:page?',
    layout: DefaultLayout,
    // component: Welcome
    component: Welcome
  },
  {
    restricted: true,
    name: 'Account',
    path: '/account',
    layout: DefaultLayout,
    component: Account,
    routes: [
      {
        all: true,
        disableTracking: true,
        name: 'Account::Reset Password',
        path: '/account/reset_password/:token?/:email?',
        layout: (props) => props.user ? <DefaultLayout {...props} /> : <SimpleLayout {...props} />,
        component: ResetPassword
      },
      {
        all: true,
        disableTracking: true,
        name: 'Account::Confirmation',
        path: '/account/confirm/:token',
        layout: DefaultLayout,
        component: ConfirmAccount
      },
      {
        all: true,
        disableTracking: true,
        name: 'Webhook Alerts::Email Confirmation',
        path: '/account/confirm_alerts/:token/:app_id/:id',
        layout: DefaultLayout,
        component: ConfirmWebhookEmailAlerts
      }
    ]
  },
  {
    all: true,
    disableTracking: true,
    name: 'Account Admin::Team Member Confirmation',
    path: '/invitation/:token',
    layout: DefaultLayout,
    component: ConfirmTeam
  },
  {
    sales: true,
    name: 'Sila Sales',
    path: '/sales',
    layout: DefaultLayout,
    component: Sales,
    routes: [
      {
        sales: true,
        exact: true,
        name: 'Sila Sales::ACHNow Routing number Checker',
        path: '/sales/check_routing_number',
        layout: DefaultLayout,
        component: RoutingNumberChecker,
      },
    ]
  },
  {
    admin: true,
    name: 'Sila Admin',
    path: '/admin',
    layout: DefaultLayout,
    component: Admin,
    routes: [
      {
        admin: true,
        exact: true,
        name: 'Sila Admin::Checklist Owners',
        path: '/admin/customers/owners',
        layout: DefaultLayout,
        component: AdminCustomersOwners,
      },
      {
        admin: true,
        exact: true,
        name: 'Sila Admin::Checklist Owner Details',
        path: '/admin/customers/owners/:id?',
        layout: DefaultLayout,
        component: AdminCustomersOwner
      },
      {
        admin: true,
        exact: true,
        name: 'Sila Admin::Checklist Templates',
        path: '/admin/customers/checklists',
        layout: DefaultLayout,
        component: AdminCustomersChecklistTemplates,
      },
      {
        admin: true,
        exact: true,
        name: 'Sila Admin::Checklist Templates Details',
        path: '/admin/customers/checklists/:id?',
        layout: DefaultLayout,
        component: AdminCustomersChecklistTemplate
      },
      {
        admin: true,
        exact: true,
        name: 'Sila Admin::Onboarding Dashboard',
        path: '/admin/customers/onboarding',
        layout: DefaultLayout,
        component: AdminCustomersOnboarding,
      }, {
        admin: true,
        name: 'Sila Admin::Onboarding Customer Info',
        path: '/admin/customers/onboarding/:id/:action?/:checklist?',
        layout: DefaultLayout,
        component: AdminCustomersOnboardingProfile,
      }, {
        admin: true,
        exact: true,
        name: 'Sila Admin::Console Users',
        path: '/admin/console_users',
        layout: DefaultLayout,
        component: AdminConsoleUsers,
      }, {
        admin: true,
        name: 'Sila Admin::Console User Details',
        path: '/admin/console_user/:handle/:action?',
        layout: DefaultLayout,
        component: AdminConsoleUser,
      }, {
        admin: true,
        name: 'Sila Admin::Apps',
        path: '/admin/apps',
        layout: DefaultLayout,
        component: AdminApplications
      },
      {
        admin: true,
        name: 'Sila Admin::Application Details',
        path: '/admin/app/:id/:action?',
        layout: DefaultLayout,
        component: AdminApplication
      },
      {
        admin: true,
        name: 'Sila Admin::End Users',
        path: '/admin/end_users',
        exact: true,
        layout: DefaultLayout,
        component: AdminEndUsers
      }, {
        admin: true,
        name: 'Sila Admin::End User Details',
        path: '/admin/end_user/:handle/:action?',
        layout: DefaultLayout,
        component: AdminEndUser,
      }, {
        admin: true,
        name: 'Sila Admin::Limit',
        path: '/admin/limit/:id/:action?',
        layout: DefaultLayout,
        component: AdminLimit,
      }, {
        admin: true,
        name: 'Sila Admin::Limits',
        path: '/admin/limits',
        layout: DefaultLayout,
        component: AdminLimits,
      }, {
        admin: true,
        name: 'Sila Admin::Limit',
        path: '/admin/limit_override/:id/:action?',
        layout: DefaultLayout,
        component: AdminLimitOverride,
      }, {
        admin: true,
        name: 'Sila Admin::Limits',
        path: '/admin/limit_overrides',
        layout: DefaultLayout,
        component: AdminLimitOverrides,
      }, {
        admin: true,
        name: 'Sila Admin::Transactions',
        path: '/admin/transactions',
        exact: true,
        layout: DefaultLayout,
        component: AdminTransactions
      },
      {
        admin: true,
        name: 'Sila Admin::Transaction Details',
        path: '/admin/transaction/:id/:action?',
        exact: true,
        layout: DefaultLayout,
        component: AdminTransaction
      }, {
        admin: true,
        name: 'Sila Admin::Sales Tiers',
        path: '/admin/sales_tiers',
        exact: true,
        layout: DefaultLayout,
        component: AdminSalesTiers
      },
      {
        admin: true,
        name: 'Sila Admin::Sales Tier Details',
        path: '/admin/sales_tier/:id?/:action?',
        exact: true,
        layout: DefaultLayout,
        component: AdminSalesTier
      },
      {
        admin: true,
        name: 'Sila Admin::Requests for Return',
        path: '/admin/requests-return',
        exact: true,
        layout: DefaultLayout,
        component: AdminRequestsReturn
      },
      {
        admin: true,
        name: 'Sila Admin::Return Details',
        path: '/admin/request-return/:id/:action?',
        exact: true,
        layout: DefaultLayout,
        component: AdminRequestReturn
      }
    ]
  },
  {
    restricted: true,
    name: 'Account Admin',
    path: '/settings',
    layout: DefaultLayout,
    component: Settings,
    routes: [
      {
        restricted: true,
        name: 'Account Admin::Account Information',
        path: '/settings/team',
        layout: DefaultLayout,
        component: Team,
      }, {
        restricted: true,
        name: 'Account Admin::Team Management',
        path: '/settings/members/:action?',
        layout: DefaultLayout,
        component: Members
      }, {
        restricted: true,
        name: 'Account Admin::Transactions',
        path: '/settings/transactions',
        layout: DefaultLayout,
        component: Transactions
      }, {
        restricted: true,
        name: 'Account Admin::Transaction Details',
        path: '/settings/transaction/:id/:action?',
        layout: DefaultLayout,
        component: Transaction
      }, {
        restricted: true,
        name: 'Account Admin::Requests for Return',
        path: '/settings/requests-return',
        layout: DefaultLayout,
        component: RequestsReturn
      }, {
        restricted: true,
        name: 'Account Admin::Requests for Return Details',
        path: '/settings/request-return/:id/:action?',
        layout: DefaultLayout,
        component: RequestReturn
      }, {
        restricted: true,
        name: 'Account Admin::Statements Dashboard',
        path: '/settings/statements',
        layout: DefaultLayout,
        component: Statements
      }, {
        restricted: true,
        name: 'Account Admin::Statement Details',
        path: '/settings/statement/:id/:action?',
        layout: DefaultLayout,
        component: Statement
      }, {
        restricted: true,
        name: 'Account Admin::End Users',
        path: '/settings/end_users',
        layout: DefaultLayout,
        component: EndUsers
      }, {
        restricted: true,
        name: 'Account Admin::End User Edit',
        path: '/settings/end_user/:handle/:action?',
        layout: DefaultLayout,
        component: EndUserProfile
      }
    ]
  },
  {
    restricted: true,
    name: 'Applications',
    path: '/apps',
    exact: true,
    layout: DefaultLayout,
    component: Applications
  },
  {
    restricted: true,
    name: 'Application Details',
    exact: true,
    path: '/app/:id?/:action?',
    layout: DefaultLayout,
    component: Application
  },
  {
    restricted: true,
    name: 'Developers',
    path: '/dev',
    layout: DefaultLayout,
    component: Developers,
    routes: [
      {
        restricted: true,
        name: 'Webhooks',
        path: '/dev/webhooks',
        exact: true,
        layout: DefaultLayout,
        component: Webhooks
      },
      {
        exact: true,
        restricted: true,
        name: 'Webhooks::Endpoint',
        path: '/dev/webhooks/:app?/:id?/:action?',
        layout: DefaultLayout,
        component: WebhookEndpoint
      },
      {
        exact: true,
        restricted: true,
        name: 'Webhooks::Create Endpoint',
        path: '/dev/webhooks/new',
        layout: DefaultLayout,
        component: WebhookEndpoint
      },
      {
        exact: true,
        restricted: true,
        name: 'Webhooks::Webhook Activity Dashboard',
        path: '/dev/webhook_events',
        layout: DefaultLayout,
        component: WebhookEvents
      },
      {
        exact: true,
        restricted: true,
        name: 'Webhooks::Webhook Activity Dashboard',
        path: '/dev/webhook_event/:id?/:action?',
        layout: DefaultLayout,
        component: WebhookEvent
      }
    ]
  },
  {
    all: true,
    name: 'System Error (500)',
    path: '/error',
    layout: DefaultLayout,
    component: (props) => <Errors status={500} {...props} />,
    routes: [
      {
        frozen: true,
        name: 'Account frozen',
        path: '/error/frozen',
        layout: DefaultLayout,
        component: (props) => <Errors status="frozen" {...props} />
      }
    ]
  },
  {
    all: true,
    name: 'Maintenance',
    path: '/maintenance',
    layout: (props) => <DefaultLayout full={true} {...props} />,
    component: () => <Maintenance />
  },
  {
    exact: true,
    path: '/',
    layout: DefaultLayout,
    component: RedirectRoute
  },
  {
    all: true,
    name: 'Page not found (404)',
    path: '/not_found',
    layout: DefaultLayout,
    component: (props) => <Errors status={404} {...props} />
  }
];