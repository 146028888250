import React, { useRef } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';

const ChecklistTask = ({ length, data, isEditing, index, match, setConfirm, isUnique, moveTask, isMobile, expandedRef, arrayHelpers }) => {
  const { t } = useTranslation();

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [spec, dropRef] = useDrop({
    accept: 'item',
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
      moveTask(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const ref = useRef(null);
  const dragDropRef = previewRef(dropRef(ref));

  const opacity = isDragging ? 0 : 1;

  return (
    <li key={data.id || data.uuid} style={{ opacity }} ref={(el) => { dragDropRef.current = el; expandedRef.current.splice(index, 1, el); }} className={`task position-relative bg-alt-hover d-flex flex-row flex-items-stretch align-items-center px-3${isDragging ? ' bg-alt shadow' : ''}`}>
      {length > 1 && isEditing && <div ref={dragRef} className={`cursor-pointer text-meta-link d-flex align-items-center loaded ${isMobile ? 'position-absolute px-3 pt-3' : 'px-2 ml-n2'}${isDragging ? 'text-primary' : 'text-meta'}`} style={isMobile ? { top: 0, right: 0, zIndex: 2 } : undefined}>
        <i className="fas fa-ellipsis-v text-lg"></i>
      </div>}
      {isEditing && length !== 1 && <div className={`d-flex d-lg-none align-items-center loaded${isMobile ? ' position-absolute pr-3 pt-2' : ''}`} style={isMobile ? { top: 0, right: 0, zIndex: 2 } : undefined}>
        {!data.unique && <TooltipButton placement="auto" variant="link" text={t('admin.customers.checklist.details.tips.duplicate')} className="d-inline-block d-lg-none btn-circle bg-white-hover cursor-pointer"><i className="fas fa-exclamation-triangle text-warning text-lg"></i></TooltipButton>}
        <Button variant="link" className="btn-circle bg-white-hover text-lg no-underline text-meta ml-lg-2 mr-lg-n3" onClick={() => setConfirm({ deleteTask: true, data: { task: data, index, arrayHelpers } })}><i className="fas fa-trash"></i></Button>
      </div>}
      <Form.Row className="m-0 w-100 py-2">
        <Col>
          <Field required className="mb-2 mb-lg-0"
            id="editChecklist.name"
            name={`tasks.${index}.name`}
            disabled={!isEditing || data.id}
            size="sm"
            fieldClass={`text-reg${isMobile ? ' mt-2' : ''}`}
            label={isMobile ? t('admin.customers.checklist.details.form.fields.taskName.label') : undefined}
            placeholder={t('admin.customers.checklist.details.form.fields.taskName.label')}
            component={FieldWrapper} />
        </Col>
      </Form.Row>
      {isEditing && !isUnique && <div style={{ width: 30 }} className="d-none d-lg-block loaded text-center">
        {isEditing && !data.unique && <TooltipButton placement="auto" variant="link" text={t('admin.customers.checklist.details.tips.duplicate')} className="p-0 cursor-pointer"><i className="fas fa-exclamation-triangle text-warning text-lg"></i></TooltipButton>}
      </div>}
      {isEditing && length !== 1 && <div style={{ width: 30 }} className="d-none d-lg-block loaded text-center">
        <Button disabled={!isEditing} variant="link" className="btn-circle bg-white-hover text-lg no-underline text-meta" onClick={() => setConfirm({ deleteTask: true, data: { task: data, index, arrayHelpers } })}><i className="fas fa-trash"></i></Button>
      </div>}
    </li>
  );
}

ChecklistTask.propTypes = {
  /**
   * The tasks length
   */
  length: PropTypes.number.isRequired,
  /**
   * The task data object
   */
  data: PropTypes.object.isRequired,
  /**
   * The isEditing boolean
   */
  isEditing: PropTypes.bool.isRequired,
  /**
   * The current task index
   */
  index: PropTypes.number.isRequired,
  /**
   * The match object to get URL params
   */
  match: PropTypes.object.isRequired,
  /**
   * The confirm function
   */
  setConfirm: PropTypes.func.isRequired,
  /**
   * The unique boolean
   */
  isUnique: PropTypes.bool.isRequired,
  /**
   * The move task function
   */
  moveTask: PropTypes.func.isRequired,
  /**
   * The mobile boolean
   */
  isMobile: PropTypes.bool.isRequired,
  /**
   * The expansion ref to detect number of tasks
   */
  expandedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  /**
   * The formik array function
   */
  arrayHelpers: PropTypes.object.isRequired
};

export default ChecklistTask;


