import React, { useState, useEffect } from 'react';

import { authApi, teamApi, consoleApi } from '../api';
import { getUrlParams, formatMd5Name } from '../utils';
import { useAuth } from '../components/context';

import Loader from '../components/common/Loader';
import RedirectRoute from '../components/routes/RedirectRoute';

const ConfirmTeam = ({ match, user, history, t, app, updateApp, setAlert }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { refreshSession, env } = useAuth();

  const params = getUrlParams();

  const sandboxToggle = async (email) => {
    try {
      const response = await consoleApi.sandboxToggle(false);
      if (response.user_approved) {
        refreshSession(user.currentTeam.id, () => {
          getInvitation(email);
        });
      } else {
        setAlert({ message: t('invite.messages.restricted'), variant: 'danger' });
        history.push('/');
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const getInvitation = async (email) => {
    const env = params.env || 'sandbox';
    try {
      const response = await teamApi.getInvitation(match.params.token, email, env);
      updateApp({ 
        invitation: {
          token: match.params.token,
          team_name: user ? formatMd5Name(response.team_name, user.company.name) : response.team_name,
          role_name: response.role_name,
          email: email,
          env
        }
      });
    } catch (error) {
      setAlert({ message: t('invite.messages.invalid', { env: t(`env.${env === 'sandbox' ? 'prod' : 'sandbox'}.label`) }), variant: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmAccountExists = async (email) => {
    try {
      const exists = await authApi.confirmAccountExists(email);
      if (exists) {
        setAlert({ message: t('invite.messages.auth'), variant: 'warning' });
        history.push({ pathname: '/login', state: { handle: params.email, from: window.location.pathname + window.location.search } });
      } else {
        getInvitation(email);
        history.push({ pathname: '/register', state: { from: location.pathname } });
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading && !user && params.email) {
      confirmAccountExists(params.email);
    } else if (params.env === 'prod' && env !== 'prod') {
      sandboxToggle(user.entity.emails[0].email)
    } else if (user && !app.invitation) {
      getInvitation(user.entity.emails[0].email);
    } else {
      setIsLoading(false);
    }
  }, []);

  return isLoading ? <Loader /> : <RedirectRoute noRedirect />;
};

export default ConfirmTeam;