import * as yup from 'yup';
import { Field } from 'formik';
import React from 'react';
import FieldWrapper from '../form/wrappers/Field';
import { FREEZE_REASONS_ARRAY } from '../../constants';

export const generateFreezeReasonValidationSchema = (t) => {
  const field = t('common:freezeReasons.form.fields.reason.label');
  const validationSchema = yup.object().shape({
    freeze_reason: yup.string().trim().required(t('common:form.errors.required', { field })),
  });

  return validationSchema;
};

export function FreezeReasonsField({ isSubmitting, t }) {
  return (
    <div>
      <Field
        autofocus
        required
        className="freeze-reason"
        id="freezeForm.reason"
        name="freeze_reason"
        type="select"
        disabled={isSubmitting}
        options={FREEZE_REASONS_ARRAY.map((reason) => ({ label: reason, value: reason }))}
        label={t('common:freezeReasons.form.fields.reason.label')}
        component={FieldWrapper}
      />
    </div>
  );
}
