import React, { useMemo, useState, useEffect } from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { adminApi } from '../../api';
import { formatDateAndTime, getUrlParams, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import DataList from '../../components/common/DataList';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import TooltipButton from '../../components/common/TooltipButton';
import { CheckboxColumnFilter, MultipleFilter, DateBetweenFilter, DateRangeColumnFilter, RangeColumnFilter, SelectColumnFilter } from '../../components/common/table/TableFilters';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'customer_onboard';

const formatSortBy = (sortBy) => {
  let query = sortBy;
  return { url: sortBy, query };
};

const formatFilters = (filters) => {
  let query = filters;
  const created = filters.find(filter => filter.id === 'created');
  const msa_signed_date = filters.find(filter => filter.id === 'msa_signed');
  const msa = filters.find(filter => filter.id === 'msa');
  const deal_size = filters.find(filter => filter.id === 'deal_size');
  if (created) {
    if (created.value) {
      query = [...query.filter(filter => filter.id !== 'created'), { id: 'pipedrive_from', value: created.value[0]?.slice(0, 10) }, { id: 'pipedrive_to', value: created.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'created')];
    }
  }
  if (msa_signed_date) {
    if (msa_signed_date.value) {
      query = [...query.filter(filter => filter.id !== 'msa_signed'), { id: 'msa_from', value: msa_signed_date.value[0]?.slice(0, 10) }, { id: 'msa_to', value: msa_signed_date.value[1]?.slice(0, 10) }];
    } else {
      query = [...query.filter(filter => filter.id !== 'msa_signed')];
    }
  }
  if (msa) {
    query = [...query.filter(filter => filter.id !== 'msa'), { id: 'msa_signed', value: msa.value.includes('signed') ? true : false }, { id: 'msa_unsigned', value: msa.value.includes('unsigned') ? true : false }];
  }
  if (deal_size) {
    query = [...query.filter(filter => filter.id !== 'deal_size'), { id: 'min_amount', value: deal_size.value[0] }, { id: 'max_amount', value: deal_size.value[1] }]
  }
  if (!filters.length) {
    query = [...query.filter(filter => filter.id !== 'msa'), { id: 'msa_signed', value: true }, { id: 'msa_unsigned', value: false }];
    filters.push({ 'id': 'msa', 'value': ['signed'] });
  }
  return { url: filters, query };
};

const Onboarding = ({ t, history, user, actions, env }) => {
  const [owners, setOwners] = useState([]);
  const params = getUrlParams();
  const columns = useMemo(() => [
    {
      id: 'created',
      Header: t('admin.customers.onboarding.labels.created'),
      accessor: 'pipedrive_date',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => value ? formatDateAndTime(value) : <span className="text-info">{t('common:labels.na')}</span>
    },
    {
      id: 'customer',
      Header: t('common:labels.customer'),
      accessor: 'name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160 }}>{value}</span>,
    },
    {
      id: 'payment_status',
      Header: t('common:labels.paymentStatus'),
      accessor: 'payment_status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160 }}>{value ? value : <span className="text-info">{t('common:labels.na')}</span>}</span>,
      Filter: CheckboxColumnFilter
    },
    {
      id: 'slack_name',
      Header: t('admin.customers.onboarding.labels.slackName'),
      accessor: 'slack_name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160 }}>{value}</span>,
    },
    {
      id: 'msa_signed',
      Header: t('admin.customers.onboarding.labels.msa'),
      accessor: 'msa_signed',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => value ? formatDateAndTime(value) : <span className="text-info">{t('common:labels.na')}</span>
    },
    {
      id: 'msa',
      Header: t('admin.customers.onboarding.labels.msaSign'),
      accessor: 'msa_signed',
      searchOptions: {
        disabled: true
      },
      disableSortBy: true,
      hideColumn: true,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => value,
      filterOptions: () => [{
        value: 'signed',
        label: t('admin.customers.onboarding.labels.signed')
      }, {
        value: 'unsigned',
        label: t('admin.customers.onboarding.labels.notSigned')
      }]
    },
    {
      id: 'deal_size',
      Header: t('admin.customers.onboarding.labels.deal'),
      accessor: 'deal_size',
      Filter: RangeColumnFilter,
      Cell: ({ value }) => value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value) : <span className="text-info">{t('common:labels.na')}</span>,
    },
    {
      id: 'contact_name',
      Header: t('admin.customers.onboarding.labels.name'),
      accessor: 'contact_name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => value ? <span className="text-break d-block" style={{ maxWidth: 160 }}>{value}</span> : <span className="text-info">{t('common:labels.na')}</span>
    },
    {
      id: 'email',
      type: 'email',
      Header: t('common:form.fields.email.label'),
      accessor: 'email',
      filter: MultipleFilter,
      Cell: ({ value }) => value ? <span className="text-break d-block" style={{ maxWidth: 200 }}>{value}</span> : <span className="text-info">{t('common:labels.na')}</span>,
      Filter: CheckboxColumnFilter
    },
    {
      id: 'phone',
      disableSortBy: true,
      Header: t('common:form.fields.phone.label'),
      accessor: 'phone',
      filter: MultipleFilter,
      Cell: ({ value }) => value ? <span className="text-nowrap d-block">{value}</span> : <span className="text-info">{t('common:labels.na')}</span>,
      Filter: CheckboxColumnFilter
    },
    {
      id: 'owner',
      Header: () => <>{t('settings.members.labels.roles.owner')}<TooltipButton placement="top" variant="link" text={t('admin.customers.onboarding.list.tips.owner')} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton></>,
      accessor: 'owners',
      Cell: ({ value }) => params.owner && owners.length && owners.some(owner => owner.id == params.owner) ? owners.find(owner => owner.id == params.owner).name : value.length === 0 ? <span className="text-info">{t('common:labels.na')}</span> : value.length > 1 ? t('common:labels.multiple') : value[0].name,
      disableSortBy: true,
      Filter: SelectColumnFilter,
      filterOptions: () => owners.length && owners.map(owner => ({ label: `${owner.name} (${owner.department})`, value: owner.id }))
    },
    {
      id: 'tasks_remaining',
      disableSortBy: true,
      disableFilters: true,
      Header: () => t('admin.customers.onboarding.labels.tasks'),
      accessor: 'tasks_remaining',
      Filter: RangeColumnFilter
    }
  ], [owners, params]);

  useEffect(() => {
    const fetchCustomerChecklistOwners = async () => {
      try {
        const response = await adminApi.fetchCustomerChecklistOwners({ limit: 100 });
        setOwners(response.owners);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
    fetchCustomerChecklistOwners();
  }, [env]);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.customers.onboarding.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]) ?
        <DataList
          columnSticky={true}
          slug={TABLE_SLUG}
          filterName={TABLE_SLUG}
          limit={TABLE_MAX_COUNT}
          fetchData={adminApi.fetchCustomerOnboard}
          columns={columns}
          onRowClick={(row) => history.push(`/admin/customers/onboarding/${row.original.id}/profile`)}
          onFilter={formatFilters}
          onSortBy={formatSortBy}
          hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE])}
          loadingMessage={t('admin.customers.onboarding.loading')} />
        :
        <div className="position-relative"><DisabledOverlay /></div>}

    </Container>
  );
};

export default Onboarding;