import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Row } from 'react-bootstrap';

import { teamApi } from '../../api';

import { hasPermissions } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import TransactionList from '../../components/common/transactions/TransactionList';
import TooltipButton from '../../components/common/TooltipButton';
import BadgeMenu from '../../components/common/BadgeMenu';
import ReturnRates from '../../components/common/transactions/ReturnRates';
import ReturnRatesModal from '../../components/common/transactions/ReturnRatesModal';

const Transactions = ({ t, history, user, location, setAlert }) => {
  const [show, setShow] = useState(false);
  const [rates, setRates] = useState(undefined);
  const [apps, setApps] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);

  const getReturnRates = async (apps) => {
    setRates(false);
    try {
      const response = await teamApi.getReturnRates(user.currentTeam.id, hasFilters && apps && apps.length ? apps : false);
      setRates(response.calculated_percents);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' })
    }
  };

  useEffect(() => {
    if (apps) getReturnRates(apps);
  }, [apps]);

  return (
    <Container className="main-content-container settings-transactions p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('settings.transactions.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasPermissions(user, 'transactions', 'Read') ?
        <TransactionList
          subHeaderComponent={() => <ReturnRates rates={rates ? rates : undefined}
            headerLeftComponent={() => <>
              <Form.Label
                className="mr-0 mb-2 mb-lg-0 mr-lg-3 text-uppercase text-sm text-nowrap d-flex"
                htmlFor="views-filter">
                {t('common:transactions.return_rates.label')}
                <TooltipButton placement="top" variant="link" text={t('common:transactions.return_rates.description')} className="ml-3 d-block d-lg-none no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>
              </Form.Label>
              <BadgeMenu
                label={!rates ? t('applications.loading') : !hasFilters ? t('common:applications.all') : undefined}
                items={hasFilters && rates && apps && apps.length ? apps : undefined}
              />
              <TooltipButton placement="top" variant="link" text={t('common:transactions.return_rates.description')} className="ml-3 d-none d-lg-block no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>
            </>} />}
          filterName={`team_${user.currentTeam.id}_transactions`}
          hasPermissions={({ user }) => hasPermissions(user, 'transactions', 'Read')}
          fetchData={teamApi.fetchTransactions}
          fetchOptions={{
            team: user.currentTeam.id
          }}
          onUpdate={({ data, tableFilters }) => {
            setHasFilters(data.length && tableFilters.filters.length !== 0);
            setApps(Object.values(data.reduce((a, c) => {
              a[c.app.app_handle + '|' + c.app.app_nickname] = c;
              return a
            }, {})).map(item => item.app.app_handle));
          }}
          onRowClick={(row) => history.push(`/settings/transaction/${row.uuid || row.original.transaction_uuid}/detail`)} /> :
        <div className="position-relative"><DisabledOverlay /></div>}

      <ReturnRatesModal show={show} onHide={() => setShow(false)} />

    </Container>
  )
};

export default Transactions;