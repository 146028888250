import React, { useEffect, useState } from 'react';
import { CardGroup, Card, Row, Col, Form, Button, Collapse } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { webhooksApi } from '../../api';
import { hasPermissions } from '../../utils';

import FieldWrapper from '../form/wrappers/Field';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import ConfirmModal from '../common/ConfirmModal';
import TooltipButton from '../common/TooltipButton';
import EndpointTestModal from './EndpointTestModal';

import { useAuth, useAlerts } from '../context';

const Trigger = ({ data, isLast }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const classes = classNames(
    'loaded',
    expanded && 'expanded',
    isLast && 'border-bottom mb-3 pb-3'
  );
  return (
    <div className={classes}>
      <h4 className="text-reg">{data.label}</h4>
      <p className="text-sm text-info mb-2">{data.description}</p>
      <Button variant="link" className="mb-2 p-0" onClick={() => setExpanded(!expanded)}><i className={`fas fa-${expanded ? 'minus' : 'plus'}-circle mr-2`}></i>{t(`common:buttons.${expanded ? 'hide' : 'show'}`)} {t('webhooks.endpoints.form.buttons.example')}</Button>
      <Collapse in={expanded}><pre className="border rounded text-sm bg-light p-3">{data.payload}</pre></Collapse>
    </div>
  );
};

const EndpointForm = ({ formRef, initialValues, setName, triggers, apps, alerts, setIsLoading, getEndpoint }) => {
  const [activeTriggers, setActiveTriggers] = useState(initialValues.triggers);
  const [trigger, setTrigger] = useState('');
  const [app, setApp] = useState(initialValues.app_id);
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const { user } = useAuth();
  const { setAlert } = useAlerts();
  const history = useHistory();
  const location = useLocation();
  const [currentEndpoint, setCurrentEndpoint] = useState({ save: false, delete: false, test: false, data: initialValues, name: initialValues.name });

  const filteredTrigger = triggers.filter(t => trigger ? t.name.includes(trigger) : t);

  const schema = yup.object().shape({
    name: yup.string().trim()
      .required(t('common:form.errors.required', { field: t('webhooks.endpoints.form.fields.name.label') })),
    url: yup.string().trim()
      .url(t('common:form.fields.url.error'))
      .required(t('common:form.errors.required', { field: t('webhooks.endpoints.form.fields.url.label') })),
    max_attempts: yup.number()
      .min(1, t('webhooks.endpoints.form.fields.max_attempts.error.min'))
      .max(14, t('webhooks.endpoints.form.fields.max_attempts.error.max'))
      .required(t('common:form.errors.integer')),
    retry_interval: yup.number()
      .min(60, t('webhooks.endpoints.form.fields.retry_interval.error'))
      .required(t('common:form.errors.integer')),
    app_id: yup.string().trim()
      .required(t('common:form.errors.required', { field: t('webhooks.endpoints.form.fields.app.label') })),
    alerts_email: yup.string()
      .email(t('common:form.fields.email.error'))
      .max(254, t('common:form.errors.max', { count: 254 }))
  });

  const subscribeAlert = async (id, email) => {
    if (id && email) {
      try {
        const response = await webhooksApi.subscribeWebhookAlert(id, email);
        setAlert({ message: response.message, variant: response.success ? 'success' : 'danger' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  const unsubscribeAlert = async (id) => {
    if (id) {
      try {
        await webhooksApi.unsubscribeWebhookAlert(id);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  const updateAlert = async (id, email) => {
    if (id && email) {
      try {
        await webhooksApi.updateWebhookAlert(id, email);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  const createEndpoint = async (values, setSubmitting) => {
    if (values) {
      setSubmitting(true);
      values.app_id = parseInt(values.app_id);
      try {
        const response = await webhooksApi.createEndpoint(values);
        if (values.alerts_email.length) {
          subscribeAlert(response.endpoint.id, values.alerts_email);
        }
        setRedirect(true);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const updateEndpoint = async (values) => {
    let message = '';
    if (values) {
      setIsLoading(true);
      values.app_id = parseInt(values.app_id);
      try {
        const response = await webhooksApi.updateEndpoint(values.id, values);
        if (!alerts.length && values.alerts_email.length) {
          subscribeAlert(values.id, values.alerts_email);
          message += t('webhooks.endpoints.form.messages.alerts.create');
        } else if (alerts.length && !values.alerts_email.length) {
          unsubscribeAlert(alerts[0].alert_uuid);
          message += t('webhooks.endpoints.form.messages.alerts.delete');
        } else if (alerts.length && values.alerts_email.length) {
          updateAlert(alerts[0].alert_uuid, values.alerts_email);
          message += t('webhooks.endpoints.form.messages.alerts.update');
        }
        setAlert({ message: message.length ? `${response.message.slice(0, -1)} ${message}` : response.message , variant: 'success' });
        getEndpoint();
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentEndpoint({ ...currentEndpoint, save: false });
        setActiveTriggers(response.endpoint.triggers);
        setIsLoading(false);
        setRedirect(true);
      }
    } else {
      formRef.current.setSubmitting(false);
      setCurrentEndpoint({ ...currentEndpoint, save: false });
    }
  };

  const deleteEndpoint = async (values) => {
    if (values) {
      values.app_id = parseInt(values.app_id);
      try {
        const response = await webhooksApi.deleteEndpoint(values);
        setAlert({ message: response.message, variant: 'success' });
        setRedirect(true);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    } else {
      setCurrentEndpoint({ ...currentEndpoint, delete: false });
    }
  };

  const handleTriggerSearch = (e) => setTrigger(e.target.value);

  const handleAppChange = (e) => setApp(e.target.value);

  const handleTriggerChange = (e) => {
    setActiveTriggers(!activeTriggers.includes(e.target.name) && e.target.checked ? [...activeTriggers, e.target.name] : activeTriggers.filter(t => t !== e.target.name));
  };

  useEffect(() => {
    formRef.current && formRef.current.setFieldValue('triggers', activeTriggers);
  }, [activeTriggers]);

  useEffect(() => {
    if (redirect) history.push({ pathname: '/dev/webhooks', state: { from: location.pathname } });
  }, [redirect]);

  return hasPermissions(user, 'webhooks', 'Write') ?
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => initialValues.id ? setCurrentEndpoint({ ...currentEndpoint, save: true, data: { ...values, app_id: app } }) : createEndpoint(values, setSubmitting)}>
      {({ errors, handleSubmit, isSubmitting, values, dirty, setFieldValue }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>

          <Row className="mb-0 mb-md-4">
            <Col md="4">
              <Form.Label className="text-hdr mb-3" htmlFor="endpointForm.name">{t('webhooks.endpoints.form.fields.name.label')}</Form.Label>
              <Field required className="name mb-4 mb-md-0"
                id="endpointForm.name"
                name="name"
                handleChange={(e) => setName(e.target.value)}
                component={FieldWrapper} />
            </Col>
            <Col md="4">
              <Form.Label className="text-hdr mb-3" htmlFor="endpointForm.url">{t('webhooks.endpoints.form.fields.url.label')}</Form.Label>
              <Field required className="url mb-4 mb-md-0"
                id="endpointForm.url"
                placeholder={t('common:form.fields.url.placeholder')}
                name="url"
                component={FieldWrapper} />
            </Col>
            <Col md="4">
              <Form.Label className="text-hdr mb-3" htmlFor="endpointForm.app_id">{t(`webhooks.endpoints.form.fields.app.${!initialValues.id ? 'label' : 'name.label'}`)}</Form.Label>
              <Field required readOnly={initialValues.id} className="app_id mb-4 mb-md-0"
                id="endpointForm.app_id"
                name="app_id"
                type="select"
                note={t(`webhooks.endpoints.form.fields.app.${initialValues.id ? 'name.description' : 'description'}`)}
                labelClass="mb-4"
                handleChange={handleAppChange}
                options={apps.map(app => ({ label: app.name, value: app.id }))}
                component={FieldWrapper} />
            </Col>
          </Row>

          <h4 className="text-hdr mb-2">{t('webhooks.endpoints.form.fields.retries.label')}</h4>

          <Row className="mb-0 mb-md-4">
            <Col md="4">
              <Field required className="max_attempts mb-4 mb-md-0"
                id="endpointForm.max_attempts"
                name="max_attempts"
                label={t('webhooks.endpoints.form.fields.max_attempts.label')}
                placeholder={t('webhooks.endpoints.form.fields.max_attempts.placeholder')}
                note={t('webhooks.endpoints.form.fields.max_attempts.description')}
                type="number"
                labelClass="mb-3"
                component={FieldWrapper} />
            </Col>
            <Col md="4">
              <Field required className="retry_interval mb-4 mb-md-0"
                id="endpointForm.retry_interval"
                name="retry_interval"
                label={t('webhooks.endpoints.form.fields.retry_interval.label')}
                placeholder={t('webhooks.endpoints.form.fields.retry_interval.placeholder')}
                note={t('webhooks.endpoints.form.fields.retry_interval.description')}
                type="number"
                labelClass="mb-3"
                append={values.retry_interval ? <em className="text-info">{t('common:labels.seconds')}</em> : undefined}
                component={FieldWrapper} />
            </Col>
            <Col md="4">
              <Field required className="alerts_email mb-4 mb-md-0"
                id="endpointForm.alerts_email"
                name="alerts_email"
                label={t('webhooks.endpoints.form.fields.alerts.label')}
                placeholder={t('common:form.fields.email.label')}
                note={t('webhooks.endpoints.form.fields.alerts.description')}
                handleChange={(e) => setFieldValue(e.target.name, e.target.value.toLowerCase().trim())}
                append={values.alerts_email && alerts && alerts[0] && alerts[0].confirmed_at && alerts[0].email === values.alerts_email ? <TooltipButton placement="top" variant="link" text={t('webhooks.endpoints.form.messages.emailConfirmed')} className="p-0 cursor-pointer"><i className="fas fa-check text-lg text-success mr-2"></i></TooltipButton> : values.alerts_email && alerts && alerts[0] && !alerts[0].confirmed_at && alerts[0].email === values.alerts_email ? <em className="text-info">{t('webhooks.endpoints.form.messages.emailConfirmPending')}</em> : values.alerts_email ? <em className="text-info">{t('webhooks.endpoints.form.messages.emailConfirmPending')}</em> : undefined}
                labelClass="mb-3"
                component={FieldWrapper} />
            </Col>
          </Row>

          <h4 className="text-hdr mb-2">{t('webhooks.endpoints.form.fields.events.label')}</h4>
          <p className="text-info mb-4"><Trans i18nKey="webhooks.endpoints.form.fields.events.description">Visit <a href="https://docs.silamoney.com/docs/webhooks" target="_blank">our docs</a> to learn about each of these events and what they do.</Trans></p>

          <CardGroup className="mb-4">
            <Card className="border">
              <Card.Header className="bg-info py-3 px-4">
                <h4 className="text-sm text-info m-0">{t('webhooks.endpoints.form.fields.events.available')}</h4>
              </Card.Header>
              <Card.Body className="triggers overflow-auto custom-scrollbar position-relative p-0">
                <div className="trigger-search w-100 bg-white">
                  <Field className="p-4 m-0"
                    name="trigger-search"
                    fieldClass="p-2"
                    placeholder={t('search.placeholder')}
                    handleChange={handleTriggerSearch}
                    append={<i className="fas fa-search text-primary"></i>}
                    component={FieldWrapper} />
                </div>
                <div className="trigger-results trigger-switches pt-0 px-4 pb-4">
                  {triggers.length === 0 ? <Loader className="mt-4" /> : filteredTrigger.length !== 0 ? filteredTrigger.map((t, index) =>
                    <Form.Check custom key={index} className="mb-2 loaded">
                      <Form.Check.Input id={t.name} name={t.name} onChange={handleTriggerChange} type="checkbox" checked={activeTriggers.some(at => t.name === at)} />
                      <Form.Check.Label className="text-reset" htmlFor={t.name}>{`/${t.name}`}</Form.Check.Label>
                    </Form.Check>
                  ) : <p className="text-center text-info font-italic my-4">{t('webhooks.endpoints.empty.triggers')}</p>}
                </div>
              </Card.Body>
            </Card>
            <Card className="border">
              <Card.Header className="bg-info py-3 px-4">
                <h4 className="text-sm text-info m-0">{t('webhooks.endpoints.form.fields.events.selected')}</h4>
              </Card.Header>
              <Card.Body className="triggers p-4 overflow-auto">
                {activeTriggers.length === 0 && <p className="text-info mb-0">{t('webhooks.endpoints.form.fields.events.error')}</p>}
                {triggers.filter(trigger => activeTriggers.some(active => trigger.name === active)).map((trigger, index) =>
                  <Trigger key={index} data={trigger} isLast={index !== triggers.filter(trigger => activeTriggers.some(active => trigger.name === active)).length - 1} />
                )}
              </Card.Body>
            </Card>
          </CardGroup>

          <footer className="d-flex">
            <LoaderButton variant="primary" type="submit" size="sm" className="mr-3 px-4" loading={isSubmitting} disabled={!dirty || Object.keys(errors).length}>{t(initialValues.id ? 'common:buttons.save' : 'webhooks.endpoints.buttons.create')}</LoaderButton>

            {initialValues.id && <Button variant="outline-primary" size="sm" className="loaded mr-3 px-4" disabled={activeTriggers.length === 0 || activeTriggers.some((trigger, index) => trigger !== initialValues.triggers[index])} onClick={() => setCurrentEndpoint({ ...currentEndpoint, test: true })}>{t('webhooks.endpoints.form.buttons.test')}</Button>}

            <Button variant="outline-light" disabled={isSubmitting} size="sm" className="px-4" as={NavLink} exact={true} to="/dev/webhooks">{t('common:buttons.cancel')}</Button>

            {hasPermissions(user, 'webhooks', 'Delete') && initialValues.id && <Button variant={currentEndpoint.delete ? 'warning' : 'outline-warning'} onClick={() => setCurrentEndpoint({ ...currentEndpoint, delete: true })} size="sm" className="delete action px-4 ml-auto">{t(currentEndpoint.delete ? 'common:status.deleting' : 'common:buttons.delete')}</Button>}
          </footer>

          {currentEndpoint.delete &&
            <ConfirmModal
              show={currentEndpoint.delete}
              data={currentEndpoint.data}
              title={t('common:buttons.confirm')}
              message={t('common:form.messages.confirm.delete', { name: currentEndpoint.name })}
              buttonLabel={t('common:buttons.delete')}
              buttonVariant="warning"
              onHide={deleteEndpoint} />}
          {currentEndpoint.save &&
            <ConfirmModal
              show={currentEndpoint.save}
              title={t('common:buttons.saveChanges')}
              message={t('common:form.messages.confirm.save', { name: currentEndpoint.name })}
              buttonLabel={t('common:buttons.save')}
              onHide={updateEndpoint}
              data={currentEndpoint.data} />}
          {currentEndpoint.test &&
            <EndpointTestModal
              show={currentEndpoint.test}
              data={currentEndpoint.data}
              onHide={() => setCurrentEndpoint({ ...currentEndpoint, test: false })}
              />}

        </Form>
      )}
    </Formik> : <>
      <Row as="ul" className="list-unstyled m-0 mt-3">
        <Col as="li" md="4" className="border-left pl-4 mb-5">
          <h4 className="text-info text-sm">{t('webhooks.endpoints.form.fields.name.label')}</h4>
          <span className="text-lg">{initialValues.name}</span>
        </Col>
        <Col as="li" md="4" className="border-left pl-4 mb-5">
          <h4 className="text-info text-sm">{t('webhooks.endpoints.form.fields.url.label')}</h4>
          <span className="text-lg">{initialValues.url}</span>
        </Col>
        <Col as="li" md="4" className="border-left pl-4 mb-5">
          <h4 className="text-info text-sm">{t('webhooks.endpoints.form.fields.app.name.label')}</h4>
          <span className="text-lg">{initialValues.app_nickname}</span>
        </Col>
        <Col as="li" md="4" className="border-left pl-4 mb-5">
          <h4 className="text-info text-sm">{t('webhooks.endpoints.form.fields.max_attempts.label')}</h4>
          <span className="text-lg">{initialValues.max_attempts}</span>
        </Col>
        <Col as="li" md="4" className="border-left pl-4 mb-5">
          <h4 className="text-info text-sm">{t('webhooks.endpoints.form.fields.retry_interval.label')}</h4>
          <span className="text-lg">{initialValues.retry_interval} {t('common:labels.seconds')}</span>
        </Col>
        <Col as="li" md="4" className="border-left pl-4 mb-5">
          <h4 className="text-info text-sm">{t('webhooks.endpoints.labels.events')}</h4>
          {initialValues.triggers.length ? initialValues.triggers.map((trigger, index) => <span className="text-lg d-block" key={index}>{`/${trigger}${index !== initialValues.triggers.length - 1 ? ', ' : ''}`}</span>) : <span className="text-center text-info font-italic">{t('webhooks.endpoints.form.fields.events.empty')}</span>}
        </Col>
      </Row>

      {initialValues.triggers.length !== 0 && <>
        <h4 className="text-lg mb-4 text-info">{t('webhooks.endpoints.form.fields.events.selected')}</h4>

        <Card className="border mt-4 mb-2">
          <Card.Body className="p-4">
            {triggers.filter(trigger => initialValues.triggers.some(active => trigger.name === active)).map((trigger, index) =>
              <Trigger key={index} data={trigger} isLast={index !== triggers.filter(trigger => initialValues.triggers.some(active => trigger.name === active)).length - 1} />
            )}
          </Card.Body>
        </Card>
      </>}

    </>
};

EndpointForm.propTypes = {
  /**
   * The form ref
   */
  formRef: PropTypes.any.isRequired,
  /**
   * The apps array
   */
  apps: PropTypes.array.isRequired,
  /**
   * The set loading function
   */
  setIsLoading: PropTypes.func.isRequired,
  /**
   * The initialValues object
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The name set function
   */
  setName: PropTypes.func.isRequired,
  /**
   * The save function when the form is submitted
   */
  getEndpoint: PropTypes.func.isRequired
};

export default EndpointForm;