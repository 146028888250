import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const EasterEggModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  return (
    <Modal id="🥚-modal" centered
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="🥚-title">
      <Modal.Header closeButton>
        <Modal.Title as="h3" id="🥚-modal-title">{t('easterEgg.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center text-lg text-info">{t('common:status.comingSoon')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('common:buttons.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
};

EasterEggModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default EasterEggModal;