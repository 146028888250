import { useEffect, useState, useRef, useCallback } from 'react';

const useInfiniteScroll = (load, options, shouldExecute) => {
  const [isLoading, setIsLoading] = useState(shouldExecute);
  const [isFetching, setIsFetching] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(false);

  const useVisibility = (cb,  deps) => {
    const intersectionObserver = useRef(null);
    return useCallback(node => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting && hasMore);
      });
      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }

  const loadAsync = async (options, callback) => {
    setIsFetching(true);
    try {
      const response = await load({...options, page: options.page || page });
      if (response.pagination && response.pagination.current_page) {
        setHasMore(response.pagination.current_page < response.pagination.total_pages);
        if (response.pagination.current_page < response.pagination.total_pages) setPage(response.pagination.current_page + 1);
      }
      setData(prevState => refresh ? response[options.slug] : [...prevState, ...response[options.slug]]);
    } catch (error) {
      setData([]);
      setError(error);
    } finally {
      setIsFetching(false);
      setIsLoading(false);
      setRefresh(false);
      if (callback) callback();
    }
  };

  const lastItem = useVisibility(visible => {
    if (visible) loadAsync(options);
  }, [page, data]);
  
  useEffect(() => {
    if (refresh) {
      setPage(1);
      setIsLoading(true);
      setRefresh(false);
      loadAsync({  ...options, page: 1 });
    }
  }, [refresh]);

  useEffect(() => {
    let didCancel = false;
    if (!isFetching) return;
    if (!didCancel && shouldExecute) {
      setIsLoading(true);
      loadAsync(options, () => {
        setLoaded(true);
      });
    }
    return () => {
      didCancel = true;
    }
  }, [shouldExecute]);

  return { data, setData, refresh, setRefresh, lastItem, hasMore, isLoading, isFetching, setIsFetching, setIsLoading, loaded, setLoaded, error };
}

export default useInfiniteScroll;