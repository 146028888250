import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FieldArray, Field } from 'formik';
import { v4 as uuid } from 'uuid';

import DropdownPersist from '../../common/DropdownPersist';
import FieldWrapper from './Field';

const getValues = (fieldName, obj) => fieldName.split('.').length && fieldName.split('.').some(match => obj[match]) ? fieldName.split('.').reduce((o,i)=> o[i], obj) : [];

const AddButton = ({ disabled, push, initialValues, value, setValue, field, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button variant="link" className="p-0" disabled={disabled} onClick={() => {
      const newValue = { tempId: uuid(), [field.slug]: '' };
      push(newValue);
      setValue(newValue.tempId);
    }} {...rest}><i className="fas fa-plus-circle mr-2"></i>{t('common:buttons.add')}</Button>
  );
};

const CloseButton = ({ field, remove, setValue, index, errors, setErrors, ...rest }) => {
  return (
    <Button variant="link" onClick={() => {
      delete errors[field.name];
      remove(index);
      setValue(false);
      setErrors(errors);
    }} {...rest}><i className="fas fa-times-circle"></i></Button>
  );
};

const FieldArrayWrapper = ({ disableAdd, form, field, fields, values, errors, formatValue, label, pluralLabel, placeholder }) => {
  const [value, setValue] = useState(false);
  const { t } = useTranslation();

  const fieldValues = getValues(field.name, values);
  const fieldErrors = getValues(field.name, errors);
  const defaultValues = getValues(field.name, form.initialValues);

  return fieldValues && fieldValues.filter(data => !data.action).length === 0 ?
    <FieldArray
      name={field.name}>{({ push }) => fieldValues.map((data, index) =>
        fields ? <Fragment key={index}>{fields(index)}</Fragment> :
          <Field key={index} autofocus
            className={field.slug}
            id={`${form.id}.${field.slug}.${index}`}
            label={label || t(`common:form.fields.${field.slug}.label`)}
            name={`${field.name}.${index}.${field.slug}`}
            placeholder={placeholder}
            handleChange={field.handleChange || undefined}
            component={FieldWrapper} />)}
    </FieldArray>
    :
    <DropdownPersist show={value && value.length > 0}>
      <Form.Group className={`autofocus${fieldErrors && fieldErrors.length ? ' err' : ''}`}>
        <Form.Label className="shrink">{pluralLabel|| t(`common:form.fields.${field.slug}.pluralLabel`)}</Form.Label>
        <Dropdown.Toggle className="w-100 text-left" childBsPrefix="form-control">
          <span className="text-nowrap overflow-hidden" style={{ textOverflow: 'ellipsis' }}>{fieldValues.map(data => formatValue ? formatValue(data) : data[field.slug]).join(' | ')}</span>
        </Dropdown.Toggle>
      </Form.Group>
      <Dropdown.Menu className="w-100">
        <FieldArray
          name={field.name}>
          {({ remove, push }) => <>
            {fieldValues && fieldValues.length > 0 && fieldValues.map((data, index) => {
              const id = data.uuid || data.id || data.tempId;
              const selected = id === value;
              return (
                <div key={index} className="px-3 py-2 d-flex align-items-center w-100">
                  <Button
                    style={selected ? { opacity: 1 } : undefined}
                    className="p-0 mr-3 mb-auto"
                    variant="link"
                    disabled={value}
                    onClick={() => {
                      setValue(id);
                      if (fieldErrors && fieldErrors[index]) form.setFieldTouched(`${field.name}.${index}.${field.slug}`, true);
                    }}
                  ><i className={`fas fa-pen text-${selected ? 'primary' : fieldErrors && fieldErrors[index] ? 'warning' : 'info'}`}></i></Button>
                  {selected ?
                    (fields ?
                      <div className="w-100">
                        <div className="w-100 d-flex">
                          <div className="w-100">{fields(index)}</div>
                          {!defaultValues[index] && <CloseButton className="mb-auto p-0 ml-3" remove={remove} setValue={setValue} index={index} field={field} errors={errors} setErrors={form.setErrors} />}
                        </div>
                        {selected && <Button disabled={field.slug === 'address' && !fieldValues[index]['street_address_1'] || fieldErrors && fieldErrors[index]} variant="link" className="p-0" onClick={(e) => { setValue(false); e.preventDefault(); }}><i className="fas fa-save mr-2"></i>{t('common:buttons.save')}</Button>}
                      </div> :
                      <div className="w-100">
                        <Field required autofocus className={`${field.slug} m-0 w-100`}
                          id={`${form.id}.${field.slug}.${index}`}
                          placeholder={placeholder}
                          label={label || t(`common:form.fields.${field.slug}.label`)}
                          name={`${field.name}.${index}.${field.slug}`}
                          handleChange={field.handleChange || undefined}
                          hasError={true}
                          append={!defaultValues[index] ? <CloseButton className="p-0" remove={remove} setValue={setValue} errors={errors} setErrors={form.setErrors} index={index} field={field} /> : undefined}
                          component={FieldWrapper} />
                        {selected && <Button disabled={field.slug && !fieldValues[index][field.slug] || fieldErrors && fieldErrors[index]} variant="link" className="p-0 mt-2 mb-1" onClick={(e) => { setValue(false); e.preventDefault(); }}><i className="fas fa-save mr-2"></i>{t('common:buttons.save')}</Button>}
                      </div>)
                    : <Button
                      variant="link"
                      className="p-0 text-reset text-nowrap overflow-hidden w-100 text-left" style={{ opacity: 1, textOverflow: 'ellipsis' }}
                      disabled={value}
                      onClick={() => setValue(id)}
                    >{formatValue ? formatValue(data) : data[field.slug]}</Button>}
                </div>);
            })}
            {!value && !disableAdd && <div className="px-3 py-2">
              <AddButton
                field={field}
                disabled={errors[field.name]}
                push={push}
                initialValues={form.initialValues}
                value={value}
                setValue={setValue} />
            </div>}
          </>}
        </FieldArray>
      </Dropdown.Menu>
    </DropdownPersist>;
};

FieldArrayWrapper.propTypes = {
  /**
   * The form object
   */
  form: PropTypes.object.isRequired,
  /**
   * The field object
   */
  field: PropTypes.object.isRequired,
  /**
   * The fields component
   */
  fields: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]),
  /**
   * The values object
   */
  values: PropTypes.object.isRequired,
  /**
   * The errors object
   */
  errors: PropTypes.object.isRequired,
  /**
 * The format value function
 */
  formatValue: PropTypes.func
};

export default FieldArrayWrapper;