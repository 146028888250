import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import { useAuth } from '../context/AuthDataProvider';
import { generateWallet } from '../../utils';

import CopyButton from '../common/CopyButton';
import TooltipButton from '../common/TooltipButton';
import FieldWrapper from '../form/wrappers/Field';

const AddressFieldWrapper = ({ wallet, autoFocus, label, readOnly, plaintext, onAddressChange, onGenerate, showDesc, tabIndex }) => {
  const addressRef = useRef(null);
  const { t } = useTranslation();
  const { user } = useAuth();

  const generate = () => {
    generateWallet(newWallet => {
      if (onGenerate) onGenerate(newWallet);
    });
  };

  useEffect(() => {
    if (autoFocus && addressRef.current) setTimeout(() => { addressRef.current.focus(); }, 100);
  }, [addressRef]);

  return (
    <Field required className={classNames('blockchain_address', plaintext && 'mb-0')}
      tabIndex={!plaintext && tabIndex ? tabIndex : undefined}
      plaintext={plaintext}
      readOnly={readOnly}
      id={`keyGenForm.${uuid()}.blockchain_address`}
      label={!plaintext ? label || t('applications.detail.form.fields.address.label') : undefined}
      placeholder={t('applications.detail.form.fields.address.placeholder')}
      note={(!readOnly && showDesc) && t('applications.detail.form.fields.address.description')}
      handleChange={onAddressChange}
      appendClass={plaintext ? 'border-0 rounded-0 p-0' : undefined}
      append={<>
        {wallet.privateKey && !readOnly && <strong className="text-success mr-2">{t('applications.labels.generated')}</strong>}
        {wallet.blockchain_address && document.queryCommandSupported('copy') && <CopyButton inputRef={addressRef} variant="link" placement="top" className="p-1" />}
        {!readOnly && <Button className="generate ml-2 rounded-sm" variant="primary" size="sm" onClick={() => generate()}>{t('common:buttons.generate')}</Button>}
      </>}
      name="blockchain_address"
      inputRef={addressRef}
      component={FieldWrapper} />
  );
};

AddressFieldWrapper.propTypes = {
  /**
   * The wallet values
   */
  wallet: PropTypes.object,
  /**
   * The autofocus boolean
   */
  autoFocus: PropTypes.bool,
  /**
   * The optional label
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  /**
   * The read only boolean
   */
  readOnly: PropTypes.bool,
  /**
   * The plain text boolean
   */
  plaintext: PropTypes.bool,
  /**
   * The function that is called when the address field value has changed.
   */
  onAddressChange: PropTypes.func,
  /**
   * The function that is called after the address is autogenerated
   */
  onGenerate: PropTypes.func,
  /**
   * The show description boolean
   */
  showDesc: PropTypes.bool,
  /**
   * The tab index number
   */
  tabIndex: PropTypes.number
};

const PrivateKeyFieldWrapper = ({ label, wallet, show }) => {
  const [reveal, setReveal] = useState(show);
  const privateKeyRef = useRef(null);
  const { t } = useTranslation();
  return (
    <Form.Group className="privateKey loaded readonly" controlId={`keyGenForm.${uuid()}.privateKey`}>
      <Form.Label>{label || t('applications.detail.form.fields.key.label')}</Form.Label>
      <InputGroup>
        <Form.Control autoComplete="new-password" type={reveal ? 'text' : 'password'} name="privateKey" value={wallet.privateKey} readOnly />
        <input className="sr-only" type="text" name="privateKey-hidden" value={wallet.privateKey} ref={privateKeyRef} readOnly />
        <InputGroup.Append className="d-flex align-items-center">
          <TooltipButton placement="top" variant="link" text={reveal ? t('common:buttons.hide') : t('common:buttons.reveal')} popoverProps={{ style: { textAlign: 'center' } }} onClick={() => setReveal(!reveal)} className="p-1"><i className={`text-lg fas fa-${reveal ? 'eye-slash' : 'eye'}`}></i></TooltipButton>
          {document.queryCommandSupported('copy') && <CopyButton inputRef={privateKeyRef} variant="link" placement="top" className="p-1 ml-2" />}
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
};

PrivateKeyFieldWrapper.propTypes = {
  /**
   * The show boolean to reveal the private key
   */
  show: PropTypes.bool,
  /**
   * The wallet values
   */
  wallet: PropTypes.object,
  /**
   * The optional label
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const KeyGenForm = ({ wallet, onAddressChange, onGenerate, readOnly, show }) => {
  const [reveal, setReveal] = useState(show);

  const handleGenerate = (newWallet) => {
    if (onGenerate) onGenerate(newWallet);
    if (!reveal) setReveal(true);
  };

  return (
    <>
      <AddressFieldWrapper autoFocus showDesc readOnly={readOnly} wallet={wallet} onGenerate={handleGenerate} onAddressChange={onAddressChange} />
      {wallet.privateKey && <PrivateKeyFieldWrapper show={reveal} wallet={wallet} />}
    </>
  );
};

KeyGenForm.propTypes = {
  /**
   * Wallet values
   */
  wallet: PropTypes.object,
  /**
   * The function that is called when the address field value has changed.
   */
  onAddressChange: PropTypes.func,
  /**
   * The function that is called when the address is generated.
   */
  onGenerate: PropTypes.func,
  /**
   * The read only boolean
   */
  readOnly: PropTypes.bool,
  /**
   * The show boolean to reveal the private key
   */
  show: PropTypes.bool
};

export default KeyGenForm;

export const AddressField = AddressFieldWrapper;
export const PrivateKeyField = PrivateKeyFieldWrapper;