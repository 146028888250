import React from 'react';

import { generateColumns, LimitsTable } from './LimitsTable';
import { adminApi } from '../../api';

function AdminLimits({ t, history, user, env }) {
  const columns = generateColumns(t, env);
  const pageTitle = t('admin.limits.titlePlural');
  const makeNew = () => history.push('/admin/limit/new');
  const onRowClick = (row) => history.push(`/admin/limit/${row.original.uuid}/detail`);

  return (
    <LimitsTable
      t={t}
      history={history}
      user={user}
      makeNew={makeNew}
      onRowClick={onRowClick}
      pageTitle={pageTitle}
      columns={columns}
      fetchData={adminApi.fetchLimits}
    />
  );
}

export default AdminLimits;
