import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import TextFieldWrapper from './TextField';

const ElasticFieldWrapper = (props) => {
  const { children, handleChange, inputRef, ...attrs } = props;
  const [content, setContent] = useState('');
  const textareaRef = useRef(null);

  const onHandleChange = (e) => {
    if (handleChange) handleChange(e);
    setContent(e.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.minHeight = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.minHeight = scrollHeight + 'px';
    }
  }, [textareaRef.current, content]);

  return (
    <TextFieldWrapper as="textarea" handleChange={onHandleChange} inputRef={textareaRef} {...attrs}>
      {children}
    </TextFieldWrapper>
  );
};

ElasticFieldWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ElasticFieldWrapper;