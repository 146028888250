import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import AuthDataProvider from './components/context/AuthDataProvider';
import AppDataProvider from './components/context/AppDataProvider';
import AlertDataProvider from './components/context/AlertDataProvider';
import Loader from './components/common/Loader';

import App from './App';

import './i18n';
import '../../../styles/scss/dashboard.scss';

const context = {};

ReactDOM.render(
  <Router context={context}>
    <AuthDataProvider>
      <AppDataProvider>
        <AlertDataProvider>
          <Suspense fallback={<Loader />}>
            <App />
          </Suspense>
        </AlertDataProvider>
      </AppDataProvider>
    </AuthDataProvider>
  </Router>,
  document.getElementById('sila-app')
);
