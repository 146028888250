import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useAsyncDebounce } from 'react-table';
import CurrencyInput from 'react-currency-input-field';
import stickybits from 'stickybits';

import { convertDateToUTC, converUTCDatetoLocal, convertToUSD, convertToSila } from '../../../utils';
import { EMAIL_REG_EXP } from '../../../constants';

export const Filter = ({ column, show, isGlobal, handleChange }) => {
  column.isVisible = show;
  column.isGlobal = isGlobal;
  column.handleChange = handleChange;
  return column.canFilter && column.render('Filter');
};

export const MultipleFilter = (rows, filter, filterValue = []) => {
  const arr = [];
  rows.forEach((val) => {
    if (val.values[filter[0]] && filterValue.length && filterValue.some(value => filter[0].split('.').reduce((o, i) => o[i], val.values).includes(value))) arr.push(val);
  });
  return arr;
};

export const DateBetweenFilter = (rows, id, filterValues) => {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : sd;
  if (ed || sd) {
    return rows.filter(r => {
      const cellDate = new Date(r.values[id]);
      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
};

export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, placeholder, ...rest }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter)
  const { t } = useTranslation();
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Form.Control {...rest}
      size="lg"
      placeholder={placeholder || t('common:labels.search')}
      aria-label={t('common:labels.search')}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }} />
  )
};

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows: { length } },
}) => {
  const { t } = useTranslation();
  return (
    <Form.Control
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`${t('common:labels.search')} (${length})`}
    />
  );
};

export const CheckboxColumnFilter = ({
  preFilteredRows,
  filteredRows,
  column: { filterValue = [], setFilter, id, type, isVisible, isGlobal, handleChange, Header, filterOptions, searchOptions = {} }
}) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const options = useMemo(() => {
    return filterOptions && filterOptions(filteredRows).length ? filterOptions(filteredRows).filter((s => o => (k => !s.has(k) && s.add(k))(['value', 'label'].map(k => o[k]).join('|')))(new Set)) : splitMultipleOptions(preFilteredRows, id);
  }, [id, preFilteredRows, filterOptions]);

  const [term, setTerm] = useState(filterValue.some(filter => filter.term) ? filterValue.find(filter => filter.term).term : '');
  const [filters, setFilters] = useState(filterValue.filter(filter => !filter.term && filter !== term));
  const [error, setError] = useState(false);

  const filteredOptions = [... new Set(options.filter(option => (option.value && option.value.toLowerCase().includes(term.toLowerCase()) || option.toString().includes(term))))];

  useEffect(() => {
    if (!isGlobal && isVisible) formRef.current.reset();
    if (!filterValue.length) setTerm('');
  }, [isVisible]);

  useEffect(() => {
    stickybits('.options .term-message', { useStickyClasses: true });
  }, []);

  const renderForm = () => <>
    {!searchOptions.disabled && <header className="p-3 border-bottom">
      <Form.Control className="w-100" size={isGlobal ? 'sm' : undefined} defaultValue={!searchOptions.onChange ? term : undefined} value={searchOptions.onChange ? term : undefined} placeholder={t('common:labels.search')} aria-label={t('common:labels.search')} onChange={(e) => {
        let value = e.target.value;
        let isError = false;
        setError(undefined);
        if (value && type && type === 'email' && !EMAIL_REG_EXP.test(value)) {
          isError = true;
          setError(t('common:form.fields.email.error'));
        } else {
          isError = false;
        }
        if (searchOptions.onChange) {
          value = searchOptions.onChange(value);
        } else {
          value = value.trim().toLowerCase();
        }
        setTerm(value);
        if (isGlobal && handleChange) handleChange(!isError && value.length ? [...filters, { term: value }] : filters.length && !value.length ? filters : undefined);
      }} />
      {error && <Form.Label className="text-sm text-danger">{error}</Form.Label>}
    </header>}
    {options.length !== 0 && <div className="options overflow-auto custom-scrollbar" style={{ maxHeight: 200 }}>
      {!searchOptions.disabled && term.length !== 0 && <p className="term-message p-3 mb-0 text-center text-light text-sm bg-primary-light">
        <Trans i18nKey={`common:filters.labels.${filterValue.some(filter => filter.term) ? 'searching' : 'suggestion'}`}>Search <span className="mark">"{{ term }}"</span> by {{ Header }}?</Trans>
      </p>}
      <ul className={`list-unstyled mb-0 results ${isGlobal ? 'p-2' : 'p-0'}`}>
        {filteredOptions.length ? filteredOptions.map((option, index) => {
          const label = option.label || option;
          const value = option.value || option;
          return (
            <li key={index} className={`px-3 py-2 text-sm${index !== 0 ? ' border-top' : ''}`}>
              <Form.Check>
                <Form.Check.Label className="w-100">
                  <Form.Check.Input
                    checked={filters.filter(filter => filter.length && !filter.term).some(filter => filter === (value))}
                    type="checkbox"
                    name={value}
                    value={value}
                    onChange={(e) => {
                      const value = setFilteredParams(filters, e.target.value);
                      setFilters(value);
                      if (isGlobal && handleChange) handleChange(value);
                    }}
                  />
                  <span className="d-block w-100 text-break">{label}</span>
                </Form.Check.Label>
              </Form.Check>
            </li>
          );
        }) : <li className="text-sm text-center text-info font-italic py-3">{t('common:form.messages.empty')}</li>}
      </ul>
    </div>}
  </>;

  return <div className="filter m-n3">{isGlobal ? renderForm() :
    <Form ref={formRef} onSubmit={(e) => { e.preventDefault(); setFilter(term.length ? [...filters, { term: term }] : filters.length && !term.length ? filters : undefined); }}>
      {renderForm()}
      <footer className="p-3 border-top">
        <Button block type="submit" size="sm">{t('common:buttons.apply')}</Button>
      </footer>
    </Form>}
  </div>
};

export const SelectColumnFilter = ({
  preFilteredRows,
  filteredRows,
  column: { filterValue, setFilter, id, isVisible, isGlobal, handleChange, filterOptions }
}) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const options = useMemo(() => {
    return filterOptions && filterOptions(filteredRows).length ? filterOptions(filteredRows) : splitMultipleOptions(preFilteredRows, id);
  }, [id, preFilteredRows, filterOptions]);

  useEffect(() => {
    if (!isGlobal && isVisible) formRef.current.reset();
  }, [isVisible]);

  const renderForm = () => <>
    <Form.Control
      id={id}
      as="select"
      size="sm"
      defaultValue={filterValue instanceof Array ? filterValue[0] : filterValue}
      onChange={(e) => {
        const value = e.target.value || undefined;
        isGlobal && handleChange ? handleChange(value) : setFilter(value);
      }}
    >
      <option value=''>{t('common:labels.all')}</option>
      {options.map((option, index) => {
        const value = option.value || option;
        const label = option.label || option;
        return (
          <option
            key={index}
            value={value}>
            {label}
          </option>
        );
      })}
    </Form.Control>
  </>;

  return isGlobal ? renderForm() : <Form ref={formRef} className="filter">{renderForm()}</Form>
};

export const DateRangeColumnFilter = ({
  filteredRows,
  column: { filterValue = [], setFilter, id, isGlobal, handleChange }
}) => {
  const [value, setValue] = useState(filterValue);
  const [show, setShow] = useState(value.length !== 0);
  const { t } = useTranslation();

  useEffect(() => {
    if (isGlobal && handleChange && value && value.length === 2 && !value.includes(undefined)) handleChange(value);
  }, [value]);

  useEffect(() => {
    if (!filterValue.length && show && value.length) {
      setShow(false);
      setValue(filterValue);
    }
  }, [filteredRows]);

  const renderForm = () => <div className={isGlobal ? 'p-3' : 'p-0'}>
    <section className="filter-all px-3 py-2 text-sm border-top">
      <Form.Check
        className="text-nowrap"
        label={t('common:labels.all')}
        type="radio"
        name={`${id}.filter`}
        id={`${id}.all`}
        defaultChecked={!show || !value}
        onChange={() => {
          const all = '';
          setShow(false);
          isGlobal && handleChange && handleChange ? handleChange(all) : setFilter(all);
        }}
      />
    </section>
    <section className="filter-range px-3 py-2 text-sm border-top">
      <Form.Check
        className="text-nowrap"
        label={t('common:filters.labels.date')}
        type="radio"
        name={`${id}.filter`}
        id={`${id}.range`}
        defaultChecked={show}
        onChange={() => setShow(true)}
      />
    </section>
    {show && <section className="filter-picker loaded p-3">
      <Form.Label className="text-sm" htmlFor={`${id}.from`}>{t('common:labels.from')}:</Form.Label>
      <Form.Control
        size="sm"
        className="w-100 rounded-sm"
        id={`${id}.from`}
        onChange={e => {
          const date = new Date(e.target.value);
          date.setUTCHours(0, 0, 0, 0);
          const val = convertDateToUTC(date);
          setValue((old = []) => [val ? val.slice(0, -5) : undefined, old[1]]);
        }}
        type="date"
        defaultValue={filterValue[0] ? converUTCDatetoLocal(filterValue[0]) : undefined}
      />
      <br />
      <Form.Label className="text-sm" htmlFor={`${id}.to`}>{t('common:labels.to')}:</Form.Label>
      <Form.Control
        size="sm"
        className="w-100 rounded-sm"
        id={`${id}.to`}
        onChange={e => {
          const date = new Date(e.target.value);
          date.setUTCHours(23, 59, 59, 999);
          const val = convertDateToUTC(date);
          setValue((old = []) => [old[0], val ? val.slice(0, -5) : undefined]);
        }}
        type="date"
        defaultValue={filterValue[1] ? converUTCDatetoLocal(filterValue[1]) : undefined}
      />
    </section>}
  </div>;

  return <div className="filter m-n3">{isGlobal ? renderForm() :
    <Form onSubmit={(e) => { e.preventDefault(); setFilter(value); }}>
      {renderForm()}
      <footer className="p-3 border-top">
        <Button block type="submit" size="sm" disabled={show && !value.length || value.includes(undefined)}>{t('common:buttons.apply')}</Button>
      </footer>
    </Form>}
  </div>
};

export const RangeColumnFilter = ({
  preFilteredRows,
  column: { filterValue, setFilter, id, Header, isGlobal, handleChange, currency },
}) => {
  const { t } = useTranslation();

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length && preFilteredRows[0].values[id] > 1 ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length && preFilteredRows[0].values[id] > 1 ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.filter(row => row.values[id]).forEach(row => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max]
  }, [id, preFilteredRows]);

  const [lowerBound, setLowerBound] = useState(filterValue ? filterValue[0] : min);
  const [upperBound, setUpperBound] = useState(filterValue ? filterValue[1] : max);
  const [value, setValue] = useState(filterValue ? filterValue : [min, max]);

  const fieldProps = currency ? {
    as: CurrencyInput
  } : {
    className: 'w-100',
    type: 'number'
  };

  const renderForm = () => <>
    <section className="filter-picker p-3">
      <Form.Label className="text-sm" htmlFor={`${id}.min`}>{t('common:labels.min')}:</Form.Label>
      <InputGroup>
        {currency && <InputGroup.Text className="border-right-0 rounded-right-0 px-2 py-1">$</InputGroup.Text>}
        <Form.Control
          {...fieldProps}
          size="sm"
          className={`rounded-sm${currency ? ' rounded-left-0' : ''}`}
          id={`${id}.min`}
          defaultValue={isNaN(lowerBound) ? undefined : currency ? convertToUSD(lowerBound).replace(/,/g, '') : lowerBound}
          onValueChange={currency ? (v) => {
            const value = [convertToSila(v), upperBound];
            setLowerBound(value[0]);
            setValue(value);
            if (isGlobal && handleChange) handleChange(value);
          } : undefined}
          onChange={!currency ? (e) => {
            const value = [+e.target.value, upperBound];
            setLowerBound(value[0]);
            setValue(value);
            if (isGlobal && handleChange) handleChange(value);
          } : undefined}
        />
      </InputGroup>
      <br />
      <Form.Label className="text-sm" htmlFor={`${id}.max`}>{t('common:labels.max')}:</Form.Label>
      <InputGroup>
        {currency && <InputGroup.Text className="border-right-0 rounded-right-0 px-2 py-1">$</InputGroup.Text>}
        <Form.Control
          {...fieldProps}
          size="sm"
          className="rounded-sm"
          id={`${id}.max`}
          defaultValue={isNaN(upperBound) ? undefined : currency ? convertToUSD(upperBound).replace(/,/g, '') : upperBound}
          onValueChange={currency ? (v) => {
            const value = [lowerBound, convertToSila(v)];
            setUpperBound(value[1]);
            setValue(value);
            if (isGlobal && handleChange) handleChange(value);
          } : undefined}
          onChange={!currency ? (e) => {
            const value = [lowerBound, +e.target.value];
            setUpperBound(value[1]);
            setValue(value);
            if (isGlobal && handleChange) handleChange(value);
          } : undefined}
        />
      </InputGroup>
    </section>
  </>

  return <div className="filter m-n3">{isGlobal ? renderForm() :
    <Form onSubmit={(e) => { e.preventDefault(); setFilter(value); }}>
      {renderForm()}
      <footer className="p-3 border-top">
        <Button block type="submit" size="sm">{t('common:buttons.apply')}</Button>
      </footer>
    </Form>}
  </div>
};

const setFilteredParams = (filterArr, val) => {
  if (filterArr.includes(val)) {
    filterArr = filterArr.filter((n) => {
      return n !== val;
    });
  } else filterArr.push(val);
  return filterArr;
};

const splitMultipleOptions = (rows, id) => {
  const options = [];
  rows.forEach(row => {
    if (Array.isArray(row.values[id])) {
      row.values[id].forEach(option => {
        const value = option.value || option;
        if (value && options.indexOf(value) === -1) {
          options.push(value.toString());
        }
      });
    } else if (row.values[id] && options.indexOf(row.values[id]) === -1) {
      options.push(row.values[id].toString());
    }
  });
  return options;
};