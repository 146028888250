import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { formatDateAndTime } from '../../utils';

import LoaderButton from '../common/LoaderButton';
import FieldWrapper from '../form/wrappers/Field';

const VerficationRecordModal = ({ show, onHide, handle, name, data, flow, kyc_levels, verification_statuses }) => {
  const { t } = useTranslation();

  const KYC_EXCLUDE_LIST = ['DOC_KYC_BETA', 'INSTANT-ACH', 'INSTANT-ACHV2', 'INSTANT-ACHV2-1', 'KYC-EXTERNAL-IACH'];
  const kycLevelOptions = flow === 'kyb' ? kyc_levels.filter(level => level.is_kyb && level.name !== 'KYB-FD' || level.name === 'RECEIVE_ONLY').map(level => ({ value: level.name, label: level.name })) : kyc_levels.filter(level => !level.is_kyb && !KYC_EXCLUDE_LIST.includes(level.name)).map(level => ({ value: level.name, label: level.name }));

  const verificationStatusOptions = verification_statuses.filter(status => status.name !== 'unverified').map(status => ({ label: status.label, value: status.name }));

  return (
    <Modal centered id="verfication-record-modal"
      backdrop="static"
      show={show}
      onHide={() => onHide()}
      size="lg"
      aria-labelledby="verfication-record-modal-title">
      <Formik
        enableReinitialize={true}
        initialValues={{
          kyc_level: !data ? kycLevelOptions[0].value : null,
          verification_status: data ? data.verification_status : null,
          comment: ''
        }}
        validationSchema={yup.object().shape({
          comment: yup.string()
            .max(512, t('common:form.errors.max', { count: 512 }))
        })}
        onSubmit={(values) => onHide(data && data.user_handle ? data.user_handle : handle, values, data && data.user_handle ? 'member_verifications' : 'verification_history', data ? data.verification_id : undefined)}>
        {({ handleSubmit, isSubmitting, errors, dirty }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title as="h3" id="verfication-record-modal-title">
                {data ? <span className="d-flex flex-wrap">
                  <span className="mr-2">{t('admin.endUsers.records.new_evaluation.edit.header')}:</span>
                  <span className="text-primary">{data.user_handle ? `${data.first_name} ${data.last_name}` : `${formatDateAndTime(data.requested_at * 1000)} ${t('common:partials.for')} ${handle}`}</span>
                </span> : t('admin.endUsers.records.new_evaluation.new.header')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!data && <p className="text-lg mb-3">{t('admin.endUsers.records.new_evaluation.new.title')}</p>}
              <p className="text-info mb-4">{data ? t('admin.endUsers.records.new_evaluation.edit.description', { name }) : t('admin.endUsers.records.new_evaluation.new.description', { handle: handle })}</p>
              {data ? <Field required className="verification-status"
                id="verficationRecordForm.verification_status"
                label={t('admin.endUsers.records.new_evaluation.form.fields.verification_status.label')}
                options={verificationStatusOptions}
                name="verification_status"
                type="select"
                component={FieldWrapper} /> :
                <Field required className="kyc_level"
                  id="verficationRecordForm.kyc_level"
                  label={t('admin.endUsers.records.new_evaluation.form.fields.kyc_level.label', { flow: flow.toUpperCase() })}
                  options={kycLevelOptions}
                  name="kyc_level"
                  type="select"
                  component={FieldWrapper} />}
              <Field className="comment mb-0"
                id="verficationRecordForm.comment"
                is="textarea"
                rows="10"
                label={t('admin.endUsers.records.new_evaluation.form.fields.comment.label')}
                placeholder={t('admin.endUsers.records.new_evaluation.form.fields.comment.placeholder')}
                name="comment"
                component={FieldWrapper} />
            </Modal.Body>
            <Modal.Footer>
              <Button disabled={isSubmitting} variant="outline-light" onClick={() => onHide()}>{t('common:buttons.cancel')}</Button>
              <LoaderButton loading={isSubmitting} disabled={Object.keys(errors).length} onClick={isSubmitting ? (e) => e.preventDefault() : undefined} type="submit">{t(data ? 'common:buttons.saveChanges' : 'admin.endUsers.records.new_evaluation.form.buttons.submit')}</LoaderButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

VerficationRecordModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The data object
   */
  data: PropTypes.object,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired,
  /**
   * The end user handle
   */
  handle: PropTypes.string.isRequired,
  /**
   * The end user flow (kyb or kyc)
   */
  flow: PropTypes.string.isRequired,
  /**
 * The array of kyc levels
 */
  kyc_levels: PropTypes.array.isRequired,
  /**
* The array of verification statuses
*/
  verification_statuses: PropTypes.array.isRequired,
};

export default VerficationRecordModal;