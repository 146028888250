import React from 'react';
import { Nav } from 'react-bootstrap';

import { useAuth } from '../../../components/context';

import { primaryNav, secondaryNav } from '../../../constants/sidebarNavItems';

import SidebarNavItem from './SidebarNavItem';

const SidebarNavItems = () => {
  const { user } = useAuth();
  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders d-flex flex-column align-content-start h-100">
        <div className="primary w-100">
          {primaryNav.filter(item => item.permissions ? item.permissions(user) : !item.permissions).map((item, index) => <SidebarNavItem key={index} item={item} />)}
        </div>
        <div className="secondary mt-auto w-100">
          {secondaryNav.filter(item => item.permissions ? item.permissions(user) : !item.permissions).map((item, index) => <SidebarNavItem key={index} item={item} />)}
        </div>
      </Nav>
    </div>
  );
};

export default SidebarNavItems;
