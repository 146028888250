export default [{
    name: 'profile',
    title: 'user.menu.profile',
    to: '/account',
    htmlBefore: '<i class="fas fa-user"></i>',
    htmlAfter: ''
  },
  {
    name: 'password',
    title: 'user.menu.password',
    htmlBefore: '<i class="fas fa-key"></i>',
    to: '/account/reset_password',
  }
]