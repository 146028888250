import React from 'react';
import { Container, Row } from 'react-bootstrap';

import settingsNavItems from '../../constants/settingsNavItems';

import PageTitle from '../../components/common/PageTitle';
import PageSections from '../../components/common/PageSections';

const Settings = ({ t }) => (
  <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

    <Row noGutters className="page-header mb-3 mb-md-5">
      <PageTitle title={t('settings.title')} />
    </Row>

    <PageSections items={settingsNavItems} />

  </Container>
);

export default Settings;