import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';

import { adminApi } from '../../api';
import { useAlerts } from '../context';

import { formatDateAndTime } from '../../utils';

import LoaderButton from '../common/LoaderButton';
import CommentForm from '../common/CommentForm';
import FieldWrapper from '../form/wrappers/Field';

const OnboardingChecklistTaskModal = ({ show, data, onHide, onComment, match, hasWriteAccess }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  
  const [comments, setComments] = useState(undefined);

  const initialValues = {
    description: data.description || ''
  };

  const getComments = async () => {
    try {
      const response = await adminApi.getCustomerOnboardComments(match.params.id, 3, data.id);
      if (response.success) {
        setComments(response.comments.sort((a, b) => new Date(b.created) - new Date(a.created)));
        onComment({ 
          index: data.index, 
          count: response.pagination.total_count
        });
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const handleCommentSubmit = async (values) => {
    if (hasWriteAccess && values) {
      try {
        await adminApi.createCustomerOnboardComment(match.params.id, 3, { ...values, task_id: data.id });
        getComments();
        setAlert({ message: t('common:comments.success'), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <Modal centered id="onboarding-checklist-task-modal"
      backdrop="static"
      show={show}
      size="xl"
      onHide={() => onHide()}
      aria-labelledby="onboarding-checklist-task-modal-title">

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => onHide({ description: values.description, id: data.id })}>
        {({ values, handleSubmit, errors, dirty, isSubmitting }) => (<>
          <Modal.Header className="p-4" closeButton>
            <Modal.Title id="onboarding-checklist-task-modal-title">{data.name}</Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-4">

            {data.assigned && <Row noGutters className="bg-info px-4 py-3 mt-n4 ml-n4 mr-n4 mb-4">
              <Col className="d-lg-flex align-items-center">
                <span className="d-block text-primary">{t('admin.customers.onboarding.checklist.labels.owner')}:</span>
                <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">{data.owner_login}</span>
              </Col>
              <Col className="d-lg-flex align-items-center">
                <span className="d-block text-primary">{t('admin.customers.onboarding.checklist.task.labels.assigned')}:</span>
                <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">{formatDateAndTime(data.assigned)}</span>
              </Col>
              <Col className="d-lg-flex align-items-center">
                <span className="d-block text-primary">{t('admin.customers.onboarding.checklist.task.labels.completed')}:</span>
                <span className="d-block ml-0 mb-2 ml-lg-3 mb-lg-0">{data.completed_date ? formatDateAndTime(data.completed_date) : <span className="text-info">{t('common:labels.na')}</span>}</span>
              </Col>
            </Row>}

            <Form noValidate autoComplete="new-password">
              <h4 className="mb-3" htmlFor="taskForm.description">{t('admin.customers.onboarding.checklist.task.form.fields.description.label')}</h4>
              <Field
                id="taskForm.description"
                fieldClass="no-resize text-reg"
                type="elastic"
                rows="3"
                placeholder={t('admin.customers.onboarding.checklist.task.form.fields.description.placeholder')}
                name="description"
                disabled={!hasWriteAccess}
                component={FieldWrapper} />
            </Form>

            <CommentForm className="mt-5" onSubmit={handleCommentSubmit} comments={comments} hasWriteAccess={hasWriteAccess} />

          </Modal.Body>

          <Modal.Footer className="pt-0 px-4 pb-4 border-top-0 loaded">
            <Button disabled={isSubmitting} variant="outline-light" onClick={() => onHide()}>{t('common:buttons.cancel')}</Button>
            {hasWriteAccess && <LoaderButton loading={isSubmitting} disabled={!dirty || Object.keys(errors).length} onClick={(e) => isSubmitting ? e.preventDefault() : handleSubmit({ ...data, ...values })}>{t('common:buttons.update')}</LoaderButton>}
          </Modal.Footer>

        </>
        )}
      </Formik>
    </Modal>
  );
};

OnboardingChecklistTaskModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired,
  /**
   * The function when a comment has been added
   */
  onComment: PropTypes.func.isRequired,
  /**
   * The user data object
   */
  data: PropTypes.object,
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired
};

export default OnboardingChecklistTaskModal;