import React, { useEffect, useState } from 'react';
import { Container, Row, Button, Card } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

import { adminApi } from '../../api';

import { convertToUSD, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import SalesTierForm from '../../components/admin/SalesTierForm';
import SalesTierLimits from '../../components/admin/SalesTierLimits';

const SalesTier = ({ t, match, history, user, env, setAlert }) => {
  const [initialValues, setInitialValues] = useState({
    name: '',
    long_name: '',
    description: '',
    id: ''
  });
  const [limits, setLimits] = useState([]);
  const [name, setName] = useState(initialValues.name);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const types = [{
    label: t('admin.salesTiers.limits.types.aggregate.title'),
    key: 'aggregate_limit',
    slug: 'aggregates',
    endpoint: 'limit_aggregate'
  }, {
    label: t('admin.salesTiers.limits.types.rate.title'),
    key: 'rate_limit',
    slug: 'rates',
    endpoint: 'limit_rate'
  }, {
    label: t('admin.salesTiers.limits.types.unscoped.title'),
    key: 'unscoped_limit_rate',
    slug: 'unscoped_rates',
    endpoint: 'limit_set_unscoped_rate'
  }];

  const handleLimits = (entries) => {
    const results = [];
    if (entries) {
      Object.entries(entries).forEach(entry => {
        entry[1].forEach(data => {
          results.push({
            ...data,
            uuid: uuid(),
            view_name: data.view_name ? `${data.view_name}.${data.method}` : null,
            amount: data.amount ? convertToUSD(data.amount).replace(/,/g, '') : null,
            type: types.some(type => type.slug === entry[0]) ? types.find(type => type.slug === entry[0]).key : types[0].key,
            unique: true
          });
        });
      });
    };
    return results;
  };

  const getSalesTier = async (id = match.params.id) => {
    setLoaded(false);
    try {
      const response = await adminApi.getSalesTier(id);
      setName(response.sales_tier.name);
      setInitialValues(response.sales_tier);
      setLimits(handleLimits(response.sales_tier.limits));
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setRedirect(true);
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])) history.push({ pathname: '/admin/sales_tiers', state: { from: location.pathname } });
  }, []);

  useEffect(() => {
    if (redirect) history.push({ pathname: '/admin/sales_tiers', state: { from: location.pathname } });
  }, [redirect]);

  useEffect(() => {
    if (match.params.action) {
      getSalesTier();
    } else {
      setLoaded(true);
    }
  }, [env, user.currentTeam]);

  return (
    <Container className="sales-tier main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5 loaded">
        <PageTitle title={t('admin.salesTiers.detail.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {!loaded ? <Loader /> :
        <Card className="loaded flex-grow-1">
          <Card.Header className="d-block d-sm-flex justify-content-between align-content-stretch p-4">
            <h3 className="m-0">{match.params.action === 'detail' ? name : match.params.action === 'limits' ? t('admin.salesTiers.limits.title', { name }) : t('admin.salesTiers.create.title')}</h3>
            {initialValues.name === 'default' && <p className="text-lg text-primary mt-2 mt-sm-0">{t('admin.salesTiers.detail.labels.default')}</p>}
          </Card.Header>
          <Card.Body className="p-4 d-flex align-content-stretch flex-column">
            {match.params.action !== 'limits' ? <SalesTierForm
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              name
              setName={setName}
              getSalesTier={getSalesTier}
              limits={limits}
              match={match} />
            : <SalesTierLimits
                limits={limits}
                getSalesTier={getSalesTier}
                match={match}
                types={types}
                name={name} />}
          </Card.Body>
        </Card>}

    </Container>
  );
};

export default SalesTier;