import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { webhooksApi } from '../api';

const ConfirmWebhookEmailAlerts = ({ t, user, match, location, setAlert }) => {
  const confirmEmailAlert = async () => {
    try {
      const response = await webhooksApi.confirmWebhookEmailAlert(match.params.token);
      setAlert({ message: response.message, variant: response.success ? 'success' : 'danger' });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    confirmEmailAlert();
  }, []);

  return <Redirect to={user ? { pathname: location.state && location.state.from ? location.state.from : `/dev/webhooks/${match.params.app_id}/${match.params.id}/detail` } : { pathname: '/login' }} />;
};

export default ConfirmWebhookEmailAlerts;