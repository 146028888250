import React, { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';

import { adminApi } from '../../api';
import { formatDateAndTime, uploadDocument } from '../../utils';

import TooltipButton from '../common/TooltipButton';
import Loader from '../common/Loader';
import DocumentUploadModal from '../common/DocumentUploadModal';
import DocumentPreviewModal from '../common/DocumentPreviewModal';

import { useAlerts } from '../context';

const EndUserDocuments = ({ initialValues }) => {
  const [loaded, setLoaded] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [upload, setUpload] = useState({ show: false, percentage: {} });
  const [preview, setPreview] = useState({ show: false, data: undefined });
  const [types, setTypes] = useState([]);
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const getDocumentTypes = async () => {
    try {
      const response = await adminApi.getDocumentTypes();
      setTypes(response.document_types);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const getEndUserDocuments = async () => {
    try {
      const response = await adminApi.getEndUserDocuments(initialValues.handle);
      setDocuments(response.documents);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setLoaded(true);
    }
  };

  const getEndUserDocument = async (document) => {
    if (document) {
      setPreview({ show: true, date: undefined });
      try {
        const response = await adminApi.getEndUserDocument(initialValues.handle, document.uuid);
        let blob = new Blob([response], { type: document.file_type === 'pdf' ? 'application/pdf' : `image/${document.file_type}` });
        setPreview({ show: true, data: { ...document, file: URL.createObjectURL(blob) } });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        setPreview({ show: false, date: undefined });
      }
    }
  };

  const addDocument = (values, setSubmitting) => {
    if (values) {
      const name = values.files[0].name;
      const data = {
        name,
        filename: name,
        mime_type: values.files[0].type,
        document_type: values.document_type,
        identity_type: values.identity_type
      };

      uploadDocument({
        url: `/superuser/end_users/${initialValues.handle}/documents`,
        file: values.files[0],
        data: data,
        onUpload: (percentage) => setUpload({ ...upload, percentage: { ...upload.percentage, [name]: percentage } }),
        onError: (status, error) => {
          if (setSubmitting) setSubmitting(false);
          setAlert({ message: status === 413 ? t('common:upload.form.messages.filesize') : error, style: 'danger' });
          setUpload({ ...upload, percentage: {} });
        },
        onSuccess: (message) => {
          delete upload.percentage[name];
          setUpload({ show: false, percentage: upload.percentage });
          setAlert({ message, style: 'success' });
          getEndUserDocuments();
        }
      });
    } else {
      setUpload({ show: false, percentage: {} });
    }
  };

  useEffect(() => {
    getDocumentTypes();
    getEndUserDocuments();
  }, []);

  return (<>

    <h4 className="mb-2">{t('admin.endUsers.docs.title')}</h4>
    <p className="text-info mb-4">{t('admin.endUsers.docs.description')}</p>

    {(!loaded || types.length === 0) ? <Loader /> :

      <>
        <Card className="mb-2 border loaded overflow-hidden">
          <Table bordered responsive className="border-0">
            <thead>
              <tr>
                <th className="border-right pl-4 py-3">{t('common:upload.labels.date')}</th>
                <th className="border-right px-4 py-3">{t('common:upload.labels.name')}</th>
                <th className="border-right px-4 py-3">{t('common:upload.labels.type')}</th>
                <th className="pr-4 py-3" width="1%">{t('common:labels.action')}</th>
              </tr>
            </thead>
            <tbody>
              {documents.length !== 0 ? documents.map((document, index) => <tr key={index}>
                <td className="pl-4 py-3">{formatDateAndTime(document.uploaded)}</td>
                <td className="px-4 py-3"><span className="w-100 d-block text-break pr-3 text-left" style={{ maxWidth: '300px' }}>{document.file_name}</span></td>
                <td className="px-4 py-3">{types.find(type => type.name === document.document_type).label}</td>
                <td className="pr-4 py-3 text-center" width="1%"><TooltipButton placement="top" variant="link" popoverProps={{ style: { textAlign: 'center' } }} onClick={() => getEndUserDocument(document)} text={t('common:labels.preview')} className="p-0 cursor-pointer"><i className="text-lg fas fa-eye"></i></TooltipButton></td>
              </tr>) :
                <tr className="disabled">
                  <td className="p-0 cursor-default" colSpan="4"><p className="text-center text-info font-italic mx-4 my-5">{t('admin.endUsers.docs.empty')}</p></td>
                </tr>}
            </tbody>
          </Table>
        </Card>

        <p className="loaded text-right mb-5"><Button variant="link" className="p-0 text-underline" onClick={() => setUpload({ ...upload, show: true })}>{t('common:upload.buttons.upload')}</Button></p>

      </>}

    {upload.show && <DocumentUploadModal show={upload.show} types={types} percentage={upload.percentage} onHide={addDocument} />}

    {preview.show && <DocumentPreviewModal show={preview.show} data={preview.data} onHide={() => setPreview({ show: false, data: undefined })} />}

  </>
  );
};

EndUserDocuments.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to set the value of the form
   */
  setInitialValues: PropTypes.func.isRequired
};

export default EndUserDocuments;