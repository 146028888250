import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Card, Form, Col, Button, ListGroup } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { adminApi, consoleApi } from '../../api';

import { handleResponseError, removeEmpty, getObjectDiff, getUniqueArray, formatNumberColumns, applyFilter, applyQuery, convertSecondsToTime, convertToSila } from '../../utils';
import { useCurrentDimensions } from '../../utils/hooks';

import FieldWrapper from '../form/wrappers/Field';
import TooltipButton from '../common/TooltipButton';
import ConfirmModal from '../common/ConfirmModal';
import GlobalFilterMenu from '../common/GlobalFilterMenu';
import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import TimePicker from '../common/TimePicker';
import SalesTierLimitTypesModal from './SalesTierLimitTypesModal';

import { useAlerts } from '../context';

const UNIQUE_KEYS = ['duration', 'amount', 'cap', 'view_name', 'method', 'kyc_level'];
const EXPANDED_MAX_ROW_COUNT = 8;

const fieldClass = (initialValues, limit, key) => {
  const value = initialValues.find(value => value.id === limit.id && value.type === limit.type);
  return (value && value[key] !== limit[key]) ? 'border-success' : undefined;
};

const SalesTierLimits = ({ name, limits, getSalesTier, match, types }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const location = useLocation();
  const [show, setShow] = useState({ modal: false });
  const [expanded, setExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(limits.length === 0);
  const [tableFilters, setTableFilters] = useState(location.search.length ? applyQuery(location.search.substring(1)).filters : []);
  const [deleted, setDeleted] = useState([]);
  const [currentLimit, setCurrentLimit] = useState({ update: false, delete: false, data: undefined });
  const [versions, setVersions] = useState([]);
  const [endpoints, setEndpoints] = useState({});
  const [kycLevels, setKycLevels] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [expandedHeight, setExpandedHeight] = useState();
  const formRef = useRef(null);
  const expandedRef = useRef([]);
  const history = useHistory();
  const currentWidth = useCurrentDimensions().width;
  const isMobile = currentWidth <= 992;

  const updateLimits = async (values) => {
    if (values) {
      try {
        const response = await adminApi.updateSalesTierLimits(values.changes, types, match.params.id);
        if (response.every(res => res.success)) {
          setAlert({ message: t('admin.salesTiers.limits.messages.success'), variant: 'success' });
          getSalesTier(match.params.id);
        } else {
          setAlert({ message: t('admin.salesTiers.limits.messages.error'), variant: 'warning' });
        }
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setCurrentLimit({ ...currentLimit, update: false, data: undefined });
        setRedirect(true);
      }
    } else {
      formRef.current.setSubmitting(false);
      setCurrentLimit({ ...currentLimit, update: false, data: undefined });
    }
  };

  const deleteLimit = (values) => {
    if (values) {
      values.arrayHelpers.remove(values.index);
      if (values.limit.id) setDeleted([...deleted, { id: values.limit.id, type: values.limit.type }]);
    }
    setCurrentLimit({ ...currentLimit, delete: false, data: undefined });
  };

  const handleChanges = (before, after) => {
    const changes = [];
    before.map(limit => removeEmpty(limit)).forEach(b => {
      if (b.id && deleted.some(d => b.id === d.id && b.type === d.type)) changes.push({ ...b, status: 'delete' });
    });
    after.map(limit => removeEmpty(limit)).forEach(a => {
      let change, newLimit = before.find(b => b.id === a.id && b.type === a.type);
      if (a.id && newLimit) {
        change = getObjectDiff(newLimit, a);
        if (Object.keys(change).length)
          changes.push({
            ...change,
            api_version: a.api_version,
            id: a.id,
            type: a.type,
            status: 'put'
          });
      } else if (!a.id) {
        changes.push({ ...a, status: 'post' });
      }
    });
    return changes.map(limit => limit.view_name ? { ...limit, method: limit.view_name.split('.').pop(), view_name: limit.view_name.replace(/\.[^/.]+$/, '') } : limit);
  };

  const getMetaData = async () => {
    try {
      const [versionsResponse, endpointsResponse, kycLevelsResponse] = await Promise.all([
        consoleApi.get({ url: '/api_versions' }),
        consoleApi.get({ url: '/limited_endpoints' }),
        consoleApi.get({ url: '/kyc_levels' })
      ]);
      if (!versionsResponse.success) throw handleResponseError(versionsResponse);
      if (!endpointsResponse.success) throw handleResponseError(endpointsResponse);
      if (!kycLevelsResponse.success) throw handleResponseError(kycLevelsResponse);
      setVersions(versionsResponse.api_versions);
      setEndpoints(endpointsResponse.limited_endpoints.decorators);
      setKycLevels(kycLevelsResponse.kyc_levels);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setLoaded(true);
    }
  };

  const getEndpointOptions = () => {
    const options = [];
    types.forEach(type => {
      versions.forEach(version => {
        if (endpoints[version][type.key]) endpoints[version][type.key].forEach(endpoint => {
          if (!options.some(option => option.label === endpoint)) options.push({
            label: endpoint,
            value: endpoint
          });
        });
      });
    });
    return options;
  };

  useEffect(() => {
    const { url } = applyFilter({ filters: { url: tableFilters, query: tableFilters } });
    if (decodeURI(location.search.substring(1)) !== url) history.push({ pathname: location.pathname, search: url });
  }, [tableFilters]);


  useEffect(() => {
    if (!limits.length && formRef.current && history.location.state && history.location.state.limits) formRef.current.setFieldValue('limits', history.location.state.limits.map(limit => { delete limit.id; return limit }));
  }, [limits, formRef.current, history.location]);

  useEffect(() => {
    if (redirect) history.push({ pathname: '/admin/sales_tiers', state: { from: location.pathname } });
  }, [redirect]);

  useEffect(() => {
    getMetaData();
  }, []);

  return (
    <>
      <p className="mb-4 text-med text-info">
        {limits.length ?
          <Trans i18nKey="admin.salesTiers.limits.description.update">Below you will see all the limits set for this sales tier. You may view, edit and add additional limits to this sales tier, as well as delete limits.  You may filter and search by a specific endpoint, and filter by limit type to narrow the scope. To edit, you must turn on edit mode. <Button variant="link" className="text-med p-0 mt-n1" onClick={() => setShow({ ...show, modal: true })}>Learn more about each limit type here</Button>.</Trans> :
          <Trans i18nKey="admin.salesTiers.limits.description.create">Below you can set all the the rate, aggregate, and unscoped limits for this sales tier. Click “Add limits” to add a new editable row to the table. <Button variant="link" className="text-med p-0 mt-n1" onClick={() => setShow({ ...show, modal: true })}>Learn more about each limit type here</Button>.</Trans>}
      </p>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        initialValues={{
          limits: limits.filter(limit => !tableFilters.length || tableFilters.length && tableFilters.every(filter => limit[filter.id] && filter.value && filter.value.includes(limit[filter.id])))
        }}
        validationSchema={yup.object().shape({
          limits: yup.array().of(yup.object().shape({
            type: yup.string()
              .required(t('common:form.errors.required', { field: t('admin.salesTiers.limits.labels.type') })),
            view_name: yup.string()
              .ensure().when('type', {
                is: (value) => value && value !== 'unscoped_limit_rate',
                then: yup.string().required(t('common:form.errors.required', { field: t('admin.salesTiers.limits.labels.view_name') }))
              }),
            cap: yup.number().nullable()
              .when('type', {
                is: (value) => value && value !== 'aggregate_limit',
                then: yup.number().required(t('common:form.errors.required', { field: t('admin.salesTiers.limits.labels.cap') }))
              }),
            duration: yup.number().nullable()
              .required(t('common:form.errors.required', { field: t('admin.salesTiers.limits.labels.duration') })),
            amount: yup.number().nullable()
              .when('type', {
                is: (value) => value && value === 'aggregate_limit',
                then: yup.number().required(t('common:form.errors.required', { field: t('admin.salesTiers.limits.labels.amount') }))
              }),
            unique: yup.boolean()
              .required(t('admin.salesTiers.limits.tips.duplicate'))
              .oneOf([true], t('admin.salesTiers.limits.tips.duplicate'))
          }))
        })}
        onSubmit={(values) => setCurrentLimit({ ...currentLimit, update: true, data: { name, changes: handleChanges(limits.map(limit => { delete limit.uuid; return limit; }), values.limits.map(limit => { delete limit.uuid; return limit; })) } })}>
        {({ values, errors, handleSubmit, isSubmitting, dirty, resetForm, initialValues, setFieldValue }) => {

          useEffect(() => {
            const arrayList = [];
            const lists = expandedRef.current;
            if (values.limits && values.limits.length > EXPANDED_MAX_ROW_COUNT && !isMobile && lists && lists.length) {
              for (let i = 0; i < EXPANDED_MAX_ROW_COUNT; i++) {
                var eachList = lists[i].clientHeight;
                arrayList.push(eachList);
                setExpandedHeight(arrayList.reduce((a, b) => a + b, 0));
              }
            } else if (isMobile) {
              setExpandedHeight(false);
            }
          }, [isMobile, values.limits]); 

          useEffect(() => {
            values.limits.forEach(limit => {
              limit.unique = getUniqueArray(values.limits.map(limit => { const { id, unique, ...rest } = limit; return removeEmpty(rest); }), UNIQUE_KEYS).some(unique => limit.uuid === unique.uuid);
            });
          }, [values.limits]);

          return (<Form noValidate className="loaded d-flex flex-grow-1 flex-column justify-content-between align-content-stretch" autoComplete="new-password" onSubmit={handleSubmit}>
            <FieldArray
              name="limits"
              render={arrayHelpers => <Card className="border loaded flex-grow-1" style={{ zIndex: 2 }}>
                <Card.Header className="d-flex justify-content-between align-content-stretch p-4">
                  <div className="d-flex align-items-center">
                    {values.limits.length > EXPANDED_MAX_ROW_COUNT && <TooltipButton placement="top" variant="link" text={t(`common:buttons.${expanded ? 'collapse' : 'expand'}`)} className="p-0 cursor-pointer no-underline loaded mr-3" onClick={() => setExpanded(!expanded)} popoverProps={{ style: { textAlign: 'center' } }}><i className={`sila-icon text-lg text-${expanded ? 'primary collapsed' : 'info expanded'}`}></i></TooltipButton>}
                    {isEditing && <Button variant="link" className="p-0 text-lg loaded" onClick={() => arrayHelpers.unshift({
                      uuid: uuid(),
                      duration: '',
                      amount: '',
                      cap: '',
                      api_version: versions[0],
                      view_name: '',
                      method: '',
                      kyc_level: '',
                      type: '',
                      unique: true
                    })}>{t('admin.salesTiers.limits.buttons.create')}</Button>}
                    {tableFilters.length !== 0 &&
                      <Button size="sm" onClick={() => setTableFilters([])} variant="link" className="p-0 text-underline text-info ml-3 loaded">{t('common:filters.buttons.clear')}</Button>}
                  </div>
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="switch"
                      id="edit-mode-switch"
                      onChange={(e) => setIsEditing(e.target.checked)}
                      checked={isEditing} />
                    <Form.Label htmlFor="edit-mode-switch" className="m-0 mr-3 mr-md-4 text-reg">{t('admin.salesTiers.limits.buttons.edit')}</Form.Label>
                    {loaded && <GlobalFilterMenu
                      tableFilters={tableFilters}
                      setTableFilters={setTableFilters}
                      data={[{
                        id: 'type',
                        label: t('admin.salesTiers.limits.labels.type'),
                        options: types.map(type => ({ label: type.label, value: type.key }))
                      }, {
                        id: 'view_name',
                        search: true,
                        label: t('admin.salesTiers.limits.labels.view_name'),
                        options: getEndpointOptions()
                      }]} />}
                  </div>
                </Card.Header>
                <Card.Body className="p-0 d-flex flex-grow-1 flex-column">
                  <Form.Row className="d-none d-lg-flex flex-nowrap bg-info m-0 border-bottom px-3 py-2">
                    {isEditing && values.limits.length !== 0 && <div className="d-none d-lg-block loaded" style={{ width: 30 }}>&nbsp;</div>}
                    <Col lg="2" className="text-info font-weight-bold">{t('admin.salesTiers.limits.labels.type')}</Col>
                    <Col lg="2" className="text-info font-weight-bold">{t('admin.salesTiers.limits.labels.view_name')}</Col>
                    <Col lg="2" className="text-info font-weight-bold">{t('admin.salesTiers.limits.labels.kyc_level')}</Col>
                    <Col lg="1" className="text-info font-weight-bold">{t('admin.salesTiers.limits.labels.cap')}<TooltipButton placement="top" variant="link" text={t('admin.salesTiers.limits.tips.cap')} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton></Col>
                    <Col lg="3" className="text-info font-weight-bold">{t('admin.salesTiers.limits.labels.duration')}<TooltipButton placement="top" variant="link" text={t('admin.salesTiers.limits.tips.duration')} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton></Col>
                    <Col lg="2" className="text-info font-weight-bold">{t('admin.salesTiers.limits.labels.amount')}<TooltipButton placement="top" variant="link" text={t('admin.salesTiers.limits.tips.amount')} className="ml-2 no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton></Col>
                    {isEditing && values.limits.some(limit => !limit.unique) && <div className="d-none d-lg-block loaded" style={{ width: 30 }}>&nbsp;</div>}
                  </Form.Row>
                  <div className="d-flex flex-grow-1 flex-column">
                    {!loaded ? <Loader /> : <>
                      {values.limits.length ? <ul className={`list-unstyled mb-0${!expanded ? ' overflow-auto custom-scrollbar' : 'overflow-hidden'}`} style={!expanded ? { maxHeight: expandedHeight } : undefined}>
                        {values.limits.map((limit, index) => {
                          const api_version = limit.api_version || versions[0];
                          return (<li key={index} ref={(el) => expandedRef.current.splice(index, 1, el)} className={`loaded limit position-relative bg-primary-light-hover d-flex flex-row flex-items-stretch align-items-center${index !== 0 ? ' border-top' : ''}`}>
                            {isEditing && values.limits.length !== 0 && <div className={`d-flex align-items-center loaded${isMobile ? ' position-absolute pr-3 pt-2' : ''}`} style={isMobile ? { top: 0, right: 0, zIndex: 2 } : undefined}>
                              {!limit.unique && <TooltipButton placement="auto" variant="link" text={t('admin.salesTiers.limits.tips.duplicate')} className="d-inline-block d-lg-none btn-circle-sm bg-white-hover cursor-pointer"><i className="fas fa-exclamation-triangle text-warning text-lg"></i></TooltipButton>}
                              <Button variant="link" className="btn-circle-sm bg-white-hover text-lg no-underline text-info ml-lg-2 mr-lg-n3" onClick={() => setCurrentLimit({ delete: true, data: { limit, index, arrayHelpers } })}><i className="fas fa-trash"></i></Button>
                            </div>}
                            <Form.Row className="m-0 w-100 p-3 py-lg-2 px-lg-3">
                              <Col md="12" lg="2">
                                <Field required className="mb-2 mb-lg-0"
                                  type="select"
                                  size="sm"
                                  label={isMobile ? t('admin.salesTiers.limits.labels.type') : undefined}
                                  fieldClass={isEditing ? fieldClass(limits, limit, 'type') : undefined}
                                  id="editSalesTierLimits.type"
                                  name={`limits.${index}.type`}
                                  handleChange={(e) => {
                                    const value = e.target.value;
                                    if (limit.id && limit.type !== value) {
                                      setFieldValue(`limits.${index}.id`, undefined);
                                      setDeleted([...deleted, { id: limit.id, type: limit.type }]);
                                    }
                                    if (value === 'rate_limit') {
                                      setFieldValue(`limits.${index}.kyc_level`, undefined);
                                      setFieldValue(`limits.${index}.amount`, undefined);
                                    } else if (value === 'aggregate_limit') {
                                      setFieldValue(`limits.${index}.cap`, undefined);
                                    } else {
                                      setFieldValue(`limits.${index}.view_name`, undefined);
                                      setFieldValue(`limits.${index}.amount`, undefined);
                                    }
                                  }}
                                  options={types.map(type => ({ value: type.key, label: type.label }))}
                                  disabled={!isEditing}
                                  placeholder={t('admin.salesTiers.limits.placeholders.type')}
                                  component={FieldWrapper} />
                              </Col>
                              <Col md="12" lg="2">
                                <Field required className="mb-2 mb-lg-0"
                                  type="select"
                                  size="sm"
                                  label={isMobile ? t('admin.salesTiers.limits.labels.view_name') : undefined}
                                  fieldClass={isEditing ? fieldClass(limits, limit, 'view_name') : undefined}
                                  id="editSalesTierLimits.view_name"
                                  name={`limits.${index}.view_name`}
                                  options={limit.type === 'rate_limit' && endpoints[api_version] && endpoints[api_version][limit.type] ? endpoints[api_version][limit.type].map(value => ({
                                    value: value,
                                    label: `${value} (API ${api_version})`
                                  })) : limit.type === 'aggregate_limit' && endpoints[api_version] && endpoints[api_version][limit.type] ? endpoints[api_version][limit.type].map(value => ({
                                    value: value,
                                    label: value.includes('IssueSila.post') ? value.replace('.post', ' (ACH Debit)') : value.replace('.post', ' (ACH Credit)')
                                  })) : []}
                                  disabled={!isEditing || limit.type === 'unscoped_limit_rate' || !limit.type}
                                  readOnly={limit.type === 'unscoped_limit_rate'}
                                  placeholder={limit.type !== 'unscoped_limit_rate' ? t('admin.salesTiers.limits.placeholders.view_name') : ' '}
                                  component={FieldWrapper} />
                              </Col>
                              <Col md="12" lg="2">
                                <Field required className="mb-2 mb-lg-0"
                                  type="select"
                                  size="sm"
                                  label={isMobile ? t('admin.salesTiers.limits.labels.kyc_level') : undefined}
                                  fieldClass={isEditing ? fieldClass(limits, limit, 'kyc_level') : undefined}
                                  id="editSalesTierLimits.kyc_level"
                                  name={`limits.${index}.kyc_level`}
                                  options={limit.type === 'aggregate_limit' ? kycLevels.map(level => ({ value: level.id, label: level.name })) : []}
                                  disabled={!isEditing || limit.type !== 'aggregate_limit'}
                                  readOnly={limit.type !== 'aggregate_limit'}
                                  placeholder={limit.type === 'aggregate_limit' ? t('admin.salesTiers.limits.placeholders.kyc_level') : ' '}
                                  component={FieldWrapper} />
                              </Col>
                              <Col md="12" lg="1">
                                <Field required className="mb-2 mb-lg-0"
                                  id="editSalesTierLimits.cap"
                                  fieldClass={isEditing ? fieldClass(limits, limit, 'cap') : undefined}
                                  name={`limits.${index}.cap`}
                                  type="number"
                                  size="sm"
                                  label={isMobile ? t('admin.salesTiers.limits.labels.cap') : undefined}
                                  disabled={!isEditing || limit.type === 'aggregate_limit'}
                                  readOnly={limit.type === 'aggregate_limit'}
                                  placeholder={limit.type !== 'aggregate_limit' ? t('admin.salesTiers.limits.placeholders.cap') : ' '}
                                  component={FieldWrapper} />
                              </Col>
                              <Col md="12" lg="3" className={isMobile ? 'mb-2' : undefined}>
                                {isMobile && <Form.Label>{t('admin.salesTiers.limits.labels.duration')}</Form.Label>}
                                <TimePicker inline
                                  disabled={!isEditing}
                                  duration={values.limits[index].duration}
                                  onHandleChange={(value) => setFieldValue(`limits.${index}.duration`, value)} />
                              </Col>
                              <Col md="12" lg="2">
                                <Field required className="mb-0"
                                  id="editSalesTierLimits.amount"
                                  fieldClass={`${isEditing ? fieldClass(limits, limit, 'amount') : ''}${limit.amount ? ' pl-0 border-left-0' : ''}`}
                                  name={`limits.${index}.amount`}
                                  size="sm"
                                  type="number"
                                  label={isMobile ? t('admin.salesTiers.limits.labels.amount') : undefined}
                                  prepend={limit.type !== 'unscoped_limit_rate' && limit.amount ? '$' : undefined}
                                  prependClass={`pr-1 ${isEditing ? fieldClass(limits, limit, 'amount') : ''}`}
                                  disabled={!isEditing || limit.type !== 'aggregate_limit'}
                                  readOnly={limit.type !== 'aggregate_limit'}
                                  placeholder={limit.type === 'aggregate_limit' ? t('admin.salesTiers.limits.placeholders.amount') : ' '}
                                  component={FieldWrapper} />
                              </Col>
                            </Form.Row>
                            {isEditing && values.limits.some(limit => !limit.unique) && <div style={{ width: 30 }} className="d-none d-lg-block loaded text-center">
                              {isEditing && !limit.unique && <TooltipButton placement="auto" variant="link" text={t('admin.salesTiers.limits.tips.duplicate')} className="p-0 cursor-pointer ml-n3"><i className="fas fa-exclamation-triangle text-warning text-lg"></i></TooltipButton>}
                            </div>}
                          </li>);
                        })}
                      </ul> : <p className="text-center text-info font-italic mx-auto my-5 w-75">{tableFilters.length ? t('common:form.messages.empty') : t('admin.salesTiers.limits.empty')}</p>}
                    </>}
                  </div>
                </Card.Body>
              </Card>}
            />
            <Card.Footer className="border-0 p-0 mt-4 text-right">
              {!history.location.state && <Button className="px-4" variant="outline-light" onClick={() => resetForm({ values: initialValues })} disabled={!dirty}>{t('common:buttons.reset')}</Button>}
              <LoaderButton variant="primary" onClick={!dirty ? () => { setRedirect(true); setAlert({ message: t('admin.salesTiers.limits.messages.success'), variant: 'success' }); } : undefined} type={dirty ? 'submit' : undefined} className="ml-3 px-4" loading={isSubmitting} disabled={Object.keys(errors).length || !isEditing}>{t('common:buttons.save')}</LoaderButton>
            </Card.Footer>
          </Form>);
        }}
      </Formik>

      {currentLimit.update && <ConfirmModal
        size="lg"
        show={currentLimit.update}
        data={currentLimit.data}
        title={t('admin.salesTiers.limits.confirm.title')}
        renderMessageComponent={({ data }) => <>
          <p className="text-lg text-info mb-4">{t('admin.salesTiers.limits.confirm.description', { name: data.name })}</p>
          <h3>{t('admin.salesTiers.limits.confirm.changes.title')}:</h3>
          <div className="overflow-auto rounded border custom-scrollbar" style={{ maxHeight: 337 }}>
            <ListGroup className="border-0">
              {data.changes.map((change, index) => {
                const { status, type, view_name, id, api_version, method, unique, uuid, ...rest } = change;
                return (<ListGroup.Item className={`d-flex border-0${index !== 0 ? ' border-top' : ''}`} key={index}>
                  <span className={`text-nowrap mr-2 text-${change.status === 'delete' ? 'warning' : 'info'}`}>
                    {t(`admin.salesTiers.limits.confirm.changes.${status}`, { type: types.find(t => t.key === type).label })}{(view_name || change.status !== 'delete' && Object.keys(rest).length !== 0) && ':'}
                  </span>
                  <span>
                    {view_name && <em className="mr-2">{view_name} (API {api_version})</em>}
                    {change.status !== 'delete' && Object.keys(rest).length !== 0 && Object.entries(rest).map(value =>
                      <Fragment key={value[0]}>
                        <span className="ml-2 mr-1">{t(`admin.salesTiers.limits.labels.${value[0]}`)}:</span>
                        <em className="text-info">
                          {value[0] === 'duration' ? convertSecondsToTime(value[1]) : value[0] === 'kyc_level' ? kycLevels.find(level => level.id === parseInt(value[1])).name : value[0] === 'amount' ? `$${formatNumberColumns(value[1])} (${formatNumberColumns(convertToSila(value[1]))} Sila)` : Number.isInteger(value[1]) ? formatNumberColumns(value[1]) : value[1]}</em>
                      </Fragment>)}
                  </span>
                </ListGroup.Item>);
              })}
            </ListGroup>
          </div>
        </>}
        buttonLabel={t('common:buttons.confirmSave')}
        onHide={updateLimits} />}

      {currentLimit.delete && <ConfirmModal
        show={currentLimit.delete}
        data={currentLimit.data}
        title={t('admin.salesTiers.limits.delete.title')}
        message={t('admin.salesTiers.limits.delete.description', { name })}
        buttonLabel={t('common:buttons.delete')}
        buttonVariant="warning"
        onHide={deleteLimit} />}

      {show.modal && <SalesTierLimitTypesModal show={show.modal} onHide={() => setShow({ ...show, modal: false })} />}

    </>

  );
};

SalesTierLimits.propTypes = {
  /**
   * The sales tier name
   */
  name: PropTypes.string.isRequired,
  /**
   * The limits array
   */
  limits: PropTypes.array.isRequired,
  /**
   * The limit types array
   */
  types: PropTypes.array.isRequired,
  /**
   * The save function when the form is submitted
   */
  getSalesTier: PropTypes.func.isRequired,
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired
};

export default SalesTierLimits;