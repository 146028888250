import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LogoutModal = ({ show, onHide }) => {
  const handleHide = (logout) => logout ? onHide(logout) : onHide();
  const { t } = useTranslation();
  return (
    <Modal centered id="logout-modal"
      backdrop="static"
      show={show}
      onHide={handleHide}
      aria-labelledby="logout-modal-title">
      <Modal.Header closeButton>
        <Modal.Title as="h3" id="logout-modal-title">
          {t('user.logout.title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-lg text-info mb-0">{t('user.logout.description')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-light" onClick={() => onHide()}>{t('common:buttons.cancel')}</Button>
        <Button onClick={() => handleHide(true)}>{t('common:buttons.logout')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

LogoutModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default LogoutModal;