import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { webhooksApi } from '../../api';
import { formatDateAndTime, capitalize, hasPermissions } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import DataList from '../../components/common/DataList';
import ConfirmModal from '../../components/common/ConfirmModal';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import TooltipButton from '../../components/common/TooltipButton';

import { DateRangeColumnFilter, CheckboxColumnFilter, SelectColumnFilter, MultipleFilter, DateBetweenFilter } from '../../components/common/table/TableFilters';

import { WEBHOOK_TROUBLESHOOTING_URL } from '../../constants';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'webhook_events';
const ITEM_SLUG = 'events';

const formatFilters = (filters) => {
  let query = filters;
  const created = filters.find(filter => filter.id === 'event_created');
  const archived = filters.find(filter => filter.id === 'last_response_status_code' && filter.value.includes('archived'));
  const multiple_status_code = filters.find(filter => filter.id === 'last_response_status_code' && filter.value.includes(','));
  if (created) {
    if (created.value) {
      query = [...query.filter(filter => filter.id !== 'event_created'), { id: 'event_created_from', value: created.value[0] }, { id: 'event_created_to', value: created.value[1] }];
    } else {
      query = [...query.filter(filter => filter.id !== 'event_created')];
    }
  }
  if (archived) {
    query = [...query.map(filter => filter.id === 'last_response_status_code' ? { ...filter, value: filter.value.filter(value => !value.includes('archived')) } : filter), { id: 'archived', value: 'true' }];
  }
  if (multiple_status_code) {
    query = [...query.map(filter => filter.id === 'last_response_status_code' ? { ...filter, value: filter.value.filter(value => !value.includes(',')) } : filter), ...multiple_status_code.value[0].split(',').map(value => ({ id: 'last_response_status_code', value: value.trim() }))];
  }
  return { url: filters, query };
};

const formatSortBy = (sortBy) => {
  let query = sortBy;
  return { url: sortBy, query };
};

const WebhookEvents = ({ t, history, user, env, location, setAlert }) => {
  const [retry, setRetry] = useState({ show: false, data: undefined });
  const [triggers, setTriggers] = useState([]);
  const [disabledWebhooks, setDisabledWebhooks] = useState([]);
  const [hasDisabled, setHasDisabled] = useState(false);
  const [hasRetry, setHasRetry] = useState(false);
  const tableRef = useRef({});

  const fetchDisabledWebhooks = async () => {
    try {
      const response = await webhooksApi.fetchDisabledWebhooks(user.currentTeam.id);
      setDisabledWebhooks(response.endpoints.map(endpoint => endpoint.uuid));
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const resendWebhookEvent = async (values) => {
    try {
      const response = await webhooksApi.resendWebhookEvent(values, user.currentTeam.id);
      setAlert({ message: response.message, variant: 'success' });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const columns = useMemo(() => [
    {
      id: 'event_created',
      Header: t('common:labels.created'),
      accessor: 'event_created',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => <span className="text-nowrap">{formatDateAndTime(value)}</span>,
      columnStyles: () => ({ width: '20%' })
    },
    {
      id: 'webhook_endpoint_name',
      Header: t('webhooks.events.list.labels.name'),
      accessor: 'webhook_endpoint_name',
      disableSortBy: true,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value, row }) => <span style={{ maxWidth: 160, minWidth: 160 }} className={`text-break d-inline-block position-relative${!row.original.webhook_endpoint_active && !row.original.webhook_endpoint_deleted_at ? ' text-warning pr-4' : row.original.webhook_endpoint_deleted_at ? ' text-info pr-4' : ''}`}>{value}{!row.original.webhook_endpoint_active && !row.original.webhook_endpoint_deleted_at && <i className="ml-2 fas fa-exclamation-triangle text-warning position-absolute mt-1"></i>}{row.original.webhook_endpoint_deleted_at && <i className="ml-2 fas fa-ban text-info position-absolute mt-1"></i>}</span>
    },
    {
      id: 'app_handle',
      Header: t('common:labels.app'),
      accessor: 'app_handle',
      disableSortBy: true,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>{value}</span>
    },
    {
      id: 'webhook_endpoint_active',
      Header: () => <>{t('webhooks.events.list.labels.active')}<TooltipButton placement="top" variant="link" text={t('webhooks.events.list.tips.active')} className="p-0 no-underline ml-2"><i className="sila-icon info text-lg"></i></TooltipButton></>,
      accessor: 'webhook_endpoint_active',
      filter: MultipleFilter,
      Filter: SelectColumnFilter,
      columnStyles: () => ({ width: '1%' }),
      Cell: ({ value, row }) => <span className={`text-${(row.original.webhook_endpoint_deleted_at || value === null) ? 'info' : value ? 'success' : 'warning'}`}>{t(`common:status.${row.original.webhook_endpoint_deleted_at ? 'deleted' : value === null ? 'unreported' : value ? 'active' : 'inactive'}`)}</span>,
      disableSortBy: true,
      disableFilters: true,
      filterOptions: () => [{
        label: t('common:status.active'),
        value: 'true'
      }, {
        label: t('common:status.inactive'),
        value: 'false'
      }]
    },
    {
      id: 'event_type',
      Header: t('webhooks.events.list.labels.event'),
      accessor: 'event_type',
      searchOptions: {
        disabled: true
      },
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      columnStyles: () => ({ width: '5%' }),
      Cell: ({ value }) => `/${value}`,
      disableSortBy: true,
      filterOptions: () => triggers.map(trigger => ({ label: trigger.label, value: trigger.name }))
    },
    {
      id: 'is_delivered',
      Header: t('webhooks.events.list.labels.delivered'),
      accessor: 'is_delivered',
      Filter: SelectColumnFilter,
      Cell: ({ value }) => capitalize(value),
      columnStyles: () => ({ width: '1%' }),
      disableSortBy: true,
      filterOptions: () => [{
        label: t('common:status.true'),
        value: 'true'
      }, {
        label: t('common:status.false'),
        value: 'false'
      }]
    },
    {
      id: 'last_response_status_code',
      Header: t('webhooks.events.list.labels.status_code'),
      accessor: 'last_response_status_code',
      searchOptions: {
        disabled: true
      },
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value, row }) => {
        const startsWith = value && parseInt(value.toString().charAt(0));
        return row.original.archived ? <span className="d-flex flex-nowrap"><span className="text-primary">{t('common:status.unsent')}</span><TooltipButton placement="top" variant="link" text={t('webhooks.events.send.description')} className="p-0 no-underline ml-3"><i className="sila-icon info text-lg"></i></TooltipButton></span> : value ? <span className={`text-${startsWith == 2 ? 'success' : (startsWith == 4 || startsWith == 5) ? 'warning' : 'primary'}`}>{value}</span> : null;
      },
      columnStyles: () => ({ width: '1%' }),
      disableSortBy: true,
      filterOptions: () => [{
        label: t('webhooks.events.list.filters.status_code.info'),
        value: '1XX'
      }, {
        label: t('webhooks.events.list.filters.status_code.success'),
        value: '2XX'
      }, {
        label: t('webhooks.events.list.filters.status_code.redirect'),
        value: '3XX'
      }, {
        label: t('webhooks.events.list.filters.status_code.error'),
        value: '4XX'
      }, {
        label: t('webhooks.events.list.filters.status_code.internalError'),
        value: '5XX'
      }, {
        label: t('common:status.unsent'),
        value: 'archived'
      }]
    },
    {
      id: 'retry',
      Header: () => <TooltipButton placement="top" variant="link" text={t('webhooks.events.troubleshootingTooltip')} className="p-0 no-underline ml-4"><i className="sila-icon info text-lg"></i></TooltipButton>,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableFilters: true,
      disableSortBy: true,
      columnStyles: () => ({ width: '1%' }),
      Cell: ({ row }) => hasPermissions(user, 'webhooks', 'Write') && (!row.original.is_delivered && !row.original.is_pending && !row.original.webhook_endpoint_deleted_at || row.original.archived) ? <Button block size="sm" className="retry text-center" onClick={() => setRetry({ show: true, data: row.original })}>{t(`common:buttons.${row.original.archived ? 'send' : 'retry'}`)}</Button> : null
    },
  ].filter(column => column.id === 'retry' && hasRetry || column.id === 'retry' && hasDisabled || column.id !== 'retry'), [triggers, user, hasRetry, hasDisabled]);

  useEffect(() => {
    fetchDisabledWebhooks();
  }, [env, user.currentTeam]);

  useEffect(() => {
    if (hasDisabled) setAlert({ message: t('webhooks.events.list.messages.disabled'), variant: 'danger', dismissable: true });
  }, [hasDisabled]);

  useEffect(() => {
    const getTriggers = async () => {
      try {
        const response = await webhooksApi.getTriggers();
        setTriggers(response.triggers);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    };
    getTriggers();
  }, []);

  return (
    <Container className="main-content-container d-flex flex-column flex-grow-1 p-3 p-md-5">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('webhooks.events.title')}>
          <Button variant="outline-light" className="back text-info" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasPermissions(user, 'webhooks', 'Read') ?
        <DataList
          innerRef={tableRef}
          slug={ITEM_SLUG}
          filterName={TABLE_SLUG}
          limit={TABLE_MAX_COUNT}
          fetchData={webhooksApi.fetchWebhookEvents}
          fetchOptions={{
            team: user.currentTeam.id
          }}
          columns={columns}
          onRowClick={(row, e) => {
            if (!e.target || (e.target.closest && !e.target.closest('.retry')) || !e.target.closest && !e.target.className.includes('retry')) {
              history.push(`/dev/webhook_event/${row.original.uuid}/detail`);
            } else {
              e.preventDefault();
            }
          }}
          onUpdate={({ data }) => {
            setHasDisabled(data.some(item => disabledWebhooks.some(uuid => uuid === item.webhook_endpoint_uuid)));
            setHasRetry(data.some(item => hasPermissions(user, 'webhooks', 'Write') && !item.is_delivered && !item.is_pending));
          }}
          onFilter={formatFilters}
          onSortBy={formatSortBy}
          hasPermissions={({ user }) => hasPermissions(user, 'webhooks', 'Read')}
          loadingMessage={t('webhooks.events.loading')}
          headerRightComponent={() => <a href={WEBHOOK_TROUBLESHOOTING_URL} className="ml-2 text-underline" target="_blank">{t('webhooks.events.troubleshooting')}</a>} />
        :
        <div className="position-relative"><DisabledOverlay /></div>}

      {retry.show &&
        <ConfirmModal
          show={retry.show}
          data={retry.data}
          title={t(`webhooks.events.${retry.data.archived ? 'send' : 'retry'}.confirm.title`, { name: retry.data.webhook_endpoint_name })}
          message={t(`webhooks.events.${retry.data.archived ? 'send' : 'retry'}.confirm.description`, { name: retry.data.webhook_endpoint_name })}
          buttonLabel={t(`common:buttons.${retry.data.archived ? 'send' : 'retry'}`)}
          onHide={(values) => {
            setRetry({ show: false, data: undefined });
            if (values && tableRef.current) {
              resendWebhookEvent(values);
              tableRef.current.setRefresh(true);
            }
          }} />}

    </Container>
  );
};

export default WebhookEvents;
