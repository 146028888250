import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { adminApi } from '../../api';
import { SUPERUSER_LEVEL } from '../../constants';
import { hasSuperuserAccess } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import OnboardingProfileForm from '../../components/admin/OnboardingProfileForm';
import OnboardingChecklistForm from '../../components/admin/OnboardingChecklistForm';
import OnboardingDocuments from '../../components/admin/OnboardingDocuments';
import CommentForm from '../../components/common/CommentForm';
import Loader from '../../components/common/Loader';

const OnboardingProfile = ({ t, match, location, history, user, setAlert }) => {
  const [key, setKey] = useState(match.params.action);
  const [comments, setComments] = useState(undefined);
  const [redirect, setRedirect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [owners, setOwners] = useState([]);
  const [details, setDetails] = useState({
    name: null,
    email: null,
    phone: null,
    contact_name: null,
    created: null,
    onboarding_status: null
  });
  const [initialValues, setInitialValues] = useState({
    payment_status: null,
    requested_products: [],
    third_party_partners: null,
    limited_production_access_date: null,
    std_production_access_date: null,
    start_date: null,
    due_date: null,
    sales_rep: null,
    business_description: null,
    website: null,
    terms: null,
    privacy_policy: null,
    ach_company_name: null,
    ach_company_id: null,
    deal_uuid: null,
    kyc_levels_used: null,
    mobile_apps: null,
    external_payment_providers: null,
    international_payment_providers: null,
    kyc_levels_approved: [],
    kyc_levels_requested: [],
    products_added: [],
    products_approved: [],
    business_license_number: null,
    ownership: null,
    platform_type: null,
    kyc_levels_list: [],
    payment_status_list: [],
    products_list: []
  });
  const sectionId = key && key === 'documents' ? 2 : key && key === 'checklists' ? 1 : 0;
  const hasWriteAccess = hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]);

  const getCustomerInfo = async () => {
    try {
      const response = await adminApi.getCustomerInfo(match.params.id);
      setDetails(response.details);
      setInitialValues(response.info);
      setOwners(response.owners);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setRedirect(true);
    } finally {
      setLoaded(true);
    }
  };

  const getComments = async () => {
    try {
      const response = await adminApi.getCustomerOnboardComments(match.params.id, sectionId);
      if (response.success) {
        setComments(response.comments.sort((a, b) => new Date(b.created) - new Date(a.created)));
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const handleCommentSubmit = async (values) => {
    if (hasWriteAccess && values) {
      try {
        await adminApi.createCustomerOnboardComment(match.params.id, sectionId, values);
        getComments();
        setAlert({ message: t('common:comments.success'), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    }
  };

  useEffect(() => {
    setKey(match.params.action);
  }, [match]);

  useEffect(() => {
    setComments(undefined);
    getComments();
  }, [key]);

  useEffect(() => {
    if (redirect || !hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE])) history.push('/admin/customers/onboarding');
  }, [redirect]);

  useEffect(() => {
    getCustomerInfo();
  }, []);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.customers.onboarding.profile.title')}>
          <Button variant="outline-light" className="back text-info" as={NavLink} to={{ pathname: '/admin/customers/onboarding', state: { from: location.pathname } }}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {!loaded ? <Loader /> : <Card>
        <Card.Header className="d-flex justify-content-between p-4">
          <h3 className="m-0">{details.name}</h3>
        </Card.Header>
        <Card.Body as={Row} className="p-0" noGutters>
          <Tab.Container activeKey={key} defaultActiveKey={key} onSelect={k => setKey(k)}>
            <Col md="4" lg="3" xl="2" className="border-right">
              <Nav className="d-flex flex-column align-content-start h-100 py-4">
                <Nav.Item className="w-100">
                  <Nav.Link className={`d-block px-4 py-3${key === 'profile' ? ' bg-primary-accent text-primary' : ''}`} eventKey="profile" as={NavLink} to={{ pathname: `/admin/customers/onboarding/${match.params.id}/profile`, state: { from: location.pathname } }}>{t('admin.customers.onboarding.navigation.profile')}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="w-100">
                  <Nav.Link className={`d-block px-4 py-3${key === 'checklists' ? ' bg-primary-accent text-primary' : ''}`} eventKey="checklists" as={NavLink} to={{ pathname: `/admin/customers/onboarding/${match.params.id}/checklists${match.params.checklist ? `/${match.params.checklist}` : ''}`, state: { from: location.pathname } }}>{t('admin.customers.onboarding.navigation.checklist')}</Nav.Link>
                </Nav.Item>
                <Nav.Item className="w-100">
                  <Nav.Link className={`d-block px-4 py-3${key === 'documents' ? ' bg-primary-accent text-primary' : ''}`} eventKey="documents" as={NavLink} to={{ pathname: `/admin/customers/onboarding/${match.params.id}/documents`, state: { from: location.pathname } }}>{t('common:upload.title')}</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md="8" lg="9" xl="10" className="p-4">
              <Tab.Content>
                {match.params.action === 'profile' && <Tab.Pane className="loaded" eventKey="profile">
                  <OnboardingProfileForm match={match} details={details} initialValues={initialValues} owners={owners} hasWriteAccess={hasWriteAccess} />
                </Tab.Pane>}
                {match.params.action === 'checklists' && <Tab.Pane className="loaded position-relative" eventKey="checklists" style={{ zIndex: 1 }}>
                  <OnboardingChecklistForm match={match} hasWriteAccess={hasWriteAccess} />
                </Tab.Pane>}
                {match.params.action === 'documents' && <Tab.Pane className="loaded" eventKey="documents">
                  <OnboardingDocuments match={match} hasWriteAccess={hasWriteAccess} />
                </Tab.Pane>}
                <CommentForm className="mt-5" onSubmit={handleCommentSubmit} comments={comments} hasWriteAccess={hasWriteAccess} />
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Card.Body>
      </Card>}

    </Container>
  );
};

export default OnboardingProfile;