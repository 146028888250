import React, { forwardRef, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { consoleApi } from '../../../api';

const CaptchaWrapper = forwardRef(({ handleChange, ...attrs }, ref) => {
  const [recaptchaKey, setRecaptchaKey] = useState('');
  
  const getKey = async () => {
      try {
        let response = await consoleApi.getRecaptchaKey();
        setRecaptchaKey(response.recaptcha_key);
      } catch (error) {
        throw error;
      }
  }
  // The empty dependency array means this useEffect will only run once, when the component mounts
  useEffect(() => {
    getKey();
  }, []); 

  return process.env.RECAPTCHA_SKIP !== 'True' && recaptchaKey ? (
    <ReCAPTCHA
      ref={ref}
      sitekey={recaptchaKey}
      onChange={handleChange}
      {...attrs} />
  ) : null;
});

CaptchaWrapper.propTypes = {
  forwardRef: PropTypes.shape({
    /**
     * The onChange event.
     */
    handleChange: PropTypes.func.required
  })
};

export default CaptchaWrapper;
