import React from 'react';
import PropTypes from 'prop-types';

const SilaLogo = ({ fill = '#00146B', width = 81, height = 36, className, ...rest }) => (
  <svg className={className || undefined} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M8.992 29.824v5.66C5.678 35.052 2.56 33.88.38 32.446a.842.842 0 0 1-.306-1.045L1.6 27.953a.833.833 0 0 1 1.212-.37c1.72 1.133 4.036 1.893 6.18 2.24ZM20.266 11.883A18.269 18.269 0 0 0 15.71 10.5V4.834A22.289 22.289 0 0 1 22.526 6.9a.84.84 0 0 1 .395 1.094l-1.536 3.47a.832.832 0 0 1-1.12.42ZM24.411 26.3c0 4.887-3.52 8-8.702 9.012V29.43c1.314-.546 1.867-1.419 1.867-2.505 0-1.188-.721-1.96-1.87-2.534-1.645-.823-4.171-1.234-6.714-1.863-4.384-1.095-8.81-2.844-8.81-8.52 0-4.922 3.57-8.044 8.81-9.034v5.861c-1.389.54-1.975 1.434-1.975 2.546 0 1.225.767 2.006 1.976 2.585 1.674.801 4.191 1.208 6.713 1.842 4.35 1.09 8.705 2.861 8.705 8.492ZM56.452 33.966a.827.827 0 0 1-.481 1.133 10.951 10.951 0 0 1-3.554.577c-6.084 0-8.601-3.566-8.601-8.6V5.886a.838.838 0 0 1 .839-.839h5.035a.84.84 0 0 1 .839.84v21.187c0 1.888.92 3.147 3.146 3.147.155 0 .31-.01.463-.029a.831.831 0 0 1 .853.493l1.46 3.28ZM80.735 21.727c-.04-5.636-3.326-10.385-11.068-10.385-2.653 0-6.128 1.165-8.684 2.813a.907.907 0 0 0-.325 1.107l1.35 3.232a.796.796 0 0 0 1.155.402c1.571-.97 3.714-1.873 5.68-1.873 4.024 0 5.334 2.314 5.334 4.808H68.93c-7.044 0-9.922 2.966-9.922 6.88 0 3.91 3.113 6.965 8.63 6.965 2.74 0 5.182-.705 6.948-2.765h.001v1.506a.838.838 0 0 0 .84.84h4.47a.84.84 0 0 0 .84-.84v-12.69h-.002Zm-11.477 9.469c-2.258 0-3.692-1.016-3.692-2.648 0-1.627 1.153-2.757 4.103-2.757h4.917c0 4.436-2.938 5.405-5.328 5.405ZM36.84 11.761h-6.188a.262.262 0 0 0-.262.263v22.97c0 .145.117.263.262.263h6.189a.262.262 0 0 0 .262-.263v-22.97a.262.262 0 0 0-.262-.263ZM33.746 7.552a3.776 3.776 0 1 0 0-7.552 3.776 3.776 0 0 0 0 7.552Z" fill={fill} fillRule="evenodd" />
  </svg>
);

SilaLogo.propTypes = {
  /**
   * The SVG fill color value.
   */
  fill: PropTypes.string,
  /**
   * The SVG width
   */
  width: PropTypes.string,
  /**
   * The SVG height
   */
  height: PropTypes.string,
  /**
   * The classes applied to the SVG DOM element.
   */
  className: PropTypes.any
};

export default SilaLogo;
