import { useEffect, useRef, useCallback } from 'react';

const useInterval = (callback, timeout) => {
  const savedCallback = useRef(callback);

  const handler = useCallback(
    () => savedCallback.current(new Date().getTime()),
    []
  );

  // Store latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up/teardown the interval
  useEffect(() => {
    if (timeout) {
      const id = window.setInterval(handler, timeout);
      return () => window.clearInterval(id);
    }
  }, [handler, timeout]);
}

export default useInterval;
