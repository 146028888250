import React, { useState, useEffect } from 'react';
import { Container, Col } from 'react-bootstrap';

import { authApi } from '../api';

import LoaderButton from '../components/common/LoaderButton';

import { TYPEFORM_REGISTER_QUESTIONNAIRE_FORM, SIGNUP_STEP_COMPLETED } from '../constants';

const QuestionnaireFlow = ({ t, location, history, app, updateApp, setAlert }) => {
  const [isSkipping, setIsSkipping] = useState(false);
  const skip = async (isSkipped) => {
    try {
      if (isSkipped) {
        setIsSkipping(true);
      }
      await authApi.updateSignUpStep({ signup_step_completed: SIGNUP_STEP_COMPLETED.STEP3 });
      updateApp({ registration: { inProgress: false } });
      history.push({ pathname: '/welcome', state: { from: location.pathname } });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (!app.registration.inProgress) {
      history.push({ pathname: '/welcome', state: { from: location.pathname } });
    } else {
      const script = document.createElement('script');
      script.src = "https://embed.typeform.com/embed.js";
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        typeformEmbed.makeWidget(
          document.getElementById('questionnaire-form'),
          TYPEFORM_REGISTER_QUESTIONNAIRE_FORM + '?email=' + app.registration.email + '&company_name=' + app.registration.company_name,
          {
            hideHeaders: true,
            hideFooter: true,
            onSubmit: function () {
              skip(false);
            }
          }
        );
      });
    }
  }, []);
  
  return (
    <Container className="register main-content-container p-0">
      <Col className="p-0">
        <div id="questionnaire-form" className="questionnaire-form" style={{ height: 720 }}></div>
        <div className="questionnaire-btns btn-group position-absolute">
          <LoaderButton className="questionnaire-action m-2" variant="outline-primary" size="sm" type="button" onClick={() => skip(true)} loading={isSkipping} loadingLabel={t('common:status.skipping')}>{t('register.buttons.skip')}</LoaderButton>
        </div>
      </Col>
    </Container>
  );
};

export default QuestionnaireFlow;