import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

import { setUniqueCookie } from '../utils';
import { authApi } from '../api';

import { useAuth } from '../components/context';

const ConfirmAccount = ({ t, user, match, location, setAlert }) => {
  const { onUpdateUser } = useAuth();

  const confirmRegistration = async () => {
    try {
      await authApi.confirmRegistration(match.params.token, user);
      if (user) {
        onUpdateUser({ confirmed_at: Math.trunc(new Date(new Date()).getTime() / 1000) });
        setUniqueCookie('sila_console_confirmed', user.entity.handle);
        setAlert({ message: t('account.confirm.user'), variant: 'success' });
      } else {
        setAlert({ message: t('account.confirm.guest'), variant: 'success' });
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (user && !user.confirmed_at || !user) confirmRegistration();
  }, []);

  return <Redirect to={user ? { pathname: location.state && location.state.from ? location.state.from : '/' } : { pathname: '/login' }} />;
};

export default ConfirmAccount;