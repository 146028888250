import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Dropdown, DropdownButton, Form, Button, Row, Col } from 'react-bootstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import stickybits from 'stickybits';
import PropTypes from 'prop-types';

import TableFilter from './TableFilter';
import TableGlobalFilter from './TableGlobalFilter';
import AddFilterModal from '../AddFilterModal';
import TooltipButton from '../../common/TooltipButton';
import { DefaultColumnFilter, GlobalFilter } from './TableFilters';

import { useApp } from '../../context';

const EXPANDED_MAX_ROW_COUNT = 8;

const TableContainer = ({ filterName, columns, enableManualFilters, enableGlobalFilter, enableGlobalFilterSmall, enableExpanded, enableFilterMenu, disableFilterMenu, data, lastItem, tableFilters, setTableFilters, activeFilter, setActiveFilter, onRowClick, onRowEnter, onRowLeave, containerClasses, rowClasses, headerClasses, cellClasses, subHeaderComponent, headerLeftComponent, headerRightComponent, renderRowSubComponent, renderCellComponent, renderEmptyComponent, renderHoverComponent, globalFilterPlaceholder, columnSticky, ...rest }) => {
  const { t } = useTranslation();
  const { app, setAppData } = useApp();
  const expandedRef = useRef([]);
  const history = useHistory();

  const [expanded, setExpanded] = useState(true);
  const [expandedHeight, setExpandedHeight] = useState();
  const [hoveredRow, setHoveredRow] = useState(null);

  const { sortBy, filters, globalFilter } = tableFilters;
  const initialState = React.useMemo(() => {
    const state = { sortBy, filters };
    if (enableGlobalFilter) state.globalFilter = globalFilter;
    return state;
  });

  const instance = useTable(
    {
      columns,
      data,
      activeColumn: { Filter: DefaultColumnFilter },
      manualFilters: enableManualFilters,
      manualSortBy: enableManualFilters,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  );

  const {
    state,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    setAllFilters,
    setSortBy,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = instance;

  const [filterData, setFilterData] = useState(false);

  const selectFilter = (data) => {
    setActiveFilter(data);
  };

  const saveFilter = (data) => {
    if (data) data = { ...data, name: data.name.trim() };
    if (data && data.id) {
      setAppData({
        settings: {
          ...app.settings,
          filters: app.settings.filters.map(filter =>
            filter[filterName] && filter.id === data.id ? { ...filter, ...data } :
              filter[filterName] && data.active ?
                { ...filter, active: false } :
                filter)
        }
      }, () => {
        if (data.active) setActiveFilter(data);
      });
    } else if (data) {
      setAppData({
        settings: {
          ...app.settings,
          filters:
            [...app.settings.filters.map(filter => filter[filterName] ? { ...filter, active: data.active ? false : filter.active } : filter), {
              [filterName]: true,
              id: uuid(),
              ...data
            }]
        }
      }, () => {
        setActiveFilter(data);
      });
    }
    setFilterData(false);
  };

  const clearFilters = () => {
    if (activeFilter) setActiveFilter(false);
    setTableFilters({ filters: [], sortBy: [] });
    setAllFilters([]);
    setSortBy([]);
    history.push(history.location.pathname);
  };

  const deleteFilter = (id) => {
    setAppData({
      settings: {
        ...app.settings,
        filters: app.settings.filters.filter(filter => filter.id !== id)
      }
    }, () => {
      if (activeFilter && activeFilter.id === id) {
        clearFilters();
      }
      setFilterData(false);
    });
  };

  const handleSelectFilter = (e, filter) => {
    if (e.target.closest && !e.target.closest('.edit')) {
      selectFilter(filter);
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (activeFilter) setTableFilters({ filters: activeFilter.filters, sortBy: activeFilter.sortBy });
  }, [activeFilter]);

  useEffect(() => {
    setTableFilters({ sortBy: state.sortBy, filters: state.filters, globalFilter: enableGlobalFilter ? state.globalFilter : null });
  }, [state]);

  useEffect(() => {
    if (enableExpanded || columnSticky) {
      const arrayList = [];
      const lists = expandedRef.current;
      if (rows.length > EXPANDED_MAX_ROW_COUNT && lists && lists.length) {
        for (let i = 0; i < EXPANDED_MAX_ROW_COUNT; i++) {
          if (lists[i]) {
            var eachList = lists[i].clientHeight;
            arrayList.push(eachList);
            setExpandedHeight(arrayList.reduce((a, b) => a + b, 0) - 20);
          }
        }
      }
    }
  }, [rows, expandedRef.current, enableExpanded, columnSticky]);

  useEffect(() => {
    if (!expanded || columnSticky) stickybits('.table-sticky thead th', { useStickyClasses: true });
  }, [expanded]);

  return (<>
    <Card className={classNames('loaded flex-grow-1', containerClasses && containerClasses)}>
      <Card.Header className="p-4 d-block d-lg-flex align-items-center justify-content-between">
        <div className="mr-auto d-block d-lg-flex align-items-center">
          {enableExpanded && rows.length > EXPANDED_MAX_ROW_COUNT && <TooltipButton placement="top" variant="link" text={t(`common:buttons.${expanded ? 'collapse' : 'expand'}`)} className="p-0 cursor-pointer no-underline loaded" onClick={() => setExpanded(!expanded)} popoverProps={{ style: { textAlign: 'center' } }}><i className={`sila-icon text-lg text-${expanded ? 'primary collapsed' : 'info expanded'}`}></i></TooltipButton>}
          {enableGlobalFilter && data.length !== 0 && <GlobalFilter
            style={{ minWidth: 300 }}
            size={enableGlobalFilterSmall ? 'sm' : undefined}
            className={classNames('w-100', enableExpanded && rows.length > EXPANDED_MAX_ROW_COUNT && 'ml-4')}
            placeholder={globalFilterPlaceholder}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter} />}
          {filterName && <><Form.Label
            className="mr-3 mb-0 text-uppercase text-sm text-nowrap"
            htmlFor="views-filter">{t('common:filters.views.header')}</Form.Label>
            <DropdownButton id="views-filter"
              className="text-nowrap ml-0 mt-2 ml-lg-2 mt-lg-0 w-auto"
              bsPrefix="btn btn-outline-light btn-block"
              variant="outline-light"
              disabled={app.settings.filters.filter(filter => filter[filterName]).length === 0}
              title={activeFilter && app.settings.filters.filter(filter => filter[filterName]).length ? activeFilter.name : t('common:filters.views.all')}>
              {app.settings.filters.filter(filter => filter[filterName]).map((filter, index) =>
                <Dropdown.Item key={index} style={{ minWidth: 200 }} onClick={(e) => handleSelectFilter(e, filter)} className={`d-flex align-items-center${activeFilter && filter.id === activeFilter.id ? ' active' : ''}`}>
                  <span className="text-sm">{filter.name}</span>
                  <Button size="sm" variant="link" className="p-0 ml-auto edit" onClick={() => { setFilterData(filter); }}><i className={`ml-3 fas fa-pen ${activeFilter && filter.id === activeFilter.id ? 'text-white' : 'text-primary'}`}></i></Button>
                </Dropdown.Item>)}
              {activeFilter && <Dropdown.Item onClick={clearFilters}><span className="text-sm">{t('common:filters.views.all')}</span></Dropdown.Item>}
            </DropdownButton>
          </>}
          {headerLeftComponent && <div className={filterName ? 'ml-0 mt-2 ml-lg-3 mt-lg-0' : undefined}>{headerLeftComponent(rows.map(row => row.values))}</div>}
          <div className="d-flex align-items-top align-items-lg-center">
            <div className="d-block d-lg-flex text-nowrap">
              {filterName && ((!activeFilter && (filters.length !== 0 || sortBy.length !== 0)) || (activeFilter && (JSON.stringify(activeFilter.filters) !== JSON.stringify(filters) || JSON.stringify(activeFilter.sortBy) !== JSON.stringify(sortBy)))) &&
                <Button size="sm" variant="link p-0 text-underline d-block d-lg-inline-block ml-0 ml-lg-3 mt-2 mt-lg-0 loaded" onClick={() => { setFilterData({ filters, sortBy }); }}>{t('common:filters.buttons.create')}</Button>}
              {(filters.length !== 0 || sortBy.length !== 0) &&
                <Button size="sm" onClick={clearFilters} variant="link" className="p-0 text-underline text-info d-block d-lg-inline-block mt-2 ml-0 mt-lg-0 ml-lg-3 loaded">{t('common:filters.buttons.clear')}</Button>}
            </div>
          </div>
        </div>
        <div className="ml-auto d-block d-lg-flex align-items-center">
          {headerRightComponent && <div className="ml-0 mt-2 ml-lg-3 mt-lg-0">{headerRightComponent(rows.map(row => row.values))}</div>}
          {enableFilterMenu && <div className="ml-0 mt-2 ml-lg-3 mt-lg-0"><TableGlobalFilter headerGroups={headerGroups} tableFilters={tableFilters} setAllFilters={setAllFilters} clearFilters={clearFilters} /></div>}
        </div>
      </Card.Header>
      {subHeaderComponent && <Card.Header className="p-4 dropdown-z-index">{subHeaderComponent(rows)}</Card.Header>}
      <Card.Body className="p-0 d-flex flex-column">
        <div className={classNames('d-flex flex-grow-1', !expanded || columnSticky && 'overflow-auto custom-scrollbar')} style={!expanded || columnSticky ? { maxHeight: expandedHeight } : undefined}>
          <Table hover responsive {...getTableProps([
            {
              className: classNames(!expanded || columnSticky && 'table-sticky')
            }
          ])} {...rest}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.filter(header => !header.hideColumn).map((column, index) => (
                    <th key={index} {...column.getHeaderProps([
                      {
                        className: classNames(
                          headerClasses ? headerClasses({ headerGroup, column, index }) : [
                            'align-middle',
                            'py-2',
                            index === 0 && 'pl-4',
                            index === headerGroup.headers.length - 1 && 'pr-4',
                            index !== 0 && headerGroup.headers.length !== headerGroup.headers.length && 'px-3',
                          ]
                        ),
                        style: column.columnStyles ? column.columnStyles({ headerGroup, column }) : null,
                      }
                    ])}>
                      <TableFilter key={index} index={index} column={column} filters={filters} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length !== 0 ? rows.map((row) => {
                prepareRow(row);
                return (<tr
                  key={row.getRowProps().key}
                  {...row.getRowProps([
                    {
                      className: classNames(
                        row.index === hoveredRow && 'hover',
                        row.index === 0 && 'first',
                        row.index === (rows.length - 1) && 'last',
                        rowClasses && rowClasses({ row, length: rows.length })
                      )
                    }
                  ])}
                  onMouseEnter={(e) => { onRowEnter && onRowEnter(row, e); setHoveredRow(row.index); row.hover = true; }}
                  onMouseLeave={(e) => { onRowLeave && onRowLeave(row, e); setHoveredRow(null); row.hover = false; }}
                  onClick={onRowClick ? (e) => onRowClick(row, e) : undefined}
                  ref={(el) => lastItem && rows[rows.length - 1].index === parseInt(row.getRowProps().key.replace('row_', '')) ? lastItem(el) : expandedRef.current.splice(row.index, 1, el)}>
                  {row.cells.filter(cell => cell.column.hideCell !== undefined && cell.column.hideCell({ ...cell }) !== true || !cell.column.hideCell && !cell.column.hideColumn).map((cell, index) => <td key={index} {...cell.getCellProps([
                    {
                      className: classNames(
                        cellClasses ? cellClasses({ ...cell, index, length: rows.length }) : [
                          'py-3',
                          index === 0 && 'pl-4',
                          index === cell.row.cells.length - 1 && 'pr-4',
                          index !== 0 && index !== cell.row.cells.length && 'px-3',
                          index % 2 !== 0 && hoveredRow !== row, index && 'bg-primary-light',
                          index % 2 == 0 && hoveredRow !== row.index && 'bg-white'
                        ],
                        cell.column.cellClasses && cell.column.cellClasses({ ...cell, index, length: rows.length })
                      ),
                      colSpan: cell.column.colSpan ? cell.column.colSpan({ ...cell, index }) : null,
                      style: cell.column.columnStyles ? cell.column.columnStyles({ ...cell, index }) : null,
                    }
                  ])}>{renderCellComponent ? renderCellComponent({ ...cell, index, length: rows.length }) : cell.render('Cell')}</td>)}
                  {renderHoverComponent && row.index === hoveredRow && renderHoverComponent({ row })}
                </tr>);
              }) : <tr className="disabled">
                <td className="p-0 bg-white border-0 cursor-default" colSpan={visibleColumns.length}>
                  {renderEmptyComponent ? renderEmptyComponent({ headerGroups, tableFilters, setAllFilters, globalFilter: enableGlobalFilter ? state.globalFilter : null }) : <p className="text-center text-info font-italic mx-4 my-5">{t('common:form.messages.empty')}</p>}
                </td>
              </tr>}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>

    {filterData && <AddFilterModal
      show={filterData && Object.keys(filterData).length !== 0}
      data={filterData}
      filters={app.settings.filters.filter(filter => filter[filterName]).map(filter => filter.name.toLowerCase())}
      onHide={saveFilter}
      onDelete={deleteFilter} />}

  </>);
};

TableContainer.propTypes = {
  /**
   * The boolean to enable / disable manual filters
   */
  enableManualFilters: PropTypes.bool,
  /**
 * The boolean to enable / disable global filter
 */
  enableGlobalFilter: PropTypes.bool,
  /**
 * The boolean to reduce the size of the global filter,
 */
  enableGlobalFilterSmall: PropTypes.bool,
  /**
 * The boolean to enable / disable expanded / collapse table
 */
  enableExpanded: PropTypes.bool,
  /**
 * The boolean to enable / disable the global filter menu
 */
  enableFilterMenu: PropTypes.bool,
  /**
   * The unique name for the table filter stored in local storage
   */
  filterName: PropTypes.string,
  /**
   * The boolean to toggle on client side or server side filtering & sorting
   */
  filterName: PropTypes.string,
  /**
   * The placeholder text for the global filter
   */
  globalFilterPlaceholder: PropTypes.string,
  /**
   * The table columns array
   */
  columns: PropTypes.array.isRequired,
  /**
   * The data array
   */
  data: PropTypes.array.isRequired,
  /**
   * The last item of the table for infinite scroll
   */
  lastItem: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The filter object of the table
   */
  tableFilters: PropTypes.object.isRequired,
  /**
  * The function to set the filters of the table
  */
  setTableFilters: PropTypes.func.isRequired,
  /**
  * The active filter object
  */
  activeFilter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  /**
  * The function to set the active filter object
  */
  setActiveFilter: PropTypes.func,
  /**
  * The function when a table row is clicked.  Row values are passed through the function.
  */
  onRowClick: PropTypes.func,
  /**
  * The function when a table row is hovered.  Row values are passed through the function.
  */
  onRowEnter: PropTypes.func,
  /**
  * The function when a table row is hovered off.  Row values are passed through the function.
  */
  onRowLeave: PropTypes.func,
  /**
* The classes applied to a table container <Card>
*/
  containerClasses: PropTypes.string,
  /**
* The classes applied to a table row
*/
  rowClasses: PropTypes.func,
  /**
* The classes applied to a table header cells
*/
  headerClasses: PropTypes.func,
  /**
* The classes applied to a table cell
*/
  cellClasses: PropTypes.func,
  /**
  * The optional component to render in the table header on the left
  */
  headerLeftComponent: PropTypes.func,
  /**
  * The optional component to render in the table header on the right
  */
  headerRightComponent: PropTypes.func,
  /**
  * The optional component to render in under the table header
  */
  subHeaderComponent: PropTypes.func,
  /**
  * The function to render directly underneath a selected tablel row, requires isExpanded functinality. 
  */
  renderRowSubComponent: PropTypes.func,
  /**
  * The function to render the table cell.  Cell values are passed through the cuntion.
  */
  renderCellComponent: PropTypes.func,
  /**
  * The function to render empty results.
  */
  renderEmptyComponent: PropTypes.func,
  /**
  * The function to render an element on hover in the table row.
  */
  renderHoverComponent: PropTypes.func
};

TableContainer.defaultProps = {
  enableExpanded: false,
  enableManualFilters: true,
  enableGlobalFilter: false,
  enableGlobalFilterSmall: false,
  enableFilterMenu: true
};

export default TableContainer;
