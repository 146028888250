import React, { useEffect, useState } from 'react';
import { Container, Row, Button, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { adminApi } from '../../api';
import { hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import ChecklistTasks from '../../components/admin/ChecklistTasks';

const ChecklistTemplate = ({ t, match, history, user, env, setAlert }) => {
  const [initialValues, setInitialValues] = useState({
    name: '',
    tasks: [],
  });
  const [name, setName] = useState(initialValues.name);
  const [loaded, setLoaded] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const getChecklist = async (id) => {
    setLoaded(false);
    try {
      const response = await adminApi.getCustomerChecklistTemplate(id);
      if (!response.tasks) response.tasks = [];
      setInitialValues(response);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setRedirect(true);
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (match.params.id && match.params.id == 'new' && !hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE])) history.push('/admin/customers/checklists');
  }, []);

  useEffect(() => {
    if (redirect) history.push('/admin/customers/checklists');
  }, [redirect]);

  useEffect(() => {
    if (match.params.id && match.params.id !== 'new') {
      getChecklist(match.params.id);
    } else {
      setLoaded(true);
    }
  }, [env, user.currentTeam]);


  return (
    <Container className="create-checklist main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5 loaded">
        <PageTitle title={t('admin.customers.checklist.title')}>
          <Button variant="outline-light" className="back text-info" as={NavLink} exact={true} to="/admin/customers/checklists">{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {!loaded ? <Loader /> :
        <Card className="loaded flex-grow-1">
          <Card.Header className="d-block d-sm-flex justify-content-between align-content-stretch align-items-center p-4">
            <h3 className="m-0">{match.params.id !== 'new' ? (name || initialValues.name) : t('admin.customers.checklist.details.title')}</h3>
          </Card.Header>
          <Card.Body className="p-4 d-flex align-content-stretch flex-column">
            <ChecklistTasks
              user={user}
              initialValues={initialValues}
              setName={setName}
              getChecklist={getChecklist}
              match={match} />
          </Card.Body>
        </Card>}

    </Container>
  );
};

export default ChecklistTemplate;