import React, { useMemo, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDateAndTime } from '../../../utils';
import { teamApi } from '../../../api';
import { EMAIL_REG_EXP } from '../../../constants';

import DataList from '../DataList';
import UUIDSearchForm from '../UUIDSearchForm';
import ConfirmModal from '../../common/ConfirmModal';
import { DateRangeColumnFilter, CheckboxColumnFilter, MultipleFilter } from '../table/TableFilters';

const TABLE_SLUG = 'statements';
const TABLE_MAX_COUNT = 20;

const updateFilterId = (id) => {
  switch (id) {
    case 'to_email':
      return 'email';
    case 'sent_date':
      return 'sent';
    case 'user_handle':
      return 'handle';
    case 'user_name':
      return 'name';
    default:
      return id;
  };
}

const formatFilters = (filters) => {
  let query = filters;
  const sent_date = filters.find(filter => filter.id === 'sent_date');
  const email = filters.find(filter => filter.id === 'to_email');
  if (sent_date) {
    query = [...query.filter(filter => filter.id !== 'sent_date'), { id: 'start_date', value: sent_date.value[0] }, { id: 'end_date', value: sent_date.value[1] }];
  }
  if (email) {
    query = query.map(filter => filter.id === 'to_email' ? ({ ...filter, value: filter.value.length ? filter.value.map(val => val.term ? encodeURIComponent(val.term) : encodeURIComponent(val)) : encodeURIComponent(filter.value) }) : filter)
  }
  return { url: filters, query };
};

const formatSortBy = (sortBy) => {
  let query = sortBy.map(sort => ({ ...sort, id: updateFilterId(sort.id) }));
  return { url: query, query }
};

const StatementList = ({ setAlert, user, filterName, fetchData, fetchOptions, onRowClick, hasPermissions, history, customer }) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState({ show: false, type: '', data: undefined });
  const [hasResend, setHasResend] = useState(false);

  const columns = useMemo(() => [
    {
      id: 'sent_date',
      Header: t('common:transactions.statements.labels.sentDate'),
      accessor: 'sent_date',
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => formatDateAndTime(value)
    },
    {
      id: 'user_name',
      Header: t('common:transactions.statements.labels.endUserName'),
      accessor: 'user_name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value, row }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}><Button href={`/${customer ? 'settings' : 'admin'}/end_user/${row.original.user_handle}/profile`} variant="link" className="text-reset text-underline p-0 end-user-link">{value}</Button></span>
    },
    {
      id: 'user_handle',
      Header: t('common:transactions.statements.labels.endUserHandle'),
      accessor: 'user_handle',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value, row }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}><Button href={`/${customer ? 'settings' : 'admin'}/end_user/${row.original.user_handle}/profile`} variant="link" className="text-reset text-underline p-0 end-user-link">{value}</Button></span>
    },
    {
      id: 'to_email',
      Header: t('common:labels.emailAddress'),
      accessor: data => data.email,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value, row }) => <><span className={(value && !EMAIL_REG_EXP.test(value)) ? 'text-danger' : !value ? 'text-info' : ''}>{value ? value : t('common:labels.na')}</span>{(!value || (value && !EMAIL_REG_EXP.test(value))) && <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Popover id={`row-email-${row.original.id}`}>
          <Popover.Content>
            <p className="mb-2">{t(`common:transactions.statements.tips.${!value ? 'noEmail' : 'invalidEmail'}`)}</p>
          </Popover.Content>
        </Popover>}><i className="fas fa-exclamation-triangle text-warning text-lg ml-2"></i>
      </OverlayTrigger>}</>
    },
    {
      id: 'delivery_status',
      Header: t('common:transactions.statements.labels.deliveryStatus'),
      accessor: 'delivery_status',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      columnStyles: () => ({ width: '1%' }),
      Cell: ({ value }) => value ? <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}>{t(`common:status.${value.toLowerCase()}`)}</span> : <span className="text-info">{t('common:labels.na')}</span>,
      filterOptions: () => [{
        value: 'Success',
        label: t('common:status.success')
      }, {
        value: 'Failed',
        label: t('common:status.failed')
      }, {
        value: 'Unsent',
        label: t('common:status.unsent')
      }]
    },
    {
      id: 'identifier',
      Header: t('common:transactions.statements.labels.identifier'),
      accessor: 'identifier',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 100 }}>{value}</span>
    },
    {
      id: 'resend',
      Header: t('common:transactions.statements.labels.resend'),
      accessor: 'resend',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      columnStyles: () => ({ width: '1%' }),
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row }) => (!row.original.delivery_status || row.original.delivery_status && row.original.delivery_status.toLowerCase() !== 'success') ? <Button onClick={(e) => {
        e.stopPropagation();
        setConfirm({ ...confirm, show: true, type: !row.original.email ? 'noEmail' : row.original.is_email_update ? 'resend' : 'invalid', data: { name: row.original.user_name, email: row.original.email, user_handle: row.original.user_handle, statement_id: row.original.statement_id } });
      }} variant="primary" size="sm" type="button">{t(row.original.delivery_status && row.original.delivery_status.toLowerCase() === 'unsent' ? 'common:buttons.send' : 'common:transactions.statements.labels.resend')}</Button> : null
    },
  ].filter(column => column.id === 'resend' && hasResend || column.id !== 'resend'), [hasResend]);

  const handleResend = async (value) => {
    setConfirm({ show: false, type: '', data: undefined });
    if (value) {
      if (value.type === 'resend') {
        try {
          const response = await teamApi.reSendStatement(value.data.statement_id, user.currentTeam.id);
          setAlert({ message: t('settings.statements.resendMessage'), variant: 'success' });
        } catch (error) {
          setAlert({ message: error, variant: 'warning' });
        }
      } else {
        history.push(`/${customer ? 'settings' : 'admin'}/end_user/${value.data.user_handle}/profile`);
      }
    }
  };

  return (<>
    <DataList
      filterName={filterName}
      slug={TABLE_SLUG}
      limit={TABLE_MAX_COUNT}
      fetchData={fetchData}
      fetchOptions={fetchOptions}
      columns={columns}
      headerLeftComponent={() => <UUIDSearchForm onSubmit={onRowClick} htmlSize={50} placeholder={t('common:transactions.statements.labels.uuidPlaceholder')} />}
      onRowClick={onRowClick}
      onFilter={formatFilters}
      onSortBy={formatSortBy}
      onUpdate={({ data }) => {
        setHasResend(data.some(item => !item.delivery_status || item.delivery_status && item.delivery_status.toLowerCase() !== 'success'));
      }}
      hasPermissions={hasPermissions}
      loadingMessage={t('common:transactions.statements.loading')} />

    {confirm.show && <ConfirmModal
      show={confirm.show}
      isHide={true}
      title={t(`common:transactions.statements.modal.${confirm.type}.title`)}
      renderMessageComponent={() => <>
        <p className="text-lg mb-4 text-muted">{t(`common:transactions.statements.modal.${confirm.type}.description`, { name: confirm.data.name, email: confirm.data.email })}</p>
      </>}
      buttonLabel={t(`common:buttons.${confirm.type === 'resend' ? 'sendEmail' : 'updateEmail'}`)}
      data={confirm}
      onHide={handleResend} />}
  </>)
};

StatementList.propTypes = {
  /**
   * The customer boolean to determine the view for customer or admin
   */
  customer: PropTypes.bool,
  /**
   * The unique item slug for the data list
   */
  filterName: PropTypes.string,
  /**
   * The fetch data function
   */
  fetchData: PropTypes.func.isRequired,
  /**
   * The fetch options object
   */
  fetchOptions: PropTypes.object.isRequired,
  /**
   * The click handler function trigger on a table row
   */
  onRowClick: PropTypes.func.isRequired,
  /**
   * The function to validate user permissions
   */
  hasPermissions: PropTypes.func.isRequired
};

export default StatementList;