import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { teamApi, appsApi } from '../../api';

import { handleResponseError, truncate } from '../../utils';
import TooltipButton from '../common/TooltipButton';
import ReturnRates from '../common/transactions/ReturnRates';

import { useAuth } from '../context';

const ReturnRatesBanner = ({ className }) => {
  const [rates, setRates] = useState(false);
  const [apps, setApps] = useState(false);
  const [app, setApp] = useState('all');
  const [refresh, setRefresh] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();

  const getReturnRates = async (apps) => {
    let request = apps.map(handle => teamApi.getReturnRates(user.currentTeam.id, [handle], handle));
    setRates(false);
    if (apps.length > 1) request = [teamApi.getReturnRates(user.currentTeam.id, false, 'all'), ...request];
    try {
      const response = await Promise.all(request);
      if (!response) {
        throw handleResponseError(response);
      }
      setRates(response.map(data => ({ handle: data.target_handle, ...data.calculated_percents })));
    } catch (error) {
      throw error;
    } finally {
      setRefresh(false);
    };
  };

  const fetchApps = async () => {
    setRates(false);
    setApps(false);
    try {
      const response = await appsApi.fetchApps({ team: user.currentTeam.id, limit: 5 });
      setApps(response.apps.map(app => app.handle));
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (refresh) getReturnRates(apps);

  }, [refresh]);

  useEffect(() => {
    if (rates) setApp(rates.find(rate => parseFloat(rate.total_percent) === Math.max(...rates.map(rate => rate.total_percent))).handle);
  }, [rates]);

  useEffect(() => {
    if (apps && apps.length) getReturnRates(apps);
  }, [apps]);

  useEffect(() => {
    fetchApps();
  }, [user.currentTeam]);

  return apps.length ? (<Card className={`return-rates-banner loaded${className ? ` ${className}` : ''}`}>
    <Card.Header className="p-4">
      <ReturnRates rates={rates ? rates.find(rate => rate.handle === app) : undefined}
        headerLeftComponent={() => <>
          <Form.Label
            className="mr-0 mb-2 mb-lg-0 mr-lg-3 text-uppercase text-sm text-nowrap d-flex"
            htmlFor="apps-filter">
            {t('common:transactions.return_rates.view')}:
            <TooltipButton placement="top" variant="link" text={t('common:transactions.return_rates.description')} className="ml-2 d-block d-lg-none no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>
          </Form.Label>
          <Dropdown id="apps-filter">
            <Dropdown.Toggle block variant="outline-light" as={Button}
              className="text-nowrap ml-0 mt-2 ml-lg-2 mt-lg-0 w-100"
              disabled={!apps}>
              {!apps ? t('applications.loading') : app === 'all' ? t('common:applications.all') : truncate(app, 20)}
            </Dropdown.Toggle>
            {apps && apps.length > 1 && <Dropdown.Menu>
              <div className="overflow-auto custom-scrollbar" style={{ maxHeight: 200 }}>
                {app !== 'all' && <Dropdown.Item onClick={() => setApp('all')}>{t('common:applications.all')}</Dropdown.Item>}
                {apps.map((handle, index) =>
                  <Dropdown.Item key={index} onClick={() => setApp(handle)} className={`d-flex align-items-center${handle === app ? ' active' : ''}`}>{handle}</Dropdown.Item>)}
              </div>
            </Dropdown.Menu>}
          </Dropdown>
          <TooltipButton placement="top" variant="link" text={t('common:transactions.return_rates.description')} className="ml-3 d-none d-lg-block no-underline p-0"><i className="sila-icon info text-lg"></i></TooltipButton>
        </>}
        headerRightComponent={() => <TooltipButton placement="top" popoverProps={{ style: { textAlign: 'center' } }} variant="outline-light" disabled={!rates} onClick={() => setRefresh(true)} text={t('common:buttons.refresh')} className="btn-circle ml-3"><i className={`fas fa-sync-alt${refresh ? ' spin' : ''}`}></i></TooltipButton>} />
    </Card.Header>
  </Card>) : null;
};

ReturnRatesBanner.propTypes = {
  /**
   * The classes to be added to the DOM element that wraps the entire app
   */
  className: PropTypes.string
};

export default ReturnRatesBanner;