
import { hasPermissions } from '../utils';

export default [{
    name: 'settings.headers.account',
    items: [{
        title: 'settings.account.title',
        description: 'settings.account.description',
        to: '/team'
    }],
    permissions: (user) => hasPermissions(user, 'teams', 'Read'),
}, {
    name: 'settings.headers.members',
    items: [{
        title: 'settings.members.title',
        description: 'settings.members.description',
        to: '/members'
    }],
    permissions: (user) => hasPermissions(user, 'members', 'Read'),
}, {
    name: 'settings.headers.transactions',
    items: [
        {
            title: 'settings.transactions.title',
            description: 'settings.transactions.description',
            to: '/transactions'
        },
        {
            title: 'settings.requestsForReturn.title',
            description: 'settings.requestsForReturn.description',
            to: '/requests-return'
        },
        {
            title: 'settings.statements.title',
            description: 'settings.statements.description',
            to: '/statements'
        }
    ],
    permissions: (user) => hasPermissions(user, 'transactions', 'Read')
}, {
    name: 'settings.headers.endUsers',
    items: [
        {
            title: 'settings.endUsers.title',
            description: 'settings.endUsers.description',
            to: '/end_users'
        }
    ],
    permissions: (user) => hasPermissions(user, 'transactions', 'Read')
}]