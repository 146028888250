import React from 'react';
import Cookies from 'js-cookie';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { REDIRECT_ROUTES, SUPERUSER_LEVEL } from '../../constants';
import { hasSuperuserAccess } from '../../utils';

import { useAuth } from '../context';

const isAdminRoute = (routes, path) => routes.some(route => route.routes ? route.routes.some(subRoute => subRoute.admin && subRoute.path.includes(path)) : route.admin && route.path.includes(path));

const RedirectRoute = ({ from, routes, noRedirect }) => {
  const { user, authFrozen } = useAuth();
  const location = useLocation();
  const hasSuperUserAccess = user && hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS, SUPERUSER_LEVEL.ONBOARDING_READ_ONLY, SUPERUSER_LEVEL.ONBOARDING_READ_WRITE]);
  const redirect = (from && user && !hasSuperUserAccess && !isAdminRoute(routes, from) || from && user && hasSuperUserAccess && isAdminRoute(routes, from)) ? from : REDIRECT_ROUTES.USER_HOME;
  return <Redirect to={{ pathname: user ? redirect : authFrozen ? REDIRECT_ROUTES.USER_FROZEN : location.pathname === '/' && !Cookies.get('sila_console_registered') ? REDIRECT_ROUTES.GUEST_HOME : '/login', state: noRedirect ? undefined : { from: window.location.pathname + window.location.search } }} />
};

RedirectRoute.propTypes = {
  /**
   * The url to be redirected back to, if specified
   */
  from: PropTypes.string,
  /**
   * The routes array
   */
  routes: PropTypes.array,
  /**
   * The redirect boolean to force a redirect, or not
   */
  noRedirect: PropTypes.bool
};

export default RedirectRoute;