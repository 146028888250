import React, { useMemo } from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { formatDateAndTime, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import DataList from '../../components/common/DataList';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import {
  CheckboxColumnFilter,
  MultipleFilter,
  DateBetweenFilter,
  DateRangeColumnFilter,
} from '../../components/common/table/TableFilters';

const formatSortBy = (sortBy) => {
  const query = sortBy;
  return { url: sortBy, query };
};

function ColumnsTableCell({ value, t, isDate = false, showTime = true, canWrap = false }) {
  let displayedValue = value;
  let style = { whiteSpace: 'nowrap' };

  if (canWrap) {
    style = { width: 140, textWrap: true };
  }

  if (isDate && value) displayedValue = formatDateAndTime(value, showTime);

  return (
    <span className="text-break d-block" style={style}>
      {displayedValue || <span className="text-info">{t('common:labels.na')}</span>}
    </span>
  );
}

function MultiTextColumnsTableCell({ value, t }) {
  const isMultiLine = value && value.length > 0;
  let style = {};
  if (isMultiLine) style = { width: 140, textWrap: true };

  return (
    <span className="text-break d-block" style={style}>
      {isMultiLine ? (
        value.map((val) => (
          <span key={val} style={{ display: 'block' }}>
            {val},
          </span>
        ))
      ) : (
        <span className="text-info">{t('common:labels.na')}</span>
      )}
    </span>
  );
}

const formatLimitFilters = (filters) => {
  let query = filters;
  const beginsAt = filters.find((filter) => filter.id === 'begins_at');
  const expiresAt = filters.find((filter) => filter.id === 'expires_at');
  const amount = filters.find((filter) => filter.id === 'amount');
  const duration = filters.find((filter) => filter.id === 'duration');

  if (beginsAt) {
    if (beginsAt.value) {
      const [start, end] = beginsAt.value;
      const startUTC = new Date(start).toISOString();
      const endUTC = new Date(end).toISOString();

      query = [
        ...query.filter((filter) => filter.id !== 'begins_at'),
        { id: 'begins_at_start', value: startUTC },
        { id: 'begins_at_end', value: endUTC },
      ];
    }
  }
  if (expiresAt) {
    if (expiresAt.value) {
      const [start, end] = expiresAt.value;
      const startUTC = new Date(start).toISOString();
      const endUTC = new Date(end).toISOString();

      query = [
        ...query.filter((filter) => filter.id !== 'expires_at'),
        { id: 'expires_at_start', value: startUTC },
        { id: 'expires_at_end', value: endUTC },
      ];
    }
  }

  if (amount) {
    if (amount.value) {
      query = [
        ...query.filter((filter) => filter.id !== 'amount'),
        {
          id: 'amount',
          value: amount.value.map((val) => (val.term ? Number(val.term) * 100 : val * 100)),
        },
      ];
    }
  }

  if (duration) {
    if (duration.value) {
      query = [
        ...query.filter((filter) => filter.id !== 'duration'),
        {
          id: 'duration',
          value: duration.value.map((val) =>
            val.term ? Number(val.term) * 24 * 60 * 60 : val * 24 * 60 * 60
          ),
        },
      ];
    }
  }

  return { url: filters, query };
};

export const generateColumns = (t, env, isLimitOverride = false) => {
  const limitColumns = [
    {
      id: 'name',
      Header: t('admin.limits.table.name'),
      accessor: 'name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} canWrap />,
    },
    {
      id: 'app_handle',
      Header: t('admin.limits.table.app_handle'),
      accessor: 'app_handle',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} canWrap />,
    },
    {
      id: 'provider_name',
      Header: t('admin.limits.table.provider_name'),
      accessor: 'provider_name',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} />,
    },
    {
      id: 'transaction_graph_label',
      Header: t('admin.limits.table.transaction_graph_labels'),
      accessor: 'transaction_graph_labels',
      disableSortBy: true,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <MultiTextColumnsTableCell value={value} t={t} />,
    },
    {
      id: 'user_category',
      Header: t('admin.limits.table.user_category'),
      accessor: 'user_category',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} />,
    },
    {
      id: 'limit_type',
      Header: t('admin.limits.table.limit_type'),
      accessor: 'limit_type',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} />,
    },
    {
      id: 'amount',
      Header: t('admin.limits.table.amount'),
      accessor: 'amount',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} />,
    },
    {
      id: 'duration',
      Header: t('admin.limits.table.duration'),
      accessor: 'duration',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} />,
    },
    {
      id: 'begins_at',
      Header: t('admin.limits.table.begins_at'),
      accessor: 'begins_at',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} isDate showTime={false} />,
    },
  ];

  if (isLimitOverride) {
    limitColumns.splice(1, 0, {
      id: 'expires_after_count',
      Header: t('admin.limits.table.expires_after_count'),
      accessor: 'expires_after_count',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} />,
    });
    limitColumns.splice(2, 0, {
      id: 'expires_at',
      Header: t('admin.limits.table.expires_at'),
      accessor: 'expires_at',
      filter: DateBetweenFilter,
      Filter: DateRangeColumnFilter,
      Cell: ({ value }) => <ColumnsTableCell value={value} t={t} isDate showTime={false} />,
    });
  }
  return useMemo(() => limitColumns, [env]);
};

export function LimitsTable({
  t,
  history,
  user,
  makeNew,
  onRowClick,
  pageTitle,
  columns,
  fetchData,
  isLimitOverride = false,
}) {
  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={pageTitle}>
          <Button variant="primary" onClick={makeNew} className="mr-3">
            {isLimitOverride
              ? t('admin.limits.createOverride.button')
              : t('admin.limits.createLimit.button')}
            <i className="fas fa-plus-circle ml-2" />
          </Button>
          <Button
            variant="outline-light"
            className="back text-info"
            onClick={() => history.goBack()}
          >
            {t('common:buttons.back')}
          </Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]) ? (
        <DataList
          slug="limits"
          limit={20}
          fetchData={fetchData}
          columns={columns}
          onRowClick={onRowClick}
          onFilter={formatLimitFilters}
          filterName={isLimitOverride ? 'admin_limit_override' : 'admin_limits'}
          onSortBy={formatSortBy}
          // eslint-disable-next-line no-shadow
          hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])}
          loadingMessage={t(
            `admin.limits.${isLimitOverride ? 'loadingLimitOverride' : 'loadingLimit'}`
          )}
          fetchOptions={{ team: user.currentTeam.id }}
          onFiltered={({ tableFilters, setData, setLoaded, setRefresh }) => {
            if (tableFilters.filters.length || tableFilters.sortBy.length) {
              setRefresh(true);
            } else {
              setData([]);
              setRefresh(true);
              setLoaded(true);
            }
          }}
          renderEmptyComponent={({ tableFilters }) =>
            tableFilters.filters.length === 0 && tableFilters.sortBy.length === 0 ? (
              <>
                <p className="text-lg text-center mt-5">{t('common:filters.empty.title')}</p>
                <p className="text-meta text-center mb-5">
                  {t('common:filters.empty.description')}
                </p>
              </>
            ) : (
              <p className="text-meta text-center mx-4 my-5">{t('common:form.messages.empty')}</p>
            )
          }
        />
      ) : (
        <div className="position-relative">
          <DisabledOverlay />
        </div>
      )}
    </Container>
  );
}
