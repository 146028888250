import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const loadPathPrefix = (process.env.NODE_ENV && process.env.NODE_ENV === 'production') ?
  '/static' : 'http://localhost:3000/assets/bundles';

const detectionOptions = {
  order: ['cookie', 'navigator', 'localStorage', 'queryString', 'htmlTag'],
  lookupFromPathIndex: 0
}

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['app', 'common'],
    detection: detectionOptions,
    defaultNS: 'app',
    load: 'languageOnly',
    debug: (process.env.NODE_ENV && process.env.NODE_ENV === 'production') ? false : true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${loadPathPrefix}/locales/{{lng}}-{{ns}}.json?v=${process.env.COMMIT_HASH}`,
      crossDomain: (process.env.NODE_ENV && process.env.NODE_ENV === 'production') ? false : true,
    }
  });

export default i18n;
