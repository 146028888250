import React, { useState, useRef, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Redirect, NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import * as yup from 'yup';

import { useAuth } from '../components/context';

import { authApi } from '../api';

import LoaderButton from '../components/common/LoaderButton';
import FieldWrapper from '../components/form/wrappers/Field';
import LockoutModal from '../components/auth/LockoutModal';
import CaptchaWrapper from '../components/form/wrappers/Captcha';

import { SIGNUP_STEP_COMPLETED, HANDLE_EMAIL_REG_EXP, COOKIES_OPTIONS } from '../constants';

const Login = ({ user, location, history, setAlert, updateApp }) => {
  const [usernameOptions, setUsernameOptions] = useState([]);
  const [passwordValue, setPasswordValue] = useState('');
  const [page, setPage] = useState('email');
  const [redirectToPreviousRoute, setRedirectToPreviousRoute] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(process.env.RECAPTCHA_SKIP === 'True');
  const [show, setShow] = useState(false);
  const [locked, setLocked] = useState({ until: '', message: '' });
  const { onLogin } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };
  const { t } = useTranslation();
  const handleRef = useRef(null);
  const passwordRef = useRef(null);
  const recaptchaRef = useRef(null);

  const handleClose = () => {
    setShow(false);
  };

  const handleCancel = (resetForm, setSubmitting) => {
    if (process.env.RECAPTCHA_SKIP !== 'True') setRecaptchaToken(false);
    setUsernameOptions([]);
    setPasswordValue('');
    setPage('email');
    resetForm();
    setSubmitting(false);
  };

  const initialValues = {
    handle: location.state && location.state.handle ? location.state.handle : '',
    password: ''
  };

  const schema = yup.object().shape({
    handle: yup.string()
      .matches(HANDLE_EMAIL_REG_EXP, {
        message: t('common:form.fields.handle.error')
      })
      .required(t('common:form.errors.required', { field: t((page === 'username') ? 'common:form.fields.handle.select' : 'common:form.fields.handle.combo') })),
    password: (page === 'email') ? yup.string()
      .required(t('common:form.errors.required', { field: t('common:form.fields.password.label') })) : null
  });

  useEffect(() => {
    setTimeout(() => {
      if (location.state && location.state.handle && handleRef.current) passwordRef.current.focus();
    }, 1000);
  }, []);

  if (user || redirectToPreviousRoute) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <Container className="login main-content-container p-0">
        <h2 className="mb-3 mb-md-5">{t((page === 'email') ? 'login.title' : 'common:multipleAccounts.title')}</h2>
        {page === 'username' && <p className="mb-4 text-lg text-info">{t('common:multipleAccounts.description')}</p>}
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
            try {
              if (page === 'email') {
                setPasswordValue(values.password);
              } else {
                values = { ...values, password: passwordValue };
              }
              if (process.env.RECAPTCHA_SKIP !== 'True') {
                values = { ...values, ctoken: recaptchaToken };
              }
              const response = await authApi.authenticate(values);
              if (!response.success) {
                setUsernameOptions([]);
                setPasswordValue('');
                setPage('email');
                setSubmitting(false);
                if (process.env.RECAPTCHA_SKIP !== 'True') {
                  setRecaptchaToken(false);
                  recaptchaRef.current.reset();
                }
                if (response.locked) {
                  setLocked({ ...locked, until: response.locked.until, message: response.message });
                  resetForm({ values: initialValues });
                  setShow(true);
                } else {
                  if (response.message && (response.message.includes('locked') || response.message.includes('passphrase'))) {
                    passwordRef.current.focus();
                    resetForm({ values: { ...initialValues, handle: values.handle } });
                  } else {
                    handleRef.current.focus();
                    resetForm({ values: initialValues });
                  }
                  setAlert({ message: response.message.includes('locked') ? response.message : t('login.form.messages.error'), variant: 'warning' });
                }
              } else {
                if (response.total_count && response.total_count > 1) {
                  if (response.accounts.length > 0) {
                    setUsernameOptions(response.accounts.map(handle => ({
                      'label': handle,
                      'value': handle
                    })));
                  }
                  setFieldValue('handle', initialValues.handle, false);
                  setPage('username');
                } else {
                  if (response.user.sila_device_id) {
                    const currentYear = new Date();
                    const nextYear = new Date();
                    nextYear.setFullYear(currentYear.getFullYear() + 1);
                    Cookies.set('sila_console_device_id', response.user.sila_device_id, { ...COOKIES_OPTIONS, 'expires': nextYear });
                  }
                  if (response.user && response.user.mfa_required) {
                    setSubmitting(false);
                    resetForm({ values: initialValues });
                    updateApp({ registration: { handle: response.user.handle, email: response.user.email, company_name: response.user.company_name } });
                    setAlert({ message: response.message, variant: 'success' });
                    history.push({ pathname: '/login/mfa', state: { from: location.pathname } });
                  } else {
                    setAlert({ message: response.message, variant: 'success' });
                    onLogin(response);
                    setSubmitting(false);
                    resetForm({ values: initialValues });
                    if (response.user && response.user.signup_step_completed != undefined && response.user.signup_step_completed != '') {
                      if (response.user.signup_step_completed != SIGNUP_STEP_COMPLETED.STEP3) {
                        updateApp({ registration: { email: response.user.entity.emails[0]['email'], company_name: response.user.company.name, inProgress: true } });
                      }
                      if (response.user.signup_step_completed === SIGNUP_STEP_COMPLETED.STEP1) {
                        history.push({ pathname: '/register/invite_team_members', state: { from: location.pathname } });
                      } else if (response.user.signup_step_completed === SIGNUP_STEP_COMPLETED.STEP2) {
                        history.push({ pathname: '/register/questionnaire', state: { from: location.pathname } });
                      } else {
                        setRedirectToPreviousRoute(true);
                      }
                    } else {
                      setRedirectToPreviousRoute(true);
                    }
                  }
                }
              }
            } catch (error) {
              setAlert({ message: error, variant: 'warning' });
            }
          }}>
          {({ errors, handleSubmit, isSubmitting, resetForm, setSubmitting, dirty, setFieldValue }) => (
            <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
              {page === 'email' && <><Field required autofocus material data-hj-whitelist className="handle mb-4"
                id="loginForm.handle"
                label={t('common:form.fields.handle.combo')}
                name="handle"
                handleChange={(e) => setFieldValue(e.target.name, e.target.value.toLowerCase().trim())}
                fieldClass="data-hj-whitelist"
                inputRef={handleRef}
                component={FieldWrapper} />
                <Field required autofocus material className="password mb-5"
                  id="loginForm.password"
                  label={t('common:form.fields.password.label')}
                  name="password"
                  type="password"
                  inputRef={passwordRef}
                  append={<NavLink to="/forgot_password" className="forgot-password text-sm text-info">{t('login.forgotPassword')}</NavLink>}
                  component={FieldWrapper} />
                <CaptchaWrapper className="form-group my-5" ref={recaptchaRef} handleChange={(value) => setRecaptchaToken(value)} />
              </>}
              {page === 'username' && <Field required autofocus material data-hj-whitelist className="mb-5"
                id="loginForm.handle"
                name="handle"
                type="select"
                label={t('common:form.fields.handle.select')}
                options={usernameOptions}
                fieldClass="data-hj-whitelist"
                component={FieldWrapper} />}
              <div className="d-block d-md-flex align-items-center">
                <LoaderButton className="mr-5 d-sm-block" variant="primary" type="submit" loading={isSubmitting} loadingLabel={t('common:status.loggingin')} disabled={Object.keys(errors).length || !recaptchaToken || !dirty}>{t('common:buttons.login')}</LoaderButton>
                {page === 'email' && <p className="text-info mt-5 mb-0 mt-md-0 d-flex align-items-center">{t('login.signup.desc')}  <NavLink to="/register" className="ml-2 d-flex align-items-center no-underline flex-nowrap"><span className="lnk">{t('login.signup.action')}</span><i className="sila-icon long-arrow-right ml-2"></i></NavLink></p>}
                {page === 'username' && <Button variant="outline-light" onClick={() => handleCancel(resetForm, setSubmitting)}>{t('common:buttons.cancel')}</Button>}
              </div>
            </Form>
          )}
        </Formik>
      </Container>
      <LockoutModal locked={locked} recaptchaStatus={recaptchaToken} show={show} onHide={handleClose} />
    </>
  );
};

export default Login;