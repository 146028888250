import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import FieldWrapper from '../form/wrappers/Field';
import CaptchaWrapper from '../form/wrappers/Captcha';

import { authApi } from '../../api';

import { useDebounce } from '../../utils/hooks';
import { disAllowPassword } from '../../utils';

import { useAuth, useAlerts } from '../context';

const ConfirmPasswordForm = ({ usernameOptions, showUsername, userEmail, onSubmit }) => {
  const [status, setStatus] = useState({ pending: false, isValid: false });
  const [recaptchaToken, setRecaptchaToken] = useState(process.env.RECAPTCHA_SKIP === 'True');
  const [currentPassword, setCurrentPassword] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [password, setPassword] = useState('');
  const debouncedCurrentPassword = useDebounce(currentPassword, 750);
  const debouncedpassword = useDebounce(password, 750);
  const confirmPasswordRef = useRef(null);
  const formRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { setAlert } = useAlerts();
  const recaptchaRef = useRef(null);

  const initialValues = {
    user: user ? true : false,
    handle: '',
    currentPassword: '',
    password: '',
    confirmPassword: ''
  };

  const schema = yup.object().shape({
    handle: usernameOptions && usernameOptions.length ? yup.string()
      .required(t('common:form.errors.required', { field: t('common:form.fields.handle.select') })) : null,
    currentPassword: yup.string()
      .when('user', {
        is: true,
        then: yup.string()
          .required(t('common:form.errors.required', { field: t('common:form.fields.currentPassword.label') }))
          .test('confirmPassword', t('common:form.fields.currentPassword.error'), (value) => value && status.isValid)
      }),
    password: process.env.RECAPTCHA_SKIP === 'True' || (process.env.RECAPTCHA_SKIP !== 'True' && status.isValid) ? yup.string()
      .required(t('common:form.errors.required', { field: t('common:form.fields.password.label') }))
      .min(8, t('common:form.fields.password.minError'))
      .max(100, t('common:form.fields.password.maxError'))
      .test('checkValidPassword', t('common:form.fields.password.error') || t('common:status.validating'), (value) => isValidPassword && value) : null,
    confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], t('register.form.fields.confirmPassword.error'))
      .required(t('common:form.errors.required', { field: t('register.form.fields.confirmPassword.label') }))
  });

  const confirmPassword = async (value) => {
    if (value) {
      setStatus({ ...status, pending: true });
      try {
        let response;
        if (process.env.RECAPTCHA_SKIP !== 'True') {
          response = await authApi.confirmPassword(user.entity.handle, value, recaptchaToken);
        } else {
          response = await authApi.confirmPassword(user.entity.handle, value);
        }
        if (process.env.RECAPTCHA_SKIP !== 'True' && response.success) {
          setRecaptchaToken(false);
          recaptchaRef.current.reset();
        }
        setStatus({ ...status, isValid: response.success, pending: false });
      } catch (error) {
        setAlert({ message: error, variant: 'danger' });
        setStatus({ ...status, isValid: false, pending: false });
        if (process.env.RECAPTCHA_SKIP !== 'True') {
          setRecaptchaToken(false);
          recaptchaRef.current.reset();
        }
      } finally {
        formRef.current.setFieldTouched('currentPassword', true);
      }
    }
  }

  const checkValidPassword = async (value) => {
    if (value) {
      if (user) setIsValidPassword(disAllowPassword(value, user.entity.emails[0].email, user.entity.handle));
      else setIsValidPassword(disAllowPassword(value, userEmail));
    }
  };

  useEffect(() => {
    if (!status.isValid && recaptchaToken && debouncedCurrentPassword)
      confirmPassword(debouncedCurrentPassword);
  }, [recaptchaToken, debouncedCurrentPassword]);

  useEffect(() => {
    if (debouncedpassword) checkValidPassword(debouncedpassword);
  }, [debouncedpassword]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formRef}
      onSubmit={(values, form) => {
        if (process.env.RECAPTCHA_SKIP !== 'True') {
          values = { ...values, ctoken: recaptchaToken }
        }
        onSubmit(values, form);
      }}>
      {({ errors, handleSubmit, isSubmitting, isValid, touched }) => (
        <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
          <h2 className={!user ? "mb-3 mb-md-5" : "mb-3"}>{t(`resetPassword.title.${!user ? 'createPassword' : 'password'}`)}</h2>
          {user && <>
            {(process.env.RECAPTCHA_SKIP === 'True' ||  process.env.RECAPTCHA_SKIP !== 'True' &&  !status.isValid) && <Field required autofocus
              className="currentPassword"
              id="setPasswordForm.currentPassword"
              label={t('common:form.fields.currentPassword.label')}
              note={!status.isValid ? t('common:form.fields.currentPassword.description') : undefined}
              name="currentPassword"
              type="password"
              handleChange={e => setCurrentPassword(e.target.value)}
              isValid={status && status.isValid && !status.pending}
              append={status && status.pending ? <Loader size="sm" className="mr-3" /> : undefined}
              component={FieldWrapper} />}
            {(process.env.RECAPTCHA_SKIP === 'True' || (process.env.RECAPTCHA_SKIP !== 'True' && status.isValid)) && <><Field required autofocus showstrength
              className="password"
              id="setPasswordForm.password"
              label={t('common:form.fields.password.label')}
              name="password"
              type="password"
              handleChange={e => setPassword(e.target.value)}
              component={FieldWrapper} />
            <Field required autofocus
              className="confirmPassword"
              id="setPasswordForm.confirmPassword"
              label={t('register.form.fields.confirmPassword.label')}
              name="confirmPassword"
              type="password"
              inputRef={confirmPasswordRef}
              component={FieldWrapper} /></>}
          </>}
          {!user && <>
            {showUsername && <>
              <h4 className="mb-4 text-lg">{t('common:multipleAccounts.title')}</h4>
              <p className="mb-4 text-lg text-info">{t('common:multipleAccounts.description')}</p>
              <Field required autofocus material className="mb-4"
                id="setPasswordForm.handle"
                name="handle"
                type="select"
                label={t('common:form.fields.handle.select')}
                options={usernameOptions}
                component={FieldWrapper} />
            </>}
            <Field required autofocus showstrength material
              className="password mb-4"
              id="setPasswordForm.password"
              label={t('common:form.fields.password.label')}
              name="password"
              type="password"
              handleChange={e => setPassword(e.target.value)}
              component={FieldWrapper} />
            <Field required autofocus material
              className="confirmPassword mb-4"
              id="setPasswordForm.confirmPassword"
              label={t('register.form.fields.confirmPassword.label')}
              name="confirmPassword"
              type="password"
              component={FieldWrapper} /></>}
          <CaptchaWrapper ref={recaptchaRef} className="form-group my-5" handleChange={(value) => setRecaptchaToken(value)} />
          <div className="d-flex">
            <LoaderButton className="mr-3" variant="primary" type="submit" loading={isSubmitting} disabled={Object.keys(errors).length || !isValid || !recaptchaToken || !Object.keys(touched).length}>{t(!user ? 'common:buttons.reset' : 'resetPassword.form.buttons.submit')}</LoaderButton>
            <Button as={!user ? NavLink : undefined} onClick={user ? () => history.goBack() : undefined} to={!user ? { pathname: '/login', state: { from: location.pathname } } : undefined} variant="outline-light">{t('common:buttons.cancel')}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

ConfirmPasswordForm.propTypes = {
  /**
   * The user handles of the accounts associated with the provided email address
   */
  usernameOptions: PropTypes.array,
  /**
   * The show username boolean to toggle account lookup
   */
  showUsername: PropTypes.bool,
  /**
   * The email adress of the current user
   */
  userEmail: PropTypes.string,
  /**
   * The submit function when resetting or changing password
   */
  onSubmit: PropTypes.func.isRequired
};

export default ConfirmPasswordForm;
