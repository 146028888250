import React from 'react';
import { Container, Row } from 'react-bootstrap';

import PageTitle from '../../components/common/PageTitle';
import PageSections from '../../components/common/PageSections';

import adminNavItems from '../../constants/adminNavItems';

const Admin = ({ t }) => (
  <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

    <Row noGutters className="page-header mb-3 mb-md-5">
      <PageTitle title={t('admin.title')} />
    </Row>

    <PageSections items={adminNavItems} />

  </Container>
);

export default Admin;