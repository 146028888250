import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';

import { injectPdfjsCdn } from '../../utils';

import Loader from '../common/Loader';

import { useAlerts } from '../context';

pdfjs.GlobalWorkerOptions.workerSrc = injectPdfjsCdn(pdfjs.version);

const DocumentPreviewModal = ({ show, data, onHide, modal = true }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const containerRef = useRef(null);

  const nextPage = () => pageNumber < numPages && setPageNumber(pageNumber + 1);

  const prevPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);

  useEffect(() => {
    if (data && data.file_type === 'pdf') {
      setDimensions({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight
      });
    }
  }, [data]);

  return !data ? <Loader /> : <>
    {!modal ?
      <section className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-column flex-grow-1 overflow-auto custom-scrollbar bg-secondary" ref={containerRef} style={{ height: 500 }}>
          {!data ? <Loader /> : <div className="m-auto">
            <Document
              className="loaded"
              loading={<Loader />}
              file={{ url: data.file }}
              options={{ cMapUrl: 'cmaps/', cMapPacked: true }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              onLoadError={(error) => console.log(error)}
              onSourceError={(error) => console.log(error)}>
              <Page pageNumber={pageNumber} width={dimensions.width} height={dimensions.height} scale={0.99} />
            </Document>
          </div>}
        </div>
        {numPages > 1 && <footer className="d-flex justify-content-between loaded mt-auto p-4 border-top align-items-center">
          <Button variant="outline-light" disabled={pageNumber === 1} className="text-meta" onClick={() => prevPage()}><i className="sila-icon arrow-left"></i></Button>
          {numPages > 1 && <div className="text-center">
            Page {pageNumber} of {numPages}
          </div>}
          <Button variant="outline-light" disabled={pageNumber >= numPages} className="text-meta" onClick={() => nextPage()}><i className="sila-icon arrow-right"></i></Button>
        </footer>}
      </section> :
      <Modal id="doc-preview-modal" centered
        backdrop="static"
        show={show}
        size="lg"
        onHide={() => onHide()}
        aria-labelledby="doc-preview-modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="doc-preview-modal-title"><span className="mr-3">{t('common:upload.preview.title')}</span>{data && <span className="d-inline-block text-break pr-3 loaded" style={{ maxWidth: '100%' }}>{data.file_name}</span>}</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={containerRef} className="p-0 overflow-auto" style={{ height: 500 }}>
          {!data ? <Loader /> : <>{data.file_type === 'pdf' ? <Document
            className="loaded"
            loading={<Loader />}
            file={{ url: data.file }}
            options={{ cMapUrl: 'cmaps/', cMapPacked: true }}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            onLoadError={(error) => setAlert({ message: error, variant: 'danger' })}
            onSourceError={(error) => setAlert({ message: error, variant: 'danger' })}>
            <Page pageNumber={pageNumber} width={dimensions.width} heihgt={dimensions.height} />
          </Document> : <div className="d-flex justify-content-center align-items-center loaded"><img style={{ maxWidth: '100%' }} src={data.file} /></div>}
          </>}
        </Modal.Body>
        {numPages > 1 && <Modal.Footer className="d-flex justify-content-between loaded">
          <Button variant="outline-light" disabled={pageNumber === 1} className="text-meta" onClick={() => prevPage()}><i className="sila-icon arrow-left"></i></Button>
          <Button variant="outline-light" disabled={pageNumber >= numPages} className="text-meta" onClick={() => nextPage()}><i className="sila-icon arrow-right"></i></Button>
        </Modal.Footer>}
      </Modal>}
  </>
};

DocumentPreviewModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The data object
   */
  data: PropTypes.object,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default DocumentPreviewModal;