import React from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { teamApi } from '../../api';

import { hasPermissions } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import StatementList from '../../components/common/transactions/StatementList';

const Statements = ({ t, history, user, setAlert }) => {

  return (
    <Container className="main-content-container statements p-3 p-md-5 d-flex flex-column flex-grow-1 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('settings.statements.title')}>
          <Button variant="outline-light" className="back text-meta" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {hasPermissions(user, 'transactions', 'Read') && user && (user.role === 1 || user.role === 2) ?
        <StatementList
          customer={true}
          setAlert={setAlert}
          user={user}
          filterName={`team_${user.currentTeam.id}_statements`}
          hasPermissions={({ user }) => hasPermissions(user, 'transactions', 'Read')}
          fetchData={teamApi.fetchStatements}
          fetchOptions={{
            team: user.currentTeam.id
          }}
          history={history}
          onRowClick={(data, e) => (e.target && !e.target.closest('.end-user-link') || !e.target) && history.push(`/settings/statement/${data.uuid || data.original.statement_id}/detail`)} /> :
        <div className="position-relative"><DisabledOverlay /></div>}

    </Container>
  )
};

export default Statements;