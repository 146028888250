import React, { useState, useEffect, useCallback } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import footerNavItems from '../../constants/footerNavItems';

import EasterEggModal from '../common/EasterEggModal';
import { useAuth } from '../context';

const MainFooter = () => {
  const { user } = useAuth();
  const [hover, setHover] = useState(false);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 192) setShow(true);
  }, []);

  useEffect(() => {
    if (hover) {
      document.addEventListener('keydown', handleKeyDown, false);
      return () => {
        document.removeEventListener('keydown', handleKeyDown, false);
      };
    }
  }, [hover]);

  return (
    <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
      <Container fluid={user ? true : undefined} className="p-0 my-auto d-flex">
        <div className="mx-auto ml-sm-auto mr-sm-0 d-block d-sm-flex text-center align-items-center">
          {footerNavItems && footerNavItems.length &&
            <Nav className="mr-0 mr-sm-3">
              {process.env.ENVIRONMENT === 'staging' && <Nav.Item className="text-light">
                v{process.env.RELEASE_VERSION}
              </Nav.Item>}
              {footerNavItems.map((item, index) => (
                <Nav.Item className={item.classes || undefined} key={index}>
                  <Nav.Link className="py-0 px-3" as={item.to ? NavLink : undefined} to={item.to ? { pathname: item.to, state: { from: location.pathname } } : undefined} href={item.href || undefined} target={item.href ? '_blank' : undefined}>
                    {item.htmlBefore && (
                      <span
                        className="d-inline-block item-icon-wrapper"
                        dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                      />
                    )}
                    {item.title && <span className="text">{t(item.title)}</span>}
                    {item.htmlAfter && (
                      <span
                        className="d-inline-block item-icon-wrapper"
                        dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                      />
                    )}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          }
          <span className="copyright pl-0 pl-sm-4">{t('footer.copyright', { year: new Date().getFullYear() })}</span>
        </div>
      </Container>
      <div id="🥚" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}></div>
      <EasterEggModal show={show} onHide={() => setShow(false)} />
    </footer>
  )
};

export default MainFooter;