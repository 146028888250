import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { consoleApi } from '../../../../api';
import { useAuth, useApp, useAlerts } from '../../../context';

import ConfirmModal from '../../../common/ConfirmModal';

const SandboxToggle = () => {
  const { t } = useTranslation();
  const { user, refreshSession, env } = useAuth();
  const { updateApp } = useApp();
  const { setAlert } = useAlerts();

  const [isChecked, setIsChecked] = useState(env === 'prod');
  const [disabled, setDisabled] = useState(false);
  const [confirm, setConfirm] = useState({ show: false, isChecked });

  const sandboxToggle = async (isChecked) => {
    let timer;
    setDisabled(true);
    setIsChecked(true);
    try {
      const response = await consoleApi.sandboxToggle(!isChecked);
      setIsChecked(!response.sandbox_enabled);
      if (response.user_approved) {
        updateApp({ isLoading: true });
        refreshSession(user.currentTeam.id, () => {
          updateApp({ isLoading: false });
          setDisabled(false);
          setAlert({ message: t(`env.${response.sandbox_enabled ? 'sandbox' : 'prod'}.success`), variant: 'success' });
        });
      } else {
        setAlert({ message: t('env.prod.not_approved'), variant: 'warning' });
        setIsChecked(false);
        timer = setTimeout(() => { setDisabled(false); }, 6000);
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setConfirm({ show: false, isChecked });
    }
    return () => clearTimeout(timer);
  };

  const handleToggle = (values) => {
    if (values) {
      sandboxToggle(values.isChecked);
    } else {
      setConfirm({ ...confirm, show: false });
    }
  };

  return (<>
    <Form id="sandbox-toggle" className="sandbox-toggle text-nowrap d-flex align-items-center pr-3">
      <Form.Label className="text-uppercase text-xs mb-0" htmlFor="sandbox-switch">{t('env.sandbox.label')}</Form.Label>
      <Form.Check
        className="ml-2"
        type="switch"
        id="sandbox-switch"
        onChange={(e) => setConfirm({ show: true, isChecked: e.target.checked })}
        checked={isChecked}
        disabled={disabled}
      />
      <Form.Label className="text-uppercase text-xs mb-0 text-nowrap d-none d-md-block d-lg-block" htmlFor="sandbox-switch">{t('env.prod.label')}</Form.Label>
      <Form.Label className="text-uppercase text-xs mb-0 text-nowrap d-md-none d-lg-none" htmlFor="sandbox-switch">{t('env.prod.mobileLabel')}</Form.Label>
    </Form>

    {confirm.show && <ConfirmModal
      show={confirm.show}
      data={{ isChecked: confirm.isChecked }}
      title={t('env.confirm.title')}
      message={t('env.confirm.description', { env: t(`env.${confirm.isChecked ? 'prod' : 'sandbox'}.label`) })}
      buttonLabel={t('env.confirm.button', { env: t(`env.${confirm.isChecked ? 'prod' : 'sandbox'}.label`) })}
      onHide={handleToggle} />}

  </>
  );
};

export default SandboxToggle;
