import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { RETURN_RATES_DOCS_URL, NACHA_DOCS_URL, NACHA_LIMITS, SILA_LIMITS } from '../../../constants';

const ReturnRatesModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  return (
    <Modal centered id="return-rates-modal"
      backdrop="static"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="return-rates-modal-title">
      <Modal.Header closeButton>
        <Modal.Title as="h3" id="return-rates-modal-title">{t('common:transactions.return_rates.modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-lg text-info mb-4">{t('common:transactions.return_rates.modal.description')}</p>
        <div className="mx-0 mx-md-3 mx-lg-5">
          <Table className="border">
            <thead>
              <tr>
                <th>{t('common:transactions.return_rates.modal.limits.type')}</th>
                <th>{t('common:transactions.return_rates.modal.limits.nacha')}</th>
                <th>{t('common:transactions.return_rates.modal.limits.sila')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('common:transactions.return_rates.modal.limits.unauth')}</td>
                <td>{NACHA_LIMITS.unauth}%</td>
                <td>{SILA_LIMITS.unauth}%</td>
              </tr>
              <tr>
                <td>{t('common:transactions.return_rates.modal.limits.admin')}</td>
                <td>{NACHA_LIMITS.admin}%</td>
                <td>{SILA_LIMITS.admin}%</td>
              </tr>
              <tr>
                <td>{t('common:transactions.return_rates.modal.limits.total')}</td>
                <td>{NACHA_LIMITS.total}%</td>
                <td>{SILA_LIMITS.total}%</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer className="m-0" as={Row}>
        <Col className="d-flex justify-content-lg-end">
          <Button variant="link" href={RETURN_RATES_DOCS_URL} target="_blank" className="p-0 text-underline">{t('common:transactions.return_rates.modal.buttons.docs')}</Button>
        </Col>
        <Col className="d-flex justify-content-lg-start">
          <Button variant="link" href={NACHA_DOCS_URL} target="_blank" className="p-0 text-underline">{t('common:transactions.return_rates.modal.buttons.nacha')}</Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

ReturnRatesModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The function that is fired when the modal is closed
   */
  onHide: PropTypes.func.isRequired
};

export default ReturnRatesModal;