import Cookies from 'js-cookie';

import consoleApi from './console';
import { handleResponseError } from '../utils';

const currentTeam = parseInt(Cookies.get('sila_console_team'), 10);

const getEndpoint = async (id, app_id) => {
  try {
    const response = await consoleApi.get({
      url: `/endpoints/${id}`,
      data: {
        app_id,
      },
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createEndpoint = async (values) => {
  try {
    const response = await consoleApi.post({
      url: '/endpoints',
      data: {
        name: values.name,
        url: values.url,
        max_attempts: values.max_attempts,
        retry_interval: values.retry_interval,
        app_id: values.app_id,
        triggers: values.triggers,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateEndpoint = async (id, values) => {
  try {
    const response = await consoleApi.put({
      url: `/endpoints/${id}`,
      data: {
        name: values.name,
        url: values.url,
        max_attempts: values.max_attempts,
        retry_interval: values.retry_interval,
        app_id: values.app_id,
        triggers: values.triggers,
        active: values.active,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteEndpoint = async (values) => {
  try {
    const response = await consoleApi.delete({
      url: `/endpoints/${values.id}`,
      data: {
        app_id: values.app_id,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getTriggers = async () => {
  try {
    const response = await consoleApi.get({ url: '/triggers' });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchEndpoints = async (options) => {
  try {
    const response = await Promise.all(
      options.apps.map((app) =>
        consoleApi.get({
          url: '/endpoints',
          data: {
            app_id: app.id,
            limit: options.limit || 8,
            page: options.page || 1,
          },
        })
      )
    );
    if (!response) {
      throw handleResponseError(response);
    }
    let endpoints = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const data of response) {
      endpoints = [...endpoints, ...data.endpoints];
    }
    return { endpoints };
  } catch (error) {
    throw error;
  }
};

const fetchDisabledWebhooks = async (team = currentTeam) => {
  try {
    const response = await consoleApi.get({
      url: '/endpoints',
      data: {
        team_id: team,
        is_disabled: true,
      },
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchWebhookEvents = async (options) => {
  const limit = options.limit || 20;
  const page = options.page || 1;
  const team = options.team || currentTeam;
  try {
    const response = await consoleApi.get({
      url: `/webhook_events?team_id=${team}&limit=${limit}&page=${page}${
        options.query ? options.query : ''
      }`,
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getWebhookEvent = async (id, team = currentTeam) => {
  try {
    const response = await consoleApi.get({
      url: `/webhook_events/${id}`,
      data: {
        team_id: team,
      },
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getWebhookAlerts = async (id) => {
  try {
    const response = await consoleApi.get({
      url: `/endpoints/${id}/alerts`,
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const subscribeWebhookAlert = async (id, email) => {
  try {
    const response = await consoleApi.post({
      url: `/endpoints/${id}/alerts`,
      data: {
        email,
      },
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const unsubscribeWebhookAlert = async (id) => {
  try {
    const response = await consoleApi.delete({
      url: `/alerts/${id}`,
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateWebhookAlert = async (id, email) => {
  try {
    const response = await consoleApi.put({
      url: `/alerts/${id}`,
      data: {
        email,
      },
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getWebhookEventSample = async (trigger) => {
  try {
    const response = await consoleApi.get({
      url: `/webhooks_event_sample/${trigger}`,
    });
    if (!response) {
      throw handleResponseError(response);
    }
    return { ...response, name: trigger };
  } catch (error) {
    throw error;
  }
};

const fetchPayloads = async (triggers) => {
  try {
    const response = await Promise.all(
      triggers.map((trigger) => getWebhookEventSample(trigger.name))
    );
    if (!response) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const sendPayload = async (app_id, endpoint_uuid, payload, _trigger) => {
  const parsed = JSON.parse(payload);
  try {
    const response = await consoleApi.post({
      url: '/webhook_event_send_sample_payload',
      data: {
        app_id,
        endpoint_uuid,
        payload: parsed,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const resendWebhookEvent = async (values, team = currentTeam) => {
  try {
    const response = await consoleApi.put({
      url: `/webhook_events/${values.uuid}/resend`,
      data: {
        team_id: team,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const confirmWebhookEmailAlert = async (token) => {
  try {
    const response = await consoleApi.get({
      url: '/confirm_webhook_email_alerts',
      data: {
        confirmation_token: token,
      },
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const webhooksApi = {
  getEndpoint,
  createEndpoint,
  updateEndpoint,
  deleteEndpoint,
  getTriggers,
  fetchEndpoints,
  fetchPayloads,
  getWebhookEventSample,
  sendPayload,
  fetchWebhookEvents,
  getWebhookEvent,
  resendWebhookEvent,
  fetchDisabledWebhooks,
  getWebhookAlerts,
  subscribeWebhookAlert,
  unsubscribeWebhookAlert,
  updateWebhookAlert,
  confirmWebhookEmailAlert,
};

export default webhooksApi;
