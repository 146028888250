import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar } from 'react-bootstrap';
import stickybits from 'stickybits';

import NavbarSearch from './NavbarSearch';
import NavbarTeams from './NavbarTeams';
import NavbarNav from './NavbarNav/NavbarNav';
import NavbarToggle from './NavbarToggle';
import NavbarGuest from './NavbarGuest';

import { useAuth } from '../../context';

import SilaLogo from '../../../components/common/SilaLogo';

const MainNavbar = ({ stickyTop, full }) => {
  const classes = classNames('main-navbar border-bottom bg-white px-3', stickyTop && 'sticky-top');
  const { user } = useAuth();
  useEffect(() => {
    stickybits('.main .main-navbar', {useStickyClasses: true});
  }, []);

  return (
    <>
    <header className={classes}>
      {user ? <Navbar as="nav" type="light" className="align-items-stretch flex-md-nowrap p-0">
          <Container fluid className="my-auto p-0 h-100">
            <Navbar.Brand href="/" className="d-sm-block d-md-none">
              <SilaLogo id="main-logo" className="d-inline-block align-middle mr-1 logo" />
            </Navbar.Brand>
            {user.teams && user.teams.length && user.currentTeam && <nav className="main-navbar__teams d-none d-md-block"><NavbarTeams /></nav>}
            {/* <NavbarSearch /> */}
            <NavbarNav />
            <NavbarToggle full={full} />
          </Container>
        </Navbar>
        : <NavbarGuest />
      }
    </header>
    {user && user.teams && user.teams.length && user.currentTeam && <nav className="main-navbar__teams d-block d-md-none m-3"><NavbarTeams /></nav>}
    </>
  );
};

MainNavbar.propTypes = {
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
