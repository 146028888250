import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Loader = ({ className, label, style, fixed, ...rest }) => (
  <div className={`content-loading d-flex justify-content-center align-items-center position-${fixed ? 'fixed' : 'absolute'}`}>
    <div className={`${className ? `${className} ` : ''}loader text-center`}>
      {!style && <Spinner animation="border" role="status" variant="primary" {...rest}><span className="sr-only">Loading....</span></Spinner>}
      {style === 'dots' && <div className="dots"><div></div><div></div><div></div><div></div></div>}
      {label && <p className="text-info text-sm mt-2" dangerouslySetInnerHTML={{ __html: label }}></p>}
    </div>
  </div>
);

Loader.propTypes = {
  /**
   * The optional label that is displayed underneath the spinner.
   */
  label: PropTypes.string,
  /**
   * The optional style.
   */
  style: PropTypes.string,
  /**
   * The optional classes.
   */
  className: PropTypes.string,
  /**
   * The optional position.
   */
  fixed: PropTypes.bool
};

export default Loader;