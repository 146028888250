import React, { useMemo, useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { adminApi } from '../../../api';
import { formatDateAndTime } from '../../../utils';
import { useAlerts } from '../../context';

import DataList from '../DataList';
import { CheckboxColumnFilter, MultipleFilter } from '../table/TableFilters';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'end_users';

const formatSortBy = (sortBy) => {
  let query = sortBy;
  const name = sortBy.find(sort => sort.id === 'name');
  if (name) {
    query = [...query.filter(sort => sort.id !== 'name'), { id: 'full_name', desc: name.desc }, { id: 'first_name', desc: name.desc }];
  }
  return { url: sortBy, query };
};

const formatFilters = (filters) => {
  let query = filters;
  return { url: filters, query };
};

const EndUsersList = ({ customer, filterName, fetchData, fetchOptions, onRowClick, onFiltered, hasPermissions }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();
  const [statuses, setStatuses] = useState([]);

  const getVerificationStatus = async () => {
    try {
      const response = await adminApi.getVerificationStatuses();
      if (response.success) {
        setStatuses(response.verification_statuses);
      }
    } catch (error) {
      setAlert({ message: error, variant: 'danger' });
    }
  };

  const columns = useMemo(() => [
    {
      id: 'last_verification_time',
      Header: t('admin.endUsers.labels.verificationTime'),
      accessor: 'last_verification_update',
      disableFilters: true,
      Cell: ({ value }) => value ? formatDateAndTime(value) : <span className="text-meta">{t('common:labels.na')}</span>
    },
    {
      id: 'name',
      Header: t('common:labels.name'),
      accessor: data => data.full_name || `${data.first_name} ${data.last_name}`,
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: customer,
      Cell: ({ value, row }) => <span className={`text-break d-block position-relative ${!customer ? 'pl-4' : ''}`} style={{ maxWidth: 160, minWidth: 160 }}>{!customer && <i className={`sila-icon mr-2 text-primary text-lg ml-n4 position-absolute ${row.original.entity_type}`}></i>} {value || <span className="text-meta">{t('common:labels.na')}</span>}</span>
    },
    {
      id: 'handle',
      Header: t('common:labels.handle'),
      accessor: 'user_handle',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      disableSortBy: customer,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>{value}</span>,
    },
    {
      id: 'app_handle',
      Header: t('common:labels.app'),
      accessor: 'apps',
      filter: MultipleFilter,
      Cell: ({ value }) => value.map((item, index) => <span key={item} className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>{item}</span>),
      Filter: CheckboxColumnFilter,
      disableSortBy: customer,
    },
    {
      id: 'email',
      Header:  t('admin.labels.email'),
      accessor: 'emails',
      filter: MultipleFilter,
      Cell: ({ value }) => value.map((item, index) => value ? <span key={item} className="text-break d-block" style={{ maxWidth: 200, minWidth: 160 }}>{item}</span> : <span className="text-meta">{t('common:labels.na')}</span>),
      Filter: CheckboxColumnFilter,
      disableSortBy: customer,
    },
    {
      id: 'phone',
      Header: t('common:labels.phoneNumber'),
      accessor: 'phones',
      filter: MultipleFilter,
      searchOptions: {
        onChange: (value) => value.replace(/\D/g,'')
      },
      Filter: CheckboxColumnFilter,
      disableSortBy: customer,
      Cell: ({ value }) => <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>{value}</span>,
    },
    {
      id: 'last_verification_status',
      Header: t('admin.endUsers.labels.kycStatus'),
      accessor: 'last_verification_status',
      filter: MultipleFilter,
      Filter: CheckboxColumnFilter,
      Cell: ({ value }) => <Badge pill className="badge-outline py-2 px-3 w-100 d-block mx-auto" variant={value.includes('passed') ? 'success' : value.includes('failed') ? 'warning' : 'primary'}>{statuses.find(status => value.value && status.name == value.value || value && status.name == value).label}</Badge>,
      filterOptions: () => statuses.map(status => ({
        value: status.name,
        label: status.label
      }))
    }
  ], [statuses]);

  if (customer) {
    delete columns[0];
    delete columns[6];
  }

  useEffect(() => {
    if(!customer) getVerificationStatus();
  }, [customer]);

  return (
    <DataList
      slug={TABLE_SLUG}
      limit={TABLE_MAX_COUNT}
      filterName={filterName}
      fetchData={fetchData}
      fetchOptions={fetchOptions}
      columns={columns}
      onRowClick={onRowClick}
      onFilter={formatFilters}
      onSortBy={formatSortBy}
      onFiltered={onFiltered}
      renderEmptyComponent={({ tableFilters }) => tableFilters.filters.length === 0 && tableFilters.sortBy.length === 0 ? <>
        <p className="text-lg text-center mt-5">{t('common:filters.empty.title')}</p>
        <p className="text-meta text-center mb-5">{t('common:filters.empty.description')}</p>
      </> : <p className="text-meta text-center mx-4 my-5">{t('common:form.messages.empty')}</p>}
      hasPermissions={hasPermissions}
      loadingMessage={t('admin.endUsers.loading')} />
  )
};

EndUsersList.propTypes = {
  /**
   * The unique item slug for the data list
   */
  filterName: PropTypes.string,
  /**
   * The fetch data function
   */
  fetchData: PropTypes.func.isRequired,
  /**
   * The fetch options object
   */
  fetchOptions: PropTypes.object.isRequired,
  /**
   * The click handler function trigger on a table row
   */
  onRowClick: PropTypes.func.isRequired,
  /**
   * The function to validate user permissions
   */
  hasPermissions: PropTypes.func.isRequired
};

export default EndUsersList;
