import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Table, Badge } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement,
  LineElement, Title, Tooltip, Legend } from 'chart.js';

import Loader from '../common/Loader';
import TooltipButton from '../common/TooltipButton';

import { appsApi } from '../../api';
import { useAlerts } from '../context';
import { configureChart } from '../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AppCharts = ({ appId }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    usage_by_status_code: {
      status_code_200: '-',
      status_code_400: '-',
      status_code_500: '-'
    },
    usage_by_endpoint: {
      labels: [],
      datasets: [
        {
          label: '/get_transactions',
          backgroundColor: '#0030FC',
          borderColor: '#0030FC',
          data: []
        }, {
          label: '/check_kyc',
          backgroundColor: '#00C9C2',
          borderColor: '#00C9C2',
          data: []
        }, {
          label: '/request_kyc',
          backgroundColor: '#009691',
          borderColor: '#009691',
          data: []
        }, {
          label: '/issue_sila',
          backgroundColor: '#FC8A00',
          borderColor: '#FC8A00',
          data: []
        }, {
          label: '/redeem_sila',
          backgroundColor: '#CC4900',
          borderColor: '#CC4900',
          data: []
        }
      ]
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const { setAlert } = useAlerts();

  const fetchAppMetrics = async () => {
    setIsLoading(true);
    try {
      const response = await appsApi.fetchAppMetrics(appId);
      setInitialValues({
        ...response,
        usage_by_endpoint: {
          ...response.usage_by_endpoint,
          datasets: initialValues.usage_by_endpoint.datasets.filter(dataset => response.usage_by_endpoint.datasets.some(metric => metric.label === dataset.label)).map(metric => ({ ...metric, ...response.usage_by_endpoint.datasets.find(dataset => dataset.label === metric.label) }))
        }
      });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAppMetrics();
  }, []);

  return (
    <div className="add-ons loaded">
      {isLoading && <Loader />}
      <div className="d-flex flex-row justify-content-between mt-3 mb-3">
        <div>
          <div className="d-flex align-items-bottom">
            <h3>{t('applications.metrics.title')}</h3>
            <TooltipButton placement="top" popoverProps={{ style: { textAlign: 'center' } }} variant="outline-light" disabled={isLoading} onClick={() => fetchAppMetrics()} text={t('common:buttons.refresh')} className="btn-circle ml-3"><i className={`fas fa-sync-alt${isLoading ? ' spin' : ''}`}></i></TooltipButton>
          </div>
          <p className="text-muted">{t('applications.metrics.description')}</p>
        </div>
        <div>
          <p className="text-muted text-right mb-1">
            <Trans i18nKey="applications.metrics.requestsDescription">Number of requests in the past <strong>72 hours</strong></Trans>:
          </p>
          <Table responsive>
            <thead>
              <tr>
                <th className="bg-transparent border-bottom-0 py-1 text-lg text-success">{t('applications.metrics.200s')}</th>
                <th className="bg-transparent border-bottom-0 py-1 text-lg text-warning">{t('applications.metrics.400s')}</th>
                <th className="bg-transparent border-bottom-0 py-1 text-lg text-danger">{t('applications.metrics.500s')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-1 border-top-0 text-lg">
                  <Badge pill className="badge-outline py-2 w-100" variant="success">{initialValues.usage_by_status_code.status_code_200}</Badge>
                </td>
                <td className="py-1 border-top-0 text-lg">
                  <Badge pill className="badge-outline py-2 w-100" variant="warning">{initialValues.usage_by_status_code.status_code_400}</Badge>
                </td>
                <td className="py-1 border-top-0 text-lg">
                  <Badge pill className="badge-outline py-2 w-100" variant="danger">{initialValues.usage_by_status_code.status_code_500}</Badge>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <Line options={configureChart({
        title: t('applications.metrics.requestsPerEndpoint'),
        xLabel: 'Endpoints',
        yLabel: '# of Requests'
      })} data={initialValues.usage_by_endpoint} />
    </div>
  );
};

AppCharts.propTypes = {
  /**
   * The id of the application
   */
  appId: PropTypes.number.isRequired,
};

export default AppCharts;