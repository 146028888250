import { hasPermissions } from '../utils';

export default [{
    name: 'developers.headers.webhooks',
    items: [
        {
            title: 'webhooks.endpoints.title',
            description: 'webhooks.endpoints.description',
            to: '/webhooks'
        },
        {
            title: 'webhooks.events.title',
            description: 'webhooks.events.description',
            to: '/webhook_events',
        }
    ],
    permissions: (user) => hasPermissions(user, 'webhooks', 'Read')
}]