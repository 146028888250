import React, { createContext, useState, useContext, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import useInterval from '../../utils/hooks/useInterval';

const INTERVAL = 1000;
const DEFAULT_TIMEOUT = 5000;
const DEFAULT_VARIANT = 'primary';

export const alertContext = createContext(null);

// Initialize alert data
let initAlertData = {
  alerts: [],
  setAlert: () => null,
  clearAlert: () => null,
};

// Create a provider for components to consume and subscribe to changes
const AlertDataProvider = props => {

  // Initialize alert state
  const [alerts, setAlerts] = useState(initAlertData.alerts);

  // Append a new alert (or override existing by id)
  const setAlert = useCallback((alert) => {
    const existing = alerts.find((n) => n.id === alert.id);
    const nextAlerts = existing
      ? alerts.map((n) =>
        n.id === alert.id ? { ...existing, ...alert } : n
      )
      : alerts.concat({
        id: uuid(),
        timestamp: new Date().getTime(),
        variant: DEFAULT_VARIANT,
        ...alert
      });
    setAlerts(nextAlerts);
  }, [alerts, setAlerts]);

  // Clear alert(s) by id, or clear ALL alerts
  const clearAlert = useCallback((id) => {
    if (!id) {
      setAlerts([]);
    } else {
      const ids = Array.isArray(id) ? id : [id];
      const nextAlerts = alerts.filter(
        ({ id }) => !ids.includes(id)
      );
      setAlerts(nextAlerts);
    }
  }, [alerts, setAlerts]);

  // Set up interval to auto-expire alerts
  const handleExpiredAlerts = useCallback((currentTime) => {
    if (alerts.length) {
      const expiredIds = alerts.filter(alert => !alert.dismissable).reduce((acc, n) => {
        const isExpired =
          n.timestamp <= currentTime - (n.timeout || DEFAULT_TIMEOUT);
        return isExpired && n.timeout !== null ? acc.concat(n.id) : acc;
      }, []);
      if (expiredIds.length) {
        clearAlert(expiredIds);
      }
    }
  }, [alerts, clearAlert]);

  useInterval(handleExpiredAlerts, INTERVAL);

  return <alertContext.Provider value={{
    alerts,
    setAlert,
    clearAlert,
  }} {...props} />;
};

export const useAlerts = () => useContext(alertContext);

export default AlertDataProvider;