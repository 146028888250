import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Loader from '../common/Loader';
import LoaderButton from '../common/LoaderButton';
import FieldWrapper from '../form/wrappers/Field';
import StatusBadge from './StatusBadge';

import { useAuth } from '../context';

const initialValues = ({ data }) => ({
  member: data ? data.member || data.invitation : { new: true, email: '' },
  role: data ? data.role : { id: '' }
});

const schema = ({ t }) => yup.object().shape({
  member: yup.object().shape({
    new: yup.boolean(),
    email: yup.string()
      .email(t('common:form.fields.email.error'))
      .max(254, t('common:form.errors.max', { count: 254 }))
      .when('new', {
        is: true,
        then: yup.string().required(t('common:form.errors.required', { field: t('common:form.fields.email.label') }))
      }),
  }),
  role: yup.object().shape({
    id: yup.number().integer()
      .required(t('common:form.errors.required', { field: t('settings.members.labels.role') })),
  })
});

const MemberDataForm = ({ t, show, onHide, data, errors, handleSubmit, isSubmitting, dirty, setFieldValue, loading }) => {
  const { user } = useAuth();
  const isInvite = data && data.invitation
  const isMember = data && data.member;
  const isLoading = isSubmitting || loading;

  const roleOptions = [
    {
      label: t('settings.members.labels.roles.1'),
      value: 1,
    },
    {
      label: t('settings.members.labels.roles.2'),
      value: 2
    },
    {
      label: t('settings.members.labels.roles.3'),
      value: 3
    }
  ];

  return (
    <Modal centered id="member-modal"
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="member-modal-title">
      <Form noValidate autoComplete="new-password" className="loaded" onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title as="h3" id="member-modal-title">{isMember ? t('settings.members.headers.user') : t('settings.members.headers.invite')}</Modal.Title>
        </Modal.Header>
        {isLoading && <Loader />}
        <Modal.Body>
          <fieldset id="memberForm.contact">
            <div className="d-flex justify-content-between">
              <h5>{t('settings.members.headers.contact')}</h5>
              {data && <div><StatusBadge data={data} /></div>}
            </div>
            {isMember &&
              <Field required autofocus plaintext readOnly className="name"
                id="memberForm.name"
                label={t('common:form.fields.name.label')}
                name="member.name"
                component={FieldWrapper} />}
            {(!isMember || isInvite) &&
              <Field required autofocus plaintext={data} readOnly={data} className="email"
                id="memberForm.email"
                label={t('common:form.fields.email.label')}
                name="member.email"
                handleChange={(e) => setFieldValue(e.target.name, e.target.value.toLowerCase().trim())}
                component={FieldWrapper} />}
            {isMember &&
              <Field required autofocus plaintext readOnly className="username"
                id="memberForm.handle"
                label={t('common:form.fields.handle.label')}
                name="member.handle"
                component={FieldWrapper} />}
          </fieldset>
          <fieldset id="memberForm.role">
            <h5>{t('settings.members.labels.role')}</h5>
            <Field required autofocus className="role"
              id="memberForm.role"
              label={t('settings.members.labels.roles.placeholder')}
              name="role.id"
              type="select"
              options={roleOptions.filter(option => user.role !== 1 ? option.value !== 1 : option)}
              component={FieldWrapper} />
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-light" onClick={(e) => { e.preventDefault(); onHide(); }} disabled={isSubmitting}>{t('common:buttons.cancel')}</Button>
          <LoaderButton variant="primary" type="submit" loading={isSubmitting} loadingLabel={isMember ? t('common:status.submitting') : t('common:status.sending')} disabled={Object.keys(errors).length || isSubmitting || isMember && !dirty}>{isMember ? t('settings.members.buttons.save') : isInvite ? t('settings.members.buttons.resend') : t('settings.members.buttons.send')}</LoaderButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

MemberDataForm.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The member data object
   */
  data: PropTypes.object,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

const MemberDataModal = withFormik({
  enableReinitialize: true,
  mapPropsToValues: initialValues,
  validationSchema: schema,
  handleSubmit: (values, { props: { t, onHide, data } }) => {
    if (data && data.member) data.role.name = t(`settings.members.labels.roles.${data.role.id}`);
    onHide({ ...data, ...values });
  }
})(MemberDataForm);

export default withTranslation()(MemberDataModal);