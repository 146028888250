import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';

import { adminApi } from '../../api';

import UserForm from '../common/UserForm';
import ConfirmModal from '../common/ConfirmModal';

import { useAlerts } from '../context';

const ConsoleUserForm = ({ initialValues, getConsoleUser, setName, formRef }) => {
  const [currentUser, setCurrentUser] = useState({ show: false, data: undefined, handle: initialValues.handle, name: `${initialValues.first_name} ${initialValues.surname}` });
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const updateUser = async (values) => {
    if (values) {
      try {
        await adminApi.updateConsoleUser(initialValues.handle, {
          first_name: values.first_name,
          last_name: values.surname,
          emails: values.emails.length && values.emails.some(e => e.email.length) ? values.emails : null,
          phones: values.phones.length && values.phones.some(phone => phone.phone.length) ? values.phones : null,
          birthdate: values.birthdate,
          addresses: values.addresses.length && values.addresses.some(address => address.street_address_1.length) ? values.addresses : null
        });
        getConsoleUser();
        setAlert({ message: t(`admin.user.update.message`, { name: `${values.first_name} ${values.surname}` }), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        formRef.current.resetForm({ values: initialValues });
      } finally {
        setCurrentUser({ ...currentUser, show: false, data: undefined });
      }
    } else {
      formRef.current.setSubmitting(false);
      setCurrentUser({ ...currentUser, show: false, data: undefined });
    }
  };

  return (
    <>
      <UserForm
        formRef={formRef}
        initialValues={initialValues}
        setName={setName}
        onSubmit={(values) => setCurrentUser({ ...currentUser, show: true, data: values })} />

      {currentUser.data && <ConfirmModal
        show={currentUser.show}
        data={currentUser.data}
        title={t('admin.user.update.title')}
        renderMessageComponent={() => <>
          <p className="text-lg mb-4">{t(`admin.user.update.confirm`, { name: currentUser.name })}</p>
          <p className="text-info">{t(`admin.user.update.description`, { name: currentUser.name, handle: currentUser.handle })}</p>
        </>}
        buttonLabel={t('admin.user.update.action')}
        onHide={updateUser} />}

    </>
  );
};

ConsoleUserForm.propTypes = {
  /**
   * The values of the form
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * The function to get console user data
   */
  getConsoleUser: PropTypes.func.isRequired,
  /**
   * The function to set the name of the console user
   */
  setName: PropTypes.func,
  /**
  * The form ref
  */
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
};

export default ConsoleUserForm;