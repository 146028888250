import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Col } from 'react-bootstrap';
import { Redirect, NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead'
import Cookies from 'js-cookie';
import * as yup from 'yup';

import FieldWrapper from '../components/form/wrappers/Field';
import CaptchaWrapper from '../components/form/wrappers/Captcha';
import Loader from '../components/common/Loader';
import LoaderButton from '../components/common/LoaderButton';

import { useDebounce } from '../utils/hooks';
import { disAllowPassword, capitalize } from '../utils';
import { PHONE_REG_EXP, CLEARBIT_SEARCH_URI, COOKIES_OPTIONS, SLACK_EVENT_TRACKING_CHANNEL } from '../constants';

import { authApi, consoleApi } from '../api';

import { useAuth } from '../components/context';

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL, SDK_AGREEMENT_URL } from '../constants';

const Register = ({ user, location, history, app, updateApp, setAlert }) => {
  const [emailStatus, setEmailStatus] = useState({ pending: false, isValid: false });
  const [email, setEmail] = useState('');
  const debouncedEmail = useDebounce(email, 750);
  const [recaptchaToken, setRecaptchaToken] = useState(process.env.RECAPTCHA_SKIP === 'True');
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [password, setPassword] = useState('');
  const debouncedpassword = useDebounce(password, 750);
  const [isCompanyListed, setIsCompanyListed] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const debouncedCompany = useDebounce(company, 750);
  const { from } = location.state || { from: { pathname: '/' } };
  const { onLogin } = useAuth();
  const formRef = useRef(null);
  const emailRef = useRef(null);
  const companyNameRef = useRef(null);
  const { t } = useTranslation();

  const initialValues = {
    first_name: '',
    surname: '',
    company_name: '',
    email: app.invitation ? app.invitation.email : '',
    phone: '',
    password: '',
    confirmPassword: '',
    privacy: ''
  };

  const schema = yup.object().shape({
    first_name: yup.string().trim()
      .required(t('common:form.errors.required', { field: t('common:form.fields.firstName.label') }))
      .max(100, t('common:form.errors.max', { count: 100 })),
    surname: yup.string().trim()
      .required(t('common:form.errors.required', { field: t('common:form.fields.lastName.label') }))
      .max(100, t('common:form.errors.max', { count: 100 })),
    company_name: yup.string()
      .max(95, t('common:form.errors.max', { count: 95 }))
      .required(t('common:form.errors.required', { field: t('register.form.fields.companyName.label') })),
    email: !app.invitation ? yup.string()
      .max(254, t('common:form.errors.max', { count: 254 }))
      .email(t('common:form.fields.email.error'))
      .required(t('common:form.errors.required', { field: t('common:form.fields.email.label') }))
      .test('checkEmail', t('common:form.errors.exists', { field: t('common:form.fields.email.label') }) || t('common:status.validating'), (value) => app.validation.checkEmail.isValid && value) : null,
    phone: yup.string()
      .matches(PHONE_REG_EXP, t('common:form.fields.phone.error'))
      .required(t('common:form.errors.required', { field: t('common:form.fields.phone.label') })),
    password: yup.string()
      .min(8, t('common:form.fields.password.minError'))
      .max(100, t('common:form.fields.password.maxError'))
      .test('checkValidPassword', (!isValidPassword ? t('common:form.fields.password.handle') : t('common:form.fields.password.error')) || t('common:status.validating'), (value) => isValidPassword && value),
    confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], t('register.form.fields.confirmPassword.error')),
    privacy: yup
      .boolean()
      .oneOf([true], t('register.form.fields.privacy.error'))
      .required(t('register.form.fields.privacy.error'))
  });

  const checkEmail = async (value) => {
    if (value) {
      setEmailStatus({ ...emailStatus, pending: true });
      updateApp({ validation: { ...app.validation, checkEmail: { ...app.validation.checkEmail, email: value } } });
      try {
        const exists = await authApi.confirmAccountExists(value);
        setEmailStatus({ ...emailStatus, isValid: !exists, pending: false });
        updateApp({ validation: { ...app.validation, checkEmail: { ...app.validation.checkEmail, isValid: !exists } } });
      } catch (error) {
        setEmailStatus({ ...emailStatus, isValid: false, pending: false });
        updateApp({ validation: { ...app.validation, checkEmail: { ...app.validation.checkEmail, isValid: false, error: error } } });
      } finally {
        formRef.current.setFieldTouched('email', true);
      }
    }
  };

  const checkValidPassword = async (value) => {
    if (email && value) {
      setIsValidPassword(disAllowPassword(value, email));
    }
  };

  const register = async (values, { setSubmitting }) => {
    if (process.env.RECAPTCHA_SKIP !== 'True') {
      values = { ...values, ctoken: recaptchaToken }
    }
    try {
      const response = await authApi.register(values);
      updateApp({
        registration: {
          ...app.registration,
          ...values,
          id: response.users.id,
          handle: response.users.handle,
          team_id: response.users.team_id,
          team_name: app.invitation.team_name,
          inProgress: true
        }
      });
      if (app.invitation) {
        values.handle = response.users.handle;
      }
      authenticate(values, { setSubmitting });
      return response;
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setSubmitting(false);
    }
  };

  const authenticate = async (values, { setSubmitting }) => {
    try {
      let response;
      if (process.env.RECAPTCHA_SKIP !== 'True') {
        response = await authApi.authenticate({ handle: values.email, password: values.password, via: 'register', ctoken: values.ctoken });
      } else {
        response = await authApi.authenticate({ handle: values.email, password: values.password, via: 'register' });
      }
      if (response.user.sila_device_id) {
        const currentYear = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(currentYear.getFullYear() + 1);
        Cookies.set('sila_console_device_id', response.user.sila_device_id, { ...COOKIES_OPTIONS, 'expires': nextYear });
      }
      onLogin(response);
      let next_screen = 'invite_team_members';
      if (app.invitation && app.invitation.token) {
        sendSlackMessage({
          name: `${values.first_name} ${values.surname}`,
          handle: values.handle,
          email: values.email,
          team: app.invitation.team_name,
          role: app.invitation.role_name,
          env: capitalize(app.invitation.env)
        });
        updateApp({ invitation: false });
        if (Cookies.get('sila_console_invitation')) Cookies.remove('sila_console_invitation');
        next_screen = 'questionnaire';
      }
      history.push({ pathname: '/register/' + next_screen, state: { from: location.pathname } });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setSubmitting(false);
    }
  };

  const handleCompanySearch = async (query) => {
    if (isLoading == true) return;
    setIsLoading(true);
    fetch(`${CLEARBIT_SEARCH_URI}${query}`)
      .then(res => res.json())
      .then((result) => {
        const cOptions = result.map((i) => ({ name: i.name }));
        setCompanyOptions(cOptions);
        setIsLoading(false);
      },
        (error) => { console.info(error); });
  };

  const useCompanyName = (setFieldValue) => {
    setIsCompanyListed(false);
    setFieldValue('company_name', company);
    setTimeout(() => { companyNameRef.current.focus(); }, 750);
  };

  const sendSlackMessage = async (data) => {
    try {
      await consoleApi.sendSlackMessage({
        channel: SLACK_EVENT_TRACKING_CHANNEL,
        message: t('invite.messages.events.accept', data)
      });
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  useEffect(() => {
    if (debouncedpassword) checkValidPassword(debouncedpassword);
  }, [debouncedpassword]);

  useEffect(() => {
    if (debouncedCompany) handleCompanySearch(debouncedCompany);
  }, [debouncedCompany]);

  useEffect(() => {
    if (debouncedEmail) checkEmail(debouncedEmail);
  }, [debouncedEmail]);

  if (user) {
    return <Redirect to={from} />;
  }

  return (
    <Container className="register main-content-container p-0">
      {(app.invitation && app.invitation.team_name) && <h2 className="mb-3">{t('register.invitedTitle', { team_name: app.invitation.team_name })}</h2>}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        innerRef={formRef}
        onSubmit={(values, { setSubmitting }) => {
          try {
            const { email, privacy, surname, ...newValues } = values;
            if (privacy) newValues.privacy_policy_accept = new Date().toString();
            updateApp({ registration: { ...app.registration, ...newValues, email, surname, inProgress: true } });
            register({
              ...newValues,
              email: values.email,
              surname: values.surname,
              invitation_token: app.invitation ? app.invitation.token : null,
              invitation_env: app.invitation ? app.invitation.env : null
            }, { setSubmitting });
          } catch (error) {
            setAlert({ message: error, variant: 'warning' });
          }
        }}>
        {({ values, errors, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, touched }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Form.Row>
              <Col sm="12" md="6">
                <Field hideError={values.first_name.length === 0} required autofocus material data-hj-whitelist className="first-name"
                  id="registerForm.first_name"
                  label={t('common:form.fields.firstName.label')}
                  name="first_name"
                  fieldClass="data-hj-whitelist"
                  component={FieldWrapper} />
              </Col>
              <Col sm="12" md="6">
                <Field hideError={values.surname.length === 0} required autofocus material data-hj-whitelist className="last-name"
                  id="registerForm.surname"
                  label={t('common:form.fields.lastName.label')}
                  name="surname"
                  fieldClass="data-hj-whitelist"
                  component={FieldWrapper} />
              </Col>
            </Form.Row>
            {isCompanyListed ? <Field focused required autofocus material data-hj-whitelist
              hideError={!company}
              is={Typeahead}
              options={companyOptions}
              onChange={(selected) => {
                const value = (selected.length > 0) ? selected[0] : '';
                setFieldValue('company_name', value, true);
              }}
              onInputChange={(text, event) => setCompany(text)}
              handleBlur={(e) => {
                setFieldTouched('company_name', true);
              }}
              filterBy={() => true}
              multiple={false}
              labelKey="name"
              renderMenu={(results, menuProps) => (
                <>
                  {company || results.length > 0 ? <Menu {...menuProps}>
                    {results.length > 0 && results.map((result, index) => (
                      <MenuItem option={result.name} position={index} key={`${result.name}_${index}`}>{result.name}</MenuItem>
                    ))}
                    <MenuItem option="" className="font-italic text-info" position={results.length + 1} key={`custom_${results.length + 1}`} onClick={() => useCompanyName(setFieldValue)}>
                      {t('register.form.fields.companyName.useCompanyName')}
                      <span className="text-primary">{company}</span>
                    </MenuItem>
                  </Menu> : null}
                </>
              )}
              id="registerForm.company_name"
              label={t('register.form.fields.companyName.label')}
              name="company_name"
              fieldClass="data-hj-whitelist p-0 borer-bottom-0"
              component={FieldWrapper} /> : <Field required autofocus material data-hj-whitelist
                id="registerForm.company_name"
                label={t('register.form.fields.companyName.label')}
                name="company_name"
                inputRef={companyNameRef}
                fieldClass="data-hj-whitelist hide"
                component={FieldWrapper} />}
            <Field hideError={values.email.length === 0} required autofocus material data-hj-whitelist plaintext={app.invitation ? true : false} readOnly={app.invitation ? true : false} className="email"
              id="registerForm.email"
              label={t('common:form.fields.email.workLabel')}
              name="email"
              inputRef={emailRef}
              handleBlur={(e) => checkEmail(e.target.value)}
              handleChange={e => {
                const value = e.target.value.toLowerCase().trim();
                setFieldValue(e.target.name, value);
                setEmail(value);
              }}
              isValid={!errors.email && emailStatus && emailStatus.isValid && !emailStatus.pending}
              component={FieldWrapper}
              fieldClass="data-hj-whitelist"
              append={!errors.email && emailStatus && emailStatus.pending ? <Loader size="sm" className="mr-3" /> : undefined} />
            <Field hideError={values.phone.length === 0} required autofocus material data-hj-whitelist className="phone"
              id="registerForm.phone"
              label={t('common:form.fields.phone.label')}
              name="phone"
              type="tel"
              fieldClass="data-hj-whitelist"
              component={FieldWrapper} />
            <Field hideError={values.password.length === 0} required autofocus material showstrength
              id="registerPasswordForm.password"
              label={t('register.form.fields.password.label')}
              name="password"
              type="password"
              handleChange={e => setPassword(e.target.value)}
              component={FieldWrapper} />
            <Field hideError={values.confirmPassword.length === 0} required autofocus material className="confirmPassword"
              id="registerPasswordForm.confirmPassword"
              label={t('register.form.fields.confirmPassword.label')}
              name="confirmPassword"
              type="password"
              component={FieldWrapper} />
            <Form.Row className="d-flex align-items-center my-4">
              <Col>
                <Field required className="privacy text-mb mb-1"
                  id="registerForm.privacy"
                  name="privacy"
                  type="checkbox"
                  error={errors.privacy}
                  component={FieldWrapper}>
                  <Trans i18nKey="register.form.fields.privacy.label">I agree to the <a href={PRIVACY_POLICY_URL} className="text-reset" target="_blank">Privacy Policy</a>, the <a href={TERMS_OF_SERVICE_URL} className="text-reset" target="_blank">Terms of Service</a> and the <a href={SDK_AGREEMENT_URL} className="text-reset" target="_blank">SDK License Agreement</a>.</Trans>
                </Field>
              </Col>
            </Form.Row>
            <CaptchaWrapper className="form-group my-5" handleChange={(value) => setRecaptchaToken(value)} />
            <div className="d-block d-md-flex align-items-center">
              <LoaderButton className="mr-5 d-sm-block text-uppercase" variant="primary" type="submit" loadingLabel={t('common:status.registering')} loading={isSubmitting} disabled={Object.keys(errors).length || !isValid || !recaptchaToken || !Object.keys(touched).length}>{t('register.form.buttons.submit')}</LoaderButton>
              <p className="text-info mt-5 mb-0 mt-md-0 d-flex align-items-center flex-nowrap">{t('register.haveAccount')}<NavLink to="/login" className="ml-2 no-underline d-flex align-items-center"><span className="lnk">{t('common:buttons.login')}</span><i className="sila-icon long-arrow-right ml-2"></i></NavLink></p>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Register;