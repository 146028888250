import { getQueryString, handleResponseError } from '../utils';

const request = async (params) => {
  const method = params.method || 'GET';
  let qs = '';
  let body;
  const headers = params.headers || {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (['GET'].indexOf(method) > -1 && params.data) {
    qs = `?${getQueryString(params.data)}`;
  } else {
    // POST or PUT or DELETE
    body = JSON.stringify(params.data);
  }

  const url = params.url + qs;
  try {
    const response = await fetch(`/api/v2${url}`, {
      method,
      headers,
      body,
    });
    let result;
    if (params.binary) {
      result = await response.blob();
    } else {
      result = await response.json();
      result.status_code = response.status;
    }
    return result;
  } catch (error) {
    return error;
  }
};

const sandboxToggle = async (sandbox) => {
  try {
    const response = await request({
      method: 'POST',
      url: '/sandbox',
      data: {
        sandbox: sandbox,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const sendSlackMessage = async (values) => {
  try {
    const response = await request({
      method: 'POST',
      url: '/send_slack_message',
      data: values,
    });
    if (!response.success) {
      throw handleResponseError(response);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const getRecaptchaKey = async () => {
  try {
    const response = await request({
      method: 'GET',
      url: '/recaptcha_key',
    });
    return response;
  } catch (error) {
    throw error;
  }
};
const consoleApi = {
  get: (params) => request({ ...params, method: 'GET' }),
  post: (params) => request({ ...params, method: 'POST' }),
  put: (params) => request({ ...params, method: 'PUT' }),
  delete: (params) => request({ ...params, method: 'DELETE' }),
  sandboxToggle,
  sendSlackMessage,
  getRecaptchaKey,
};

export default consoleApi;
