/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { adminApi } from '../../api';
import { formatDateAndTime, capitalize, hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

import PageTitle from '../../components/common/PageTitle';
import DataList from '../../components/common/DataList';
import DisabledOverlay from '../../components/common/DisabledOverlay';
import {
  CheckboxColumnFilter,
  MultipleFilter,
  DateBetweenFilter,
  DateRangeColumnFilter,
  SelectColumnFilter,
} from '../../components/common/table/TableFilters';

const TABLE_MAX_COUNT = 20;
const TABLE_SLUG = 'apps';

const formatSortBy = (sortBy) => {
  const query = sortBy;
  return { url: sortBy, query };
};

const formatFilters = (filters) => {
  let query = filters;
  const created = filters.find((filter) => filter.id === 'created');
  if (created) {
    if (created.value) {
      query = [
        ...query.filter((filter) => filter.id !== 'created'),
        { id: 'start_date', value: created.value[0]?.slice(0, 10) },
        { id: 'end_date', value: created.value[1]?.slice(0, 10) },
      ];
    } else {
      query = [...query.filter((filter) => filter.id !== 'created')];
    }
  }
  return { url: filters, query };
};

function Applications({ t, history, user, env }) {
  const columns = useMemo(
    () => [
      {
        id: 'created',
        Header: t('common:labels.created'),
        accessor: 'created',
        filter: DateBetweenFilter,
        Filter: DateRangeColumnFilter,
        Cell: ({ value }) => <span className="text-nowrap">{formatDateAndTime(value)}</span>,
      },
      {
        id: 'nickname',
        Header: t('admin.labels.app'),
        accessor: 'nickname',
        filter: MultipleFilter,
        Filter: CheckboxColumnFilter,
        Cell: ({ value }) => (
          <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>
            {value}
          </span>
        ),
      },
      {
        id: 'brand_name',
        Header: t('applications.detail.form.fields.brand_name.label'),
        accessor: 'brand_name',
        filter: MultipleFilter,
        Filter: CheckboxColumnFilter,
        Cell: ({ value }) =>
          value ? (
            <span className="text-break d-block" style={{ maxWidth: 160, minWidth: 160 }}>
              {value}
            </span>
          ) : (
            <span className="text-info">{t('common:labels.na')}</span>
          ),
      },
      {
        id: 'handle',
        Header: t('common:labels.handle'),
        accessor: 'handle',
        filter: MultipleFilter,
        Filter: CheckboxColumnFilter,
        Cell: ({ value }) => (
          <span className="text-break d-block" style={{ maxWidth: 130, minWidth: 130 }}>
            {value}
          </span>
        ),
      },
      {
        id: 'team_name',
        Header: t('common:labels.team'),
        accessor: 'team_name',
        filter: MultipleFilter,
        Cell: ({ value }) => (
          <span className="text-break d-block" style={{ maxWidth: 120, minWidth: 120 }}>
            {value}
          </span>
        ),
        Filter: CheckboxColumnFilter,
      },
      {
        id: 'env',
        Header: t('applications.labels.env'),
        accessor: 'env',
        disableSortBy: true,
        disableFilters: true,
        Cell: () => t(`env.${env}.label`),
        Filter: CheckboxColumnFilter,
      },
      {
        id: 'frozen',
        Header: t('applications.labels.frozen'),
        accessor: 'frozen',
        Cell: ({ value }) => (value ? capitalize(value) : t('common:status.false')),
        Filter: SelectColumnFilter,
        columnStyles: () => ({ width: '1%' }),
        filterOptions: () => [
          {
            label: t('common:status.true'),
            value: 'true',
          },
          {
            label: t('common:status.false'),
            value: 'false',
          },
        ],
      },
      {
        id: 'sales_tier',
        Header: t('applications.labels.sales_tier'),
        accessor: 'sales_tier',
        filter: MultipleFilter,
        Cell: ({ value }) => value || <span className="text-info">{t('common:labels.na')}</span>,
        Filter: CheckboxColumnFilter,
        columnStyles: () => ({ width: '1%' }),
      },
      {
        id: 'vaccount_enabled',
        Header: t('applications.labels.vAccountsEnabled'),
        accessor: 'vaccount_enabled',
        Cell: ({ value }) => (value ? t('common:status.true') : t('common:status.false')),
        Filter: SelectColumnFilter,
        filterOptions: () => [
          {
            label: t('common:status.true'),
            value: 'true',
          },
          {
            label: t('common:status.false'),
            value: 'false',
          },
        ],
      },
    ],
    [env]
  );

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.applications.title')}>
          <Button
            variant="outline-light"
            className="back text-info"
            onClick={() => history.goBack()}
          >
            {t('common:buttons.back')}
          </Button>
        </PageTitle>
      </Row>

      {hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS]) ? (
        <DataList
          slug={TABLE_SLUG}
          filterName={TABLE_SLUG}
          limit={TABLE_MAX_COUNT}
          fetchData={adminApi.fetchApps}
          columns={columns}
          onRowClick={(row) => history.push(`/admin/app/${row.original.id}/detail`)}
          onFilter={formatFilters}
          onSortBy={formatSortBy}
          // eslint-disable-next-line no-shadow
          hasPermissions={({ user }) => hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])}
          loadingMessage={t('applications.loading')}
        />
      ) : (
        <div className="position-relative">
          <DisabledOverlay />
        </div>
      )}
    </Container>
  );
}

export default Applications;
