import React, { useState } from 'react';
import { Modal, Button, Table, Alert, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import LoaderButton from '../LoaderButton';
import { FreezeReasonsField, generateFreezeReasonValidationSchema } from '../FreezeReasonsField';

function FreezePaymentMethodModal({
  show,
  data,
  name,
  handleSubmitFreezePayment,
  setShowFreezePaymentMethodModal,
  isAdmin,
  customerDescription,
  adminDescription,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const validationSchema = isAdmin ? generateFreezeReasonValidationSchema(t) : null;

  const reasons = data.freeze_reasons;

  const onSubmit = async (freezeReason) => {
    setSubmitting(true);
    await handleSubmitFreezePayment(freezeReason);
    setSubmitting(false);
  };

  // format reasons and dates to display with linebreaks if Resource is frozen
  const formattedReasons = Object.entries(reasons).map(([reason, timestamp]) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });
    return (
      <span key={timestamp}>
        {reason} on {formattedDate}
        <br />
      </span>
    );
  });

  return (
    <Modal
      centered
      id="freeze-payment-method-modal"
      backdrop="static"
      show={show}
      size={data.transaction_list ? 'lg' : ''}
      onHide={() => setShowFreezePaymentMethodModal(false)}
      aria-labelledby="freeze-payment-method-modal-title"
    >
      <Formik
        initialValues={{
          freeze_reason: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, handleSubmit, dirty }) => (
          <Form noValidate autoComplete="new-password" onSubmit={handleSubmit}>
            <Modal.Header closeButton={!isSubmitting}>
              <Modal.Title id="freeze-payment-method-modal-title">
                {t(
                  `common:paymentMethods.modal.${
                    data.transaction_list
                      ? `pendingTransaction.title`
                      : `${data.frozen ? 'unFreeze' : 'freeze'}.title`
                  }`
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="text-lg mb-4">
                {t(
                  `common:paymentMethods.modal.${
                    data.transaction_list
                      ? `pendingTransaction.confirm`
                      : `${data.frozen ? 'unFreeze' : 'freeze'}.confirm`
                  }`,
                  {
                    payment_name: data.payment_name || t('common:labels.noName'),
                    payment_method: data.payment_method_type,
                    name,
                  }
                )}
              </p>
              {!data.transaction_list && (
                <p className="text-meta">
                  {t(
                    `common:paymentMethods.modal.${
                      data.frozen ? 'unFreeze' : 'freeze'
                    }.description`,
                    {
                      payment_method: data.payment_method_type,
                    }
                  )}
                </p>
              )}
              {data.transaction_list && data.transaction_list.length > 0 && (
                <>
                  <Alert variant="warning" className="mb-4 loaded">
                    <i className="fas fa-exclamation-triangle text-warning" />
                    {t('common:paymentMethods.modal.pendingTransaction.warning')}
                  </Alert>
                  <Table bordered responsive className="border-0">
                    <thead>
                      <tr className="bg-secondary">
                        <th className="border-right border-light pl-4 py-3">
                          {t('common:labels.app')}
                        </th>
                        <th className="border-right border-light px-4 py-3">
                          {t('common:transactions.labels.type')}
                        </th>
                        <th className="border-right border-light px-4 py-3">
                          {t('common:transactions.labels.amount')}
                        </th>
                        <th className="border-right border-light pl-4 py-3">
                          {t('common:transactions.labels.source')}
                        </th>
                        <th className="border-right border-light pl-4 py-3">
                          {t('common:transactions.labels.destination')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.transaction_list.map((transaction, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                          <td className="px-2 py-2">{transaction.app}</td>
                          <td className="px-2 py-2">{transaction.transaction_type}</td>
                          <td className="px-2 py-2">
                            <i className="sila-icon sila text-primary" /> {transaction.amount}
                          </td>
                          <td className="px-2 py-2">{transaction.source_type}</td>
                          <td className="px-2 py-2">{transaction.destination_type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
              {!isAdmin && <p className="text-lg mb-4">{customerDescription}</p>}
              {isAdmin && <p className="text-lg mb-4">{adminDescription}</p>}
              {isAdmin && Object.values(reasons).length !== 0 && (
                <div className="text-md mb-4">
                  {Object.values(reasons).length === 1
                    ? t('common:freezeUnfreeze.model.frozenListDescriptorSingular')
                    : t('common:freezeUnfreeze.model.frozenListDescriptorPlural')}
                  <br />
                  {formattedReasons}
                </div>
              )}
              {isAdmin && <FreezeReasonsField isSubmitting={isSubmitting} t={t} />}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-light"
                disabled={isSubmitting}
                onClick={() => setShowFreezePaymentMethodModal(false)}
              >
                {t('common:buttons.cancel')}
              </Button>
              <LoaderButton
                loading={isSubmitting}
                onClick={isSubmitting ? (e) => e.preventDefault() : undefined}
                type="submit"
                disabled={isAdmin && (!dirty || Object.keys(errors).length)}
              >
                {t(
                  data.transaction_list
                    ? `common:paymentMethods.modal.pendingTransaction.button`
                    : `common:labels.${data.frozen ? 'unfreeze' : 'freeze'}`
                )}
              </LoaderButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

FreezePaymentMethodModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The console user name
   */
  name: PropTypes.string.isRequired,
  /**
   * The payment method data object
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  /**
   * The hide function to close the modal
   */
  handleSubmitFreezePayment: PropTypes.func.isRequired,
  /**
   * The hide function to close the modal
   */
  setShowFreezePaymentMethodModal: PropTypes.func.isRequired,
  /**
   * Controls visiblity of freeze reasons component
   */
  isAdmin: PropTypes.bool.isRequired,
};

export default FreezePaymentMethodModal;
