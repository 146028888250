import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const PaymentInstrumentModal = ({ show, data, onHide }) => {
  const { t } = useTranslation();

  return (
    <Modal centered id="payment-instrument-modal"
      backdrop="static"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="payment-instrument-modal-title">
      <Modal.Header closeButton>
        <Modal.Title id="payment-instrument-modal-title">{t('common:paymentMethods.modal.detail.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <thead>
            <tr>
              <th className="py-2 pl-4" width="50%">{t('common:labels.detail')}</th>
              <th className="py-2 pr-4" width="50%">{t('common:labels.data')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 pl-4" width="50%">{t('common:paymentMethods.labels.method')}</td>
              <td className="py-2 pr-4" width="50%">{data.payment_method_type}</td>
            </tr>
            {data.payment_method_type === 'Bank Account' && <>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:paymentMethods.labels.name')}</td>
                <td className="py-2 pr-4" width="50%">{data.account_name}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.routing')}</td>
                <td className="py-2 pr-4" width="50%">{data.routing_number}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.account')}</td>
                <td className="py-2 pr-4" width="50%">{data.account_number}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.institutionName')}</td>
                <td className="py-2 pr-4" width="50%">{data.institution_name}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.accountLinkStatus')}</td>
                <td className="py-2 pr-4" width="50%">{data.account_link_status}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.active')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${data.active ? 'true' : 'false'}`)}</td>
              </tr>
            </>}
            {data.payment_method_type === 'Wallet' && <>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:paymentMethods.labels.name')}</td>
                <td className="py-2 pr-4" width="50%">{data.nickname}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.wallets.type.label')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:transactions.labels.wallets.type.${data.blockchain_network.toLowerCase()}`)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.wallets.type.address')}</td>
                <td className="py-2 pr-4" width="50%">{data.blockchain_address}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.wallets.type.walletId')}</td>
                <td className="py-2 pr-4" width="50%">{data.payment_method_id}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.wallets.type.isDefault')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:labels.${data.default ? 'yes' : 'no'}`)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.active')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${!data.frozen ? 'true' : 'false'}`)}</td>
              </tr>
            </>}
            {data.payment_method_type === 'Virtual Account' && <>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:paymentMethods.labels.name')}</td>
                <td className="py-2 pr-4" width="50%">{data.virtual_account_name}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.account')}</td>
                <td className="py-2 pr-4" width="50%">{data.account_number}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.routing')}</td>
                <td className="py-2 pr-4" width="50%">{data.routing_number}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.active')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${data.active ? 'true' : 'false'}`)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.virtualAccount.achCreditEnabled')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${data.ach_credit_enabled ? 'true' : 'false'}`)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.virtualAccount.achDebitEnabled')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${data.ach_debit_enabled ? 'true' : 'false'}`)}</td>
              </tr>
            </>}
            {data.payment_method_type === 'Card' && <>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:paymentMethods.labels.name')}</td>
                <td className="py-2 pr-4" width="50%">{data.card_name}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.cardLastFour')}</td>
                <td className="py-2 pr-4" width="50%">{data.card_last_4 ? `********${data.card_last_4}` : ''}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.expiration')}</td>
                <td className="py-2 pr-4" width="50%">{data.expiration ? `${data.expiration.slice(-2)}/${data.expiration.substring(0, 4).slice(-2)}` : ''}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.type')}</td>
                <td className="py-2 pr-4" width="50%">{data.card_type}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.pullEnabled')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${data.pull_enabled ? 'true' : 'false'}`)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.pushEnabled')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${data.push_enabled ? 'true' : 'false'}`)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.pushAvailability')}</td>
                <td className="py-2 pr-4" width="50%">{data.push_availability}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:labels.active')}</td>
                <td className="py-2 pr-4" width="50%">{t(`common:status.${data.active ? 'true' : 'false'}`)}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.country')}</td>
                <td className="py-2 pr-4" width="50%">{data.country}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.currency')}</td>
                <td className="py-2 pr-4" width="50%">{data.currency}</td>
              </tr>
              <tr>
                <td className="py-2 pl-4" width="50%">{t('common:transactions.labels.cards.cardID')}</td>
                <td className="py-2 pr-4" width="50%">{data.card_id ? `${data.card_id.split("-")[0]}-xxxx-xxxx-xxxx-xxxx` : ''}</td>
              </tr>
            </>}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  )
};

PaymentInstrumentModal.propTypes = {
  /**
   * The visibility boolean
   */
  show: PropTypes.bool.isRequired,
  /**
   * The payment instrument data object
   */
  data: PropTypes.object.isRequired,
  /**
   * The hide function to close the modal
   */
  onHide: PropTypes.func.isRequired
};

export default PaymentInstrumentModal;