import React from 'react';
import { Container, Button, Row } from 'react-bootstrap';

import { teamApi } from '../../api';
import { hasPermissions } from '../../utils';

import PageTitle from '../../components/common/PageTitle';
import EndUsersList from '../../components/common/endusers/EndUsersList';

const EndUsers = ({ t, history, user }) => {

  return (
    <Container className="main-content-container settings-end-users p-3 p-md-5 d-flex flex-column flex-grow-1 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.endUsers.title')}>
          <Button variant="outline-light" className="back text-meta" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      <EndUsersList
        customer={true}
        filterName={`team_${user.currentTeam.id}_end_users`}
        fetchData={teamApi.getEndUsers}
        fetchOptions={{ team: user.currentTeam.id }}
        onRowClick={(row) => history.push(`/settings/end_user/${row.original.user_handle}/profile`)}
        hasPermissions={({ user }) => hasPermissions(user, 'transactions', 'Read')} />

    </Container>
  )
};

export default EndUsers;
