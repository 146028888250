import React, { useState, useRef } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { appsApi } from '../../api';

import { useAuth, useAlerts } from '../context';

import CopyButton from '../common/CopyButton';
import ConfirmationModal from '../common/ConfirmationModal';
import ConfirmPasswordActionForm from '../auth/ConfirmPasswordActionForm';

const ClientSecretFieldWrapper = ({ app, credentials, onGenerate, tabIndex, readOnly, plaintext, disableGenerate }) => {
  const [confirm, setConfirm] = useState(false);
  const inputRef = useRef(null);
  const { user } = useAuth();
  const { setAlert } = useAlerts();
  const { t } = useTranslation();

  const generate = async (app) => {
    if (app) {
      try {
        const response = await appsApi.regenerateClientSecret(app);
        credentials.client_secret = response.client_secret;
        if (onGenerate) onGenerate(credentials);
        setConfirm(false);
        return credentials;
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    } else {
      setConfirm(false);
    }
  };

  return (<>
    <Form.Group className={classNames('client_secret', readOnly && 'readonly', plaintext && 'plaintext mb-0')} controlId={`clientSecretGenForm.${uuid()}.client_secret`}>
      <Form.Label>{t('common:applications.client_secret')}</Form.Label>
      <InputGroup>
        <Form.Control tabIndex={tabIndex} autoComplete="new-password" type={credentials.client_secret ? 'text' : 'password'} name="client_secret" className={credentials.client_secret ? 'text-ellipsis w-50' : undefined} value={!credentials.client_secret ? '•••••••••••••••••••••••••••••••' : credentials.client_secret} readOnly plaintext={plaintext} />
        <input className="sr-only" type="text" name="client_secret_hidden" value={credentials.client_secret} ref={inputRef} readOnly />
        <InputGroup.Append className={classNames('d-flex align-items-center', plaintext && 'border-0 rounded-0 p-0')}>
          {credentials.client_secret && <>
            <strong className="text-success mr-2 ml-3">{t('applications.labels.generated')}</strong>
            {document.queryCommandSupported('copy') && <CopyButton inputRef={inputRef} variant="link" placement="top" className="p-1" />}
          </>}
          {!disableGenerate && <Button className="generate ml-2 rounded-sm" variant="primary" size="sm" onClick={() => setConfirm(true)}>{t('common:buttons.regenerate')}</Button>}
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>

    {confirm &&
      <ConfirmationModal buttonDisabled
        show={confirm}
        setShow={setConfirm} 
        data={app}
        title={t('applications.regenerate.title')}
        renderMessageComponent={({ setDisabled }) => <>
          <p className="text-lg mb-4">{t('applications.regenerate.description')}</p>
          <p className="text-info mb-4">{t('applications.regenerate.note')}</p>
          <ConfirmPasswordActionForm onPasswordConfirm={(isValid) => setDisabled(!isValid)} />
        </>}
        buttonLabel={t('common:buttons.regenerate')}
        handleSubmit={generate} />}
  </>);
};

ClientSecretFieldWrapper.propTypes = {
  /**
   * The applicatioin id
   */
  app: PropTypes.number.isRequired,
  /**
   * The application credentials
   */
  credentials: PropTypes.object,
  /**
   * The function that is called when the secret is generated.
   */
  onGenerate: PropTypes.func,
  /**
   * The read only boolean
   */
  readOnly: PropTypes.bool,
  /**
   * The tab index number
   */
  tabIndex: PropTypes.number,
  /**
   * The plain text boolean
   */
  plaintext: PropTypes.bool,
  /**
   * The disable generate button boolean
   */
  disableGenerate: PropTypes.bool
};

const ClientIdFieldWrapper = ({ credentials, readOnly, tabIndex, plaintext }) => {
  const inputRef = useRef(null);
  const { t } = useTranslation();
  return (
    <Form.Group className={classNames('client_id', readOnly && 'readonly', plaintext && 'plaintext mb-0')} controlId={`clientSecretGenForm.${uuid()}.client_id`}>
      <Form.Label>{t('common:applications.client_id')}</Form.Label>
      <InputGroup>
        <Form.Control tabIndex={tabIndex} autoComplete="new-password" type="text" name="client_id" value={credentials.client_id} readOnly plaintext={plaintext} />
        <input className="sr-only" type="text" name="client_id_hidden" value={credentials.client_id} ref={inputRef} readOnly />
        <InputGroup.Append className={classNames('d-flex align-items-center', plaintext && 'border-0 rounded-0 p-0')}>
          {document.queryCommandSupported('copy') && <CopyButton inputRef={inputRef} variant="link" placement="top" className="p-1 ml-2" />}
        </InputGroup.Append>
      </InputGroup>
    </Form.Group>
  );
};

ClientIdFieldWrapper.propTypes = {
  /**
   * The generated application client credentials
   */
  credentials: PropTypes.object,
  /**
   * The read only boolean
   */
  readOnly: PropTypes.bool,
  /**
   * The tab index number
   */
  tabIndex: PropTypes.number,
  /**
   * The plain text boolean
   */
  plaintext: PropTypes.bool
};

const ClientSecretGenForm = ({ app, credentials, onGenerate, readOnly, disableGenerate }) => {
  return (
    <>
      <ClientIdFieldWrapper readOnly={readOnly} credentials={credentials} />
      <ClientSecretFieldWrapper app={app} credentials={credentials} onGenerate={onGenerate} readOnly={readOnly} disableGenerate={disableGenerate} />
    </>
  );
};

ClientSecretGenForm.propTypes = {
  /**
   * The Application ID
   */
  app: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  /**
   * The generated application client credentials
   */
  credentials: PropTypes.object,
  /**
   * The function that is called when the secret is generated.
   */
  onGenerate: PropTypes.func,
  /**
   * The read only boolean
   */
  readOnly: PropTypes.bool,
  /**
   * The disable generate button boolean
   */
  disableGenerate: PropTypes.bool
};

export default ClientSecretGenForm;

export const ClientSecretField = ClientSecretFieldWrapper;
export const ClientIdField = ClientIdFieldWrapper;