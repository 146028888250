import React, { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';

import { adminApi } from '../../api';
import { useAlerts } from '../context';
import { formatDateAndTime, uploadDocument } from '../../utils';

import TooltipButton from '../common/TooltipButton';
import Loader from '../common/Loader';
import DocumentUploadModal from '../common/DocumentUploadModal';
import DocumentPreviewModal from '../common/DocumentPreviewModal';

const OnboardingDocuments = ({ match, hasWriteAccess }) => {
  const { t } = useTranslation();
  const { setAlert } = useAlerts();

  const [loaded, setLoaded] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [upload, setUpload] = useState({ show: false, percentage: {} });
  const [preview, setPreview] = useState({ show: false, data: undefined });
  const [isDownloading, setIsDownloading] = useState(false);
  const [types, setTypes] = useState([]);

  const getDocumentTypes = async () => {
    try {
      const response = await adminApi.getDocumentTypes();
      setTypes(response.document_types);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    }
  };

  const getDocuments = async () => {
    try {
      const response = await adminApi.getCustomerInfoDocuments(match.params.id);
      setDocuments(response.documents);
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
    } finally {
      setLoaded(true);
    }
  };

  const getDocument = async (document) => {
    if (document) {
      setPreview({ show: true, date: undefined });
      try {
        const response = await adminApi.getCustomerInfoDocument(match.params.id, document.uuid);
        let blob = new Blob([response], { type: document.file_type === 'pdf' ? 'application/pdf' : `image/${document.file_type}` });
        setPreview({ show: true, data: { ...document, file: URL.createObjectURL(blob) } });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        setPreview({ show: false, date: undefined });
      }
    }
  };

  const downloadDocument = async (document) => {
    if (document) {
      try {
        setIsDownloading(document.uuid);
        const response = await adminApi.getCustomerInfoDocument(match.params.id, document.uuid)
        let blob = new Blob([response], { type: document.file_type === 'pdf' ? 'application/pdf' : `image/${document.file_type}` });
        let a = window.document.createElement("a");
        window.document.body.appendChild(a);
        a.style = "display: none";
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = document.filename;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      } finally {
        setIsDownloading(false);
      }
    }
  };

  const addDocument = (values, setSubmitting) => {
    if (hasWriteAccess && values) {
      const name = values.files[0].name;
      const data = {
        name,
        filename: name,
        mime_type: values.files[0].type,
        document_type: values.document_type_name || values.document_type,
        identity_type: values.document_type_name || values.identity_type
      };

      uploadDocument({
        url: `/superuser/customer_onboard_info_documents/${match.params.id}`,
        file: values.files[0],
        data: data,
        onUpload: (percentage) => setUpload({ ...upload, percentage: { ...upload.percentage, [name]: percentage } }),
        onError: (status, error) => {
          if (setSubmitting) setSubmitting(false);
          setAlert({ message: status === 413 ? t('common:upload.form.messages.filesize') : error, style: 'danger' });
          setUpload({ ...upload, percentage: {} });
        },
        onSuccess: (message) => {
          delete upload.percentage[name];
          setUpload({ show: false, percentage: upload.percentage });
          setAlert({ message, style: 'success' });
          getDocuments();
        }
      });
    } else {
      setUpload({ show: false, percentage: {} });
    }
  };

  useEffect(() => {
    getDocumentTypes();
    getDocuments();
  }, []);

  return (<>

    <h4 className="mb-2">{t('admin.customers.docs.title')}</h4>
    <p className="text-info mb-4">{t('admin.customers.docs.description')}</p>

    {(!loaded) ? <Loader /> :

      <>
        <Card className="mb-2 border loaded overflow-hidden">
          <Table bordered responsive className="border-0">
            <thead>
              <tr>
                <th className="border-right pl-4 py-3">{t('common:upload.labels.date')}</th>
                <th className="border-right px-4 py-3">{t('common:upload.labels.name')}</th>
                <th className="border-right px-4 py-3">{t('common:upload.labels.type')}</th>
                <th className="pr-4 py-3 text-center" width="1%">{t('common:labels.action')}</th>
              </tr>
            </thead>
            <tbody>
              {documents.length !== 0 ? documents.map((document, index) => <tr key={index}>
                <td className="pl-4 py-3">{formatDateAndTime(document.created)}</td>
                <td className="px-4 py-3"><span className="w-100 d-block text-break pr-3 text-left" style={{ maxWidth: '300px' }}>{document.filename}</span></td>
                <td className="px-4 py-3">{types.find(type => type.name === document.document_type)?.label}</td>
                <td className="pr-4 py-3 text-center" width="1%">
                  <div className="d-flex flex-nowrap">
                    <TooltipButton placement="top" variant="link" popoverProps={{ style: { textAlign: 'center' } }} onClick={() => getDocument(document)} text={t('common:labels.preview')} className="p-0 cursor-pointer text-info-link mr-4"><i className="text-lg fas fa-eye"></i></TooltipButton>
                    <TooltipButton disabled={isDownloading && isDownloading === document.uuid} placement="top" variant="link" popoverProps={{ style: { textAlign: 'center' } }} rootClose={true} onClick={() => downloadDocument(document)} text={t(`common:${isDownloading ? 'status.downloading' : 'labels.download'}`)} className="p-0 cursor-pointer text-info-link">{isDownloading && isDownloading === document.uuid ? <div className="position-relative"><Loader size="sm" /></div> : <i className="text-lg fas fa-download"></i>}</TooltipButton>
                  </div>
                </td>
              </tr>) :
                <tr className="disabled">
                  <td className="p-0 cursor-default" colSpan="4"><p className="text-center text-info font-italic mx-4 my-5">{t('common:upload.empty')}</p></td>
                </tr>}
            </tbody>
          </Table>
        </Card>

        {hasWriteAccess && <p className="loaded text-right mb-5"><Button variant="link" className="p-0 text-underline" onClick={() => setUpload({ ...upload, show: true })}>{t('common:upload.buttons.upload')}</Button></p>}

      </>}

    {upload.show && <DocumentUploadModal show={upload.show} types={types} percentage={upload.percentage} onHide={addDocument} />}

    {preview.show && <DocumentPreviewModal show={preview.show} data={preview.data} onHide={() => setPreview({ show: false, data: undefined })} />}

  </>
  );
};

OnboardingDocuments.propTypes = {
  /**
   * The react dom match object
   */
  match: PropTypes.object.isRequired,
  /**
   * The write access boolean
   */
   hasWriteAccess: PropTypes.bool.isRequired
};

export default OnboardingDocuments;