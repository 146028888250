import React, { useState, useEffect, useRef } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { teamApi } from '../../api';

import { DEFAULT_ADDRESS, DEFAULT_PHONE, DEFAULT_IDENTITY, DEFAULT_EMAIL } from '../../constants';

import Loader from '../../components/common/Loader';
import PageTitle from '../../components/common/PageTitle';
import EndUserForm from '../../components/common/endusers/EndUserForm';
import ConfirmModal from '../../components/common/ConfirmModal';
import PaymentMethods from '../../components/common/endusers/PaymentMethods';

const EndUserProfile = ({ t, match, setAlert, history, user }) => {
  const [key, setKey] = useState('profile');
  const [isLoading, setIsLoading] = useState(true);
  const [confirm, setConfirm] = useState({ show: false, data: undefined });
  const [redirect, setRedirect] = useState(false);
  const [isSmsOptin, setIsSmsOptin] = useState(true);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    full_name: '',
    handle: '',
    identities: DEFAULT_IDENTITY,
    birthdate: '',
    emails: DEFAULT_EMAIL,
    phones: DEFAULT_PHONE,
    addresses: DEFAULT_ADDRESS,
    sms_enabled: true
  });
  const [name, setName] = useState(`${initialValues.first_name} ${initialValues.last_name}`);
  const formRef = useRef(null);

  const getEndUser = async (callback) => {
    try {
      const [endUserResponse, paymentInstruments] = await Promise.all([
        teamApi.getEndUser(match.params.handle, user.currentTeam.id),
        teamApi.getEndUserPaymentInstruments({ team: user.currentTeam.id, handle: match.params.handle })
      ]);
      if (endUserResponse.success && paymentInstruments.success) {
        const full_name = endUserResponse.entity_name || `${endUserResponse.first_name} ${endUserResponse.last_name}`;
        const data = {
          first_name: endUserResponse.first_name,
          last_name: endUserResponse.last_name,
          full_name: full_name,
          handle: endUserResponse.user_handle,
          identities: [{ identity: endUserResponse.ssn }],
          birthdate: endUserResponse.birthdate,
          emails: [endUserResponse.email],
          phones: [endUserResponse.phone],
          addresses: [endUserResponse.address],
          sms_enabled: endUserResponse.sms_enabled,
          payment_instruments: paymentInstruments.payment_instruments
        };
        setName(full_name);
        setInitialValues(data);
        if (callback) callback(data);
      } else {
        setRedirect(true);
      }
    } catch (error) {
      setAlert({ message: error, variant: 'danger' });
      setRedirect(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (values) => {
    if (values) {
      try {
        const { phones, emails, addresses, handle, identities, ...newValues } = values;
        await teamApi.updateEndUser(initialValues.handle, { ...newValues, user_handle: handle, phone: phones[0], email: emails[0], address: addresses[0] }, user.currentTeam.id);
        setAlert({ message: t(`admin.user.update.message`, { name: values.full_name }), variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'danger' });
        formRef.current.resetForm({ value: initialValues });
      } finally {
        setConfirm({ show: false, data: undefined });
        getEndUser();
      }
    } else {
      formRef.current.setSubmitting(false);
      setConfirm({ show: false, data: undefined });
    }
  };

  const endUserSmsOptin = async (id) => {
    try {
      await teamApi.smsOptIn(initialValues.handle, id, user.currentTeam.id);
      setAlert({ message: t(`common:form.fields.sms.success`), variant: 'success' });
      getEndUser();
      setIsSmsOptin(false);
    } catch (error) {
      setAlert({ message: error, variant: 'danger' });
    }
  };

  useEffect(() => {
    setKey(match.params.action);
  }, [match]);

  useEffect(() => {
    if (redirect) history.push('/settings/end_users');
  }, [redirect]);

  useEffect(() => {
    getEndUser();
  }, []);

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">

      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.endUsers.profile.title')}>
          <Button variant="outline-light" className="back text-meta" onClick={() => history.goBack()}>{t('common:buttons.back')}</Button>
        </PageTitle>
      </Row>

      {isLoading ? <Loader /> :
        <Card className="mb-3 mb-md-5 loaded">
          <Card.Header className="p-4">
            <h3 className="m-0"><i className="sila-icon mr-2 text-primary individual"></i> {name}</h3>
          </Card.Header>
          <Card.Body as={Row} className="p-0" noGutters>
            <Tab.Container activeKey={key} defaultActiveKey={key} onSelect={k => setKey(k)}>
              <Col md="4" lg="3" xl="2" className="border-right">
                <Nav className="d-flex flex-column align-content-start h-100 py-4">
                  <Nav.Item className="w-100">
                    <Nav.Link className={`d-block px-4 py-3${key === 'profile' ? ' bg-primary-accent text-primary' : ''}`} eventKey="profile" as={NavLink} to={{ pathname: `/settings/end_user/${match.params.handle}/profile`, state: { from: location.pathname } }}>{t('admin.endUsers.navigation.profile')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="w-100">
                    <Nav.Link className={`d-block px-4 py-3${key === 'payment-instruments' ? ' bg-primary-accent text-primary' : ''}`} eventKey="payment-instruments" as={NavLink} to={{ pathname: `/settings/end_user/${match.params.handle}/payment-instruments`, state: { from: location.pathname } }}>{t('admin.endUsers.navigation.paymentInstruments')}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md="8" lg="9" xl="10" className="p-4">
                <Tab.Content>
                  <Tab.Pane eventKey="profile">
                    <EndUserForm customer
                      formRef={formRef}
                      initialValues={initialValues}
                      setName={setName}
                      onSubmit={(values) => setConfirm({ ...confirm, show: true, data: values })}
                      formFooterComponent={({ values }) => <>
                        <Button className="mr-auto" disabled={values.sms_enabled || !isSmsOptin === true} onClick={() => endUserSmsOptin(values.phones[0].id)}>{t('admin.endUsers.profile.headers.sms')}</Button>
                        <Button variant="outline-light" className="ml-4" onClick={() => history.push(`/settings/transactions?user_handle=${values.handle}`)}>{t('common:buttons.viewTransactions')}</Button>
                        {/* COMMENTED TILL THE STATEMENTS LIST IS READY
                          <Button variant="outline-light" className="ml-4" onClick={() => history.push(`#`)}>{t('common:buttons.viewStatements')}</Button> */}
                      </>} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="payment-instruments">
                    <PaymentMethods
                      initialValues={initialValues}
                      getEndUser={getEndUser}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Card.Body>
        </Card>}

      {confirm.show && <ConfirmModal
        show={confirm.show}
        data={confirm.data}
        title={t('settings.endUsers.user.confirm.title')}
        renderMessageComponent={() => <>
          <p className="text-lg text-info">{t(`settings.endUsers.user.confirm.description`, { name: `${confirm.data.full_name}` })}</p>
        </>}
        buttonLabel={t('settings.endUsers.user.confirm.action')}
        onHide={updateUser} />}

    </Container>
  );
};

export default EndUserProfile; 
