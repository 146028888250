import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { adminApi } from '../../api';

import EndUserForm from '../common/endusers/EndUserForm';
import ConfirmModal from '../common/ConfirmModal';

import { formatDateAndTime } from '../../utils';

import { useAlerts } from '../context';

function EndUserProfile({ initialValues, phones, getEndUser }) {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState({ show: false, data: undefined });
  const formRef = useRef(null);
  const { setAlert } = useAlerts();

  const updateUser = async (values) => {
    if (values) {
      try {
        await adminApi.updateEndUser(initialValues.handle, values);
        getEndUser();
        setAlert({
          message: t(`admin.user.update.message`, { name: values.full_name }),
          variant: 'success',
        });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
        formRef.current.resetForm({ values: initialValues });
      } finally {
        setConfirm({ ...confirm, show: false, data: undefined });
      }
    } else {
      formRef.current.setSubmitting(false);
      setConfirm({ ...confirm, show: false, data: undefined });
    }
  };

  return (
    <>
      <EndUserForm
        formRef={formRef}
        initialValues={initialValues}
        onSubmit={(values) => setConfirm({ ...confirm, show: true, data: values })}
        // eslint-disable-next-line react/no-unstable-nested-components
        formFooterComponent={() => (
          <div className="d-flex mr-auto">
            <div className="d-flex mr-5">
              <h4 className="text-info text-uppercase text-reg mr-3">
                {t('admin.endUsers.profile.headers.device')}:
              </h4>
              <span>{t(`common:labels.${initialValues.devices.length ? 'yes' : 'no'}`)}</span>
            </div>

            <div className="d-flex">
              <h4 className="text-info text-uppercase text-reg mr-3">
                {t('admin.endUsers.profile.headers.sms')}:
              </h4>
              <span>
                {phones.some((phone) => phone.sms_confirmation_requested && !phone.sms_confirmed) &&
                  `${phones
                    .filter((phone) => phone.sms_confirmation_requested)
                    .map((phone) => phone.phone)
                    .join(', ')} (${t('common:status.requested')})`}
                {phones.some((phone) => phone.sms_confirmed) &&
                  `${
                    phones.some((phone) => phone.sms_confirmation_requested && !phone.sms_confirmed)
                      ? ', '
                      : ''
                  }${phones
                    .filter((phone) => phone.sms_confirmed)
                    .map((phone) => phone.phone)
                    .join(', ')} (${t('common:status.confirmed')})`}
                {phones.some(
                  (phone) => !phone.sms_confirmation_requested || !phone.sms_confirmed
                ) && t('common:labels.no')}
              </span>
            </div>
          </div>
        )}
      />

      {initialValues.wallets && initialValues.wallets.length !== 0 && (
        <>
          <h4 className="mt-5 mb-3">{t('admin.endUsers.profile.headers.wallets')}</h4>
          <Card className="mb-5 border overflow-hidden">
            <Table bordered responsive className="border-0">
              <thead>
                <tr>
                  <th className="border-right pl-4 py-3">
                    {t('applications.detail.form.fields.address.label')}
                  </th>
                  <th className="border-right px-4 py-3">
                    {t('common:transactions.labels.wallets.type.label')}
                  </th>
                  <th className="pr-4 py-3">
                    {t('common:transactions.labels.wallets.migration.label')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {initialValues.wallets.map((wallet) => (
                  <tr key={wallet.blockchain_address}>
                    <td className="pl-4 py-3">{wallet.blockchain_address}</td>
                    <td className="px-4 py-3">
                      {t(
                        `common:transactions.labels.wallets.type.${wallet.blockchain_network.toLowerCase()}`
                      )}
                    </td>
                    <td className="pr-4 py-3">
                      {formatDateAndTime(wallet.migrated_at) || (
                        <span className="text-info">{t('common:labels.na')}</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </>
      )}

      {initialValues.memberships && initialValues.memberships.length !== 0 && (
        <>
          <h4 className="mb-3">
            {t(
              `admin.endUsers.profile.headers.${
                initialValues.entity_type === 'business' ? 'members' : 'memberships'
              }`
            )}
          </h4>
          <Card className="mb-5 border overflow-hidden">
            <Table bordered responsive className="border-0">
              <thead>
                <tr>
                  <th className="border-right pl-4 py-3">{t('common:form.fields.name.label')}</th>
                  <th className="border-right px-4 py-3" width="1%">
                    {t('common:kyb.roles.controlling_officer')}
                  </th>
                  <th className="border-right px-4 py-3" width="1%">
                    {t('common:kyb.roles.beneficial_owner')}
                  </th>
                  <th className="border-right px-4 py-3" width="1%">
                    {t('common:kyb.roles.admin')}
                  </th>
                  {initialValues.memberships.some(
                    (membership) => membership.certification_token
                  ) && <th className="pr-4 py-3">{t('common:kyb.certification_status')}</th>}
                </tr>
              </thead>
              <tbody>
                {initialValues.memberships.map((membership, index) => (
                  <tr key={membership.business_handle || membership.user_handle + index}>
                    <td className="pl-4 py-3">
                      <Button
                        href={`/admin/end_user/${
                          membership.business_handle || membership.user_handle
                        }/profile`}
                        variant="link"
                        className="text-reset text-underline p-0"
                      >
                        <span
                          className="w-100 d-block text-break pr-3 text-left"
                          style={{ maxWidth: '160px' }}
                        >
                          {membership.entity_name || membership.user_handle}
                        </span>
                      </Button>
                    </td>
                    <td className="px-4 py-3 text-center">
                      {membership.role === 'controlling_officer' && (
                        <i className="fas fa-circle text-primary" />
                      )}
                    </td>
                    <td className="px-4 py-3 text-center">
                      {membership.role === 'beneficial_owner' && (
                        <i className="fas fa-circle text-primary" />
                      )}
                    </td>
                    <td className="px-4 py-3 text-center">
                      {membership.role === 'administrator' && (
                        <i className="fas fa-circle text-primary" />
                      )}
                    </td>
                    {membership.certification_token && (
                      <td className="pr-4 py-3">
                        {t(
                          `common:kyb.${
                            membership.certification_token ? 'certified' : 'not_certified'
                          }`
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </>
      )}

      {initialValues.apps && initialValues.apps.length !== 0 && (
        <>
          <h4 className="mb-3">{t('admin.endUsers.profile.headers.apps')}</h4>
          <Card className="mb-5 border overflow-hidden">
            <Table bordered responsive className="border-0">
              <thead>
                <tr>
                  <th className="border-right pl-4 py-3">
                    {t('applications.detail.form.fields.name.label')}
                  </th>
                  <th className="border-right px-4 py-3">
                    {t('applications.detail.form.fields.handle.label')}
                  </th>
                  <th className="pr-4 py-3">{t('common:labels.created')}</th>
                </tr>
              </thead>
              <tbody>
                {initialValues.apps.map((app) => (
                  <tr key={`${app.id}app_id`}>
                    <td className="pl-4 py-3">
                      <Link
                        to={{
                          pathname: `/admin/app/${app.app_id}/detail`,
                        }}
                        className="text-reset text-underline p-0"
                      >
                        {app.app_nickname}
                      </Link>
                    </td>
                    <td className="px-4 py-3">
                      <Link
                        to={{
                          pathname: `/admin/app/${app.app_id}/detail`,
                        }}
                        className="text-reset text-underline p-0"
                      >
                        <span
                          className="w-100 d-block text-break pr-3 text-left"
                          style={{ maxWidth: '160px' }}
                        >
                          {app.app_handle}
                        </span>
                      </Link>
                    </td>
                    <td className="pr-4 py-3">{formatDateAndTime(app.added)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </>
      )}

      {confirm.show && (
        <ConfirmModal
          show={confirm.show}
          data={confirm.data}
          title={t('admin.user.update.title')}
          renderMessageComponent={() => (
            <>
              <p className="text-lg mb-4">
                {t(`admin.user.update.confirm`, { name: confirm.data.full_name })}
              </p>
              <p className="text-info">
                {t(`admin.user.update.description`, {
                  name: confirm.data.full_name,
                  handle: confirm.data.handle,
                })}
              </p>
            </>
          )}
          buttonLabel={t('admin.user.update.action')}
          onHide={updateUser}
        />
      )}
    </>
  );
}

EndUserProfile.propTypes = {
  /**
   * The values of the form
   */
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  /**
   * The end users phones array to determine SMS opt in
   */
  // eslint-disable-next-line react/forbid-prop-types
  phones: PropTypes.array.isRequired,
  /**
   * The function to get end user data
   */
  getEndUser: PropTypes.func.isRequired,
};

export default EndUserProfile;
