/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { adminApi } from '../../api';

import PageTitle from '../../components/common/PageTitle';
import Loader from '../../components/common/Loader';
import FreezeModal from '../../components/common/FreezeModal';
import EndUserProfile from '../../components/admin/EndUserProfile';
import VerificationRecords from '../../components/admin/VerificationRecords';
import EndUserDocuments from '../../components/admin/EndUserDocuments';
import PaymentMethods from '../../components/common/endusers/PaymentMethods';
import ConfirmModal from '../../components/common/ConfirmModal';
import { hasSuperuserAccess } from '../../utils';
import { SUPERUSER_LEVEL } from '../../constants';

function EndUser({ t, match, location, history, user, setAlert }) {
  const [key, setKey] = useState('profile');
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [phones, setPhones] = useState([]);
  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
    full_name: '',
    handle: '',
    emails: [{ email: '', action: 'add' }],
    phones: [{ phone: '', action: 'add' }],
    birthdate: '',
    addresses: [
      {
        action: 'add',
        street_address_1: '',
        street_address_2: null,
        city: '',
        state: '',
        postal_code: '',
      },
    ],
    wallets: [
      {
        action: 'add',
        blockchain_address: '',
      },
    ],
    identities: [
      {
        action: 'add',
        identity_type: 'SSN',
        identity: '',
      },
    ],
    devices: [],
    apps: [],
    verification_status: false,
    certification_status: '',
    verification_history: [],
    member_verifications: [],
    payment_instruments: [],
  });

  const [showFreezeModal, setShowFreezeModal] = useState(false);
  const [achTransactions, setAchTransactions] = useState(false);
  const [userDetails, setUserDetails] = useState(undefined);

  const getEndUser = async (callback) => {
    let data = {};
    try {
      const [endUserResponse, statusesResponse, levelsResponse, paymentInstruments] =
        await Promise.all([
          adminApi.getEndUser(match.params.handle),
          adminApi.getVerificationStatuses(),
          adminApi.getKycLevels(),
          adminApi.getEndUserPaymentInstruments({
            team: user.currentTeam.id,
            handle: match.params.handle,
          }),
        ]);
      if (
        endUserResponse.success &&
        statusesResponse.success &&
        levelsResponse.success &&
        paymentInstruments.success
      ) {
        data = {
          first_name: endUserResponse.entity.first_name,
          last_name: endUserResponse.entity.last_name,
          full_name:
            endUserResponse.entity.entity_name ||
            `${endUserResponse.entity.first_name} ${endUserResponse.entity.last_name}`,
          handle: endUserResponse.user_handle,
          emails: endUserResponse.emails.length
            ? [endUserResponse.emails[0]]
            : initialValues.emails,
          phones: endUserResponse.phones.length
            ? [endUserResponse.phones[0]]
            : initialValues.phones,
          birthdate: endUserResponse.entity.birthdate,
          addresses: endUserResponse.addresses.length
            ? [endUserResponse.addresses[0]]
            : initialValues.addresses,
          wallets:
            endUserResponse.wallets || endUserResponse.wallets.length
              ? endUserResponse.wallets
              : initialValues.wallets,
          identities: endUserResponse.identities.filter((identity) => identity.identity).length
            ? [endUserResponse.identities.filter((identity) => identity.identity)[0]]
            : initialValues.identities,
          devices: endUserResponse.devices,
          entity_type: endUserResponse.entity_type,
          apps: endUserResponse.apps,
          memberships:
            endUserResponse.entity_type === 'individual'
              ? endUserResponse.memberships
              : endUserResponse.members,
          member_verifications:
            endUserResponse.entity_type === 'business'
              ? endUserResponse.member_verifications
              : null,
          verification_history: endUserResponse.verification_history,
          verification_status: endUserResponse.verification_status,
          certification_status: endUserResponse.certification_status,
          verification_statuses: statusesResponse.verification_statuses,
          kyc_levels: levelsResponse.kyc_levels,
          payment_instruments: paymentInstruments.payment_instruments,
          frozen: endUserResponse.entity.frozen,
          freeze_reasons: endUserResponse.entity.freeze_reasons
            ? endUserResponse.entity.freeze_reasons
            : {},
        };
        setPhones(endUserResponse.phones);
        setInitialValues(data);
        if (callback) callback(data);
      } else {
        setRedirect(true);
      }
    } catch (error) {
      setAlert({ message: error, variant: 'warning' });
      setRedirect(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setKey(match.params.action);
  }, [match]);

  useEffect(() => {
    if (redirect)
      history.push({ pathname: '/admin/end_users', state: { from: location.pathname } });
  }, [redirect]);

  useEffect(() => {
    getEndUser();
  }, []);

  const userFreezeUnfreeze = async (freezeReasonObj) => {
    const desiredFreezeState = !initialValues.frozen;
    const response = await adminApi.userFreezeUnfreeze(
      desiredFreezeState,
      freezeReasonObj.freeze_reason,
      initialValues.handle
    );
    getEndUser();
    if (response.success) {
      setAlert({
        message: response.message,
        variant: 'success',
      });
    } else if (response.status_code === 400) {
      setAlert({
        message: response.message,
        variant: 'danger',
      });
    } else if (response.status_code === 409) {
      setAlert({
        message: response.message,
        variant: 'warning',
      });
    }
    if (response.frozen && response.unsubmitted_transactions) {
      setAchTransactions(true);
      setUserDetails({ transactions: response.unsubmitted_transactions });
    }
    setShowFreezeModal(false);
  };

  const cancelACHTransactions = async (values) => {
    if (values) {
      try {
        const response = await Promise.all(
          values.transactions.map((transaction) =>
            adminApi.cancelACHTransactions({ user_handle: values.user_handle, transaction })
          )
        );
        let message;
        if (response.some((res) => !res.success)) {
          response.forEach((res) => {
            // eslint-disable-next-line no-undef
            if (!res.success) throw handleResponseError(res);
            else message = response.message;
          });
        }
        setAchTransactions(false);
        setUserDetails(undefined);
        setAlert({ message: message, variant: 'success' });
      } catch (error) {
        setAlert({ message: error, variant: 'warning' });
      }
    } else {
      setAchTransactions(false);
      setUserDetails(undefined);
    }
  };

  // variable used in FreezeModal
  const name = initialValues.full_name;
  const type = t('common:endUsers.labels.singular');

  return (
    <Container className="main-content-container p-3 p-md-5 d-flex flex-column flex-grow-1">
      <Row noGutters className="page-header mb-3 mb-md-5">
        <PageTitle title={t('admin.endUsers.profile.title')}>
          <Button
            variant="outline-light"
            className="back text-info"
            onClick={() => history.goBack()}
          >
            {t('common:buttons.back')}
          </Button>
        </PageTitle>
      </Row>

      {isLoading ? (
        <Loader />
      ) : (
        <Card className="loaded flex-grow-1">
          <Card.Header className="d-flex p-4">
            <h3 className="m-0 mr-auto">
              <i className={`sila-icon mr-2 text-primary ${initialValues.entity_type}`} />{' '}
              {initialValues.full_name}
            </h3>
            <Button
              onClick={() => setShowFreezeModal(true)}
              className="generate mr-3 rounded-sm py-1"
              variant="primary"
              size="sm"
            >
              {t(`common:buttons.${initialValues.frozen === true ? 'unfreeze' : 'freeze'}`)}
            </Button>
            {initialValues.verification_status && (
              <p
                className={`text-lg mb-0 text-${
                  initialValues.verification_status.includes('passed')
                    ? 'success'
                    : initialValues.verification_status.includes('failed')
                    ? 'warning'
                    : 'primary'
                }`}
              >
                {initialValues.entity_type === 'business' ? 'KYB' : 'KYC'}{' '}
                {
                  initialValues.verification_statuses.find(
                    // eslint-disable-next-line eqeqeq
                    (status) => status.name == initialValues.verification_status
                  ).label
                }
              </p>
            )}
          </Card.Header>
          <Card.Body as={Row} className="p-0" noGutters>
            <Tab.Container activeKey={key} defaultActiveKey={key} onSelect={(k) => setKey(k)}>
              <Col md="4" lg="3" xl="2" className="border-right">
                <Nav className="d-flex flex-column align-content-start h-100 py-4">
                  <Nav.Item className="w-100">
                    <Nav.Link
                      className={`d-block px-4 py-3${
                        key === 'profile' ? ' bg-primary-accent text-primary' : ''
                      }`}
                      eventKey="profile"
                      as={NavLink}
                      to={{
                        pathname: `/admin/end_user/${match.params.handle}/profile`,
                        state: { from: location.pathname },
                      }}
                    >
                      {t('admin.endUsers.navigation.profile')}
                    </Nav.Link>
                  </Nav.Item>
                  {initialValues.verification_history.length !== 0 && (
                    <>
                      <Nav.Item className="w-100">
                        <Nav.Link
                          className={`d-block px-4 py-3${
                            key === 'records' ? ' bg-primary-accent text-primary' : ''
                          }`}
                          eventKey="records"
                          as={NavLink}
                          to={{
                            pathname: `/admin/end_user/${match.params.handle}/records`,
                            state: { from: location.pathname },
                          }}
                        >
                          {t('admin.endUsers.navigation.records')}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="w-100">
                        <Nav.Link
                          className={`d-block px-4 py-3${
                            key === 'docs' ? ' bg-primary-accent text-primary' : ''
                          }`}
                          eventKey="docs"
                          as={NavLink}
                          to={{
                            pathname: `/admin/end_user/${match.params.handle}/docs`,
                            state: { from: location.pathname },
                          }}
                        >
                          {t('admin.endUsers.navigation.docs')}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="w-100">
                        <Nav.Link
                          className={`d-block px-4 py-3${
                            key === 'payment-instruments' ? ' bg-primary-accent text-primary' : ''
                          }`}
                          eventKey="payment-instruments"
                          as={NavLink}
                          to={{
                            pathname: `/admin/end_user/${match.params.handle}/payment-instruments`,
                            state: { from: location.pathname },
                          }}
                        >
                          {t('admin.endUsers.navigation.paymentInstruments')}
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  )}
                </Nav>
              </Col>
              <Col md="8" lg="9" xl="10" className="p-4">
                <Tab.Content>
                  <Tab.Pane eventKey="profile">
                    <EndUserProfile
                      initialValues={initialValues}
                      phones={phones}
                      setInitialValues={setInitialValues}
                      getEndUser={getEndUser}
                    />
                  </Tab.Pane>
                  {initialValues.verification_history.length !== 0 && (
                    <>
                      <Tab.Pane eventKey="records">
                        <VerificationRecords
                          initialValues={initialValues}
                          setInitialValues={setInitialValues}
                          getEndUser={getEndUser}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="docs">
                        <EndUserDocuments
                          initialValues={initialValues}
                          setInitialValues={setInitialValues}
                          getEndUser={getEndUser}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="payment-instruments">
                        <PaymentMethods initialValues={initialValues} getEndUser={getEndUser} />
                      </Tab.Pane>
                    </>
                  )}
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Card.Body>
        </Card>
      )}

      {showFreezeModal && (
        <FreezeModal
          show={showFreezeModal}
          data={{}}
          handleFreeze={userFreezeUnfreeze}
          reasons={initialValues.freeze_reasons}
          setShow={setShowFreezeModal}
          loaderButtonText={
            initialValues.frozen ? t('common:buttons.unfreeze') : t('common:buttons.freeze')
          }
          isAdmin={hasSuperuserAccess(user, [SUPERUSER_LEVEL.FULL_ACCESS])}
          adminDescription={
            initialValues.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.reasonDescription', { name })
              : t('common:freezeUnfreeze.model.freeze.reasonDescription', { name })
          }
          customerDescription={
            initialValues.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.confirm', { name })
              : t('common:freezeUnfreeze.model.freeze.confirm', { name })
          }
          title={
            initialValues.frozen
              ? t('common:freezeUnfreeze.model.unFreeze.title', { type })
              : t('common:freezeUnfreeze.model.freeze.title', { type })
          }
        />
      )}

      {achTransactions && (
        <ConfirmModal
          size="lg"
          show={achTransactions}
          data={{ user_handle: initialValues.handle, transactions: userDetails.transactions }}
          title={t('admin.endUsers.freezeUnfreeze.model.achTransaction.title')}
          message={t('admin.endUsers.freezeUnfreeze.model.achTransaction.message', {
            handle: initialValues.handle,
            transaction_count: userDetails.transactions.length,
          })}
          buttonLabel={t('admin.endUsers.freezeUnfreeze.model.cancelTransaction.button')}
          cancelButtonLabel={t('admin.endUsers.freezeUnfreeze.model.approveTransaction.button')}
          onHide={cancelACHTransactions}
        />
      )}
    </Container>
  );
}

export default EndUser;
