import React, { createContext, useState, useContext } from 'react';
import Cookies from 'js-cookie';

export const appContext = createContext(null);

// Set default app data
let appData = {
  isLoading: false,
  menuVisible: false,
  invitation: false,
  validation: {
    checkPassword: {},
    confirmPassword: {},
    checkTeamName: {},
    checkHandle: {},
    checkEmail: {}
  },
  registration: {
    inProgress: false
  },
  settings: {
    filters: []
  },
  checklist: {}
};

// Get current users handle
let sila_console_handle = Cookies.get('sila_console_handle');

// Get app data
const getAppStorage = () => ({
  ...appData,
  settings: { ...appData.settings, ...JSON.parse(localStorage.getItem(`sila_console_settings_${sila_console_handle}`)) } || appData.settings,
  checklist: { ...appData.checklist, ...JSON.parse(localStorage.getItem(`sila_console_checklist_${sila_console_handle}`)) } || appData.checklist
});

// Set app data
const setAppStorage = (data) => { // eslint-disable-next-line
  if (!sila_console_handle) sila_console_handle = Cookies.get('sila_console_handle');
  data.settings && localStorage.setItem(`sila_console_settings_${sila_console_handle}`, JSON.stringify({ ...appData.settings, ...data.settings }));
  data.checklist && localStorage.setItem(`sila_console_checklist_${sila_console_handle}`, JSON.stringify({ ...appData.checklist, ...data.checklist }));
  return true;
};

// Initialize app data
let initAppData = getAppStorage();

// Create a provider for components to consume and subscribe to changes
const AppDataProvider = props => {

  // Initialize app state
  const [app, setApp] = useState(initAppData);

  const updateApp = (state, callback) => setApp(prevApp => {
    const values = { ...prevApp, ...state };
    if (callback) callback(values);
    return values;
  });

  const setAppData = (data, callback) => {
    initAppData = getAppStorage();
    for (let key in data) {
      if (initAppData.hasOwnProperty(key)) initAppData[key] = data[key];
    }
    setAppStorage(initAppData);
    updateApp(initAppData);
    if (callback) callback(initAppData);
  };

  return <appContext.Provider value={{
    app,
    updateApp,
    setAppData,
  }} {...props} />;
};

export const useApp = () => useContext(appContext);

export default AppDataProvider;